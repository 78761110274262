import styled from 'styled-components'
import {
   MixinVertical, 
	Border,
	BorderRadius,
	MixinColor,
	MixinBackground,
	MixinPadding,
   MixinDisabled,
   MixinGrid,
} from 'components/topology/Topology'

///TODO style
export const ListItemStyled = styled.div`
	font-size: 14px;
	white-space: nowrap;
	padding:3px;
	cursor: ${props => props.disabled ? 'default' : 'pointer'};
	border: 1px solid;

	border-color:${ props => {
		if(props.disabled)
			return props.theme?.colors?.white
		else {
			return props.marked ? props.theme?.colors?.primaryDarken800 : props.theme?.colors?.white
		}
	}};
	
	background-color:${ props => {
		if(props.disabled)
			return props.theme?.colors?.white
		else {
			return props.marked ? props.theme?.colors?.primaryDarken : props.theme?.colors?.white
		}
	}};

	color: ${ props => {
		if(props.disabled)
			return 'rgba(120, 120, 120, 1)'
		else {
			return props.marked ? props.theme?.colors?.white : props.theme?.colors?.black
		}	
	}};

	margin-left: ${ props => {
		let count = 0
		let v = props.value

		for (;v[count] === '\t' && count < v.length;++count)
			;
		return count * props.size
	}}px;
`

///TODO style
export const ListStyled = styled.div`
	user-select: none;
	transition: all 0.1s ease-out;
	overflow-y:auto;
	margin: 30px 0;
	font-size: 14px;
	${Border('primary')}
	${BorderRadius()}
	${MixinColor('grey700')}
	${MixinBackground('primaryBackground')}
	${MixinPadding(['7px', 'md'])}
	margin: 0 7px;
	min-width: 200px;
	
	&:focus {
		${Border('focus')}
		outline: none;
	}

	${({ disabled }) => disabled && MixinDisabled()}
	${({ error, theme }) =>
		error &&
		`
		border: ${theme.borders.error};
			&:focus{
					border: ${theme.borders.error};
					outline: none;
		}
	`}
`

///TODO style
export const ButtonsStyled = styled.div`
	${MixinVertical({ acy: 'center', acx: 'center', aiy: 'center', aix: 'center', g: 'md' })}
`

///TODO style
export const EnumListStyled = styled.div`
	${MixinGrid({ tx: '1fr auto 1fr' })}
`