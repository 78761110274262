import styled from 'styled-components'
import { MixinMargin, MixinFont, Border  } from 'components/topology/Topology'
import { customElements, Css, withStyles } from 'cssStatic'
import { IconButton } from '@material-ui/core'

//#region Style statyczne
Css.add({
	'.o-input-datetime-wrapper': { 'position': 'fixed', 'z-index': '9999', 'min-width': '255px'},
	'.o-input-datetime-topar-date': {'display': 'flex', 'max-height': '30px', 'align-items': 'center',  'margin-right': 'auto', 'font-weight': '600', 'letter-spacing': '1px', 'text-transform': 'capitalize'},
	'.o-input-datetime-icon-buttons': {'padding': '0px', 'color': 'var(--o-mainDarken700)', },
	'.o-input-datetime-topbar-icons': {'font-size': '31px'},
	'.o-input-datetime-input-clear-icon': ['cursor-pointer', {'color': 'rgba(0,0,0, 0.50)'}],
	'.calendar-grid-weekdays-content': {'font-weight': 500, 'min-width': '30px', 'min-height': '30px', 'text-align': 'center', 'color': 'rgba(26, 49, 75, 0.75)', 'text-transform': 'uppercase'},
	'.calendar-grid-days-content': ['calendar-grid-center-content', {'min-width': '30px', 'min-height': '30px',  'text-align': 'center', 'color': 'rgba(0, 0, 0, 0.87)', 
		'appearance': 'none', 'background-color': 'rgb(255, 255, 255)', 'border': '0px', 'outline': '0px', 'cursor': 'pointer', 'border-radius': '50%', 'transition': '.15s' }],
	'.calendar-grid-days-content:hover': {'background-color': 'rgba(0, 0, 0, 0.09)'},
	'.calendar-grid-days-content-disabled-month': {'width': '30px', 'height': '30px', 'background': 'transparent', 'border': 'none', 'cursor': 'default'},
	'.calendar-grid-days-content-selected-date': {'color': 'rgb(255, 255, 255)', 'background-color': 'var(--o-mainDarken700) !important','font-weight': 500},
	'.calendar-grid-days-content-today-date': {'font-weight': 500, 'outline': '2px var(--o-mainDarken700) solid'},
	'.calendar-grid-center-content': {'display': 'flex', 'justify-content': 'center', 'align-items': 'center', 'text-align':'center'},
	'.calendar-grid-time-buttons-wrapper': {'display': 'flex', 'flex-direction': 'column', 'justify-content': 'center', 'align-items': 'center',},
})
//#endregion

//#region withStyles
export const StyledIconButton = withStyles({root: 'o-input-datetime-icon-buttons'}, IconButton)
//#endregion


//#region customElements
export const CalendarWrapper = customElements('o-calendar-wrapper', ['br-radius-4', 'p-lr-5', 'p-top-8', 'p-bottom-5', 'm-tb-2', {
	'display': 'flex',
	'flex-direction': 'column',
	'justify-content': 'space-around',
	'align-items': 'center',
	'width': 250,
	'box-shadow': 'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
	'background-color': 'var(--o-mainBackground)',
	'border': '1px solid	rgba(85,85,85, 0.4)',
	'display': 'flex',
	'flex-direction': 'column',
	'overflow-x': 'hidden',
}])

export const StyledCalendarTopbar = customElements('o-calendar-topbar', {
	'display': 'flex',
	'justify-content': 'space-between',
	'flex-direction': 'row',
	'align-items': 'center',
	'margin-top': '8px',
	'margin-bottom': '8px',
	'width': '100%',
	'padding': '0px 16px',
})

export const StyledCalendarGrid = customElements('o-calendar-grid', ['grid', 'grid-gap-5', 'grid-col-7',  'grid-aix-end', 'm-top-8', 'm-bottom-5'], {'width': '90%'})

export const StyledTimeWrapper = customElements('o-calendar-time-wrapper', {
	'display': 'flex',
	'flex-wrap': 'nowrap',
	'align-items': 'center',
	'justify-content': 'space-around',
	'width': '100%',
	'border-top': '3px solid var(--o-mainDarken800)',
	'padding': '8px 5px 5px 5px',
})
//#endregion


//TODO Przepisać na CssStaticLib!
export const StyledTimeInput = styled.div` 
	display: flex;
	justify-content: center;
	align-items: center;

	& input {
		height: 20px;
		outline: none;
		padding: 0 3px 1px 3px;
		width: 25px;
		height: 50px;
		background-color: transparent;
		margin-right: 8px;
		user-select: none;
		-moz-appearance: textfield;
		${Border('none')};
		${MixinFont('p-md')}
	}

	& input[type='number']::-webkit-inner-spin-button,
	& input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		${MixinMargin(['none'])};
	}
`
