import React from 'react'
import Input from './Input/Input'
import PropTypes from 'prop-types'
import { InputAdornment } from '@material-ui/core'
import { useState } from 'react'
import { Visibility, VisibilityOff } from '@material-ui/icons'

/**
 * Komponent input password
 *
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @param {boolean} autoComplete Czy włączona auto kompletacja tekstu
 * @returns {Node}
 */
export default function InputPassword({ name, hasLabel, autoComplete, ...other }) {
	const [visible, setVisible] = useState(false);

	return (
		<>
			<Input
					autoComplete={autoComplete}
					type={visible ? "text" : "password"}
					name={name}
					hasLabel={hasLabel}
					InputProps={{
						endAdornment: (
							<InputAdornment position="start" onClick={() => setVisible(!visible)}>
									{visible ? <Visibility /> : <VisibilityOff />}
							</InputAdornment>
						)
					}}
				{...other}
			/>
		</>
	)
}

InputPassword.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool
}

InputPassword.defaultProps = {
	hasLabel: true
}
