import React from 'react'
import { Clear } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import includes from 'lodash/includes'
import { appCtrl } from 'AppCtrl'


// const result = await Helper.uploadFileXhr({
//    url: '/postFile',
//    files: await Helper.selectFileDialog(true),
//    data: { a: 10, b: 'wewewwe' },
//    onProgress: params => {
//       console.log(`${params.position} - ${params.size}`)
//       params.stop = !element.isMounted
//       element.text = `upload ${Math.round(100 * params.position / params.size)}%`
//    }
// })


// const blob = await Helper.downloadFile({
//    url: '/getFile/stanowiska.xlsx',
//    //url: '/getFile/gory.tar',
//    onProgress: (params) => {
//       console.log(`${params.position} - ${params.size}`)

//       element.text = `download ${Math.round(100 * params.position / params.size)}%`
//       params.stop = !element.isMounted
//    }
// })
// await Helper.fileSaveDialog(blob, 'stanowiska.xlsx')



/**
* Sprawdza czy odpowiedz z fetch jest poprawna.
* Jeśli nie to generuje wyjątek i umieszcza w nim najważniejsze informacje:
* status {integer} Status odpowiedzi
* statusText {string} Text statusu
* url {string} Url zapytanie
* json {object} Dodatkowe informacje przesłane przez serwer
* response {Object} 
*
* @param {object} response Odpowiedz fetch'a
*/
// const fetchCheckErrors = async(response) => {
//    if (response.ok)
//       return

//    const err = new Error(response.statusText)
//    err.status = response.status
//    err.statusText = response.statusText
//    err.response = response
//    err.url = response.url
//    try { err.json = await response.json() } catch (e) { }
//    throw err
// }

/**
* Ładuje plik z serwera i zwraca go jako blob
*
* @param {object} params:
* 	{string} url adres pobierania
* 	{function} onProgress Powiadamia o postepie
* 	{bool} stop Utawienie na true zatrzymuje ładowanie
* 	{integer} position Pozycja w ładowaniu pliku
* 	{integer} size Rozmiar ładowanego pliku
* 	{object} headers Nagłówki wysyłane do servera
* @returns {Blob} blob z plikiem. Uwaga nie ma możliwości odebrania odpowiedzi serwera naprzykład json
*/
// const downloadFile = async (params) => {
//    let response = await fetch(params.url, { headers: params.headers })

//    await Helper.fetchCheckErrors(response)

//    const reader = response.body.getReader()
//    const size = +response.headers.get('Content-Length')
//    let position = 0
//    let chunks = []

//    for (; ;)
//    {
//    const { done, value } = await reader.read()

//    if (params.stop) {
//    reader.cancel()
//    return
//    }

//    if (done)
//    break

//    chunks.push(value)
//    position += value.length

//    params.position = position
//    params.size = size

//    if (params.onProgress)
//    params.onProgress(params)
//    }

//    if (params.onProgress)
//    params.onProgress(params)

//    return new Blob(chunks)
//    }

   /**
   * Otwiera dialog zapytanie gdzie zapisać plik
   *
   * @param {Blob} blob Blob pobrany przez serwera
   * @param {string} fileName Nazwa pliku ktura się pojawi jako domyślna przy zapisywaniu
   */
//    static async fileSaveDialog(blob, fileName) {
//    const a = document.createElement("a")
//    a.href = URL.createObjectURL(blob)
//    a.setAttribute("download", fileName || 'file')
//    document.body.appendChild(a)
//    a.click()
//    document.body.removeChild(a)
//    return
// }

/**
* Umożliwia wyświetlenie z poziomu przegladarki dialogu z wyborem plików do upload'u.
* Wyświelanie jest asynchroniczne.
* 
* @param {bool} multiple czy można zaznaczać wiele plików
@returns {} Po wywołaniu await selectFileDialog() zwraca tablicę (nie Array wybranych plików) 
*/
// static selectFileDialog(multiple) {
// return new Promise((resolve, reject) => {
// try {
// const input = document.createElement("input")

// input.setAttribute('type', 'file')
// input.style.display = 'none'
// input.multiple = multiple
// input.onchange = (e) => {
// document.body.removeChild(input)
// resolve(e.target.files)
// }
// document.body.appendChild(input)
// input.click()
// }
// catch (err) {
// reject(err)
// }
// })
// }

/**
    * Asynchronicznie wysyła pliki na serwer pod adress params.url przy pomocy XMLHttpRequest.
    * Uruchamiać await Helper.uploadFileXhr({...})
    * Jeśli błąd rzuca wyjątek w err.message tekst błędu. w err.status i err.statusText numer i tekst błedu
    * Jeśli wszystko ok zwraca text odpowiedzi serwera
    *
    * @param {Object} params Object zawierający:
    *    {string} url Adres na który wysyłamy pliki  
    *    {} files Tablica plików do wysłania
    *    {object} data Dane wysyłane dodatkowo na server
    *    {object} headers Mapa nagłowkow do wysłania {nazwa:wartosc,...}
    *    {function} onProgress Powiadamianie o postępie wysyłania. Ustawiając params.stop=true można zatrzymać wczytywanie
    *       params.position = ile plikow zostalo wysłanych
    *       params.size = ilość wszystkich
    * @returns {string} Zwraca text odpowiedzi serwera. Jeśli jest to json to można parsowac.
    */
  export const uploadFilesWithProgress = async(params) => {
	const filePromises = []
	params.files.forEach(file => {
		const uploadSingleFileParams = { ...params };
		uploadSingleFileParams.files = [file];
		filePromises.push(uploadFileXhr(uploadSingleFileParams));
	})

	const results = await Promise.allSettled(filePromises)
	// Przypisujemy każdemu rezultatowi jego plik
	results.forEach((result, index) => result.file = params.files[index]);
	if (results.find(result => result.status === 'rejected'))
	{ 
		// Przypadek w którym któryś z plików nie został poprawnie wysłany 
		const errorClass = new Error()
		errorClass.results = results.filter(result => result.status === 'rejected')
		throw errorClass
	}

	return results
}

/**
* Asynchronicznie wysyła pliki na serwer pod adress params.url przy pomocy XMLHttpRequest.
* Uruchamiać await Helper.uploadFileXhr({...})
* Jeśli błąd rzuca wyjątek w err.message tekst błędu. w err.status i err.statusText numer i tekst błedu
* Jeśli wszystko ok zwraca text odpowiedzi serwera
*
* @param {Object} params Object zawierający:
* 	{string} url Adres na który wysyłamy pliki  
* 	{array} files Tablica plików do wysłania. W naszym przypadku na ten moment jest to zawsze tablica jedno elementowa, ponieważ wysyłamy
* 			 po jednym pliku. Pojedynczy obiekt ma takie atrybuty jak "size", "name", "id", "result" (nasze dane zwrotne).
* 	{object} data Dane wysyłane dodatkowo na server. Na ten moment nie używamy tej właściwości ponieważ nic ekstra nie wysyłamy.
* 	{object} headers Mapa nagłowkow do wysłania {nazwa:wartosc,...}
*	{function} onProgress Powiadamianie o postępie wysyłania. Ustawiając params.stop=true można zatrzymać wczytywanie
*	@returns {string} Zwraca text odpowiedzi serwera. Jeśli jest to json to można parsowac. 
*/
export const uploadFileXhr = (params) => {
   return new Promise((resolve, reject) => 
   {
		if (!params.files) { //Jeśli brak plików rozwiązujemy obietnicę z "params"
			resolve(params)
			return
		}

		const formData = new FormData()
		for (const file of params.files) { //Dodajemy pliku do obiektu FormData
			formData.append('file', file, file.name)
		}

		if (params.data) //Jeśli podano dodatkowe dane do przesłania razem z plikami, również dodajemy je do obiektu
         formData.append('data', JSON.stringify(params.data))

		let filesUploadedContentLength = 0;
		let filesContentLength = -1;
		const xhr = new XMLHttpRequest();
		console.log(params);
		xhr.upload.addEventListener( //Callback nasłuchujący zdarzenia progress (Odpalany po każdym wysłanym pliku)
			'progress',
			(evt) => {
				if (params.stop) 
				{
					xhr.abort();
				}
				filesContentLength = evt.total;
				filesUploadedContentLength = evt.loaded;

				const progressData = {
					files: params.files,
					filesContentLength: evt.total,
					filesUploadedContentLength: evt.loaded,
					inProgress: true
				};
				params.onProgress(progressData);
			},
			false
		)

		xhr.onreadystatechange = (evt) => {
			switch (xhr.readyState) {
				case 0: //0 zapytania niezainicjowane
					break
				case 1: //1 cel otwarty
					break
				case 2: //2 zapytanie wysłane
					break
				case 3: //3 odbieranie odpowiedzi
					break
				case 4: //4 żądanie zakończone
					const progressData = {
						files: params.files,
						filesContentLength: filesContentLength,
						filesUploadedContentLength: filesUploadedContentLength,
						inProgress: false
					};
					params.onProgress(progressData);

					if (includes([200, 201], xhr.status)) { 
						//Jeśli dostajemy pozytywną odpowiedź z BE, wywołujemy funkcję onProgress i rozwiązujemy pozytywnie Promise'a
						resolve(xhr.responseText)
						break
					}

					const err = new Error(xhr.responseText)
					err.status = xhr.status
					err.statusText = xhr.statusText
					reject(err) //Rozwiązujemy negatywnie obietnicę przekazując obiekt błędu w przypadku niepowodzenia 
					break
			}
		}

		xhr.open('POST', params.url, true) //Otwieramy asynchriniczne połączenie z BE do uploadu plików
		
		if (params.headers){ //Przepisujemy nagłówki które przyszły w parametrach
			for (let name in params.headers)
				xhr.setRequestHeader(name, params.headers[name])
		}
		
		xhr.send(formData) //Wysyłamy obiekt zawierający wszystkie pliki do BE
	})
}

/**
 * Pobierz nazwę pliku bez rozszerzenia
 * @param {string} fileName Nazwa pliku
 * @returns Nazwa pliku bez rozszerzenia lub wartość wejściowa
 */
export const getFileNameWithoutExtension = (fileName) =>{
	if(fileName == null)
		return fileName;

	const lastDotIndex = fileName.lastIndexOf('.');
	if(lastDotIndex < 0)
		return fileName;

	return fileName.substring(0, lastDotIndex);
}