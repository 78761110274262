import React from 'react';
import { Css } from 'cssStatic'

Css.add({
	'.placeholder-empty-field': {width: '100%', height: '100%', background: '#f7f7f7' },
})


/**
 * Komponent placeholdera dla pustego pola - używany w przypadku gdy w atrybutach dla danego pole przyjdzie wartość null
 * 
 * @returns {Node}
 */
export const EmptyPlaceholderField = () => ( <div className='placeholder-empty-field' />)
