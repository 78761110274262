import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { InputAdornment, ClickAwayListener } from '@material-ui/core'
import { Clear, EventOutlined, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight, KeyboardArrowUp, Today } from '@material-ui/icons'

import moment from 'moment/min/moment-with-locales'
import trim from 'lodash/trim'
import range from 'lodash/range'
import chunk from 'lodash/chunk'
import clsx from 'clsx'
import isEmpty from 'lodash/isEmpty'
import { useSnackbar } from 'notistack'
import { Portal } from 'react-portal'
import InputMask from "react-input-mask"

import { useLocale, useTranslate } from 'locale/Locale'
import useField from 'components/form/hooks/useField'
import { StyledTimeWrapper, StyledTimeInput, CalendarWrapper, StyledCalendarTopbar, StyledCalendarGrid, StyledIconButton } from './StyledInputDateTime'
import Input from '../Input/Input'


//#region Globalne Funkcje pomocnicze
/**
 * Funkcja zwracająca tablicę wyrażeń regularnych używanych przez kontrolkę "InputMask" do poprawnego maskowania daty
 * @param {Boolean} onlyDate - Funkcja mówiąca o tym czy zwracamy samą datę, czy datę z godziną
 * @returns {Array} - Zwraca tablicę wyrażeń regularnych używanych do poprawnego maskowania daty 
 */
const inputMaskDefinitions = (onlyDate) => {
	const year = [/[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/]
	const month = [/[0-1]/, /[0-9]/]
	const day = [/[0-3]/, /[0-9]/]
	const hour = [/[0-2]/, /[0-9]/]
	const minute = [/[0-5]/, /[0-9]/]
	const dateSeparator = '-'
	const timeSeparator = ':'

	if (onlyDate) {
		return [...year, dateSeparator, ...month, dateSeparator, ...day]
	}
	else {
		return [...year, dateSeparator, ...month, dateSeparator, ...day, ' ', ...hour, timeSeparator, ...minute]
	}
}
//#endregion


//#region Komponenty
/**
 * Komponent Ikonki kalendarza dla Inputa
 * @param {Boolean} disabled - informacja czy kontrolka jest aktywna
 * @param {Boolean} value - aktualna wartość kontrolki
 * @param {Boolean} handleChange - funkcja odpowiadająca za zmianę wartości w formie  
 * @param {Boolean} handleMenuOpen - Funkcja wywoływana przy otwarciu menu
 */
const InputIcon = React.memo(({ disabled, required, value, handleMenuOpen, setError, setHelperText, handleChange }) => {
	return (
		<>
			{
				!disabled &&
				<InputAdornment position="end">
					<Clear
						className={'o-input-datetime-input-clear-icon'}
						onClick={() => {
							if (required) {
								setError(false)
								setHelperText(null)
							}
							if (!trim(value).length || value === undefined) {
								return
							}
							else {
								handleChange(null)
							}
						}}
					/>
				</InputAdornment>
			}
			<InputAdornment position="end">
				<EventOutlined
					className={disabled ? 'cursor-default' : 'cursor-pointer'}
					onClick={e => handleMenuOpen(e)}
				/>
			</InputAdornment>
		</>
	)
});

InputIcon.displayName = "InputIcon";


/**
 * Komponent Inputa Tekstowego
 * @param {Object} props - Właściwości przekazywane do komponentu
 * @param {Ref} ref - referencja mająca wskazywać na inputa
 * @returns {Node}
 */
const CalendarInput = React.forwardRef((props, ref) => {
	const { name, value, disabled, required, dateFormat, hasLabel, setOpen, isOpen, handleChange, onlyDate, setCoords } = props
	const { enqueueSnackbar } = useSnackbar()
	const translate = useTranslate('WebSpa/Inputs/CalendarInput')
	const translateHelperText = useTranslate('WebSpa/HelperText')
	const [inputValue, setInputValue] = useState("")
	const [error, setError] = useState(false)
	const [helperText, setHelperText] = useState(null)
	const inputMaskArray = inputMaskDefinitions(onlyDate)

	useEffect(() => {
		const dateFromValue = value === null ? undefined : moment(value).format(dateFormat)

		if (!value && required) { //Jeśli nie ma wartości a pole jest wymagane - Błąd
			setError(true)
			setHelperText(translateHelperText('require'))
			return
		}
		if (dateFromValue === null || dateFromValue === 'Invalid date') { // Jeśli nie da się parsować wartości pola na datę - Błąd
			setError(true)
			setHelperText(translate('error.invalidDateFormat'))
			return
		}
		else {
			setInputValue(value === undefined ? '' : dateFromValue) //Odświeżamy wartość inputa za każdym razem gdy zmieni się value w formie
			setError(false)
			setHelperText(null)
		}
	}, [value])


	useEffect(() => { //Dodajemy nasłuchiwanie na event scrollowania w celu poprawnego ustalenia pozycji menu przy scrollowaniu
		isOpen && window.addEventListener("scroll", handleGetElementPosition, true)
		return () => {
			window.removeEventListener("scroll", handleGetElementPosition, true)
		}
	}, [isOpen])

	/**
	 * Funkcja zwracająca obecną pozycję kontolki na ekraniewzględem całego okna
	 * @returns {Object} - Obiekt zawierający współrzędne menu
	 */
	const handleGetElementPosition = () => {
		if (!ref?.current)
			return
		else {
			const rect = ref.current.getBoundingClientRect()
			setCoords({
				top: rect.top + rect.height + window.scrollY,
				left: rect.left + window.scrollX,
				width: rect.width,
			})
		}
	}

	/**
	 * Funkcja obsługująca zdarzenie otwarcia menu kalendarza,
	 * Jeśli użytkownik ma przytrzymany jeden ze specjalnych klawiszy, menu się nie otwiera (przydatne przy edycji w wierszu)
	 */
	const handleMenuOpen = React.useCallback(e => {
		if (disabled) {
			return
		}
		else {
			handleGetElementPosition()//Funkcja aktualizująca pozycje kontrolki w celu ustawienia pozycji menu

			if ((e.ctrlKey || e.shiftKey || e.altKey || e.metaKey))
				return

			setOpen(true)
		}
	}, [])


	const validateDateBeforeSave = (stringToValidate, showSnackbar) => {
		const dateTimeISO = (onlyDate ? moment.utc(stringToValidate) : moment(stringToValidate)).toISOString();

		if (dateTimeISO !== null || dateTimeISO === 'Invalid date') {//Jeśli daty nie da się poprawnie zapisać do formy zwracamy snackbara z błędem
			handleChange(dateTimeISO)
		}
		else {
			setError(true)
			setHelperText(translate('incorrectDateFormatError'))
			showSnackbar && enqueueSnackbar(translate('incorrectDateFormatError'), { variant: 'error' })
		}
	}

	return (
		<div ref={ref}>
			<InputMask
				name={name}
				mask={inputMaskArray}
				value={inputValue}
				disabled={disabled}
				onFocus={() => setOpen(false)} //Gdy użytkownik zaczyna pisać w polu kontrolki, zamykamy kalendarz
				onBlur={(e) => {
					const value = e.target?.value ?? ''

					if (value.length === 0 && !required) {
						setError(false)
						setHelperText(null)
					}
				}}
				onChange={({ target }) => {
					const value = target?.value ?? ''

					if (value.includes('_') || value.length === 0) {//Jeśli tekst zawiera znak "_" znaczy to że maska nie jest do końca uzupełniona	
						setError(true)

						if (required) {
							setHelperText(translateHelperText('require'))
						}
						else {
							setHelperText(translate('incorrectDateFormatError'))
						}

					}
					else {
						setError(false)
						setHelperText(null)
						validateDateBeforeSave(value, true) //Jeśli maska jest poprawnie uzupełniona sprawdzamy poprawność daty
					}

					setInputValue(value)
				}}
				onKeyUp={(e) => {
					if (e.key === 'Enter') {
						if (!trim(inputValue).length) {
							//Przypadek gdy użytkownik próbuje dokonać zapisu, a nie wpisał żadnej wartości do kontrolki
							enqueueSnackbar(translate('emptyInputError'), { variant: 'error', })
						} else if (error) {
							//Jeśli data ma błędny format, nie ma sensu próbować jej zapisywać
							setError(true)
							setHelperText(translate('incorrectDateFormatError'))
							enqueueSnackbar(translate('incorrectDateFormatError'), { variant: 'error' })
						} else {
							//Gdy data pasuje do wzorca (zmienna error ma wartość false), mamy pewnosć że będzie dało się ją parsować do formatu ISO 8601
							validateDateBeforeSave(inputValue, true)
						}
					}
				}}
			>
				<Input
					name={name}
					error={error}
					helperText={helperText}
					hasLabel={hasLabel}
					onInput={() => { return }}
					InputProps={{
						endAdornment: (
							<InputIcon
								disabled={disabled}
								required={required}
								value={inputValue}
								handleMenuOpen={handleMenuOpen}
								setError={setError}
								setHelperText={setHelperText}
								handleChange={handleChange}
							/>
						)
					}}
				/>
			</InputMask>
		</div>
	)
})

CalendarInput.displayName = "CalendarInput";


/**
 * Input odpowiedzialny za dodawanie i odejmowanie godzin
 * @param {Function} setDateTimeObject - Funkcja ustawiająca obiekt daty
 * @param {Object} dateTimeObject - Aktualny obiekt czasu
 * @param {Function} onSelectValue - Funkcja wywoływana przy wyborze daty z kalendarza
 * @returns {Node}
 */
const HourInput = React.memo(({ setDateTimeObject, dateTimeObject, onSelectValue }) => {
	const hour = dateTimeObject.format("HH")

	const handleChangeHours = (newValue) => {
		setDateTimeObject(dateTimeObject.add(newValue, 'hour'))
		const dateISO = dateTimeObject.toISOString()
		onSelectValue(dateISO)
	}

	return (
		<StyledTimeInput> {/*TODO - PRZEPISAĆ NA CSS STATIC LIB */}
			<input
				type='number'
				min='00'
				max={'24'}
				value={hour}
				readOnly
			/>
			<div className='calendar-grid-time-buttons-wrapper'>
				<StyledIconButton onClick={() => handleChangeHours(1)} >
					<KeyboardArrowUp />
				</StyledIconButton>
				<StyledIconButton onClick={() => handleChangeHours(- 1)} >
					<KeyboardArrowDown /> {/* Żeby odjąć minuty przekazujemy "minutesRounded" ze znakiem minus */}
				</StyledIconButton>
			</div>
		</StyledTimeInput>
	)
})

HourInput.displayName = "HourInput";



/**
 * Input odpowiedzialny za dodawanie i odejmowanie minut
 * @param {Function} setDateTimeObject - Funkcja ustawiająca obiekt daty
 * @param {Object} dateTimeObject - Aktualny obiekt czasu
 * @param {Function} onSelectValue - Funkcja wywoływana przy wyborze daty z kalendarza
 * @returns {Node}
 */
const MinuteInput = React.memo(({ setDateTimeObject, dateTimeObject, onSelectValue }) => {
	const ROUND_TO = 10 //Wartość do której zaokrąglamy

	const handleChangeMinutes = (newValue) => {
		setDateTimeObject(dateTimeObject.add(newValue, 'minute'))
		const dateISO = dateTimeObject.toISOString()
		onSelectValue(dateISO)
	}

	return (
		<StyledTimeInput> {/*TODO - PRZEPISAĆ NA CSS STATIC LIB */}
			<input
				type='number'
				min='00'
				max={'60'}
				value={dateTimeObject.format('mm')}
				readOnly
			/>
			<div className='calendar-grid-time-buttons-wrapper'>
				<StyledIconButton
					onClick={() => {
						const roundUp =
							ROUND_TO - (dateTimeObject.minute() % ROUND_TO)
						handleChangeMinutes(roundUp)
					}}
				>
					<KeyboardArrowUp />
				</StyledIconButton>
				<StyledIconButton
					onClick={() => {
						let roundDown = dateTimeObject.minute() % ROUND_TO
						roundDown = roundDown === 0 ? ROUND_TO : roundDown
						handleChangeMinutes(- roundDown) /* Żeby odjąć minuty przekazujemy "roundDown" ze znakiem minus */
					}}
				>
					<KeyboardArrowDown />
				</StyledIconButton>
			</div>
		</StyledTimeInput>
	)
})

MinuteInput.displayName = "MinuteInput";

/** 
 * Komponent wyświetlający dni tygodnia
 * @param {Object} dateTimeObject - Aktualny obiekt czasu
 * @returns {Node}
 */
function Weeks({ dateTimeObject }) {

	const weeks = dateTimeObject.localeData().weekdaysMin(true) ?? []

	if (weeks.length === 0) {
		console.error("Błąd! Nie udało się pobrać dni tygodnia")
		return null
	}

	return (
		weeks.map((week, index) => (
			<div key={`weekday-${index}`} className='calendar-grid-weekdays-content'>
				<span className='calendar-grid-center-content' >{week}</span>
			</div>
		))
	)
}


/**
 * Komponent renderujący dni w kalendarzu
 * @param {Object} dateTimeObject - Aktualny obiekt czasu
 * @param {Function} onSelectValue - Funkcja wywoływana przy wyborze daty z kalendarza
 * @returns {Node}
 */
const CalendarBody = React.memo(({ dateTimeObject, onSelectValue, originalInputValue: originalInputValue }) => {
	const drawCalendar = () => {
		const m = dateTimeObject // m - objekt daty z obecną lokalizacją
		const daysInMonth = m.clone().endOf('month').daysInMonth(); //liczba dni w miesiącu
		const firstDayInMonth = m.clone().startOf('month').day(); //Od którego dnia tygodnia miesiąc się zaczyna
		const lastDayInMonth = m.clone().endOf('month').day(); //Na którym dniu tygodnia miesiąc się kończy
		const days = [].concat(
			Array(firstDayInMonth === 0 ? 6: firstDayInMonth - 1).fill().map(() => -1), // Wypełniacz - dni w poprzednim miesiacu (niedziela - 0 - więc zastępujemy ją 6)
			Array(daysInMonth).fill().map((_, idx) => 1 + idx), // Dni w aktualnym miesiacu
			Array(lastDayInMonth,7).fill().map(() => -1), // Wypełniacz - dni w następnym miesiacu
		)

		const calendar = chunk(days, 7).map((row, index) => {
			return row
		})
		return calendar
	}

	const today = moment();
	const calendar = drawCalendar()

	return (
		<StyledCalendarGrid>
			<Weeks dateTimeObject={dateTimeObject} />
			{calendar.map((weeks, parentIndex) => {
				return weeks.map((day, index) => {

					const isDayCurrentDay = day === today.date() && dateTimeObject.month() === today.month() && dateTimeObject.year() === today.year();
					const shouldBeDayMarkedAsSelected = day === dateTimeObject.date() && originalInputValue?.month() === dateTimeObject.month() && originalInputValue?.year() === dateTimeObject.year();
					const currentMonth = day !== -1;

					if (!currentMonth)
						return (
							<button
								key={index}
								className='calendar-grid-days-content-disabled-month'
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
								}}
							/> /*Placeholder*/
						)

					return (
						<button
							key={index}
							onClick={(e) => {
								const currentSelectedDate = dateTimeObject.date(day)
								const dateISO = currentSelectedDate.toISOString()
								onSelectValue(dateISO)
							}}
							className={clsx('calendar-grid-days-content', {
								'calendar-grid-days-content-selected-date': shouldBeDayMarkedAsSelected,
								'calendar-grid-days-content-today-date': isDayCurrentDay,
							})}
						>
							{day}
						</button>
					)
				})
			})}
		</StyledCalendarGrid>
	)
})

CalendarBody.displayName = "CalendarBody";


/**
 * Komponent wyświetlający miesiąc wraz z rokiem, oraz strzałki do zmiany miesiąca
 * @param {Function} setDateTimeObject - Funkcja ustawiająca obiekt daty
 * @param {Object} dateTimeObject - Aktualny obiekt czasu
 * @returns {Node}
 */
const Topbar = React.memo(({ dateTimeObject, setDateTimeObject }) => {
	const topbarDateFormat = 'MMMM YYYY'

	return (
		<StyledCalendarTopbar>
			<div className='o-input-datetime-topar-date'>
				{dateTimeObject.format(topbarDateFormat)}
			</div>
			<div>
				<StyledIconButton
					onClick={() => {
						setDateTimeObject(dateTimeObject.clone().subtract(1, 'month'))
					}}
				>
					<KeyboardArrowLeft className='o-input-datetime-topbar-icons' />
				</StyledIconButton>
				<StyledIconButton
					onClick={() => {
						setDateTimeObject(dateTimeObject.clone().add(1, 'month'))
					}}
				>
					<KeyboardArrowRight className='o-input-datetime-topbar-icons' />
				</StyledIconButton>
			</div>
		</StyledCalendarTopbar>
	)
})

Topbar.displayName = "Topbar";


/**
 * Komponent wyświetlający kalendarz oraz montujący go jako "Portal"
 * @param {String} value - Obecna wartość kontrolki z Formy
 * @param {Function} onSelectValue - Funkcja wywoływana przy wyborze daty z kalendarza
 * @param {Boolean} isOpen - Informacja czy menu jest otwarte
 * @param {Object} coords - Obiekt zawierający obecne współrzędne kontrolki
 * @returns {Node}
 */
function Calendar({ value, onSelectValue, isOpen, coords, onlyDate }) {
	const [dateTimeObject, setDateTimeObject] = useState(value === null ? moment() : moment(value))
	const translate = useTranslate('WebSpa/Inputs/CalendarInput')

	/// Wartość inputu - obiekt dateTimeObject - może mieć zmieniany miesiąc w TopBar,
	// używane do porównania czy aktualna data jest datą wybraną w inpucie
	const originalInputValue = value ? moment(value) : undefined;

	useEffect(() => {
		setDateTimeObject(value === null ? moment() : moment(value))
	}, [value])

	if (!isOpen || isEmpty(coords))
		return null


	const styles = { left: `${coords.left}px`, width: `${coords.width}px`, top: `${coords.top}px`, }

	return (
		<Portal>
			<div style={styles} className='o-input-datetime-wrapper' >
				<CalendarWrapper>
					<Topbar dateTimeObject={dateTimeObject} setDateTimeObject={setDateTimeObject} />
					<CalendarBody dateTimeObject={dateTimeObject} onSelectValue={onSelectValue} originalInputValue={originalInputValue}/>
					{onlyDate ? null : <StyledTimeWrapper >
						<div>{translate('time')}</div>
						<HourInput setDateTimeObject={setDateTimeObject} dateTimeObject={dateTimeObject} onSelectValue={onSelectValue} />
						<MinuteInput setDateTimeObject={setDateTimeObject} dateTimeObject={dateTimeObject} onSelectValue={onSelectValue} />
					</StyledTimeWrapper> 
					}
				</CalendarWrapper>
			</div>
		</Portal>
	)
}


/**
 * Komponent dostarczający dane z Formy do komponentu Calendar
 * @param {String} name - Nazwa kontrolki 
 * @param {Boolean} isOpen - Informacja czy menu jest otwarte
 * @param {Object} coords - Obiekt zawierający obecne współrzędne kontrolki
 * @returns {Node}
 */
function CalendarProvider({ name, isOpen, coords, onlyDate }) {
	const field = useField(name)

	const handleSelectDate = useCallback(selectedValue => {

		if (!moment(selectedValue, moment.ISO_8601, true).isValid()) { //Sprawdzamy czy wybraną wartość można parsować do formatu ISO 8601 który wysyłany jest do BE.
			console.error('Błąd! Wybrana wartość nie może być przetworzona na datę lub jej format nie jest zgodny ze standardem ISO 8601: ', selectedValue)
			return
		}

		field.handleChange(selectedValue)

	}, [])

	return <Calendar value={field.value} onSelectValue={handleSelectDate} isOpen={isOpen} coords={coords} onlyDate={onlyDate} />
}

/**
 * Komponent Kalendarza, scala kalendarz wraz z inputem oraz przekazuje w dół niezbędne wartości
 * @returns {Node}
 */

const DateTimeContent = React.memo(({ onlyDate, name, value, error, disabled, required, placeholder, hasLabel, dateFormat, handleChange }) => {
	const [isOpen, setOpen] = React.useState(false)
	const ref = useRef()
	const [coords, setCoords] = useState({})

	return (
		<>
			<ClickAwayListener onClickAway={() => {
				if (isOpen) {
					setOpen(false)
					setCoords({}) //Czyszczenie obiektu z koordynatami
				}
			}}>
				<div>
					<CalendarInput
						ref={ref}
						setCoords={setCoords}
						onlyDate={onlyDate}
						name={name}
						value={value}
						error={error}
						disabled={disabled}
						placeholder={placeholder}
						hasLabel={hasLabel}
						setOpen={setOpen}
						isOpen={isOpen}
						dateFormat={dateFormat}
						handleChange={handleChange}
						required={required}
					/>
					<CalendarProvider name={name} isOpen={isOpen} coords={coords} onlyDate={onlyDate} />
				</div>
			</ClickAwayListener>
		</>
	)
});

DateTimeContent.displayName = "DateTimeContent";

/**
 * Główny komponent kalendarza przekazujący wszystkie dane do niższych komponentów
 * @param {String} name - Nazwa kontrolki
 * @param {Boolean} hasLabel - informacja czy kontrolka ma etykietę
 * @param {function} onlyDate - Pokazuje tylko datę, używany dla typu Date 
 * @returns {Node}
 */
export default function InputDateTime({ name, hasLabel, onlyDate }) {
	const field = useField(name)
	const { error, disabled, placeholder, value, required } = field

	const dateFormat = onlyDate ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm"// Format daty prezentowany prezentowany w kontrolce
	const locale = useLocale()
	const currentLanguage = locale.currentCultureCode || window.navigator.language //Jeśli cultureCode nie jest ustawiony, ustawiamy język przeglądarki

	//Globalne ustawienie lokalizacji daty
	moment.locale(currentLanguage);

	const dateTimeObject = field.value === null ? moment().seconds(0) : moment(field.value).seconds(0) //Tylko w przypadku 'null' obiekt moment zwraca 'Invalid Date', w przypadku 'undefined' zwraca aktualną datę

	const handleInputChange = (value) => {
		field.handleChange(value)
	}

	return (
		<DateTimeContent
			onlyDate={onlyDate}
			name={name}
			value={value}
			error={error}
			disabled={disabled}
			placeholder={placeholder}
			hasLabel={hasLabel}
			dateTimeObject={dateTimeObject}
			dateFormat={dateFormat}
			handleChange={handleInputChange}
			required={required}
		/>
	)
}
//#endregion


//TODO Dopisać PropTypes

InputDateTime.defaultProps = {
	hasLabel: true,
}