import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import 'scss/App.scss'
import config from './config'
import Main from './contents/main/Main'

import { Login, TermsOfService, ResetPassword } from 'components/form/Form'
import { AppCtrlProvider, appCtrl, useAppCtrl } from 'AppCtrl'

import UserProcessAction from './contents/userProcessAction/UserProcessAction'
import ThemeProvider from './ThemaProvider'
import { createMuiTheme, ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import { ConfigurationSaver } from './configuration/ConfigurationSaver'

/* Cat Online */
import { default as CatLayout } from './contents/cat/Layout'
import CatGlobalStorageProvider from './contents/cat/store/CatGlobalStorageProvider'
import { default as CatProjectDetails } from './contents/cat/projectDetails/ProjectDetails'
import { default as CatAuthenticateTaskAccess } from './contents/cat/AuthenticateTaskAccess'
import { default as CatFetchCurrentTask } from './contents/cat/FetchCurrentTask'
import { default as CatEditor } from './contents/cat/Editor'
import Helmet from './Helmet/Helmet'
import { Css, Iterator } from './cssStatic/CssStaticLib'
import { IconMessageProvider } from 'components/AppMessages';
import { LogAppRuningEnvironemntInfo } from 'Tools/Tools'
import FileDownloadProgressDialog, { FileDownloadProgressProvider } from 'components/FileDownloadProgressDialog'

//import { Example } from 'components/zipList/Example1'
//import { Example } from 'components/positionObserver/Example3'

// Chcemy móc serializować obiekt 'Error'
if (!('toJSON' in Error.prototype))
{
	Object.defineProperty(Error.prototype, 'toJSON', {
		value: function () {
			var alt = {};
	
			Object.getOwnPropertyNames(this).forEach(function (key) {
				alt[key] = this[key];
			}, this);
	
			return alt;
		},
		configurable: true,
		writable: true
	});
}

const defMaterialTheme = {
	typography: {
		fontSize: 14,
		fontFamily: [
			'Roboto',
			'sans-serif',
		].join(','),
	},

	palette: {
		primary: {
			link: '#2b5687',
			main: '#316197',
			mainLighten: '#3f7daf',
			mainDarken: '#29436d',


			mainDarken700: '#336699',
			mainDarken800: 'linear-gradient(180deg, ' + config.upperMainMenuColor + ' 0%, ' + config.lowerMainMenuColor + ' 100%)',	

			mainDarken900: '#122438',
			mainDarken1000: '#1a314b',
			
			mainBackground: '#ffffff',
			mainAlternative: '#599ca4',
			selectedRowColor: 'rgba(26, 49, 75, 0.08)',

			error: '#A73A38',
			errorAlternative: '#963352',
			success: '#1A746B',

			warning: '#FB8C00',
			warningLighten: '#ddcb72',
			info: '#b26262',

			grey100: '#EDEDED',
			grey200: '#E4E4E4',
			grey300: '#D2D3D3',
			grey400: '#BCBBBB',
			grey500: '#9F9F9F',
			grey600: '#7C7C7C',
			grey700: '#424242',

			yellow: '#EE9611',

			buttonPrimary: '#336699',
			buttonSecondary: '#CC2131',
			buttonDefault: '#616161',
			buttonOutlinedHover: 'rgba(49, 97, 151, 0.1)',
			buttonDisabled: 'rgba(0, 0, 0, 0.26)'
		},
		secondary: {
			light: '#336699',
			main: '#336699',
			white: '#fff',
			black: '#000'
		},
	},
}

const materialTheme = createMuiTheme(defMaterialTheme)

const thema = {
	...defMaterialTheme.palette.primary,
	...defMaterialTheme.palette.secondary
}

Css.add({
	'body': ['m-0', 'p-0', {
		'& *': { 'box-sizing': 'border-box' },
		'background-color': '#e0e0e0',
		'font-family': 'Roboto, sans-serif',
		'height': '100%'
	}],
	'ol, ul': {
		'list-style': 'none'
	},
	'a': { 'text-decoration': 'none' },
	':root': {
		...Iterator.fromObject(thema).toObject((res, [key, value]) => {
			res['--o-' + key] = value
		}),
		'--o-font-primary': 'Roboto'
	},
	...Iterator.fromObject(thema)
		.toObject((res, [key, value]) => {
			res[`.text-${key}`] = { 'color': `var(--o-${key})` }
			res[`.${key}`] = { 'background-color': `var(--o-${key})` }
			res[`.br-${key}`] = { 'border-color': `var(--o-${key})` }
		}),
	'.f-primary': { 'font-family': 'var(--o-font-primary)' }
})

//Dodanie styli CssStaticLib
Css.addStyle()

function Contents() {
	const appCtrlState = useAppCtrl()
	const currentLanguage = appCtrlState.locale && appCtrlState.locale.currentCultureCode
	const organizationName = appCtrlState.session && appCtrlState.session.currentOrganizationInfo ?
	appCtrlState.session.currentOrganizationInfo.name : null

	

	if (!appCtrlState.session.isAuthorized) {
		const path = window.location.pathname
		let targetUrl = ''
		if (path && path !== '/') {
			targetUrl = `?redirect=${path}`
		}
		return (
			<Redirect to={`/login${targetUrl}`} />
		)
	}
	else if(!appCtrlState.session.termsOfServiceHasBeenApproved)
	{
		return (
			<Redirect to={`/termsOfService`} />
		)
	}

	return (
		<Switch>
			<Route>
				<Helmet currentLanguage={currentLanguage} organizationName={organizationName} />
				<Main />
			</Route>
		</Switch>
	)
}


/**
 * Główny komponent aplikacji. 
 *
 * @returns {Node}
 */
export function App(props) {
	LogAppRuningEnvironemntInfo(config);
	
	return (
		<FileDownloadProgressProvider>
		<AppCtrlProvider>
			<IconMessageProvider>
				<ConfigurationSaver url='/configuration' delay={2000} />
				<ThemeProvider>
					<MaterialThemeProvider theme={materialTheme}>
						<SnackbarProvider
							maxSnack={5}
							hideIconVariant={false}
							anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
							transitionDuration={{ enter: 400, exit: 600 }}
						>
							<Router>
								<Switch>
									<Route exact path={["/login", "/login/:organization"]} render={(props) => {
										return (
											<React.Fragment>
												<Helmet />
												<Login {...props} />
											</React.Fragment>
										)
									}} />
									<Route exact path="/termsOfService" render={(props) => {
										return (
											<React.Fragment>
												<Helmet />
												<TermsOfService {...props} />
											</React.Fragment>
										)
									}} />
									<Route exact path="/resetPassword" render={(props) => {
										return (
											<React.Fragment>
												<Helmet />
												<ResetPassword {...props} />
											</React.Fragment>
										)
									}} />
									<Route exact path="/userprocessactions/:organizationId/:microserviceId/:token" component={UserProcessAction} />
									<Route path="/cat">
										<CatGlobalStorageProvider>
											<CatLayout>
												<Route exact path="/cat/auth/token/:token" component={CatAuthenticateTaskAccess} />
												<Route exact path="/cat/auth/task" component={CatFetchCurrentTask} />
												<Route exact path="/cat/task/editor" component={CatEditor} />
												<Route exact path="/cat/task/editor/document/:documentId" component={CatEditor} />
												<Route exact path="/cat/task" component={CatProjectDetails} />
											</CatLayout>
										</CatGlobalStorageProvider>
									</Route>
									<Route >
										<Contents />
									</Route>

								</Switch>
							</Router >
							<FileDownloadProgressDialog />
						</SnackbarProvider>
					</MaterialThemeProvider>
				</ThemeProvider>
			</IconMessageProvider>
		</AppCtrlProvider>
		</FileDownloadProgressProvider>
	)
}