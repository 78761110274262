import React from 'react'
import { GanttVerLine } from './GanttVerLine'
import { GanttHorLine } from './GanttHorLine'
import { GanttArrow } from './GanttArrow'

export function GanttArrowLine({ x1, y1, x2, y2, color, size, height, lineWidth, onDoubleClick }) {
	if (x2 - 2 * size < x1) {
		if (y2 - y1 < 0)
			height = -height

		return (
			<React.Fragment>
				<GanttHorLine x1={x1} y1={y1} x2={x1 + size} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
				<GanttVerLine x1={x1 + size} y1={y1} y2={y1 + height} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
				<GanttHorLine x1={x1 + size} y1={y1 + height} x2={x2 - size} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
				<GanttVerLine x1={x2 - size} y1={y1 + height} y2={y2} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
				<GanttHorLine x1={x2 - size} y1={y2} x2={x2} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
				<GanttArrow x={x2 - 2 * lineWidth} y={y2} cx={3 * lineWidth} cy={3 * lineWidth} color={color} onDoubleClick={onDoubleClick} />
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<GanttHorLine x1={x1} y1={y1} x2={x1 + size} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
			<GanttVerLine x1={x1 + size} y1={y1} y2={y2} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
			<GanttHorLine x1={x1 + size} y1={y2} x2={x2} lineWidth={lineWidth} color={color} onDoubleClick={onDoubleClick} />
			<GanttArrow x={x2 - 2 * lineWidth} y={y2} cx={3 * lineWidth} cy={3 * lineWidth} color={color} onDoubleClick={onDoubleClick} />
		</React.Fragment>
	)
}