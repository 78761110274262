import { css } from 'styled-components'

/**
 * Pobieramy wartość z values przez scieżkę path 
 * @param {*} values 
 * @param {*} path 
 * @returns {*} znaleziona wartość lub undefined
 */
function _getValueFromPath(values, path) {
	for (; ;) {
		if (!path.length)
			return values

		let name = path.pop()

		if (name in values) {
			values = values[name]
			continue
		}

		return
	}
}
/**
 * Pobieramy wartość z values przez scieżkę path.
 * Jeśli nie znajdziemy to usuwamy pierwszy element z path i probujemy drugi raz
 * @param {*} values 
 * @param {*} path 
 * @returns {*} znaleziona wartość lub undefined
 */
function getValueFromPath(values, path) {
	let orgPath=path
	path=[...path]

	let res=_getValueFromPath(values,path)

	if(res)
		return res

	path=orgPath
	path.pop()
	return _getValueFromPath(values,path)
}

/**
 * Pobranie wartości z tematu. Nie umieszcza rezultatu w css``
 * @param {object} props Powinien zawierać theme w którym jest temat
 * @param {string} name Scieżka do pobierania wartości z tematu
 * @returns {*} Resultat poszukiwania
 */
export function themeValue(props, name) {
	if (!props.theme)
		return
	if (typeof (name) !== 'string')
		return name

	const path = name.split('.').reverse()
	const value = getValueFromPath(props.theme, path)
	return value
}

/**
 * Pobiera wartość z tematu
 * @param {string} name  Scieżka do wartości w temacie. Powinna być stringiem z kropkami. Np: 'Button.color.secondery'
 * 	Funkcja szuka w temacie Button -> color -> secondary. Jeśli tam nie znajdzie probóje w color->secondary itd.   
 * @param {function} mod Opcionalny parametr. funkcja modyfikujaca resultat przed zwróceniem go. Powinno się go używać tylko dla zwaracanych wartości nie będących css``.
 * @returns {*} Resultat poszukiwania zawsze jest umieszczany w css`` 
 */
export function ThemeValue(name, mod) {
	return css`${props => {
		if (mod)
			return mod(themeValue(props, name))
		return themeValue(props, name)
	}}`
}