import React from 'react'
import { useForm } from 'components/form/Form'
import Button from './Button'
import { ButtonAddNewFileVersion } from './ButtonAddNewFileVersion'

import { PasswordChange } from 'components/form/Form'
import { CreateIdentity } from 'components/form/Form'
import { GlobalStrings } from 'GlobalStrings';

import { ButtonDownloadFile } from './ButtonDownloadFile/DownloadFileButtons';
import { concat } from 'lodash'
import FormMetaObject, { EDIT_MODE_MODAL_UPA } from 'components/form/classes/FormMetaObject'
import { BackendButtons } from 'model/ButtonsDefs';

function _getVisibleButtonsForNonListView(form) {

	if (!form.data)
		return [];

	let data = form._data?.data
	if (data.length === 0 || !form._data.meta.buttons)
		return []

	let links = data[0].links

	if (!links)
		return []

	if (form.isChanged && !form.isOk)
		return []

	const allButtonsObject = { ...data[0].meta.buttons, ...form._data.meta.buttons }

	return Object.values(allButtonsObject)
		.filter(button => {
			switch (button.uiAction) {
				//Gdy przycisk ma uiAction = 'ClientAction' nie szukamy go w linkach, tylko od razu zwracamy.
				//'ClientAction' oznacza specjalny rodzaj przycisku którego kliknięcie wykonuje akcje wyłącznie po stronie FE (client side only)
				case 'ClientAction':
					if(button.name === "ButtonExport")
						return false;

					return true
				case 'ZipList':
					return false
			}

			return (
				button.name in links &&
				((form.isChanged && button.updateAllowed) || !form.isChanged)
			)
		})
		.sort((a, b) => a.sequenceIndex > b.sequenceIndex)
}

function _getVisibleButtonsForListView(form) {
	const marksData = form.rowManager.marksOrSelectedDatas

	if (!marksData.data.length)
		return []

	return Object.values({ ...marksData.meta?.buttons, ...(marksData.data.length === 1 ? marksData.data[0]?.meta?.buttons : {}) })
		.filter(button => {
			switch (button.uiAction) {
				//Gdy przycisk ma uiAction = 'ClientAction' nie szukamy go w linkach, tylko od razu zwracamy.
				//'ClientAction' oznacza specjalny rodzaj przycisku którego kliknięcie wykonuje akcje wyłącznie po stronie FE (client side only)
				case 'ClientAction':
					if(button.name === "ButtonExport")
						return false;

					return true
				case 'ZipList':
					return false
			}

			if (button.updateAllowed)
				return false

			return !marksData.data.some(row => !row.links || !(button.name in row.links))
		})
		.sort((a, b) => a.sequenceIndex > b.sequenceIndex)
}

function getVisibleButtons(form, isList) {
	if (isList)
		return _getVisibleButtonsForListView(form)

	//Komentujemy - chcemy aby zachowanie było takie same w przypadku listy 
	// jak i obiektu - guzik niech sie pojawi na liscie i w obiekcie - nawet gdy jest blad z BE
	/*if (!form.isOk) {
		console.warn("Remove listed issues to make button appear: ", form.errors)
		return []
	}*/

	return _getVisibleButtonsForNonListView(form)
}



const hardCodedButtonsInChildFromParent = ["ButtonInquirySend","ButtonTaskFinished"];
/**
 * Znajdź listę zahardokodowanych guzików z rodzica, które mają pojawić się w dziecku 
 * @param {object} form 
 */
const getHardCodedVisibleParentButtons = (form, isList) => {

	/// Kiedy mamy tryb tworzenia nowego obiektu lub zedytowano to nie chcemy aby pokazały sie guziki z rodzica
	if (form.editMode === 'create' || form.isChanged)
		return [];

	let parentButtons = [];
	if (!isList && form instanceof FormMetaObject && form?.parentForm?.parentForm) {
		parentButtons = getVisibleButtons(form?.parentForm?.parentForm, false);
	} else if (form.parentForm) {
		parentButtons = getVisibleButtons(form.parentForm, false);
	}

	parentButtons = parentButtons.filter(b => hardCodedButtonsInChildFromParent.find(el => el === b.name));

	return parentButtons;
}; 

export function isUpdateAllowedButton(form) {
	return _getVisibleButtonsForNonListView(form).some(b => b.updateAllowed)
}

/**
 * Funkcja która na podstawie metody getVisibleButtons zwraca przyciski z BE które aktualnie powinny pojawić się w naszej formie.
 * @returns Przyciski typu Default, wykonujący akcję asyncSubmit na aktualnej formie
 */
export function ButtonsBE({ isList, part }) {

	const form = useForm(['data', 'loading'])
	const selectedForm = isList ? form.getChildFormById(form.rowManager.selectedRowId) : form
	const buttons = getVisibleButtons(form, isList)


	/*
	 * Guziki, które przynależą do aktualnego obiektu
	 */
	const buttonsFromCurrentObject = buttons.map(button => {

		// W przypadku kiedy mamy guziku z oknem modalnym i kilka zaznaczonych wierszy nie chcemy aby ten guzik się wyświetlił
		if (isList && button.uiAction === GlobalStrings.displayModalUpaButtonuiActionType 
				&& button.name !== BackendButtons.ButtonCreateSupplierinvoice
				&& form?.rowManager?.marks?.length > 1)
			return null;

		//W przypadku kiedy mamy guzik z shardokodowanymy danymi do pobrania plików to zwracamy guzik do pobrania plików
		//Nie pozwalamy na obsługę tego guzika kiedy mamy zaznaczonych parę rekordów w tabeli
		if (button.uiAction === GlobalStrings.downloadFilesButtonuiActionType) {
				if (form?.rowManager?.marks.length === 0 || !isList)
					return <ButtonDownloadFile hardcodedDownloadButtonMeta={button} />;
				else
					return null;
		}

		switch (button.name) {
			case BackendButtons.CreateOCRFile:
			case BackendButtons.AddNewFileVersion:
			case BackendButtons.AddResultFile:
			case BackendButtons.AddDTPFile:
			case BackendButtons.AddReturnPackageFile:
			case BackendButtons.AddTradosProjectFile:
			case BackendButtons.AddTradosProjectTemplateFile:
			case BackendButtons.AddNewFinalFileVersion:
				return <ButtonAddNewFileVersion key={button.name} button={button} form={selectedForm} part={isList ? part : part.parent} />

			case BackendButtons.ChangePassword: {
				if (isList)
					return null
				const passwordChangeLink = form.getLink(button.name)
				return <PasswordChange key={button.name} passwordChangeLink={passwordChangeLink?.href} />
			}
			case BackendButtons.CreateIdentity: {
				if (isList)
					return null
				const createIdentityLink = form.getLink(button.name)
				const personId = form.id
				return <CreateIdentity key={button.name} createIdentityLink={createIdentityLink?.href} personId={personId} />
			}

			default: {
				return (
					<Button
						type="submit"
						id={`${form._dataType ?? ''}-${button.name}`}
						key={button.name}
						button={button}
						content={button.label}
						onClick={e => { form.asyncSubmit(button.name); e.stopPropagation(); }}
					/>
				)
			}
		}
	});
	
	let buttonsFromParentDef = [];
	if (form.editMode !== EDIT_MODE_MODAL_UPA) {
		buttonsFromParentDef = getHardCodedVisibleParentButtons(form, isList);
	}

	/*
	 * Guziki, które przynależą do rodzica aktualnego obiektu
	 */
	const buttonsFromParent = buttonsFromParentDef.map(button => {
		return (
			<Button
				type="submit"
				id={`${form._dataType ?? ''}-${button.name}`}
				key={button.name}
				button={button}
				content={button.label}
				onClick={e => {
					if (!isList && form instanceof FormMetaObject && form?.parentForm?.parentForm) {
						form.parentForm.parentForm.asyncSubmit(button.name);
					} else if (form.parentForm) {
						form.parentForm.asyncSubmit(button.name);
					}
					e.stopPropagation();
				}}
			/>
		);
	});

	return concat(buttonsFromCurrentObject, buttonsFromParent);
}