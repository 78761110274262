import React from 'react'
import PropTypes from 'prop-types'
import Button from '../list/components/buttons/Button'
import { translate } from 'locale/Locale';
import { useField } from 'components/form/Form';

/**
 * Komponent typu guzik. Po kliknięciu otwiera on stronę z linkiem w nowym oknie
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @returns {Node}
 */
export default function ButtonLink({ name, hasLabel, icon, ...other }) {
	const field = useField(name);
	const label = translate('WebSpa/MainMenu/JaspersoftReports/openReportTitle');
	
	if (!field.value)
		return <div/>;

	return <Button type='submit' content={label} onClick={e => {
			window.open(field.value, '_blank', 'noopener,noreferrer');
	}}/>;
}

ButtonLink.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool
}

ButtonLink.defaultProps = {
	hasLabel: true,
	icon: false,
}