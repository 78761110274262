import React, { createContext, useContext, useState } from 'react'
import Input from '../Input/Input'
import PropTypes from 'prop-types'
import { InputAdornment, Dialog, Typography, Divider } from '@material-ui/core'
import { StyledIcon, StyledTooltip, StyledDialogContent, StyledDialogTitle, CloseIcon } from './InputNIPStyles'
import Icon from 'components/icon/Icon'
import { useField } from 'components/form/Form'
import { FormMetaObject, Fields, } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import Preloader from '../../preloader/Preloader'
import { trimStart } from 'lodash'
import { useTranslate } from 'locale/Locale'

const InputNipContext = createContext({})//Tworzenie kontekstu dla kontrolki z NIPem
/**
 * Komponent Modala, 
 * Wysyła po kliknięciu w ikonkę request do BE i zwraca pola z nazwą oraz adresem szukanej po NIPie firmy
 */
const CheckContractorModal = () => {

   const contextData = useContext(InputNipContext)
   const { name, open, setOpen } = contextData
   const field = useField(name)
   const translate = useTranslate('WebSpa/InputNip')

   return (
      <Dialog fullWidth maxWidth="sm" open={open} onClose={() => setOpen(false)}>
         <FormMetaObject
            onLoad={async form => {
               await getFormRestService(`/guscompanies?taxid=${field.value}`).getOne(form, '')
            }}
         >
            <Preloader>
               <StyledDialogTitle >
                  <Typography variant="h5">{translate('modalTitle')}</Typography>
                  <Icon name="close" color="grey700" style={{cursor: 'pointer', fontSize: 30 }} onClick={() => setOpen(false)} />
               </StyledDialogTitle> 
               <StyledDialogContent>
                  <Fields />
               </StyledDialogContent>
            </Preloader>
         </FormMetaObject>
      </Dialog>
   )
}
/**
 * Komponent Kontrolki w której wpisujemy tekst. Kontrolka pozwala na walidację wprowadzonego tekstu
 */
const InputBased = () => {

   const contextData = useContext(InputNipContext)
   const { name, setOpen, hasLabel, hasIcon } = contextData
   const field = useField(name)
   const translate = useTranslate('WebSpa/InputNip')

   const hasValue = Boolean(field.value)

   const handleOnInput = (e) => {
      const currentValue = trimStart(e.target?.value)
      field.handleChange(currentValue)
   }
   
   return (
      <Input
         type='text'
         name={name}
         hasLabel={hasLabel}
         onInput={(e) => handleOnInput(e)}
         value={field.valueInput}
         error={field.error}
         InputProps={{
            endAdornment: hasIcon && (
               <StyledTooltip title={translate('tooltipText')} disableHoverListener={field.disabled} arrow>
                  <InputAdornment position="end" onClick={() => hasValue && setOpen(true)}>
                     <StyledIcon name="gus" error={field.error} disabled={Boolean(!hasValue)} />
                  </InputAdornment>
               </StyledTooltip>
            )
         }}
      />
   )
}

/**
 * Komponent kontrolki dla NIPu,
 * Łączy pozostałe komponenty i oplata je w kontekst
 *
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @param {boolean} hasIcon Czy ma ikonkę (używane do innego formatowania w listach)
 * @returns {Node}
 */
export default function InputNIP({ name, hasLabel, hasIcon }) {

   const [open, setOpen] = useState(false)

   return (
      <InputNipContext.Provider value={{ name, hasLabel, hasIcon, open, setOpen }} >
         <InputBased />
         <CheckContractorModal />
      </InputNipContext.Provider>
   )
}

InputNIP.propTypes = {
   name: PropTypes.string.isRequired,
   hasLabel: PropTypes.bool,
   hasIcon: PropTypes.bool
}

InputNIP.defaultProps = {
   hasLabel: true,
   hasIcon: true
}
