import React from 'react'
import { ListRaw } from 'components/form/components/list/components/ListRaw'
import { FormMetaArray } from 'components/form/Form'
import useForm from 'components/form/hooks/useForm'
import { useTranslate } from 'locale/Locale'
import Button from 'components/form/components/list/components/buttons/Button'
import { StyledDialogActions, StyledDialogContent } from './StyledDialogModal'
import { ColumnButton } from './ColumnButton'
import { messageColumnName } from 'components/form/classes/ColumnManager'
import ColumnVisibilityModalFormMetaArray from 'components/form/classes/ColumnVisibilityModalFormMetaArray'

export const shouldColumnBeVisibleInColumnListView = col => col?.meta?.label && col.name !== messageColumnName && !col?.meta?.onlyForFilter;
export const shouldColumnBeVisibleInFilterView = col => col?.meta?.label && col.name !== messageColumnName;
const formMettaArrayClassFactory = (params) => {
	return new ColumnVisibilityModalFormMetaArray(params);
}

export function ColumnList({ close, dialogContentId }) {
	const form = useForm('data')
	const cols = form.columnManager.columnArray
	const translate = useTranslate('WebSpa')
	console.log(form);
	let data = {
		meta: {
			attributes: {
				'FieldLabel': { 'name': 'FieldLabel', 'label': translate('FilterModal/columnName'), 'type': 'String', 'editableOnUpdate': false, 'sequenceIndex': 10, 'sections': ['user', 'list'], 'validations': [{ 'type': 'Required' }] },
				'FieldName': { 'name': 'FieldName', 'label': translate('ColumnModal/columnName'), 'type': 'String', 'editableOnUpdate': false, 'sequenceIndex': 0, 'sections': ['user'], 'validations': [] },
				'FieldVisible': { 'name': 'FieldVisible', 'label': translate('ColumnModal/visible'), 'type': 'Bool', 'editableOnUpdate': true, 'sequenceIndex': 20, 'sections': ['user', 'list'], 'validations': [] },
				'FieldMove': { 'name': 'FieldMove', 'label': translate('ColumnModal/moveRow'), 'type': 'MoveRow', 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'validations': [] },
			}
		},
		data:
			cols.filter( shouldColumnBeVisibleInColumnListView || shouldColumnBeVisibleInFilterView ).map(col => {
				return {
					id: col.name,
					attributes: {
						'FieldLabel': col.meta.label,
						'FieldName': col.meta.name,
						'FieldVisible': col.visible
					},
					links: {},
					meta: {}
				}
			})
	}

	return (
		<FormMetaArray
			editMode='edit'
			data={data}
			inlineEdition
			formMetaControllerClassFactory={formMettaArrayClassFactory}
			configurationPath='formMetaArray_columns'
			onRowCreate={async form => {

			}}
			onRowUpdate={async form => {
				form.changesToValues()
				return true
			}}
			onRowDelete={async form => {

			}}
			onCanRowCreate={form => {
				return false
			}}
			onCanRowUpdate={form => {
				return true
			}}
			onCanRowDelete={form => {
				return false
			}}

		>
			<StyledDialogContent id={dialogContentId}>
				<ListRaw />
			</StyledDialogContent>
			<StyledDialogActions>
				<ColumnButton close={close} />
				<Button
					type='cancel'
					onClick={close}
					style={{ padding: '5px 9px 4px' }}
					content={translate('Buttons/closeButton')}
				/>
			</StyledDialogActions>
		</FormMetaArray>
	)
}