import React from 'react'
import { useForm, useField } from 'components/form/Form'
import {  FieldTitleStyled } from './FieldTitleStyled'

export function FieldTitle({ name }) {
	const field = useField(name)
	const form = useForm('data')

	const setTitle = () => {
		const { currentValues: { ProductType } } = form

		if (ProductType) //Jesli jest obiekt ProductType
			return ProductType.value ? ProductType.value : field.valueInput //Jesli obiekt jest ale value jest puste, zwracamy field.valueInput

		else {
			return field.valueInput
		}
	}

	const title = setTitle()

	return (
		<FieldTitleStyled >
			{title}
		</FieldTitleStyled>
	)
}