import React from 'react'

export function GanttVerLine({ x1, y1, y2, lineWidth, color, onDoubleClick }) {
	let x = x1 - lineWidth / 2
	let y = Math.min(y1, y2)
	let width = lineWidth
	let height = Math.abs(y2 - y1) + 1

	return (
		<div
			style={{
				position: 'absolute',
				left: x + 'px',
				top: y + 'px',
				backgroundColor: color,
				width: width + 'px',
				height: height + 'px'
			}}
			onDoubleClick={onDoubleClick}
		/>
	)
}