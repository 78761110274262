import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { EditorContextState, useDispatch, useTrackedState } from '../contexts/editor/EditorContextState';
import EditorMediator from '../model/EditorMediator';
import useEditorMediator, { EditorControllerHookProps } from './useEditorMediator';
import LanguageDocument from '../model/LanguageDocument';

/** Definiuje stan edytora */
export type EditorContextStateController =
{
    /** Stan kontekstu edytora */
    state: EditorContextState,
    /** Mediator edytora */
    mediator: EditorMediator,
    /** Ustawiamy aktywny dokument edytora */
    activeDocumentChanged: (id: string) => void,
    /** Ustawiamy wybrany segment dokumentu */
    selectedTranslationUnit: (sequenceNumber: number) => void;
    
}

export function useEditor(mediatorProps: EditorControllerHookProps = null): EditorContextStateController
{
    const dispatch = useDispatch();
    const state: EditorContextState = useTrackedState();
    const mediator = useEditorMediator(mediatorProps);

    return useMemo(() => {
        return {
            state: state,
            mediator: mediator,
            activeDocumentChanged: (id: string) => {
                dispatch({
                    type: "ACTIVE_DOCUMENT_CHANGED",
                    documentId: id
                })
            },
            selectedTranslationUnit: (sequenceNumber: number) => {
                dispatch({
                    type: "SELECTED_TRANSLATION_UNIT",
                    selectedTranslationUnitNumber: sequenceNumber
                })
            }
        }
    }, [state, dispatch])
};