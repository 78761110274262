import './wdyr' // WAŻNE - Zawsze importować jako pierwsze

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App.js'

if (process.env.NODE_ENV === 'development') { //Service Worker - Inicjalizacja
   //const { worker } = require('./mocks/browser')
   //worker.start()
}

ReactDOM.render(<App />, document.getElementById('root'));