import CryptoJS from 'crypto-js'

/**
 * Szyfrowanie stringu przy pomocy klucza 
 */
export default class Crypto {
	/**
	 * Konstruktor
	 * 
	 * @param {string} key Klucz szyfrowania
	 */
	constructor(key) {
		this._key = key
	}

	/**
	 * Kodowanie
	 *
	 * @param {string} value Wartość do szyfrowania
	 * @returns {string} Wartość zaszyfrowana
	 */
	encrypt(value) {
		value = CryptoJS.AES.encrypt(value, this._key)
		value = value.toString()
		return value
	}

	/**
	 * Deszyfrowanie
	 *
	 * @param {string} value Wartość do odszyfrowania
	 * @returns {string} Wartość odszyfrowana
	 */
	decrypt(value) {
		value = CryptoJS.AES.decrypt(value, this._key)
		value = value.toString(CryptoJS.enc.Utf8)
		return value
	}
}