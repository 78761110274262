import React from 'react'
import { FormMetaObject, useForm } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import { linkManager } from 'components/LinkManager'
import { useHistory } from 'react-router-dom'
import { isArray, isEmpty } from 'lodash'
import { useTranslate } from 'locale/Locale'
import { useSnackbar } from 'notistack'
import { GlobalStrings } from 'GlobalStrings'
import { fileFieldsNamesOutsideFileTypesThatSupportsUpload } from 'components/form/components/file/File';
import { handleUploadMultipleFileFields } from 'components/form/components/file/File';

function findIndex(form) {
	const newRow = form.rowManager.rowData
	const treeParentRef = form.data.meta.treeParentRef
	const n = newRow.data[0].attributes[treeParentRef]
	if (!n)
		return
	const idParent = n.id
	const revRows = form.rows.slice().reverse()

	let index = revRows.findIndex(row => {
		const v = row.attributes[treeParentRef]

		// eslint-disable-next-line
		return v && v.id == idParent
	})

	if (index !== -1)
		return (revRows.length - index)

	// eslint-disable-next-line
	index = form.rows.findIndex(row => row.id == idParent)

	if (index !== -1)
		return index + 1
}

export default function CreatePartOuter({ part, dataType, useLinkName, children }) {
	const parentForm = useForm('data')
	const history = useHistory()
	const translate = useTranslate('WebSpa/Snackbar/UploadFile/CreatePartOuter/SnackbarMessage')
	const { enqueueSnackbar } = useSnackbar()

	return (
		<FormMetaObject
			isFormUsingServices
			configurationPath={part.dataTypePath}
			editMode='create'
			data={parentForm.rowManager.rowData}
			dataType={parentForm.rowManager.rowData.data[0].meta.dataType}
			useLinkName={useLinkName}
			onSubmit={async (form, submitType, props) => {
				if (submitType === GlobalStrings.reloadOnChangeSubmitType){
					await form.handleReloadOnChangeSubmitType(props);
					return;
				}

				if (!form.isOk)
					return

				//Obsługa przesyłania plików
				if (isArray(form.allFileFields) && !isEmpty(form.allFileFields)) {	
					await handleUploadMultipleFileFields(true, form, part, enqueueSnackbar, translate, history);
					if (fileFieldsNamesOutsideFileTypesThatSupportsUpload.some(fn => form.allFileFields.some(field => field.name === fn))) {
						if (form.errorFromService || form.errorFromBackEnd)
							return;

						handleAfterPostOperationsToSetNewRowAsSelected(form);
					}
					return;
				}

				await getFormRestService(form.serviceCreateHRef)
					.post(form)

				if (form.errorFromService || form.errorFromBackEnd)
					return

				handleAfterPostOperationsToSetNewRowAsSelected(form);
			}}
		>
			{children}
		</FormMetaObject>
	)

	function handleAfterPostOperationsToSetNewRowAsSelected(form) {
		const newIndex = findIndex(parentForm);

		parentForm.rowManager.pushRowFromRowData(newIndex);
		parentForm.rowManager.initVisibleRowsFromData();

		linkManager.handleListId(history, part.parent, form.id);
	}
}