import { AttributeMeta, ResourceObject } from '../ResourceDocument'
import { AttributeValueConverter, GridLikeAttributeValueConverter } from "../AttributeValueConverters";
import  iconv_lite from 'iconv-lite'


abstract class ResourceObjectExporter
{
    objects: Array<ResourceObject>;
    valueConverter: AttributeValueConverter;
    attributesMetadata: Array<AttributeMeta>;
    progressHandler: (percentageComplete: number) => void;
    totalSteps: number;
    currentStep: number;
    previousProgress: number;

    constructor(attributesMetadata: Array<AttributeMeta>, valueConverter: AttributeValueConverter, objects: Array<ResourceObject>) 
    {
        this.attributesMetadata = attributesMetadata;
        this.valueConverter = valueConverter;
        this.objects = objects;
        this.totalSteps = objects.length;
        this.currentStep = 0;
        this.previousProgress = 0;
    }

    abstract export(): Promise<Blob>;

    /**
     * Inkrementuje aktualnie procesowany krok. Jeśli poczyniony został % postęp prcoeswania, to ten fakt
     * jest raportowany poprzez handler progressHandler. Raportujemy tylko jeśli zmieni się wartość %.
     */
    incrementSteps() : boolean
    {
        this.currentStep++;
        const currentProgress = this.getCurrentProgress();
        if(currentProgress !== this.previousProgress)
        {
            if(this.progressHandler)
            {
                try
                {
                    this.progressHandler(currentProgress);
                }
                catch(err){}
            }
            
            this.previousProgress = currentProgress;
            return true;
        }

        return false;
    }

    getCurrentProgress() : number
    {
        return Math.round(this.currentStep / this.totalSteps * 100);
    }
}

export class ResourceObjectExporterToTsv extends ResourceObjectExporter
{
    static createDefault(attributesMetadata: Array<AttributeMeta>, objects: Array<ResourceObject>) : ResourceObjectExporterToTsv
    {
        return new ResourceObjectExporterToTsv(attributesMetadata, GridLikeAttributeValueConverter.createDefault(), objects);
    }

    readonly NewLine: string = '\n';
    readonly Separator: string = '\t';
    fileContent: string;

    constructor(attributesMetadata: Array<AttributeMeta>, valueConverter: AttributeValueConverter, objects: Array<ResourceObject>) {
        super(attributesMetadata, valueConverter, objects);
    }

    export(): Promise<Blob>
    {
        this.currentStep = 0;
        this.previousProgress = 0;
        this.fileContent = '';
        this.writeHeader();

        return this.writeRows().then((result) => {
            return new Promise<Blob>((resolve, reject) => {
                const res = new Blob([new Uint8Array(iconv_lite.encode(this.fileContent, "utf16-le", {addBOM: true}))]);
                resolve(res);
            })
        });
    }

    private writeHeader()
    {
        let header = '';
        for (const column of this.attributesMetadata) {
            header += '"' + column.label + '"' + this.Separator;
        }
        this.writeLine(header);
    }

    private writeRows()
    {
        return this.performTask(this.objects, 50, (resourceObject => {
            this.writeRow(resourceObject);
            this.incrementSteps()
        }));
    }

    private writeRow(resourceObject: ResourceObject)
    {
        if(resourceObject?.attributes == null)
            return;

        let row = '';
        for (const column of this.attributesMetadata) 
        {
            let attributeValue = '';
            if(column.name in resourceObject.attributes)
                attributeValue = this.valueConverter.convert(column, resourceObject.attributes[column.name]);

            const columnValue = attributeValue != null ? attributeValue.replace(/"/g, '""') : '';
            row += '"' + columnValue + '"' + this.Separator;
        }
        this.writeLine(row);
    }

    private writeLine(row: string)
    {
        this.fileContent += row + this.NewLine;
    }

    /**
     * Metoda umożliwia wykonanie czasochłonnej pracy na elementach zbiory items w sposób nieblokujący głównego
     * wątku. Procesowanie elementów jest dzielone na "mikrotaski", które trwają maksymalnie maxIterationTimeMs. 
     * Pomiędzy wykonaniami "mikrotasków" następuje przerwa na 10ms aby główny wątek mógł wykonać swoją pracę,
     * np. odświeżyć UI.
     * @param items Elementy do przetworzenia
     * @param maxIterationTimeMs Maksymalny czas (w milisekundach) na jedną iteracje (mikrotask),
     *  w której procesujemy wiele elementów.
     * @param processItem Metoda wykonująca prace na elementach
     * @returns 
     */
    private performTask(items: Array<any>, maxIterationTimeMs: number, processItem: (item: any) => void) : Promise<any>
    {
        return new Promise ((resolve, reject) => {
            // Indeks aktualnie procesowanego elementu. Musimy go pamiętać pomiędzy iteracjami (mikrotaskami).
            let currentItemIdx = -1;
            const iteration = () => {
                try
                {
                    // Jedną iteracje wykonujemy do momentu przekroczenia czasu maxIterationTimeMs.
                    // Jest to nasza pojedyncza iteracja (mikrotask). 
                    const startTimeMs = new Date().getTime();
                    while(new Date().getTime() - startTimeMs < maxIterationTimeMs)
                    {
                        currentItemIdx++;
                        if(currentItemIdx >= items.length)
                            break;
                        processItem(items[currentItemIdx]);
                    }
    
                    // Sprawdzamy czy mamy jeszcze elementy do przetworzenia. Jeśli nie to kończymy zadanie.
                    if(currentItemIdx >= items.length)
                    {
                        resolve(undefined);
                        return;
                    }
                }
                catch(err)
                {
                    reject(err);
                }

                // W tym miejscu kończymy iteracje. Zlecamy uruchomienie kolejnej iteracji po 10ms dając
                // wątkowi głównemu czas na wykonanie innych rzeczy, np. odświeżenie UI.
                setTimeout(iteration, 10);
            }

            iteration();
        });
    }
}