import { css } from 'styled-components'


/**
 *  Mixinka używana do ustawiania właściwości border - jako parametr można podać string lub obiekt z odpowiednimi właściwościami
 * @param {Object} border  - jeśli parametr jest obiektem można podać właściwości takie jak width, style, color oraz radius
 *                           przekazując właściwośc color z obiektu można posłużyć się nazwą koloru z tematu
 *                           przykładowe użycie dla obiektu: Border(width: '1px', style:'solid', color:'primary', radius:'10px'), przy czym wszystkie parametry oprócz kolor są opcjonalne
 * @param {String} border  - jeśli parametr jest stringiem funkcja zwraca odpowiednio zdefiniowany w temacie border przekazany jako parametr
 *                           przykład użycia: Border('primary'), 
 * 
 */
export function Border(border) {

   if (typeof border === 'object') {
      const { width = '1px', style = 'solid', color, radius = '0', } = border

      return css`
         border:  ${width} ${style} ${({ theme }) => theme.colors[color] || color} ;
         border-radius: ${radius};
     `
   }

   else if (typeof border === 'string')
      return css`border: ${({ theme }) => theme.borders[border] || border};`
}

/**
 * Mixin używany do ustawiania właściwości border-radius
 * Można posługiwać się nazwami z tematu lub bezpośrednio podać wartość w px
 * W przypadku nie podania żadnego parametru, ustawiany jest domyślny border 4px. 
 * przykład użycia: BorderRadius('10px) || BorderRadius('searchInputRadius') || BorderRadius()
 * @param {String} radius 
 */

export function BorderRadius(radius) {
   return css`
      border-radius: ${({ theme }) => theme.borders[radius] || radius || theme.borders.defaultRadius};
   `
}