import { Box, Divider, IconButton, Paper, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import Tooltip from '../Tooltip';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SidebarContentDocuments from './SidebarContentDocuments';
import localConfiguration from 'contents/cat/model/LocalConfiguration';
import { StyledSidebarButton, IconStyleDefault } from './CommonUtils';
import { useConfigurationBool } from 'contents/cat/hooks/useConfiguration';
import SidebarContentHeader from './SidebarContentHeader';
import LeftSidebarContent from './LeftSidebarContent';

const ConfigIsContentOpen = "Components:Editor:LeftSidebar:IsContentDocumentsOpen";
localConfiguration.registerDefaultBool(ConfigIsContentOpen, true);

const SidebarButton = ({label, children, onClick}) => {
    return (
        <Tooltip title={label} placement="right" arrow >
            <StyledSidebarButton onClick={onClick}>
                {children}
            </StyledSidebarButton>
        </Tooltip>
    );
};

const LeftSidebar = ({...other}) => {

    const [isContentOpen, setIsContentOpen] = useConfigurationBool(ConfigIsContentOpen);
    const onCloseContentClick = (event: any) => {
        setIsContentOpen(false);
    }
    const onOpenContentClick = (event: any) => {
        setIsContentOpen(true);
    }

    return (
        <Paper {...other} >
            <Box id="editor-left-sidebar-box-buttons" style={{paddingTop: '4px'}}>
                <SidebarButton label='Documents' onClick={onOpenContentClick}>
                    <DescriptionOutlinedIcon style={IconStyleDefault} fontSize='large' />
                </SidebarButton>
            </Box>
            {isContentOpen && 
                <LeftSidebarContent title={'Documents'} onCloseClick={onCloseContentClick}>
                    <SidebarContentDocuments />
                </LeftSidebarContent>
            }
        </Paper>

    );
};

export default LeftSidebar;