import React from 'react'
import { useDataView } from './DataViewProvider'
import PartClass from './PartClass'

export default function Part({ part, children, outerContent, innerContent, innerDecoration, ...props }) {
	const dataView = useDataView()
	const reactParts = React.Children.toArray(children)
	let refresh = false
	const isFirstRoot = !dataView.root

	//Jeśli nie zdefiniowany to jesteśmy w root'cie
	if (isFirstRoot) {
		dataView.root = part = new PartClass(dataView, undefined, props)
		//part.nextIndex = 0
		refresh = true
	}

	if (!part)
		part = dataView.root

	//Jesli brak parts to dodajemy 
	if (!part.parts) {
		let partThis = part
		part.parts = reactParts
			.map(c => {
				let { part, children, outerContent, innerContent, ...props } = c.props
				return new PartClass(dataView, partThis, props)
			})
		refresh = true
	}

	if (refresh)
		dataView.refresh()

	let nextReactPart

	if (part.next)
		nextReactPart = reactParts[part.nextIndex]

	if (!outerContent)
		outerContent = <React.Fragment></React.Fragment>

	const partKey = part.key

	if (innerContent)
		innerContent = React.cloneElement(
			innerContent,
			{ ...part.props, part, key: 'inner ' + partKey }
		)

	if (nextReactPart)
		nextReactPart = React.cloneElement(
			nextReactPart,
			{ part: part.next, key: partKey }
		)

	if (!innerDecoration) {
		if (!innerContent)//|| !part.isSelected)
			innerContent = null

		if (!nextReactPart)
			nextReactPart = null

		return (
			React.cloneElement(
				outerContent,
				{ ...part.props, part, key: 'outer ' + partKey },
				<React.Fragment>
					{[
						innerContent,
						nextReactPart
					]}
				</React.Fragment>
			)
		)
	}

	return (
		React.cloneElement(
			outerContent,
			{ ...part.props, part, key: 'outer ' + partKey },
			React.cloneElement(
				innerDecoration,
				{ part, key: partKey, child1: innerContent, child2: nextReactPart }
			)
		)
	)
}