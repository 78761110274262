
import {
    IEntityContextState,
    IEntityContextStateController,
    IEntityContextStateReadOnlyController,
    IEntity,
    Pagination
} from "./DataContextModel";

import {
    IRequestDataMappper,
    BaseRequestOptions,
    RequestOptions,
    RequestResults,
    SaveRequestOptions,
    RequestExecutionStrategy
} from "./RequestModel";

import getCatOnlineService from '../../../../services/CatOnlineService';
import CompossedDataMapper from "./CompossedDataMapper";

export function resourceObjectToEntity(resourceObject: object): IEntity
{
    return {
        __id: resourceObject['id'],
        __internalId: resourceObject['id'],
        __type: resourceObject['type'],
        ...resourceObject['attributes']
    };
}

/** Metoda nadpisuje po całości obiekty jeśli przyszły dane w odpowiedzi. */
export function overrideMergeStrategy(
    entityContextController: IEntityContextStateController,
    requestOptions: RequestOptions,
    requestResult: RequestResults,
    shouldPushToOrderSet: boolean)
{
    if(!requestResult?.ok || !requestResult?.resource?.data)
    {
        return;
    }

    for (const dataObject of requestResult.resource.data) 
    {
        let entity = resourceObjectToEntity(dataObject);
        entityContextController.setEntity(entity);

        if(shouldPushToOrderSet && !entityContextController.getEntityOrderSet().includes(entity.__id))
        {
            entityContextController.pushToOrderSet(entity.__id);   
        }
    }
}

/**
 * Metoda commituje zmiany do indeksu encji oraz nadpisuje po całości obiekty jeśli przyszły dane w odpowiedzi.
 */
export function commitAndOverrideMergeStrategy(
    entityContextController: IEntityContextStateController,
    requestOptions: RequestOptions,
    requestResult: RequestResults)
{
    entityContextController.commitAllEntitiesChanges();
    overrideMergeStrategy(entityContextController, requestOptions, requestResult, true);
}

/**
 * Metoda commituje zmiany do indeksu encji oraz nadpisuje po całości obiekty jeśli przyszły dane w odpowiedzi. 
 * Operacja wykonywana jest tylko dla tych obiketów, które istnieją w indeksie order index.
 */
export function commitAndOverrideExistingObjectsMergeStrategyForUpdate(
    entityContextController: IEntityContextStateController,
    requestOptions: RequestOptions,
    requestResult: RequestResults)
{
    entityContextController.commitAllEntitiesChanges();
    overrideMergeStrategy(entityContextController, requestOptions, requestResult, false);
}

export class ApiLoadStrategyDataMapper implements IRequestDataMappper {
    _entities: Array<IEntity>;
    _pagination: Pagination;

    constructor() {
        this._entities = [];
        this._pagination = null;
    }
    onFinishedRequest(
        contextId: string,
        requestOptions: RequestOptions,
        requestResult: RequestResults
    ) {
        for (const dataObject of requestResult.resource.data) {
            let entity: IEntity = {
                __id: dataObject.id,
                __internalId: dataObject.id,
                __type: dataObject.type,
                ...dataObject.attributes
            };
            this._entities.push(entity);
        }

        if(requestResult.resource.currentPage && requestResult.resource.pages){
            this._pagination = {
                currentPage: requestResult.resource.currentPage,
                pagesCount: requestResult.resource.pages
            };
        }
    }
    mergeWithEntityIndex(
        entityContextController: IEntityContextStateController,
        requestOptions: RequestOptions,
        requestResult: RequestResults
    ) {
        entityContextController.clearOrderSet();
        entityContextController.clearIndex();
        for (const entity of this._entities) {
            entityContextController.setEntity(entity);
            entityContextController.pushToOrderSet(entity.__id);
        }
        entityContextController.setPagination(this._pagination);

        this._entities = [];
    }
    
    createRequestPayload(entityContextController: IEntityContextStateReadOnlyController, requestOptions: RequestOptions): any {
        throw new Error("Method not implemented.");
    }
}

class ApiSaveStrategyDataMapper implements IRequestDataMappper {
    constructor() {
    }

    onFinishedRequest(
        contextId: string,
        requestOptions: RequestOptions,
        requestResult: RequestResults
    ) {

    }
    mergeWithEntityIndex(
        entityContextController: IEntityContextStateController,
        requestOptions: RequestOptions,
        requestResult: RequestResults
    ) {
        switch(requestOptions.executionStrategy)
        {
            case RequestExecutionStrategy.Update:
                commitAndOverrideExistingObjectsMergeStrategyForUpdate(entityContextController, requestOptions, requestResult);
                break;
            default: 
                commitAndOverrideMergeStrategy(entityContextController, requestOptions, requestResult);
                break;
        }
    }
    
    createRequestPayload(entityContextController: IEntityContextStateReadOnlyController, requestOptions: RequestOptions): any {
        switch(requestOptions.executionStrategy)
        {
            case RequestExecutionStrategy.Update:
            case RequestExecutionStrategy.Create:
                return this.createRequestSavePayload(entityContextController, requestOptions);
            default:
                throw new Error("Unknown execution strategy.");
        }
    }

    createRequestSavePayload(entityContextController: IEntityContextStateReadOnlyController, requestOptions: SaveRequestOptions): any {
        const changedEntites: IEntity[] = entityContextController.getEntityChanges();
        const resourceDocument = { data: [] };

        for(const entity of changedEntites) {
            const resourceObject = {
                id: entity.__id,
                type: entity.__type,
                attributes: {
                    ...entityContextController.getEntityAttributes(entity)
                }
            };

            resourceDocument.data.push(resourceObject);
        }

        return resourceDocument;
    }
}

class ApiDeleteStrategyDataMapper implements IRequestDataMappper {
    onFinishedRequest(contextId: string, options: RequestOptions, requestResult: RequestResults) 
    {
    }

    mergeWithEntityIndex(entityContextController: IEntityContextStateController, requestOptions: RequestOptions, requestResult: RequestResults) 
    {
    }
    createRequestPayload(entityContextController: IEntityContextStateReadOnlyController, requestOptions: RequestOptions): any  
    {
        return null;
    }
    
}

export default class LivoCatApiDataMapper extends CompossedDataMapper
{
    _loadStrategyMemo?: ApiLoadStrategyDataMapper;
    _updateStrategyMemo?: ApiSaveStrategyDataMapper;
    _createStrategyMemo?: ApiSaveStrategyDataMapper;
    _deleteStrategyMemo?: ApiDeleteStrategyDataMapper;

    constructor()
    {
        super();
        this._loadStrategyMemo = null;
        this._updateStrategyMemo = null;
        this._createStrategyMemo = null;
        this._deleteStrategyMemo = null;
    }

    createLoadExecutionStrategy(key: string): IRequestDataMappper 
    {
        if(this._loadStrategyMemo === null)
            this._loadStrategyMemo = new ApiLoadStrategyDataMapper();
        return this._loadStrategyMemo;
    }
    createUpdateExecutionStrategy(key: string): IRequestDataMappper 
    {
        if(this._updateStrategyMemo === null)
            this._updateStrategyMemo = new ApiSaveStrategyDataMapper();

        return this._updateStrategyMemo;
    }
    createCreateExecutionStrategy(key: string): IRequestDataMappper 
    {
        if(this._createStrategyMemo === null)
            this._createStrategyMemo = new ApiSaveStrategyDataMapper();

        return this._createStrategyMemo;
    }
    createDeleteExecutionStrategy(key: string): IRequestDataMappper 
    {
        if(this._deleteStrategyMemo === null)
            this._deleteStrategyMemo = new ApiDeleteStrategyDataMapper();

        return this._deleteStrategyMemo;
    }
}

export const performApiRequest = async (user, requestMethod, endpointName, endpointArguments) => {
	const requestResult: RequestResults = {
		resource: null,
		ok: false,
		status: -1,
		method: requestMethod,
		errorMessage: null
	};

	try
	{
		let catOnlineServiceConfig = {
			authToken: undefined
		};

		if (user.isLoggedIn) {
			catOnlineServiceConfig.authToken = user.accessToken;
		}

		const catOnlineService = getCatOnlineService(catOnlineServiceConfig);
		const result = await catOnlineService[endpointName](...endpointArguments);
		requestResult.ok = result.ok;
		requestResult.status = result.status;
		requestResult.resource = result.payload;
	}
	catch(error){
		requestResult.errorMessage = error;
	}

	return requestResult;
};