import React, { Dispatch, SetStateAction } from 'react'
import localConfiguration from "../model/LocalConfiguration";

export function useConfigurationStr(key: string): [string, Dispatch<SetStateAction<string>>]  {
    const [value, setValue] = React.useState(localConfiguration.getValueString(key));
    const setValueConfig = (value: string) => {
        localConfiguration.setValueString(key, value);
        setValue(value);
    };

    return [value, setValueConfig];
};

export function useConfigurationBool(key: string): [boolean, Dispatch<SetStateAction<boolean>>]  {
    const [value, setValue] = React.useState(localConfiguration.getValueBool(key));
    const setValueConfig = (value: boolean) => {
        localConfiguration.setValueBool(key, value);
        setValue(value);
    };

    return [value, setValueConfig];
};

export function useConfigurationNumber(key: string): [number, Dispatch<SetStateAction<number>>] {
    const [value, setValue] = React.useState(localConfiguration.getValueNumber(key));
    const setValueConfig = (value: number) => {
        localConfiguration.setValueNumber(key, value);
        setValue(value);
    };

    return [value, setValueConfig];
};