import {useObservableContext} from '../../observableContext/ObservableContaxt'

/**
 * Hook powiadomienia o zmianie w formie
 *
 * @param {string or Array[string]} name na jakie zdarzenia ma być powiadomiony komponet
 * @returns {Node}
 */
export default function useForm(eventName) {
	const form=useObservableContext(eventName)

	return form
}