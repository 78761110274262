import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'

/**
 * 
 * @param {Object} form - Aktualna forma na której pracujemy 
 * @param {String} section - Nazwa sekcji do której mają należeć szukane pola 
 * @param {String} sectionNot Nazwy sekcji nie widocznych
 * @returns Tablica pól należących do podanej sekcji 
 */
export const getSectionFields = (form, section, sectionNot) => {
   if(isEmpty(form)){
      console.error("Nie przekazano obiektu `form` bądź obiekt jest pusty!")
      return []
   }
   else { 
      const metas = form.metas
      const editMode = form.editMode
      let fields = Object.values(metas)

      fields = fields.filter(field => {
         let result = true;
         if(section)
         {
            if (field === null || !field.name || isEmpty(field.sections))
               result = false;
            else
               result = includes(field.sections, section);
         }
         
         if(!result)
            return result;

         if(sectionNot)
         {
            if (isEmpty(field.sections))
               result = false
            else
               result = !includes(field.sections, sectionNot);
         }

         if(!result)
            return result;

         result = !form.shouldExcludeFieldFromRendering(field);
         return result;
      });
      
      if (editMode === 'create')
         fields = fields.filter(field => field.visibleOnCreate)
   
      return  fields.sort((a, b) => ((a.sequenceIndex ? a.sequenceIndex : 0) - (b.sequenceIndex ? b.sequenceIndex : 0)))   
   
   }
}
