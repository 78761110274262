import React from 'react'
import PropTypes from 'prop-types';
import SegmentViewModel from './model/SegmentViewModel'
import SegmentElementViewModel from './model/SegmentElementViewModel'
import './Segment.css'
import EditorWithTrackingMode from "./editorsBasedOnDraftjs/editorWithTrackingMode/editorWithTrackingMode";
//import EditorWithoutTrackingMode from "./editorsBasedOnDraftjs/editorWithoutTrackingMode/editorWithoutTrackingMode";


export default function Segment({ 
	segmentObject, sourceSegmentObject, onSegmentSelected, allowedTags, trackingMode, isReadOnly, 
	onSegmentChange, onKeyDown, onSegmentBlur,
	editorEventSubscription,
	className }) {

	const onSegmentSelectedInternal = () => {
		if (onSegmentSelected) {
			onSegmentSelected()
        }
	}

	return (
		<div>
			<EditorWithTrackingMode
				sourceSegmentObject={sourceSegmentObject}
				segmentObject={segmentObject}
				onSegmentChange={onSegmentChange}
				allowedTags={allowedTags}
				readOnly={isReadOnly}
				trackingMode={trackingMode}
				onSelect={onSegmentSelectedInternal}
				onBlur={onSegmentBlur}
				onKeydown={onKeyDown}
				editorEventSubscription={editorEventSubscription}
			/>

		</div>
		)
}

Segment.propTypes = {
	segmentObject: PropTypes.instanceOf(SegmentViewModel).isRequired,
	sourceSegmentObject: PropTypes.instanceOf(SegmentViewModel),
	allowedTags: PropTypes.arrayOf(SegmentElementViewModel)
}
