import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'
import React from 'react'
import DV from '../../../components/dataView/DataView'
import CreatePartInner from './CreatePartInner'
import CreatePartOuter from './CreatePartOuter'

function InnerDecoration({ child1, child2 }) {
	if (!child1)
		return null

	return <div className='layout-part'>
		{child1}
		{child2}
	</div>
}

export default function CreatePart({ part, name, title, dataType, useLinkName, createEditForm, polymorphic }) {
	return (
		<ErrorBoundary>
			<DV.Part
				part={part}
				name={name}
				title={title}
				outerContent={<CreatePartOuter useLinkName={useLinkName} dataType={dataType} />}
				innerContent={<CreatePartInner createEditForm={createEditForm} polymorphic={polymorphic} />}
				innerDecoration={<InnerDecoration />}
			>
			</DV.Part>
		</ErrorBoundary>
	)
}
