import React from "react"
import PropTypes from "prop-types"
import { CardBorder } from './Accordion.styled'


/**
 * Komponent karty akordeonu
 *
 * @method AccordionCard
 * @param {boolean} isVisible Czy karta jest widoczna
 * @param {Node} children Zawartość karty
 * @returns {Node}
 */
export default function AccordionCard({ isVisible, children }) {
	if (!isVisible)
		return null

	return (
		<CardBorder >
			{children}
		</CardBorder>
	)
}

AccordionCard.propTypes = {
	isVisible: PropTypes.bool,
	children: PropTypes.node
}

AccordionCard.defaultProps = {
	isVisible: true
}
