import React from 'react'
import Icon from 'components/icon/Icon'
import { Check } from '@material-ui/icons'
import moment from 'moment'
import SegmentGroup from 'components/form/components/segmentGroup/SegmentGroup'
import { trimEnd } from 'lodash'
import Button, { ButtonsFactoryClass } from '../buttons/Button'	
import { translate } from 'locale/Locale'
import { GlobalStrings } from 'GlobalStrings'

export const cellFactory = {

	Empty: function(field){
		return ""
	},

	Bool: function (field) {
		return field.valueInput && <Check style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} />
	},
	String: function (field) {
		switch (field.meta.uiInputType) {
			case 'TextArea':
				const res = [];

				trimEnd(field.valueInput).split('\n').forEach((line, index) => {
					res.push(line)
					res.push(<br key={index} />)
				})
				return ( <div style={{padding:"5px"}}>{res}</div> )
			case GlobalStrings.FieldUiInputTypeButtonLink: {
				return GetButtonField(field, 'WebSpa/MainMenu/JaspersoftReports/openReportTitle');
			}
			case GlobalStrings.FieldUiInputTypeButtonVideoLink: {
				return GetButtonField(field, 'WebSpa/MainMenu/TeachingMaterials/openVideo');
			}
			default:
				return field.valueInput;
		}
		function GetButtonField(field, pathToBeTranslatedAsLabel) {
			if (!field.value)
				return <div />;

			const label = translate(pathToBeTranslatedAsLabel)
			return <Button type='submit' content={label} onClick={e => {
				window.open(field.value, '_blank', 'noopener,noreferrer');
			}} />;
		}
	},
	Enum: function (field) {
		return field.valueInput
	},
	Number: function (field) {
		const { meta: { precision } } = field
		const precisionValue = precision !== undefined ? precision : 2 //Jeśli precision występuje to go zwracamy, jeśli nie zaokrąglamy do 2 miejsc

		if (typeof field.valueInput !== 'number')
			return field.valueInput
		return parseFloat(field.valueInput).toLocaleString(undefined, { minimumFractionDigits: precisionValue, maximumFractionDigits: precisionValue })
	},
	Int: function (field) {
		return field.valueInput
	},
	Ref: function (field) {
		const value = field.value

		if (value && value.value)
			return value.value

		return <div />
	},

	GuidList: function (field) {
		if (field?.value)
			return Object.values(field.value).map(v => {
				if (!v.value) {
					console.warn('Undefined value in GuidList: ', v)
					return v.id
				}
				return v.value
			}).join(', ')
	},

	FileList: function (field) {
		if (field?.value)
			return Object.values(field.value).map(v => {
				if (!v.value) {
					console.warn('Undefined value in FileList: ', v)
					return v.id
				}
				return v.value
			}).join(', ')
	},

	Date: function (field) {
		const value = field.valueInput
		if (value === '')
			return value
		const date = new Date(value)

		return moment(date).format('YYYY-MM-DD')
	},

	Timestamp: function (field) {
		const value = field.valueInput

		if (value === '')
			return value
		const d = new Date(value)
		return moment(d).format('YYYY-MM-DD HH:mm')
	},

	File: function (field) {
		const value = field.value

		if (value && value.value)
			return value.value

		return <div />
	},
	SegmentGroup: function (field) {
		return <SegmentGroup name={field.name} />
	},
	EnumList: function (field) {
		if (!Array.isArray(field.value))
			return <div />
		return field.value.map(v => v.value).join(', ')
	},
	MoveRow: function (field) {
		return (
			<div>
				<Icon name='north' list color={'primary'}
					onClick={ev => {
						field.form.parentForm.handleMoveRow(field.form.id, -1)
						ev.stopPropagation()
					}}
				/>
				<Icon name='south' list color={'primary'}
					onClick={ev => {
						field.form.parentForm.handleMoveRow(field.form.id, 1)
						ev.stopPropagation()
					}}
				/>
			</div>
		)
	}
}