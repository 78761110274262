import React from 'react'
import { useForm } from 'components/form/Form'
import { FormMetaObject } from 'components/form/Form'
import { CellGantt } from '../gantt'
import { GlobalStrings } from 'GlobalStrings'

/**
 * Rysowanie wiersza
 *
 * @param {*} { row, rowIndex, children, onClick, onDoubleClick, startColumnIndex }
 * @returns {Node}
 */
export function Row({ row, rowIndex, children, onClick, onDoubleClick, onClickTreeChild, startColumnIndex, ...other }) {
	const form = useForm('data')
	const child = React.Children.only(children)

	if (!startColumnIndex)
		startColumnIndex = 0

	let rowHeight = GetRowHeight(form?.columnManager?.visibleColumnArray);

	return (
		<FormMetaObject
			isFormUsingServices={form.isFormUsingServices}
			key={rowIndex}
			editMode={row.id ? 'edit' : 'create'}
			data={
				row.id ? form.rowManager.getDataFromId(row.id) 
					: form.rowManager.getDataFromRow(row, form._data.ui)
			}
			onSubmit={async (form, submitType, props) => {
				if (submitType === 'ganttLink') {
					await form.handleRowUpdate()
					form.parentForm.trigger('data')
					return
				}
				if (submitType === GlobalStrings.reloadOnChangeSubmitType){
					await form.handleReloadOnChangeSubmitType(props);
					return;
				}
				if (submitType === 'saveEditRow') {
					if (!form.isOk)
						return

					if (form.editMode === 'edit')
						if (await form.handleRowUpdate()) {
							form.parentForm.rowManager.editRowId = null
							form.parentForm.trigger('data')
						}

					if (form.editMode === 'create')
						if (await form.handleRowCreate()) {
							form.parentForm.rowManager.editRowId = null
							form.parentForm.rowManager.selectedRowId = form.id
							form.parentForm._rows.push(form.data.data[0])
							form.parentForm.rowManager.initVisibleRowsFromData()
							form.parentForm.trigger('data')
						}
					return
				}
			}}
		>
			{
				form.columnManager.visibleColumnArray.map((column, columnIndex) => {
					if (columnIndex < startColumnIndex)
						return null

					return React.cloneElement(child, { key: columnIndex, row, rowIndex, column, columnIndex, onClick, onDoubleClick, onClickTreeChild, rowHeight: rowHeight, ...other })
				})
			}
			<CellGantt row={row} rowIndex={rowIndex} />
		</FormMetaObject>
	)
}

/**
 * Znajdź wysokość komórek w wierszu
 *  * @param {*} visibleColumnArray Tablica zawierająca informacje na temat widocznych kolumn z columnManager
 */
const GetRowHeight = (visibleColumnArray) => {
	let rowHeight = undefined;

	for (let columnIndex in visibleColumnArray) {

		const fieldMeta = visibleColumnArray[columnIndex]?.meta;

		if (fieldMeta === undefined)
			continue;

		if (fieldMeta?.sections?.includes('list') && fieldMeta.style !== undefined ) {
			
			if(fieldMeta.style.match(/rowHeight: auto/)) {
				return 'auto';
			}

			// TODO - nie da się na razie rozpoznać czy dane dotyczą sekcji list czy sekcji widoku obiektu 
			// W przyszłosci należy je odzielić
			/*f(rowHeight === undefined || fieldMeta.height > rowHeight){
				rowHeight = fieldMeta.height;
			}*/
		}
	}
	return rowHeight;
}
