import { compValue } from 'components/form/toolCompareValue'

export class GroupManager {
	/**
	 * Konstruktor
	 * @param {Object} form Forma do której jest podłączony ten menager grupowania
	 */
	constructor(form) {
		this._form = form
	}

	get isGroup() {
		return !!this._form.columnManager.firstGroup
	}

	/**
	 * @returns {Array} Zwraca pogrupowane wiersze z formy w formacie:
	 * {
			offsetRowIndex,	//przesuniecie indexu wiersza. Tyle trzega dodać 
									// do kazdego indexu wiersza żeby otrzymać index w form.rowManager.visibleRows
			colName,				//Nazwa kolumny po której jest grupowanie
			colIndex,			//Index kolumny po której jest grupowanie
			colValue				//Wartość przechowywana w tej kolumnie
			rows: []				//Wiersze z których powstanie grupowanie
			groups: []			//Pogrupowane wiersze w tym formacie
		}
		W root'ie dostępne tylko groups pozostałe są niezdefiniowane
	 */
	get group() {
		return {
			groups: this._groupRows(this._form.rowManager.visibleRows, 0, 0)
		}
	}

	/**
	 * Grupuję kolumny w formacie:
		  [
			{
				colName,		//Nazwa kolumny po której jest grupowanie
				colIndex,	//Index kolumny po której jest grupowanie
				colValue		//Wartość przechowywana w tej kolumnie
				rows: []		//Wiersze z których powstanie grupowanie
				groups: []	//Pogrupowane wiersze w tym formacie
			}, ...
		] 
	 * @param {*} rows Wiersze do grupowania
	 * @param {*} colIndex indeks kolumny grupującej
	 * @param {integer} offsetRowIndex przesuniecie indexu wiersza
	 * @return {array} Patrz format powyżej
	 */
	_groupRows(rows, colIndex, offsetRowIndex) {
		//Jeśli brak wierszy to nie grupujemy
		if (!rows || rows.length < 1)
			return []

		const groupArray = this._form.columnManager.groupArray
		const colName = groupArray[colIndex].name //TODO błąd

		//Łączenie wierszy w grupy
		let groups = rows.reduce((prev, row, rowIndex) => {
			const colValue = row.attributes[colName]

			if (!prev.length) {
				prev.push({
					offsetRowIndex,
					colIndex,
					colName,
					colValue: colValue,
					rows: [row]
				})
				return prev
			}

			const prevGroup = prev[prev.length - 1]

			//Jeśli poprzednia grupa i ten wiersz są zgodne
			if (compValue(prevGroup.colValue, colValue) === 0)
				//Dodajemy do grupy
				prevGroup.rows.push(row)
			else
				//Tworzymy nową grupę
				prev.push({
					offsetRowIndex: offsetRowIndex + rowIndex,
					colIndex,
					colName,
					colValue: colValue,
					rows: [row]
				})

			return prev
		}, [])

		++colIndex

		//Jeśli nie ma więcej kolumn grupujących to kończymy
		if (colIndex >= groupArray.length)
			return groups

		//Grupujemy dalej w głąb
		let _this = this
		groups.forEach(group => {
			if (group.rows.length < 2)
				return
			group.groups = _this._groupRows(group.rows, group.colIndex + 1, group.offsetRowIndex)
		})

		return groups
	}
}