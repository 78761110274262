
import { IEntityContextStateController, IEntityContextStateReadOnlyController } from "./DataContextModel";
import {
    IRequestDataMappper,
    RequestOptions,
    RequestExecutionStrategy,
    RequestResults
} from "./RequestModel";

/**
 * Implementacja mappera, która daje możliwość tworzenia mappera złożonego z różnych strategi
 * mapowania danych w zależności od strategii wykonania żądania i klucza żadania.
 */
export default abstract class CompossedDataMapper implements IRequestDataMappper
{
    /** Tworzy strategie mapowania dla żądań ładujących dane. */
    abstract createLoadExecutionStrategy(key: string): IRequestDataMappper;
    /** Tworzy strategie mapowania dla żądań aktualizujących dane. */
    abstract createUpdateExecutionStrategy(key: string): IRequestDataMappper;
    /** Tworzy stategie mapowania dla żądań tworzących dane. */
    abstract createCreateExecutionStrategy(key: string): IRequestDataMappper;
    /** Tworzy stategie mapowania dla żądań kasujących dane. */
    abstract createDeleteExecutionStrategy(key: string): IRequestDataMappper;

    /** Pobierz strategie mapowania dla podanego requestu */
    getMappingStrategyOrThrowError( requestOptions: RequestOptions ): IRequestDataMappper 
    {
        let mapperStrategy = null;

        switch (requestOptions.executionStrategy) 
        {
            case RequestExecutionStrategy.Load:
                mapperStrategy = this.createLoadExecutionStrategy(requestOptions.key);
                break;
            case RequestExecutionStrategy.Update:
                mapperStrategy = this.createUpdateExecutionStrategy(requestOptions.key);
                break;
            case RequestExecutionStrategy.Create:
                mapperStrategy = this.createCreateExecutionStrategy(requestOptions.key);
                break;
            case RequestExecutionStrategy.Delete:
                mapperStrategy = this.createDeleteExecutionStrategy(requestOptions.key);
                break;
            default:
                throw new Error("Unknown execution strategy");
        }

        if (!mapperStrategy) {
            throw new Error("Mapping strategy not implemented.");
        }

        return mapperStrategy;
    }

    onFinishedRequest(contextId: string, options: RequestOptions, requestResult: RequestResults) 
    {
        const mappingStrategy = this.getMappingStrategyOrThrowError(options);
        mappingStrategy.onFinishedRequest(contextId, options, requestResult);
    }

    mergeWithEntityIndex(entityContextController: IEntityContextStateController, requestOptions: RequestOptions, requestResult: RequestResults) 
    {
        const mappingStrategy = this.getMappingStrategyOrThrowError(requestOptions);
        mappingStrategy.mergeWithEntityIndex(entityContextController, requestOptions, requestResult);
    }

    createRequestPayload(entityContextController: IEntityContextStateReadOnlyController, requestOptions: RequestOptions): any
    {
        const mappingStrategy = this.getMappingStrategyOrThrowError(requestOptions);
        return mappingStrategy.createRequestPayload(entityContextController, requestOptions);
    }
}