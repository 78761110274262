import React from 'react'
import DV from '../../../components/dataView/DataView'
import { FormMetaArray, useForm, FormConsumer } from 'components/form/Form'
import getFormRestService from "services/getFormRestService"
import { linkManager } from 'components/LinkManager'
import config from 'config'
import { useHistory } from 'react-router-dom'
import ModalUpa from 'components/modalUpa/ModalUpa';
import { MESSAGE_ACTIONS, useIconMessageDispatch } from 'components/AppMessages'
import {BackendButtons} from 'model/ButtonsDefs';

const multiupdateButtons = [
	BackendButtons.ButtonCreateSupplierinvoice,
	BackendButtons.ButtonGenerateInvoice,
	BackendButtons.ButtonExportElixirSupplierPayments,
]

/**
 * Metoda wywoływana po kliknięciu na guzik z poleceniem
 */
async function onSubmitButtons(form, submitType, setModalButton) {
	const marksData = form.rowManager.marksOrSelectedDatas
	const buttons = marksData.meta?.buttons
	const button = buttons?.[submitType]

	if (!button)
		return
	
	if(marksData.data.length > 1 && multiupdateButtons.includes(button.name))
	{
		// multiupdate
		const link = marksData.data[0].links?.[button.name];
		if(link.params?.supportsBulk)
		{
			const href = link?.href.slice(config.serviceApiVer.length);
			const childForms = [];

			for (let i = 0; i < marksData.data.length; ++i) {
				const row = marksData.data[i];
				const childform = form.getChildFormById(row.id);
		
				if (childform) {
					const buttonIsLink = childform.onLinkButton(submitType)
					if (!buttonIsLink)			
						childForms.push(childform);
				}
					
			}
			if(childForms.length > 0)
				await getFormRestService(href).patchMulti(childForms, '')	

			return;
		}
	}

	for (let i = 0; i < marksData.data.length; ++i) {
		const row = marksData.data[i]
		const link = row.links?.[button.name]
		let href = link?.href
		href = href.slice(config.serviceApiVer.length)
		const childform = form.getChildFormById(row.id)

		if (childform) {

			if (childform?.onDisplayModalUpa(submitType, button => setModalButton(button))){
				return;
			}

			const buttonIsLink = childform.onLinkButton(submitType)
			if (!buttonIsLink)			
				await getFormRestService(href).patch(childform, '', submitType === 'ButtonPayByP24' || submitType === 'ButtonPayByP24UPA' )	
		}
			
	}
}

async function onDeleteButtons(form, submitType) {
	if (submitType !== 'delete')
		return false

	const marksData = form.rowManager.marksOrSelectedDatas

	for (let i = 0; i < marksData.data.length; ++i) {
		const row = marksData.data[i]
		let href = row.links?.delete?.href
		if (!href)
			continue
		href = href.slice(config.serviceApiVer.length)
		const childform = form.getChildFormById(row.id)

		if (childform)
			return await getFormRestService(href).deleteOne(childform)
	}
}

export default function ListPartOuter({ dataType, useCreateLinkName, useLinkName, children, part, group, sort, isGantt, inlineEditionOnly }) {
	const dataView = DV.useDataView()
	const history = useHistory()
	const parentForm = useForm('data')

	const iconMessageDispatch = useIconMessageDispatch();

	const [modalButton, setModalButton] = React.useState(undefined);

	let loadParam

	if (parentForm)
		loadParam = parentForm.id

	return (
		<FormMetaArray
			isFormUsingServices
			configurationPath={part.dataTypePath}
			enableMultiMarked
			isGantt={isGantt}
			group={group}
			sort={sort}
			dataType={dataType}
			useLinkName={useLinkName}
			useCreateLinkName = {useCreateLinkName}
			inlineEdition={inlineEditionOnly}
			onLoadParam={loadParam}
			onRowCreate={async form => {
				return await getFormRestService(form.serviceCreateHRef).post(form)
			}}
			onRowUpdate={async form => {
				return await getFormRestService(form.serviceUpdateHRef).patch(form, '')
			}}
			onRowDelete={async form => {

			}}
			onSaveEditRows={async form => {
				const mapForms = new Map()

				form.changedChildForms.forEach(f => {
					if (f.id)
						mapForms.set(f.id, f)
				})

				if (mapForms.size) {
					const forms = []
					mapForms.forEach(f => forms.push(f))

					await getFormRestService('').patches(forms)
				}

				iconMessageDispatch({
					type: MESSAGE_ACTIONS.APPEND_MESSAGE,
					payload: {
						messages: form.popObjectInfoMessages(),
						updatedDataType: form.dataType,
						messagesOwnersId: [ ...mapForms.keys() ]
					}
				});


				const newForm = form.changedChildForms.find(f => f.id === undefined)

				if (newForm) {
					await getFormRestService(newForm.serviceCreateHRef)
						.post(newForm)

					iconMessageDispatch({
						type: MESSAGE_ACTIONS.APPEND_MESSAGE,
						payload: {
							messages: newForm.popObjectInfoMessages(),
							updatedDataType: newForm.dataType,
							messagesOwnersId: [ newForm.data.data[0].id ]
						}
					});
				}
			}}
			onCanRowCreate={form => {
				return form.serviceCreate
			}}
			onCanRowUpdate={form => {
				return form.serviceUpdate
			}}
			onCanRowDelete={form => {
				return form.serviceDelete
			}}
			onLoad={async (form) => {
				part.props.form = form
				if (form.serviceReadHRef) {
					const id = linkManager.getNextPartIdToOpen(part)

					if (id && id !== 'create') {
						//Jeśli jakieś parametry znajduą się już w linku, oddzielamy je "&". Jeśli nie ma jeszcze żadnego parametru to dodajemy "?"
						const separator = form.serviceReadHRef.includes('?') ? '&' : '?'

						if (await getFormRestService(form.serviceReadHRef + `${separator}search=Id:eq:` + id).get(form)) {
							linkManager.synchronize(dataView)
						}
					} else {

						///Dodanie filtru do requestu do BE aby odfilrowac oferty w oknie ofert danego klienta
						form.filterManager.setCompanyFilterForCrmOffers(form)

						if (await getFormRestService(form.serviceReadHRef).get(form)) {					
							//synchronizacja rowData z zaznaczonym wierszem
							form.rowManager.refreshRowData()
							linkManager.synchronize(dataView)
						}
					}

					iconMessageDispatch({
						type: MESSAGE_ACTIONS.APPEND_MESSAGE,
						payload: {
							messages: form.popObjectInfoMessages(),
							updatedDataType: form.dataType
						}
					});
				}
			}}
			onSubmit={async (form, submitType, props) => {
				if (submitType === 'createFromList') {
					await getFormRestService(form.serviceCreateHRef)
						.post_createFromList(form, props.marks.map(m => { return { id: m } }))
				}
				else if (submitType === 'Filter') {
					if (props && props.length) {
						form.filterManager.values = props
						form.filterManager.setCompanyFilterForCrmOffers(form);
						await getFormRestService(form.serviceReadHRef).get(form)
					}
				}
				else if (submitType === 'saveEditRows') {
					await form.handleSaveEditRows()
					props.after()
					//W tej sytuaji opuszczamy metodę, reszta kodu jest wykonywana w onSaveEditRows
					return;
				}
				else if (submitType === 'saveRows') {
					await getFormRestService(form.serviceUpdateHRef).patches_array(form, props)
				}
				else if (submitType === 'delete' && await onDeleteButtons(form, submitType)) {
					linkManager.handleListNewPage(history, part, true)
					form.asyncLoad()
				}
				else {
					await onSubmitButtons(form, submitType, setModalButton);
				}

				const selectedRowsId = Object.values(form.rowManager.marksOrSelectedDatas.data).map(f=>f.id);
				iconMessageDispatch({
					type: MESSAGE_ACTIONS.APPEND_MESSAGE,
					payload: {
						messages: form.popObjectInfoMessages(),
						updatedDataType: form.dataType,
						messagesOwnersId: selectedRowsId
					}
				});

			}}
		>
			<FormConsumer eventName='data'>
				{form => {
					if (typeof part.props.title === 'function')
						part.props.functionParentTitle = part.props.title
					if (part.props.functionParentTitle) {
						let t = part.props.title
						part.props.title = part.props.functionParentTitle(form)
						if (t !== part.props.title)
							dataView.refresh()
					}
					return null
				}}
			</FormConsumer>
			<ModalUpa modalButton={modalButton} setModalButton={setModalButton} part={part}/>
			{children}
		</FormMetaArray>
	)
}
