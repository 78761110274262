import styled from 'styled-components'
import { withStyles, Dialog } from '@material-ui/core'

///TODO style
export const StyledDialog = withStyles(() => ({
	paper: {
		minWidth: '500px',
	}
}))(Dialog)

///TODO style
export const Title = styled.h5`
   font-weight: 600;
   font-size: 25px
`

///TODO style
export const Subtitle = styled.p`
   margin-left: 10px;
   margin-bottom: 10px;
   font-size: 19px;
`

///TODO style
export const Description = styled.p`
   margin-left: 30px;
   font-size: 15px;
   font-weight:bold;
`
///TODO style
export const Key = styled.span`
    background: #35373B;
    padding: 3px 8px;
    font-size: 13px;
    font-weight: 400;
    min-width: 26px;
    height: 27px;
    margin: 4px;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.primaryBackground};
    box-shadow: 0 1px 0 ${({ theme }) => theme.colors.primaryBackground};
    text-shadow: none;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: 21px;
`

///TODO style
export const Wrapper = styled.div`
   display:flex;
   align-items:center;
   /* justify-content: space-between */
`