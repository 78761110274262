import React from 'react'
import { Tree } from './Tree'

export function Trees({ tree, treeChildren }) {
	return tree.children.map(t => {
		return (
			<Tree tree={t}>
				{treeChildren}
			</Tree>
		)
	})
}