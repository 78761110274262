export class GanttManager {
	/**
	 * Konstruktor
	 * @param {Object} form Forma do której jest podłączony ten menager Gantt'a
	 */
	constructor(form) {
		this._form = form
	}

	get isGantt() {
		return true
	}

	get isReactangleView() { return this._isRectangleView }
	set isReactangleView(v) {
		this._isRectangleView = v
		this._form.saveConfiguration()
	}

	get startTime() { return this._startTime }
	set startTime(v) { this._startTime = v }
	get endTime() { return this._endTime }
	set endTime(v) { this._endTime = v }
	get startTimeColumnName() { return this._startTimeColumnName }
	set startTimeColumnName(v) { this._startTimeColumnName = v }
	get endTimeColumnName() { return this._endTimeColumnName }
	set endTimeColumnName(v) { this._endTimeColumnName = v }

	initTimeRangeFromRows(startTimeColumnName, endTimeColumnName, toDate) {
		this.startTimeColumnName = startTimeColumnName
		this.endTimeColumnName = endTimeColumnName
		this.recalculateTimeRangeFromRows(toDate)
	}

	recalculateTimeRangeFromRows(toDate) {
		this._setTimeRange()
		const _this = this
		this._form.rows.forEach(row =>
			_this._addTimeRange(row.attributes[this.startTimeColumnName], row.attributes[this.endTimeColumnName])
		)
		if (toDate)
			this._timeRangeToDateRange()
	}

	positionFromTime(time) {
		if (!this.startTime || !this.endTime)
			return

		const begin = this.startTime.getTime()
		const end = this.endTime.getTime()
		const t = time.getTime()

		if (begin === end || !t)
			return

		return (t - begin) / (end - begin)
	}

	timeFromPosition(position) {
		if (!this.startTime || !this.endTime)
			return

		const begin = this.startTime.getTime()
		const end = this.endTime.getTime()

		if (begin === end)
			return

		return position * (end - begin) + begin
	}

	positionRangeFromRow(row) {
		let begin = row.attributes[this.startTimeColumnName]
		let end = row.attributes[this.endTimeColumnName]

		if (begin)
			begin = new Date(begin)
		if (end)
			end = new Date(end)

		if (!begin || !end)
			return

		let positionBegin = this.positionFromTime(begin)
		let positionEnd = this.positionFromTime(end)

		if (positionBegin === undefined || positionEnd === undefined)
			return

		return [positionBegin, positionEnd]
	}

	positionRangeToForm(positionBegin, positionEnd, form) {
		if (!positionBegin || !positionEnd || !form)
			return

		let tb = this.timeFromPosition(positionBegin)
		let te = this.timeFromPosition(positionEnd)

		if (tb === undefined || te === undefined)
			return

		form.handleChanges({
			[this.startTimeColumnName]: (new Date(tb)).toISOString(),
			[this.endTimeColumnName]: (new Date(te)).toISOString()
		})
	}

	_setTimeRange(startTime, endTime) {
		this.startTime = startTime ? new Date(startTime) : undefined
		this.endTime = endTime ? new Date(endTime) : undefined
	}

	_addTimeRange(startTime, endTime) {
		startTime = startTime ? new Date(startTime) : undefined
		endTime = endTime ? new Date(endTime) : undefined

		if (startTime && (!this.startTime || this.startTime > startTime))
			this.startTime = startTime

		if (endTime && (!this.endTime || this.endTime < endTime))
			this.endTime = endTime
	}

	_timeRangeToDateRange() {
		this.startTime = this._timeToDate(this.startTime)
		this.endTime = this._timeToDate(this.endTime, true)
	}

	_timeToDate(time, up) {
		if (!time)
			return

		const y = time.getFullYear()
		const m = time.getMonth()
		let d = time.getDate()
		const h = time.getHours()
		const mm = time.getMinutes()
		const s = time.getSeconds()

		if (up && (h || mm || s))
			++d

		return new Date(y, m, d)
	}

	loadConfiguration(c) {
		// if (this.isReactangleView !== undefined)
		this.isReactangleView = c.isReactangleView //Dodać walidację czy c.isReactangleView istnieje
	}

	saveConfiguration(c) {
		c.isReactangleView = this.isReactangleView
	}

}