import { IEditorEvents, nextGlobalHandlerId } from "contents/cat/model/EditorMediator";

export default class EditorController implements IEditorEvents
{
    onActiveDocumentChanged: (documentId: string) => void;

    private handlerId: number;
    private debugId: string;

    constructor()
	{
		this.handlerId = nextGlobalHandlerId();
        this.debugId = "[TUDC_" + this.handlerId + "] ";
	}
    onGoToDocumentTU(tuSequenceNumber: number)
    {

    }
    
    onGoToDocument(documentId: string)
    {
    }

    getHandlerId()
    {
        return this.handlerId;
    }

	getHandlerDebugId() 
    {
		return this.debugId;
	}
}