import React from 'react'
import PropTypes from 'prop-types'
import useForm from 'components/form/hooks/useForm'
import FormMetaArray from '../form/FormMetaArray'
import  { ComboboxRef }   from './ComboboxRef'
import { ComboboxTag } from './Combobox'
import { isArray } from 'lodash'
import { GlobalStrings } from 'GlobalStrings'

/**
 * Wrapper dla Kontrolki ComboboxRef. Oplata kontrolkę w komponent FormMetaArray 
 * potrzebny do prawidłowej komunikacji z BE oraz przekazuje w dół propsy
 * 
 * @param {String} name - Nazwa kontrolki z BE
 * @param {Boolean} hasLabel - Informacja czy posiada ona labelkę czy nie
 * @param {Function} onChange - Funkcja wywoływana przy zmianie stanu
 */

 const ComboboxMultiChoiceWrapper = ({ name, hasLabel }) => {
   const form = useForm('data')
   const field = form.getField(name)

   return (
      <FormMetaArray limit={undefined}>
         <ComboboxMultipleChoice name={name} hasLabel={hasLabel} field={field}/>
      </FormMetaArray>
   )
}

/**
 * Komponent Kontrolki Combobox pozwalający na obsługęwybranie wielu wartości na raz.
 * @param {String} name - Nazwa kontrolki z BE
 * @param {Object} field - Obiekt zawierający najważniejsze informacje o danym polu przychodzące z BE
 * @param {Boolean} hasLabel - Informacja czy posiada ona labelkę czy nie
 */

const ComboboxMultipleChoice = ({name, field, hasLabel}) => {

   const handleChange = (e, newValue) => {
      e.stopPropagation()

      //Odświeżanie formy po wybraniu wartości z inputa
      if (field.form.data?.meta?.reloadOnChangeAttributes?.includes(field.name) && newValue) {
         field.form.asyncSubmit(GlobalStrings.reloadOnChangeSubmitType, { triggeredFieldName: name });
      }

      if (isArray(newValue)) 
         field.form.handleChange(name, newValue)
   }

   return(
      <ComboboxRef 
         name={name}
         field={field}
         hasLabel={hasLabel}
         value={field?.value || []}
         multiple
         limitTags={1}
         filterSelectedOptions
         forcePopupIcon={false}
         onChange={(e, newValue) => handleChange(e, newValue)}
         renderTags={(tags, getTagProps) => (
            tags.map((tag, index) => <ComboboxTag getTagProps={getTagProps} value={tag?.value} index={index} />)
         )}
      />
   )
}

//#region Export komponentów
   export default ComboboxMultiChoiceWrapper
//#endregion  

//#region PropTypes & DefaultProps
ComboboxMultiChoiceWrapper.propTypes = {
   name: PropTypes.string.isRequired,
   hasLabel: PropTypes.bool,
}

ComboboxMultipleChoice.propTypes = {
   name: PropTypes.string.isRequired,
   field: PropTypes.object,
   hasLabel: PropTypes.bool,
}

ComboboxMultiChoiceWrapper.defaultProps = {
   hasLabel: true,
}

ComboboxMultipleChoice.defaultProps = {
   hasLabel: true,
}
//#endregion  
