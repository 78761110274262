import React from 'react'
import { Preloader } from 'components/form/Form'
import { Buttons } from 'components/form/components/list/components/buttons/Buttons'
import { Sections } from 'components/form/components/sections/Sections'
import { HorizontalPair } from 'components/topology/HorizontalPair'
import Card from 'components/card/Card'

export default function Person({ part, polymorphic }) {
	return (
		<React.Fragment>
			<Card>
				<Preloader>
					<HorizontalPair
						left={<div />}
						right={<Buttons part={part} />}
					/>
					<Sections polymorphic={polymorphic} />
				</Preloader>
			</Card>
		</React.Fragment>
	)
}
