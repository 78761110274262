import React from 'react'
import HomePart from './dataView/HomePart'
import { ModulePart } from './dataView/ModulePart'
import EditOnePart from './dataView/EditOnePart'
import ListPart from './dataView/ListPart'
import { FunctionTitle, FunctionParentTitle } from './dataView/FunctionTitle'
import QualityAssuranceList from './QualityAssuranceList'
import Company from './Company'
import { useTranslate } from 'locale/Locale'
import InternationalizationAdminPage from './InternationalizationAdminPage'
import internationalizationObject from './Internationalization.js' //Obiekt ze zmockowanymi danymi na potrzeby zakładki "Internacjonalizacja"
import { CompanyTabs, ProjectItemTabs } from './ReusablePartsCreator'
import { GlobalStrings } from 'GlobalStrings'

/**
 * Funkcja która pobiera zmockowane dane z pliku JSON w formacie Obiektu oraz zapisuje je w Formie aplikacji (Rozwiązanie dla widoku InternationalizationAdminPage)
 * @param {Object} form - Obiekt Formy z aplikacji
 */
const InternationalizationAdminPageDataLoad = async form => {
	form.clearChanges()
	form.data = internationalizationObject
	form.errorFromService = undefined
	form.trigger('data')
}

//Dodano propsa tabTitle - odpowiedzialnego za wyświetlanie tekstu w tabach. Gdy nie ma tabTitle w tabach wyświetlane jest title 

/**
 * Opis parametrów przyjmowanych przez !! ListPart !!: 
 * @param {String} dataType - typ danych zwracany przez BE
 * @param {String} useLinkName - parametr przyjmujący nową nazwę linku. Stosowany by nadpisać generowanie linku na podstawie dataType.
 * @param {Bool} polymorphic - parametr mówiący o tym czy dana pozycja zawiera pola polimorficzne czy nie
 * @param {Bool} isGantt - parametr mówiący o tym czy dana pozycja ma być wyświetlana w widoku Gantt czy nie
 * @param {String} icon - nazwa ikony z pliku NewIcons.js
 * @param {Array} group - Tablica z nazwami kolumn po których chcemy grupować tabele. Używana do inicjalizowania grupowania w konkretnych tabelach
 * @param {Array} sort - Tablica z nazwami kolumn po których chcemy sortować tabele. Używana do inicjalizowania sortowania w konkretnych tabelach
 * @param {Boolean} inlineEditionOnly - Parametr który mówi o tym czy dany part będzie jedynie w trybie edycji w wierszu. Oznacza to że nie będzie dało się przejść do 
 * zwykłego trybu 'edit', oraz edycja w wierszu dla tabeli będzie domyślnie włączona
 * 
 */

export default function Parts() {
	const translate = useTranslate('WebSpa/MainMenu')

	return (
		<HomePart>
			<ModulePart name='myTasksModule' icon='project_items' dataType='MyTasksModule' title={translate('MyTasksModule/title')} autoOpenTab={4}>
				<ListPart useObjectLinkName='Inquiry' name='myTasksInquiries' dataType='MyTasksInquiries' title={translate('MyTasksInquiries/title')} titleEdit={translate('MyTasksInquiries/titleEdit')} titleCreate={translate('MyTasksInquiries/titleCreate')} >
						<ListPart dataType='InquiryItemFile' title={translate('MyTasksInquiries/InquiryItem/InquiryItemFile/title')} titleEdit={translate('Inquiry/InquiryItem/InquiryItemFile/titleEdit')} titleCreate={translate('Inquiry/InquiryItem/InquiryItemFile/titleCreate')} />
						<ListPart useLinkName='InquiryItemReferenceFile' dataType='InquiryItemFile' title={translate('MyTasksInquiries/InquiryItem/InquiryItemReferenceFile/title')} titleEdit={translate('Inquiry/InquiryItem/InquiryItemReferenceFile/titleEdit')} titleCreate={translate('Inquiry/InquiryItem/InquiryItemReferenceFile/titleCreate')} />
						<ListPart dataType='InquiryItem' polymorphic title={translate('MyTasksInquiries/InquiryItem/title')} titleEdit={FunctionTitle(translate('Inquiry/InquiryItem/titleEdit'), 'Name')} titleCreate={translate('Inquiry/InquiryItem/titleCreate')}>
							<ListPart dataType='TranslationStat' title={translate('Inquiry/InquiryItem/TranslationStat/title')} titleEdit={translate('Inquiry/InquiryItem/TranslationStat/titleEdit')} titleCreate={translate('Inquiry/InquiryItem/TranslationStat/titleCreate')} />
						</ListPart>
				</ListPart>
				<ListPart useObjectLinkName='Offer' name='myTasksUnsentOffers' dataType='MyTasksUnsentOffers' title={translate('MyTasksUnsentOffers/title')} titleEdit={translate('MyTasksUnsentOffers/titleEdit')} titleCreate={translate('MyTasksUnsentOffers/titleCreate')} >
					<ListPart dataType='OfferItem' polymorphic title={translate('Offer/OfferItem/title')} titleEdit={FunctionTitle(translate('Offer/OfferItem/titleEdit'), 'Name')} titleCreate={translate('Offer/OfferItem/titleCreate')}>
						<ListPart dataType='InquiryItemFile' title={translate('Offer/OfferItem/InquiryItemFile/title')} titleEdit={translate('Offer/OfferItem/InquiryItemFile/titleEdit')} titleCreate={translate('Offer/OfferItem/InquiryItemFile/titleCreate')} />
						<ListPart dataType='TranslationStat' title={translate('Offer/OfferItem/TranslationStat/title')} titleEdit={translate('Offer/OfferItem/TranslationStat/titleEdit')} titleCreate={translate('Offer/OfferItem/TranslationStat/titleCreate')} />
						<ListPart name='complaint' dataType='Complaint' title={translate('Offer/OfferItem/Complaint/title')} titleEdit={translate('Offer/OfferItem/Complaint/titleEdit')} titleCreate={translate('Offer/OfferItem/Complaint/titleCreate')} >
							<ListPart dataType='ComplaintEntry' title={translate('Complaint/ComplaintEntry/title')} titleEdit={translate('Complaint/ComplaintEntry/titleEdit')} titleCreate={translate('Complaint/ComplaintEntry/titleCreate')} />
							<ListPart dataType='InquiryItemFile' title={translate('Complaint/InquiryItemFile/title')} titleEdit={translate('Complaint/InquiryItemFile/titleEdit')} titleCreate={translate('Complaint/InquiryItemFile/titleCreate')} />
						</ListPart>
					</ListPart>
				</ListPart>
				<ListPart useObjectLinkName='Offer' name='myTasksOffersAwaitingAcceptance' dataType='MyTasksOffersAwaitingAcceptance' title={translate('MyTasksOffersAwaitingAcceptance/title')} titleEdit={translate('MyTasksOffersAwaitingAcceptance/titleEdit')} titleCreate={translate('MyTasksOffersAwaitingAcceptance/titleCreate')} >
					<ListPart dataType='OfferItem' polymorphic title={translate('Offer/OfferItem/title')} titleEdit={FunctionTitle(translate('Offer/OfferItem/titleEdit'), 'Name')} titleCreate={translate('Offer/OfferItem/titleCreate')}>
						<ListPart dataType='InquiryItemFile' title={translate('Offer/OfferItem/InquiryItemFile/title')} titleEdit={translate('Offer/OfferItem/InquiryItemFile/titleEdit')} titleCreate={translate('Offer/OfferItem/InquiryItemFile/titleCreate')} />
						<ListPart dataType='TranslationStat' title={translate('Offer/OfferItem/TranslationStat/title')} titleEdit={translate('Offer/OfferItem/TranslationStat/titleEdit')} titleCreate={translate('Offer/OfferItem/TranslationStat/titleCreate')} />
						<ListPart name='complaint' dataType='Complaint' title={translate('Offer/OfferItem/Complaint/title')} titleEdit={translate('Offer/OfferItem/Complaint/titleEdit')} titleCreate={translate('Offer/OfferItem/Complaint/titleCreate')} >
							<ListPart dataType='ComplaintEntry' title={translate('Complaint/ComplaintEntry/title')} titleEdit={translate('Complaint/ComplaintEntry/titleEdit')} titleCreate={translate('Complaint/ComplaintEntry/titleCreate')} />
							<ListPart dataType='InquiryItemFile' title={translate('Complaint/InquiryItemFile/title')} titleEdit={translate('Complaint/InquiryItemFile/titleEdit')} titleCreate={translate('Complaint/InquiryItemFile/titleCreate')} />
						</ListPart>
					</ListPart>
				</ListPart>
				<ListPart useObjectLinkName='ProjectItem' name='myTasksTasks' dataType='MyTasksTasks' title={translate('MyTasksTasks/title')} titleEdit={translate('MyTasksTasks/titleEdit')} titleCreate={translate('MyTasksTasks/titleCreate')} >
					{ProjectItemTabs({useSupplierProjectItemOrder: true})}	
				</ListPart>
				<ListPart useObjectLinkName='ProjectItem' name='myTasksCurrentTasks' dataType='MyTasksCurrentTasks' title={translate('MyTasksCurrentTasks/title')} titleEdit={translate('MyTasksCurrentTasks/titleEdit')} >
					{ProjectItemTabs({useSupplierProjectItemOrder: true})}
				</ListPart>
				<ListPart useObjectLinkName='ProjectItem' name='myTasksFinishedCurrentTasks' dataType='MyTasksFinishedCurrentTasks' title={translate('MyTasksFinishedCurrentTasks/title')} titleEdit={translate('MyTasksFinishedCurrentTasks/titleEdit')} >
					{ProjectItemTabs({useSupplierProjectItemOrder: true})}
				</ListPart>
				<ListPart useObjectLinkName='ProjectItem' name='MyTasksProposedTasks' dataType='MyTasksProposedTasks' title={translate('MyTasksProposedTasks/title')} titleEdit={translate('MyTasksProposedTasks/titleEdit')} autoOpenTab={0}>
					{ProjectItemTabs({ useSupplierProjectItemOrder: true })}
				</ListPart>
			</ModulePart>

			<ModulePart name='CRMModule' icon='reportsPriceListItemCompanySupplier' dataType='CRMModule' title={translate('CRMModule/title')} autoOpenTab={0}>
				<ListPart useObjectLinkName='Company' name='AllCRM' dataType='AllCRM' title={translate('AllCRM/title')} titleEdit={translate('AllCRM/titleEdit')} autoOpenTab={0}>
					<ListPart name='crmCustomerContacts' dataType={GlobalStrings.CRMCustomerContactsDataType}  title={translate('CRM/CRMCustomerContact/title')} titleEdit={translate('CRM/CRMCustomerContact/titleEdit')} titleCreate={translate('CRM/CRMCustomerContact/titleCreate')} />
					<ListPart name='person' dataType='Person' title={translate('CRM/Person/title')} titleEdit={translate('CRM/Person/titleEdit')} titleCreate={translate('CRM/Person/titleCreate')} />
					<ListPart name='generalContacts' dataType='GeneralContacts' title={translate('CRM/GeneralContacts/title')} titleEdit={translate('CRM/GeneralContacts/titleEdit')} titleCreate={translate('CRM/GeneralContacts/titleCreate')} />
				</ListPart>
				<ListPart useObjectLinkName='Company' name='MyCRM' dataType='MyCRM' title={translate('MyCRM/title')} titleEdit={translate('MyCRM/titleEdit')} autoOpenTab={0}>
					<ListPart name='crmCustomerContacts' dataType={GlobalStrings.CRMCustomerContactsDataType}  title={translate('CRM/CRMCustomerContact/title')} titleEdit={translate('CRM/CRMCustomerContact/titleEdit')} titleCreate={translate('CRM/CRMCustomerContact/titleCreate')} />
					<ListPart name='person' dataType='Person' title={translate('CRM/Person/title')} titleEdit={translate('CRM/Person/titleEdit')} titleCreate={translate('CRM/Person/titleCreate')} />
					<ListPart name='generalContacts' dataType='GeneralContacts' title={translate('CRM/GeneralContacts/title')} titleEdit={translate('CRM/GeneralContacts/titleEdit')} titleCreate={translate('CRM/GeneralContacts/titleCreate')} />
				</ListPart>
				<ListPart name='appReportCRMContacts' dataType='AppReportCRMContacts' title={translate('CRM/AppReportCRMContacts/title')} />
				<ListPart useObjectLinkName={GlobalStrings.CRMCustomerContactsDataType} name='allCrmCustomerContacts' dataType= 'AllCrmCustomerContacts'  title={translate('CRM/AllCRMCustomerContact/title')} titleEdit={translate('CRM/CRMCustomerContact/titleEdit')}/>
			</ModulePart>

			<ListPart name='companiesTS' icon='translationEnginePref' dataType='CompaniesTS' title={translate('CompanyTS/title')} titleEdit={translate('CompanyTS/titleEdit')} titleCreate={translate('CompanyTS/titleCreate')}>
				<ListPart dataType='TranslationMemoryFile' title={translate('CompanyTS/TranslationMemoryFile/title')} titleEdit={translate('CompanyTS/TranslationMemoryFile/titleEdit')} titleCreate={translate('CompanyTS/TranslationMemoryFile/titleCreate')} />
			</ListPart>
			

			<ListPart name='inquiries' icon='inquiries' dataType='Inquiry' title={translate('Inquiry/title')} titleEdit={translate('Inquiry/titleEdit')} titleCreate={translate('Inquiry/titleCreate')}>
				<ListPart dataType='InquiryItemFile' title={translate('Inquiry/InquiryItem/InquiryItemFile/title')} titleEdit={translate('Inquiry/InquiryItem/InquiryItemFile/titleEdit')} titleCreate={translate('Inquiry/InquiryItem/InquiryItemFile/titleCreate')} />
				<ListPart useLinkName='InquiryItemReferenceFile' dataType='InquiryItemFile' title={translate('Inquiry/InquiryItem/InquiryItemReferenceFile/title')} titleEdit={translate('Inquiry/InquiryItem/InquiryItemReferenceFile/titleEdit')} titleCreate={translate('Inquiry/InquiryItem/InquiryItemReferenceFile/titleCreate')} />
				<ListPart dataType='InquiryItem' polymorphic title={translate('Inquiry/InquiryItem/title')} titleEdit={FunctionTitle(translate('Inquiry/InquiryItem/titleEdit'), 'Name')} titleCreate={translate('Inquiry/InquiryItem/titleCreate')}>
					<ListPart dataType='TranslationStat' title={translate('Inquiry/InquiryItem/TranslationStat/title')} titleEdit={translate('Inquiry/InquiryItem/TranslationStat/titleEdit')} titleCreate={translate('Inquiry/InquiryItem/TranslationStat/titleCreate')} />
				</ListPart>
			</ListPart>

			<ListPart name='offers' icon='offer' dataType='Offer' title={translate('Offer/title')} titleEdit={translate('Offer/titleEdit')} titleCreate={translate('Offer/titleCreate')}>
				<ListPart dataType='OfferCustomerFinalFiles' title={translate('Offer/OfferItem/OfferCustomerFinalFiles/title')} />			
				<ListPart dataType='OfferItem' polymorphic title={translate('Offer/OfferItem/title')} titleEdit={FunctionTitle(translate('Offer/OfferItem/titleEdit'), 'Name')} titleCreate={translate('Offer/OfferItem/titleCreate')}>
					<ListPart dataType='InquiryItemFile' title={translate('Offer/OfferItem/InquiryItemFile/title')} titleEdit={translate('Offer/OfferItem/InquiryItemFile/titleEdit')} titleCreate={translate('Offer/OfferItem/InquiryItemFile/titleCreate')} />
					<ListPart dataType='TranslationStat' title={translate('Offer/OfferItem/TranslationStat/title')} titleEdit={translate('Offer/OfferItem/TranslationStat/titleEdit')} titleCreate={translate('Offer/OfferItem/TranslationStat/titleCreate')} />
					<ListPart name='complaint' dataType='Complaint' title={translate('Offer/OfferItem/Complaint/title')} titleEdit={translate('Offer/OfferItem/Complaint/titleEdit')} titleCreate={translate('Offer/OfferItem/Complaint/titleCreate')} >
						<ListPart dataType='ComplaintEntry' title={translate('Complaint/ComplaintEntry/title')} titleEdit={translate('Complaint/ComplaintEntry/titleEdit')} titleCreate={translate('Complaint/ComplaintEntry/titleCreate')} />
						<ListPart dataType='InquiryItemFile' title={translate('Complaint/InquiryItemFile/title')} titleEdit={translate('Complaint/InquiryItemFile/titleEdit')} titleCreate={translate('Complaint/InquiryItemFile/titleCreate')} />
					</ListPart>
				</ListPart>
				<ListPart dataType='OfferPayment' title={translate('Offer/OfferPayment/title')} titleEdit={translate('Offer/OfferPayment/titleEdit')} titleCreate={translate('Offer/OfferPayment/titleCreate')} />
			</ListPart>

			<ListPart name='projects' icon='project' dataType='Project' title={translate('Project/title')} titleEdit={FunctionTitle(translate('Project/titleEdit'), 'ProjectIdent')} titleCreate={translate('Project/titleCreate')} autoOpenTab={0}>
				<ListPart dataType='ProjectItem' polymorphic title={translate('Project/ProjectItem/title')} titleEdit={FunctionTitle(translate('Project/ProjectItem/titleEdit'), 'ProductEntityRef')} titleCreate={translate('Project/ProjectItem/titleCreate')} group={['SubProjectRef']} sort={[{ name: 'SubProjectRef', dir: 1 }, { name: 'SubProjOrderIndex', dir: 1 }]}>
					{ProjectItemTabs()}
				</ListPart>
				<ListPart name='subprojects' dataType='ProjectSubproject' title={translate('Project/Subproject/title')} titleEdit={translate('Project/Subproject/titleEdit')} titleCreate={translate('Project/Subproject/titleCreate')}>
					<ListPart dataType='ProjectItem' polymorphic title={translate('Project/ProjectItem/title')} titleEdit={FunctionTitle(translate('Project/ProjectItem/titleEdit'), 'ProductEntityRef')} titleCreate={translate('Project/ProjectItem/titleCreate')} group={['SubProjectRef']} sort={[{ name: 'SubProjectRef', dir: 1 }, { name: 'SubProjOrderIndex', dir: 1 }]}>
						{ProjectItemTabs()}
					</ListPart>
					<ListPart dataType='ProjectItemFile' title={translate('Project/ProjectItem/ProjectItemFile/title')} titleEdit={translate('Project/ProjectItem/ProjectItemFile/titleEdit')} titleCreate={translate('Project/ProjectItem/ProjectItemFile/titleCreate')} />
					<ListPart useLinkName='ProjectItemReferenceFile' dataType='ProjectItemFile' title={translate('Project/ProjectItem/ProjectItemReferenceFile/title')} titleEdit={translate('Project/ProjectItem/ProjectItemReferenceFile/titleEdit')} titleCreate={translate('Project/ProjectItem/ProjectItemReferenceFile/titleCreate')} />
				</ListPart>
				<ListPart dataType='ProjectItemFile' title={translate('Project/ProjectItemFile/title')} titleEdit={translate('Project/ProjectItem/ProjectItemFile/titleEdit')} titleCreate={translate('Project/ProjectItem/ProjectItemFile/titleCreate')} />
				<ListPart useLinkName='ProjectItemReferenceFile' dataType='ProjectItemFile' title={translate('Project/ProjectItem/ProjectItemReferenceFile/title')} titleEdit={translate('Project/ProjectItem/ProjectItemReferenceFile/titleEdit')} titleCreate={translate('Project/ProjectItem/ProjectItemReferenceFile/titleCreate')} />
				<ListPart useLinkName='ProjectItemFinalFile' dataType='ProjectItemFile' title={translate('Project/ProjectItem/ProjectItemFinalFile/title')} titleEdit={translate('Project/ProjectItem/ProjectItemFinalFile/titleEdit')} titleCreate={translate('Project/ProjectItem/ProjectItemFinalFile/titleCreate')} />
			</ListPart>

			<ListPart name='complaint' icon='complaint' dataType='Complaint' title={translate('Complaint/title')} titleEdit={translate('Complaint/titleEdit')} titleCreate={translate('Complaint/titleCreate')} autoOpenTab={0}>
				<ListPart dataType='ComplaintEntry' title={translate('Complaint/ComplaintEntry/title')} titleEdit={translate('Complaint/ComplaintEntry/titleEdit')} titleCreate={translate('Complaint/ComplaintEntry/titleCreate')} />
				<ListPart dataType='InquiryItemFile' title={translate('Complaint/InquiryItemFile/title')} titleEdit={translate('Complaint/InquiryItemFile/titleEdit')} titleCreate={translate('Complaint/InquiryItemFile/titleCreate')} />
			</ListPart>

			<ListPart name='priceLists' icon='price_list' dataType='PriceList' title={translate('PriceList/title')} titleEdit={FunctionTitle(translate('PriceList/titleEdit'), 'Name')} titleCreate={translate('PriceList/titleCreate')} autoOpenTab={0}>
				<ListPart dataType='PriceListItemOrganization' title={translate('PriceList/PriceListItemOrganization/title')} titleEdit={translate('PriceList/PriceListItemOrganization/titleEdit')} titleCreate={translate('PriceList/PriceListItemOrganization/titleCreate')} />
			</ListPart>

			{/* <ListPart name='companies' icon='company' dataType='Company' title={translate('Company/title')} titleEdit={FunctionTitle(translate('Company/titleEdit'), 'ShortName')} titleCreate={translate('Company/titleCreate')} createEditForm={(part, polymorphic) => <Company polymorphic={polymorphic} />}> */}
			<ListPart name='companies' icon='company' dataType='Company' title={translate('Company/title')} titleEdit={FunctionTitle(translate('Company/titleEdit'), 'ShortName')} titleCreate={translate('Company/titleCreate')} >
				{CompanyTabs()}
			</ListPart>

			<EditOnePart useLinkName='MyProfile' name='myProfile' icon='my_profile' dataType='Company' title={translate('MyProfile/title')} createEditForm={() => <Company />}>
				{CompanyTabs('MyProfile')}
			</EditOnePart>

			<EditOnePart useLinkName='MyProfileTS' name='myProfileTS' icon='my_profile' dataType='CompaniesTS' title={translate('MyProfileTS/title')} createEditForm={() => <Company />}>
				<ListPart dataType='TranslationMemoryFile' title={translate('CompanyTS/TranslationMemoryFile/title')} titleEdit={translate('CompanyTS/TranslationMemoryFile/titleEdit')} titleCreate={translate('CompanyTS/TranslationMemoryFile/titleCreate')} />
			</EditOnePart>

			<EditOnePart useLinkName='OrganizationProfile' name='organizationProfile' icon='my_profile' dataType='Company' title={translate('OrganizationProfile/title')} createEditForm={() => <Company />}>
				{CompanyTabs()}
			</EditOnePart>

			<ListPart name='qualityAssurance' icon='quality_assurance' dataType='QualityAssurance' title={translate('QualityAssurance/title')} titleEdit={translate('QualityAssurance/titleEdit')} titleCreate={translate('QualityAssurance/titleCreate')} list={<QualityAssuranceList />} />
			<ListPart name='supplierLanguageInfo' icon='' dataType='SupplierLanguageInfo' title={translate('Company/Person/SupplierLanguageInfo/title')} titleEdit={translate('Company/Person/SupplierLanguageInfo/titleEdit')} titleCreate={translate('Company/Person/SupplierLanguageInfo/titleCreate')} />
			<ListPart name='priceListItemCompanySupplier' icon='reportsPriceListItemCompanySupplier' dataType='PriceListItemCompanySupplier' title={translate('Translators/title')} titleEdit={translate('Translators/titleEdit')} titleCreate={translate('Translators/titleCreate')} />
			
			<ListPart name='jaspersoftReports' dataType='JaspersoftReports' icon='reportsPriceListItemCompanySupplier' title={translate('JaspersoftReports/title')} />

			<ModulePart name='persons' icon='company' dataType='Persons' title={translate('Persons/title')} autoOpenTab={0}>
				<ListPart name='suppliers' dataType='Suppliers' title={translate('PersonSuppliers/title')} titleEdit={translate('PersonSuppliers/titleEdit')} />
				<ListPart name='customers' dataType='Customers' title={translate('PersonCustomers/title')} titleEdit={translate('PersonCustomers/titleEdit')} />
			</ModulePart>

			<ListPart name='invoice' icon='reportInvoice' dataType='Invoice' title={translate('Invoice/title')}>
				<ListPart dataType='InvoiceRow' title={translate('Invoice/InvoiceRow/title')} />
			</ListPart>

			<ListPart name='costInvoice' icon='reportInvoice' dataType='CostInvoice' title={translate('CostInvoice/title')}>
				<ListPart dataType='InvoiceRow' title={translate('Invoice/InvoiceRow/title')} />
			</ListPart>

			<ListPart name='provisionalInvoice' icon='reportInvoice' dataType='ProvisionalInvoice' title={translate('ProvisionalInvoice/title')}>
				<ListPart dataType='InvoiceRow' title={translate('Invoice/InvoiceRow/title')} />
			</ListPart>

			<ListPart name='prepaymentInvoice' icon='reportInvoice' dataType='PrepaymentInvoice' title={translate('PrepaymentInvoice/title')}>
				<ListPart dataType='InvoiceRow' title={translate('Invoice/InvoiceRow/title')} />
			</ListPart>

			<ListPart name='receipt' icon='reportInvoice' dataType='Receipt' title={translate('Receipt/title')}>
				<ListPart dataType='InvoiceRow' title={translate('Invoice/InvoiceRow/title')} />
			</ListPart>

			<ListPart name='correctingInvoice' icon='reportInvoice' dataType='CorrectingInvoice' title={translate('CorrectingInvoice/title')}>
				<ListPart dataType='InvoiceRow' title={translate('Invoice/InvoiceRow/title')} />
			</ListPart>

			<ListPart name='specification' icon='translationSubengine' dataType='Specification' title={translate('Specification/title')} titleEdit={translate('Specification/titleEdit')} titleCreate={translate('Specification/titleCreate')} />
	
			<ListPart useObjectLinkName='ProjectItem'  useLinkName='ProposedJob'  name='proposedJob' icon='proposed_job' dataType='ProjectItem' title={translate('proposedJob/title')} titleEdit={translate('proposedJob/titleEdit')} titleCreate={translate('proposedJob/titleCreate')} >
				{ProjectItemTabs()}
			</ListPart>
			<ListPart name='reportProfitability' icon='reportProfitability' dataType='ReportProfitability' title={translate('ReportProfitability/title')} />
			<ListPart name='reportProfitabilityGlobalDaily' icon='reportProfitability' dataType='ReportProfitabilityGlobalDaily' title={translate('ReportProfitabilityGlobalDaily/title')} />
			<ListPart name='reportProfitabilityGlobalMonthly' icon='reportProfitability' dataType='ReportProfitabilityGlobalMonthly' title={translate('ReportProfitabilityGlobalMonthly/title')} />
			<ListPart name='reportProfitabilityGlobalMonthlyLanguage' icon='reportProfitability' dataType='ReportProfitabilityGlobalMonthlyLanguage' title={translate('ReportProfitabilityGlobalMonthlyLanguage/title')} />
		    <ListPart name='reportProfitabilityGlobalMonthlyPerson' icon='reportProfitability' dataType='ReportProfitabilityGlobalMonthlyPerson' title={translate('ReportProfitabilityGlobalMonthlyPerson/title')} />
			<ListPart name='reportProfitabilityGlobalMonthlyProduct' icon='reportProfitability' dataType='ReportProfitabilityGlobalMonthlyProduct' title={translate('ReportProfitabilityGlobalMonthlyProduct/title')} />
			<ListPart name='appReportSupplierTasksToBeInvoiced' icon='reportProfitability' dataType='AppReportSupplierTasksToBeInvoiced' title={translate('AppReportSupplierTasksToBeInvoiced/title')} />
			<ListPart name='reportOfferEfficiency' icon='reportOfferEfficiency' dataType='ReportOfferEfficiency' title={translate('ReportOfferEfficiency/title')} />
			<ListPart name='reportQaEfficiency' icon='reportQaEfficiency' dataType='ReportQaEfficiency' title={translate('ReportQaEfficiency/title')} />

			<ListPart name='featureTypes' icon='featureTypes' dataType='ValueType' title={translate('ValueType/title')} titleEdit={FunctionTitle(translate('ValueType/titleEdit'), 'Name')} titleCreate={translate('ValueType/titleCreate')} autoOpenTab={0}>
				<ListPart dataType='ValueTypeEnum' title={translate('ValueType/ValueTypeEnum/title')} titleEdit={translate('ValueType/ValueTypeEnum/titleEdit')} titleCreate={translate('ValueType/ValueTypeEnum/titleCreate')} />
			</ListPart>

			<ListPart name='productTypes' icon='productTypes' dataType='Entity' title={translate('Entity/title')} titleEdit={FunctionTitle(translate('Entity/titleEdit'), 'Name')} titleCreate={translate('Entity/titleCreate')}>
				<ListPart dataType='Features' title={translate('Entity/Features/title')} titleEdit={translate('Entity/Features/titleEdit')} titleCreate={translate('Entity/Features/titleCreate')} >
					<ListPart dataType='ProductFeatureAccessRight' title={translate('Entity/Features/ProductFeatureAccessRight/title')} titleEdit={translate('Entity/Features/ProductFeatureAccessRight/titleEdit')} inlineEditionOnly />
				</ListPart>
				<ListPart dataType='ProductPricingManner' title={translate('Entity/ProductPricingManner/title')} titleEdit={translate('Entity/ProductPricingManner/titleEdit')} titleCreate={translate('Entity/ProductPricingManner/titleCreate')} />
			</ListPart>

			<ListPart name='templates' icon='templates' dataType='TemplateFile' title={translate('Templates/title')} titleEdit={translate('Templates/titleEdit')} titleCreate={translate('Templates/titleCreate')} />
			<ListPart name='group' icon='group' dataType='Group' title={translate('Group/title')} titleEdit={translate('Group/titleEdit')} titleCreate={translate('Group/titleCreate')} />
			<ListPart name='currency' icon='currency' dataType='Currency' title={translate('Currency/title')} titleEdit={translate('Currency/titleEdit')} titleCreate={translate('Currency/titleCreate')} >
				<ListPart dataType='CurrencyRate' title={translate('CurrencyRate/title')} titleEdit={translate('CurrencyRate/titleEdit')} titleCreate={translate('CurrencyRate/titleCreate')} />
			</ListPart>
			<ListPart name='translationPriceModifierCustomer' icon='vatTaxRate' dataType='TranslationPriceModifierCustomer' title={translate('TranslationPriceModifierCustomer/title')} titleEdit={translate('TranslationPriceModifierCustomer/titleEdit')} titleCreate={translate('TranslationPriceModifierCustomer/titleCreate')} />
			<ListPart name='translationPriceModifierSupplier' icon='vatTaxRate' dataType='TranslationPriceModifierSupplier' title={translate('TranslationPriceModifierSupplier/title')} titleEdit={translate('TranslationPriceModifierSupplier/titleEdit')} titleCreate={translate('TranslationPriceModifierSupplier/titleCreate')} />
			<ListPart name='customerSupplierTypePref' icon='customerSupplierTypePref' dataType='CustomerSupplierTypePref' title={translate('CustomerSupplierTypePref/title')} titleEdit={translate('CustomerSupplierTypePref/titleEdit')} titleCreate={translate('CustomerSupplierTypePref/titleCreate')} />

			<ListPart name='organizationConfiguration' icon='organizationConfiguration' dataType='Configuration' title={translate('OrganizationConfiguration/title')} titleEdit={translate('OrganizationConfiguration/titleEdit')} titleCreate={translate('OrganizationConfiguration/titleCreate')} />
			<ListPart name='vatTaxRate' icon='vatTaxRate' dataType='VatTaxRate' title={translate('VatTaxRate/title')} titleEdit={translate('VatTaxRate/titleEdit')} titleCreate={translate('VatTaxRate/titleCreate')} />
			<ListPart name='nonWorkingDays' icon='systemConfiguration' dataType='NonWorkingDays' title={translate('NonWorkingDays/title')} titleEdit={translate('NonWorkingDays/titleEdit')} titleCreate={translate('NonWorkingDays/titleCreate')} />
			<ListPart name='automaticOfferFileExtension' icon='systemConfiguration' dataType='AutomaticOfferFileExtension' title={translate('AutomaticOfferFileExtension/title')} titleEdit={translate('AutomaticOfferFileExtension/titleEdit')} titleCreate={translate('AutomaticOfferFileExtension/titleCreate')} />
			<ListPart name='automaticOfferDocumentStyleMapping' icon='systemConfiguration' dataType='AutomaticOfferDocumentStyleMapping' title={translate('AutomaticOfferDocumentStyleMapping/title')} titleEdit={translate('AutomaticOfferDocumentStyleMapping/titleEdit')} titleCreate={translate('AutomaticOfferDocumentStyleMapping/titleCreate')} />
			
			<ListPart name='SettlementCompanies' icon='company' dataType='SettlementCompanies' title={translate('SettlementCompanies/title')} titleEdit={translate('SettlementCompanies/titleEdit')} titleCreate={translate('SettlementCompanies/titleCreate')}>
				<ListPart name='person' dataType='Person' title={translate('Company/Person/title')} titleEdit={translate('Company/Person/titleEdit')} titleCreate={translate('Company/Person/titleCreate')} />
				<ListPart name='settlementCompanyBankAccounts' dataType='SettlementCompanyBankAccounts' title={translate('SettlementCompanies/SettlementCompanyBankAccounts/title')} titleEdit={translate('SettlementCompanies/SettlementCompanyBankAccounts/titleEdit')} titleCreate={translate('SettlementCompanies/SettlementCompanyBankAccounts/titleCreate')} />
			</ListPart>

			
			<ListPart name='settlementSource'  icon='currency' dataType='SettlementSource' title={translate('SettlementSource/title')} titleEdit={translate('SettlementSource/titleEdit')} titleCreate={translate('SettlementSource/titleCreate')} />
			<ListPart name='settlementDocument' icon='registryForDocuments' dataType='SettlementDocument' title={translate('SettlementDocument/title')} titleEdit={translate('SettlementDocument/titleEdit')} titleCreate={translate('SettlementSource/titleCreate')} >
				<ListPart dataType='SettlementEntry' title={translate('SettlementDocumentPayment/title')} titleEdit={translate('SettlementDocumentPayment/titleEdit')} titleCreate={translate('SettlementDocumentPayment/titleCreate')} />
			</ListPart>
			<ListPart name='payments' icon='currency' dataType='Payments' title={translate('Payments/title')} titleEdit={translate('Payments/titleEdit')} titleCreate={translate('Payments/titleCreate')} />
			<ListPart name='settlement'  icon='templates' dataType='Settlement' title={translate('Settlement/title')} titleEdit={translate('Settlement/titleEdit')} titleCreate={translate('Settlement/titleCreate')}>
				<ListPart dataType='SettlementEntry' title={translate('Settlement/SettlementEntry/title')} titleEdit={translate('Settlement/SettlementEntry/titleEdit')} titleCreate={translate('Settlement/SettlementEntry/titleCreate')} >
					<ListPart dataType='SettlementDocumentPayment' title={translate('SettlementDocumentPayment/title')} titleEdit={translate('SettlementDocumentPayment/titleEdit')} titleCreate={translate('SettlementDocumentPayment/titleCreate')} />
				</ListPart>
			</ListPart>
			<ListPart name='SettlementCompensation'  icon='templates' dataType='SettlementCompensation' title={translate('Compensation/title')} titleEdit={translate('Compensation/titleEdit')} titleCreate={translate('Compensation/titleCreate')}>
				<ListPart dataType='SettlementEntry' title={translate('Settlement/SettlementEntry/title')} titleEdit={translate('Settlement/SettlementEntry/titleEdit')} titleCreate={translate('Settlement/SettlementEntry/titleCreate')} >
					<ListPart dataType='SettlementDocumentPayment' title={translate('SettlementDocumentPayment/title')} titleEdit={translate('SettlementDocumentPayment/titleEdit')} titleCreate={translate('SettlementDocumentPayment/titleCreate')} />
				</ListPart>
			</ListPart>
			<ListPart name='CostDocument' icon='registryForDocuments' dataType='CostDocument' title={translate('CostDocument/title')} titleEdit={translate('CostDocument/titleEdit')} titleCreate={translate('CostDocument/titleCreate')} >
				<ListPart dataType='SettlementEntry' title={translate('SettlementDocumentPayment/title')} titleEdit={translate('SettlementDocumentPayment/titleEdit')} titleCreate={translate('SettlementDocumentPayment/titleCreate')} />
			</ListPart>

			<ListPart name='translationEnginePref' icon='translationEnginePref' dataType='TranslationEnginePref' title={translate('TranslationEnginePref/title')} titleEdit={translate('TranslationEnginePref/titleEdit')} titleCreate={translate('TranslationEnginePref/titleCreate')} />
			<ListPart name='customerRetentionAlerts' icon='systemConfiguration' dataType='CustomerRetentionAlerts' title={translate('CustomerRetentionAlerts/title')} titleEdit={translate('CustomerRetentionAlerts/titleEdit')} titleCreate={translate('CustomerRetentionAlerts/titleCreate')} />
			
			<ListPart name='translationSubengine' icon='translationSubengine' dataType='TranslationSubengine' title={translate('TranslationSubengine/title')} titleEdit={translate('TranslationSubengine/titleEdit')} titleCreate={translate('TranslationSubengine/titleCreate')} >
				<ListPart name='subenginesScoringRanges' dataType='SubenginesScoringRanges' title={translate('SubenginesScoringRanges/title')} titleEdit={translate('SubenginesScoringRanges/titleEdit')} titleCreate={translate('SubenginesScoringRanges/titleCreate')} />
			</ListPart>


			<ListPart name='projDeliveryTemplate' icon='projDeliveryTemplate' dataType='ProjDeliveryTemplate' title={translate('ProjDeliveryTemplate/title')} titleEdit={translate('ProjDeliveryTemplate/titleEdit')} titleCreate={translate('ProjDeliveryTemplate/titleCreate')} group={['SourceProductRef']} sort={[{ name: 'SourceProductRef', dir: 1 }, { name: 'OrderIndex', dir: 1 }]}>
				<ListPart name='projItemDeliveryTemplate' dataType='ProjItemDeliveryTemplate' title={FunctionParentTitle(translate('ProjDeliveryTemplate/ProjItemDeliveryTemplate/titleEdit'), 'DeliveryProductRef')} tabTitle={translate('ProjDeliveryTemplate/ProjItemDeliveryTemplate/title')} titleEdit={FunctionTitle(translate('ProjDeliveryTemplate/ProjItemDeliveryTemplate/titleEdit'), 'DeliveryProductRef')} titleCreate={translate('ProjDeliveryTemplate/ProjItemDeliveryTemplate/titleCreate')} />
			</ListPart>

			<ListPart name='role' icon='role' dataType='Role' title={translate('Role/title')} titleEdit={translate('Role/titleEdit')} titleCreate={translate('Role/titleCreate')} autoOpenTab={0}>
				<ListPart dataType='RoleBusinessCapabilities' title={translate('Role/RoleBusinessCapabilities/title')} titleEdit={translate('Role/RoleBusinessCapabilities/titleEdit')}>
					<ListPart dataType='BussinessCapabilityItem' polymorphic title={translate('Role/RoleBusinessCapabilities/BussinessCapabilityItem/title')} titleEdit={translate('Role/RoleBusinessCapabilities/BussinessCapabilityItem/titleEdit')} group={['AggregationGroup']} inlineEditionOnly />
				</ListPart>
			</ListPart>

			<ListPart name='organization' icon='organization' dataType='Organization' title={translate('Organization/title')} titleEdit={translate('Organization/titleEdit')} titleCreate={translate('Organization/titleCreate')} />
			<ListPart name='agreementFile' icon='templates' dataType='AgreementFile' title={translate('AgreementFile/title')} />
			<ListPart name='serviceUsage'  icon='translationEnginePref' dataType='ServiceUsage' title={translate('SystemServiceUsage/title')} />
			<ListPart name='templateString' icon='templates' dataType='TemplateString' title={translate('TemplateString/title')} />
			<EditOnePart name='internationalization' dataType="Internationalization" icon='internationalization' title={translate('Internationalization/title')} createEditForm={() => <InternationalizationAdminPage isOrganizationContext={false} />} onLoad={form => InternationalizationAdminPageDataLoad(form)} />
			<EditOnePart name='organizationInternationalization' icon='internationalization' dataType='OrganizationInternationalization' title={translate('Internationalization/title')} createEditForm={() => <InternationalizationAdminPage isOrganizationContext={true} />} onLoad={form => InternationalizationAdminPageDataLoad(form)} />

			<ModulePart name='systemConfigurationModule' icon='systemConfiguration' dataType='SystemConfigurationModule' title={translate('SystemConfigurationModule/title')} autoOpenTab={0}>
				<ListPart name='configurationLivoCore' dataType='SystemConfigurationLivoCore' title={translate('SystemConfigurationLivoCore/title')} titleEdit={translate('SystemConfigurationLivoCore/titleEdit')} titleCreate={translate('SystemConfigurationLivoCore/titleCreate')} />
				<ListPart name='configurationIdentity' dataType='SystemConfigurationIdentity' title={translate('SystemConfigurationIdentity/title')} titleEdit={translate('SystemConfigurationIdentity/titleEdit')} titleCreate={translate('SystemConfigurationIdentity/titleCreate')} />
			</ModulePart>

			<ListPart name='registryForDocuments' icon='registryForDocuments' dataType='RegistryForDocuments' title={translate('RegistryForDocuments/title')} titleEdit={translate('RegistryForDocuments/titleEdit')} titleCreate={translate('RegistryForDocuments/titleCreate')} />
			
			<ModulePart name='documentRegistryHistoryModule' icon='documentRegistryHistory' dataType='DocumentRegistryHistoryModule' title={translate('DocumentRegistryHistory/title')} autoOpenTab={0}>
				<ListPart useCreateLinkName='DocumentRegistryHistory' name='documentRegistryHistoryProjectManagement' dataType='DocumentRegistryHistoryProjectManagement' title={translate('DocumentRegistryHistoryProjectManagement/title')} titleEdit={translate('DocumentRegistryHistory/titleEdit')} titleCreate={translate('DocumentRegistryHistory/titleCreate')} />
				<ListPart useCreateLinkName='DocumentRegistryHistory' name='documentRegistryHistoryInvoice' dataType='DocumentRegistryHistoryInvoice' title={translate('DocumentRegistryHistoryInvoice/title')} titleEdit={translate('DocumentRegistryHistory/titleEdit')} titleCreate={translate('DocumentRegistryHistory/titleCreate')} />
				<ListPart useCreateLinkName='DocumentRegistryHistory' name='documentRegistryHistorySettlements' dataType='DocumentRegistryHistorySettlements' title={translate('DocumentRegistryHistorySettlements/title')} titleEdit={translate('DocumentRegistryHistory/titleEdit')} titleCreate={translate('DocumentRegistryHistory/titleCreate')} />
				<ListPart useCreateLinkName='DocumentRegistryHistory' name='documentRegistryHistoryTranslationService' dataType='DocumentRegistryHistoryTranslationService' title={translate('DocumentRegistryHistoryTranslationService/title')} titleEdit={translate('DocumentRegistryHistory/titleEdit')} titleCreate={translate('DocumentRegistryHistory/titleCreate')} />
			</ModulePart>

			<ListPart name='version' dataType='Version' />
			<ModulePart name='templateDefModule' icon='templateDef' dataType='TemplateDefModule' title={translate('TemplateDefModule/title')} autoOpenTab={0}>
				<ListPart name='templateDefProjectManagement' dataType='TemplateDefProjectManagement' title={translate('TemplateDefProjectManagement/title')} titleEdit={translate('TemplateDefProjectManagement/titleEdit')} titleCreate={translate('TemplateDefProjectManagement/titleCreate')} />
				<ListPart name='templateDefInvoice' dataType='TemplateDefInvoice' title={translate('TemplateDefInvoice/title')} titleEdit={translate('TemplateDefInvoice/titleEdit')} titleCreate={translate('TemplateDefInvoice/titleCreate')} />
				<ListPart name='templateDefTranslationService' dataType='TemplateDefTranslationService' title={translate('TemplateDefTranslationService/title')} titleEdit={translate('TemplateDefTranslationService/titleEdit')} titleCreate={translate('TemplateDefTranslationService/titleCreate')} />
				<ListPart name='templateDefSettlements' dataType='TemplateDefSettlements' title={translate('TemplateDefSettlements/title')} titleEdit={translate('TemplateDefSettlements/titleEdit')} titleCreate={translate('TemplateDefSettlements/titleCreate')} />
			</ModulePart>			
			<ModulePart name='templateStringModule' icon='templateDef' dataType='TemplateStringModule' title={translate('TemplateStringModule/title')} autoOpenTab={0}>
				<ListPart useCreateLinkName='TemplateString' name='templateStringProjectManagement' dataType='TemplateStringProjectManagement' title={translate('TemplateStringProjectManagement/title')} titleEdit={translate('TemplateStringProjectManagement/titleEdit')} titleCreate={translate('TemplateStringProjectManagement/titleCreate')} />
				<ListPart useCreateLinkName='TemplateString' name='templateStringInvoice' dataType='TemplateStringInvoice' title={translate('TemplateStringInvoice/title')} titleEdit={translate('TemplateStringInvoice/titleEdit')} titleCreate={translate('TemplateStringInvoice/titleCreate')} />
				<ListPart useCreateLinkName='TemplateString' name='templateStringTranslationService' dataType='TemplateStringTranslationService' title={translate('TemplateStringTranslationService/title')} titleEdit={translate('TemplateStringTranslationService/titleEdit')} titleCreate={translate('TemplateStringTranslationService/titleCreate')} />
				<ListPart useCreateLinkName='TemplateString' name='templateStringSettlements' dataType='TemplateStringSettlements' title={translate('TemplateStringSettlements/title')} titleEdit={translate('TemplateStringSettlements/titleEdit')} titleCreate={translate('TemplateStringSettlements/titleCreate')} />
			</ModulePart>
			<ModulePart name='configurationModule' icon='organzationConfiguration' dataType='ConfigurationModule' title={translate('ConfigurationModule/title')} autoOpenTab={0}>
				<ListPart name='configurationProjectManagement' dataType='ConfigurationProjectManagement' title={translate('ConfigurationProjectManagement/title')} titleEdit={translate('ConfigurationProjectManagement/titleEdit')} titleCreate={translate('ConfigurationProjectManagement/titleCreate')} />
				<ListPart name='configurationInvoice' dataType='ConfigurationInvoice' title={translate('ConfigurationInvoice/title')} titleEdit={translate('ConfigurationInvoice/titleEdit')} titleCreate={translate('ConfigurationInvoice/titleCreate')} />
				<ListPart name='configurationCRM' dataType='ConfigurationCRM' title={translate('ConfigurationCRM/title')} titleEdit={translate('ConfigurationCRM/titleEdit')} titleCreate={translate('ConfigurationCRM/titleCreate')} />
				<ListPart name='configurationSettlements' dataType='ConfigurationSettlements' title={translate('ConfigurationSettlements/title')} titleEdit={translate('ConfigurationSettlements/titleEdit')} titleCreate={translate('ConfigurationSettlements/titleCreate')} />
				<ListPart name='configurationTranslationService' dataType='ConfigurationTranslationService' title={translate('ConfigurationTranslationService/title')} titleEdit={translate('ConfigurationTranslationService/titleEdit')} titleCreate={translate('ConfigurationTranslationService/titleCreate')} />
				<ListPart name='configurationNotifications' dataType='ConfigurationNotifications' title={translate('ConfigurationNotifications/title')} titleEdit={translate('ConfigurationNotifications/titleEdit')} titleCreate={translate('ConfigurationNotifications/titleCreate')} />
			</ModulePart>

			<ListPart name='teachingMaterials' icon='registryForDocuments' dataType='TeachingMaterials' title={translate('TeachingMaterials/title')} titleEdit={translate('TeachingMaterials/titleEdit')} titleCreate={translate('TeachingMaterials/titleCreate')} />

		</HomePart >
	)
}
