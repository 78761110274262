const draftjsMyCommands = {
    myDelete: 'myDelete',
    myBackspace: 'myBackspace',
    myAutoSuggestTrigger: 'myAutoSuggestTrigger',
    myMoreThanOneSelection: 'myMoreThanOneSelection',
    myTagging: 'myTagging',
    disabledInsertOnTag: 'disabledInsertOnTag',
    enterDisabled: 'enterDisabled',
    tabDisabled: 'tabDisabled',
    editingDisabled: 'editingDisabled',
}

export default draftjsMyCommands
