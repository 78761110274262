export default class PartClass {
	constructor(dataView, parent, props) {
		this.dataView = dataView
		this.parent = parent
		this.parts = undefined
		this.nextIndex = undefined
		this.props = props
		this.reachable = undefined
	}

	get key() {
		if (!this.parent)
			return "."

		return this.parent.key + ' ' + this.parent.parts.findIndex(p => p === this)
	}

	get editTabs() {
		if (!this.parts)
			return [this]

		return [this, ...this.parts.filter(p => {
			return (p.reachable !== false) //|| p.parts
		})]
	}

	get dataTypePath() {
		let p = '', c = ''

		if (this.parent)
			p = this.parent.dataTypePath
		if (this.props.dataType)
			c = this.props.dataType

		if (p === '')
			return c
		return p + '/' + c
	}

	get isOpen() {
		if (!this.parts)
			return false

		if (this.props.connected && !this.isSelected && this.next)
			return false

		if (this.parent && this.parent.isSelected && this.parent.props.connected)
			return false

		return true
	}

	get next() {
		if (this.nextIndex !== undefined)
			return this.parts[this.nextIndex]
		return undefined
	}

	set next(v) {
		this.nextIndex = this.parts.findIndex(p => p === v)
		if (this.nextIndex === -1)
			this.nextIndex = undefined
	}

	get isSelected() {
		return this.dataView.selected === this
	}

	select() {
		this.dataView.selected = this
	}

	refresh() {
		this.dataView.refresh()
	}

	handleSelect(bAutoRefresh) {
		if (this.parent.next === this) {
			if (this.isSelected) {
				return false
			}
			this.select()
			if (bAutoRefresh)
				this.refresh()
			return true
		}

		if (this.parent.next)
			this.parent.next.closeChildren()

		this.parent.next = this
		this.select()
		if (bAutoRefresh)
			this.refresh()
		return true
	}

	closeChildren() {
		this.nextIndex = this.parts = undefined
	}

	findChildByName(name) {
		if (!this.parts)
			return
		return this.parts.find(p => p.props.name === name)
	}

	handleSelectChildByName(name, bAutoRefresh) {
		const child = this.findChildByName(name)

		if (!child)
			return false
		return child.handleSelect(bAutoRefresh)
	}

	findChildByDataType(dataType) {
		if (!this.parts)
			return
		return this.parts.find(p => p.props.dataType === dataType)
	}

	findChildByUseLinkName(useLinkName) {
		if (!this.parts)
			return
		return this.parts.find(p => p.props.useLinkName === useLinkName)
	}

	handleSelectChildByDataType(dataType, bAutoRefresh) {
		const child = this.findChildByDataType(dataType)

		if (!child)
			return false
		return child.handleSelect(bAutoRefresh)
	}

	handleSelectChildByComplexName(complexName, bAutoRefresh) {
		let child = this.findChildByName(complexName)

		if (!child)
			child = this.findChildByDataType(complexName)
		
		if (!child)
			child = this.findChildByUseLinkName(complexName)

		if (!child)
			return false

		return child.handleSelect(bAutoRefresh)
	}

	toString()
	{
		return `PartClass - ${this.key}`
	}
}