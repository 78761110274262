import { ObservableContextClass } from '../../observableContext/ObservableContaxt'

/**
 * Klasa basowa form
 */
export default class FormBase extends ObservableContextClass {

	constructor({ onLoad, onSubmit }) {
		super()
		this._onAsyncLoad = onLoad
		this._onAsyncSubmit = onSubmit
		this._childForms = new Set()
	}

	get parentForm() { return this._parentForm }

	get isLoading() { return this._isLoading }
	set isLoading(v) {
		this._isLoading = v
		this.trigger('loading')
	}

	get onLoadParam() { return this._onLoadParam }
	set onLoadParam(v) { this._onLoadParam = v }

	asyncLoad(loadType) {
		if (this._onAsyncLoad && this.isMounted)
			this._forceAsyncLoad({ loadType })
	}

	asyncSubmit(submitType, props) {
		if (this._onAsyncSubmit && this.isMounted)
			this._forceAsyncSubmit({ submitType, props })
	}

	get isChanged() { return this._isChanged }
	set isChanged(v) { this._isChanged = v }

	getRequired(name) { return false }
	getDisabled(name) { return false }
	getError(name) { return false }
	getPlaceholder(name) { return '' }
	getHint(name) { return '' }
	getLabel(name) { return name }
	getValue(name) { }
	handleChange(name, newValue, bFromParent) { }
	getMeta(name) { return {} }
	/**
	 * Czy wymuszamy aby pole nie zostało wyrenderowane?
	 * @param {any} field 
	 * @returns true/false
	 */
	shouldExcludeFieldFromRendering(field) { return false; }

	/*
	* @deprecated Metoda dodaje sekcje "polimorphic" do wszystkich attrybutów
	*/
	addPolymorphicSection() { }
	setEmptyData() { }
	clearChanges() { }

	onUnmounted() {
		if (this._parentForm)
			this._parentForm._childForms.delete(this)
	}

	getChildFormById(id) {
		return [...this._childForms].find(c => c.id === id)
	}
	get childForms() { return this._childForms }
	get changedChildForms() {
		return [...this._childForms].filter(c => c.isChanged)
	}
	get changedChildForm() {
		return [...this._childForms].find(c => c.isChanged)
	}
}
