import EditorFindAndReplaceSearch from "../model/EditorFindAndReplaceSearch";
import { DocumentCommentChangedArgs, IEditorDocumentEvents, IEditorEvents, IEditorTranslationUnitEvents, IFindAndReplaceEvents, nextGlobalHandlerId } from "../model/EditorMediator";

export default class TranslationUnitsDocumentController implements IFindAndReplaceEvents, IEditorEvents, IEditorDocumentEvents, IEditorTranslationUnitEvents
{
	onFindAndReplaceCancel: () => void;
	onFindNext: (findSearch: EditorFindAndReplaceSearch, didChange: boolean) => void;
	onReplace: (findSearch: EditorFindAndReplaceSearch, replaceAll: boolean, didChange: boolean) => void;
	selectNextFindResultOrGoToNextPage: (findSearch: EditorFindAndReplaceSearch, didChange: boolean, userChangedPageNavigation: boolean) => void;
    onActiveDocumentChanged: (documentId: string) => void;
    onGoToDocument: (documentId: string) => void;
    onGoToDocumentTU: (tuSequenceNumber: number) => void;
    onSelectedTranslationUnit: (languageDocumentId: string, sequenceNumber: number) => void;
    onDocumentCommentChanged: (languageDocumentId: string, args: DocumentCommentChangedArgs) => void;
    onTuConfirmationLevelChanged: (languageDocumentId: string, sequenceNumber: number, confirmationLevel: number) => void;
    onTryFixTuTags: (languageDocumentId: string, sequenceNumber: number) => void;

    private handlerId: number;
    private debugId: string;

    constructor()
	{
		this.handlerId = nextGlobalHandlerId();
        this.debugId = "[TUDC_" + this.handlerId + "] ";
	}

    getHandlerId()
    {
        return this.handlerId;
    }

	getHandlerDebugId() 
    {
		return this.debugId;
	}
}