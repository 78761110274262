import { appCtrl } from 'AppCtrl'
import { GlobalStrings } from 'GlobalStrings'
import { isArray, isEmpty, isObject } from 'lodash'
import FilterExpressionWriter, { FilterOperatorQS } from 'services/FilterExpressionWriter'

export class FilterManager {
   /**
  * Konstruktor
  * @param {Object} form Forma do której jest podłączony ten menager Gantt'a
  */
   constructor(form) {
      this._form = form
      this._values = []
      this._filterExpressionWriter = new FilterExpressionWriter()
      this._filterExpression = null
      this._isOpen = false
      this._localeClass = appCtrl.locale //klasa odpowiedzialna za tłumaczenie stringów
      this._filterTypes = {
         notEqual: "notEqual",
         lessThan: "lessThan",
         lessThanOrEqual: "lessThanOrEqual",
         greaterThan: "greaterThan",
         greaterThanOrEqual: "greaterThanOrEqual",
         startWith: "startWith",
         endsWith: "endsWith",
         contains: "contains",
         range: 'range',
         equal: "equal",
         empty: "empty",
      }
      this._enumFilterTypes = { //Wartości pokazywane w polu Typ (ComparisionOperator) jako opcje listy rozwijalnej
         startWith: this._localeClass.translate('WebSpa/FilterModal/startWith'),
         endsWith: this._localeClass.translate('WebSpa/FilterModal/endsWith'),
         contains: this._localeClass.translate('WebSpa/FilterModal/contains'),
      }
   }
   /**
    * GETTERY
    */
   get values() { return this._values }

   get filterTypes() { return this._filterTypes }

   get isOpen() { return this._isOpen }

   get filterExpression() { return this._filterExpression }
   /**
    * Getter który "układa" wartości w tablicy tak aby można je było wyświetlić jako opcje w comboboxie,
    * Combobox przyjmuje wartości tylko jako tablicę.
    * Zwracamy już przetłumaczone wartości
    */
   get enumFilterTypes() {
      const { startWith, endsWith, contains } = this._enumFilterTypes
      return [
         {value: startWith, id:1}, 
         {value: endsWith, id:2},
         {value: contains, id:3},
      ]
   }

   /**
    * SETTERY
    */
   set values(newValue) {
      this._values = newValue

      this.SetFilterExpresionFromValues()

      this._form.saveConfiguration()
   }

   /**
    * Metoda ustawia wartość pola _filterExpression na podstawie wartości pól: _values
    */
   SetFilterExpresionFromValues() {
      this._filterExpressionWriter.clear()

      for (const value of this._values) {
         const fieldCondition = value ? Object.values(value)[0] : {}
         const { filterTypes } = this

         if (value === undefined) {
            continue
         }

         switch (fieldCondition.filterType) {
            case filterTypes.startWith: //Na początku
               if (Array.isArray(fieldCondition.value)) {
                  for (let index = 0; index < fieldCondition.value.length; index++) {
                     this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.StartWith, fieldCondition.value[index]);       
                  }
               } else {
                  this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.StartWith, fieldCondition.value)
               }
               break;
            case filterTypes.endsWith: //Na końcu
               if (Array.isArray(fieldCondition.value)) {
                  for (let index = 0; index < fieldCondition.value.length; index++) {
                     this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.EndsWith, fieldCondition.value[index]);       
                  }
               } else { 
                  this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.EndsWith, fieldCondition.value)
               }
               break;
             case filterTypes.empty:
                 if (fieldCondition.empty == true)
                     this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.IsNull, true );
                 else
                     this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.NotNull, true );
               break;
            case filterTypes.equal:
               if (Array.isArray(fieldCondition.value)) {
                  for (let index = 0; index < fieldCondition.value.length; index++) {
                     const currentValue = fieldCondition.value[index];
                     let filterOperator = FilterOperatorQS.Equal;
                     if(currentValue === null)
                     {
                        filterOperator = FilterOperatorQS.IsNull; 
                     }

                     this._filterExpressionWriter.addCondition(fieldCondition.name, filterOperator, currentValue);  
                  }
               } else {
                     this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.Equal, fieldCondition.value);
               }
               break
            case filterTypes.range:
               if (fieldCondition.from != null) {
                  this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.GreaterThanOrEqual, fieldCondition.from)
               }
               if (fieldCondition.to != null) {
                  this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.LessThanOrEqual, fieldCondition.to)
               }
               if (fieldCondition.from === undefined && fieldCondition.to) {
                  console.log("Range query filter has no 'from' and 'to' value!")
               }
               break
            default:
               if (Array.isArray(fieldCondition.value)) {
                  for (let index = 0; index < fieldCondition.value.length; index++) {
                     this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.Contains, fieldCondition.value[index], undefined);       
                  }
               } else {
                  this._filterExpressionWriter.addCondition(fieldCondition.name, FilterOperatorQS.Contains, fieldCondition.value, fieldCondition.readableValue)
               }
               break
         }
      }

      this._filterExpression = this._filterExpressionWriter.writeToString()
   }

   /**
    * METODY
    */

   setOpen(newValue) {
      this._isOpen = newValue
      this._form.trigger('data')
   }

   /**
    *  Funkcja która z obiektu value zwraca wartości odpowiednie dla każdego typu.
    * @param {Object} fieldName  - Nazwa kolumny z której dany chcemy pobrać
    * @param {String} valueName - nazwa wartości którą chcemy pobrać: Jeden z: 'value', 'from', 'to', 'comparisionOperator' 
    */
   getSavedValues(fieldName, valueName) {
      let fieldValue
      this.values.forEach((value) => {   
          if (value && fieldName in value) {
            if (valueName === GlobalStrings.EmptyFilterAttibuteName) {
                /// Pod tą wartością przechowywane są czasem nie tylko wartości bool. 
                /// Chcemy zwracać wartość wyłącznie, gdy jest pamiętana wartość pola Empty
                fieldValue = value[fieldName][valueName.toLowerCase()];
                const isBoolean = typeof fieldValue == "boolean" || (!isEmpty(fieldValue) && typeof fieldValue[0] == "boolean");
                return isBoolean ? fieldValue : undefined;
            }
            else if (valueName === "comparisionOperator" ) {
               fieldValue = value[fieldName][valueName];
               return fieldValue;
            }
            else if (value[fieldName].type === 'String'){

                  fieldValue = value[fieldName].value;
               return;
            }
            else if (value[fieldName].type === 'Enum' && !isEmpty(value[fieldName].readableValue)) {

               fieldValue = {
                  value: value[fieldName].readableValue,
                  id: value[fieldName].value
               }

               return fieldValue
            } else if ( (value[fieldName].type === 'EnumList' || value[fieldName].type === 'Ref') && !isEmpty(value[fieldName].readableValue)) {
               fieldValue = [];
               for (let index = 0; index < value[fieldName].readableValue.length; index++) {
                  const readVal = value[fieldName].readableValue[index];
                  const val = value[fieldName].value[index];
                  fieldValue.push({
                     value: readVal,
                     id: val
                  });
               }
               return fieldValue
              }

            fieldValue = value[fieldName][valueName]
         }

      })

      return fieldValue
   }

   /**
    * Funkcja zwracająca odpowiednio spraparowany obiekt ze wszystkich pól które dostała przy filtrowaniu
    * @param {Object} data - Objekt z właściwościami meta oraz data wszystkich pól wyświetlonych przy filtrowaniu (zwracanych w FilterList)
    * 
    * filterType - właściwość opisująca typ filtrowania, przyjmuje wartości:
    * > "contains" - zawiera dla obiektów z typem Ref oraz String
    * > "range" - zakres od - do dla obiektów typu Int, Number, Date, Timestamp
    */

   getPropsFromRows(data) {
      return data.data.map(field => {

         let keys = Object.keys(field.attributes)

         for (const key of keys) {
            switch (key) {
               case 'ComparisionOperator':
                case 'Value': {
                    const empty = field.attributes[GlobalStrings.EmptyFilterAttibuteName];
                    if (empty !== undefined) {
                        return this.GetEmptyFilterProp(field, key);
                    }    
                  const fieldType = field.meta.attributes['Value'].type;
                  if (fieldType === 'Bool') {
                     if (field.attributes[key] === undefined)
                        return null;
                  }
                  else if (!field.attributes[key]) //Jeśli to pole jest puste zwracamy null (user nic nie ustawił)
                     return null;

                  let value;
                  let readableValue;
                  if (fieldType === 'String'){
                     readableValue = field.attributes['Value'];
                     value = field.attributes['Value'];
                  }
                  else if (fieldType === 'EnumList'){
                     readableValue = field.attributes['Value'].map(v => v.value);
                     value = field.attributes['Value'].map(v => v.id);
                  }
                  else if (fieldType === 'Ref') {
                     value = (isObject(field.attributes['Value']) ? field.attributes['Value']?.map(v => v.id) : field.attributes['Value']) || ""
                     readableValue = (isObject(field.attributes['Value']) ? field.attributes['Value']?.map(v => v.value) : field.attributes['Value']) || ""
                  }
                  else if (fieldType === 'Bool'){
                     // Jeśli filtrujemy po wartości false, to musimy również filtrować po wartości null. 
                     readableValue = field.attributes['Value'] === false ? [false, null] : field.attributes['Value'];
                     value = readableValue;
                  }
                  else {
                     value = (isObject(field.attributes['Value']) ? field.attributes['Value']?.id : field.attributes['Value']) || ""

                     //Potrzebne w przypadku gdy wartością jest obiekt - dzięki temu przy odczycie zapisanej w konfiguracji wartości mamy wartość którą możemy wyświetlić w kontrolce zamiast id 
                     readableValue = (isObject(field.attributes['Value']) ? field.attributes['Value']?.value : field.attributes['Value']) || ""
                  }



                  const comparisionOperator = field.attributes['ComparisionOperator']
                  const inputFilterType = this.setFilterTypeValue(comparisionOperator, fieldType)

                  if (comparisionOperator)
                     return ({
                        [field.id]: {
                           name: field.id,
                           value,
                           comparisionOperator,
                           type: field.meta.attributes[key].type,
                           filterType: inputFilterType
                        }
                     })
                  else
                     return ({
                        [field.id]: {
                           name: field.id,
                           value,
                           readableValue,
                           type: field.meta.attributes[key].type,
                           filterType: inputFilterType
                        }
                     })
               }
               case 'From':
               case 'To': {
                    const empty = field.attributes[GlobalStrings.EmptyFilterAttibuteName];
                    const from = field.attributes['From'];
                    const to = field.attributes['To'];
                    if (empty !== undefined) {
                        return this.GetEmptyFilterProp(field, key);
                    }                  
                  else if (to == null && from == null) //Jeśli ani from ani to nie ma wartości zwracamy null (user nic nie ustawił)
                     return null
                  else
                     return ({
                        [field.id]: {
                           name: field.id,
                           from,
                           to,
                           type: field.meta.attributes[key].type,
                           filterType: this.filterTypes.range
                        }
                     })
                }
               default:
                  break
            }
         }
      }).filter(field => field != null) //Jeśli coś jest null nie zwracamy tego 
   }

    GetEmptyFilterProp(field, key) {
        const empty = field.attributes[GlobalStrings.EmptyFilterAttibuteName] === false ? [false, null] : field.attributes[GlobalStrings.EmptyFilterAttibuteName];
        return ({
            [field.id]: {
                name: field.id,
                empty,
                type: field.meta.attributes[key].type,
                filterType: this.filterTypes.empty
            }
        });
    }

   /**
    * Funkcja pozwalająca określić filterType. 
    * Określamy go na podstawie wartości które przyszły z pola comparisionOperator jeśli takie pole istnieje
    * Jesli nie istnieje możemy określić wartośc na podstawie typu danych, wtedy należy dodać kolejnego if'a 
    * Jeśli żadne z powyższych, zwracamy typ równy 'contains'
    * 
    * @param {String} comparisionOperator - wartośc z pola  ComparisionOperator (combobox Typ)
    * @param {String} type - typ danych dla danego pola, np. Enum, String, Bool itp 
    */
   setFilterTypeValue(comparisionOperator, type) {
      const { startWith, endsWith, contains } = this._enumFilterTypes;

      if (comparisionOperator?.value === startWith)
         return this.filterTypes?.startWith;
      else if (comparisionOperator?.value === endsWith)
         return this.filterTypes?.endsWith;
      else if (comparisionOperator?.value === contains)
         return this.filterTypes?.contains;
      else if (type === 'Enum')
         return this.filterTypes?.equal;
      else if (type === 'EnumList')
         return this.filterTypes?.equal;
      else if (type === 'Ref')
         return this.filterTypes?.equal;
      else if (type === 'Bool')
         return this.filterTypes?.equal;
      
      return this.filterTypes?.contains;
   }

   resetForm() {
      this._values = []
      if (window.name.startsWith(GlobalStrings.CrmOffersWindowName)){
         this._filterExpression = null;

         this._form.saveConfiguration()
         this.setCompanyFilterForCrmOffers(this._form);
      }else {
         this._filterExpression = null;
         this._form.saveConfiguration()
      }
      this._form.asyncLoad()

   }

   /**
    * Metoda ustawia filtr dla ofert w ramach modułu CRM -  
    * @param {} form 
    * @returns 
    */
   setCompanyFilterForCrmOffers(form) {
      if (window.name.startsWith(GlobalStrings.CrmOffersWindowName)) {
   
         if (form._dataType !== 'Offer') {
            window.name = '_blank';
            return;
         }
   
         const id = window.name.split('_')[1];

         if (form.filterManager._filterExpression == null || form.filterManager._filterExpression?.split(',')?.filter(f => !f.startsWith(GlobalStrings.OfferCustomerRefFieldName)).length !== 0){
            form.filterManager._filterExpressionWriter.addCondition(GlobalStrings.OfferCustomerRefFieldName, 'eq', id);
            form.filterManager._filterExpression = form.filterManager._filterExpressionWriter.writeToString();
            form.filterManager._filterExpressionWriter.clear();
         }
      }
   }

   /**
    * Funkcja Ładująca konfigurację
    * @param {Object} c - objekt konfiguracji
    */

   loadConfiguration(c) {
      this._values = c.filterValues
      this.SetFilterExpresionFromValues()
   }

   /**
    * Funkcja zapisująca konfigurację
    * @param {Object} c - objekt konfiguracji
    */
   saveConfiguration(c) {
      c.filterValues = this._values
   }
}

