import styled from 'styled-components'
import posed from 'react-pose'

/**
 * Animacja Ikonki
 * more i less - wartości wykorzystywane w przekazywanych propsach - pose(condition ? 'more' : 'less')
 */
const PosedIcon = posed.i({
   more: {
      rotate: 0,
      transition: {
         rotate: { type: 'spring', stiffness: 250, damping: 25 },
         default: { duration: 150 }
      }

   },
   less: {
      rotate: 180,
      transition: {
         rotate: { type: 'spring', stiffness: 250, damping: 25 },
         default: { duration: 150 }
      }
   },
})

/**
 * używając tego komponentu trzeba podać jako propsa pose tak jak poniżej:
 * pose = {isOpen ? 'more' : 'less'} - nazyw more oraz less odnoszą się do nazw użytych w PosedIcon!
 */
const StyledDropdownIcon = styled(PosedIcon)`
 ${({ error, theme }) => error && `color: ${theme.colors.error};`}
 ${({ disabled }) => disabled && `color: transparent`};
`

export default StyledDropdownIcon