import { IEntityContextStateController, IEntityContextStateReadOnlyController } from "../../contexts/data/DataContextModel";
import LivoCatApiDataMapper, { ApiLoadStrategyDataMapper, commitAndOverrideMergeStrategy, resourceObjectToEntity } from "../../contexts/data/LivoCatApiDataMapper";
import { IRequestDataMappper, RequestOptions, RequestResults } from "../../contexts/data/RequestModel";
import { TranslationUnitAttributes, removeHighlightedEntitiesFromDraftIndex } from "./TranslationUnitViewModel";
import SegmentElementType from "./SegmentElementType";

class TranslationUnitsReplaceDataMapper implements IRequestDataMappper
{
    onFinishedRequest(contextId: string, options: RequestOptions, requestResult: RequestResults) 
    {
        // Nic nie robimy
    }

    mergeWithEntityIndex(entityContextController: IEntityContextStateController, requestOptions: RequestOptions, requestResult: RequestResults) 
    {
        commitAndOverrideMergeStrategy(entityContextController, requestOptions, requestResult);

        if(!requestResult?.ok || !requestResult?.resource?.data)
        {
            return;
        }

        for (const dataObject of requestResult.resource.data) 
        {
            let entity = resourceObjectToEntity(dataObject);
            entityContextController.setEntityChanges(entity);
            entityContextController.commitEntityChanges(entity);
        }
        
    }
    createRequestPayload(entityContextController: IEntityContextStateReadOnlyController, requestOptions: RequestOptions) 
    {
        const draftEntities = entityContextController.getDraftEntities();
        const resourceDocument = { data: [] };

        for(const entity of draftEntities)
        {
            const attributes = entityContextController.getEntityAttributes(entity);
            if(attributes && attributes[TranslationUnitAttributes.Target] 
                && attributes[TranslationUnitAttributes.Target]['elements']
                && attributes[TranslationUnitAttributes.Target]['elements'].length > 0)
            {
                let hasSelectedElement = false;
                for (const element of attributes[TranslationUnitAttributes.Target]['elements']) 
                {
                    if(element.typeName === SegmentElementType.StartHighlight && element.status === "selected")
                    {
                        hasSelectedElement = true;
                        break;
                    }
                    
                }

                if(!hasSelectedElement)
                    continue;
                
                const resourceObject = {
                    id: entity.__id,
                    type: entity.__type,
                    attributes: {
                        [TranslationUnitAttributes.Target]: attributes[TranslationUnitAttributes.Target]
                    }
                };

                resourceDocument.data.push(resourceObject);
            }
        }
        
        return resourceDocument;
    }
}

class TranslationUnitsLoadDataMapper extends ApiLoadStrategyDataMapper
{
    mergeWithEntityIndex(entityContextController: IEntityContextStateController, requestOptions: RequestOptions, requestResult: RequestResults) 
    {
        removeHighlightedEntitiesFromDraftIndex(entityContextController);
        super.mergeWithEntityIndex(entityContextController, requestOptions, requestResult);
    }
}

export default class TranslationUnitDataMapper extends LivoCatApiDataMapper
{
    _translationUnitsReplaceStrategyMemo?: TranslationUnitsReplaceDataMapper;
    _translationUnitsLoadStrategyMemo?: TranslationUnitsLoadDataMapper;
    _isActiveFindRoutine: boolean;

    constructor(isActiveFindRoutine: boolean) 
    {
        super();
        this._translationUnitsReplaceStrategyMemo = null;
        this._translationUnitsLoadStrategyMemo = null;
        this._isActiveFindRoutine = isActiveFindRoutine;
    }

    createUpdateExecutionStrategy(key: string): IRequestDataMappper
    {
        if(key === "updateTranslationUnitsReplace")
        {
            if(this._translationUnitsReplaceStrategyMemo === null)
                this._translationUnitsReplaceStrategyMemo =  new TranslationUnitsReplaceDataMapper();
            
            return this._translationUnitsReplaceStrategyMemo;
        }

        return super.createUpdateExecutionStrategy(key);
    }

    createLoadExecutionStrategy(key: string): IRequestDataMappper 
    {
        if(this._isActiveFindRoutine)
        {
            if(this._translationUnitsLoadStrategyMemo === null)
                this._translationUnitsLoadStrategyMemo = new TranslationUnitsLoadDataMapper();

            return this._translationUnitsLoadStrategyMemo;
        }

        return super.createLoadExecutionStrategy(key);
    }
}