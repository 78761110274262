export interface IDocumentCommentDto 
{
    id?: string,
    createdAt?: string,
    comment?: string,
    languageDocumentId?: string,
    translationSegmentId?: number,
    userName?: string
}

export const CommentDtoType = "DocumentCommentDto";
export const CommentDtoProps = {
    comment: 'comment',
    languageDocumentId: 'languageDocumentId',
    translationSegmentId: 'translationSegmentId'
} as const;