import { CryptoSessionStorage } from 'components/Storages'

/**
 * Klasa sesji przechowującej tokeny do serwera
 *
 */
export default class SessionCat {
	/**
	 * Wartość przechowywana w sesji
	 *
	 */
	set value(v) {
		this._value = v
		CryptoSessionStorage.set('loginCat', v)
		this.waitForRefresh()
	}
	/**
	 * Pobranie wartości przechowywanej w sesji
	 *
	 */
	get value() {
		return this._value
	}

	/**
	 * Czy jest autoryzacja
	 *
	 */
	get isAuthorized() { return this._value !== undefined }
	
	/**
	 * token
	 */
	get accessToken() { return this._value.accessToken }
	/**
	 * Czas przedawnienia tokena
	 */
	get accessTokenExpiration() { return new Date(this._value.accessTokenExpiration) }
	/**
	 * refresh token
	 */
	get refreshToken() { return this._value.refreshToken }
	/**
	 * Czas przedawnienia refresh tokena
	 */
	get refreshTokenExpiration() { return new Date(this._value.refreshTokenExpiration) }
	
	/**
	 * Czy token jest przedawniony
	 */
	get isAccessTokenExpired() { return this._value && this.accessTokenExpiration < new Date() }
	/**
	 * Czy refresh token jest przedawniony
	 */
	get isRefreshTokenExpired() { return this._value && this.refreshTokenExpiration < new Date() }

	get taskId() { return this._value && this._value.taskId }
	get projectId() { return this._value && this._value.projectId }

	get canComplete() { return this._value && this._value.canCompleteTask }
	set canComplete(v) { if (this._value) this._value.canCompleteTask=v }

	/**
	 * Odczytanie tokenu ze storygu
	 */
	restore() {
		this._value = CryptoSessionStorage.get('loginCat')

		if (!this._value || this.isRefreshTokenExpired)
			this._value = undefined

		this.waitForRefresh()
	}

	/**
	 * Czekanie na odswierzenie tokena
	 */
	waitForRefresh() {
		// clearTimeout(this._timer)
		// this._timer = undefined

		// if (!this._value)
		// 	return

		// let time = this.accessTokenExpiration.getTime() - (new Date()).getTime() - 30000

		// if (time < 0) {
		// 	this.value = undefined
		// 	return
		// }

		// let _this = this
		// this._timer = setTimeout(() => {
		// 	getFormRestService(`/auth/identities/${_this.identityId}/token`)
		// 		.refreshAccessToken()
		// }, time)
	}
}