import { Box, Divider, IconButton, List, ListItem, ListItemText, Paper, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Create } from '@material-ui/icons';
import React from 'react'
import SidebarContentHeader from './SidebarContentHeader';
import useLanguageDocument from 'contents/cat/hooks/useLanguageDocument';
import useSessionTask from 'contents/cat/hooks/useSessionTask';
import { compareDocumentsFn } from 'contents/cat/model/Utils';
import { useEditor } from 'contents/cat/hooks/useEditor';
import useEditorMediator from 'contents/cat/hooks/useEditorMediator';

const SidebarListItemText = withStyles(() => ({
	root: {
		margin: 1
	},
    primary: {
        fontSize: '0.8rem'
    }
}))(ListItemText);

const itemSelectedStyle = {backgroundColor: 'lightgray'}

const SidebarContentDocuments = ({...other}) => {
    const editor = useEditor();
    const documents = useSessionTask()?.languageDocuments;
    if(!documents)
        return null;

    const onItemClick = (documentId: string) => {
        //editor.activeDocumentChanged(documentId); // zdarzenie oparte na stanie
        editor.mediator.onActiveDocumentChanged(documentId);
    };

    const items = documents.toSorted(compareDocumentsFn).map((document) => {
        const itemStyle = editor.state.activeDocumentId === document.id ? itemSelectedStyle : null;
        return (
            <>
            <ListItem disableGutters button dense onClick={() => {onItemClick(document.id)}} style={itemStyle} >
                <SidebarListItemText primary={document.name} />
            </ListItem>
            <Divider />
            </>
        );
    });

    return (
        <List  >
            {items}
        </List>

    );
};

export default SidebarContentDocuments;