import React from 'react'

/**
 * Rysowanie pojedynczego elementu drzewa
 *
 * @param {*} { tree, children }
 * @returns {Node}
 */
export function Tree({ tree, children }) {
	return React.Children.map(children, (child, index) => {
		return React.cloneElement(child, { tree, treeChildren: children })
	})
}