export class EnumListClass {
	constructor() {
		this.enumListValues = []
	}

	onRenderProvider({ selected, values, onChange, disabled, error, IDs }) {

		this.disabled = disabled 
		this.error = error
		this.IDs = IDs

		const isSelected = new Set()

		this.onChange = onChange
		for (let s of selected)
			isSelected.add(s.id)

		if (values) {
			this.enumListValues = values.map(v => {
				return {
					id: v.id,
					value: v.value,
					selected: isSelected.has(v.id),
					marked: false
				}
			})
		}
	}

	callOnChange() {
		let selected = this.enumListValues
			.filter(v => v.selected)
			.map(v => {
				return {
					id: v.id,
					value: v.value
				}
			})
		this.onChange(selected)
	}

	moveMarked(fromIsSelectedList, disabledValues) {
		let list = this.enumListValues.filter(value => value.selected === fromIsSelectedList && value.marked)

		let disabledValuesArray = disabledValues?.disabledValues
		if (disabledValuesArray){
			list = list.filter( element => !disabledValuesArray.some( x => x.id === element.id))
		}
		
		list.forEach(v => {
			v.marked = false
			v.selected = !fromIsSelectedList
		})
		this.lastSelected = undefined
		this.lastNotSelected = undefined
		this.refresh()
		this.callOnChange()
	}

	moveValue(value) {
		if(this.disabled)
			return 

		else {
			value.marked = false
			value.selected = !value.selected
			this.refresh()
			this.callOnChange()
		}	
	}

	moveAll(isSelectedList, disabledValues) {
		let list = this.enumListValues
		let disabledValuesArray = disabledValues?.disabledValues
		if (disabledValuesArray){
			list = this.enumListValues.filter( element => !disabledValuesArray.some( x => x.id === element.id))
		}
		list.forEach(v => {
			v.marked = false
			v.selected = !isSelectedList
		})
		this.refresh()
		this.callOnChange()
	}

	mark(value, ctrl, shift) {
		const list = this.enumListValues.filter(v => v.selected === value.selected)

		if (!shift)
			if (value.selected)
				this.lastSelected = value.id
			else
				this.lastNotSelected = value.id

		if (!ctrl && !shift) {
			list.forEach(v => v.marked = false)
			value.marked = true
			this.refresh()
			return
		}

		if (ctrl) {
			value.marked = !value.marked
			this.refresh()
			return
		}

		if (shift) {
			let lastId = value.selected ? this.lastSelected : this.lastNotSelected
			let inRange = false

			list
				.forEach(v => {
					if (inRange) {
						if (v.id === lastId || value.id === v.id) {
							v.marked = true
							inRange = false
						} else
							v.marked = true
					}
					else {
						if (v.id === lastId || value.id === v.id) {
							inRange = true
							v.marked = true
						} else
							v.marked = false
					}
				})
			this.refresh()
		}
	}
}