const AttributeTypes = {
    Ref: 'Ref',
    GuidList: 'GuidList',
    Enum: 'Enum',
    EnumList: 'EnumList',
    File: 'File',
    Bool: 'Bool',
    String: 'String',
    Timestamp: 'Timestamp',
    Date: 'Date',
    Int: 'Int',
    Number: 'Number',
};

export {AttributeTypes};