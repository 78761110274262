/**
 * @module services/getRestService
 */

import config from 'config'
import RestService from './RestService'

/**
 * Pobranie instacji FormRestService na podstawie serviceName
 * @method getRestService
 * @param {*} serviceName
 * @returns {FormRestService}
 */
export default function getRestService(serviceName) {
	if (serviceName && serviceName.startsWith(config.serviceApiVer)) {
		serviceName = serviceName.slice(config.serviceApiVer.length);
	}
	return new RestService(config.serviceUrl + serviceName);
}