import React, {useEffect, useRef, useState} from 'react';
import Autosuggest from 'react-autosuggest';
import './styles.css'
import {getTagObjectFromTagName, getTagsFromAllowedTagsArrayObjects} from "./handleTagsFunctions";
import {value} from "lodash/seq";

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestions(value, allowedTags) {
    const escapedValue = escapeRegexCharacters(value.trim());

    const regex = new RegExp('^' + escapedValue, 'i');
    const tags = getTagsFromAllowedTagsArrayObjects(allowedTags)
    return tags.filter(item => regex.test(item.name));
}

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSuggestion(suggestion) {
    return (
        <span>{suggestion.name}</span>
    );
}

const AutoSuggestComponent = props => {

    const textInput = useRef(null)
    const [valueHook, setValueHook] = useState('')
    const [suggestionHook, setSuggestionHook] = useState({suggestions: []})
    const [isSuggestion, setIsuggestion] = useState(true)

    const onChange = (event, { newValue, method }) => {
        setValueHook(newValue)
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        let allowedTags = props.allowedTags
        const suggestions = getSuggestions(value, allowedTags)

        setSuggestionHook({suggestions: suggestions})
        setIsuggestion(suggestions.length ? true : false)
    };

    const onSuggestionsClearRequested = () => {
        setSuggestionHook({suggestions: []})
    };

    useEffect(() => {
        textInput.current.input.focus({
            preventScroll: true
        });
    }, [])

    const onKeyDown = (e) => {
        if (e.key==='Enter'){
            let tagObject =  getTagObjectFromTagName(valueHook, props.allowedTags)
            if (tagObject) {
               props.onSubmit(valueHook, tagObject)
               e.stopPropagation();
               e.preventDefault();
            }
            
        }
        else if (e.key==='Escape'){
            props.onClose(e.key)
        }
    }
    const handleOnBlur = () => {
        props.onClose('lostFocus')
    }
    const value = valueHook
    const inputProps = {
        placeholder: "Press esc to close.",
        value,
        onChange: onChange,
        onBlur: handleOnBlur
    };
    return (
        <div contentEditable={true}
             onKeyDown={onKeyDown}
        >
            <Autosuggest
                ref={textInput}
                shouldRenderSuggestions={() => true}
                suggestions={suggestionHook.suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                style={{width: "100px"}}
            />
            {!isSuggestion &&
                <div className={'react-autosuggest__suggestions-container--open'}>
                    <p className={'no-suggestions'}>No suggestions</p>
                </div>
            }
        </div>

    );
}

export default AutoSuggestComponent
