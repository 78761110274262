import React, { useEffect, useState } from 'react'
import config from 'config';
import Button from '@material-ui/core/Button';
import { useTranslate } from 'locale/Locale'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles, Dialog, DialogActions, DialogContent, Divider, List, ListItem, ListItemText, TextareaAutosize, CircularProgress } from '@material-ui/core'
import { isEmpty, toString } from 'lodash'
import Typography from '@material-ui/core/Typography';
import {saveLogsToFileOnLocalDisk} from 'Tools/Logging/EventLog';
import { useForm } from './form/Form';
import Logger from 'Tools/Logging/Logger';
import HttpService from 'services/HttpService';
import Icon from './icon/Icon';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const StyledListItemText = withStyles(() => ({
    root: {
       display: 'inline-flex',
       marginTop: '2px',
       marginBottom: '2px'
    },
    primary: {
       fontSize: '0.8rem',
       fontWeight: 600,
    },
    secondary: {
       fontSize: '0.8rem',
       marginLeft: 5,
       color: 'inherit',
    },
 }))(ListItemText)

const defaultLocalization = {
  close: "Close",
  downloadEventLog: "Download Event Log",
  eventLogDownloadingEventLog: "If the problem still occurs download the event log and send it to ",
  preparingAdditionalDiagnosticInfo: "Preparing additional diagnostic info.",
  additionalDiagnosticInfoReady: "Preparing additional diagnostic info - done.",
}

const ErrorModalEx = ({open, errorObject, logSerializer, onClose, localization}) => {

  const [additionalDiagnosticTests, setAdditionalDiagnosticTests] = useState(0);

  useEffect(() => {

    // Probujemy wykonac dodatkowe testy diagnostyczne w celu weryfikacji czy sa jakies problemy z CORS
    // Poniższe wywołanie powinno wykonać request, który wygeneruje preflight request.
    const invokeVersionEdnpoint = async () => {
      await new HttpService(config.serviceHost).get('/api/v1/00000000-0000-0000-0000-000000000000/version');
      setAdditionalDiagnosticTests(prevValue => prevValue + 1);
    }

    // Poniższe wywołanie jest requestem prostym. Taki request nei generuje preflight request.
    const invokeVersionEdnpointSimpleMode = async (method, url) => {
      const fetchConfig = {
        method : method,
        headers: {
        },
        mode: 'cors'
      };
      let response = null;

      Logger.of('ErrorModalEx.AdditionalDiagnostics').info(`Fetch ${fetchConfig.method} ${url}`);
      try
      {
        response = await fetch(url, fetchConfig);
      }
      catch(error){
        Logger.of('ErrorModalEx.AdditionalDiagnostics').error(error, `Fetch ${fetchConfig.method} ${url}. Rejected because of network error.`);
        if(response && response.headers)
          Logger.of('ErrorModalEx.AdditionalDiagnostics').error("Headers", ...response.headers);

        throw error;
      }

      try
      {
        if(!response.ok){
          Logger.of('ErrorModalEx.AdditionalDiagnostics').error(response, `Fetch ${fetchConfig.method} ${url}. Http failed with status code: ${response.status}`, ...response.headers);
        }
  
        const payloadAsText = await new HttpService().tryParseResponsePayloadToString(response.clone());
        Logger.of('ErrorModalEx.AdditionalDiagnostics').info('Payload', payloadAsText);
      }
      catch(error){
        Logger.of('ErrorModalEx.AdditionalDiagnostics').error(error, 'Error while parsing response payload or headers.');
        throw error;
      }

      setAdditionalDiagnosticTests(prevValue => prevValue + 1);
    }

    const fetchErrorHandler = (reason) => {
      console.error(reason);
      setAdditionalDiagnosticTests(prevValue => prevValue + 1);
    }

    invokeVersionEdnpointSimpleMode('GET', 'https://www.cloudflare.com/cdn-cgi/trace').catch(fetchErrorHandler);
    invokeVersionEdnpointSimpleMode('GET', config.serviceHost + '/api/v1/00000000-0000-0000-0000-000000000000/version').catch(fetchErrorHandler);
    invokeVersionEdnpoint().catch(fetchErrorHandler);
  }, []);

  const title = toString(errorObject.title);
  const details = toString(errorObject.detail);
  const status = errorObject.status;
  const httpMethod = errorObject.method;
  const traceId = errorObject.traceId;
  const url = errorObject.url;
  const jsStack = errorObject.stack;
  const type = errorObject.type;

  const errorAttributes = [
    {label: "Type", value: type},
    {label: "Status", value: status},
    {label: "Url", value: url},
    {label: "Trace ID", value: traceId},
    {label: "Stack", value: jsStack}
  ].filter(element => element.value);

  const errorAttributeItems = errorAttributes.map((element => {
    return (
      <ListItem className='p-0 m-0'>
        <StyledListItemText primary={element.label + ":"} secondary={element.value}/>
      </ListItem>
    )
  }));

  const onSaveReportFileClick = () => {
    console.log("onSaveReportFileClick()");
    saveLogsToFileOnLocalDisk(logSerializer);
  }

  const areRunningAdditionalTests = additionalDiagnosticTests < 3;
  localization = {...defaultLocalization, ...localization};

  return (
      <Dialog aria-labelledby="customized-dialog-title" open={open} scroll="paper" maxWidth="md">
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>

        <Typography gutterBottom>
          {details}
        </Typography>

        <Typography gutterBottom>
          <br/>
            {localization.eventLogDownloadingEventLog} <a href="mailto:support@livolink.com">support@livolink.com</a>.
        </Typography>

        <Typography gutterBottom>
          {areRunningAdditionalTests
            ? <><CircularProgress size={20} /> {localization.preparingAdditionalDiagnosticInfo}</>
            : <><Icon name="check" color="green700" style={{fontSize: '20px'}} /> {localization.additionalDiagnosticInfoReady}</>
          }
        </Typography>
        

        <Typography style={{paddingTop: '25px'}}>
          <List dense>
              {errorAttributeItems}
          </List>
        </Typography>

        <Typography>
          <StyledListItemText primary="Logs:"/>
          <TextareaAutosize aria-label="empty textarea" placeholder="Empty" style={{width: '100%'}}  maxRows={15}>
              {logSerializer.toStrings().join('\r\n')}
          </TextareaAutosize>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={onSaveReportFileClick} disabled={areRunningAdditionalTests}>
          {areRunningAdditionalTests && <CircularProgress size={20} style={{marginRight: '5px'}} />}{localization.downloadEventLog}
        </Button>
        <Button autoFocus color="primary" onClick={onClose}>
          {localization.close}
        </Button>
      </DialogActions>

    </Dialog>
  )
}

const emptyObject = {};

const ErrorModalFormEx = ({useDefaultTranslation}) => {
  const form = useForm('data')
	const [open, setOpen] = React.useState(false)
	const [errorClassObject, setErrorClassObject] = useState({})
  const translateButtons = useTranslate('WebSpa/Buttons')
  const errorModalTranslate = useTranslate('WebSpa/ErrorModal')
  let localization = emptyObject;
  
  if(!useDefaultTranslation){
    localization = {
      close: translateButtons('closeButton'),
      downloadEventLog: errorModalTranslate('downloadEventLogButton'),
      eventLogDownloadingEventLog: errorModalTranslate('eventLogDownloadingEventLogInfo'),
      preparingAdditionalDiagnosticInfo: errorModalTranslate('preparingAdditionalDiagnosticInfo'),
      additionalDiagnosticInfoReady: errorModalTranslate('additionalDiagnosticInfoReady')
    };
  }

  useEffect(() => {
		const error = form.errorFromService
		
		if(!isEmpty(error)){
			setOpen(true)
			setErrorClassObject({...errorClassObject, ...error})
		}

	}, [form.errorFromService])

  if (isEmpty(errorClassObject)) //Jeśli obiekt błędu jest pusty nic nie wyświetlamy 
		return null

  if(!errorClassObject?.shouldErrorModalAppear) // Właściwość mówiąca o tym czy okienko modalne z błędem ma się pojawić - jego wartość ustawiamy w pliku ErrorHandlingClasses
		return null

  return (
    <ErrorModalEx 
      open={open} 
      onClose={() => setOpen(false)} 
      errorObject={errorClassObject} 
      logSerializer={Logger.memorySinkSerializer}
      localization={localization}
     />
  )
};

export { ErrorModalEx, ErrorModalFormEx }
export default ErrorModalEx;