import { FormBase } from './FormBase'
import FormMetaObjectClass from '../../classes/FormMetaObject'

/**
 * Komponent FormMetaObject
 *
 * @param {function} createFormClass Funkcja zwracajaca klasę kontekstu
 * @param {*} data Dane początkowe formy
 * @param {Node} children Zawartość
 * @param {*} onLoadParam Parametry potrzebne do stwierdzenia czy trzeba redrawować komponent 
 * @param {FormMetaObject} formMetaControllerClassFactory Jest to metoda wytwórcza, która przyjmuje jeden parametr z konfiguracją
 * 				obiektu FormMetaObject i zwraca instancje obiektu FormMetaObject lub obiektu, który dziedziczy po tej klasie. Pozwala
 * @returns {Node}
 */
export default function FormMetaObject({ data, children, onChange, onLoadParam, redirectToLogin, errorModal, formMetaControllerClassFactory, ...other }) {
	return FormBase({
		data, children, onChange, onLoadParam, redirectToLogin, errorModal,
		createFormClass: (params) => formMetaControllerClassFactory ? formMetaControllerClassFactory(other) : new FormMetaObjectClass(other)
	})
}