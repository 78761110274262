
import LoggerSinkInterface from './LoggerSinkInterface';

/* 
Wysyła logi do natywnej konsoli.
*/
export default class NativeConsoleSink extends LoggerSinkInterface {
    get id(){
        return 'NativeConsole';
    }

    trace(module, ...args) {
        const prefix = `${module} |`;
        console.trace(prefix, ...args);
    }

    info(module, ...args) {
        const prefix = `${module} |`;
        console.info(prefix, ...args);
    }

    warn(module, ...args) {
        const prefix = `${module} |`;
        console.warn(prefix, ...args);
    }

    error(module, ...args) {
        const prefix = `${module} |`;
        console.error(prefix, ...args);
    }
}