import React from 'react'
import { ListGrid, Header, HeaderCell, CellSwitch, Rows, Row, HeaderGantt, RowsSwitch, CellBorder, RowsTypeGroup, Group, CellGroupBorder, GroupCell, Groups, RowsTypeTree, Tree, TreeRow, Trees, GroupRows } from 'components/form/components/list/components'

export function ListRawComponentsTree({ onKeyUp, onClickRow, onClickGroupBreakLine, onClickAdd, onDoubleClickRow, onClickTreeChild, onAdd, dataTypeAdd }) {
	return (
		<ListGrid onKeyUp={onKeyUp}>
			{/* <HeaderTreeIndent /> */}
			<Header>
				<HeaderCell />
			</Header>
			<HeaderGantt />

			<RowsSwitch>
				<Rows type='normal'>
					<Row onClick={onClickRow} onDoubleClick={onDoubleClickRow}>
						<CellBorder>
							<CellSwitch />
						</CellBorder>
					</Row>
				</Rows>
				<RowsTypeGroup type='group'>
					<Group>
						<GroupCell>
							<CellGroupBorder onClickGroupBreakLine={onClickGroupBreakLine}>
								<CellSwitch />
							</CellGroupBorder>
						</GroupCell>
						<GroupRows>
							<Row onClick={onClickRow} onDoubleClick={onDoubleClickRow}>
								<CellBorder>
									<CellSwitch />
								</CellBorder>
							</Row>
						</GroupRows>
						<Groups />
						{/* <GroupAddLine /> */}
					</Group>
				</RowsTypeGroup>
				<RowsTypeTree type='tree'>
					<Tree>
						<TreeRow>
							<Row onClick={onClickRow} onDoubleClick={onDoubleClickRow} onClickTreeChild={onClickTreeChild}>
								<CellBorder>
									<CellSwitch />
								</CellBorder>
							</Row>
						</TreeRow>
						<Trees />
					</Tree>
				</RowsTypeTree>
			</RowsSwitch>
		</ListGrid>
	)
}