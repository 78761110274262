const decoratorsId = {
    INS: 'INS',
    DEL: 'DEL',
    SOURCE_TEXT: 'SOURCE_TEXT',
    TAG: 'TAG',
    INS_TAG: 'INS_TAG',
    DEL_TAG: 'DEL_TAG',
    isInsertChangeTracking: (id) => {
        switch(id){
            case decoratorsId.INS:
            case decoratorsId.INS_TAG:
                return true;
            default:
                return false;
        }
    },
    isDeleteChangeTracking: (id) => {
        switch(id)
        {
            case decoratorsId.DEL:
            case decoratorsId.DEL_TAG:
                return true;
            default:
                return false;
        }
    },
    isChangeTracking: (id) => {
        return decoratorsId.isInsertChangeTracking(id) || decoratorsId.isDeleteChangeTracking(id);
    },
    isText: (id) => {
        switch(id)
        {
            case decoratorsId.SOURCE_TEXT:
            case decoratorsId.INS:
            case decoratorsId.DEL:
                return true;
            default:
                return false;
        }
    }
}

export default decoratorsId
