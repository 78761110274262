function compValue0(a, b) {
	if (a == null)
		if (b == null)
			return 0
		else
			return -1
	else
		if (b == null)
			return 1
		else
			if (a === b)
				return 0
			else
				return a > b ? 1 : -1
}

/**
 * Prównuje dwie wartości. (Takie jakie używamy w naszych FormMeta...)
 * @param {*} a wartość pierwsza
 * @param {*} b wartość druga
 * @return {number} Zwraca -1 jeśli b > a. Zwraca 1 jeśli a > b. Zwraca 0 jeśli a=b.
 */
export function compValue(a, b) {
	// W ten sposób sprowadzamy null i undefined do wspólnego mianownika
	if(a === null)
		a = undefined;
	if(b === null)
		b = undefined;

	if (typeof (a) !== 'object')
	{
		if (typeof (b) !== 'object')
			return compValue0(a, b)
		else
			return -1
	}
	else
	{
		if (typeof (b) !== 'object')
			return 1
		else
			return compValue0(a?.id, b?.id)
	}
}