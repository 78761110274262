import React, { useEffect, useState } from 'react'
import useForm from '../../hooks/useForm'
import Button from 'components/form/components/list/components/buttons/Button'
import { useTranslate } from 'locale/Locale'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, List, ListItem } from '@material-ui/core'
import { StyledHeaderText, StyledListItemText } from './ErrorModal.styled'
import { isEmpty, toString } from 'lodash'
import PropTypes from 'prop-types'
import { WindowOpen } from 'Tools/Tools'

/**
 * Komponent wyświetlający w zależności od zmiennej 'status' informacje o błędzie w ciele pojawiającego się
 * przy błędzie okienka modalnego
 * @param {Object} errorClassObject - Obiekt zawierający opis aktualnego błędu 
 * @returns 
 */
const ErrorModalContent = ({ errorClassObject }) => {

	if(isEmpty(errorClassObject)) //Jeśli obiekt błędu jest pusty nic nie wyświetlamy 
		return null

	const { status, detail, traceId, url, type, responseData } = errorClassObject

	if(errorClassObject.status === 0){ //Sytuacja gdy mamy doczynienia z błędem po stronie FE.
		return (
			<List dense>
				<ListItem style={{ marginBottom: "40px" }}>
					<StyledListItemText secondary={toString(detail)} />
				</ListItem>
				<ListItem>
					<StyledListItemText primary='Type: ' secondary={toString(type)} />
				</ListItem>
				<ListItem>
					<StyledListItemText primary='Status code: ' secondary={toString(status)} />
				</ListItem>
			</List>
		)
	}

	return (
		<List dense>
			<ListItem style={{marginBottom: "40px"}}>
					<StyledListItemText secondary={toString(detail)} />
			</ListItem>
			<ListItem>
				<StyledListItemText primary='Trace ID: ' secondary={toString(traceId)} />
			</ListItem>
			<ListItem>
				<StyledListItemText primary='Status code: ' secondary={toString(status)} />
			</ListItem>
			<ListItem>
				<StyledListItemText primary='Url: ' secondary={toString(url)} />
			</ListItem>
			<ListItem>
				<StyledListItemText primary='Data: ' secondary={<code>{JSON.stringify(responseData)}</code>} />
			</ListItem>
		</List>
	)
}

/**
 * Komponent modalnego okna wyświetlający szczegóły napotkanych błędów
 *
 * @returns {Node}
 */
const ErrorModal = () => {
	const form = useForm('data')
	const [open, setOpen] = React.useState(false)
	const [errorClassObject, setErrorClassObject] = useState({})
	const translate = useTranslate('WebSpa/Buttons')

	useEffect(() => {
		const error = form.errorFromService
		
		if(!isEmpty(error)){
			setOpen(true)
			setErrorClassObject({...errorClassObject, ...error})
		}

	}, [form.errorFromService])
	
	
	if (isEmpty(errorClassObject)) //Jeśli obiekt błędu jest pusty nic nie wyświetlamy 
		return null
	
	if(!errorClassObject?.shouldErrorModalAppear) // Właściwość mówiąca o tym czy okienko modalne z błędem ma się pojawić - jego wartość ustawiamy w pliku ErrorHandlingClasses
		return null

	const { title, status, stack, errorDetailsFromResponse } = errorClassObject

	const handleShowResponse = () => {
		if(status === 0){
			WindowOpen(stack)
		}
		else{
			WindowOpen(JSON.stringify(errorDetailsFromResponse))
		}	
	}

	return (
		<Dialog open={open} onClose={() => setOpen(false)}  fullWidth	maxWidth='sm'>
			<StyledHeaderText variant="h1" noWrap>{toString(title)}</StyledHeaderText>
			<Divider />
			<DialogContent>
				<ErrorModalContent errorClassObject={errorClassObject}/>
				<Button type='submit' content={"Show response"} onClick={() => handleShowResponse()} style={{margin: '15px 10px'}} />
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button  type='cancel' content={translate('closeButton')} onClick={() => setOpen(false)} />
			</DialogActions>
		</Dialog>
	)
}

//#region Export komponentów
	export default ErrorModal
//#endregion

//#region PropTypes & DefaultProps
ErrorModalContent.propTypes = {
	errorClassObject: PropTypes.object.isRequired
}

ErrorModalContent.defaultProps = {
	errorClassObject: {}
}
//#endregion