import styled, { css } from 'styled-components'
import { GetFromTheme } from './GetFromTheme'

/** Mixin margin'u. Działa dokladnie tak jak css: 'margin x x x x;' 
 * z taką różnicą że x może być pobrane z theme.
 * 
 * Przypomnienie parametrów:
 * margin top right bottom left;
 * 
 * Przypomnienie skrótów:
 * margin x; -> margin x x x x;
 * margin topBottom leftRight; -> margin topBottom leftRight topBottom leftRight;
 * margin top leftRight bottom; -> margin top leftRight bottom leftRight;
 * 
 * @param {Array} margin - Tablica margin'ów
 * @returns {Array}
 */
export function MixinMargin(margins) {
	if (!Array.isArray(margins) || margins.length === 0)
		return ''

	let f = margin => props => GetFromTheme(props, 'margin', margin, '0px')

	switch (margins.length) {
		case 1:
			return css`margin: ${f(margins[0])};`
		case 2:
			return css`margin: ${f(margins[0])} ${f(margins[1])};`
		case 3:
			return css`margin: ${f(margins[0])} ${f(margins[1])} ${f(margins[2])};`
		case 4:
			return css`margin: ${f(margins[0])} ${f(margins[1])} ${f(margins[2])} ${f(margins[3])};`
		default:
			return ''
	}
}

///TODO style
export const Margin = styled.div`
	${props => MixinMargin(props.margins)}
`