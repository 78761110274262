import React from 'react'
import useTmLookups from './hooks/useTmLookups';
import Paper from '@material-ui/core/Paper';
import MaterialTableMetaDriven from './MaterialTableMetaDriven';
import Typography from '@material-ui/core/Typography';

export default function TmLookups({ match, history }) {

	const tmLookups = useTmLookups();
	const resourceDocument = tmLookups.resource;

	return (
		<React.Fragment>
			<Paper elevation={3} style={{ 'margin': '2px' }}>
				<Typography variant="h6" component="h5" className='p-5'>
					Translation Memory
				</Typography>
				<MaterialTableMetaDriven
					meta={resourceDocument.meta}
					data={resourceDocument.data}
					isLoading={tmLookups.isLoading}
					style={{fontSize: '0.75rem'}}
					options={{
						paging: false,
						showTitle: false,
						emptyRowsWhenPaging: false,
						padding: 'dense',
						filtering: false,
						selection: false,
						search: false,
						toolbar: false,
						minBodyHeight: '150px',
						maxBodyHeight: '150px'
					}}
				/>
            </Paper>
		</React.Fragment>
	)
}