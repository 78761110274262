import { forEach } from 'jszip'
import _ from 'lodash'

export function toForm(form, values, { dependentObjectsFieldName, isDependentObjectFieldName }) {
	values = _.cloneDeep(values)

	const mapRows = new Map()
	const mapValues = new Map()
	const data = []

	form.data.data.forEach(row => mapRows.set(row.id, row))
	values.left.forEach(v => mapValues.set(v.id, v))
	values.right.forEach(v => mapValues.set(v.id, v))

	values.right.forEach(v => {
		if (!v.next)
			return
		v.next.forEach(id => {
			const value = mapValues.get(id)

			if (value.next)
				throw new Error('Wrong values in toForm')
			value.next = [v.id]
		})
		v.next = undefined
	})

	mapRows.forEach(row => {
		const value = mapValues.get(row.id)

		if ( value !== undefined ) {
			
			const dependentFilesIds = row.attributes?.[dependentObjectsFieldName]?.map(f => f.id)?.sort((a, b) => a - b) ?? []
			const nextIds = value.next?.sort((a, b) => a - b) ?? []

			if (dependentFilesIds.length !== nextIds.length || nextIds.some((n, i) => n !== dependentFilesIds[i])) {
			let newValue = null

			if (value.next)
				newValue = value.next.map(id => {
					return { id }
				})

			data.push({ id: row.id, attributes: { [dependentObjectsFieldName]: newValue }, type: form.dataType })
			}	
		}
	});


	return data
}