import React from 'react'
import { Grid, MixinGradientHidder, MixinFont } from 'components/topology/Topology'
import { useField } from 'components/form/Form'
import styled from 'styled-components'

///TODO style
const SegmentStyle = styled.div`
	${MixinGradientHidder({ paddingX: 'sm', paddingY: 'sm' })}
	border: solid 1px #eee;
	${MixinFont('s-sm')};
`

export default function SegmentGroup({ name }) {
	const field = useField(name)
	const segments = field.value

	if (!Array.isArray(segments))
		return <div />

	const tx = segments.map(s => s.value + 'fr').join(' ')

	return (
		///TODO style
		<Grid tx={tx} f='column'>
			{
				segments.map((s, index) => {
					return (
						<SegmentStyle key={index}>
							{s.label}
						</SegmentStyle>
					)
				})
			}
		</Grid>
	)
}