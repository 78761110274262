import React, { useState } from 'react'
import { useForm } from 'components/form/Form'
import { Horizontal } from 'components/topology/Topology'
import { Tooltip } from '@material-ui/core'
import { Css, withStyles } from 'cssStatic'
import Icon from 'components/icon/Icon'
import { GlobalStrings } from 'GlobalStrings'
import getFormRestService from 'services/getFormRestService'
import { useIconMessageDispatch, useIconMessageState, MESSAGE_ACTIONS } from 'components/AppMessages';
import Logger from 'Tools/Logging/Logger'

Css.add({
	'.tooltip-message': ['f-14'],
	'.icon-messages-icons': { 'cursor': 'pointer', 'font-size': '1.7rem' }, //Style wspólne dla wszystkich ikonek
	'.icon-messages-hourglass_empty-icon': ['icon-messages-icons', { 'color': 'var(--o-mainDarken900)' }],
	'.icon-messages-directions_run-icon': ['icon-messages-icons', { 'color': 'var(--o-mainDarken900)' }],
	'.icon-messages-info_outline-icon': ['icon-messages-icons', { 'color': 'var(--o-mainDarken900)', }],
	'.icon-messages-warning_amber-icon': ['icon-messages-icons', { 'color': 'var(--o-warning)', }],
	'.icon-messages-error-icon': ['icon-messages-icons', { 'color': 'var(--o-errorAlternative)' }],
	'.icon-messages-verified_user-icon': ['icon-messages-icons', { 'color': 'var(--o-success)' }],
	'.icon-messages-assignment-icon': ['icon-messages-icons', { 'color': 'var(--o-success)' }],
});

const StyledTooltip = withStyles({ tooltip: 'tooltip-message' }, Tooltip)

/**
 * Funkcja obsługująca powiadomienie do użytkownika w formie ikonki
 * @param {*} isList Informacja czy znajdujemy się w kontekście listy
 */
export function IconMessages({ isList }) {
	const form = useForm('data')

	const iconMessageState = useIconMessageState();
	const iconMessageDispatch = useIconMessageDispatch();

	const resourceObject = form?._data?.data[0];

	if(!resourceObject)
		return;

	const messages = iconMessageState.filter(m => m.resourceDataTypeName === form.dataType
		 && resourceObject.id === m.resourceId ).sort(m=>m.operationType);

	const linkExists = resourceObject.links[GlobalStrings.UpdateNotifySourceMessageLinkName];

	const handleClick = async (message) => {
		
		if (!resourceObject || !message.couldBeCleared)
			return;

		let link;

		if (resourceObject.links) {

			link = resourceObject.links[GlobalStrings.UpdateNotifySourceMessageLinkName];

			if (!link) {
				return;
			}

			link.href = link.href.replace("api/v1/", "/");
			link = link.href.replace("{notificationOwnerId}", resourceObject.id);

			try {
				const response = await getFormRestService(link)
					.patchNotificationState(resourceObject.id, resourceObject.type, message.operationType);
				if (response) {
					iconMessageDispatch({ type: MESSAGE_ACTIONS.REMOVE_MESSAGE, payload: { id: message.correlationId } });
				}
			} catch (err) {
				Logger.of('PatchNotificationState').error(err, 'Nieudana próba wygaszenia notyfikacji');
			}
		}
	};
	return (<Horizontal className='grid-acx-start'>
		{
			messages.map(message => {
				switch (message.operationState) {
					case 10://Start
						return <MessageIcon message={message} handleClick={handleClick} linkExists={linkExists} iconName={'hourglass_empty'}/>;
					case 30://Processing
						return <MessageIcon message={message} handleClick={handleClick} linkExists={linkExists} iconName={'directions_run'}/>;
					case 40://Proposal
						return <MessageIcon message={message} handleClick={handleClick} linkExists={linkExists} iconName={'assignment'}/>;			
					case 50://Info
						return <MessageIcon message={message} handleClick={handleClick} linkExists={linkExists} iconName={'info_outline'}/>;
					case 60://InfoWarnining 
						return <MessageIcon message={message} handleClick={handleClick} linkExists={linkExists} iconName={'warning_amber'}/>;
					case 100://Failure
						return <MessageIcon message={message} handleClick={handleClick} linkExists={linkExists} iconName={'error'}/>;
					case 110://Finished
						return <MessageIcon message={message} handleClick={handleClick} linkExists={linkExists} iconName={'verified_user'}/>;
				}
			})
		}
	</Horizontal>);
}

function MessageIcon({ message, handleClick, linkExists, iconName }) {

	const [isHovering, setIsHovering] = useState(false);

	return (<div onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)} onClick={() => handleClick(message)}>
		<StyledTooltip title={message.operationDepiction}>
			<Icon
				name={isHovering && linkExists && message.couldBeCleared ? 'block' : iconName}
				className={`icon-messages-${iconName}-icon`}
			/>
		</StyledTooltip>
	</div>);
}