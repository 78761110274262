import { Box, IconButton, Paper, withStyles } from '@material-ui/core';
import React from 'react'
import Tooltip from '../Tooltip';
import localConfiguration from 'contents/cat/model/LocalConfiguration';
import { useConfigurationBool } from 'contents/cat/hooks/useConfiguration';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SidebarContentDocuments from './SidebarContentDocuments';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import SidebarContentComments from './SidebarContentComments';
import RightSidebarContent from './RightSidebarContent';

const ConfigIsContentOpen = "Components:Editor:RightSidebar:IsContentCommentsOpen";
localConfiguration.registerDefaultBool(ConfigIsContentOpen, true);

const StyledSidebarButton = withStyles(() => ({
	root: {
		padding: 1
	}
}))(IconButton);

const SidebarButton = ({label, children, onClick}) => {
    return (
        <Tooltip title={label} placement="right" arrow >
            <StyledSidebarButton onClick={onClick}>
                {children}
            </StyledSidebarButton>
        </Tooltip>
    );
};

const IconStyleDefault = {color: '#576d85'};

const RightSidebar = ({...other}) => {

    const [isContentOpen, setIsContentOpen] = useConfigurationBool(ConfigIsContentOpen);
    const onCloseContentClick = (event: any) => {
        setIsContentOpen(false);
    }
    const onOpenContentClick = (event: any) => {
        setIsContentOpen(true);
    }

    return (
        <Paper {...other} >
            {isContentOpen && 
                <RightSidebarContent title={'Comments'} onCloseClick={onCloseContentClick} >
                    <SidebarContentComments />
                </RightSidebarContent>
            }
            
            <Box id="editor-right-sidebar-box-buttons" style={{paddingTop: '4px', width: '37px' }}>
                <SidebarButton label='Comments' onClick={onOpenContentClick}>
                    <CommentOutlinedIcon style={IconStyleDefault} fontSize='large' />
                </SidebarButton>
            </Box>
            
        </Paper>

    );
};

export default RightSidebar;