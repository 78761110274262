import React, { useEffect } from 'react'
import { ListRaw } from 'components/form/components/list/components/ListRaw'
import { FormMetaArray } from 'components/form/Form'
import useForm from 'components/form/hooks/useForm'
import { useTranslate } from 'locale/Locale'
import Button from 'components/form/components/list/components/buttons/Button'
import { StyledDialogActions, StyledDialogContent } from './StyledDialogModal'
import { FilterButton } from './FilterButton'
import { isEmpty } from 'lodash'
import { shouldColumnBeVisibleInFilterView } from '../columns/ColumnList';
import { GlobalStrings } from 'GlobalStrings'


/**
 * Nazwa pola służącego do przekazania informacji do pola typu ComboboxRefList, 
 * że należy użyć innej nazwy pola niż jest ustawiona w Field. 
 * Wynika to z tego, że w okienku do filtrowania pola z wartościami do filtrowania mają inne nazwy (np. Value, From, To) niż linki przesyłane do FE
 */
export const FIELD_REF_LINK_NAME = 'useFieldMetaName';

export default function FilterList({ close, dialogContentId }) {
	const form = useForm('data')
	const cols = form.columnManager.columnArray
		.filter(shouldColumnBeVisibleInFilterView)
		.filter(c => 
			{ 
				if(window.name.startsWith(GlobalStrings.CrmOffersWindowName)) {
					return c.name !== GlobalStrings.OfferCustomerRefFieldName; 
				}
				return true;
		 });

	const { filterManager } = form
	const translate = useTranslate('WebSpa/FilterModal')
	const translateButtons = useTranslate('WebSpa/Buttons')
	useEffect(() =>{
		console.log("On mount")
		return () => {
			console.log("On unmount")
		}
	})

	const emptyMetaAttribute = { 'name': GlobalStrings.EmptyFilterAttibuteName, 'label': translate('emptyValue'), 'type': 'Bool', 'uiInputType': 'Indeterminate', 'editableOnUpdate': true, 'sequenceIndex': 60, 'sections': ['user', 'list'], 'validations': [] };
	let data = {
		meta: {
			attributes: {
				'FieldLabel': { 'name': 'FieldLabel', 'label': translate('columnName'), 'type': 'String', 'editableOnUpdate': false, 'sequenceIndex': 10, 'sections': ['user', 'list'], 'validations': [{ 'type': 'Required' }] },
				'FieldName': { 'name': 'FieldName', 'label': 'Nazwa pola', 'type': 'String', 'editableOnUpdate': false, 'sequenceIndex': 0, 'sections': ['user',], 'validations': [] },
			}
		},
		data:
			cols.map(col => {

				switch (col.meta.type) {
					case 'Int':
					case 'Number':
					case 'Date':
					case 'Timestamp': {
						return {
							id: col.name,
							attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'From': filterManager.getSavedValues(col.name, 'from'), 'To': filterManager.getSavedValues(col.name, 'to'), 'Empty': filterManager.getSavedValues(col.name, GlobalStrings.EmptyFilterAttibuteName) },
							links: {},
							meta: {
								attributes: {
									'From': { 'name': 'From', 'label': translate('fromValue'), 'type': col.meta.type, 'editableOnUpdate': true, 'sequenceIndex': 40, 'sections': ['user', 'list'], 'validations': [] },
									'To': { 'name': 'To', 'label': translate('toValue'), 'type': col.meta.type, 'editableOnUpdate': true, 'sequenceIndex': 50, 'sections': ['user', 'list'], 'validations': [] },
									'Empty': emptyMetaAttribute,
								}
							}
						}
					}			
					case 'Ref':
						{
							let links;
							if (form.getLinkHRef) {
								const linkName = 'get' + col.name;
								const fieldHrefLink = form.getLink(linkName);
								links = [];
								links[linkName] = fieldHrefLink;

								//musimy sprawdzić czy dany link referencyjny wymaga parametrów, jeśli tak to nie możemy go użyć
								let linkRequiresParams = true;
								
								// szukamy parametrów w linku
								if (fieldHrefLink?.href) {
									const linkParams = Array.from(fieldHrefLink.href.matchAll(/\{(.*?)\}/g));

									if (linkParams.length===1) {
										// jedynym możliwym parametrem w linku jest id organizacji - jest on uzupełniany
										const isParamOrgId = linkParams.filter(el => el[1] === 'organizationId').length === 1;
										if (isParamOrgId) {
											linkRequiresParams = false;
										}
									} else if (linkParams.length === 0){
										linkRequiresParams = false;
									}
								}
					
								// W przypadku kiedy nie istnieje odpowiedni link zwracamy kontrolkę 
								// do filtrowania po pojedynczej wartości stringa (nie ma obsługi po BE dla filtrowania po wielu wartościach stringowych dla typuów referencyjnych)
								if (linkRequiresParams) {
									const filterTypesValues = filterManager.enumFilterTypes //Pobieranie wartości wyświetlanych jako opcje w combobox
									return {
										id: col.name,
										attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'ComparisionOperator': filterManager.getSavedValues(col.name, 'comparisionOperator'), 'Value': filterManager.getSavedValues(col.name, 'value'), 'Empty': filterManager.getSavedValues(col.name, GlobalStrings.EmptyFilterAttibuteName) },
										links: {},
										meta: {
											attributes: {
												'Value': { 'name': 'Value', 'label': translate('searchedValue'), 'type': 'String', 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'validations': [] },
												'ComparisionOperator': { 'name': 'ComparisionOperator', 'label': 'Typ', 'type': 'Enum', 'editableOnUpdate': true, 'sequenceIndex': 20, 'sections': ['list', 'user'], 'values': filterTypesValues, 'validations': [] },
												'Empty': emptyMetaAttribute,
											}
										}
									};
								}
							}

							const value = {
								'name': 'Value', 'label': translate('searchedValue'), 
								'type': 'Ref', 'editableOnUpdate': true, 'sequenceIndex': 30,
								'sections': ['user', 'list'], 'validations': [], uiInputType: "MultipleChoiceCombo"
							}

							value[FIELD_REF_LINK_NAME] = col.name;

							return {
								id: col.name,
								attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'Value': filterManager.getSavedValues(col.name, 'value'), 'Empty': filterManager.getSavedValues(col.name, GlobalStrings.EmptyFilterAttibuteName) },
								links: links,
								meta: {
									attributes: {
										'Value': value,
										'Empty': emptyMetaAttribute,
									}, 
								}
							}
						}
					case 'String':
					case 'File':  {
						const filterTypesValues = filterManager.enumFilterTypes //Pobieranie wartości wyświetlanych jako opcje w combobox
						return {
							id: col.name,
							attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'ComparisionOperator': filterManager.getSavedValues(col.name, 'comparisionOperator'), 'Value': filterManager.getSavedValues(col.name, 'value'), 'Empty': filterManager.getSavedValues(col.name, GlobalStrings.EmptyFilterAttibuteName) },
							links: {},
							meta: {
								attributes: {
									'Value': { 'name': 'Value', 'label': translate('searchedValue'), 'type': 'String', uiInputType: "InputTags", 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'validations': [] },
									'ComparisionOperator': { 'name': 'ComparisionOperator', 'label': 'Typ', 'type': 'Enum', 'editableOnUpdate': true, 'sequenceIndex': 20, 'sections': ['list', 'user'], 'values': filterTypesValues, 'validations': [] },
									'Empty': emptyMetaAttribute,
								},
							}
						};
					}					
					case 'Bool': {
						return {
							id: col.name,
							attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'Value': filterManager.getSavedValues(col.name, 'value'), },
							links: {},
							meta: {
								attributes: {
									'Value': { 'name': 'Value', 'label': translate('searchedValue'), 'type': col.meta.type, 'uiInputType': 'Indeterminate', 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'validations': [] },
								}
							}
						}
					}
					case 'Enum': {
						return {
							id: col.name,
							attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'Value': filterManager.getSavedValues(col.name, 'value'), 'Empty': filterManager.getSavedValues(col.name, GlobalStrings.EmptyFilterAttibuteName) },
							links: {},
							meta: {
								attributes: {
									'Value': { 'name': 'Value', 'label': translate('searchedValue'), 'type': 'EnumList', 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'values': col.meta.values, 'validations': [] },
									'Empty': emptyMetaAttribute,
								}
							}
						}
					}
					case 'EnumList': {
						const valuesArray = isEmpty(col.meta?.values) ? [] : col.meta?.values 

						return {
							id: col.name,
							attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'Value': filterManager.getSavedValues(col.name, 'value'), 'Empty': filterManager.getSavedValues(col.name, GlobalStrings.EmptyFilterAttibuteName) },
							links: {},
							meta: {
								attributes: {
									'Value': { 'name': 'Value', 'label': translate('searchedValue'), 'type': 'Enum', 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'values': valuesArray, 'validations': [] },
									'Empty': emptyMetaAttribute,
								}
							}
						}
					}
					case 'FileList': {
						const refValuesArray = isEmpty(col.meta?.refValues) ? [] : col.meta.refValues 

						return {
							id: col.name,
							attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'Value': filterManager.getSavedValues(col.name, 'value'), 'Empty': filterManager.getSavedValues(col.name, GlobalStrings.EmptyFilterAttibuteName) },
							links: {},
							meta: {
								attributes: {
									'Value': { 'name': 'Value', 'label': translate('searchedValue'), 'type': 'Enum', 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'validations': [], 'values': refValuesArray },
									'Empty': emptyMetaAttribute,
								}
							}
						}
					}
					default: {
						const filterTypesValues = filterManager.enumFilterTypes //Pobieranie wartości wyświetlanych jako opcje w combobox

						return {
							id: col.name,
							attributes: { 'FieldLabel': col.meta.label, 'FieldName': col.meta.name, 'Value': '' },
							links: {},
							meta: {
								attributes: {
									'Value': { 'name': 'Value', 'label': translate('searchedValue'), 'type': 'String', 'editableOnUpdate': true, 'sequenceIndex': 30, 'sections': ['user', 'list'], 'validations': [] },
									'ComparisionOperator': { 'name': 'ComparisionOperator', 'label': 'Typ', 'type': 'Enum', 'editableOnUpdate': true, 'sequenceIndex': 20, 'sections': ['user','list'], 'values': filterTypesValues, 'validations': [] }
								}
							}
						}
					}
				}
			})
	}

	return (
		<FormMetaArray
			editMode='edit'
			data={data}
			inlineEdition
			configurationPath='formMetaArray_filter'
			onRowCreate={async form => {

			}}
			onRowUpdate={async form => {
				form.changesToValues()
				return true
			}}
			onRowDelete={async form => {

			}}
			onCanRowCreate={form => {
				return false
			}}
			onCanRowUpdate={form => {
				return true
			}}
			onCanRowDelete={form => {
				return false
			}}

		>
			<StyledDialogContent id={dialogContentId}>
				<ListRaw />
			</StyledDialogContent>
			<StyledDialogActions>
				<FilterButton close={close} />
				<Button type='delete' onClick={() => filterManager.resetForm()} content={translate('clearButton')} />
				<Button type='cancel' onClick={close} style={{ padding: '5px 9px 5px' }} content={translateButtons('closeButton')} />
			</StyledDialogActions>
		</FormMetaArray>
	)
}