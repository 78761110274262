import { useTrackedState, useDispatch } from 'contents/cat/store/global'
import { useMemo } from 'react';

const useUser = () => {
    const globalState = useTrackedState();
    const globalDispatch = useDispatch();

    return useMemo(() => {
        return {
            isLoggedIn: globalState.session.user.isLoggedIn,
            accessToken: globalState.session.user.accessToken,
            accessTokenExpiration: globalState.session.user.accessTokenExpiration,
            refreshToken: globalState.session.user.refreshToken,
            refreshTokenExpiration: globalState.session.user.refreshTokenExpiration,
            taskToken: globalState.session.user.taskToken,
            authSuccess: (accessToken, accessTokenExpiration, refreshToken, refreshTokenExpiration, taskTokenValue) => {
                globalDispatch({
                    type: 'AUTH_TASK_SUCCESS',
                    'accessToken': accessToken,
                    'accessTokenExpiration': accessTokenExpiration,
                    'refreshToken': refreshToken,
                    'refreshTokenExpiration': refreshTokenExpiration,
                    'taskToken': taskTokenValue
                });
            }
        }
    }, [globalState.session.user])
}

export default useUser;