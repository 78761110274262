import { makeStyles } from '@material-ui/core'

export const useTextAreaStyles = makeStyles(({ palette }) => ({
	root: {
		justifyContent: 'center',
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'rgba(0, 0, 0, 0.8)',
				borderRadius: props => props.hasLabel === false ? 0 : '4px',

				'& legend': {
					fontSize: '0.85em !important'
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			'& fieldset': {
				borderColor: 'rgba(0, 0, 0, 0.4)',
				borderRadius: props => props.hasLabel === false ? 0 : '4px',
			},
		},
		'& .MuiInputLabel-root.MuiFormLabel-root.Mui-disabled ': {
			color: 'rgba(0, 0, 0, 0.6)',
		},
		'& .MuiInputLabel-root.MuiFormLabel-root ': {
			color: 'rgba(0, 0, 0, 0.9)',
			fontSize: '18px'
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.6)',
		},
		'& .MuiInputLabel-root.MuiFormLabel-root.MuiFormLabel-root.Mui-error': {
			'& fieldset': {
				borderColor: palette.primary.error,
				borderRadius: props => props.hasLabel === false ? 0 : '4px',
			},
			color: '#f44336'
		},
		'& .MuiInputLabel-root.MuiFormLabel-root.Mui-focused': {
			color: '#316197'
		},
		'& .MuiFormHelperText-root': {
			position: 'absolute',
			bottom: '-13px',
			left: '0px',
			whiteSpace: 'nowrap'
		},
		'& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
			paddingTop: '14px'
		}
	}
}))