import { IEntityContextStateController, IEntityContextStateReadOnlyController } from "../contexts/data/DataContextModel";
import { IDocumentCommentDto } from "./DocumentComment";
import { DocumentCommentChangeState } from "./EditorMediator";
import { removeFromArrayByIndex } from "./Utils";

export interface ITranslationUnitDto 
{
    seqNum?: number,
    source?: any,
    target?: any,
    confirmationLevel?: number,
    isLocked?: boolean,
    isEnabled?: boolean,
    score?: number,
    qeScore?: number,
    origin?: string,
    isContextMatch?: boolean,
    providerName?: string,
    messages?: any,
    comments?: IDocumentCommentDto[]
}


// export function findEntityIdBySequenceNumber(sequenceNumber: number, contextController: IEntityContextStateReadOnlyController): string | null
// {
//     for(const entityId of contextController.getEntityOrderSet())
//     {
//         const entityDto = contextController.getEntityActual(entityId) as ITranslationUnitDto;
//         if(entityDto.seqNum === sequenceNumber)
//             return entityId;
//     }
// }

export function addComments(changeState: DocumentCommentChangeState, contextController: IEntityContextStateController)
{
    const translationUnit = contextController.getEntityActual(changeState.tuSequenceNumber.toString());
    if(!translationUnit)
        return;

    const translationUnitDto = translationUnit as ITranslationUnitDto;
    if(!translationUnitDto.comments)
    {
        translationUnitDto.comments = [];
    }
    
    for(const commentDto of changeState.objects)
    {
        translationUnitDto.comments.push(commentDto as IDocumentCommentDto);
    }
}

export function removeComments(changeState: DocumentCommentChangeState, contextController: IEntityContextStateController)
{
    if(changeState.tuSequenceNumber != null)
    {
        removeCommentsFromTu(changeState, contextController);
    }
    else
    {
        removeAllCommentsFromTus(contextController);
    }
}

function removeCommentsFromTu(changeState: DocumentCommentChangeState, contextController: IEntityContextStateController)
{
    const translationUnit = contextController.getEntityActual(changeState.tuSequenceNumber.toString());
    if(!translationUnit)
        return;

    const translationUnitDto = translationUnit as ITranslationUnitDto;
    if(!translationUnitDto?.comments)
        return;

    for(const commentDto of changeState.objects)
    {
        const indexOfComment = translationUnitDto.comments.findIndex((tuComment) => tuComment.id === commentDto.id);
        if(indexOfComment >= 0)
            removeFromArrayByIndex(indexOfComment, translationUnitDto.comments);
    }
}

function removeAllCommentsFromTus(contextController: IEntityContextStateController)
{
    for(const entityId of contextController.getEntityOrderSet())
    {
        const translationUnitDto = contextController.getEntityActual(entityId) as ITranslationUnitDto;
        if(!translationUnitDto?.comments)
            continue;

        translationUnitDto.comments = null;
    }
}