import React from 'react'
import PropTypes from 'prop-types'
import useField from '../../hooks/useField'
import { StyledTooltip } from './Label.styled'
import { StyledLabel } from './Label.styled'

/**
 * Komponent labelki dla pól formy
 *
 * @method Label
 * @param {string} name Nazwa pola
 * @param {string} label Labelka pola
 * @returns {Node}
 */
export default function Label({ name, label, ...other }) {
	const field = useField(name)
	const { error, required, disabled } = field

	return (
		<StyledLabel
			htmlFor={name}
			error={error}
			disabled={disabled}
			required={required}
			{...other}
		>
			<StyledTooltip title={error ?? ""} placement='right-start'>
				<div>
					{`${label ? label : field.label}${required ? " * " : ""}`}
				</div>
			</StyledTooltip>
		</StyledLabel>
	)
}

Label.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string
}
