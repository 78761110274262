/**
 * Funkcja pobierająca wartość z tematu.
 * Jeśli value nie ma w themacie to zwracane value
 * Jeśli !value to zwracana wartość defaultValue z tematu
 *
 * @param {object} props Props'y 
 * @param {string} themaPropName Nazwa wartości z tematu
 * @param {*} value Wartość do pobrania
 * @param {*} defaultValue Domyslna wartość (jeśli nie ustawione value)
 * @returns {*} Wartość pobrana z tematu
 */
export function GetFromTheme(props, themaPropName, value, defaultValue) {
	if (!value)
		value = defaultValue

	if (!props || !props.theme)
		return value

	const themeValues = props.theme[themaPropName]

	if (!themeValues || !themeValues[value])
		return value

	return themeValues[value]
}


