/**
	##### Pełna Dokumentacja kontrolki: https://material-ui.com/api/button/ ######
	Komponent Buttona używany do budowania różnego rodzaju przycisków w aplikacji. 
*/

import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton } from './Button.styled'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'
import { TooltipOnlyWhenEllipsisActive } from 'components/Tooltip'
import { ButtonWithModal } from './ButtonWithModal'

export class ButtonsFactoryClass {
	/**
	 * Obiekt w którym znajdują się wszystkie możliwe wartości atrybutu type
	 */
	static TYPES = Object.freeze({
		SUBMIT: 'submit',
		CANCEL: 'cancel',
		DELETE: 'delete',
		ICON: 'icon',
		DEFAULT: 'default',
	})

	/**
	 * Metoda tworząca komponent przycisku w zależności od przekazanego parametru
	 * @param {string} type - Typ przycisku, możliwe wartości które przyjmuje przycisk są zapisane w obiekcie  TYPES
	 * @param {Object} props - Propsy do zaaplikowania do komponentu <StyledButton />
	 * @returns Komponent <StyledButton /> z odpowiednim typem przekazanym poprzez parametr type
	 */
	static createButton(type, props) {
		const { content, icon } = props

		switch (type) {

			case this.TYPES.SUBMIT: { return <StyledButton {...props} color="primary" variant="contained" ><ButtonContent content={content}/></StyledButton> }

			case this.TYPES.CANCEL: { return <StyledButton {...props} variant="outlined" ><ButtonContent content={content}/></StyledButton> }

			case this.TYPES.DELETE: { return <StyledButton {...props} color="secondary" variant="contained"><ButtonContent content={content}/></StyledButton> }

			case this.TYPES.ICON: { return <StyledButton {...props} color="primary" variant="outlined">{icon}</StyledButton> }

			case this.TYPES.DEFAULT: { return <StyledButton {...props} ><ButtonContent content={content}/></StyledButton> }

			default: { return <StyledButton color="default" {...props} ><ButtonContent content={content}/></StyledButton> }
		}
	}
}

/**
 * Wrapper oplatający Buttona w komponent Tooltip. Używa metody isEllipsisActive() sprawdzającej czy 
 * w buttonie wyświetla się cała treść podanego tekstu, czy jest ona ograniczona przez wielokropek dostarczony wraz z CSS-ową metodą "text-overflow: ellipsis"
 * Jeśli  tekst wyświetla się z kropkami na końcu, wtedy komponent pozwala na pojawienie się Tooltipa w celu zwiększenia czytelności zawartości danego przycisku
 * @param {String} content - Zawartość przycisku do wyświetlenia
 * @returns {Node}
 */

const ButtonContent = ({ content }) => {

	return (
		<TooltipOnlyWhenEllipsisActive title={content} >
			<Box textOverflow="ellipsis" overflow="hidden">{ content }</Box>
		</TooltipOnlyWhenEllipsisActive>
	)
}

/**
//TODO BUTTON - ZMIENIĆ OPIS DLA NOWYCH PRZYCISKÓW 
 * Przycisk korzystający z ostylowanego komponentu <Button /> z biblioteki MaterialUI
 * Jego zadaniem jest tworzenie odpowiedniego przycisku na podstawie wartości przekazanych w propsach
 * @param {String} id id przekazywane do komponentu <button /> w celu testów automatycznych
 * @param {String} color kolor przycisku, może przyjmować wartości opisane w sekcji PropTypes & DefaultProps.
 * @param {String} variant wariant przycisku, może przyjmować wartości opisane w sekcji PropTypes & DefaultProps
 * @param {Node} content Zawartość przycisku, może nim być tekst lub ikona
 * @param { Object } button Obiekt przycisku pochodzący z BE
 * @param {*} other Dodatkowe propsy przekazywane do komponentu <Button /> z biblioteki MaterialUI
 * @returns {Node}
 */

const Button = ({ type, button, id, color, variant, content, ...other }) => {

	// Jeśli z BE przyjdzie obiekt confirmation (NAWET GDY JEST PUSTY)
	// oznacza to że chcemy wyświetlić modala z potwierdzeniem operacji
	if(!isEmpty(button) && button.confirmation){
		return (
			<ButtonWithModal 
				button={button}
				buttonType={type}
				buttonAction={other.onClick} //Przekazujemy domyślną funkcję onClick zostanie ona wywołana dopiero po kliknięciu przycisku w modalu
				render={(setOpen) => { //Render props pattern
					other.onClick = () => setOpen(true) //Nadpisujemy domyślną akcję przycisku, tak aby wyświetlał on okienko modalne po kliknięciu
					return ButtonsFactoryClass.createButton(type, { id, color, variant, content, ...other })
				}}
			/>
		)
	}

	return ButtonsFactoryClass.createButton(type, { id, color, variant, content, ...other })

}

/* #region Export komponentów */
//export default React.memo(Button, arePropsEqual)
export default Button
/* #endregion */

/* #region  PropTypes & DefaultProps */
Button.propTypes = {
	type: PropTypes.oneOf(Object.values(ButtonsFactoryClass.TYPES)),
	button: PropTypes.object,
	id: PropTypes.string,
	color: PropTypes.oneOf(['primary', 'secondary', 'default', 'inherit']),
	variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.elementType
	])
}

Button.defaultProps = {
	type: 'default'
}

/* #endregion */
