import React from 'react'
import {  useForm } from 'components/form/Form'
import Icon from 'components/icon/Icon'

export const AddChildButton = ({ onClick }) => {
	const form = useForm('data')

	if (!form.serviceCreateTreeChildHRef)
		return null

	return (
		<Icon
			name='add'
			onClick={onClick}
		/>
	)
}