import useSWR from 'swr'
import useUser from 'contents/cat/hooks/useUser';
import getCatOnlineService from 'services/CatOnlineService';
import useGlobalError from './useGlobalError';
import { useEffect } from 'react';

const fetchFromApi = async (user, endpointName, ...args) => {
	
	let catOnlineServiceConfig = {};
	if (user.isLoggedIn) {
		catOnlineServiceConfig.authToken = user.accessToken;
	}
	const catOnlineService = getCatOnlineService(catOnlineServiceConfig);
	const result = await catOnlineService[endpointName](...args);
	return result;
}


const useMySwr = (keys) => {
	const user = useUser();
	const globalError = useGlobalError();
	const { data, error } = useSWR([user, ...keys], fetchFromApi)

	useEffect(() => {
		if(error){
			globalError.setError(error);
		}
	}, [error]);

	return {
		data,
		error,
		isLoading: !error && !data
	};
}

export default useMySwr