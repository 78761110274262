import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress, Divider } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import logo from 'icons/logo/livocat.png'
import Backdrop from '@material-ui/core/Backdrop';
import CircularPreloader from 'components/preloader/CircularPreloader';
import useSessionTask from 'contents/cat/hooks/useSessionTask';
import useRequestState from './hooks/useRequestState';
import ErrorModal from './ErrorModal';
import ButtonWithPreloader from './components/buttons/Button'
import stringResources from './StringResources';
import useGlobalError from './hooks/useGlobalError';
import { Provider as DataContextProvider } from './contexts/data/DataContext';
import {performApiRequest} from './contexts/data/LivoCatApiDataMapper';
import useUser from './hooks/useUser';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const navigationTabs = {
    editor: {
        index: 1,
        route: '/cat/task/editor'
    },
    documents: {
        index: 0,
        route: '/cat/task'
    }
}

const routeToNavigationTabIndex = (location) => {
    for (const navigationTabName in navigationTabs) {
        const navigationTab = navigationTabs[navigationTabName];
        if (location.startsWith(navigationTab.route)) {
            return navigationTab.index;
        }
    }

    return 0;
};

const shouldNavigationBeVisible = (location) => {
    return !location.startsWith('/cat/auth');
}

const Layout = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const user = useUser();
    const currentTask = useSessionTask();
    const currentTaskRef = useRef(null);
    const currentRequest = useRequestState();
    const globalError = useGlobalError();

    const isNavigationVisible = shouldNavigationBeVisible(location.pathname);
    const isCanCompleteButtonActive = currentTask.canComplete;
    const isGlobalPreloaderVisible = currentRequest.isLoading;
    const actualPageIndex = routeToNavigationTabIndex(location.pathname);
    const [pageIndex, setPageIndex] = React.useState(actualPageIndex);
    const [IsOpenFinishJobDialog, setIsOpenFinishJobDialog] = React.useState(false);
    const [isLoadingTranslationProblems, setIsLoadingTranslationProblems] = React.useState(false);
    const [translationProblems, setTranslationProblems] = React.useState(null);

    useEffect(() => {
        globalError.setError(currentRequest.request?.error);
    }, [currentRequest.request?.error]);

    useEffect(() => {
        // Chodzi o to, że chcemy przeładować ekran (odświezyć segmenty) po kliknięciu 
        // Finish Job. Traktujemy to jako rozwiązanie tymczasowe. Dopóki nie poprawimy źródła problemu.
        if (currentTaskRef.current?.canComplete && !currentTask.canComplete) {
            window.location.reload(false);
        }
        currentTaskRef.current = currentTask;
    }, [currentTask.canComplete]);

    const handleTabChange = (event, newValue) => {
        setPageIndex(newValue);
        history.push(event.currentTarget.attributes['data-route'].value);
    };

    const handleFinishTaskClick = () => {
        setIsOpenFinishJobDialog(true);
        
        const loadTranslationProblems = async () =>
        {
            setIsLoadingTranslationProblems(true);
            try
            {
                const requestResult = await performApiRequest(user, "GET", "getProjectTaskProblems", [currentTask.projectId, currentTask.id]);
                if(requestResult?.ok && requestResult.resource?.messages)
                {
                    setTranslationProblems(requestResult.resource.messages.map(m => m.text));
                }
                else
                {
                    setTranslationProblems(null);
                }
            }
            catch(error)
            {
                console.error(error);
            }
            finally
            {
                setIsLoadingTranslationProblems(false);
            }
        }
        
        loadTranslationProblems();
    };

    const handleFinishTaskConfirmationYesClick = () => {
        currentTask.completeTask();
        setTranslationProblems(null);
        setIsOpenFinishJobDialog(false);
    }

    const finishJobButtonStrings = isCanCompleteButtonActive ? 
        currentRequest.request?.isLoading ? stringResources.finishJobButton.loading : stringResources.finishJobButton.active : 
        stringResources.finishJobButton.inactive;

    return (
        <DataContextProvider>
            <div className={classes.root} style={{'display': 'flex', 'flexDirection': 'column', 'height': '100vh'}}>
                <AppBar position="sticky" style={{'flex': '0 1 auto'}}>
                    <Toolbar style={{ 'minHeight': '48px'}}>
                        <Typography variant="h6">
                            <img src={logo} alt="livocat" style={{width: "125px", margin: 'auto', marginRight: '10px', display: 'block'}}/>
                        </Typography>
                        {currentTask.taskName &&
                            <>
                                <Divider orientation='vertical' />
                                <Typography variant="h7" style={{paddingLeft: '10px'}}>
                                    {currentTask.taskName}
                                </Typography>
                            </>
                        }
                        {isNavigationVisible &&
                            <React.Fragment>
                                <Typography variant="h6" className={classes.title}>
                                    <Tabs
                                        value={pageIndex}
                                        onChange={handleTabChange}
                                        centered
                                    >
                                        {!currentTask.useMinimalCatFeatures && 
                                            <Tab label="Project" index={navigationTabs.documents.index} data-route={navigationTabs.documents.route} />
                                        }
                                        
                                        <Tab label="Editor" index={navigationTabs.editor.index} data-route={navigationTabs.editor.route} />
                                    </Tabs>
                                </Typography>
                                <Typography variant="h6">
                                    <ButtonWithPreloader 
                                        label={finishJobButtonStrings.label} 
                                        tooltip={finishJobButtonStrings.tooltip}
                                        onClick={handleFinishTaskClick} 
                                        disabled={!isCanCompleteButtonActive} 
                                        isLoading={currentRequest.request?.isLoading} />
                                    <Dialog
                                        open={IsOpenFinishJobDialog}
                                        onClose={() => {setIsOpenFinishJobDialog(false)}}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">{stringResources.finishJobButton.active.label + "?"}</DialogTitle>
                                        <DialogContent>
                                            {isLoadingTranslationProblems &&
                                                <DialogContentText id="alert-dialog-description" style={{textAlign: 'center'}}>
                                                    <CircularPreloader label='Loading additional informations' />
                                                </DialogContentText>
                                            }
                                        
                                            {!isLoadingTranslationProblems && translationProblems &&
                                                <DialogContentText id="alert-dialog-description">
                                                    <ul style={{listStyle: 'square'}}>
                                                        {translationProblems.map(tp => <li>{tp}</li>)}
                                                    </ul>
                                                </DialogContentText>
                                            }
                                        
                                        <DialogContentText id="alert-dialog-description">
                                            {stringResources.finishJobButton.active.confirmation}
                                        </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                        <Button onClick={() => {setIsOpenFinishJobDialog(false); setTranslationProblems(null);}} color="primary">
                                            No
                                        </Button>
                                        <Button onClick={handleFinishTaskConfirmationYesClick} color="primary" disabled={isLoadingTranslationProblems} autoFocus>
                                            Yes
                                        </Button>
                                        </DialogActions>
                                    </Dialog>
                                </Typography>
                            </React.Fragment>
                        }
                    </Toolbar>
                    
                </AppBar>
                <Container style={{'flex': '1 1 auto', 'overflow': 'hidden', 'padding': '0px'}} maxWidth={false}>
                    {props.children}
                </Container>
            </div>
            <ErrorModal />
            <Backdrop className={classes.backdrop} open={currentRequest.request?.isLoading}>
                <CircularPreloader label="Please wait" />
            </Backdrop>
        </DataContextProvider>
        
    )
};

export default Layout;
