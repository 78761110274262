import React, {
    Fragment,
    useState,
    useEffect,
    useReducer,
} from 'react';
import { useTrackedState, useDispatch, RequestMethod } from 'contents/cat/store/global'
import ResourceDocument from 'contents/cat/model/ResourceDocument';
import getCatOnlineService from 'services/CatOnlineService';
import useUser from './useUser'

export class ComponentResource {

    constructor(dispatch, componentState, componentIdentifier) {
        this._dispatch = dispatch
        this._state = componentState
        this._resourceDocument = componentState.resource ? new ResourceDocument(componentState.resource) : new ResourceDocument(null)
        this._componentIdentifier = componentIdentifier
        this._hasBegin = false
        this._actions = []
    }

    get isDispatchingMultipleActions() {
        return this._hasBegin
    }

    beginUpdate() {
        if (!this._hasBegin) {
            this._hasBegin = true
            this._actions = []
        }
    }

    endUpdate() {
        this._dispatchMultipleActions(this._actions)
        this._actions = []
        this._hasBegin = false
    }

    _dispatchMultipleActions(actions) {
        this._dispatch({ type: 'DISPATCH_MULTIPLE_ACTIONS', actions })
    }

    _dispatchIfNotDispatchingMultipleActions() {
        if (!this.isDispatchingMultipleActions) {
            this._dispatch(this._actions[0])
            this._actions = []
        }
    }

    setAttributeValue(resourceObjectInternalId, attributeName, attributeValue) {

        const resourceObject = this.findResourceObjectByInternalId(resourceObjectInternalId)
        const resourceObjectType = resourceObject ? resourceObject.type : null;

        this._actions.push(this._createAttributeValueChangeAction(resourceObjectInternalId, resourceObjectType, attributeName, attributeValue))

        this._dispatchIfNotDispatchingMultipleActions()
    }

    setAttributesValue(resourceObjectInternalId, attributeNameValues) {
        if (!attributeNameValues || !attributeNameValues.length) {
            return;
        }

        const resourceObject = this.findResourceObjectByInternalId(resourceObjectInternalId)
        const resourceObjectType = resourceObject ? resourceObject.type : null;

        let actions = []
        for (let i = 0; i < attributeNameValues.length; i++) {
            const attributeValueChange = attributeNameValues[i]
            const action = this._createAttributeValueChangeAction(resourceObjectInternalId, resourceObjectType, attributeValueChange.name, attributeValueChange.value)
            actions.push(action)
        }

        this._dispatchMultipleActions(actions)
    }

    _createAttributeValueChangeAction(resourceObjectInternalId, resourceObjectType, attributeName, attributeValue) {
        return {
            type: 'COMPONENT_SET_RESOURCEOBJECT_ATTRIBUTE_VALUE',
            'component': this._componentIdentifier,
            'resourceObjectInternalId': resourceObjectInternalId,
            'resourceObjectType': resourceObjectType,
            'attributeName': attributeName,
            'attributeValue': attributeValue
        }
    }

    getAttributeValue(resourceObjectInternalId, attributeName) {
        // Najpierw sprawdzamy w zmienionych wartosciach
        const resourceObjectChanges = this._state.changes[resourceObjectInternalId]
        if (resourceObjectChanges && resourceObjectChanges.hasOwnProperty(attributeName)) {
            return resourceObjectChanges[attributeName]
        }

        // Szukamy orginalnej wartosci
        const resourceObject = this.findResourceObjectByInternalId(resourceObjectInternalId)

        if (resourceObject) {
            return resourceObject.attributes[attributeName]
        }

        return undefined
    }

    getResourceObjectId(resourceObjectInternalId) {
        const resourceObject = this.findResourceObjectByInternalId(resourceObjectInternalId)
        if (resourceObject) {
            return resourceObject.id
        }

        return null
    }

    get pagesCount() {
        return this._resourceDocument.pagesCount
    }

    get currentPage() {
        return this._resourceDocument.currentPage
    }

    get resourceObjects() {
        let uniqueResourceIds = new Set()

        this._resourceDocument.data.forEach(resourceObject => {
            uniqueResourceIds.add(resourceObject.id)
        })

        Object.values(this._state.changes).forEach(resourceObject => {
            if (resourceObject.__internalId) {
                uniqueResourceIds.add(resourceObject.__internalId)
            }
        })

        let componentResourceObjects = []
        uniqueResourceIds.forEach(resourceId => {
            let componentResourceObject = new ComponentResourceObject(this, resourceId);
            componentResourceObjects.push(componentResourceObject)
        })

        return componentResourceObjects
    }

    findResourceObjectByInternalId(resourceObjectInternalId) {
        return this._resourceDocument.data.find(resourceObject => {
            return resourceObject.id == resourceObjectInternalId
        })
    }

    clearResourceObjectChanges(resourceObjectInternalId) {
        this._actions.push({
            type: 'COMPONENT_CLEAR_RESOURCEOBJECT_CHANGES',
            'component': this._componentIdentifier,
            'resourceObjectInternalId': resourceObjectInternalId
        });

        this._dispatchIfNotDispatchingMultipleActions()
    }

    applyResourceObjectChanges(resourceObjectInternalId) {
        this._actions.push({
            type: 'COMPONENT_CLEAR_RESOURCEOBJECT_CHANGES',
            'component': this._componentIdentifier,
            'resourceObjectInternalId': resourceObjectInternalId
        });

        this._dispatchIfNotDispatchingMultipleActions()
    }

    clearChanges() {
        this._actions.push({
            type: 'COMPONENT_CLEAR_CHANGES',
            'component': this._componentIdentifier
        });

        this._dispatchIfNotDispatchingMultipleActions()
    }

    clear() {
        this._actions.push({
            type: 'COMPONENT_CLEAR',
            'component': this._componentIdentifier
        });

        this._dispatchIfNotDispatchingMultipleActions()
    }

    get hasChanges() {
        return Object.values(this._state.changes).length > 0
    }

    saveChanges(storeContext) {
        this._actions.push({
            type: 'COMPONENT_SAVE_CHANGES',
            'component': this._componentIdentifier,
            'storeContext': storeContext
        });

        this._dispatchIfNotDispatchingMultipleActions()
    }

    createResourceDocumentFromChanges() {
        let resourceDocument = {
            data: []
        }

        Object.values(this._state.changes).forEach(resourceObject => {

            let changedResourceObject = {
                attributes: {}
            }

            for (const [key, value] of Object.entries(resourceObject)) {
                switch (key) {
                    case '__id':
                        changedResourceObject.id = value
                        break
                    case '__type':
                        changedResourceObject.type = value
                        break
                    case '__internalId':
                        break
                    default:
                        changedResourceObject.attributes[key] = value
                        break
                }
            }

            resourceDocument.data.push(changedResourceObject)
        })

        return resourceDocument
    }
}

class ComponentResourceObject {
    constructor(componentResource, resourceObjectInternalId) {
        this._componentResource = componentResource
        this._resourceObjectInternalId = resourceObjectInternalId
    }

    get internalId() {
        return this._resourceObjectInternalId
    }

    get id() {
        return this._componentResource.getResourceObjectId(this._resourceObjectInternalId)
    }

    setAttributeValue(attributeName, attributeValue) {
        this._componentResource.setAttributeValue(this._resourceObjectInternalId, attributeName, attributeValue)
    }

    setAttributesValue(setAttributesValue) {
        this._componentResource.setAttributesValue(this._resourceObjectInternalId, setAttributesValue)
    }

    getAttributeValue(attributeName) {
        return this._componentResource.getAttributeValue(this._resourceObjectInternalId, attributeName)
    }

    clearChanges() {
        this._componentResource.clearResourceObjectChanges(this._resourceObjectInternalId)
    }

    applyChanges() {
        this._componentResource.applyResourceObjectChanges(this._resourceObjectInternalId)
    }
}

const defaultFetchDataOptions = {
    setup: (serviceMethodName, serviceMethodParams) => {

    },
    serviceMethodName: null,
    serviceMethodParams: null
}

const performServiceRequest = async (globalDispatch, user, componentOptions, requestOptions) => {
    globalDispatch({ type: 'REQUEST_STARTED', component: componentOptions.componentIdentifier });

    try {
        let catOnlineServiceConfig = {};
        if (user.isLoggedIn) {
            catOnlineServiceConfig.authToken = user.accessToken;
        }
        const catOnlineService = getCatOnlineService(catOnlineServiceConfig);
        const result = await catOnlineService[requestOptions.serviceMethodName](...requestOptions.serviceMethodParams);

        if (!componentOptions.didCancel) {
            if (result.ok) {
                globalDispatch({
                    type: 'REQUEST_SUCCESS',
                    component: componentOptions.componentIdentifier,
                    status: result.status,
                    resource: result.payload,
                    requestMethod: requestOptions.serviceMethodRequestType
                });
            }
            else {
                globalDispatch({ type: 'REQUEST_FAILURE', component: componentOptions.componentIdentifier, status: result.status });
            }

        }
    } catch (error) {
        if (!componentOptions.didCancel) {
            globalDispatch({ type: 'REQUEST_FAILURE', component: componentOptions.componentIdentifier, status: 0 });
        }
    }
};

const useComponent = (componentIdentifier, fetchDataOptions) => {
    const globalState = useTrackedState();
    const globalDispatch = useDispatch();

    if (!fetchDataOptions) {
        fetchDataOptions = defaultFetchDataOptions
    }

    let defaultComponentState = {
        request: {
            isLoading: false,
            isError: false,
            status: 0
        },
        resource: null,
        changes: {
            /* '__internalId': {__internalId: 'id', __id: 'id', __type: 'type', attributeName: 'attributeValue'} */
        },
        saveChangesToStore: 1,
        pagination: { rowsPerPage: 20, page: 1 }
    }

    let componentState = globalState.components[componentIdentifier] || defaultComponentState;
    const user = useUser();

    let result = {
        resource: new ComponentResource(globalDispatch, componentState, componentIdentifier),
        isLoading: false,
        saveChanges: (requestOptions) => {
            result.resource.saveChanges({ requestOptions })
        },
        pagination: {
            ...componentState.pagination,
            setPage: (page) => {
                globalDispatch({
                    type: 'COMPONENT_SET_PAGE',
                    component: componentIdentifier,
                    page
                })
            },
            setRowsPerPage: (rowsPerPage) => {
                globalDispatch({
                    type: 'COMPONENT_SET_ROWS_PER_PAGE',
                    component: componentIdentifier,
                    rowsPerPage
                })
            }
        }
    };

    if (componentState) {
        if (componentState.request) {
            result.isLoading = componentState.request.isLoading
        }
    }

    // onLoad
    useEffect(() => {
        let didCancel = false;

        let serviceMethodParams = [ ...fetchDataOptions.serviceMethodParams ]
        serviceMethodParams.push({ limit: result.pagination.rowsPerPage, page: result.pagination.page })

        performServiceRequest(
            globalDispatch,
            user,
            { didCancel, componentIdentifier },
            {
                serviceMethodName: fetchDataOptions.serviceMethodName,
                serviceMethodParams: serviceMethodParams,
                serviceMethodRequestType: RequestMethod.GET
            })

        return () => {
            didCancel = true;
            result.resource.clear()
        };
    }, [result.pagination.rowsPerPage, result.pagination.page, fetchDataOptions.serviceMethodParams]);

    useEffect(() => {
        let didCancel = false;

        if (componentState.saveChangesToStore == 1 || componentState.saveChangesToStore === undefined) {
            return;
        }
        console.log("SAVE CHANGES TO STORE: " + componentState.saveChangesToStore)

        globalDispatch({
            type: 'COMPONENT_SAVE_CHANGES_TO_STORE',
            component: componentIdentifier,
            saveRoutine: async (dispatch, requestOptions) => {
                await performServiceRequest(dispatch, user, { didCancel, componentIdentifier }, {...requestOptions, serviceMethodRequestType: RequestMethod.UPDATE} )
            }
        })

        return () => { 
            didCancel = true;
        };
    }, [componentState.saveChangesToStore])

    return result;
}

export default useComponent;