export function FunctionTitle(title, name) {
	return (form) => {
		return title + form.getValueInput(name)
	}
}


export function FunctionParentTitle(title, name) {
	return (form) => {
		return title + ': ' + form.parentForm.getValueInput(name)
	}
}

