import React from 'react'
import { useForm } from 'components/form/Form'
import { ListRaw } from './ListRaw'
import { RectsGantt } from './rectsGantt'

export function ListModeView({ part, dataTypeAdd, isGantt, inlineEditionOnly, onClickRow, onDoubleClickRow }) {
	const form = useForm('data')

	if (form.ganttManager.isReactangleView)
		return <RectsGantt part={part} />

	return <ListRaw part={part} dataTypeAdd={dataTypeAdd} isGantt={isGantt} inlineEditionOnly={inlineEditionOnly} onClickRow={onClickRow} onDoubleClickRow={onDoubleClickRow} />
}