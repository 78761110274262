import React from 'react'
import { useForm} from 'components/form/Form'

/**
 * Wybieranie odpowiedniej procedury rysowaniu wierszy
 * Dzieci w children powinne miec type=== 'normal'|'group'|'tree'
 */
export function RowsSwitch({ children, ...other }) {
	const form = useForm('data')
	let type = 'normal'

	if (form.groupManager.isGroup)
		type = 'group'
	if (form.treeManager.isTree)
		type = 'tree'

	const childrenArray = React.Children.toArray(children)
	const child = childrenArray.find(c => c.props.type === type)

	if (!child)
		return null

	return React.cloneElement(child, other)
}