import { withStyles, CardMedia, CardActions } from '@material-ui/core'
import logo from 'icons/logo/logotyp.svg'
import { customElements } from 'cssStatic'

///TODO style
export const StyledCardMedia = withStyles(({ palette }) => ({
	root: {
		backgroundColor: palette.primary.mainDarken800,
		backgroundImage: `url(${logo})`,
		backgroundSize: '30%',
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: '5%',
		minHeight: 80,
	}
}))(CardMedia)

///TODO style
export const StyledCardActions = withStyles(({ palette }) => ({
	root: {
		justifyContent: 'flex-end'
	}
}))(CardActions)

export const CardWrapper = customElements('o-card-wrapper', {
	height: '100vh',
	display: 'flex',
	'justify-content': 'center',
	'align-items': 'center',
	'&>div': {
		'width': '700px'
	}
})