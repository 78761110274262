import { DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

export const StyledDialogTitle = withStyles(({ palette }) => ({
   root: {
      fontSize: 30
   },
}))(DialogTitle)

export const StyledDialogActions = withStyles(({ palette }) => ({
   root: {
      margin: '15px 0',
      padding: '10px 24px 10px 0'
   },
}))(DialogActions)

export const StyledDialogContent = withStyles(({ palette }) => ({
   root: {
   },
}))(DialogContent)