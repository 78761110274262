import React from 'react'
import { FormMetaRoot, FormConsumer } from 'components/form/Form'
import { useAppCtrl } from 'AppCtrl'

export default function HomePartOuter({ children, part }) {
	const appCtrl = useAppCtrl()

	return (
		<FormMetaRoot links={appCtrl.session.links}>
			<FormConsumer>
				{form => {
					part.parts.forEach(p => {
						const partName = p.props.useLinkName ? p.props.useLinkName : p.props.dataType
						p.reachable = !!form.getServiceRelativeRead(partName)
					})
					return null
				}}
			</FormConsumer>
			{children}
		</FormMetaRoot>
	)
}
