import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'components/form/Form'
import { useTranslate } from 'locale/Locale'
import Button from './Button'
import { useHistory } from 'react-router-dom'
import { linkManager } from 'components/LinkManager'

/**
 * Przycisk Add pozwalający na otworzenie dodawania nowego rekordu
 * @param {Node} content -  Zawartość przycisku, może nim być tekst lub ikona, jeśli go nie przekażemy, zostanie wyświetlony domyślny tekst dla tego przycisku 
 * @returns Przycisk Add
 */

export function ButtonAdd({ content, isList, part }) {
	const form = useForm('data')
	const translate = useTranslate('WebSpa/Buttons')
	const history = useHistory()

	if (!form.serviceCreate || !isList || !part || form.rowManager.inlineEdition)
		return null

	return (
		<Button
			type='submit'
			id={`${form._dataType ?? ''}-ButtonAdd`}
			onClick={evt => {
				form.clearChanges()
				linkManager.handleListId(history, part)
				evt.stopPropagation()
			}}
			content={content || translate('addButton')}
		/>
	)
}

ButtonAdd.propTypes = {
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.elementType
	]),
}
