import Session from './Session'
import SessionCat from './SessionCat'
import React from 'react'
import RefreshableContext from 'components/refreshableContext/RefreshableContext'
import { LocaleClass } from 'locale/LocaleClass'
import defaultLocalizedStrings from 'locale/DefaultLocalizedStrings.json'
import { ConfigurationClass } from 'configuration/ConfigurationClass'
import { isEmpty } from 'lodash'
import { GlobalStrings } from 'GlobalStrings';

const appContext = RefreshableContext.create()

function useAppCtrl() {
	return appContext.useContext()
}

/**
 * Główny kontroler aplikacyjny
 */
class AppCtrl {
	/**
	 * Tworzy AppCtrl
	 */
	constructor() {
		this.session = new Session(this)
		this.session.restore()
		this.sessionCat = new SessionCat(this)
		this.sessionCat.restore()
		this._messages = []
		this._userInfoMessages = []
		this.JSONresponse = ''
		this.configuration = new ConfigurationClass()
		this.configuration.fromLocalStorage()

		if (!this.session.value) {
			this.initLocale({})
		} else {
			this.initLocale({
				CulturePreferences: this.session.value.CulturePreferences,
				Languages: this.session.value.Languages
			})
		}
	}

	initLocale(v) {
		this.locale = new LocaleClass({ ...v, defaultLocalizedStrings })
	}

	logout() {
		this.session.value = undefined
		this._messages = []
		this._userInfoMessages = []
	}

	get userInfoMessages() {
		this._userInfoMessages = this._messages.filter(m => m.type === 'UserInfoMessage').reverse()
		return this._userInfoMessages
	}

	cleanJSONResponse() {
		this.JSONresponse = undefined
	}

	addMessages(messages, refresh) {
		if (isEmpty(messages))
			return

		const JSONresponse = messages.filter(m => {
			return m.type === 'InternalMessage' && m.value
		})

		if (JSONresponse.length)
			this.JSONresponse = JSONresponse[0].value

		else
			this.JSONresponse = undefined

		messages = messages.filter(m => {

			/// Na ten moment powiadomienia typu: NotifySourceMessageDataType (karteczkowe lub iconowe) trzymamy w AppMessages
			if (m.type === GlobalStrings.NotifySourceMessageDataType)
				return false;

			if (m.type !== 'UserInfoMessage')
				return true
			return this._messages.findIndex(_m => _m.infoMessage === m.infoMessage) === -1
		})

		this._messages.push(...messages)

		if (refresh && this.refresh)
			this.refresh()
	}

	clearMessages(refresh) {
		this._messages = []
		if (refresh && this.refresh)
			this.refresh()
	}

	canMessageAppear(section, message){
		if(message.scope && section){
			if(message.scope === 'ListSection' && section === 'list')
				return true
			
			if (message.scope === 'UserSection' && section === 'user')
				return true	

			else if (message.scope === 'ListUserSection' && (section === 'user' || section === 'list'))
				return true
			else
				return false		
		}

		return true //Zmienić na FALSE
	}

	_removeMessage(index) {
		this._messages.splice(index, 1)
	}

	removeUserInfoMessage(message) {
		this._messages = this._messages.filter(element => element.infoMessage !== message)
		this.refresh()
	}

	checkNeedToReload(form) {
		let messageIndex = this._messages.findIndex(m => {
			return m.type === 'ReloadResourceMessage' &&
				form.dataType === m.resourceDataTypeName &&
				// eslint-disable-next-line
				form.id == m.resourceId
		})

		if (messageIndex >= 0) {
			this._removeMessage(messageIndex)
			form.asyncLoad('reload')
			return
		}

		messageIndex = this._messages.findIndex(m => {
			return m.type === 'ReloadSubResourceMessage' &&
				form.parentForm &&
				form.dataType === m.subResourceDataTypeName &&
				// eslint-disable-next-line
				form.parentForm.id == m.resourceId &&
				form.parentForm.dataType === m.resourceDataTypeName
		})

		if (messageIndex >= 0) {
			this._removeMessage(messageIndex)
			form.asyncLoad('reload')
			return
		}

		messageIndex = this._messages.findIndex(m => {
			return m.type === 'ReloadResourceListMessage' &&
				form.parentForm &&
				form.parentForm.dataType === m.resourceDataTypeName
		})

		if (messageIndex >= 0) {
			this._removeMessage(messageIndex)
			form.asyncLoad('reload')
			return
		}
	}

	checkNeedToRedirect() {
		console.log(this._messages);
		let messageIndex = this._messages.findIndex(m => {
			return m.type === 'RedirectMessage' && m.address;
		});

		if (messageIndex >= 0) {
			window.open(this._messages.at(messageIndex).address, "_self");
			return;
		}
	}

	refreshAppState(){
		this.refresh()
	}
}

const appCtrl = new AppCtrl()
const createDataFactory = (props) => {
	return appCtrl
}

function AppCtrlProvider({ children }) {
	return (
		<appContext.Provider
			fixedData
			createData={createDataFactory}>
			{children}
		</appContext.Provider>
	)
}

export {
	AppCtrlProvider,
	useAppCtrl,
	appCtrl
}