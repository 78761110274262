import React from 'react'
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthenticateTaskAccessSwr } from './hooks/useApi';
import useUser from 'contents/cat/hooks/useUser';

import CircularPreloader from 'components/preloader/CircularPreloader';
import SingleBoxContainer from 'contents/cat/SingleBoxContainer'
import SimpleErrorView from './SimpleErrorView';

const customStatusErrorMessages = {
	"404": "Project does not exists at given URL."
};

export default function AuthenticateTaskAccess({ match, history }) {
	const taskToken = match.params.token

	// TODO: Lepiej bezpośrednio skonsumować serwis bez pośrednictwa SWR
	const { data, error } = useAuthenticateTaskAccessSwr(taskToken)
	const currentUser = useUser();

	const hasAuthenticatedAgainstTaskToken = currentUser.isLoggedIn && currentUser.taskToken === taskToken

	useEffect(() => {
		if (data?.payload && !hasAuthenticatedAgainstTaskToken) {
			currentUser.authSuccess(
				data.payload.accessToken,
				data.payload.accessTokenExpiration,
				data.payload.refreshToken,
				data.payload.refresTokenExpiration,
				taskToken);
		}
	}, [data?.payload]);

	if(hasAuthenticatedAgainstTaskToken)
	{
		return (
			<Redirect to='/cat/auth/task' />
		)
	}

 	if(error)
	{
		return <SimpleErrorView errorObject={error} responseData={data} customStatusErrorMessages />
	}

	if(!data) 
	{
		return (
			<SingleBoxContainer>
				<CircularPreloader label="Please wait, authenticating.." />
			</SingleBoxContainer>
		);
	}

	return (<SingleBoxContainer></SingleBoxContainer>);
}