import React from "react"
import PropTypes from "prop-types"
import useField from '../../hooks/useField'
import Icon from 'components/icon/Icon'

import InputNumber from "../input/InputNumber"
import Checkbox from "../checkbox/Checkbox"
import InputText from "../input/InputText"
import InputDateTime from "../input/InputDateTime/InputDateTime"
import InputInt from "../input/InputInt"
import Textarea from '../textarea/Textarea'
import InputPassword from '../input/InputPassword'
import File from '../file/File'
import SegmentGroup from '../segmentGroup/SegmentGroup'
import LabelField from '../labelField/LabelField'
import EnumList from '../enumList/EnumList'
import InputNIP from '../input/InputNIP/InputNIP'
import ComboboxRef from '../combobox/ComboboxRef'
import ComboboxMultipleChoice from '../combobox/ComboboxMultipleChoice'
import ComboboxEnumList from '../combobox/ComboboxEnumList'
import ComboboxEnum from '../combobox/ComboboxEnum'
import { ReadOnly, EmptyPlaceholderField} from './displayTypes'
import HTMLEditor from '../HTMLEditor/HTMLEditor'
import InputTags from "../input/InputTags"
import ComboboxRefList from "../combobox/ComboboxRefList"
import { ComboboxRefListProvider } from "../combobox/ComboboxRefListProvider"
import { checkboxOptionsNonindeterminate, checkboxOptionsindeterminate } from "../checkbox/Checkbox";
import ButtonLink from "../ButtonLink/ButtonLink";
import { GlobalStrings } from "GlobalStrings"

const fielFactory = {
	
	Empty: function(props, meta){
		return <EmptyPlaceholderField />
	},

	Bool: function (props, meta) {
		const options = {
			indeterminate: false
		}

		switch (meta.uiInputType) {
			case 'Default':
				return <Checkbox {...props} options={checkboxOptionsNonindeterminate} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			case 'Indeterminate':
				return <Checkbox {...props} options={checkboxOptionsindeterminate} />
			default:
				return <Checkbox {...props} options={checkboxOptionsNonindeterminate} />
		}
	},
	String: function (props, meta) {
		// Url - adres url
		// Tel - numer telefonu		
		// Email
		// Text - jednoliniowe pole tekstowe
		// TextArea - wieloliniowe pole tekstowe
		// Kontrolka do obsługi i walidacji danych z GUSu
		// HTMLEditor - Edytor szablonów HTML 
		switch (meta.uiInputType) {
			case 'ReadOnly':
				return <ReadOnly {...props} />
			case 'TextArea':
				return <Textarea {...props} />
			case 'NIP':
				return <InputNIP {...props} />
			case 'Label':
				return <LabelField {...props} />
			case 'HtmlEditor':
				return <HTMLEditor {...props}/>
			case 'InputTags':
				return <InputTags {...props}/>
			case GlobalStrings.FieldUiInputTypeButtonLink:
			case GlobalStrings.FieldUiInputTypeButtonVideoLink:
				return <ButtonLink {...props} />
			case GlobalStrings.FieldUiInputTypeButtonPassword:
				return <InputPassword {...props} />
			default:
				return <InputText {...props} />
		}
	},
	Enum: function (props, meta) {
		// Radio
		//Brak combo
		switch (meta.uiInputType) {
			case 'Default':
				return <ComboboxEnum {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default:
				return <ComboboxEnum {...props} />
		}
	},
	Number: function (props, meta) {
		// Number ? - pole do wyboru liczby całkowitej
		switch (meta.uiInputType) {
			case 'Default':
				return <InputNumber {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default:
				return <InputNumber {...props} />
		}
	},
	Int: function (props, meta) {
		// Number ? - pole do wyboru liczby całkowitej
		switch (meta.uiInputType) {
			case 'Default':
				return <InputInt {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default:
				return <InputInt {...props} />
		}
	},

	Ref: function (props, meta) {
		switch (meta.uiInputType) {
			case 'Default':
				return <ComboboxRef {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			case 'MultipleChoiceCombo':
				return	<ComboboxRefListProvider>
					<ComboboxRefList {...props} />
				</ComboboxRefListProvider>	 
			default:
				return <ComboboxRef {...props} />
		}
	},

	GuidList: function (props, meta) {
		switch (meta.uiInputType) {
			case 'Default':
				return <ComboboxMultipleChoice {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			case 'ComboboxMultipleChoice':
				return <EnumList useRefValuesArray {...props} />
			default:
				return <ComboboxMultipleChoice {...props} />
		}
	},

	FileList: function (props, meta) {
		switch (meta.uiInputType) {
			case 'Default':
				return <EnumList useRefValuesArray {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			case 'MultipleChoiceList':
				return <EnumList useRefValuesArray {...props} />
			case 'MultipleChoiceCombo':
				return <ComboboxEnumList useRefValuesArray {...props} />
			default:
				return <EnumList useRefValuesArray {...props} />
		}
	},
	
	Timestamp: function (props, meta) {
		// Time - wybór godziny i minuty
		// Month ?- wybór miesiąca i roku
		// Date ?
		switch (meta.uiInputType) {
			case 'Default':
				return <InputDateTime {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default:
				return <InputDateTime {...props} />
		}
	},

	Date: function (props, meta) {
		switch (meta.uiInputType) {
			case 'Default':
				return <InputDateTime onlyDate {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default:
				return <InputDateTime onlyDate {...props} />
		}
	},
	File: function (props, meta) {
		switch (meta.uiInputType) {
			case 'Default':
				return <File {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default:
				return <File {...props} />
		}
	},
	SegmentGroup: function (props, meta) {
		switch (meta.uiInputType) {
			case 'Default':
				return <SegmentGroup {...props} />
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default:
				return <SegmentGroup {...props} />
		}
	},
	EnumList: function (props, meta) {
		switch (meta.uiInputType) {
			case 'Default': 
				return props?.hasLabel === false ? <ComboboxEnumList {...props} /> : <EnumList {...props} />
			case 'ComboboxMultipleChoice': 
				return <ComboboxEnumList {...props} /> 
			case 'ReadOnly':
				return <ReadOnly {...props} />
			default: 
				return props?.hasLabel === false ? <ComboboxEnumList {...props} /> : <EnumList {...props} />
		}
	},
	MoveRow: function (props) {
		const field = useField(props.name)

		return (
			<div>
				<Icon name="north" isInList color={'primary'}
					onClick={ev => {
						field.form.parentForm.handleMoveRow(field.form.id, -1)
						ev.stopPropagation()
					}}
				/>
				<Icon name="south" isInList color={'primary'}
					onClick={ev => {
						field.form.parentForm.handleMoveRow(field.form.id, 1)
						ev.stopPropagation()
					}}
				/>
			</div>
		)
	}
}

/**
 * Komponent pojedyńczego pola generowanego na podstawie metadanych
 *
 * @param {string} name Nazwa pola
 * @returns {Node}
 */
export default function Field(props) {
	const field = useField(props.name)
	const meta = field.meta

	if (!(meta?.type in fielFactory))
		return null

	return fielFactory[meta.type](props, meta)
}

Field.propTypes = {
	name: PropTypes.string.isRequired
}
