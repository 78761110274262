import React from 'react'
import { StyledTooltip } from './Tooltip.styled'
import trim from 'lodash/trim'

/**
 * Komponent Tooltipa używany w aplikacji 
 * @param {String} title - Tekst do wyświetlenia w Tooltipie
 * @param {String} children - Komponent opleciony Tooltipem
 * @returns {Node} - Komponent wyświetlający Tooltip
 */
export const Tooltip = ({ title, children, ...other }) => { //TODO podmienić wszystkie Tooltipy używane w aplikacji na ten komponent
	if (!trim(title) || typeof title !== 'string')
		return children

	return (
		<StyledTooltip {...other} title={title} >
			{children}
		</StyledTooltip>
	)
}
