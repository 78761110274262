import EnumValue from "../../model/EnumValue"

export enum DocumentMessageSeverity {
    Warning = 200,
    Error = 300
};

type DocumentMessage = {
    message: string,
    origin: EnumValue,
    severity: EnumValue
};

export default DocumentMessage;