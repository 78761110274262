
/**
 * Klasa sterująca
 *  @param form klasa przyjmuje obiekt form który modyfikuje i z którego wyciąga potrzebne informacje
 *  @function handleKeyPress - funkcja wywoływana po naciśnięciu klawisza Enter w polu inputa. 
 * Jako parametr przyjmuje obiekt event, czyta z niego odpowiedni klawisz po czym w zależności 
 * od wartości zmienia wartość currentPage w formie
 *  @function handleSubtract - wywoływana po naciśnieciu przycisku wstecz, odejmuje jedną stronę
 *  @function handleAdd -  wywoływana po naciśnieciu przycisku dalej, dodaje jedną stronę
 *  @function handleChange - obsługuje wpisywanie wartości do inputa, jako parametr przyjmuje nową wartość
 * @function value - getter dla właściwości this._value
 * 
 */
export default class Data {

	onRenderProvider({ form }) {
		this.pages = form.pages
		this.form = form
		this._value = this.form.currentPage
		this.rowsOnOnePage = this.form.rows ? form.rows.length : null
		this._rowValue = this.pages === 1 ? this.rowsOnOnePage : this.form.limit
		// Jeśli strona jest jedna to do rowValue przypisujemy ilość rekordów na tej stronie, 
		// jeśli stron jest więcej, przypisujemy domyślną wartość z BE limit
	}

	get value() {
		return this._value
	}

	get rowValue() {
		return this._rowValue
	}

	handleKeyPress(e) {
		const v = e.target.value

		if (e.key === "Enter") {
			if (v <= 1)
				this.form.currentPage = 1 //W przypadku gdy wprowadzona wartosć jest ujemna - przekierowanie na pierwszą stronę
			else
				if (v >= this.pages)
					this.form.currentPage = this.pages //W przypadku gdy użytkownik wpisze za dużą liczę, zostanie przekierowany do ostatniej strony
				else
					this.form.currentPage = e.target.value

			this._value = this.form.currentPage
			this.form.asyncLoad()
			return true
		}
	}

	/**
	 * 
	 * @param {Bool} first - parametr ustawiany na true przy przycisku paginatora pozwalającym na przejście do pierwszej strony
	 */
	handleSubtract(first) {
		if (this.form.currentPage === 1)
			return

		if (first)
			this.form.currentPage = 1
		else
			this.form.currentPage--

		this._value = this.form.currentPage
		this.refresh()
		this.form.asyncLoad()
		return true
	}
	/**
		* 
		* @param {Bool} last - parametr ustawiany na true przy przycisku paginatora pozwalającym na przejście do ostatniej strony
		*/
	handleAdd(last) {
		if (this.form.currentPage >= this.form.pages)
			return

		if (last)
			this.form.currentPage = this.pages
		else
			this.form.currentPage++

		this._value = this.form.currentPage
		this.refresh()
		this.form.asyncLoad()
		return true
	}

	handleChange(value) {
		this._value = value
		this.refresh()
	}
	/**___________________Metody dla RowsPerPage_____________
	/**
	 * 
	 * @param {Number} value - działa jak funkcja handlaChange tylko dla komponentu RowsPerPage 
	 */

	handleRowChange(value) {
		this._rowValue = value
		this.refresh()
	}

	/**
	 * @param {Object} e - działa jak funkcja handlaChange tylko dla komponentu RowsPerPage 
	 */

	handleRowsKeyPress(e) {
		const v = e.target.value

		if (e.key !== "Enter")
			return

		if (v < 1)
			this.form.limit = 15
		else
			this.form.limit = e.target.value

		this._RowValue = this.form.limit
		this.form.currentPage = 1
		this.form.asyncLoad()
		return true
	}
}