import React from 'react'
import { useForm } from 'components/form/Form'
import { FormMetaObject } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import { GanttObservedArrow } from './GanttObservedArrow'

export function GanttObserved({ row }) {
	const form = useForm('data')
	const id2 = row.id
	const ganttPrev = 'DependOnItemsListId'
	let value = row.attributes[ganttPrev]

	if (!Array.isArray(value))
		return null

	return (
		<FormMetaObject
			dataType={form._dataType}
			data={form.rowManager.getDataFromRow(row, form._data.ui)}
			onSubmit={async (form, submitType) => {
				if (submitType === 'ganttDeleteLink') {
					await getFormRestService(form.serviceUpdateHRef)
						.patch(form, '')
					form.parentForm.trigger('data')
				}
			}}
		>
			{value.map(v => <GanttObservedArrow
				id1={v.id}
				id2={id2}
			/>)}
		</FormMetaObject>
	)
}