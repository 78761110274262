import React from 'react';
import { Provider } from 'contents/cat/store/global';

const CatGlobalStorageProvider = (props) => {
    return (
        <Provider>
            {props.children}
        </Provider>
    )
};

export default CatGlobalStorageProvider;