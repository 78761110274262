import React from 'react'
import { customElements } from 'cssStatic'
import { rowGap, colGap } from '../fields/getFieldDimentions'


export const StyledSectionContentWrapper = customElements('o-section-content-wrapper', {
	'display': 'grid',
   'row-gap': `${rowGap}px`,
   'column-gap': `${colGap}px`,
})

/**
 * Wrapper otaczający całą zawartość sekcji (pola oraz podsekcje) 
 * Wykorzystywany w celu poprawnego ułożenia pól oraz sekcji
 * @param {Node} children - Dzieci które wrapper ma otoczyć
 * @returns 
 */
export const SectionContentWrapper = ({ children }) =>  (
  <StyledSectionContentWrapper>
    {children}
  </StyledSectionContentWrapper>
)
