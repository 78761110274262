import styled from 'styled-components'
import { lighten, MixinColor } from 'components/topology/Color'

/**
 * Komponent stylizujacy ikonę
 * @param {Bool} normal - zmienie kolor ikony na domyślny szary - #555
 * @param {Number} md - odpowiedzialny za rozmiar ikonki, domyślnie - 24px
 * @param {Bool} dark - odpowiedzialny za kolor ikonki
 * @param {Bool} light - odpowiedzialny za kolor ikonki
 * @param {Number} rotate - odpowiedzialny za rotację ikony
 * @param {Bool} error - Specjalne style dla inputa z errorem
 * @param {Bool} disabled - Specjalne style dla inputa nieaktywnego
 * @param {Bool} warning - Specjalne style dla ikony w komponencie Tooltip.js
 * @param {Bool} list - Specjalne style dla ikony w komponencie CellSwitch.js
 * @param {Bool} dropdown - Specjalne style dla ikony w komponencie Search.js
 * @param {Bool} calendar - Specjalne style dla ikony w komponencie InputDateTime.js
 */

///TODO style
export const StlyedIcon = styled.i`
	font-size: ${({ md }) => md && `${md}px`};
	cursor: pointer;
	color: ${({ theme, color }) => color && theme.colors[color]};

${({ theme, normal }) => normal && `color: ${theme.colors.grey700};`};

${({ dark }) =>
		dark &&
		`color: rgba(0, 0, 0, 0.54);`}/* Rules for using icons as black on a light background. */

${({ light }) =>
		light &&
		`color: rgba(255, 255, 255, 1);`}/* Rules for using icons as white on a dark background. */

${({ rotate }) => rotate && `transform: rotate(${rotate}deg);`}

${props =>
		props.warning &&
		`
	font-size: 20px;
	cursor: pointer;
	margin-left: 100px;	
	`} /*Ikonka używana przy tooltipach w komponencie Tooltip.js */


	${props =>
		props.isInList &&
		`
		font-weight: bold;
		margin-left: 10%;	
	`} /*Ikonka używana zaznaczania wartości Bool w listach. Używana w komponencie CellSwitch */

	
${({ dropdown }) =>
		dropdown &&
		`
	height: 16px;
	`} /*Ikonka używana w wyszukiwarce do stylizowania ikon dropdown. Używana w komponencie Search.js */

${props => props.combobox && `
		color: rgba(0,0,0, 0.6);
		${props.disabled && `color: rgba(0,0,0, 0.3) !important`};
		${props.error && !props.disabled && MixinColor('error')};
`}

${props =>
		props.input &&
		`
		transition: 0.2s;
		cursor: pointer;
		color:inherit;
		${props.disabled && `color: rgba(0,0,0, 0.3) !important`};
		${props.error && !props.disabled && MixinColor('error')};
		
	`} /*Ikonka używana w inpucie kalendarza. Używana w komponencie InputdateTime.js */

	${({ error }) => error && MixinColor('error')}

	${({ disabled }) => disabled && `color: #747d8c; cursor: no-drop`}
`
