import React from 'react'
import { useAppCtrl } from 'AppCtrl'
import getFormRestService from 'services/getFormRestService'

export function ConfigurationSaver({ delay, url }) {
	const appCtrl = useAppCtrl()

	React.useEffect(() => {
		let timer

		timer = setInterval(async () => {
			const configuration = appCtrl.configuration

			if (!configuration || !configuration.hasChanges)
				return

			configuration.changesToConfiguration()
			await getFormRestService(url)
				.patchConfiguration(configuration.toKeyValues, appCtrl.session.identityId)
			configuration.toLocalStorage()
		}, delay)
		return () => {
			clearTimeout(timer)
		}
	}, [])

	return null
}