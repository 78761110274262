// TODO: Przeniesienie zarządzania wszystkimi messagami (nie tylko karteczkowymi (ikonowymi)) w oparciu o stan react

import { useReducer } from "react";
import { createContainer } from "react-tracked";

export const MESSAGE_ACTIONS = {
    APPEND_MESSAGE: 'append',
    REMOVE_MESSAGE: 'remove'
};

export function messageReducer(messages, action) {
    switch (action.type) {
        case MESSAGE_ACTIONS.APPEND_MESSAGE:
            {
                const newMessages = action.payload.messages;
                const updatedDataType = action.payload.updatedDataType;
                const messagesOwnersId = action.payload.messagesOwnersId;

                if (!messagesOwnersId) {
                    /// przypadek kiedy odświeżono całą listę - usuwamy wszystkie poprzednie wiadomości dla danego typu danych
                    messages = messages.filter(m => m.resourceDataTypeName !== updatedDataType);
                } else {
                    // obsługa pozostałych sytuacji: 
                    // - odświeżenie pojedynczego obiektu, 
                    // - aktualizacja pojedynczego obiektu
                    // - aktualizacja obiektu lub wielu z poziomu listy

                    // Zostawiamy wiadomości z obiektów na których nie pracujemy, pozostałe wiadomości muszą być dostarczone przez BE
                    messages = messages.filter(m => !messagesOwnersId.some(mo => mo === m.resourceId));
                }

                /// Dodajemy nowe wiadomości
                if (newMessages) {
                    messages = [...messages, ...newMessages];
                }

                return messages;
            }
        /// Usuwamy wszystkie wiadomości z podanym id - może istnieć wiele wiadomości z tym samym id
        /// dla różnych typów danych np. wiadomości w rodzicu z dziecka
        case MESSAGE_ACTIONS.REMOVE_MESSAGE:
            return messages?.filter(m => m.correlationId !== action.payload.id);
    }
}

/**
 * Sprawdzamy czy 2 wiadomości 'karteczkowe' są takie same:
 * Wiadomości są takie same jeśli: 
 * - Mają tego samego właściciela - resourceId
 * - Właściciel ma taki sam typ danych - resourceDataTypeName
 * - Mają taki sam typ operacji - operationType
 * @returns 
 */
const areIconMessagesEqual = (message1, message2) => {
    return message1.resourceDataTypeName === message2.resourceDataTypeName &&
        message1.resourceId === message2.resourceId &&
        message1.operationType === message2.operationType;
};

/**
 * 
 * @returns 
 */
const useMessageReducer = () => useReducer(messageReducer, []);

export const {
    Provider: IconMessageProvider,
    useTrackedState: useIconMessageState,
    useUpdate: useIconMessageDispatch,
} = createContainer(useMessageReducer);

