import React from 'react'
import PropTypes from 'prop-types'
import { Card, makeStyles, Typography } from '@material-ui/core'
import { styleToColor } from 'Tools/styleToColor'
import './Sections.scss'
import { SectionContentWrapper } from './SectionContentWrapper'

//#region  Style
const useLabelSectionStyles = makeStyles(({ palette }) => ({
	typographyRoot: {
		color: props => styleToColor(props.style, palette),
		background: 'linear-gradient(180deg, transparent 40%, rgba(255,255,255,1) 35%)',
		position: 'absolute',
		top: 0,
		left: 12,
		transform: 'translateY(-50%)',
		padding: '0 5px',
		fontSize: '18px',
		fontWeight: 500,	
		whiteSpace: 'nowrap',
	},
	cardRoot: {
		border: '2px solid',
		borderColor: props => styleToColor(props.style, palette),
		padding: '10px 10px 0 10px',
		boxShadow: 'none',
		position: 'relative',
		zIndex: 1,
		overflow: 'visible',
	},
	fieldsWrapper: {
		overflowX: 'hidden',
		padding: '15px 5px 25px 5px',
	}
}))
//#endregion

/**
 * Komponent wyświetlający sekcję Label z obramówką
 * 
 * @param {Object} section Obiekt sekcji nadrzędnej - rodzic grupujący inne sekcje z tabami
 * @returns {Node}
 */
export function SectionLabel({ section, children }) {
	const { cardRoot, typographyRoot, fieldsWrapper } = useLabelSectionStyles({ style: section.style })

	return (
		<Card className={cardRoot}>
			<Typography className={typographyRoot} variant="h4" >{section.label}</Typography>
			<div className={fieldsWrapper} >
				<SectionContentWrapper>
					{children}
				</SectionContentWrapper>
			</div>
		</Card>
	)
}

//#region PropTypes & DefaultProps
SectionLabel.propTypes = {
	section: PropTypes.object.isRequired,
	children: PropTypes.node
}
//#endregion