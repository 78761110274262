import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/icon/Icon'
import { Vertical } from 'components/topology/Topology'
import { AccordionContent, AccordionHead } from './Accordion.styled'
import StyledDropdownIcon from 'components/topology/StyledDropdownIcon'


function Cards({ children, isOpen }) {

	return (
		<AccordionContent pose={isOpen ? 'open' : 'closed'} isOpen={isOpen}>
			{children}
		</AccordionContent >
	)
}

function CardWrapper({ children }) {
	return (
		<Vertical >
			{children}
		</Vertical>
	)
}

/**
 * Komponent umożliwiający rozsuwanie/zsuwanie zawartości
 *
 * @method Accordion
 * @param {boolean} isOpen Czy jest domyślnie rozsunięty
 * @param {string} title Tytuł w nagłówku akordeonu
 * @param {Node} children	Zawartość
 * @returns {Node}
 */
export default function Accordion(props) {
	const [isOpen, setIsOpen] = useState(props.isOpen ? props.isOpen : false)

	return (
		<React.Fragment>
			<CardWrapper>
				{/* <ScrollToBottom> */}
				<AccordionHead
					onClick={e => setIsOpen(!isOpen)}
				>
					<div> {props.title} </div>

					<StyledDropdownIcon
						pose={isOpen ? 'less' : 'more'}
					>
						<Icon name='expand_more' />
					</StyledDropdownIcon>
				</AccordionHead>
				{/* </ScrollToBottom> */}
				<Cards isOpen={isOpen}> {props.children} </Cards>
			</CardWrapper>
		</React.Fragment>
	)
}

Accordion.propTypes = {
	children: PropTypes.node,
	isOpen: PropTypes.bool,
	title: PropTypes.string
}
