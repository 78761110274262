import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'
import React from 'react'
import DV from '../../../components/dataView/DataView'
import EditPartInner from './EditPartInner'
import EditPartOuter from './EditPartOuter'

function InnerDecoration({ child1, child2 }) {
	if (!child1)
		return null

	return <div className='layout-part'>
		{child1}
		{child2}
	</div>
}

export default function EditPart({ part, name, dataType, useLinkName, title, children, createEditForm, polymorphic, autoOpenTab }) {
	return (
		<ErrorBoundary>
			<DV.Part
				part={part}
				name={name}
				title={title}
				outerContent={<EditPartOuter useLinkName={useLinkName} dataType={dataType} autoOpenTab={autoOpenTab} />}
				innerContent={<EditPartInner createEditForm={createEditForm} polymorphic={polymorphic} part={part} />}
				innerDecoration={<InnerDecoration />}
			>
				{children}
			</DV.Part>
		</ErrorBoundary>
	)
}
