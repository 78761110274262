import { MarkManager } from './MarkManager.js'
import { getFileNameWithoutExtension } from 'Tools/IOTools'

export class Data {
	/**
	 * 
	 * @param {object} args
	 * @param {
	 * 	left:[
	 * 		{
	 * 			id:string, 
	 * 			value:string,
	 * 			next:[string]
	 * 		}
	 * 	],
	 * 	right:[]
	 * } args.values
	 * 	
	 */
	constructor({ values }) {
		this._changes = false
		this._markLeft = new MarkManager()
		this._markRight = new MarkManager()
		this.left = values.left
		this.right = values.right
		this._connect()
	}

	get changes() { return this._changes }
	set changes(v) { this._changes = v }

	get connections() {
		return {
			left: this.left
				.filter(v => v.next?.length > 0)
				.map(v => v.value + '->' + v.next.map(vv => {
					return this.rightById(vv)?.value ?? '!! ' + this.leftById(vv)?.value ?? vv
				})),
			right: this.right
				.filter(v => v.next?.length > 0)
				.map(v => v.value + '->' + v.next.map(vv => {
					return this.leftById(vv)?.value ?? '!! ' + this.rightById(vv)?.value ?? vv
				}))
		}
	}

	get values() { return { left: this.left, right: this.right } }

	onRenderProvider({ values }) {
		this.left = values.left
		this.right = values.right
		this._connect()
	}

	_connect() {
		this._setBacks()
		this._sortValues()
		this._setMaps()
		this._markLeft.clear()
		this._markRight.clear()
	}

	_setBacks() {
		const left = new Map()
		const right = new Map()

		this._mapLeft = left
		this._mapRight = right

		this.left.forEach(v => left.set(v.id, { v }))
		this.right.forEach(v => right.set(v.id, { v }))

		this.left.forEach(v => {
			v._back = undefined
		})
		this.right.forEach(v => {
			v._back = undefined
		})
		this.left.forEach(v => {
			if (!v.next)
				return
			v.next.forEach(id => {
				const vv = right.get(id)
				if (vv)
					vv.v._back = v
			})
		})
		this.right.forEach(v => {
			if (!v.next)
				return
			v.next.forEach(id => {
				const vv = left.get(id)
				if (vv)
					vv.v._back = v
			})
		})
	}

	_sortValues() {
		const f = (a, b) => {
			const aBack = (a.next ? a.value : undefined) ?? a._back?.value ?? ''
			const bBack = (b.next ? b.value : undefined) ?? b._back?.value ?? ''
			const res = aBack.localeCompare(bBack)

			if (res !== 0)
				return res

			return a.value.localeCompare(b.value)
		}
		this.left.sort(f)
		this.right.sort(f)
	}

	_setMaps() {
		const left = this._mapLeft
		const right = this._mapRight

		this.left.forEach((v, index) => left.set(v.id, { v, index }))
		this.right.forEach((v, index) => right.set(v.id, { v, index }))

		this.left.filter(v => v.next).forEach(v => {
			const _next = []
			v.next.map(id => {
				const e = right.get(id)
				if (e)
					_next.push(e.index)
			})

			if (_next.length > 0) {
				v._nextFirst = this.right[Math.min(..._next)].id
				v._nextLast = this.right[Math.max(..._next)].id
			}
		})

		this.right.filter(v => v.next).forEach(v => {
			const _next = []
			v.next.map(id => {
				const e = left.get(id)
				if (e)
					_next.push(e.index)
			})

			if (_next.length > 0) {
				v._nextFirst = this.left[Math.min(..._next)].id
				v._nextLast = this.left[Math.max(..._next)].id
			}
		})
	}

	leftById(id) { return this._mapLeft.get(id)?.v }
	rightById(id) { return this._mapRight.get(id)?.v }
	markLeftIsFree() {
		return !this._markLeft.ids.some(id => {
			const left = this.leftById(id)
			return left && (left._back || left.next)
		})
	}
	markRightIsFree() {
		return !this._markRight.ids.some(id => {
			const right = this.rightById(id)
			return right && (right._back || right.next)
		})
	}

	_removeMapLeftIds(ids) {
		this._mapRight.forEach(_row => {
			const row = _row.v
			if (!row.next)
				return

			const count = row.next?.length
			row.next = row.next.filter(n => !ids.includes(n))
			if (row.next?.length !== count)
				this.changes = true
			if (!row.next.length) {
				row.next = undefined
				this.changes = true
			}
		})
	}

	_removeMapRightIds(ids) {
		this._mapLeft.forEach(_row => {
			const row = _row.v
			if (!row.next)
				return

			const count = row.next?.length
			row.next = row.next.filter(n => !ids.includes(n))
			if (row.next?.length !== count)
				this.changes = true
			if (!row.next.length) {
				row.next = undefined
				this.changes = true
			}
		})
	}

	_addMapLeftIds(id, ids) {
		const row = this.leftById(id)

		if (!row)
			return false

		this._removeMapRightIds(ids)
		if (row.next)
			row.next.push(...ids.filter(id => !row.next.includes(id)))
		else
			row.next = ids
	}

	_addMapRightIds(id, ids) {
		const row = this.rightById(id)

		if (!row)
			return false

		this._removeMapLeftIds(ids)
		if (row.next)
			row.next.push(...ids.filter(id => !row.next.includes(id)))
		else
			row.next = ids
	}

	_linkLeft() {
		if (this._markLeft.count === 1 && this._markRight.count > 0) {
			const idLeft = this._markLeft.ids[0]
			const left = this.leftById(idLeft)

			if (!this.markRightIsFree())
				return false

			//		left0 --> |right|
			//		|left|
			//		...
			//zamieniamy na:
			//		left0 <-- |right|
			//		left  <--
			//		...
			if (left._back?.next?.length === 1) {
				left.next = [left._back.id, ...this._markRight.ids]
				left._back.next = undefined
				return true
			}

			//		|left| -->  *
			//		       ...
			//		       -->  | |
			//		       ...
			if (!left._back) {
				this._addMapLeftIds(idLeft, this._markRight.ids)
				return true
			}

			return false
		}
	}

	_linkRight() {
		if (this._markRight.count === 1 && this._markLeft.count > 0) {
			const idRight = this._markRight.ids[0]
			const right = this._mapRight.get(idRight)?.v

			if (!this.markLeftIsFree())
				return false

			//		|left| <-- right0
			//		           |right|
			//		           ...
			//zamieniamy na:
			//		|left| --> right0
			//		       --> |right|
			//		           ...
			if (right._back?.next?.length === 1) {
				right.next = [right._back.id, ...this._markLeft.ids]
				right._back.next = undefined
				return true
			}

			//		  * <-- |left|
			//		... <-- 
			//		| | <--
			//    ...
			if (!right._back) {
				this._addMapRightIds(idRight, this._markLeft.ids)
				return true
			}

			return false
		}
	}

	link() {
		if (this._linkLeft() || this._linkRight()) {
			this._connect()
			this.refresh()
			this.changes = true
			return true
		}

		return false
	}

	unlink() {
		this._removeMapLeftIds(this._markLeft.ids)
		this._removeMapRightIds(this._markRight.ids)

		this._markLeft.ids.forEach(id => {
			const row = this.leftById(id)

			if (row.next) {
				row.next = undefined
				this.changes = true
			}
		})
		this._markRight.ids.forEach(id => {
			const row = this.rightById(id)

			if (row.next) {
				row.next = undefined
				this.changes = true
			}
		})

		this._connect()
		this.refresh()
	}

	_autoLink() {
		this.left.forEach(fileOnLeftSide => {
			if (fileOnLeftSide.next)
				return

			const fileNameWithoutExtension = getFileNameWithoutExtension(fileOnLeftSide.value);
			const matchingFileOnRightSide = this._findFileOnRightSideByFileNameWithoutExtension(fileNameWithoutExtension)
			if (!matchingFileOnRightSide)
				return

			fileOnLeftSide.next = [matchingFileOnRightSide.id]
			this.changes = true
		});
	}

	/**
	 * Wyszukuje pliku po prawej stronie po nazwie pliku
	 * @param {string} fileName 
	 * @returns Plik po prawej stronie lub undefined jeśli nie znaleziono
	 */
	_findFileOnRightSideByFileNameWithoutExtension(fileName)
	{
		return this.right.find(fileOnRightSide => {
			const fileNameWithoutExtension = getFileNameWithoutExtension(fileOnRightSide.value)
			return fileNameWithoutExtension === fileName
		});
	}

	autoLink() {
		this._autoLink()
		this._connect()
		this.refresh()
	}
}