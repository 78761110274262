import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import useForm from 'components/form/hooks/useForm'
import getFormRestService from 'services/getFormRestService'
import FormMetaArray from '../form/FormMetaArray'
import { useTranslate } from 'locale/Locale'
import { Combobox, ComboboxInput, ComboboxMenu, ComboboxTag } from './Combobox'
import  { RefLinkIcon, makeRefLink, getDataType } from './RefLinkIcon'
import { InputAdornment } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'lodash'
import { GlobalStrings } from 'GlobalStrings'
import { useComboboxRefListSetState, useComboboxRefListState } from './ComboboxRefListProvider'
import { ComboboxRefInput, formDataToValues } from './ComboboxRef'
import { FIELD_REF_LINK_NAME } from '../list/components/filtration/FilterList'

/**
 * Wrapper dla Kontrolki ComboboxRef. Oplata kontrolkę w komponent FormMetaArray 
 * potrzebny do prawidłowej komunikacji z BE oraz przekazuje w dół propsy
 * 
 * @param {String} name - Nazwa kontrolki z BE
 * @param {Boolean} hasLabel - Informacja czy posiada ona labelkę czy nie
 */

function ComboboxMetaDriven({ name, hasLabel }) {
   const form = useForm('data')
   const field = form.getField(name)

   return (
      <FormMetaArray limit={undefined}>
         <ComboboxRefList name={name} form={form} field={field} hasLabel={hasLabel} />
      </FormMetaArray>
   )
}

/**
 * Komponent Kontrolki Combobox dla wartośći REF
 * @param {String} name - Nazwa kontrolki z BE
 * @param {Object} field - Obiekt zawierający najważniejsze informacje o danym polu przychodzące z BE
 * @param {Boolean} hasLabel - Informacja czy posiada ona labelkę czy nie
 */

function ComboboxRefList({ name, field, hasLabel, ...other }) {

   const form = useForm('data')
   const translate = useTranslate('WebSpa/Inputs/Combobox')
	const translateRef = useTranslate('WebSpa/Snackbar/ComboboxRef')
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()

   const comboboxRefListOptionsState = useComboboxRefListState();
   const setcomboboxRefListOptionsStateState = useComboboxRefListSetState();
   
   const configurationPath = field.form.parentForm  && field.form.parentForm._configurationPath //Dodane na potrzeby karteczki 160 z Trello
   
   
   if(name === 'Value' && field?.meta[FIELD_REF_LINK_NAME] !== undefined) {
      name = field?.meta[FIELD_REF_LINK_NAME];
   }

   form.preventRenderErrorModal = true //Powstrzymanie modala z błędem przed pojawieniem się
   
   const href = field.form.getLinkHRef(name)
   const { disabled, required, value, meta, placeholder, error, label } = field
   const [open, setOpen] = React.useState(false)
   const [options, setOptions] = React.useState([])
   const loading = open && options.length === 0
   const noOptionPlaceholderObiect = Object.freeze({
      value: translate('noOptionText'),
      disabled: true,
   })

   let refLink = makeRefLink(name, field)
   const refIdData = {
      name: name,
      dataType: getDataType(name, field),
      inputName: "RefLink",
   }

   const inputParams = {
      name,
      label,
      error,
      placeholder,
      disabled,
      required,
      hasLabel,
   }

   const localizations = {
      noOptionsText: translate('noOptionText'),
      loadingText: translate('loadingText'),
      clearText: translate('clearText'),
      closeText: translate('closeText'),
      openText: translate('openText'),
   }

   useEffect(() => {
      let active = true
      const options = {
         refreshAppState: false
      }

      if (!loading) {
         return undefined
      }

      if(!isEmpty(field.meta?.refValues)){ // Jeśli BE przysyła tablicę refValues to używamy jej zamiast pobierać wartości z API
         setOptions(field.meta?.refValues)
         return
      }

      (async () => {

         //jeśli wcześniej pobraliśmy listę wartości referencyjnych to nie musimy ich pobierać ponownie
         if(comboboxRefListOptionsState){
            setOptions(comboboxRefListOptionsState);
            return () => {
               active = false;
            };
         }

         const isOk = await getFormRestService(href).get(form, options)
     
         if(isOk){
            let options = formDataToValues(form.rows, setOptions, name, configurationPath) //configurationPath - Dodane na potrzeby karteczki 160 z Trello

            setcomboboxRefListOptionsStateState(options);

            if (isEmpty(form.rows)) {//Sytuacja w której BE zwrócił pustą tablicę data - Nie ma rekordów do wyświetlenia
               setOptions([noOptionPlaceholderObiect])
            }
         }
         else { //Obsługa błędów przy pobieraniu danych z BE
            setOptions([ noOptionPlaceholderObiect ])
            
            enqueueSnackbar(translateRef('error'), //TODO Zamienić tekst informacji o błędzie, na tekst opisu błędu przychodzący z BE
            {
               variant: 'error', 
               persist: true, 
               action: key => <Close onClick={() => closeSnackbar(key)} style={{cursor: 'pointer', marginRight: 5, fontSize: '1.3rem' }} />
            })
         }
      })()


      return () => {
         active = false
      }
   }, [loading])

   useEffect(() => {
      if (!open) {
         setOptions([])
      }
   }, [open])


   const handleChange = (e, newValue) => {

      //Sytuacja gdy wartość jest stringiem
      if (newValue) {
         field.handleChange(newValue)
         return
      }

      //Sytuacja gdy wpiszemu Nulla
      if (newValue === null && !required) {
         field.handleChange(newValue)
         return
      }
      return
   }

   let comboboxValue = field?.value;
   if (!Array.isArray(comboboxValue) && comboboxValue){
      comboboxValue = [comboboxValue];
   }

   return (
      <Combobox
         name={name}
         inputParams={inputParams}
         localizations={localizations}
         value={field?.value ?? []}
         open={open}
         options={options}
         loading={loading}
         onOpen={e => {
            if((e.type === 'mousedown' || e.type === 'click') && (e.ctrlKey || e.shiftKey  || e.altKey  || e.metaKey)) {
               setOpen(false)
            }
            else {
               setOpen(true)
            }
         }}
         multiple
         onClose={() => setOpen(false)}
         getOptionLabel={(option) => option.value || ""}
         getOptionSelected={((option, value) => option.id === value.id)}
         getOptionDisabled={(option) => option.disabled}
         onChange={(e, newValue) => handleChange(e, newValue)}
         renderInput={(params) => (<ComboboxRefInput name={name} inputParams={inputParams} params={params} loading={loading} refLink={refLink} refIdData={refIdData}/>)}
         renderOption={(option, inputValue) =><ComboboxMenu option={option.value} inputValue={inputValue} name={name} /> }
         limitTags={1}
         renderTags={(tags, getTagProps) => { 
            if (tags.map)
               return (tags.map((tag, index) => <ComboboxTag key={index} getTagProps={getTagProps} value={tag?.value} index={index} />))
         }}
         {...other}
         isComboboxList
      />
   )
}

//#region Export komponentów
   export default ComboboxMetaDriven
   export { ComboboxRefList }
//#endregion


ComboboxMetaDriven.propTypes = {
   name: PropTypes.string.isRequired,
   hasLabel: PropTypes.bool,
   onChange: PropTypes.func,
}

ComboboxRefList.propTypes = {
   name: PropTypes.string,
   field: PropTypes.object.isRequired,
   hasLabel: PropTypes.bool,
   onChange: PropTypes.func,
}

ComboboxMetaDriven.defaultProps = {
   hasLabel: true,
}
//#endregion
