import React from 'react'
import DV from '../../../components/dataView/DataView'
import { FormMetaObject, useForm, FormConsumer } from 'components/form/Form'
import getFormRestService from "services/getFormRestService"
import ModalUpa from 'components/modalUpa/ModalUpa'
import { useHistory } from 'react-router-dom'
import { linkManager } from 'components/LinkManager'
import { GlobalStrings } from 'GlobalStrings'
import { MESSAGE_ACTIONS, useIconMessageDispatch } from 'components/AppMessages';
import { isArray, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { handleUploadMultipleFileFields } from 'components/form/components/file/File';
import { useTranslate } from 'locale/Locale';

export default function EditPartOuter({ dataType, useLinkName, children, part, autoOpenTab }) {

	const { enqueueSnackbar } = useSnackbar();

	const parentForm = useForm('data')
	const dataView = DV.useDataView()
	const history = useHistory()
	const [modalButton, setModalButton] = React.useState(undefined)

	const iconMessageDispatch = useIconMessageDispatch();

	const translate = useTranslate('WebSpa/Snackbar/UploadFile/CreatePartOuter/SnackbarMessage');


	React.useEffect(()=>{
		linkManager.handleAutoTab(history, part, autoOpenTab)
	},[])

	return (
		<FormMetaObject
			isFormUsingServices
			configurationPath={part.dataTypePath}
			editMode='edit'
			data={parentForm.rowManager.rowData}
			onLoad={async (form, loadType) => {
				part.props.form = form

				if (loadType === 'reload') {
					let readOne = form.serviceReadOneHRef

					if (!readOne)
						return

					readOne = readOne.replace('{id}', form.id)

					if (!await getFormRestService(readOne)
						.getOne(form))
						return

					if (form.parentForm) {
						form.parentForm.rowManager.setRowFromFormChild(form)
						form.parentForm.trigger('data')
					}

					iconMessageDispatch({
						type: MESSAGE_ACTIONS.APPEND_MESSAGE,
						payload: {
							messages: form.parentForm.popObjectInfoMessages(),
							updatedDataType: form.dataType,
							messagesOwnersId: [ form.data.data[0].id ]
						}
					});
				}
			}}
			dataType={dataType}
			useLinkName={useLinkName}
			onSubmit={async (form, submitType, props) => {
				
				if (submitType === GlobalStrings.reloadOnChangeSubmitType) {
					await form.handleReloadOnChangeSubmitType(props);
				}
				else if (submitType === 'delete' && await form.onDeleteButton(submitType)) {
					if (form.parentForm) {
						linkManager.handleListNewPage(history, part.parent, true);
						form.parentForm.asyncLoad();
					}
				}
				else if (await form.onSubmitButton(submitType)){
					if (form.parentForm) {
						form.parentForm.rowManager.rowData = { ...form.parentForm.rowManager.rowData }
						form.parentForm.trigger('data')
					}
				}
				//Sytuacja kiedy aktualizujemy objekt	
				else if (form.onDisplayModalUpa(submitType, button => setModalButton(button))) {}
				else if (!submitType) {

					//Obsługa przesyłania plików
					if (isArray(form.allFileFields) && !isEmpty(form.allFileFields)) {
						handleUploadMultipleFileFields(false, form, part, enqueueSnackbar, translate, history);
						return;
					}

					await getFormRestService(form.serviceUpdateHRef).patch(form, '');
				} 
				else {
					form.onLinkButton(submitType);
				}

				iconMessageDispatch({
					type: MESSAGE_ACTIONS.APPEND_MESSAGE,
					payload: {
						messages: form.parentForm.popObjectInfoMessages(),
						updatedDataType: form.dataType,
						messagesOwnersId: [ form.data.data[0].id ]
					}
				});

			}}
		>
			<FormConsumer eventName='data'>
				{form => {
					

					if (typeof part.props.title === 'function')
						part.props.functionTitle = part.props.title
					if (part.props.functionTitle) {
						let t = part.props.title
						part.props.title = part.props.functionTitle(form)
						if (t !== part.props.title)
							dataView.refresh()
					}
					let isChangeReachable = false

					if (part.parts)
						part.parts.forEach(p => {
							let dataType = p.props.useLinkName || p.props.dataType
							let r = !!(form.getServiceRelativeRead(dataType) || form.getServiceRelativeReadOne(dataType))
							isChangeReachable = isChangeReachable || p.reachable !== r
							p.reachable = r
						})
					if (isChangeReachable)
						dataView.refresh()
					return null
				}}
			</FormConsumer>
			<ModalUpa modalButton={modalButton} setModalButton={setModalButton} part={part}/>
			{children}
		</FormMetaObject>
	)
}
