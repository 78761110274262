import React from 'react';

export function ReadOnly(){

return(
  <>
    <div />
  </>
)}

