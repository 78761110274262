import React from 'react'
import DV from '../../components/dataView/DataView'
import './dataView/DataView.scss'
import Layout from 'components/layout/Layout'
import Tabs from './dataView/Tabs'
import Parts from './Parts'
import TopBarWrapper from './dataView/TopBarWrapper'
import OnClickListener from './OnClickListener'
import HelperModal from 'components/HelperModal/HelperModal'
import { linkManager } from 'components/LinkManager'
import { useHistory } from 'react-router-dom'
import Info from 'components/info/Info'

function SynchronizeRoot() {
	const dataView = DV.useDataView()
	const history = useHistory()

	React.useEffect(() => {
		return history.listen(location => {
			linkManager.synchronize(dataView)
		})
	}, [])

	linkManager.synchronize(dataView)
	return null
}

export default function Main() {
	const [open, setOpen] = React.useState(false)

	return (
		<DV.Provider>
			<SynchronizeRoot />
			<HelperModal open={open} setOpen={setOpen} />
			<OnClickListener open={open} setOpen={setOpen}>
				<Layout>
					<div className='layout-right-inner'>
						<TopBarWrapper />
						<Info/>
						{/* <Tabs /> */}
						<Parts />
					</div>
				</Layout>
			</OnClickListener>
		</DV.Provider>
	)
}