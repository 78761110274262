import { fade, makeStyles } from '@material-ui/core/styles'

/**
 * Komponent Stylizujący wyszukiwarkę
 */

export const useStyles = makeStyles((theme) => ({
	search: {
		position: 'relative',
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
		  backgroundColor: fade(theme.palette.common.white, 0.25),
		},
	 },
	 searchIcon: {
		color: theme.palette.primary.buttonPrimary,
		padding: theme.spacing(0, 1),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	 },
	 inputRoot: {
		color: 'inherit',
		height: 34,
	 },
	 inputInput: {
		border: `1px solid ${theme.palette.primary.mainDarken1000}`,
		borderLeftColor: 'transparent',
		padding: '6px 7px 7px 0',
		minWidth: 30,
		width: 150,
		paddingLeft: `calc(1em + ${theme.spacing(2.5)}px)`,
		transition: theme.transitions.create('width'),
	 },
}))



