import React from 'react'
import useForm from 'components/form/hooks/useForm'
import { Horizontal } from 'components/topology/Topology'
import RowsPerPage from './RowsPerPage'
import DetailPaginator from './DetailPaginator'

/**
 * Komponent paginatora
 *
 * @returns {Node}
 */
function Paginator({ onPage }) {
	const form = useForm('data')

	return (
		<Horizontal className='grid-aiy-center grid-acx-space-between m-top-10'>
			<RowsPerPage />
			<DetailPaginator form={form} onPage={onPage} />
		</Horizontal>
	)
}

export default Paginator