import { CryptoLocalStorage } from 'components/Storages'
import getFormRestService from 'services/getFormRestService'

/**
 * Klasa sesji przechowującej tokeny do serwera
 *
 */
export default class Session {
	constructor() {
		this._modulesStorage = Object.freeze({
			inquiries: '45d48353-ebda-4c14-a00f-3ae83a092546',
			offers: 'c3a50f7d-b40f-4e1c-85b3-fc7ec189ac0d',
			specifications: '5e5be3fc-c8d0-4f72-81cb-d5ba559cb34b',
			projects: 'fd7c4fed-d620-479f-ab12-f8275ea546a2',
			CRMModule: '8206584E-D098-ED11-9A8D-83072F03447B',
			AllCRM: 'A23242D3-469E-ED11-992B-E1D12F03447B',
			MyCRM: 'C0165AD7-469E-ED11-93CD-E3D12F03447B',
			AppReportCRMContacts: 'B60D9F52-B72A-EE11-8277-A1BAF81237D8',
			AllCrmCustomerContacts: '6481D195-9D48-EE11-8D1D-75CC4A014ABB',
			complaint: 'd1bacdf8-5d31-4178-ae66-ea37774993f9',
			priceLists: 'd43132d4-719c-46da-a190-07bad4951b9f',
			companies: 'c3d607ac-b208-41f5-b0c7-add1f31ab140',
			companiesTS: 'e617d93c-a3ae-ed11-8c1b-2c3b1d65b421',
			settlementCompanies: 'F4D54514-B0F0-ED11-8B77-88F156818483',
			myProfile: 'af72d2d1-06bf-4d30-8319-c44d13a1c0fc',
			myProfileTS: '4AE3AC88-6BC9-ED11-A58C-34BB51818483',
			featureTypes: '87d862d7-907a-41f2-ba0c-17f0f5721c9e',
			productTypes: '9f541334-5f5c-4f1d-88a5-1afeec8b6aaa',
			templates: '36acbf89-897e-4ca0-8a3e-a9a7b9aa7567',
			qualityAssurance: '4cd10c4a-b0fb-4976-a43e-72b396330352',
			priceListItemCompanySupplier: '0c62d6f2-da33-48d7-a9cc-645dfe77bbca',
			jaspersoftReports: '96605c5c-224e-ed11-ab99-dadff64a16c9',
			invoice: 'a79ec6f0-702f-4655-86dd-fcb1ba950261',
			costInvoice: 'fcc12552-74c4-6145-a3d0-fbe87b3591b1',
			provisionalInvoice: '46FCAEF0-34E6-EC11-AA83-ADC789427FBC',
			receipt: 'DE3554F7-34E6-EC11-92EE-AEC789427FBC',
			prepaymentInvoice: 'FAA619A0-52F3-EE11-B994-302ACBEDD513',
			correctingInvoice: 'F2968F48-9833-EF11-A464-3BA9AF68225E',
			proposedJob: '315c066f-1a2a-4f3d-8a16-6e52f776b48d',
			reportProfitability: '722b13a5-77f3-413a-98a5-feef810109f4',
			reportProfitabilityGlobalDaily: '22058e01-f29e-ed11-a944-63e12f03447b',
			reportProfitabilityGlobalMonthly: '505c870a-f29e-ed11-9a73-64e12f03447b',
			reportProfitabilityGlobalMonthlyLanguage: 'AE430CBD-F847-EE11-8F2B-8ABC4A014ABB',
			reportProfitabilityGlobalMonthlyPerson: '32893D02-C248-EE11-A72E-4FD04A014ABB',
			reportProfitabilityGlobalMonthlyProduct: '4C252307-C248-EE11-8278-50D04A014ABB',
            appReportSupplierTasksToBeInvoiced: 'ff7e3bf6-257c-b3e5-e053-2300f40a2941',
			reportOfferEfficiency: '2caebd28-93e8-4b32-a5c3-1ffbf0c046c9',
			reportQaEfficiency: '6a983bc2-0abd-40c1-b6d3-8aa425dd7608',
			group: 'd715d231-ba29-4f94-a5f9-1503918ddcb5',
			currency: 'ab969f8e-5237-450d-8889-ed7ad2de0f30',
			projDeliveryTemplate: 'deabc883-c44e-461d-b24b-d592e9a2bdb6',
			templateDefModule: '6c716ccf-9bbd-c059-e053-9c00f40a069e',
			templateDefProjectManagement: '64cd4859-329f-4e0e-8837-e6ecef8b10cc',
			templateDefInvoice: '6c716ccf-9cbd-c059-e053-9c00f40a069e',
			templateDefTranslationService: '8C844E85-DAC4-ED11-81FA-98E350818483',
			templateDefSettlements: '2ECFB4F8-E498-EE11-B4E7-9769CAA25344',
			translationPriceModifierCustomer: '943E19C3-3B3F-ED11-BFC2-9926F54A16C9',
			translationPriceModifierSupplier: 'CC4E35C8-3B3F-ED11-BAE8-9B26F54A16C9',
			customerSupplierTypePref: '3c72f699-b539-40eb-b5bb-20231ec4602d',
			translationEnginePref: 'cca37725-71e1-46ce-8a7b-eeaead0bf0de',
			translationSubengine: '3c13f2dc-1f66-e540-b94b-44781d0628e4',
			customerRetentionAlerts: '1C5718D4-864C-EE11-95CB-36354B014ABB',
			vatTaxRate: '6a19b51c-ab5b-4d36-8614-a759d2bb4380',
			settlementSource: 'b7838c3b-0708-bc46-bf50-410797701844',
			settlement: '0fdcb134-ee87-e54c-868d-d9c195c1b233',
			settlementDocument: 'd53f9e00-58e2-a547-a200-b3ff5f43023c',
			payments: '94f23ecf-01c4-ee11-ba7e-dc8998240263',
			costDocument: '02608B9F-7F9E-EE11-AC17-CF06CBA25344',
			SettlementCompensation: 'C8A7820F-0374-EE11-AFBC-1DF72045802B',
			role: '39279d6f-1be9-41cb-9b96-c76fb7159c24',
			organization: '09832330-3f29-4701-bfee-ea1bcfd89085',
			organizationConfiguration: '0ac2ca70-1143-405b-902a-f47a934e1a4b',
			systemConfigurationModule: '2b02be3a-9237-46ab-a982-77d909e6ed92',
			systemConfigurationLivoCore: '3457E1E4-D890-ED11-9201-BBB48F9CA82B',
			systemConfigurationIdentity: '0C4665EA-D890-ED11-A669-BDB48F9CA82B',
			serviceUsage: 'D6C818A5-83F8-ED11-AA95-29C071ECC3CE',
			agreementFile: 'f6f11ce2-f5d1-df72-e053-0f00f40a3a91',
			registryForDocuments: '2d530340-4364-49ae-8953-5262f4b7f430',
			documentRegistryHistoryModule: '1415572f-cb09-ed11-b253-afa9aef140c6',
			documentRegistryHistoryProjectManagement:'8A13A89F-35AE-EE11-B223-36E995240263',
			documentRegistryHistoryInvoice:'940D34A6-35AE-EE11-A19C-37E995240263',
			documentRegistryHistorySettlements:'D8E90AAA-35AE-EE11-AF22-39E995240263',
			documentRegistryHistoryTranslationService:'F49854AE-35AE-EE11-9F58-3AE995240263',
			internationalization: '312ba319-d0f7-4356-8beb-bd2257747bd0',
			organizationInternationalization: '5447753c-fe36-4e0e-b583-d6844fda09c2',
			myTasksModule: '2f4f529f-d197-499c-81e1-690be000ff7b',
			MyTasksProposedTasks: '145133B1-C885-ED11-BC05-8A648E9CA82B',
			myTasksCurrentTasks: '0577591f-cef9-4330-8883-523c2bedd880',
			myTasksFinishedCurrentTasks: '14B19F28-16CD-EC11-9EFB-234E61CF5284',
			myTasksInquiries: '1c50de26-1f58-4a01-8a62-827869eb7e67',
			myTasksTasks: '0502b9e7-d5a6-477b-b5fe-d4dfecd240cc',
			myTasksUnsentOffers: '0ab7880c-431d-4440-8a35-c6e7b191dc12',
			myTasksOffersAwaitingAcceptance: '86BEF29C-BF1C-EE11-AD2D-D6EF7AE4FB53',
			configurationModule: '7c4e95bc-4b97-445c-82f4-fbce20585b8b',
			configurationProjectManagement: 'e1965653-7bf9-4304-9e3c-a7415a00b2dc',
			configurationInvoice: '1ce127ee-9c2c-4783-8368-534262487b2e',
			configurationCRM: '80061124-5BF9-ED11-B618-E6DC75E4FB53',
			configurationTranslationService: 'E4778445-DCB8-ED11-8B0C-D406192994AA',
			configurationNotifications: '9817df2d-2bae-41f7-836a-031776c46b51',
			nonWorkingDays: '710dc2e3-15d3-c92d-e053-6500f40a88e5',
			automaticOfferFileExtension: '98201552-e910-ee11-ba4c-6ef078e4fb53',
			automaticOfferDocumentStyleMapping: '16c950e7-f610-ee11-b0bd-fef278e4fb53',
			configurationSettlements: '2c4caecb-8204-4881-bde0-2d29737a67b3',
			organizationProfile: '28c9cdcf-b839-6b6f-e053-9c00f40a3d0e',
			templateString: '6CA7FAA3-C11F-ED11-84F0-7143CD46D76E',
			version: '2e225603-eb21-ed11-999b-1a75cd46d76e',
			teachingMaterials: 'EE58598B-EFC7-ED11-B0F7-5F3351818483',
			templateStringModule: '80E3EFE1-7D29-EF11-8E56-58DDC0566778',
			templateStringProjectManagement: '20B50401-7E29-EF11-B670-5BDDC0566778',
			templateStringInvoice: '0E1AC7C2-C62C-EF11-A0D0-7AA6AE68225E',
			templateStringSettlements: 'A87BFFC5-C62C-EF11-B719-7BA6AE68225E',
			templateStringTranslationService: 'E8E0D4C8-C62C-EF11-9D37-7CA6AE68225E',
			Persons: '6e1b2ff5-a533-ef11-9aa3-d0abaf68225e',
			Customers: '9cc697f9-a533-ef11-8d1a-d2abaf68225e',
			Suppliers: '247c84f7-a533-ef11-9969-d1abaf68225e',
		})
	}

	addJaspersoftModule(moduleName, moduleLabel, moduleId, moduleLink) {
		const modules = { ...this._modulesStorage};
		modules[moduleName] = { label: moduleLabel, id: moduleId, link: moduleLink };
		this._modulesStorage = Object.freeze({
			...modules,		
		});

		this._value.Modules.push({ label: moduleLabel, id: moduleId, name: moduleName, link: moduleLink });

		this.value = this._value;
	}

	/**
	 * Wartość przechowywana w sesji
	 *
	 */
	set value(v) 
	{
		this._value = v
		if(v == null)
			this._links = null;
		CryptoLocalStorage.set('login', v)
		this.waitForRefresh()
	}
	/**
	 * Pobranie wartości przechowywanej w sesji
	 *
	 */
	get value() {
		return this._value
	}
	/**
	 * Funkcja zwracająca id konkretnego modułu z tablicy _modulesStorage po jego nazwie.
	 * Nazwy są takie same jak nazwy nadane konkretnym <ListPart /> w pliku Parts.js.
	 * @param {String} moduleName Nazwa nadana konkretnym <ListPart /> w pliku Parts.js
	 * @returns id konkretnego modułu
	 */
	getModuleIdFromStorage(moduleName) {
		return this._modulesStorage[moduleName]
	}

	/**
	 * Czy jest autoryzacja
	 *
	 */
	get isAuthorized() { return this._value !== undefined }
	/**
	 * Login usera
	 */
	get login() { return this._value.Login }
	/**
	 * token
	 */
	get accessToken() { return this._value.AccessToken }
	/**
	 * Czas przedawnienia tokena
	 */
	get accessTokenExpiration() { return new Date(this._value.AccessTokenExpiration) }
	/**
	 * refresh token
	 */
	get refreshToken() { return this._value.RefreshToken }
	/**
	 * Czas przedawnienia refresh tokena
	 */
	get refreshTokenExpiration() { return new Date(this._value.RefreshTokenExpiration) }
	/**
	 * Identyfikator użytkownika
	 */
	get identityId() { return this._value.IdentityId }
	/**
	 * Moduły dostępne dla applikacji
	 */
	get modules() { return this._value.Modules }
	/**
	 * Czy użytkownik zaakceptował warunki korzystania z usługi
	 */
	get termsOfServiceHasBeenApproved() { return this._value.TermsOfServiceHasBeenApproved }
	/**
	 * Pobierz dokument (ResourceDocument) reprezentujący warunku korzystania z usługi, które muszą być zaakceptowane
	 */
	get termsOfServiceApprovalRequired() { return this._value.TermsOfServiceApprovalRequired }

	/**
	 * Funkcja która przeszukuje cały obiekt links szukając klucza pasującego do podanej jako parametr nazwy z przedrostkiem 'read'  
	 * @param {String} name - nazwa linku - powinna odpowiedać nazwie linku który chcemy znaleść z obiektu links
	 * @returns {String} - znaleziony na podstawie parametru name link z BE
	 */
	findLinkByName(name) {
		let findedLink = undefined

		for (const [key, value] of Object.entries(this.links)) {
			if (key.toLowerCase().includes('read' + name.toLowerCase()))
				findedLink = value.href
		}
		return findedLink
	}

	/**
	 * Znajduje moduły według jego id
	 *
	 * @param {any} id Id modułu
	 * @param {Array} modules gdzie ma szukać id
	 * @returns {Object} Znaleziony opis modułu lub undefined jeśli brak
	 */
	findModule(moduleName, modules) {
		if (!modules && this._value)
			modules = this.modules

		if (!modules)
			return

		let index = modules.findIndex(m => m.id.toUpperCase() === this.getModuleIdFromStorage(moduleName).toUpperCase())


		if (index >= 0)
			return modules[index]

		for (let i in modules) {
			let m = modules[i]

			if (m.modules) {
				let res = this.findModule(moduleName, m.modules)

				if (res)
					return res
			}
		}
	}
	/**
	 * Zamienia moduły na linki dostępu do nadrzednych końcówek BE
	 *
	 */
	get links() {
		if(this._links)
			return this._links;

		let links = {}
		let m

		m = this.findModule('inquiries')
		if (m) {
			links.readInquiry = links.createInquiry = m.link
			links.readOneInquiry = { ...m.link }
			links.readOneInquiry.href += '/{id}'
		}

		m = this.findModule('offers')
		if (m) {
			links.readOffer = links.createOffer = m.link
			links.readOneOffer = { ...m.link }
			links.readOneOffer.href += '/{id}'
		}

		m = this.findModule('specifications')
		if (m) {
			links.readSpecifications = links.createSpecifications = m.link
			links.readOneSpecifications = { ...m.link }
			links.readOneSpecifications.href += '/{id}'
		}

		m = this.findModule('projects')
		if (m) {
			links.readProject = links.createProject = m.link
			links.readOneProject = { ...m.link }
			links.readOneProject.href += '/{id}'
		}

	

		m = this.findModule('complaint')
		if (m) {
			links.readComplaint = links.createComplaint = m.link
			links.readOneComplaint = { ...m.link }
			links.readOneComplaint.href += '/{id}'
		}

		m = this.findModule('companies')
		if (m) {
			links.readCompany = links.createCompany = m.link
			links.readOneCompany = { ...m.link }
			links.readOneCompany.href += '/{id}'
		}

		m = this.findModule('companiesTS')
		if (m) {
			links.readCompaniesTS = links.createCompaniesTS = m.link
			links.readOneCompaniesTS = { ...m.link }
			links.readOneCompaniesTS.href += '/{id}'
		}
		
		m = this.findModule('settlementCompanies')
		if (m) {
			links.readSettlementCompanies = links.createSettlementCompanies = m.link
			links.readOneSettlementCompanies = { ...m.link }
			links.readOneSettlementCompanies.href += '/{id}'
		}

		m = this.findModule('myProfile')
		if (m) {
			links.readMyProfile = links.createMyProfile = m.link
			links.readOneMyProfile = { ...m.link }
		}

		m = this.findModule('myProfileTS')
		if (m) {
			links.readMyProfileTS = links.createMyProfileTS = m.link
			links.readOneMyProfileTS = { ...m.link }
		}

		m = this.findModule('nonWorkingDays')
		if (m) {
			links.readNonWorkingDays = links.createNonWorkingDays = m.link
			links.readOneNonWorkingDays = { ...m.link }
		}

		m = this.findModule('automaticOfferFileExtension')
		if (m) {
			links.readAutomaticOfferFileExtension = links.createAutomaticOfferFileExtension = m.link
			links.readOneAutomaticOfferFileExtension = { ...m.link }
		}

		m = this.findModule('automaticOfferDocumentStyleMapping')
		if (m) {
			links.readAutomaticOfferDocumentStyleMapping = links.createAutomaticOfferDocumentStyleMapping = m.link
			links.readOneAutomaticOfferDocumentStyleMapping = { ...m.link }
		}

		m = this.findModule('organizationProfile')
		if (m) {
			links.readOrganizationProfile = links.createOrganizationProfile = m.link
			links.readOneOrganizationProfile = { ...m.link }
		}

		m = this.findModule('featureTypes')
		if (m) {
			links.readValueType = links.createValueType = m.link
			links.readOneValueType = { ...m.link }
			links.readOneValueType.href += '/{id}'
		}

		m = this.findModule('priceLists')
		if (m) {
			links.readPriceList = links.createPriceList = m.link
			links.readOnePriceList = { ...m.link }
			links.readOnePriceList.href += '/{id}'
		}

		m = this.findModule('productTypes')
		if (m) {
			links.readEntity = links.createEntity = m.link
			links.readOneEntity = { ...m.link }
			links.readOneEntity.href += '/{id}'
		}

		m = this.findModule('templates')
		if (m) {
			links.readTemplateFile = links.createTemplates = m.link
		}


		m = this.findModule('templateStringModule')
		if (m && m.modules.length > 0)
			links.readTemplateStringModule = {}

		m = this.findModule('templateStringProjectManagement')
		if (m)
			links.readTemplateStringProjectManagement = m.link

		m = this.findModule('templateStringInvoice')
		if (m)
			links.readTemplateStringInvoice = m.link
		
		m = this.findModule('templateStringTranslationService')
		if (m)
			links.readTemplateStringTranslationService = m.link
		
		m = this.findModule('templateStringSettlements')
		if (m)
			links.readTemplateStringSettlements = m.link

		m = this.findModule('Persons')
		if (m && m.modules.length > 0)
			links.readPersons = {}

		m = this.findModule('Customers')
		if (m)
			links.readCustomers = m.link

		m = this.findModule('Suppliers')
		if (m)
			links.readSuppliers = m.link

		m = this.findModule('qualityAssurance')
		if (m) {
			links.readQualityAssurance = links.createQualityAssurance = m.link
			links.readOneQualityAssurance = { ...m.link }
			links.readOneQualityAssurance.href += '/{id}'
		}

		m = this.findModule('priceListItemCompanySupplier')
		if (m)
			links.readPriceListItemCompanySupplier = links.createPriceListItemCompanySupplier = m.link

		m = this.findModule('proposedJob')
		if (m) {
			links.readProposedJob = links.createProposedJob = m.link
			links.readOneProposedJob = { ...m.link }
			links.readOneProposedJob.href += '/{id}'
		}

		m = this.findModule('invoice')
		if (m) {
			links.readInvoice = links.createInvoice = m.link
			links.readOneInvoice = { ...m.link }
			links.readOneInvoice.href += '/{id}'
		}

		m = this.findModule('costInvoice')
		if (m) {
			links.readCostInvoice = links.createCostInvoice = m.link
			links.readOneCostInvoice = { ...m.link }
			links.readOneCostInvoice.href += '/{id}'
		}

		m = this.findModule('provisionalInvoice')
		if (m) {
			links.readProvisionalInvoice = links.createProvisionalInvoice = m.link
			links.readOneProvisionalInvoice = { ...m.link }
			links.readOneProvisionalInvoice.href += '/{id}'
		}

		m = this.findModule('prepaymentInvoice')
		if (m) {
			links.readPrepaymentInvoice = links.createPrepaymentInvoice = m.link
			links.readOnePrepaymentInvoice = { ...m.link }
			links.readOnePrepaymentInvoice.href += '/{id}'
		}

		m = this.findModule('correctingInvoice')
		if (m) {
			links.readCorrectingInvoice = links.createCorrectingInvoice = m.link
			links.readOneCorrectingInvoice = { ...m.link }
			links.readOneCorrectingInvoice.href += '/{id}'
		}
		
		m = this.findModule('receipt')
		if (m) {
			links.readReceipt = links.createReceipt = m.link
			links.readOneReceipt = { ...m.link }
			links.readOneReceipt.href += '/{id}'
		}

		m = this.findModule('reportProfitability')
		if (m) {
			links.readReportProfitability = links.createReportProfitability = m.link
			links.readOneReportProfitability = { ...m.link }
			links.readOneReportProfitability.href += '/{id}'
		}

		m = this.findModule('reportProfitabilityGlobalDaily')
		if (m) {
			links.readReportProfitabilityGlobalDaily = links.createReportProfitabilityGlobalDaily = m.link
		}

		m = this.findModule('reportProfitabilityGlobalMonthly')
		if (m) {
			links.readReportProfitabilityGlobalMonthly = links.createReportProfitabilityGlobalMonthly = m.link
		}

		m = this.findModule('reportProfitabilityGlobalMonthlyLanguage')
		if (m) {
			links.readReportProfitabilityGlobalMonthlyLanguage = links.createReportProfitabilityGlobalMonthlyLanguage = m.link
		}

		m = this.findModule('reportProfitabilityGlobalMonthlyPerson')
		if (m) {
			links.readReportProfitabilityGlobalMonthlyPerson = links.createReportProfitabilityGlobalMonthlyPerson = m.link
		}

		m = this.findModule('reportProfitabilityGlobalMonthlyProduct')
		if (m) {
			links.readReportProfitabilityGlobalMonthlyProduct = links.createReportProfitabilityGlobalMonthlyProduct = m.link
		}
        m = this.findModule('appReportSupplierTasksToBeInvoiced')
        if (m) {
            links.readAppReportSupplierTasksToBeInvoiced = links.createAppReportSupplierTasksToBeInvoiced = m.link
            links.readOneAppReportSupplierTasksToBeInvoiced = { ...m.link }
            links.readOneAppReportSupplierTasksToBeInvoiced.href += '/{id}'
        }

		m = this.findModule('reportOfferEfficiency')
		if (m) {
			links.readReportOfferEfficiency = links.createReportOfferEfficiency = m.link
			links.readOneReportOfferEfficiency = { ...m.link }
			links.readOneReportOfferEfficiency.href += '/{id}'
		}
		m = this.findModule('reportQaEfficiency')
		if (m) {
			links.readReportQaEfficiency = links.createReportQaEfficiency = m.link
			links.readOneReportQaEfficiency = { ...m.link }
			links.readOneReportQaEfficiency.href += '/{id}'
		}
		m = this.findModule('group')
		if (m) {
			links.readGroup = links.createGroup = m.link
			links.readOneGroup = { ...m.link }
			links.readOneGroup.href += '/{id}'
		}
		m = this.findModule('currency')
		if (m) {
			links.readCurrency = links.createCurrency = m.link
			links.readOneCurrency = { ...m.link }
			links.readOneCurrency.href += '/{id}'
		}

		m = this.findModule('projDeliveryTemplate')
		if (m) {
			links.readProjDeliveryTemplate = links.createProjDeliveryTemplate = m.link
			links.readOneProjDeliveryTemplate = { ...m.link }
			links.readOneProjDeliveryTemplate.href += '/{id}'
		}

		m = this.findModule('templateDefProjectManagement')
		if (m)
			links.readTemplateDefProjectManagement = m.link

		m = this.findModule('templateDefInvoice')
		if (m)
			links.readTemplateDefInvoice = m.link

		m = this.findModule('templateDefTranslationService')
		if (m)
			links.readTemplateDefTranslationService = m.link

		m = this.findModule('templateDefSettlements')
		if (m)
			links.readTemplateDefSettlements = m.link

		m = this.findModule('templateDefModule')
		if (m && m.modules.length > 0)
			links.readTemplateDefModule = {}

		m = this.findModule('translationPriceModifierCustomer')
		if (m) {
			links.readTranslationPriceModifierCustomer = links.createTranslationPriceModifierCustomer = m.link
			links.readOneTranslationPriceModifierCustomer = { ...m.link }
			links.readOneTranslationPriceModifierCustomer.href += '/{id}'
		}

		m = this.findModule('translationPriceModifierSupplier')
		if (m) {
			links.readTranslationPriceModifierSupplier = links.createTranslationPriceModifierSupplier = m.link
			links.readOneTranslationPriceModifierSupplier = { ...m.link }
			links.readOneTranslationPriceModifierSupplier.href += '/{id}'
		}

		m = this.findModule('customerSupplierTypePref')
		if (m) {
			links.readCustomerSupplierTypePref = links.createCustomerSupplierTypePref = m.link
			links.readOneCustomerSupplierTypePref = { ...m.link }
			links.readOneCustomerSupplierTypePref.href += '/{id}'
		}

		m = this.findModule('vatTaxRate')
		if (m) {
			links.readVatTaxRate = links.createVatTaxRate = m.link
			links.readOneVatTaxRate = { ...m.link }
			links.readOneVatTaxRate.href += '/{id}'
		}

		m = this.findModule('settlementSource')
		if (m) {
			links.readSettlementSource = links.createSettlementSource = m.link
			links.readOneSettlementSource = { ...m.link }
			links.readOneSettlementSource.href += '/{id}'
		}

		m = this.findModule('payments')
		if (m) {
			links.readPayments = links.createPayments = m.link
			links.readOnePayments = { ...m.link }
			links.readOnePayments.href += '/{id}'
		}

		m = this.findModule('settlementDocument')
		if (m) {
			links.readSettlementDocument = links.createSettlementDocument = m.link
			links.readOneSettlementDocument = { ...m.link }
			links.readOneSettlementDocument.href += '/{id}'
		}

		m = this.findModule('settlement')
		if (m) {
			links.readSettlement = links.createSettlement = m.link
			links.readOneSettlement = { ...m.link }
			links.readOneSettlement.href += '/{id}'
		}

		m = this.findModule('costDocument')
		if (m) {
			links.readCostDocument = links.createCostDocument  = m.link
			links.readOneCostDocument = { ...m.link }
			links.readOneCostDocument.href += '/{id}'
		}

		m = this.findModule('SettlementCompensation')
		if (m) {
			links.readSettlementCompensation = links.createSettlementCompensation = m.link
			links.readOneSettlementCompensation = { ...m.link }
			links.readOneSettlementCompensation.href += '/{id}'
		}

		m = this.findModule('translationEnginePref')
		if (m) {
			links.readTranslationEnginePref = links.createTranslationEnginePref = m.link
			links.readOneTranslationEnginePref = { ...m.link }
			links.readOneTranslationEnginePref.href += '/{id}'
		}

		m = this.findModule('translationSubengine')
		if (m) {
			links.readTranslationSubengine = links.createTranslationSubengine = m.link
			links.readOneTranslationSubengine = { ...m.link }
			links.readOneTranslationSubengine.href += '/{id}'
		}
		
		m = this.findModule('customerRetentionAlerts')
		if (m) {
			links.readCustomerRetentionAlerts = links.createCustomerRetentionAlerts = m.link
			links.readOneCustomerRetentionAlerts = { ...m.link }
			links.readOneCustomerRetentionAlerts.href += '/{id}'
		}

		m = this.findModule('role')
		if (m) {
			links.readRole = links.createRole = m.link
			links.readOneRole = { ...m.link }
			links.readOneRole.href += '/{id}'
		}

		m = this.findModule('organization')
		if (m) {
			links.readOrganization = links.createOrganization = m.link
			links.readOneOrganization = { ...m.link }
			links.readOneOrganization.href += '/{id}'
		}

		m = this.findModule('organizationConfiguration')
		if (m) {
			links.readConfiguration = links.createConfiguration = m.link
			// links.readOneConfiguration = { ...m.link }
			// links.readOneConfiguration.href += '/{id}'
		}

		m = this.findModule('systemConfigurationLivoCore')
		if (m)
			links.readSystemConfigurationLivoCore = m.link

		m = this.findModule('systemConfigurationIdentity')
		if (m)
			links.readSystemConfigurationIdentity = m.link

		m = this.findModule('systemConfigurationModule')
		if (m && m.modules.length > 0)
			links.readSystemConfigurationModule = {}

		m = this.findModule('agreementFile')
		if (m) {
			links.readAgreementFile = links.createAgreementFile = m.link
			links.readOneAgreementFile = { ...m.link }
			links.readOneAgreementFile.href += '/{id}'
		}

		m = this.findModule('serviceUsage')
		if (m) {
			links.readServiceUsage = links.createServiceUsage = m.link
			//links.readOneServiceUsage = { ...m.link }
			//links.readOneServiceUsage.href += '/{id}'
		}

		m = this.findModule('templateString')
		if (m) {
			links.readTemplateString = links.createTemplateString = m.link
			links.readOneTemplateString = { ...m.link }
			links.readOneTemplateString.href += '/{id}'
		}

		m = this.findModule('registryForDocuments')
		if (m) {
			links.readRegistryForDocuments = links.createRegistryForDocuments = m.link
			links.readOneRegistryForDocuments = { ...m.link }
			links.readOneRegistryForDocuments.href += '/{id}'
		}

		m = this.findModule('documentRegistryHistoryModule')
		if (m && m.modules.length > 0)
			links.readDocumentRegistryHistoryModule = {}


		m = this.findModule('documentRegistryHistoryProjectManagement')
		if (m) {
			links.readDocumentRegistryHistoryProjectManagement = links.createDocumentRegistryHistoryProjectManagement = m.link
			links.readOneDocumentRegistryHistoryProjectManagement = { ...m.link }
			links.readOneDocumentRegistryHistoryProjectManagement.href += '/{id}'
		}

		m = this.findModule('documentRegistryHistoryInvoice')
		if (m) {
			links.readDocumentRegistryHistoryInvoice = links.createDocumentRegistryHistoryInvoice = m.link
			links.readOneDocumentRegistryHistoryInvoice = { ...m.link }
			links.readOneDocumentRegistryHistoryInvoice.href += '/{id}'
		}

		m = this.findModule('documentRegistryHistorySettlements')
		if (m) {
			links.readDocumentRegistryHistorySettlements = links.createDocumentRegistryHistorySettlements = m.link
			links.readOneDocumentRegistryHistorySettlements = { ...m.link }
			links.readOneDocumentRegistryHistorySettlements.href += '/{id}'
		}

		m = this.findModule('documentRegistryHistoryTranslationService')
		if (m) {
			links.readDocumentRegistryHistoryTranslationService = links.createDocumentRegistryHistoryTranslationService = m.link
			links.readOneDocumentRegistryHistoryTranslationService = { ...m.link }
			links.readOneDocumentRegistryHistoryTranslationService.href += '/{id}'
		}

		m = this.findModule('MyTasksProposedTasks')
		if (m)
			links.readMyTasksProposedTasks = m.link

		m = this.findModule('myTasksCurrentTasks')
		if (m)
			links.readMyTasksCurrentTasks = m.link

		m = this.findModule('myTasksFinishedCurrentTasks')
		if (m)
			links.readMyTasksFinishedCurrentTasks = m.link

		m = this.findModule('myTasksInquiries')
		if (m)
			links.readMyTasksInquiries = m.link

		m = this.findModule('myTasksTasks')
		if (m)
			links.readMyTasksTasks = m.link

		m = this.findModule('myTasksUnsentOffers')
		if (m)
			links.readMyTasksUnsentOffers = m.link

		m = this.findModule('myTasksOffersAwaitingAcceptance')
		if (m)
			links.readMyTasksOffersAwaitingAcceptance= m.link

		m = this.findModule('myTasksModule')
		if (m && m.modules.length > 0)
			links.readMyTasksModule = {}

		m = this.findModule('CRMModule')
		if (m && m.modules.length > 0)
			links.readCRMModule = {}


		m = this.findModule('AllCRM')
		if (m)
			links.readAllCRM = m.link

		m = this.findModule('MyCRM')
		if (m)
			links.readMyCRM = m.link
		
		m = this.findModule('AppReportCRMContacts')
		if (m)
			links.readAppReportCRMContacts = m.link	

		m = this.findModule('AllCrmCustomerContacts')
		if (m)
			links.readAllCrmCustomerContacts = m.link	
			
		m = this.findModule('internationalization')
		if (m) {
			links.readInternationalization = {

				href: "/api/v1/system/languages",
				method: "GET",
				params: {},
				rel: 'read'
			}
		}

		m = this.findModule('organizationInternationalization')
		if (m) {
			links.readOrganizationInternationalization = {
				href: "/api/v1/system/languages",
				method: "GET",
				params: {},
				rel: 'read'
			}
		}

		m = this.findModule('specifications')
		if (m) {
			links.readSpecification = links.createSpecification = m.link
			links.readOneSpecification = { ...m.link }
			links.readOneSpecification.href += '/{id}'
		}

		m = this.findModule('configurationProjectManagement')
		if (m) {
			links.readConfigurationProjectManagement = m.link
			links.readOneConfigurationProjectManagement = { ...m.link }
			links.readOneConfigurationProjectManagement.href += '/{id}'
		}

		m = this.findModule('configurationInvoice')
		if (m)
			links.readConfigurationInvoice = m.link

		m = this.findModule('configurationSettlements')
		if (m)
			links.readConfigurationSettlements = m.link
			
		m = this.findModule('configurationCRM')
		if (m)
			links.readConfigurationCRM = m.link

		m = this.findModule('configurationTranslationService')
		if (m)
			links.readConfigurationTranslationService = m.link

		m = this.findModule('configurationNotifications')
		if (m)
			links.readConfigurationNotifications = m.link

		m = this.findModule('configurationModule')
		if (m && m.modules.length > 0)
			links.readConfigurationModule = {}

		m = this.findModule('version')
		if (m)
			links.readVersion = m.link;

		m = this.findModule('jaspersoftReports')
		if (m)
			links.readJaspersoftReports = m.link;

		m = this.findModule('teachingMaterials')
		if (m) {
			links.readTeachingMaterials = links.createTeachingMaterials = m.link
			links.readOneTeachingMaterials = { ...m.link }
			links.readOneTeachingMaterials.href += '/{id}'
		}

		this._links = links;
		return links;
	}

	/**
	 * Organizacje (z rozszerzonymi informacjami) do których przynależy tożsamość
	 */
	get organizationInfos() 
	{
		return this._value && this._value.OrganizationInfos ? this._value.OrganizationInfos : [];
	}

	/**
	*  Organizacja (z rozszerzonymi informacjami) w której kontekście pracuje użytkownik
	*/
	get currentOrganizationInfo() 
	{
		const currentOrganizationId = this._value?.CurrentOrganizationId;
		if (this.organizationInfos.length > 0)
			return this.organizationInfos.find(oi => oi.id === currentOrganizationId);

		return undefined
	}

	/**
	 * Czy token jest przedawniony
	 */
	get isAccessTokenExpired() { return this._value && this.accessTokenExpiration < new Date() }
	/**
	 * Czy refresh token jest przedawniony
	 */
	get isRefreshTokenExpired() { return this._value && this.refreshTokenExpiration < new Date() }

	/**
	 * Odczytanie tokenu ze storygu
	 */
	restore() {
		this._value = CryptoLocalStorage.get('login')

		if (!this._value || this.isRefreshTokenExpired)
			this._value = undefined

		this.waitForRefresh()
	}

	/**
	 * Czekanie na odswierzenie tokena
	 */
	waitForRefresh() {
		clearTimeout(this._timer)
		this._timer = undefined

		if (!this._value)
			return

		let time = this.accessTokenExpiration.getTime() - (new Date()).getTime() - 30000

		if (time < 0) {
			this.value = undefined
			return
		}

		let _this = this
		this._timer = setTimeout(() => {
			getFormRestService(`/auth/identities/${_this.identityId}/token`)
				.refreshAccessToken()
		}, time)
	}
}