import React, { useCallback } from 'react'
import useField from '../../hooks/useField'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { useTextAreaStyles } from './Textarea.styled'
import { InputLabel } from '../input/Input/Input'
import { Tooltip } from 'components/Tooltip'
import toString from 'lodash/toString'

/**
* Komponent kontrolki opleciony w funkcję memo() która pozwala na ogarniczenie ponownego renderowania kontrolki bez zmiany propsów.
* 
* @param {String} name Nazwa pola
* @param {Object} rootClassName - Style z biblioteki material-ui dla kontrolki
* @param {String} placeholder - Placeholder kontrolki
* @param {String} hint - hint kontrolki
* @param {Boolean} hasLabel Czy ma labelkę
* @param {String} label Labelka kontrolki
* @param {String} dataType dataType kontrolki
* @param {Boolean} required Czy jest wymagany
* @param {Boolean} disabled Czy jest aktywny
* @param {Integer} rows Ilość wierszy
* @param {String} value - aktualna wartość kontrolki
* @param {Function} onChange - Callback uruchamiany podczas wpisywania tekstu w polu kontrolki
* @returns {Node}
*/
const TextArea = React.memo(({ name, rootClassName, placeholder, hint, hasLabel, label, required, dataType, disabled, rows, value, onChange }) => {
	const TEXTAREA_INPUT_NAME = `Textarea-rows-${rows}`
	
	return (
		<Tooltip title={label + (hint ? '- ' + hint : '')}>
			<TextField
				id={`${toString(dataType)}-${name}-${TEXTAREA_INPUT_NAME}`}
				name={name}
				variant="outlined"
				size="small"
				fullWidth
				className={rootClassName}
				placeholder={placeholder}
				multiline={hasLabel} //Jeśli hasLabel jest false znaczy że przycisk znajduje się w edytowalnym gridzie, wtedy atrybut multiline jest wyłączony
				label={hasLabel && <InputLabel label={label} isRequired={required} />}
				disabled={disabled}
				rows={rows}
				value={value}
				onChange={e => onChange && onChange(e)}
				InputLabelProps={{ shrink: true }}
			/>
		</Tooltip>
	)
})

/**
 * Komponent powzalający izolować komponent <TextArea /> w celu ograniczenia ponownego renderowania komponentu, podczas gdy jego propsy nie uległy zmianie.
 * Komponent <TextArea /> zostanie rerenderowany jedynie w momencie gdy zmienią się któreś z propsów do niego przekazywanych
 *
 * @param {String} name Nazwa pola
 * @param {Integer} rows Ilość wierszy
 * @param {Boolean} disabled Czy jest aktywny
 * @param {Boolean} hasLabel Czy ma labelkę
 * @returns {Node}
 */
const TextAreaRenderProvider = ({  name, rows, disabled, hasLabel }) => {
	const field = useField(name)
	const { root } = useTextAreaStyles({ hasLabel })
	rows = rows ? rows : 5

	if (!hasLabel)
		rows = 1

	const onChange = useCallback((e) => {
		field.handleChange(e.target.value)
	}, [])
	
	return (
		<TextArea 
			name={name}
			rootClassName={root}
			placeholder={field.placeholder}
			hint={field.hint}
			hasLabel={hasLabel}
			label={field.label}
			required={field.required}
			dataType={field.dataType}
			disabled={disabled !== undefined ? disabled : field.disabled}
			rows={rows}
			value={field.value || ''}
			onChange={onChange}
		/>
	)
}

//#region Export komponentu
	export default TextAreaRenderProvider
//#endregion


//#region  PropTypes
TextAreaRenderProvider.propTypes = {
	name: PropTypes.string.isRequired,
	rows: PropTypes.number,
	disabled: PropTypes.bool,
	hasLabel: PropTypes.bool
}

TextArea.propTypes = {
	name: PropTypes.string.isRequired,
	rootClassName: PropTypes.object,
	placeholder: PropTypes.string,
	hint: PropTypes.string,
	hasLabel: PropTypes.bool,
	label: PropTypes.string,
	required: PropTypes.bool,
	dataType: PropTypes.string,
	disabled	: PropTypes.bool,
	rows: PropTypes.number,
	value: PropTypes.string,
	onChange: PropTypes.func,
}

TextAreaRenderProvider.defaultProps = {
	hasLabel: true
}

//#endregion