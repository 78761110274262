import React from 'react'
import DV from '../../../components/dataView/DataView'
import TopBar from 'components/topbar/TopBar'

export default function TopBarWrapper({  }) {
	const dataView = DV.useDataView()
	let title = ''

	if (dataView.root && dataView.root.next)
		title = dataView.root.next.props.title

	return (
		<TopBar headerText={title} />
	)
}
