import React from 'react'
import * as PositionObserver from 'components/positionObserver/PositionObserver'

export function GanttElements({ children }) {
	const ref = PositionObserver.useAddElement('root')

	return (
		<div ref={ref}>
			{children}
		</div>
	)
}