/**
 * Mapa uiInputTyp'ów dla których używamy domyślnego width==='100%'
 */
const uiInputTypeWith100prc = new Set(['HtmlEditor', 'DropZone', 'Label', 'MultipleChoiceWindow', 'MultipleChoiceList', 'TextArea'])

/**
 * Zwraca domyslną szerokość pola w zależności od field.type i field.uiInputType
 * @param {Field} field 
 * @returns {string} Domyślny width
 */
export function getDefaultWidth(field) {
	if (uiInputTypeWith100prc.has(field.uiInputType))
		return '100%'
	if (field.type === 'Number' || field.type === 'Int')
		return '8u'
	if (field.type === 'Timestamp' || field.type === 'Date')
		return '8u'
	return '16u'
}

/**
 * Zwraca domyslną wysokość pola w zależności od field.type i field.uiInputType
 * @param {Field} field 
 * @returns {string} Domyślny height
 */
export function getDefaultHeight(field) {
	return
}

/**
 * Szerokość jednej jednostki w px
 * @type number
 */
export const uWidth = 10
/**
 * Rozmiar przestrzeni wolnej pomiedzy polami
 * @type number
 */
export const flexGap = 15

/**
 * Rozmiar przestrzeni wolnej pomiedzy polami w poziomie
 * @type number
 */
export const rowGap = 30

/**
 * Rozmiar przestrzeni wolnej pomiedzy polami w pionie
 * @type number
 */
 export const colGap = 15

/**
 * @param {Field} field 
 * @returns {string} Szerokość pola na ekranie
 */
export function getWidth(field) {
	let width = field.width

	if (!width)
		width = getDefaultWidth(field)

	if (width === '100%')
		return width

	if (width.endsWith('u')) {
		let v = Number.parseInt(width.slice(0, -1))

		return (v * uWidth + (v - 1) * flexGap) + 'px'
	}

	return width
}

/**
 * Wysokość jednej jednostki w px
 * @type number
 */
export const uHeight = 20

/**
 * @param {Field} field 
 * @returns Wysokość pola na ekranie
 */
export function getHeight(field) {
	let height = field.height

	if (!height)
		height = getDefaultHeight(field)

	if (height === '100%' || !height)
		return height

	if (height.endsWith('u')) {
		let v = Number.parseInt(height.slice(0, -1))

		return (v * uHeight) + 'px'
	}

	return height
}

// function widthToObject(width, field) {
// 	if (width.endsWith('u'))
// 		return {
// 			width: Number.parseInt(width.slice(0, -1))
// 		}
// 	if (width === '100%')
// 		return {
// 			fullWidthRow: true
// 		}

// 	throw new Error(`Wrong field.width=${width} for field.name=${field.name}`)
// }

// function heightToObject(height, field) {
// 	if (height === undefined || height === null)
// 		return

// 	if (height.endsWith('u'))
// 		return { height: Number.parseInt(height.slice(0, -1)) }

// 	throw new Error(`Wrong field.height=${height} for field.name=${field.name}`)
// }

// /**
//  * Funkcja pomocnicza do ustalenia wymiarów pola
//  * @param {Field} field
//  * @returns {
//  * 	fullWidthRow:	//true jeśli ma zajmować cały wierszu
//  * 	width:			//Integer ilość kolumn które ma zajmować
//  * 	height: 			//String wartość wysokości w jednostkach css
//  * 	styleWraper: 	//Style do wrappera pola
//  * }
//  */
// export function getFieldDimentionsAndStyles(field) {
// 	let { width, height } = field

// 	if (!width)
// 		width = getDefaultWidth(field)
// 	if (!height)
// 		height = getDefaultHeight(field)

// 	const result = {
// 		...widthToObject(width, field),
// 		...heightToObject(height, field),
// 		styleWrapper: {},
// 	}

// 	if (result.width)
// 		result.styleWrapper = { gridColumnEnd: `span ${result.width}` }
// 	if (result.height)
// 		result.styleWrapper = { ...result.styleWrapper, gridRowEnd: `span ${result.height}` }

// 	return result
// }