import React from 'react'
import joinClassNames from 'components/joinClassNames'
import { GradientHidder } from 'components/topology/Topology'

export default function TabItem({ part, onClick, }) {
	const TAB_ITEM_NAME = "TabItem"
	
	return (
		<div
			className={joinClassNames('dataView-tabItem', part.isOpen ? 'dataView-isOpen' : undefined, part.isSelected ? 'dataView-isSelected' : undefined)}
			id={`${part.props?.dataType}-${TAB_ITEM_NAME}`}
			onClick={onClick}
		>
			<GradientHidder
				backgroundColor={part.isOpen ? '#f1f3f3' : 'white'}
				backgroundColorHover={part.isOpen ? '#f1f3f3' : 'white'}
				paddingX="md"
				paddingY="sm"
			>
				{part.props.tabTitle || part.props.title}
			</GradientHidder>
		</div>
	)
}
