import React from 'react'
import { Data } from './Data'
import { context } from './Context'
import { ZipListContent } from './ZipListContent'

export function ZipList({ values, additionalButtons, onSave, readOnly }) {
	return (
		<context.Provider
			fixedData
			values={values}
			createData={(props) => {
				return new Data(props)
			}}>
			<ZipListContent additionalButtons={additionalButtons} onSave={onSave} readOnly={readOnly} />
		</context.Provider>
	)
}