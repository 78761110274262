import React from 'react'
import Search from 'components/form/components/search/Search'
import Button from 'components/form/components/list/components/buttons/Button'
import {ButtonExportData} from 'components/form/components/list/components/buttons'
import Icon from 'components/icon/Icon'
import CreateIcon from '@material-ui/icons/Create'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { useForm } from 'components/form/Form'
import FilterModal from './filtration/FilterModal'
import { ColumnModal } from './columns/ColumnModal'
import { customElements } from 'cssStatic'
import { SwapVert, Visibility } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import { linkManager } from 'components/LinkManager'
import { makeStyles } from '@material-ui/core'

import { BsFunnel, BsFunnelFill } from "react-icons/bs";
import { GlobalStrings } from 'GlobalStrings';
import { Tooltip } from 'components/Tooltip'
import { useTranslate } from 'locale/Locale'

const useStyles = makeStyles(({ palette }) => ({
	button: {
		border: `1px solid ${palette.primary.mainDarken1000}`,
		borderRadius: 0,
	},
	iconStyles: {
		color: `${palette.primary.mainDarken1000}`,
		fontSize: 24
	}
}));


function GantViewMode() {
	const form = useForm('data')

	const classes = useStyles();

	if (!form.ganttManager.isGantt)
		return null

	const handleButtonClick = () => {
		form.ganttManager.isReactangleView = !form.ganttManager.isReactangleView
		form.trigger('data')
	}

	return (
		<Button
			type="icon"
			className = {classes.iconStyles}
			icon={<Visibility/>}
			onClick={() => handleButtonClick()}
			style={{ borderRadius: 0, borderLeft: 0 }}
		/>
	)
}

function getColorForBoolState(boolValue)
{
	return boolValue ? '#1a314b': 'rgba(0, 0, 0, 0.4)';
}

function EditableRowMode({ dataType, part }) { //TODO Przenieść kontrolkę w inne miejsce
	const form = useForm('data')
	const history = useHistory()
	const BUTTON_NAME = "ButtonInlineEdition"

	const classes = useStyles();
	const localizedStrings = useTranslate('WebSpa/Grid');

	if (form.ganttManager.isReactangleView)//Przycisk niewidoczny dla widoku pudełkowego
		return null

	const handleButtonClick = () => {
		form.rowManager.inlineEdition = !form.rowManager.inlineEdition
		form.rowManager.editRowId = null
		form.rowManager.selectedRowId = null
		form.rowManager.clearMarks()
		if (form.rowManager.inlineEdition)
			linkManager.handleListNewPage(history, part)
		form.trigger('data')
	}

	return (
		<Tooltip title={localizedStrings('Button/EditRowInline/label')}>
			<Button
				type="icon"
				id={`${dataType}-${BUTTON_NAME}`}
				onClick={() => handleButtonClick()}
				style={{ borderLeft: 0 }}
				className = {classes.button}
				icon={<CreateIcon style={{ color: getColorForBoolState( form.rowManager.inlineEdition)}} />}
			/>
		</Tooltip>
	)
}

const ListModeStyle = customElements('o-list-mode', ['grid-hor', 'grid-aix-stretch', 'grid-aiy-center'])

function getExportButtonFromMeta(form){
	if(form._data?.meta?.buttons)
	{
		return form._data?.meta?.buttons["ButtonExport"];
	}

	return null;
}

const COLUMN_BUTTON_NAME = "ButtonOpenColumnModal"
const FILTER_BUTTON_NAME = "ButtonOpenFilterModal"
const OPEN_IN_NEW_TAB_BUTTON_NAME = "ButtonOpenInNewTab"
const SUMMARY_BUTTON_NAME = "ButtonSummary"

export function ListMode({ part, onLaunch, additionalButtons }) {
	const form = useForm('data')
	const history = useHistory()
	const dataType = form._dataType ? form._dataType : ""
	const [openFilter, setOpenFilter] = React.useState(false)
	const [openColumn, setOpenColumn] = React.useState(false)
	const localizedStrings = useTranslate('WebSpa/Grid');
	
	const classes = useStyles();
	const { filterManager } = form
	const isExportButtonAvailable = getExportButtonFromMeta(form) != null;

	let existsFilter = false; 
	if ( window.name.startsWith(GlobalStrings.CrmOffersWindowName)) {
		existsFilter = filterManager.filterExpression?.split(',').length > 1;
	} else {
		existsFilter = filterManager.filterExpression !== null && filterManager.filterExpression !== '';
	}
	
	return (
		<ListModeStyle>
			<Tooltip title={localizedStrings('Button/Filters/label')}>
				<Button
					type='icon'
					id={`${dataType}-${FILTER_BUTTON_NAME}`}
					onClick={() => setOpenFilter(true)}
					className = {classes.button}
					style={{ borderRight: 0 }}
					icon={ existsFilter ? <BsFunnelFill className = { classes.iconStyles } style={{ 'color': `var(--o-yellow)`}} />
					: <BsFunnel className = { classes.iconStyles }/>
					}
				/>
			</Tooltip>
			
			<Tooltip title={localizedStrings('Button/OpenColumnModal/label')}>
				<Button
					type='icon'
					id={`${dataType}-${COLUMN_BUTTON_NAME}`}
					onClick={() => setOpenColumn(true)}
					style={{ borderRadius: 0 }}
					className = {classes.button}
					icon={<SwapVert style={{ fontSize: 28, color: '#1a314b' }} className = { classes.iconStyles } />}
				/>
			</Tooltip>
			<Tooltip title={localizedStrings('Button/OpenInNewTab/label')}>
				<Button
					type='icon'
					id={`${dataType}-${OPEN_IN_NEW_TAB_BUTTON_NAME}`}
					onClick={() => {
						if (onLaunch) {
							onLaunch(form)
							return
						}
						const path = linkManager.getPathFromPart(part)
						const w = window.open(path + '?maximize=all', '_blank', '') // Dla osób, które w ten sposób składają urle jest specjalne miejsce w piekle
					}}
					className = {classes.button}
					style={{ borderLeft: 0 }}
					icon={<Icon name="launch" style={{ color: '#1a314b' }} />}
				/>
			</Tooltip>
			
			<EditableRowMode dataType={dataType} part={part} />
			<Tooltip title={localizedStrings('Button/Summary/label')}>
				<Button
					type='icon'
					id={`${dataType}-${SUMMARY_BUTTON_NAME}`}
					onClick={() => {
						form.includeSummary = !form.includeSummary;
						form.asyncLoad();
					}}
					className = {classes.button}
					style={{ borderLeft: 0 }}
					icon={<AssignmentOutlinedIcon style={{ color: getColorForBoolState( form.includeSummary)}} />}
				/>
			</Tooltip>
			{isExportButtonAvailable &&
				<ButtonExportData isList={true} className={classes.button} style={{ borderLeft: 0 }} />
			}
			
			<GantViewMode />
			<Search />
			<div className='grid-ver m-left-5 grid-acy-center'>
				{additionalButtons && additionalButtons()}
			</div>
			<FilterModal isOpen={openFilter} close={() => setOpenFilter(false)} />
			<ColumnModal isOpen={openColumn} close={() => setOpenColumn(false)} />
		</ListModeStyle>
	)
}