import React from 'react'

export function TreeRow({ tree, children }) {
	if (!tree.row)
		return null

	const child = React.Children.only(children)

	return (
		React.cloneElement(child, {
			key:tree.row.id,
			row: tree.row,
			rowIndex: tree.rowIndex,
			tree
		})
	)
}