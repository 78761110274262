import React from 'react'
import styled, { css } from "styled-components"
import { GetFromTheme } from "./GetFromTheme"

/**
 * Mixin zwracający css gradientu chowającego z prawej strony dzieci
 *
 * @param {string} paddingX padding z prawej i lewej strony. Default 'md'
 * @param {string} paddingY padding z gory i z dołu. Default 'md'
 * @param {string} backgroundColor kolor tła. Default 'default'
 * @param {Boolean} isCentered używany w listach, jeśli występuje dodatkowa własność - align-self: center
 * @param {Node} children dzieci
 * @returns {Array}
 */
export function MixinGradientHidder({ paddingX, paddingY, backgroundColor, backgroundColorHover, isCentered }) {
	const mix = css`
		overflow: hidden;
		position: relative;
		white-space: nowrap;
		${isCentered && `align-self: center`};
		background:${props => GetFromTheme(props, 'background', backgroundColor, 'default')};
		padding:${props => {
			const px = GetFromTheme(props, 'padding', paddingX, 'md')
			const py = GetFromTheme(props, 'padding', paddingY, 'md')
			return py + ' ' + px + ' ' + py + ' ' + px
		}};

		&:hover{
			background:${props => GetFromTheme(props, 'background', backgroundColorHover, 'default')};
		}

		&> div:last-child {
			position: absolute;
			right: 0px;
			top: 0px;
			bottom: 0px;
			width: ${props => GetFromTheme(props, 'padding', paddingX, 'md')};
			background: ${props => {
			const bc = GetFromTheme(props, 'background', backgroundColor, 'default')
			return `linear-gradient(90deg, transparent,${bc},${bc})`
		}};
		}

		&:hover > div:last-child {
			background: ${props => {
			const bc = GetFromTheme(props, 'background', backgroundColorHover, 'default')
			return `linear-gradient(90deg, transparent,${bc},${bc})`
		}};
		}
	`
	return mix
}

function GradientHidder_({ className, children }) {
	return (
		<div className={className}>
			{children}
			<div />
		</div>
	)
}

/**
 * Komponent który może w poziomie mieć długość min 0px i pokazujący gradient z prawej strony ukrywający zawartość
 *
 * @param {string} paddingX padding z prawej i lewej strony. Default 'md'
 * @param {string} paddingY padding z gory i z dołu. Default 'md'
 * @param {string} backgroundColor kolor tła. Default 'default'
 * @param {string} backgroundColorHover kolor tła gdy jest hover. Default 'default'
 * @param {Node} children dzieci
 * @returns {Node}
 */
export const GradientHidder = styled(GradientHidder_)`
	${props => MixinGradientHidder(props)}
`