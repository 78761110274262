export default class DataViewClass {
	constructor() {
		this.root = undefined
		this.selected = undefined
	}
	
	_getTab(root) {
		let res = []

		if (!root || !root.parts)
			return res

		res.push(...root.parts.filter(p => {
			return (!p.props.hidden && p.reachable !== false) || p.parts
		}))
		return res
	}

	get tabs() {
		let res = []

		for (let root = this.root;root;) {
			let tab = this._getTab(root)

			root = root.next

			if (root && root.props.connected) {
				let tabNext = this._getTab(root)
				tab.push(...tabNext)
				root = root.next
			}

			if (tab.length > 0)
				res.push(tab)
		}
		return res
	}

	toString()
	{
		return `DataViewClass - selected: ${this.selected}`
	}
}