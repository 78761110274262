import React from 'react'
import { ObservableContextConsumer } from '../../../observableContext/ObservableContaxt'

/**
 * Konsumer formy
 *
 * @param {Node} children Zawartość
 * @returns {Node}
 */
export default function FormConsumer({eventName, children }) {
	return (
		<ObservableContextConsumer eventName={eventName}>
			{children}
		</ObservableContextConsumer>
	)
}