import { DocumentCommentChangedArgs, IEditorDocumentEvents, IEditorTranslationUnitCommands, nextGlobalHandlerId } from "../model/EditorMediator";

export default class TranslationUnitController implements IEditorTranslationUnitCommands, IEditorDocumentEvents
{
    selectTranslationUnit: (languageDocumentId: string, sequenceNumber: number) => void;
    onSelectedTranslationUnit: (languageDocumentId: string, sequenceNumber: number) => void;
    onDocumentCommentChanged: (languageDocumentId: string, args: DocumentCommentChangedArgs) => void;
    onTuConfirmationLevelChanged: (languageDocumentId: string, sequenceNumber: number, confirmationLevel: number) => void;
    
    private handlerId: number;
    private debugId: string;

    constructor()
	{
		this.handlerId = nextGlobalHandlerId();
        this.debugId = "[TUC_" + this.handlerId + "] ";
	}
    
    getHandlerId()
    {
        return this.handlerId;
    }

	getHandlerDebugId() 
    {
		return this.debugId;
	}
}