import { FormBase } from './FormBase'
import FormMetaArrayClass from '../../classes/FormMetaArray'

/**
 * Komponent FormMetaArray
 *
 * @param {function} createFormClass Funkcja zwracajaca klasę kontekstu
 * @param {*} data Dane początkowe formy
 * @param {Node} children Zawartość
 * @param {FormMetaArray} formMetaControllerClassFactory Jest to metoda wytwórcza, która przyjmuje jeden parametr z konfiguracją
 * 				obiektu FormMetaArray i zwraca instancje obiektu FormMetaArray lub obiektu, który dziedziczy po tej klasie. Pozwala
 * 				na customizacje zachowania komponentu
 * @param {*} onLoadParam Parametry potrzebne do stwierdzenia czy trzeba redrawować komponent 
 * @returns {Node}
 */
export default function FormMetaArray({ data, children, onChange, onLoadParam, redirectToLogin, errorModal, formMetaControllerClassFactory, ...other }) {
	return FormBase({
		data, children, onChange, onLoadParam, redirectToLogin, errorModal,
		createFormClass: () => formMetaControllerClassFactory ? formMetaControllerClassFactory(other) : new FormMetaArrayClass(other)
	})
}