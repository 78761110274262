import React from 'react'
import Input from './Input/Input'
import PropTypes from 'prop-types'
import { trim } from 'lodash'

/**
 * Komponent input int
 *
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @returns {Node}
 */
export default function InputInt({ name, hasLabel }) {

	const onValueParse = value => {
		if(!trim(value).length) //Sytuacja gdy kontrolka jest "wyczyszczona" z tekstu
			return null

		const parsedNumber = parseInt(value)

		return parsedNumber
	}

	return (
		<Input
			type='number'
			name={name}
			hasLabel={hasLabel}
			InputProps={{ inputProps: { step: 1 } }} //Możliwe nadpisanie wartością z BE jeśli taka się pojawi
			onValueParse={value => onValueParse(value)}
		/>
	)
}

InputInt.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool
}

InputInt.defaultProps = {
	hasLabel: true
}
