import React from 'react';
import { Tooltip as MaterialTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styleValue = {
	tooltip: {
		fontSize: '12px'
	},
	popper: {
		marginTop: '-10px',
	}
}

const styleNoWrappingValue = {
    ...styleValue,
    tooltip: {
        ...styleValue.tooltip,
        maxWidth: 'none'
    }
}


const StyledTooltip = withStyles(() => (styleValue))(MaterialTooltip);
const StyledTooltipNoWrapping = withStyles(() => (styleNoWrappingValue))(MaterialTooltip);

const Tooltip = ({children, title, ...other }) => {
    if(title)
    {
        return (
            <StyledTooltip title={title} {...other } >
                {children}
            </StyledTooltip>
        );
    }

    return children;
};

export const TooltipNoWrapping = ({children, title, ...other }) => {
    if(title)
    {
        return (
            <StyledTooltipNoWrapping title={title} {...other } >
                {children}
            </StyledTooltipNoWrapping>
        );
    }

    return children;
};

export default Tooltip;