import React from 'react'
import PropTypes from 'prop-types'
import { EnumListClass } from './EnumListClass'
import RefreshableContext from 'components/refreshableContext/RefreshableContext'
import Button from 'components/form/components/list/components/buttons/Button'
import Label from '../label/Label'
import { Divider } from '@material-ui/core'
import { ListItemStyled, ListStyled, ButtonsStyled, EnumListStyled } from './EnumList.styled'
import { useField } from 'components/form/Form'
import { toString } from 'lodash'
import { useTranslate } from 'locale/Locale'

const enumContext = RefreshableContext.create()

function ListItem({ value, disabledValues }) {
	const enumList = enumContext.useContext()

	return (
		<ListItemStyled
			disabled={disabledValues != null && disabledValues.find((element) => element.value == value.value ) !== undefined && value.selected === false}
			onClick={e => enumList.mark(value, e.ctrlKey, e.shiftKey)}
			onDoubleClick={() => { if(disabledValues == null || disabledValues.find((element) => element.value == value.value) == undefined || value.selected === true) {enumList.moveValue(value)}}}
			marked={value.marked}
			value={value.value}
			size={10}
		>
			{value.value.replace('\t', '─\t')}
		</ListItemStyled>
	)
}

function List({ isSelectedList, name, id, labelId, disabledValues }) {
	const enumList = enumContext.useContext()
	const list = enumList.enumListValues.filter(value => value.selected === isSelectedList)
	
	return (
		<ListStyled id={id}>
			<Label id={labelId} name={name} style={{ fontSize: 16, padding: '3px 0' }} />
			<Divider style={{ marginBottom: 15 }} />
			{list.map(value => <ListItem key={value.id} value={value} disabledValues={disabledValues} />)}
		</ListStyled>
	)
}

function Buttons( {disabledValues, disabled} ) {
	const enumList = enumContext.useContext()
	const { IDs } = enumList
	const buttonsProps = { 
		type: 'cancel', 
		style: {fontSize: 18} 
	}

	return (
		<ButtonsStyled id={IDs.ALL_BUTTONS_WRAPPER}>
			<Button id={IDs.BUTTON_MOVE_ALL_TO_DESTINATION} {...buttonsProps} onClick={() => enumList.moveAll(false, disabledValues)} content={'<<'}  disabled={disabled} />
			<Button id={IDs.BUTTON_MOVE_MARKED_TO_DESTINATION} {...buttonsProps} onClick={() => enumList.moveMarked(false, disabledValues)} content={'<'} disabled={disabled} />
			<Button id={IDs.BUTTON_MOVE_ALL_TO_SOURCE} {...buttonsProps} onClick={() => enumList.moveMarked(true)} content={'>'} disabled={disabled} />
			<Button id={IDs.BUTTON_MOVE_MARKED_TO_SOURCE} {...buttonsProps} onClick={() => enumList.moveAll(true)} content={'>>'} disabled={disabled} />
		</ButtonsStyled>
	)
}

/**
 *
 * @param {Object} values - [{id:,value:},...]
 * @param {Set} selected - [{id:,value:},...]
 * @param {function} onChange
 * @returns
 */
export default function EnumListView({ name, values, selected, onChange, disabledValues, sourceBoxLabel, destinationBoxLabel }) {
	const field = useField(name)
	const fieldDisabled = field.disabled;
	const translate = useTranslate('WebSpa/Inputs/EnumList/Labels')
	const { error, dataType } = field ?? {}
	const IDs = Object.freeze({
		ENUM_LIST_NAME: `${toString(dataType)}-EnumList`,
		LIST_SOURCE: `${toString(dataType)}-SourceList`,
		LIST_DESTINATION: `${toString(dataType)}-DestinationList`,
		LABEL_SOURCE: `${toString(dataType)}-SourceListLabel`,
		LABEL_DESTINATION: `${toString(dataType)}-DestinationListLabel`,
		BUTTON_MOVE_ALL_TO_DESTINATION: `${toString(dataType)}-ButtonMoveAllToDestinationList`,
		BUTTON_MOVE_MARKED_TO_DESTINATION: `${toString(dataType)}-ButtonMoveMarkedToDestinationList`,
		BUTTON_MOVE_ALL_TO_SOURCE: `${toString(dataType)}-ButtonMoveAllToSourceList`,
		BUTTON_MOVE_MARKED_TO_SOURCE: `${toString(dataType)}-ButtonMoveMarkedToSourceList`,
		ALL_BUTTONS_WRAPPER: `${toString(dataType)}-AllButtonsWrapper`
	})

	return (
		<enumContext.Provider
			fixedData
			values={values}
			selected={selected}
			onChange={onChange}
			disabledValues={disabledValues}
			error={error}
			IDs={IDs}
			createData={() => {
				return new EnumListClass()
			}}>
			<EnumListStyled id={IDs.ENUM_LIST_NAME}>
				<List id={IDs.LIST_DESTINATION} labelId={IDs.LABEL_DESTINATION} isSelectedList={true} name={destinationBoxLabel != null ? destinationBoxLabel : translate('destination')} disabledValues={fieldDisabled ? values : disabledValues} />
				<Buttons disabledValues={disabledValues} disabled={fieldDisabled} />
				<List id={IDs.LIST_SOURCE} labelId={IDs.LABEL_SOURCE} isSelectedList={false} name={sourceBoxLabel != null ? sourceBoxLabel : translate('source')} disabledValues={fieldDisabled ? values : disabledValues} />
			</EnumListStyled>
		</enumContext.Provider>
	)
}


ListItem.propTypes = {
	value: PropTypes.object,
	disabledValues: PropTypes.array
}

List.propTypes = {
	isSelectedList: PropTypes.bool,
	name: PropTypes.string,
	id: PropTypes.string,
	labelId: PropTypes.string,
	disabledValues: PropTypes.array
}

EnumListView.propTypes = {
	name: PropTypes.string,
	values: PropTypes.array,
	selected: PropTypes.array,
	onChange: PropTypes.func,
	disabledValues: PropTypes.array
}

