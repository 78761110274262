import { ContextId, IEntityContextStateController, IEntityContextStateReadOnlyController } from './DataContextModel';

/** Reprezentuje stan, w którym może znaleźć się request. */
export enum RequestState {
    /** W trakcie przetwarzania */
    Pending = 1,
    /** Zakończony sukcesem */
    Successed = 2,
    /** Zakończony niepowodzeniem */
    Failed = 3
}

/** Sposób w jaki kontekst danych ma wykonać dany request. */
export enum RequestExecutionStrategy {
  /** Strategia ładowania danych. */
  Load = 1,
  /** Strategia aktualizacji danych. */
  Update = 2,
  /** Strategia tworzenia danych */
  Create = 3,
  /** Strategia usuwania danych */
  Delete = 4
}

/** Informacje dotyczącego requestu rejestrowane przez context. */
export type RequestInfo = {
    key: string;
    state: RequestState;
    startedAt: Date;
    completedAt?: Date;
};

/** Opcje requestu. Niejako opcje definiują w jaki sposób ma zostać wykonany request. 
 * Mówimy tutaj o czymś abstrakcyjnym. Request nie musi byc requestem HTTP, który uderza
 * do jakiegoś API w celu pobrania lub aktualizacji danych.
 */
export interface BaseRequestOptions {
    /** 
     * Klucz requestu. Można powiedzieć, że jego identyfikator lub identyfikator zbioru danych 
     * zwracanych przez request. */
    key: string;
    /** 
     * Implementacja {IRequestDataMappper}. Odpowiada za "przetłumaczenie" payloadu requestu na postać 
     * zrozumiałą przez kontekst danych. */
    dataMapping: IRequestDataMappper;
    /** Sposób w jaki kontekst danych ma wykonać dany request. */
    executionStrategy: RequestExecutionStrategy;
};

export interface LoadRequestOptions extends BaseRequestOptions {
  /** 
     * Metoda odpowiedzialna za wykonanie requestu. Nie powinna rzucać wyjątkiem. 
     * Błąd powinien zostać obsłużony po stronie metody i zwrócony na wyjściu.
     * @param {AbortController} abortController - https://developer.mozilla.org/en-US/docs/Web/API/AbortController
     */
   fnAsync: (abortController: AbortController) => Promise<RequestResults>;
}

export interface SaveRequestOptions extends BaseRequestOptions {
  /** 
     * Metoda odpowiedzialna za wykonanie requestu, który wykonuje operacje zapisu zasobu, tj.
     * jego aktualizację, zamianę, lub tworzenie nowego.
     * @param {any} payload - Payload requestu
     */
   fnAsync: (payload: any) => Promise<RequestResults>;
}

export interface DeleteRequestOptions extends BaseRequestOptions {
    /** 
     * Metoda odpowiedzialna za wykonanie requestu, który wykonuje operacje zapisu zasobu, tj.
     * jego aktualizację, zamianę, lub tworzenie nowego.
     * @param {any} payload - Payload requestu
     */
    fnAsync: (payload: any) => Promise<RequestResults>;
}

export type RequestOptions = LoadRequestOptions | SaveRequestOptions | DeleteRequestOptions;

export class RequestOptionsCreator {
  static load(fnAsync: (abortController: AbortController) => Promise<RequestResults>, key: string, dataMapping: IRequestDataMappper): LoadRequestOptions {
    return {
      fnAsync: fnAsync,
      key: key,
      dataMapping: dataMapping,
      executionStrategy: RequestExecutionStrategy.Load
    }
  }
  static update(fnAsync: (payload: any) => Promise<RequestResults>, key: string, dataMapping: IRequestDataMappper): SaveRequestOptions {
    return {
      fnAsync: fnAsync,
      key: key,
      dataMapping: dataMapping,
      executionStrategy: RequestExecutionStrategy.Update
    }
  }
  static create(fnAsync: (payload: any) => Promise<RequestResults>, key: string, dataMapping: IRequestDataMappper): SaveRequestOptions {
    return {
      fnAsync: fnAsync,
      key: key,
      dataMapping: dataMapping,
      executionStrategy: RequestExecutionStrategy.Create
    }
  }
  static delete(fnAsync: (payload: any) => Promise<RequestResults>, key: string, dataMapping: IRequestDataMappper): DeleteRequestOptions {
    return {
      fnAsync: fnAsync,
      key: key,
      dataMapping: dataMapping,
      executionStrategy: RequestExecutionStrategy.Delete
    }
  }
}

/** Rezultat requestu. */
export type RequestResults = {
    /** Payload (czyli zwrócone dane z API) request o ile takowy istnieje. */
    resource: any;
    /** Czy request zakończył się powodzeniem? */
    ok: boolean;
    /** Status requestu. Dowolna wartość. W zależności od tego z jakiego typu requestem mamy do czynienia. */
    status: any;
    /** Metoda wywołania requestu. */
    method: string;
    /** Prosty i przyjazny dla użytkownika komunikat o błędzie */
    errorMessage: string
};

/**
 * Definiuje sposób "tłumaczenia" formatu danych dostarczonego przez requestu na format danych
 * zrozumiały przez kontekst danych. Innymi słowy definiuje strategie, która konwertuje obiekty o pewnej strukturze
 * na obiekty w DataContext.
 */
export interface IRequestDataMappper 
{
    /** 
     * Metoda wołana po zakończeniu requestu. W tej metodzie możemy skonsumować request i przeprocesować dane 
     * np. w celu późniejszego ich wykorzystania. 
     * */
    onFinishedRequest(
      contextId: ContextId,
      options: RequestOptions,
      requestResult: RequestResults
    );
    /** 
     * Merguj dane request z indeksem obiektów w kontekście. W tym miejscu dodajemy dane do indeksu jeśli pewne dane 
     * zostały pobrane lub aktualizujemy indeks jeśli mieliśmy do czynienia z operacją aktualizującą dane. */
    mergeWithEntityIndex(
      entityContextController: IEntityContextStateController,
      requestOptions: RequestOptions,
      requestResult: RequestResults
    );

    /** Przygotuj payload na potrzeby requestu. */
    createRequestPayload(
      entityContextController: IEntityContextStateReadOnlyController,
      requestOptions: RequestOptions
    ): any;
}