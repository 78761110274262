/**
 * Moduł komunikacji z serwerem Http poprzez protokół metadanych
 * @module services/FormRestService
 */
import RestService from './RestService'
import { appCtrl } from 'AppCtrl'
import { isEmpty } from 'lodash'

const defaultOptions = {
	refreshAppState: true 
}

/**
 * Klasa komunikacyjna z serwerem Http poprzez protokół metadanych
 * @class FormRestService
 */
export default class FormRestService extends RestService {

	/**
	 * @method FormRestService
	 * @param {string} serviceHostUrl
	 */

	/**
	 * Pobranie pojedynczego rekordu z danymi
	 *
	 * @param {*} form
	 * @param {*} id
	 * @returns {*}
	 * @method getOne
	 */
	async getOne(form, id) {
		try {
			let url
			if (!id)
				url = ''
			else
				url = `/${id}`
			
			let res = await super.get(url, {}, {})
			form.clearChanges()
			//form.addPolymorphicSection(res)
			form.data = res
			form.errorFromService = undefined
			// [Performance Fix] KCH: Wygląda na to, że odświezenie kontekstu AppCtrl powoduje ponowne wyrenderowanie całej aplikacji.
			// Miejmy nadzieje, że tymczasowo bazujemy na metodzie `refreshAppState()`, która powinna wykonać odświeżenie stanu
			// właściwie całej aplikacji. To stwierdzenie nie zawsze musi być prawdą ale w newraglicznych miejscach w aplikacji
			// bazujemy na tym zachowaniu.
			//form.trigger('data')
			appCtrl.addMessages(res.messages, false)
			appCtrl.refreshAppState()

			// parent form nie zawsze występuje - nie ma go w przypadku okna userProcessActions
			if(form.parentForm?.setObjectInfoMessages) {
				form.parentForm.setObjectInfoMessages(res.messages);
			}

			return true
		}
		catch (err) {
			form.setEmptyData()
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	/**
	 * Pobranie wielu rekordów z danymi
	 *
	 * @param {*} formContext
	 * @param {*} paginator
	 * @param {*} filter
	 * @param {Object} options Czy odświeżać
	 * @returns {*}
	 * @method get
	 */
	async get(form, options={}) {
		try {
			options = {...defaultOptions, ...options}

			let parameters = this.createQueryParametersForList(form);
			let res = await super.get('', parameters, {})
			//form.addPolymorphicSection(res)
			form.data = res
			
			if(form.updateChildCreateFormData)
				form.updateChildCreateFormData()

			form.errorFromService = undefined
			form.pages = form.data.pages
			form.currentPage = form.data.currentPage
			// [Performance Fix] KCH: Wygląda na to, że odświezenie kontekstu AppCtrl powoduje ponowne wyrenderowanie całej aplikacji.
			// Miejmy nadzieje, że tymczasowo bazujemy na metodzie `refreshAppState()`, która powinna wykonać odświeżenie stanu
			// właściwie całej aplikacji. To stwierdzenie nie zawsze musi być prawdą ale w newraglicznych miejscach w aplikacji
			// bazujemy na tym zachowaniu.
			//form.trigger('data')
			appCtrl.addMessages(res.messages, false)

			if(options.refreshAppState) {
				appCtrl.refreshAppState()
			}

			if(form.setObjectInfoMessages) {
				form.setObjectInfoMessages(res.messages);
			}

			return true
		}
		catch (err) {
			form.setEmptyData()
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	/**
	 * Pobieranie danych do eksportu na podstawie podanego formularza. W ramach tej metody nie pobieramy metadanych
	 * ani linków przez co pobieranie danych z backendu trwa o wiele szybciej. Zakładamy stały limit na 10k elementów 
	 * do pobrania.
	 * @param {*} form 
	 * @returns 
	 */
	async getDataForExport(form)
	{
		try {
			let parameters = this.createQueryParametersForList(form);
			parameters.includeMeta = false;
			parameters.includeLinks = false;
			parameters.limit = 10000;
			parameters.page = 1;
			let res = await super.get('', parameters, {})

			form.errorFromService = undefined
			return res;
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return null;
		}
	}

	/**
	 * Stwórz parametry Query String dla operacji pobierania listy obiektów na podstawie
	 * obiektu formularza.
	 *
	 * @param {*} form - formularz na podstawie którego generujemy parametry Query String
	 * @returns {*}
	 * @method get
	 */
	createQueryParametersForList(form)
	{
		let parameters = {}

		if (form.columnManager && form.columnManager.sortValue)
			parameters.sort = form.columnManager.sortValue
		if (form.currentPage > 1)
			parameters.page = form.currentPage
		if (form.limit)
			parameters.limit = form.limit
		if (form.filter)
			parameters.filter = form.filter
		if (form.aggregateOption)
			parameters.aggregateOption = form.aggregateOption
		if(form.includeSummary)
			parameters.includeSummary = form.includeSummary;

		if (form.filterManager && form.filterManager.filterExpression) {
			parameters.search = form.filterManager.filterExpression
		}

		return parameters;
	}


	/**
	 * Pobranie wyłącznie metadanych
	 *
	 * @param {*} formContext
	 * @returns {*}
	 * @method getMeta
	 */
	async getMeta(form) {
		try {
			let res = await super.get('', { includeData: false, includeMeta: true }, {})
			res.data = [{
				type: res.meta.dataType,
				attributes: {},
				meta: {},
				links: {}
			}]
			form.data = res
			form.errorFromService = undefined
			form.trigger('data')
			return true
		}
		catch (err) {
			form.errorFromService = err
			form.setEmptyData()
			form.trigger('data')
			return false
		}
	}

	/**
	 * Pobranie metadanych objektu po zmianie wartości pola, które ma ustawioną flagę 'ReloadOnChange' na true
	 * @param {*} formContext
	 * @param {*} triggeredFieldName
	 * @returns {*}
	 * @method getReloadOnChangeMeta
	 */
	async getReloadOnChangeMeta(form, triggeredFieldName) {

		const payload = {
			meta: {
				dataType: form.dataType
			},
			data: [{
				type: form.data.data[0].type,
				id: form.data.data[0].id,
				attributes: form.currentValuesToSend
			}]
		};

		const parameters = {
			includeData: true,
			includeMeta: true,
			reloadOnChangeTriggerAttribute : triggeredFieldName
		};

		form.setMetaDataBeforeReloadOnChange(form.data.meta,form.data.data[0].meta);

		try {
			const res = await super.post('/clientstate', parameters, payload)
			form.setReloadOnChangeMeta(res)
			form.errorFromService = undefined
			form.trigger('data')

			// parent form nie zawsze występuje - nie ma go w przypadku okna userProcessActions
			if (form.parentForm?.setObjectInfoMessages) {
				form.parentForm.setObjectInfoMessages(res.messages);
			}

			return true
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	/**
	 * Nadpisanie rekordu 
	 *
	 * @param {*} formContext
	 * @param {*} id
	 * @param {*} addCurrentHref
	 * @returns {*}
	 * @method patch
	 */
	async patch(form, id, addCurrentHref) {
		const payload = {
			data: [
				{
					id: form.id,
					type: form.dataType,
					attributes: form.forceAllAttributesUpdate ? form.currentValuesToSend : form.changes,
				}
			],
			links: addCurrentHref
				? { currentUserUrl: { "href": window.location.href, "rel": 'currentUserUrl', "method": 'GET' } }
				: {},
		}

		try {
			let url = '/' + form.id

			if (id !== undefined) {
				if (id === '')
					url = ''
				else
					url = '/' + id
			}

			let res = await super.patch(url, {}, payload)

			form.changesToValues()
			form.mergeData(res)
			form.errorFromService = undefined
			form.isChanged = false
			// [Performance Fix] KCH: Wygląda na to, że odświezenie kontekstu AppCtrl powoduje ponowne wyrenderowanie całej aplikacji.
			// Miejmy nadzieje, że tymczasowo bazujemy na metodzie `refreshAppState()`, która powinna wykonać odświeżenie stanu
			// właściwie całej aplikacji. To stwierdzenie nie zawsze musi być prawdą ale w newraglicznych miejscach w aplikacji
			// bazujemy na tym zachowaniu.
			//form.trigger('data')
			appCtrl.addMessages(res.messages, false)
			appCtrl.refreshAppState()

			// parent form nie zawsze występuje - nie ma go w przypadku okna userProcessActions
			if (form.parentForm?.setObjectInfoMessages) {
				form.parentForm.setObjectInfoMessages(res.messages);
			}

			return true
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	async patchMulti(forms, id)
	{
		const payload = {
			data: []
		}

		for(const form of forms)
		{
			payload.data.push({
				id: form.id,
				type: form.dataType,
				attributes: form.forceAllAttributesUpdate ? form.currentValuesToSend : form.changes
			});
		}

		try {
			let url = '/' + forms[0].id

			if (id !== undefined) {
				if (id === '')
					url = ''
				else
					url = '/' + id
			}

			const res = await super.patch(url, {}, payload);
			const dataCopy = res.data ? [...res.data] : [];
			for(const form of forms)
			{
				if(dataCopy.length > 0)
				{
					const resourceObject = dataCopy.find(ro => ro.id === form.id);
					if(resourceObject){
						res.data = [resourceObject];
					}
					else
					{
						res.data = [];
					}
				}

				form.changesToValues()
				form.mergeData(res)
				form.errorFromService = undefined
				form.isChanged = false
			}

			
			// [Performance Fix] KCH: Wygląda na to, że odświezenie kontekstu AppCtrl powoduje ponowne wyrenderowanie całej aplikacji.
			// Miejmy nadzieje, że tymczasowo bazujemy na metodzie `refreshAppState()`, która powinna wykonać odświeżenie stanu
			// właściwie całej aplikacji. To stwierdzenie nie zawsze musi być prawdą ale w newraglicznych miejscach w aplikacji
			// bazujemy na tym zachowaniu.
			//form.trigger('data')
			appCtrl.addMessages(res.messages, false)
			appCtrl.refreshAppState()

			// parent form nie zawsze występuje - nie ma go w przypadku okna userProcessActions
			if (forms[0].parentForm?.setObjectInfoMessages) {
				forms[0].parentForm.setObjectInfoMessages(res.messages);
			}

			return true
		}
		catch (err) {
			forms[0].errorFromService = err
			forms[0].trigger('data')
			return false
		}
	}

	/**
	 * Nadpisanie wielu rekordów
	 *
	 * @param {*} formContext
	 * @param {*} id
	 * @returns {*}
	 * @method patch
	 */
	async patches(forms) {
		try {
			const res = []

			for (let i = 0; i < forms.length; ++i) {
				const form = forms[i]
				const payload = {
					data: [
						{
							id: form.id,
							type: form.dataType,
							attributes: form.forceAllAttributesUpdate ? form.currentValuesToSend : form.changes
						}
					]
				}

				this.setServiceName(form.serviceUpdateHRef)
				res.push(await super.patch('', {}, payload))
			}

			const messages = [];
			for (let i = 0; i < forms.length; ++i) {
				const form = forms[i]
				form.changesToValues()
				form.mergeData(res[i])
				form.errorFromService = undefined

				if (res[i]?.messages) {
					messages.push(...res[i].messages);
				}

				//form.isChanged = false
				// form.trigger('data')
				// appCtrl.addMessages(res.messages, true)
			}

			// parent form nie zawsze występuje - nie ma go w przypadku okna userProcessActions
			if (forms[0]?.parentForm?.setObjectInfoMessages) {
				forms[0].parentForm.setObjectInfoMessages(messages);
			}

			return true
		}
		catch (err) {
			const form = forms[0].parentForm
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	/**
	 * Nadpisanie wielu rekordów
	 *
	 * @param {*} formContext
	 * @param {*} id
	 * @returns {*}
	 * @method patch
	 */
	async patches_array(formArray, data) {
		try {
			const res = await super.patch('', {}, data)

			formArray.merge(res)
			//form.changesToValues()
			//form.mergeData(res)
			formArray.errorFromService = undefined
			//form.isChanged = false
			formArray.trigger('data')
			appCtrl.addMessages(res.messages, true)

			if (formArray.setObjectInfoMessages) {
				formArray.setObjectInfoMessages(res.messages);
			}

			return true
		}
		catch (err) {
			formArray.errorFromService = err
			formArray.trigger('data')
			return false
		}
	}

	async patchCat(form, segments) {
		try {
			await super.patch('', {}, segments)

			form.errorFromService = undefined
			form.isChanged = false
			form.trigger('data')
			return true
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}


	/**
	 * Stworzenie rekordu
	 *
	 * @param {*} formContext
	 * @returns {*}
	 * @method post
	 */
	async post(form) {
		const payload = {
			data: [
				{
					type: form.dataType,
					attributes: form.currentValuesToSend
				}
			]
		}

		try {
			let res = await super.post('', {}, payload)
			form.changesToValues()
			form.mergeData(res)
			form.errorFromService = undefined
			form.isChanged = false
			// [Performance Fix] KCH: Wygląda na to, że odświezenie kontekstu AppCtrl powoduje ponowne wyrenderowanie całej aplikacji.
			// Miejmy nadzieje, że tymczasowo bazujemy na metodzie `refreshAppState()`, która powinna wykonać odświeżenie stanu
			// właściwie całej aplikacji. To stwierdzenie nie zawsze musi być prawdą ale w newraglicznych miejscach w aplikacji
			// bazujemy na tym zachowaniu.
			//form.trigger('data')
			appCtrl.addMessages(res.messages, false)
			appCtrl.refreshAppState()

			// parent form nie zawsze występuje - nie ma go w przypadku okna userProcessActions
			if (form.parentForm?.setObjectInfoMessages) {
				form.parentForm.setObjectInfoMessages(res.messages);
			}

			return res.data[0].id
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	async post_createFromList(form, marks) {
		const payload = {
			data: marks
		}

		try {
			await super.post('', {}, payload)
			form.errorFromService = undefined
			form.trigger('data')

			return true
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	/**
	 * Kasowanie rekordu
	 *
	 * @param {*} formContext
	 * @returns {*}
	 * @method post
	 */
	async deleteOne(form) {
		try {
			let res = await super.deleteOne('', {}, {})
			form.errorFromService = undefined
			form.isChanged = false
			appCtrl.addMessages(res.messages, true)

			// parent form nie zawsze występuje - nie ma go w przypadku okna userProcessActions
			if (form.parentForm?.setObjectInfoMessages) {
				form.parentForm.setObjectInfoMessages(res.messages);
			}

			return res
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}
	/**
	 * Wysłanie pliku
	 *
	 * @param {*} file
	 * @returns {*}
	 * @method postFile
	 */
	async postMultipleFiles(files, onProgress) {
		try {
			const responses = await super.postMultipleFiles('', files, onProgress);
			const allMessages = []
			
			for(let response of responses){
				allMessages.push(...response?.value?.messages)
			}

			appCtrl.addMessages(allMessages, true)
			return responses
		}
		catch (error) {
			console.error(error, "Upload failed for files: ", error.results)
			throw(error);
		}
	}

	async checkAccessToken() {
		if (!appCtrl.session.isAuthorized || !appCtrl.session.isAccessTokenExpired)
			return

		this.refreshAccessToken()
	}

	async refreshAccessToken() {
		if (!appCtrl.session.isAuthorized)
			return

		const parameters = {
			meta: {
				dataType: "RefreshAccessToken"
			},
			data: [
				{
					type: "RefreshAccessToken",
					attributes: {
						RefreshToken: appCtrl.session.refreshToken,
						OrganizationId: appCtrl.session.currentOrganizationInfo.id
					}
				}
			]
		}

		try {
			let res = await super.post('', {}, parameters)

			let newValue = { ...appCtrl.session.value, ...res.data[0].attributes }
			appCtrl.session.value = newValue
			
			return true
		}
		catch (err) {
			console.log(err);
			return false
		}
	}

	/**
	 * Logowanie
	 *
	 * @param {*} formContext
	 * @returns {Array} - Tablica według wzoru: [obiektBłędu, isOk] - isOk - Właściwość true/false mówiąca o tym czy request wykonał się poprawnie 
	 * @method post
	 */
	async login(form) {
		const { Login, Password, Organization } = form.currentValuesToSend
		const parameters = {
			meta: {
				dataType: 'AuthLogin'
			},
			data: [
				{
					type: 'AuthLogin',
					attributes: { Login, Password, Organization }
				}
			]
		}

		try {
			appCtrl.session.value = undefined
			let res = await super.post('', {}, parameters)
			appCtrl.session.value = { ...res.data[0].attributes, Login }
			appCtrl.initLocale(res.data[0].attributes)
			form.errorFromService = undefined
			appCtrl.clearMessages(true) //Czyszczenie wiadomość z BE po zalogowaniu do aplikacji 

			if (res.data[0].attributes.Configuration) {
				appCtrl.configuration.setValues(res.data[0].attributes.Configuration)
			}

			return [{}, true]
		}
		catch (error) {
			form.errorFromService = error
			form.trigger('data')
			return [error, false]
		}
	}

	async loginToAnotherOrganization(form) 
	{

		try {
			let res = await super.post('', {})
			const originalSessionValue = appCtrl.session.value;
			appCtrl.session.value = { ...originalSessionValue, ...res.data[0].attributes}
			appCtrl.initLocale(originalSessionValue)
			form.errorFromService = undefined
			appCtrl.clearMessages(true) //Czyszczenie wiadomość z BE po zalogowaniu do aplikacji 

			if (res.data[0].attributes.Configuration) {
				appCtrl.configuration.setValues(res.data[0].attributes.Configuration)
			}

			return [{}, true]
		}
		catch (error) {
			form.errorFromService = error
			form.trigger('data')
			return [error, false]
		}
	}


	/**
	 * Reset hasła
	 *
	 * @param {*} formContext
	 * @returns {Array} - Tablica według wzoru: [obiektBłędu, isOk] - isOk - Właściwość true/false mówiąca o tym czy request wykonał się poprawnie 
	 * @method post
	 */
	async resetPassword(form) {
		const { Login, Organization } = form.currentValuesToSend
		const parameters = {
			meta: {
				dataType: 'ResetPassword'
			},
			data: [
				{
					type: 'ResetPassword',
					attributes: { Login, Organization }
				}
			]
		};

		try {
			appCtrl.session.value = undefined;
			let response = await super.post('', {}, parameters);
			form.errorFromService = undefined;
			appCtrl.clearMessages(true);
			appCtrl.addMessages(response?.messages, true);

			return [{}, true];
		}
		catch (error) {
			form.errorFromService = error;
			form.trigger('data');
			return [error, false];
		}
	}

	/**
	 * Nadpisanie wielu rekordów
	 *
	 * @param {*} formContext
	 * @param {*} id
	 * @returns {*}
	 * @method patch
	 */
	 async acceptTermsOfService(forms) {
		try {
			const payload = {
				data: []
			}

			for (let i = 0; i < forms.length; ++i) {
				const form = forms[i]
				payload.data.push({
					id: form.id,
					type: form.dataType,
					attributes: form.changes
				});
			}

			const res = await super.patch('', {}, payload)

			for (let i = 0; i < forms.length; ++i) {
				const form = forms[i]
				form.changesToValues()
				//form.mergeData(res[i])
				form.errorFromService = undefined
				//form.isChanged = false
				// form.trigger('data')
				// appCtrl.addMessages(res.messages, true)
			}
			return [{}, true]
		}
		catch (err) {
			const form = forms[0].parentForm
			form.errorFromService = err
			form.trigger('data')
			return [err, false]
		}
	}

	async patchCulturecode(form, cultureCode) {
		try {
			await super.patch('/' + cultureCode, {}, {})

			form.errorFromService = undefined
			form.isChanged = false
			return true
		}
		catch (err) {
			form.errorFromService = err
			form.trigger('data')
			return false
		}
	}

	async getConfiguration() {
		try {
			const identityId = appCtrl.session.identityId
			const res = await super.get('/' + identityId, { limit: 100 }, {})
			const data = res.data.find(d => d.id === identityId)
			if (!data)
				return false
			appCtrl.configuration.setValues(data.attributes)
		}
		catch (err) {
			return false
		}
	}

	async patchConfiguration(configurationChanges, identityId) {
		try {
			const parameters = {
				meta: {
					dataType: 'Configuration'
				},
				data: [
					{
						id: identityId,
						type: 'Configuration',
						attributes: configurationChanges
					}
				]
			}
			await super.patch('/' + identityId, {}, parameters)
			return true
		}
		catch (err) {
			return false
		}
	}


	async patchNotificationState(notificationOwnerId,notificationOwnerDataType, operationState){
		try {
			const parameters = {
				data: [
					{
						id: notificationOwnerId,
						type: notificationOwnerDataType,
						attributes: { ["OperationType"]: operationState },
					}
				],
			}
			return await super.patch('', {}, parameters);
		}
		catch (err) {
			return false;
		}
	}
}