import React from 'react'
import DV from '../../../components/dataView/DataView'
import EditPart from './EditPart'
import CreatePart from './CreatePart'
import ListPartInner from './ListPartInner'
import ListPartOuter from './ListPartOuter'
import { Splitter } from 'components/splitter/Splitter'
import { useForm } from 'components/form/Form'
import { linkManager } from 'components/LinkManager'
import { useHistory } from 'react-router-dom'
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'

function InnerDecoration({ child1, child2, part }) {
	const history = useHistory()
	const form = useForm('data')
	const { vertical, sizeHorizontal, sizeVertical } = form.splitter

	if (!child1)
		return null

	if (!child2)
		return child1

	return <Splitter child1={child1} child2={child2} vertical={vertical} sizeHorizontal={sizeHorizontal} sizeVertical={sizeVertical} isMaximize={linkManager.isMaximizeAll}
		dataType={form.dataType ?? part?.props?.dataType ?? ""}
		onClose={() => {
			linkManager.handleListNewPage(history, part, true)
		}}
		onChangeSize={v => {
			form.splitter = v
			form.saveConfiguration()
		}}
	/>
}

/**
 * 
 * @param {Boolean} inlineEditionOnly - Parametr który mówi o tym czy dany part będzie jedynie w trybie edycji w wierszu. Oznacza to że nie będzie dało się przejść do 
 * zwykłego trybu "edit", oraz edycja w wierszu dla tabeli będzie domyślnie włączona
 * @returns
 * @param {String} useLinkName - nazwa typu danych, po dla którego chcemy wyszukać odpowiedni link
 * @param {String} useObjectLinkName - nazwa typu danych, po dla którego chcemy wyszukać odpowiedni link dla pojedynczego obiektu,
 *  zastąpi useLinkName jeśli został podany
 */
export default function ListPart({ dataType, useLinkName, useObjectLinkName, dataTypeAdd, title, tabTitle, part, titleCreate, titleEdit, children, createEditForm, polymorphic, list, group, sort, isGantt, inlineEditionOnly, autoOpenTab }) {
	if (!part.reachable)
		return null

	return (
		<ErrorBoundary>
			<DV.Part
				part={part}
				dataType={dataType}
				title={title}
				outerContent={<ListPartOuter useLinkName={useLinkName} group={group} sort={sort} isGantt={isGantt} inlineEditionOnly={inlineEditionOnly} />}
				innerContent={list ? list : <ListPartInner group={group} sort={sort} isGantt={isGantt} dataTypeAdd={dataTypeAdd} inlineEditionOnly={inlineEditionOnly} />}
				innerDecoration={<InnerDecoration />}
			>
				<CreatePart
					name='create'
					dataType={dataType}
					useLinkName={useObjectLinkName !== undefined ? useObjectLinkName : useLinkName}
					title={titleCreate || title}
					tabTitle={tabTitle}
					hidden
					createEditForm={createEditForm}
					polymorphic={polymorphic}			
				/>
				<EditPart
					name='edit'
					dataType={dataType}
					useLinkName={useObjectLinkName !== undefined ? useObjectLinkName : useLinkName}
					title={titleEdit || title}
					tabTitle={tabTitle}
					connected
					hidden
					createEditForm={createEditForm}
					polymorphic={polymorphic}
					autoOpenTab={autoOpenTab}
					>
					{children}
				</EditPart>
			</DV.Part>
		</ErrorBoundary>
	)
}
