import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GetApp } from '@material-ui/icons'
import Input from 'components/form/components/input/Input/Input'
import { useField } from 'components/form/Form'
import { isArray, isEmpty, trim } from 'lodash'
import { useSnackbar } from 'notistack'
import DownloadFileList from './DownloadFileList'
import { useTranslate } from 'locale/Locale'
import getFormRestService from 'services/getFormRestService'
import { saveAs } from 'file-saver'
import { useFileProgress } from 'components/FileDownloadProgressDialog';

//#region Komponenty 
/**
 * Ikonka pozwalajaca na pobranie pliku
 * 
 * @param {String} fileID - ID pliku którego dotyczy ikonka
 * @param {String} fileName - Nazwa pliku którego dotyczy ikonka
 * @returns Ikona pozwalająca na pobieranie pliku z nieaktywnej kontrolki
 */
const InputIcon = ({ name, error, dataType, fileObject, handleDownload, isFieldInputDisabled }) => {

	const fileNameIsNotEmpty = !isEmpty(trim(fileObject?.fileName))
	const isDownloadByIconEnabled = fileNameIsNotEmpty && !isFieldInputDisabled
	const ICON_ID = `${dataType}-${name}-DownloadInputIcon`
	const { enqueueSnackbar } = useSnackbar();
	const translate = useTranslate('WebSpa/Snackbar');

	const ICON_COLORS = Object.freeze({
		primary: '#29436d',
		error: '#A73A38',
		disabled: '#D2D3D3',
	})
	// const error = data.getCurrentError(fileID)
	// ICON: `${this._dataType}-${name}-${this.ICON_NAME}`

	const handleIconClick = e => {
		e.stopPropagation();
		
		if(error || !isDownloadByIconEnabled) 
			return
		else
		{
			handleDownload(e, fileObject, !isDownloadByIconEnabled)
		}
	}

	/**
    * Funkcja zwracająca kolor ikonki z tablicy kolorów w zależności od stanu kontrolki
    * 
    * @param {Boolean} disabled - Informacja o aktywnośći ikonki
    * @param {Boolean} error - Informacja o tym czy jest jakiś błąd
    * @returns 
    */
	 const handleChangeIconColor = (disabled, error) => {
		if(error) return ICON_COLORS.error
		if(disabled) return ICON_COLORS.disabled
		else return ICON_COLORS.primary
	}

	const shouldIconStyleBeDisabled = !isDownloadByIconEnabled
	return (
		<GetApp
			id={ICON_ID}
			onClick={(e) => handleIconClick(e)} 
			style={{cursor: shouldIconStyleBeDisabled || error ? 'default' : 'pointer', fill: handleChangeIconColor(shouldIconStyleBeDisabled, error)}}
		/>
	)
}

/**
 * Kontrolka do pobierania plików
 * 
 * @returns {Node}
 */
const DownloadFileInput = ({ field, name, dataType, hasLabel, fileObject, valueInput, handleDownload, errorsObject, label, disabled, }) => {
	
	const error = errorsObject?.[fileObject?.id] ?? false
	const INPUT_ID = `${dataType}-${name}-DownloadInput`

	const existsDownloadLink = field?.form?.serviceDownloadFile(name, fileObject?.id, false) !== undefined;

	return (
		<div onClick={(e) => handleDownload(e, { fileName: fileObject?.value, fileID: fileObject?.id }, disabled)}>
			<Input
				name={name}
				id={INPUT_ID}
				as={'div'}
				hasLabel={hasLabel}
				hasIcon={false}
				value={valueInput}
				error={error}
				label={hasLabel && label}
				disabled={disabled}
				InputLabelProps={{ shrink: true }}
				InputProps={{ endAdornment: (
					<InputIcon 
						fileObject={{ fileName: fileObject?.value, fileID: fileObject?.id }} 
						name={name} 
						dataType={dataType} 
						handleDownload={handleDownload}
						error={error}
						isFieldInputDisabled={!existsDownloadLink}
					/>
				) 
				}}
			/>
		</div>	
	)
}


/**
 * Wrapper otaczający zawartość po kliknięciu której pobierany jest plik z serwera
 * 
 * @param {string} name Nazwa pola 
 * @param {boolean} hasLabel Informacja czy komponent jest wyświetlany normalnie czy w widoku Edycji wiersza
 * @returns {Node}
 */
 const DownloadFile = ({ name, hasLabel }) => {
	const field = useField(name);
	const { enqueueSnackbar } = useSnackbar()
	const translate = useTranslate('WebSpa/Snackbar/DownloadFile');
	const fileProgress = useFileProgress();
	const [errorsObject, setErrorsObject] = useState({})
	/**
	 * Funkcja odpowiedzialna za obsługę pobierania pliku z BE
	 * @param {*} e 
	 * @param {*} fileObject 
	 * @param {*} disabled 
	 * @returns 
	 */
	const handleDownload = async(e, fileObject, disabled = false) => {
		//Jeśli kontrolka jest nieaktywna lub użytkownik wciska kttóryś ze specjalnych klawiszy nie pozwalamy na żadną akcję
		if(disabled || e.ctrlKey || e.shiftKey  || e.altKey  || e.metaKey) {
			return
		}
		const { fileName, fileID }  = fileObject
		const serviceName = field.form?.serviceDownloadFile(name, fileID)

		if(isEmpty(trim(fileName))){ //Sytuacja w której nie ma nazwy pliku
			enqueueSnackbar(`${translate("error/nofile")}`, { variant: 'error' })
			setErrorsObject({...errorsObject, [fileID]: true})
			return
		}

		else if(isEmpty(trim(serviceName))){ //Sytuacja w której brakuje linku do pobrania pliku
			enqueueSnackbar(`${translate("error/nolink")} ${fileName}`, {variant: 'error' })
			setErrorsObject({...errorsObject, [fileID]: true})
			return
		}

		else {
			const fileHandle = fileProgress.addFile(fileID, fileName);
			try 
			{
				const BLOB = await getFormRestService(serviceName).downloadFileFromLivo((loaded, contentLength) => {
					fileProgress.setProgress(fileHandle, Math.round((loaded/contentLength)*100));
				});
				fileProgress.successed(fileHandle);
				saveAs(BLOB, fileName)
			}
			catch (error) {
				setErrorsObject({...errorsObject, [fileID]: true})

				if(error?.responseError?.title){//Gdy znamy tekst błedu wyświetlamy Snackbara z tekstem błędu
					fileProgress.failed(fileHandle, `${translate("error/defaultError")} ${error.responseError.title}`);//Inne sytuacje gdy wystąpił błąd
				}
				else
				{
					fileProgress.failed(fileHandle, `${translate("error/defaultError")} ${fileName}`);
				}
			}
		}
	}	
	//#endregion


	return (
		isArray(field.value) ?
			<DownloadFileList 
				name={name}
				dataType={field.dataType}
				files={field.value}
				label={field.label}
				handleDownload={handleDownload}
				errorsObject={errorsObject} 
			/> 
				: 
			<DownloadFileInput
				field={field} 
				name={name}
				dataType={field.dataType} 
				hasLabel={hasLabel} 
				fileObject={field.value}
				valueInput={field.valueInput} 
				errorsObject={errorsObject} 
				label={field.label} 
				handleDownload={handleDownload}
				disabled={Boolean(field.disabled || !field?.value?.id)}
			/> 
	)
	}
	//#endregion


/* #region Export komponentu */
	export default DownloadFile
	export { InputIcon }
/* #endregion */


/* #region  PropTypes */

DownloadFile.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool,
}

DownloadFileInput.propTypes = {
	name: PropTypes.string.isRequired,
	dataType: PropTypes.string,
	hasLabel: PropTypes.bool ,
	fileObject: PropTypes.object,
	valueInput: PropTypes.string,
	handleDownload: PropTypes.func,
	errorsObject: PropTypes.object,
	label: PropTypes.string,
	disabled: PropTypes.bool ,
}

InputIcon.propTypes = {
	name: PropTypes.string.isRequired,
	dataType: PropTypes.string,
	error: PropTypes.bool ,
	fileObject: PropTypes.object,
	handleDownload: PropTypes.func,
}

/* #endregion */


/* #region  Domyślne propsy */

DownloadFile.defaultProps = {
	hasLabel: true,
	name: '',
}

/* #endregion */
