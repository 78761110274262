import React from 'react'
import { useForm } from 'components/form/Form'
import * as PositionObserver from 'components/positionObserver/PositionObserver'
import { GanttArrowLine } from './GanttArrowLine'

export function GanttObservedArrow({ id1, id2 }) {
	const form = useForm('data')
	const [r1, r2] = PositionObserver.usePositionElements([id1 + '', id2 + ''], true)

	if (!r1 || !r2)
		return null

	const p1 = r1.rgCn
	const p2 = r2.lfCn

	return <GanttArrowLine
		x1={p1.x}
		y1={p1.y}
		x2={p2.x}
		y2={p2.y}
		color='#122438'
		size={10}
		height={10 + r1.cy / 2}
		lineWidth={3}
		onDoubleClick={() => {
			const ganttPrev = 'DependOnItemsListId'
			const field = form.getField(ganttPrev)
			let value = field.value
			// if (!form.serviceUpdate)
			// 	return
			value = value.filter(v => v.id !== id1)
			form.handleChange(ganttPrev, value)
			form.asyncSubmit('ganttDeleteLink')
		}}
	/>
}