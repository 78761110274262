import { Button, withStyles, Tooltip } from '@material-ui/core'
import { darken } from '@material-ui/core/styles'

export const StyledButton = withStyles(({ palette }) => ({
	root: {
		color: palette.secondary.white,
		height: 33,
		whiteSpace: 'nowrap',
		backgroundColor: palette.primary.buttonDefault,

		'&:hover': {
			backgroundColor: darken(palette.primary.buttonDefault, 0.3),
		},
	},

	contained: {
		backgroundColor: palette.primary.buttonDefault,

		'&:hover': {
			backgroundColor: darken(palette.primary.buttonDefault, 0.3),
		},
	},

	containedPrimary: {
		backgroundColor: palette.primary.buttonPrimary,

		'&:hover': {
			backgroundColor: darken(palette.primary.buttonPrimary, 0.3),
		},
	},

	containedSecondary: {
		backgroundColor: palette.primary.buttonSecondary,

		'&:hover': {
			backgroundColor: darken(palette.primary.buttonSecondary, 0.2),
		},
	},

	outlined: {
		minWidth: 45,
		height: 34,
		color: palette.primary.buttonPrimary,
		borderColor: palette.primary.mainDarken800,
		backgroundColor: 'transparent',
		padding: '2px 8px',

		'&:hover': {
			color: darken(palette.primary.buttonPrimary, 0.5),
			borderColor: 'inherit',
			backgroundColor: palette.primary.buttonOutlinedHover,
		},
	},
}))(Button)


export const StyledButtonTooltip = withStyles(() => ({
	tooltip: {
		fontSize: '14px',
	},
}))(Tooltip)

