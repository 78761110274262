import React from 'react'
import getFormRestService from 'services/getFormRestService'

import { Preloader, useForm, Field } from 'components/form/Form'
import { FormMetaObject } from 'components/form/Form'
import { Horizontal, Vertical } from 'components/topology/Topology'

import * as PositionObserver from 'components/positionObserver/PositionObserver'
import { List } from '@material-ui/core'

import { TaskCard } from './TaskCard'
import { StyledListItem } from './StyledListItem'
import { AddChildButton } from './AddChildButton'
import { FieldTitle } from './FieldTitle'
import Button from '../buttons/Button'
import { ButtonSubmit } from '../buttons'

export function Task({ row, rowIndex, children, startColumnIndex, tree, onClickTreeChild }) {
	const form = useForm('data')
	const ref = PositionObserver.useAddElement(row.id + '')

	return (
		<Horizontal className='grid-aiy-start'>
			<TaskCard ref={ref}>
				<FormMetaObject
					editMode='edit'
					data={form.rowManager.getDataFromRow(row, form._data.ui)}
					onLoad={async (form, loadType) => {
					}}
					onSubmit={async (form) => {
						if (!form.isOk)
							return
						await getFormRestService(form.serviceUpdateHRef)
							.patch(form, '')
					}}
				>
					<Preloader>
						<div className='relative grid-ver'>
							<FieldTitle name='PositionName' />
							<List style={{ border: '1px solid #000' }}>
								<StyledListItem>
									<Field name='StartTime' />
								</StyledListItem>
								<StyledListItem>
									<Field name='EndTime' />
								</StyledListItem>
								<StyledListItem>
									<Field name='SupplierCompanyRef' />
								</StyledListItem>
								<StyledListItem>
									<Field name='SupplierPersonRef' />
								</StyledListItem>
								<StyledListItem>
									<Field name='Status' />
								</StyledListItem>
								<AddChildButton onClick={e => {
									onClickTreeChild && onClickTreeChild({ rowIndex })
									e.stopPropagation()
								}} />
							</List>
							<ButtonSubmit style={{ borderRadius: 0, backgroundColor: '#1A314B', border: '2px solid #1A314B' }} fullWidth />
						</div>
					</Preloader>
					{/* <Fields /> */}
				</FormMetaObject>
			</TaskCard>
		</Horizontal>
	)
}