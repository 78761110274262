import React from 'react'

/**
 * Rysowanie pojedyńczej grupy. Dzieci powinne rysować wszystkie elementy grupy
 * @returns {Node}
 */
export function Group({ children, group }) {
	return React.Children.map(children, (child, index) => {
		return React.cloneElement(child, { group, groupChildren: children })
	})
}