import SegmentElementViewModel from "./SegmentElementViewModel"
import SegmentElementType from "./SegmentElementType"

const saveableElementsType = SegmentElementType.saveableValues();

export default class SegmentViewModel {
    constructor(elements) {
        this._elements = elements || [];
    }

    get elements() {
        return this._elements;
    }

    /**
    * @param {Array<SegmentElementViewModel>}  elements
    */
    set elements(elements) {
        this._elements = elements || [];
    }

    /**
    * @param {SegmentElementViewModel} element
    */
    addElement(element) {
        this._elements.push(element)
    }

    equals(segment) {
        if(!segment)
            return false;

        if(this.elements.length !== segment.elements.length)
            return false;

        for (let index = 0; index < this.elements.length; index++) {
            const thisElement = this.elements[index];
            const otherElement = segment.elements[index];

            if(!thisElement.equals(otherElement))
                return false;
        }

        return true;
    }

    saveableContentEquals(segment)
    {
        if(segment == null)
            return false;
        
        const thisElements = this.elements.filter(viewModel => {
            return saveableElementsType.includes(viewModel._typeName);
        });
        const otherElements = segment.elements.filter(viewModel => {
            return saveableElementsType.includes(viewModel._typeName);
        });

        const thisStringRepresentation = thisElements.join('');
        const otherStringRepresentation = otherElements.join('');

        return thisStringRepresentation === otherStringRepresentation;
    }

    toPlainText() {
        let plaintTextValue = ''
        for (let element of this._elements) {
            plaintTextValue += element.value
        }

        return plaintTextValue
    }

    hasChangeTrackingElements()
    {
        for (let index = 0; index < this.elements.length; index++) {
            const element = this.elements[index];
            if(SegmentElementType.changeTrackingValues().includes(element.typeName))
                return true;
        }

        return false;
    }
}
