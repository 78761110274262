import React from 'react'

/**
 * Rysuje wiersze w grupie jesli brak podgrup
 *
 * @param {*} { group }
 * @returns
 */
export function GroupRows({ group, children }) {
	if (group.groups)
		return null

	const child = React.Children.only(children)

	return group.rows.map((row, rowIndex) => {
		return (
			<React.Fragment key={rowIndex} >
				{React.cloneElement(child, {
					key: row.id,
					rowIndex: rowIndex + group.offsetRowIndex,
					row,
					startColumnIndex: group.colIndex + 1
				})}
			</React.Fragment>
		) 
	})
}