
import { appCtrl } from 'AppCtrl';
import { LOGIN_DATATYPE } from './login/Login';

const validateMapFunctions = {
	Required: function ({ name, value, errors, dataType }) {
		const fieldIsRequiredMessage = 'This field is required.';	
		if(Array.isArray(value) && !value.length){

			//W przypadku niezalogowanych użytkowników dajemy wiadomość po angielsku.
			if (!appCtrl.locale.currentCultureCode) {
				errors[name] = fieldIsRequiredMessage;
				return false;
			}

			errors[name] = appCtrl.locale.translate("WebSpa/HelperText/require")
			return false
		}

		if (value !== undefined && value !== '' && value !== null && value !== false)
			return true
		
		// W przypadku komunikatu pól dotyczącego logowania chcemy mieć wiadomość zawsze po angielsku
		if (dataType === LOGIN_DATATYPE) {
			errors[name] = fieldIsRequiredMessage;
			return false;
		}
			
		errors[name] = appCtrl.locale.translate("WebSpa/HelperText/require")
		return false
	},
	Length: function ({ validate, name, value, errors }) {
		if (typeof (value) !== 'string')
			return true

		if (validate.min !== undefined && value.length < validate.min) {
			errors[name] = appCtrl.locale.translate("WebSpa/HelperText/toShort")
			return false
		}

		if (validate.max !== undefined && value.length > validate.max) {
			errors[name] = appCtrl.locale.translate("WebSpa/HelperText/toLong")
			return false
		}

		return true
	},
	Range: function ({ validate, name, value, errors }) {
		if (typeof (value) !== 'number')
			return true

		if (validate.min !== undefined && value < validate.min) {
			errors[name] = appCtrl.locale.translate("WebSpa/HelperText/toSmall")
			return false
		}

		if (validate.max !== undefined && value > validate.max) {
			errors[name] = appCtrl.locale.translate("WebSpa/HelperText/toBig")
			return false
		}

		return true
	},
	RegEx: function ({ validate, name, value, errors }) {
		if (typeof (value) !== 'string' || validate.value === undefined)
			return true

		var r = new RegExp(validate.value)

		if (r.test(value))
			return true

		errors[name] = validate.errors
		return false
	},
	PasswordValidate: function ({ name, value, errors }) {

		if (typeof (value) !== 'string')
			return true

		const passwordValidation = /^(?=(.\ )*[A-Za-z\d@\!""#$%&'()*+,-./:;<=>?[\]^_`{|}~])(?=.*\d)(?=.*[@!""#$%&'()*+,-./:;<=>?[\]^_`{|}~])[A-Za-z\d@\ !""#$%&'()*+,-./:;<=>?[\]^_`{|}~]{8,}.*(?<!\ )$/gm

		const passwordRegExp = new RegExp(passwordValidation)

		if (passwordRegExp.test(value))
			return true

		errors[name] = appCtrl.locale.translate("WebSpa/HelperText/passwordRequirements")
		return false
	},
	ZipCode: function ({ name, value, errors }) {

		if (typeof (value) !== 'string')
			return true

		var zipCodeValidation = /\d{2}-\d{3}/g

		var r = new RegExp(zipCodeValidation)

		if (r.test(value))
			return true

		errors[name] = appCtrl.locale.translate("WebSpa/HelperText/zipCode")
		return false
	}
}

function validateFunctions(context) {

	if (!context.meta.validations)
		return true

	if (context.editMode === 'create' && !context?.meta?.visibleOnCreate)
		return true

	var vv = context.meta.validations

	for (var i in vv) {
		var v = vv[i]

		if (!(v.type in validateMapFunctions))
			return false

		context.validate = v
		if (!validateMapFunctions[v.type](context))
			return false
	}
}

const validateMapValues = {
	Bool: function ({ value, errors }) {
		return true
	},
	String: function ({ value, errors }) {
		return true
	},
	Enum: function ({ meta, value, errors }) {
		return true
	},
	EnumList: function ({ meta, value, errors }) {
		return true
	},
	Number: function ({ name, value, errors }) {
		if (typeof value === 'string') {
			value = value.trim()
			if (value === '')
				value = undefined
		}

		if (value === undefined)
			return true

		var v = Number(value)

		if (!Number.isNaN(v))
			return true

		errors[name] = appCtrl.locale.translate("WebSpa/HelperText/notNumber")
		return false
	},
	Int: function ({ name, value, errors }) {
		if (typeof value === 'string') {
			value = value.trim()
			if (value === '')
				value = undefined
		}

		if (value === undefined)
			return true

		var v = Number(value)

		if (Number.isInteger(v))
			return true

		errors[name] = appCtrl.locale.translate("WebSpa/HelperText/notIntigerNumber")
		return false
	},
	Ref: function ({ value, errors }) {
		return true
	},
	File: function ({ value, errors }) {
		return true
	},
	Timestamp: function ({ name, value, errors }) {

		if (!value)
			return true

		const d = new Date(value)

		if (isNaN(d)) {
			errors[name] = appCtrl.locale.translate("WebSpa/HelperText/dateFormat")
			return false
		}
		return true
	}
}

/**
 * Walidacja danych formy
 *
 * @param {FormMetaObject} form klasa formy walidowanej
 * @param {Object} errors Zwaracane błędy z walidowanych pól
 */
export default function Validate(form, errors) {
	for (var name in form.metas) {
		var meta = form.getMeta(name)
		var context = {
			name,
			value: form.getValue(name),
			meta,
			errors,
			editMode: form.editMode,
			dataType: form.dataType
		};

		if(meta === null)
			continue

		if (!(meta.type in validateMapValues))
			continue

		if (!validateMapValues[meta.type](context))
			continue

		validateFunctions(context)
	}
}