import { json } from 'body-parser'
import React from 'react'
import Events from '../events/Events'

/**
 * Context stworzony przez React.createContext()
 */
const ObservableContext = React.createContext()

/**
 * Klasa przechowywana w kontekscie ObservableContext
 */
class ObservableContextClass {
	/**
	 * Konstruktor
	 */
	constructor() {
		this._events = new Events()
	}

	/**
	 * Inicjacja danych przechowywanych przez ta klasę
	 * @param {*} v poczatkowe dane przechowywane w tej klasie
	 */
	initData(v) { this.data = v }
	/**
	 * Wartość danych przechowywanej w tej klasie
	 */
	set data(v) { this._data = v }
	/**
	 * Zwraca daną przechowywaną w tej klasie
	 */
	get data() { return this._data }

	/**
	 * Czy objekt przechowujacy daną jest zamontowany
	 */
	get isMounted() { return this._isMounted }
	/**
	 * Ustawianie czy jest zamontowany obiekt jest zamontowany
	 */
	set isMounted(v) { 
		this._isMounted = v
		if(v)
			this.onMounted()
		else
			this.onUnmounted() 
	}

	/**
	 * Wołanane przy kazdej zmianie this.isMounted na true
	 */
	onMounted() { }
	/**
	 * Wołanane przy kazdej zmianie this.isMounted na false
	 */
	onUnmounted() { }

	/**
	 * Wysłanie powiadomienia do wszystkich zainteresowanych
	 *
	 * @param {string} eventName Nazwa zdarzenia
	 */
	trigger(eventName) {
		if (this.isMounted)
		{
			//console.log(`${this.toString()} Trigger: ${eventName}`);
			this._events.trigger(eventName)
		}
			
	}

	/**
	 * Wysyła powiadomienie o zmianie w kontekscie
	 * @deprecated
	 */
	contextChanged() {
		if (this._onContextChange)
			this._onContextChange(this)
	}

	toString(){
		const className = this.constructor.name;
		let id = "(unknown)";

		if(this.data){
			if(this.data.meta)
			id = this.data.meta.dataType
			if(this.data.data && this.data.data.length > 0 && this.data.data[0].id)
				id += " " + this.data.data[0].id + " " + this.data.data.length;
		}
		
		if(this.editMode)
			id += " " + this.editMode;

		return `[${className} - ${id}]`;
	}
}

/**
 * Dostarczyciel obserwowalnego kontekstu
 *
 * @param {function} createObservableContextClass Funkcja zwracajaca utworzonej klasy ktora będzie przechowywana w kontekscie
 * @param {*} data Dane przechowywane w kontekscie
 * @param {Node} children Zawartość 
 * @param {function } onContextChange @deprecated
 * @returns {Node}
 */
function ObservableContextProvider({ createObservableContextClass, data, children, onContextChange, ...other }) {
	const context1 = React.useMemo(() => {
		//console.log("[ObservableContextProvider] createObservableContextClass: " + createObservableContextClass);
		const c = createObservableContextClass ? createObservableContextClass(other) : new ObservableContextClass(other)
		c.isMounted = true
		return c
		// eslint-disable-next-line
	}, [])

	const context2 = React.useMemo(() => {
		//console.log(`${context1.toString()} Init data`); // Tylko na potrzeby debug
		context1.initData(data)
		return context1
	}, [context1, data])

	const context3 = React.useMemo(() => {
		context2._onContextChange = onContextChange
		return context2
	}, [onContextChange, context2])

	React.useEffect(() => {
		return () => context3.isMounted = false
		// eslint-disable-next-line
	}, [])

	return (
		<ObservableContext.Provider value={context3}>
			{children}
		</ObservableContext.Provider>
	)
}

/**
 * Hook przechwytujący zmiany w kontekscie
 *
 * @param {string or Array[string]} eventName Zdarzenia które spowodują redraw komponentu
 * @returns {*} Obiekt przechowywany w kontekscie
 */
function useObservableContext(eventName) {
	const [, setState] = React.useState(true)
	const form = React.useContext(ObservableContext)
	const listener = React.useCallback(
		() => {
			//console.log(`Event '${eventName}' triggerred in form: ${form.toString()}`)
			setState(Object.create(null))
		},
		[setState]
	)

	if (!eventName)
		return form

	if (typeof (eventName) === 'string')
		form._events.useListener(eventName, listener)

	if (Array.isArray(eventName))
		eventName.map(e => form._events.useListener(e, listener))

	return form
}

/**
 * Konsumer kontekstu
 *
 * @param {Node} children Zawartość
 * @returns {Node}
 */
function ObservableContextConsumer({ eventName, children }) {

	const context = useObservableContext(eventName)

	if (!children)
		return null
	return children(context)
	// return (
	// 	<ObservableContext.Consumer>
	// 		{children}
	// 	</ObservableContext.Consumer>
	// )
}

export {
	ObservableContext,
	ObservableContextClass,
	ObservableContextProvider,
	ObservableContextConsumer,
	useObservableContext
}