import BuildInfo from './BuildInfo.json';
/**
 * Konfiguracja applikacji
	Dane konfiguracyjne wszystkie powinny ustawiane w plikach:
	.env //domyslne dla wszystkich konfiguracji
	.env.development //developreskie
	.env.production  //produkcja
	Tutaj tylko mapujemy. Pełne informacje w https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
	UWAGA: Do konfiguracji nie dodajemy żadnych sekretnych danych!!
	UWAGA: Zmiany w plikach .env* widoczne dopiero po restarcie npm start
	UWAGA: w .env.development.local można zdefiniować swoje localne ustawienia nadpisujące .env.development taksamo jest dla produkcji
 */
const config = {
	/** production | development
	 */
	mode: process.env.NODE_ENV,

	/**
		Adress serwera back-end'u musi obslugiwać CORS
		Trzeba dodać do serwera w nagłówkach:
		res.header("Access-Control-Allow-Origin", "*");
		res.header("Access-Control-Allow-Methods", "GET,HEAD,OPTIONS,POST,PUT,PATCH");
		res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization,Access-Control-Allow-Headers");
	*/
	serviceHost: process.env.REACT_APP_SERVICE_HOST,

	/** 
	 * Wersja api serwisu
	 * np. /api/v1
	 */
	serviceApiVer: process.env.REACT_APP_SERVICE_APIVER,
	/** 
	 * Pełen adres serwisu 
	 * np. http://localhost:3001/api/v1
	 */
	serviceUrl: process.env.REACT_APP_SERVICE_HOST + process.env.REACT_APP_SERVICE_APIVER,

	appVersion: {
		versionNumber: process.env.REACT_APP_APP_VERSION,
		buildTime: BuildInfo.buildTime,
		commitHash: BuildInfo.gitCommitHash ?? process.env.REACT_APP_COMMIT_HASH,
		branchName: BuildInfo.gitBranch ?? process.env.REACT_APP_GIT_BRANCH
	},

	upperMainMenuColor: process.env.REACT_APP_DEPLOYMENT_STAGE === "test" ? "#1f1f23" : '#294D7D',
	lowerMainMenuColor: process.env.REACT_APP_DEPLOYMENT_STAGE === "test" ? "#1f1f23" : '#336699'
}

export default config