import React, { useState } from 'react'
import { useForm } from 'components/form/Form'
import DV from 'components/dataView/DataView'
import getFormRestService from 'services/getFormRestService'
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Button from './Button'
import {ResourceObjectExporterToTsv} from 'model/exporters/ResourceObjectExporter';
import { saveAs } from 'file-saver';
import { Tooltip } from 'components/Tooltip'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularPreloader from 'components/preloader/CircularPreloader'
import Logger from 'Tools/Logging/Logger';
import { useTranslate } from 'locale/Locale'

function isVisible(form, isList) {
	if (isList)
		return !!form.serviceRead

	return form.serviceReadOne && !form.isChanged && form.editMode !== 'create'
}

function ExportProgressDialog({isLoadingData, exportToFilePercentComplete, canClose, handleClose})
{
	const exportLocalizedStrings = useTranslate('WebSpa/DataExport');
	return (
		<Dialog
			open={true}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{exportLocalizedStrings('Dialog/title')}</DialogTitle>
			<DialogContent dividers>
				<div style={{display: 'flex', justifyContent: 'center'}}>
					<CircularPreloader /><br/>
				</div>
				<br/>
				<div style={{display: 'flex', justifyContent: 'center'}}>
				{isLoadingData ? exportLocalizedStrings('Dialog/loadingData') : exportToFilePercentComplete + ' %'}
				</div>
				

			</DialogContent>
			{/** Tylko na potrzeby debugowania */}
			{/* {canClose && 
				<DialogActions>
					<Button  onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			} */}
			
      	</Dialog>)
}

/**
 * Przycisk z ikoną "refresh" pozwalający na przeładowanie stanu aktualnej formy
 * @returns Przycisk typu Reload, wykonujący akcję asyncSubmit na aktualnej formie. Akcja ta pozwala na przeładowanie aktualnego stanu formy
 */
export function ButtonExportData({ isList, className, style }) {
	const form = useForm('data');
	const exportLocalizedStrings = useTranslate('WebSpa/DataExport');
	const dataView = DV.useDataView();
	const [showProgress, setShowProgress] = useState(false);
	const [percentageComplete, setPercentageComplete] = useState(0);
	const [isLoadingData, setIsLoadingData] = useState(false);

	if (!isVisible(form, isList))
		return null

    const handleOnClick = async e => {
		setShowProgress(true);
		setIsLoadingData(true);
		try
		{
			// Zachowujemy nazwę eksportowanego typu jak najwcześniej. Może się zdarzyć, że dataview już nie będzie istnieć
			// po wykonaniu exportu. Choć w obecnej sytuacji, gdzie nie pozwalamy zamknąć okna dialogowego, to może być bez znaczenia.
			const exportTypeName = dataView.selected?.props?.title ?? ''; 
			const response = await getFormRestService(form.serviceReadHRef).getDataForExport(form);
			setIsLoadingData(false);

			// Eksportujemy tylko te kolumny, które użytkownik aktualnie widzi w swoim gridzie. 
			// Inne kolumny nie zostaną wyeksportowane do pliku. Takie było założenie.
			const columns = form.columnManager.visibleColumnArray.map( c => c.meta);
			const exporter = ResourceObjectExporterToTsv.createDefault(columns, response.data);
			exporter.progressHandler = (progress) => {
				setPercentageComplete(progress);
			}

			const blob = await exporter.export();
			
			saveAs(blob, 'Export_' + exportTypeName + '_' + new Date().getTime() + '.txt');
			setShowProgress(false);
		}
		catch(err)
		{
			Logger.of('ExportData').error(err);
		}
    }

	const handleCloseProgress = () => {
		setShowProgress(false);
	};

	return (
		<>
		<Tooltip title={exportLocalizedStrings('GridButtonExport/tooltip')}>
			<Button
				type='icon'
				className={className}
				style={style}
				icon={<SaveAltIcon style={{ fontSize: 25 }} />}
				id={`${form._dataType ?? ''}-ButtonExport`}
				onClick={handleOnClick}
			/>
			
		</Tooltip>
		{showProgress &&
			<ExportProgressDialog isLoadingData={isLoadingData} exportToFilePercentComplete={percentageComplete} 
				canClose={false} handleClose={handleCloseProgress} />
		}
		</>
	)
}