import React from 'react'
import { useLocale } from 'locale/Locale'
import { FormMetaObject, ComboboxEnum, Preloader } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import { useAppCtrl } from 'AppCtrl'
import { Redirect, useHistory } from 'react-router-dom'
import { ComboboxInput } from 'components/form/components/combobox/Combobox'
import { makeStyles } from '@material-ui/core/styles';
import { useDataView } from 'components/dataView/DataViewProvider'

const useStyles = makeStyles(({ palette }) => ({
	root: {
		'& [class*="MuiOutlinedInput-root"]': {
			padding: '1px !important',
			paddingLeft: '0px !important',
			fontSize: '17px !important',
			border: 'none !important',

		},
		'& [class*="MuiOutlinedInput-root"] fieldset': {
			border: `1px solid ${palette.primary.mainDarken800}`,
			borderColor: 'transparent !important',
			transition: 'all .19s cubic-bezier(0.4, 0, 0.2, 1)',
		},

		'& [class*="MuiOutlinedInput-root"]:hover fieldset': {
			borderColor: `${palette.primary.mainDarken800} !important`
		},
	},
}));


function ChangeOrganizationContextView() {
	const classes = useStyles();

	return (
		<ComboboxEnum
			name='Organization'
			disableClearable
			style={{ width: 125, height: 31 }}
			hasLabel={false}
			renderInput={(params) => <ComboboxInput name='Organization' params={params} className={classes.root} />}
			onChange={field => field.form.asyncSubmit()}
		/>
	)
}

export function ChangeOrganizationContext() {
	const appCtrl = useAppCtrl()
	const locale = useLocale()
    const history = useHistory();

    const organizations = appCtrl.session.organizationInfos.map(organizationInfo => {
        return {
            value: organizationInfo.name,
            id: organizationInfo.id,
        }
    })

	const currentOrganization = appCtrl.session?.currentOrganizationInfo ? organizations.find(org => org.id === appCtrl.session.currentOrganizationInfo.id) : undefined;

	return (
		<FormMetaObject
			data={{
				meta: {
					dataType: 'Organizations',
					attributes: {
						Organization: {
							name: 'Organization',
							type: 'Enum',
							editable: 'Editable',
							values: organizations
						}
					}
				},
				data: [
					{
						attributes: {
							Organization: currentOrganization
						}
					}
				]
			}}
			onSubmit={async form => {
				if (!form.isOk)
					return
					
				const selectedOrganization = form.getValue('Organization');

				if (await getFormRestService(`/auth/identities/me/organization/${selectedOrganization.id}`).loginToAnotherOrganization(form)) 
                {
					appCtrl.refreshAppState()
                    history.go(0); // Przeładowujemy stronę
				}
			}}
			redirectToLogin={() => null}
			errorModal={() => null}
		>
            <Preloader>
                <ChangeOrganizationContextView />
            </Preloader>
		</FormMetaObject>
	)
}