import React from 'react'
import { useForm } from 'components/form/Form'
import { useAppCtrl } from 'AppCtrl'

/**
 * Odswieża komponetów na zlecenie z BE
 * 
 * @returns {Node}
 */
export default function MessageListener() {
	const form = useForm()
	const appCtrl = useAppCtrl()

	appCtrl.checkNeedToRedirect()
	appCtrl.checkNeedToReload(form)
	return <div />
}