import styled from 'styled-components'
import { MixinColor, Border, BorderRadius, MixinBackground } from 'components/topology/Topology'

///TODO style
export const StyledPageSelector = styled.input`
	text-align: center;
	transition: all 0.1s ease-in;
	outline:none;
	width: 35px; 
	height: 30px;
	padding: 2px;
	font-size: 14px;
	${BorderRadius()};
	${Border('paginatorInput')};
	${MixinColor('grey700')};

	&:focus, &:hover{
		${BorderRadius()};
		${Border({ color: 'primary' })};
	}
	
`

///TODO style
export const DetailPaginatorWrapper = styled.div`
		width: 225px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		white-space: nowrap;
		letter-spacing: 2px;
		 ${MixinColor('grey700')};

		& input{
			-moz-appearance: textfield;
			font-size: 14px;
		}

		& input[type='number']::-webkit-inner-spin-button,
		& input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	& i{
		${MixinColor('grey700')};
	}
`

///TODO style
export const Placeholder = styled.div`
	height: 30px;
	width: ${({ size }) => size ? size : '70px'};
	${MixinBackground('none')};
`

///TODO style
export const Dropdown = styled.div`
width: 20px;
margin:  0 15px;
text-align: center;
user-select: none;
display:flex;
`

///TODO style
export const StyledWrapper = styled.div`
position:relative;
display: flex;
justify-content:space-around;
align-items: center;
font-size: 14px;

& input{
	-moz-appearance: textfield;
}

   & p{
		white-space: nowrap;
      margin: 0;
   }

	& input[type='number']::-webkit-inner-spin-button,
		& input[type='number']::-webkit-outer-spin-button
		 {
		-webkit-appearance: none;
		-ms-appearance: none;
	}
`
// export const DropdownWrapper = styled.ul`
// position:absolute;
// z-index:999;
// top: 0;
// right: 0;
// background: ${({ theme }) => theme.colors.primaryBackground};
// display: flex;
// flex-direction: column;
// justify-content:center;
// align-items: center;
// min-width: 50px;
// box-shadow:0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
// padding: 5px;
// `

// export const DropdownItem = styled.li`
// list-style-type: none;
// min-width: 45px;
// &:hover{
// 	cursor: pointer;
// }
// `