/**
 * @module services/getFormRestService
 */

import FormRestService from './FormRestService'
import config from 'config'

/**
 * Pobranie instacji FormRestService na podstawie serviceName
 * @method getFormRestService
 * @param {*} serviceName
 * @returns {FormRestService}
 */
export default function getFormRestService(serviceName) {
	if (serviceName && serviceName.startsWith(config.serviceApiVer)) {
		serviceName = serviceName.slice(config.serviceApiVer.length)
	}
	return new FormRestService(config.serviceUrl + serviceName)
}