import React from 'react'
import { useForm } from 'components/form/Form'
import { Buttons } from './buttons/Buttons'

export function ListButtons({ part }) {
	const form = useForm('data')
	const [state, setState] = React.useState(!!form.changedChildForm)

	React.useEffect(() => {
		const interval = setInterval(() => {
			if (state.changed !== !!form.changedChildForm)
				setState(!!form.changedChildForm)
		}, 100)

		return () => clearInterval(interval)
	})

	return <Buttons isList part={part} />
}