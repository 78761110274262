import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core'
import { customElements } from 'cssStatic'

export const StyledInfoWrapper = customElements('o-info-wrapper',['max-height-120','overflow-y-auto'])

///TODO style
export const StyledInfo = withStyles(({ palette }) => ({
   root: {
     margin: '5px 20px',
	  padding: '2px 15px 2px 10px',
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'space-between',
	  flexGrow: 3,
	  backgroundColor: palette.primary.grey700,
	  color: palette.secondary.white,
	  fontWeight: 400,
	  letterSpacing: '1px'
   },
	message: {
		maxWidth: '90%',
		overflowWrap: 'break-word',
	},
	filledInfo: { //Style dla wiadomości typu "Info"
		backgroundColor: palette.primary.mainDarken
	},
	filledSuccess: { //Style dla wiadomości typu "Success"
		backgroundColor: palette.primary.success
	},
	filledError: { //Style dla wiadomości typu "Error"
		backgroundColor: palette.primary.error
	},
	filledWarning: { //Style dla wiadomości typu "Warning"
		backgroundColor: palette.primary.warning
	}

}))(Alert)