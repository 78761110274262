import React from 'react'
import { useForm } from 'components/form/Form'
import { FormMetaObject } from 'components/form/Form'

/**
 * Rysowanie celi grupujacej w grupie
 *
 * @param {*} { group, children }
 * @returns {Node}
 */
export function GroupCell({ group, children }) {
	const form = useForm('data')

	if (group.colIndex === undefined)
		return null

	const child = React.Children.only(children)
	const row = group.rows[0]
	const columnIndex = group.colIndex
	const column = form.columnManager.visibleColumnArray[columnIndex]
	const rowSpan = group.rows.length
	const rowIndex = group.offsetRowIndex

	return (
		<FormMetaObject
			key={rowIndex}
			editMode='edit'
			data={form.rowManager.getDataFromRow(group.rows[0], form._data.ui)}
		>
			{React.cloneElement(child, { row, column, columnIndex, rowIndex, rowSpan })}
		</FormMetaObject>
	)
}