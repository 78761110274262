import { AttributeMeta } from "./ResourceDocument";
import { ValueConverter } from "./ValueConverter";
import { AttributeTypes } from "./APITypes";

const defaultValueConverter = new ValueConverter();

abstract class AttributeValueConverter
{
    valueConverter: ValueConverter;

    constructor(valueConverter: ValueConverter) {
        this.valueConverter = valueConverter;
    }

    abstract convert(attributeMetadata: AttributeMeta, attributeValue: any): string;
}

class GridLikeAttributeValueConverter extends AttributeValueConverter
{
    static createDefault() : GridLikeAttributeValueConverter
    {
        return new GridLikeAttributeValueConverter(defaultValueConverter);
    }

    constructor(valueConverter: ValueConverter) {
        super(valueConverter);
        
    }

    convert(attributeMetadata: AttributeMeta, attributeValue: any): string {
        switch(attributeMetadata.type)
        {
            case AttributeTypes.Ref:
                return this.valueConverter.dtoRefToString(attributeValue);
            case AttributeTypes.Enum:
                return this.valueConverter.dtoEnumToString(attributeValue);
            case AttributeTypes.File:
                return this.valueConverter.dtoFileToString(attributeValue);
            case AttributeTypes.Bool:
                return this.valueConverter.dtoBoolToString(attributeValue);
            case AttributeTypes.String:
                return attributeValue;
            case AttributeTypes.Timestamp:
                return this.valueConverter.dtoTimestampToString(attributeValue);
            case AttributeTypes.Date:
                return this.valueConverter.dtoDateToString(attributeValue);
            case AttributeTypes.Int:
                return this.valueConverter.dtoIntToString(attributeValue);
            case AttributeTypes.Number:
                return this.valueConverter.dtoNumberToString(attributeValue);
            case AttributeTypes.EnumList:
                return this.valueConverter.dtoEnumListToString(attributeValue);
            case AttributeTypes.GuidList:
                return this.valueConverter.dtoGuidListToString(attributeValue);
            default:
                console.error("Unknown attribute type: " + attributeMetadata.type);
                return attributeValue?.toString();
        }
    }
}

export {AttributeValueConverter, GridLikeAttributeValueConverter};