import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Chip, IconButton, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { customElements } from 'cssStatic'

export const StyledTopbar = customElements('o-top-bar', [
	'grid', 'height-60', 'p-right-15', 'p-left-10', 'm-bottom-5', 'f-30',
	{
		'background-color': 'var(--o-mainBackground)',
		'box-shadow': '0 1px 5px 0 rgba(0, 0, 0, 0.3)',
		'color': 'var(--o-grey700)',
		'font-family': 'var(--o-fontFamily)'
	}
])

export const StyledLink = styled(Link)`
	display:flex; 
	justify-content:center;
	align-items:center;
`

//Meterial Styles

export const StyledAvatar = withStyles(({ palette }) => ({
	root: {
		color: 'white !important',
	},
	colorDefault: {
		backgroundColor: '#599ca4',
	},
}))(Avatar)


export const StyledTooltip = withStyles(() => ({
	tooltip: {
		fontSize: '14px'
	},
	popper: {
		marginTop: '-10px',
	}
}))(Tooltip)


export const StyledChip = withStyles(() => ({
	root: {
		border: 'none',
		fontSize: 14
	},
	avatar: {
		width: '26px !important',
		height: '26px !important',
		fontSize: '13px !important',
	}
}))(Chip)

export const StyledIconButton = withStyles(() => ({
	root: {
		fontSize: 14,
		padding: 1
	},

}))(IconButton)

