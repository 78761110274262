import React, { useMemo, useEffect } from "react";
import {
    ContextId,
    EntityId,
    IEntity,
    IEntityContextStateController,
    IGenericAttributes,
    ResourceContextState,
} from '../contexts/data/DataContextModel'; //'contents/cat/contexts/data/DataContextModel';
import {
    ResourceContextsContainerState,
    createDefaultContext,
    useDispatch,
    useTrackedState,
} from '../contexts/data/DataContext';
import {
    BaseRequestOptions, LoadRequestOptions, SaveRequestOptions, RequestInfo, RequestState,
    DeleteRequestOptions
} from '../contexts/data/RequestModel';
import { request } from "http";
import { PerformStateUpdateFunc } from "../contexts/data/DataContextAction";

/** Interfejs definuje metody manipulujące kontekstem. */
export interface DataContextManipulation  {
    setAttribute: (entityId: EntityId, name: string, value: any) => void;
    setAttributes: (entityId: EntityId, values: IGenericAttributes) => void;
    performStateUpdate: (callback: PerformStateUpdateFunc) => void;
    dispatchLoadRequestWithOptions: (options: LoadRequestOptions) => AbortController;
    dispatchUpdateRequestWithOptions: (
        options: SaveRequestOptions, 
        onPerformRequestExecution?: (key: string, state: RequestState) => void,
        onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => void;
    dispatchDeferredUpdateRequestWithOptions: (options: SaveRequestOptions) => void;
    dispatchCreateRequestWithOptions: (
        options: SaveRequestOptions, 
        onPerformRequestExecution?: (key: string, state: RequestState) => void,
        onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => void;
    dispatchDeleteRequestWithOptions: (
        options: DeleteRequestOptions, 
        onPerformRequestExecution?: (key: string, state: RequestState) => void,
        onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => void;
    dispose: () => void;
};

export interface DataContext extends DataContextManipulation  {
    contextState: ResourceContextState;
    getEntity: (entityId: EntityId) => IEntity;
    getLastRequest: () => RequestInfo;
    getLastRequestByKeyStartsWith: (value: string) => RequestInfo;
    hasChangesToUpdate: () => boolean;
};

export const useDataContext = (contextId: ContextId): DataContext => {
    const state: ResourceContextsContainerState = useTrackedState();
    const dispatch = useDispatch();
    const contextState = state.contexts[contextId];

    const contextStateMemo = useMemo(() => {
        return contextState ?? createDefaultContext(contextId);
    }, [contextState, contextId]);

    const behaviourMemo : DataContextManipulation = useMemo(() => {
        return {
            setAttribute: (entityId: EntityId, name: string, value: any) => {
                let values = {};
                values[name] = value;
                dispatch({
                    type: "SET_VALUES",
                    contextId: contextId,
                    entityId: entityId,
                    values: values
                });
            },
            setAttributes: (entityId: EntityId, values: IGenericAttributes) => {
                dispatch({
                    type: "SET_VALUES",
                    contextId: contextId,
                    entityId: entityId,
                    values: values
                });
            },
            performStateUpdate: (callback: PerformStateUpdateFunc) => {
                dispatch({
                    type: "PERFORM_STATE_UPDATE",
                    contextId: contextId,
                    callback: callback
                });
            },
            dispatchLoadRequestWithOptions: (options: LoadRequestOptions): AbortController => {
                const abortController = new AbortController();
                dispatch({
                    type: "PERFORM_REQUEST",
                    contextId: contextId,
                    options: options,
                    abortController: abortController
                });

                return abortController;
            },
            dispatchUpdateRequestWithOptions: (options: SaveRequestOptions, onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => {
                const abortController = new AbortController();
                dispatch({
                    type: "PERFORM_REQUEST",
                    contextId: contextId,
                    options: options,
                    abortController: abortController,
                    onPerformRequestExecution: onPerformRequestExecution,
                    onRequestAction: onRequestAction
                });
            },
            dispatchDeferredUpdateRequestWithOptions: (options: SaveRequestOptions) => {
                const abortController = new AbortController();
                dispatch({
                    type: "PERFORM_REQUEST_DEFERRED",
                    contextId: contextId,
                    options: options,
                    abortController: abortController
                });
            },
            dispatchCreateRequestWithOptions: (options: SaveRequestOptions, onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => {
                const abortController = new AbortController();
                dispatch({
                    type: "PERFORM_REQUEST",
                    contextId: contextId,
                    options: options,
                    abortController: abortController,
                    onPerformRequestExecution: onPerformRequestExecution,
                    onRequestAction: onRequestAction
                });
            },
            dispatchDeleteRequestWithOptions: (options: DeleteRequestOptions, onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => {
                const abortController = new AbortController();
                dispatch({
                    type: "PERFORM_REQUEST",
                    contextId: contextId,
                    options: options,
                    abortController: abortController,
                    onPerformRequestExecution: onPerformRequestExecution,
                    onRequestAction: onRequestAction
                });
            },
            dispose: () => {
                dispatch({type: "DISPOSE", contextId: contextId});
            },
        };
    }, [dispatch, contextId]);

    useEffect(() => {
        if(!contextState?.deferredRequest)
            return;

        dispatch({
            type: "PERFORM_REQUEST",
            contextId: contextState.deferredRequest.contextId,
            options: contextState.deferredRequest.options,
            abortController: contextState.deferredRequest.abortController
        });

    }, [contextState?.deferredRequest])

    return useMemo(() => {
        return {
            contextState: contextStateMemo,
            getEntity: (entityId: EntityId): IEntity =>  {
                const actualEntity: IEntity = contextStateMemo.entityIndex[entityId];
                const draftEntity: IEntity = contextStateMemo.entityDraftIndex[entityId];
                return draftEntity ?? actualEntity;
            },
            getLastRequest: (): RequestInfo =>  {
                if(!contextStateMemo.requests || contextStateMemo.requests.length === 0 )
                    return null;

                return contextStateMemo.requests.at(-1);
            },
            getLastRequestByKeyStartsWith: (value: string): RequestInfo =>  {
                if(!contextStateMemo.requests || contextStateMemo.requests.length === 0 )
                    return null;

                for (let index = contextStateMemo.requests.length - 1; index < contextStateMemo.requests.length; index--) {
                    const request = contextStateMemo.requests[index];
                    if(request.key.startsWith(value))
                        return request;
                }

                return null;
            },
            hasChangesToUpdate: (): boolean => {
                if(contextStateMemo.entityChangesIndex && Object.values(contextStateMemo.entityChangesIndex).length > 0)
                    return true;

                return false;
            },
            ...behaviourMemo
        };
    }, [contextStateMemo, behaviourMemo]);
};

export type DataContextOptions = {
    load?: LoadRequestOptions;
    update?: SaveRequestOptions;
    create?: SaveRequestOptions;
    delete?: DeleteRequestOptions;
    dispose?: boolean;
};

export interface DataContextApi extends DataContext  {
    dispatchLoadRequest: (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => void;
    dispatchUpdateRequest: (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => void;
    dispatchDeferredUpdateRequest: () => void;
    dispatchCreateRequest: (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => void;
    dispatchDeleteRequest: (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void) => void;
};

export const useDataContextApi = (
    contextId: ContextId,
    options: DataContextOptions
): DataContextApi => {
    const dataContext: DataContext = useDataContext(contextId);

    useEffect(() => {
        return () => {
            if(options.dispose)
            {
                console.log("Disposing " + contextId);
                dataContext.dispose();
            }
        }
    }, []);

    useEffect(() => {
        if (options?.load?.fnAsync == null) return;

        const abortController = dataContext.dispatchLoadRequestWithOptions(options.load);

        return () => {
            abortController.abort();
            console.log("[DataContext] Unmount => " + contextId);
        };
    }, [options?.load?.key]);

    return useMemo(() => {
        const dataContextApi: DataContextApi = {
            ...dataContext,
            dispatchLoadRequest: function (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void): void {
                dataContext.dispatchUpdateRequestWithOptions(options.load, onPerformRequestExecution, onRequestAction);
            },
            dispatchUpdateRequest: function (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void): void {
                dataContext.dispatchUpdateRequestWithOptions(options.update, onPerformRequestExecution, onRequestAction);
            },
            dispatchDeferredUpdateRequest: function(): void {
                dataContext.dispatchDeferredUpdateRequestWithOptions(options.update);
            },
            dispatchCreateRequest: function (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void): void {
                dataContext.dispatchCreateRequestWithOptions(options.create, onPerformRequestExecution, onRequestAction);
            },
            dispatchDeleteRequest: function (onPerformRequestExecution?: (key: string, state: RequestState) => void, onRequestAction?: (key: string, state: RequestState, contextStateController: IEntityContextStateController) => void): void {
                dataContext.dispatchDeleteRequestWithOptions(options.delete, onPerformRequestExecution, onRequestAction);
            },
        };

        return dataContextApi;
    }, [dataContext, options]);
};
