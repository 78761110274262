import React from 'react'
import { Group } from './Group'

/**
 * Rysuje wszystkie podgrupy grupy.
 *
 * @param {*} { group, groupChildren }
 * @returns {Node}
 */
export function Groups({ group, groupChildren }) {
	if (!group.groups)
		return null

	return group.groups.map((group, index) => {
		return (
			<Group group={group} key={index}>
				{groupChildren}
			</Group>
		)
	})
}