import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import useForm from 'components/form/hooks/useForm'
import getFormRestService from 'services/getFormRestService'
import FormMetaArray from '../form/FormMetaArray'
import { useTranslate } from 'locale/Locale'
import { Combobox, ComboboxInput, ComboboxMenu } from './Combobox'
import  { RefLinkIcon, makeRefLink, getDataType } from './RefLinkIcon'
import { InputAdornment } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'lodash'
import { GlobalStrings } from 'GlobalStrings'
import { FIELD_REF_LINK_NAME } from '../list/components/filtration/FilterList'

/**
 * Funkcja która zamienia obiekt tablicę obiektów z Formy (form.rows) na tablicę wartości prezentowanych w Comboboxie
 * I ustawia gotową tablicę za pomocą funkcji setOptions()
 * 
 * @param {Array} rows - Wiersze które przychodzą w odpowiedzi z BE (właściwośc z formy - form.rows)
 * @param {Function} setOptions - Funkcja Reactowa do ustawiania stanu. W tym przypadku ustawia tablicę opcji do wyświetlenia
 * @param {String} name - nazwa kontrolki z BE
 */
export const formDataToValues = (rows, setOptions, name, configurationPath, attributesOrder ) => {  //configurationPath - Dodane na potrzeby karteczki 160 z Trello
   const SEPARATOR  = "|"
   if (rows?.length) {
      const options = rows.map(row => {
         if(!row.attributes)
         {
            return ({
               value: row.id,
               name: row.id,
               id: row.id,
            })
         }

         let attributesKeysArray = Object.entries(row.attributes)
         if( attributesOrder != null ){
            let valuesInOrder = attributesOrder[0].split(',');
            attributesKeysArray.sort((a, b) => valuesInOrder.indexOf(a[0]) - valuesInOrder.indexOf(b[0]));
         }
         const objectValuesArray = attributesKeysArray ? Object.values(Object.fromEntries(attributesKeysArray)) : [] //Tablica wszystkich wartości z row.attributes
         let value = ""

         for (const objectValue of objectValuesArray) {
            if (typeof objectValue === 'number')// Ustawiamy w tym miejscu locale na 'fr-FR' ponieważ, założenie jest takie że liczby zmiennoprzecinkowe wyświetlamy w formacie 7 000, 14 000, 14 000, itp. A podane locale dają nam taki efekt
               value = value.concat(parseFloat(objectValue).toLocaleString('fr-FR', { minimumFractionDigits: 2 }).toString(), " ") 
            else if (typeof objectValue === 'object')
               value = value.concat(objectValue.value, " ") //Jeśli objectValue jest obiektem "sklejamy" ze sobą wszystkie właściwości "value"
            else if (typeof objectValue !== 'object' && !Array.isArray(objectValue) && name !== "ProductType")
               value = value.concat(objectValue.toString(), " ") //Jeśli nie jest obiektem ani tablicą, łączymy ze sobą wszystkie wartości
            else if (typeof objectValue !== 'object' && !Array.isArray(objectValue) && name === "ProductType" && configurationPath === "Inquiry/Inquiry/InquiryItem")//dataType & name - Dodane na potrzeby karteczki 160 z Trello
               value = value.concat(` ${SEPARATOR} `, objectValue.toString())
            else if (typeof objectValue !== 'object' && !Array.isArray(objectValue) && name === "ProductType" && configurationPath !== "Inquiry/Inquiry/InquiryItem")
               value = value.concat(objectValue.toString(), " ") //Dodane na potrzeby karteczki 160 z Trello, Sytuacja gdy Combobox ma name = "ProductType" ale jest w innej lokalizacji niż pozycja zapytania
         }

         return ({
            value: value.trim().replace(SEPARATOR, ""),
            name,
            id: row.id,
         })

      })
      setOptions(options)
      return options;
   }
}
/**
 * Komponent kontrolki dla Comboboxa, dodatkowo posiadajacy ikonkę ładowania  gdy dane są w trakcie wczytywania
 * @param {Object} params - Obiekt przychodzący jako parametr, generowany i wymagany przez bibliotekę material-ui
 * @param {Object} inputParams -  Właściwości dla inputa kontrolki pobrane z formy,
 *  podstawowe to: {  name, label, error, placeholder, disabled, required, hasLabel}
 * @param {Boolean} loading - Informacja czy opcje są załadowane czy nie
 * @param {String} refLink - Link do wybranej wartości
 * @param {Object} refIdData - Obiekt z inforamcjami potrzebnymi do wygenerowania id
 */
 export const ComboboxRefInput = ({name,  inputParams, params, loading, refLink, refIdData }) => (
   <ComboboxInput 
      name={name} 
      inputParams={inputParams}
      params={params} 
      InputProps={{
         ...params.InputProps,
         endAdornment: <InputIcon inputParams={inputParams} params={params} loading={loading} refLink={refLink} refIdData={refIdData} /> 
      }}    
   />
)  

/**
 * Komponent renderujący warunkowo ikonkę dla Comboboxa. W zależności od tego czy dostarczono propsy refLink oraz refIdData wyświetlamy ikonkę przejścia
 * do nowej karty, lub ikonkę ładowania
 * @param {Object} inputParams -  Właściwości dla inputa kontrolki pobrane z formy,
 * @param {Object} params - Obiekt przychodzący jako parametr, generowany i wymagany przez bibliotekę material-ui
 * @param {Boolean} loading - Informacja czy opcje są załadowane czy nie
 * @param {String} refLink - Link do wybranej wartości
 * @param {Object} refIdData - Obiekt z inforamcjami potrzebnymi do wygenerowania id
 * @returns {Node}
 */    

 export const InputIcon = ({ inputParams, params, loading, refLink, refIdData}) => (
   refLink && refIdData ? 
   <RefLinkIcon name={inputParams.name} refLink={refLink} refIdData={refIdData} endAdornment={params.InputProps.endAdornment} />
      :
   <LoadingIcon loading={loading} endAdornment={params.InputProps.endAdornment}/>
)


/**
 * Komponent zwracający ikonkę ładowania w zależności od przekazanej wartości zmiennej loading, oraz pozostałe ikonki w odpowiednim ułożeniu
 * @param {Boolean} loading -  Informacja czy opcje są załadowane czy nie
 * @param {Object} endAdornment - Obiekt zawierający elemnty z prawej strony inputa
 * @returns {Node}
 */
 export const LoadingIcon = ({loading, endAdornment}) => (
   <InputAdornment>
      {loading ? <CircularProgress color="inherit" size={20} /> : null}
      {endAdornment.props.children[0]}
      {endAdornment.props.children.filter((el, index) => index !== 0)}
   </InputAdornment>
 )

/**
 * Wrapper dla Kontrolki ComboboxRef. Oplata kontrolkę w komponent FormMetaArray 
 * potrzebny do prawidłowej komunikacji z BE oraz przekazuje w dół propsy
 * 
 * @param {String} name - Nazwa kontrolki z BE
 * @param {Boolean} hasLabel - Informacja czy posiada ona labelkę czy nie
 */

function ComboboxMetaDriven({ name, hasLabel }) {
   const form = useForm('data')
   const field = form.getField(name)

   return (
      <FormMetaArray limit={undefined}>
         <ComboboxRef name={name} field={field} hasLabel={hasLabel} />
      </FormMetaArray>
   )
}

/**
 * Komponent Kontrolki Combobox dla wartośći REF
 * @param {String} name - Nazwa kontrolki z BE
 * @param {Object} field - Obiekt zawierający najważniejsze informacje o danym polu przychodzące z BE
 * @param {Boolean} hasLabel - Informacja czy posiada ona labelkę czy nie
 */

function ComboboxRef({ name, field, hasLabel, ...other }) {
   const form = useForm('data')
   const translate = useTranslate('WebSpa/Inputs/Combobox')
	const translateRef = useTranslate('WebSpa/Snackbar/ComboboxRef')
   const { enqueueSnackbar, closeSnackbar } = useSnackbar()
   
   const configurationPath = field.form.parentForm  && field.form.parentForm._configurationPath //Dodane na potrzeby karteczki 160 z Trello
   
   
   if(name === 'Value' && field?.meta[FIELD_REF_LINK_NAME] !== undefined) {
      name = field?.meta[FIELD_REF_LINK_NAME];
   }


   form.preventRenderErrorModal = true //Powstrzymanie modala z błędem przed pojawieniem się
   
   const href = field.form.getLinkHRef(name)
   const { disabled, required, value, meta, placeholder, error, label, hint } = field
   const [open, setOpen] = React.useState(false)
   const [options, setOptions] = React.useState([])
   const loading = open && options.length === 0
   const noOptionPlaceholderObiect = Object.freeze({
      value: translate('noOptionText'),
      disabled: true,
   })

   let refLink = makeRefLink(name, field)
   const refIdData = {
      name: name,
      dataType: getDataType(name, field),
      inputName: "RefLink",
   }

   const inputParams = {
      name,
      label,
      error,
      placeholder,
      hint,
      disabled,
      required,
      hasLabel,
   }

   const localizations = {
      noOptionsText: translate('noOptionText'),
      loadingText: translate('loadingText'),
      clearText: translate('clearText'),
      closeText: translate('closeText'),
      openText: translate('openText'),
   }

   useEffect(() => {
      let active = true

      if (!loading) {
         return undefined
      }

      if(!isEmpty(field.meta?.refValues)){ // Jeśli BE przysyła tablicę refValues to używamy jej zamiast pobierać wartości z API
         setOptions(field.meta?.refValues)
         return
      }

      (async () => {
         const isOk = await getFormRestService(href).get(form)
     
         if(isOk){
           let attributesParams = new URLSearchParams( href );
           attributesParams = attributesParams.getAll('attributes');

            formDataToValues(form.rows, setOptions, name, configurationPath, attributesParams) //configurationPath - Dodane na potrzeby karteczki 160 z Trello
            if (isEmpty(form.rows)) {//Sytuacja w której BE zwrócił pustą tablicę data - Nie ma rekordów do wyświetlenia
               setOptions([noOptionPlaceholderObiect])
            }
         }
         else { //Obsługa błędów przy pobieraniu danych z BE
            setOptions([ noOptionPlaceholderObiect ])
            
            enqueueSnackbar(translateRef('error'), //TODO Zamienić tekst informacji o błędzie, na tekst opisu błędu przychodzący z BE
            {
               variant: 'error', 
               persist: true, 
               action: key => <Close onClick={() => closeSnackbar(key)} style={{cursor: 'pointer', marginRight: 5, fontSize: '1.3rem' }} />
            })
         }
      })()


      return () => {
         active = false
      }
   }, [loading])

   useEffect(() => {
      if (!open) {
         setOptions([])
      }
   }, [open])


   const handleChange = (e, newValue) => {

      //Odświeżanie formy po wybraniu wartości z inputa
      if (field?.form?.data?.meta?.reloadOnChangeAttributes?.includes(field.name) && newValue) {
         field.form.asyncSubmit(GlobalStrings.reloadOnChangeSubmitType, { triggeredFieldName: name });
      }

      //Sytuacja gdy wartość jest stringiem
      if (newValue && typeof newValue.value === 'string') {
         const valueToSend = { value: newValue.value, id: newValue.id }
         field.handleChange(valueToSend)
         return
      }

      //Sytuacja gdy wpiszemu Nulla
      if (newValue === null && !required) {
         field.handleChange(null)
         return
      }
      return
   }

   return (
      <Combobox
         name={name}
         inputParams={inputParams}
         localizations={localizations}
         value={value}
         open={open}
         options={options}
         loading={loading}
         hiny={hint}
         onOpen={e => {
            if((e.type === 'mousedown' || e.type === 'click') && (e.ctrlKey || e.shiftKey  || e.altKey  || e.metaKey)) {
               setOpen(false)
            }
            else {
               setOpen(true)
            }
         }}
         onClose={() => setOpen(false)}
         getOptionLabel={(option) => option.value || ""}
         getOptionSelected={((option, value) => option.id === value.id)}
         getOptionDisabled={(option) => option.disabled}
         onChange={(e, newValue) => handleChange(e, newValue)}
         renderInput={(params) => (<ComboboxRefInput name={name} inputParams={inputParams} params={params} loading={loading} refLink={refLink} refIdData={refIdData}/>)}
         renderOption={(option, inputValue) =><ComboboxMenu option={option.value} inputValue={inputValue} name={name} /> }
         {...other}
      />
   )
}

//#region Export komponentów
   export default ComboboxMetaDriven
   export { ComboboxRef }
//#endregion

//#region PropTypes & DefaultProps
formDataToValues.propTypes = {
   setOptions: PropTypes.func.isRequired,
   name: PropTypes.string,
   rows: PropTypes.array,
}

ComboboxRefInput.propTypes = {
   params: PropTypes.object.isRequired,
   inputParams: PropTypes.object,
   loading: PropTypes.bool,
   refLink: PropTypes.string,
   refIdData: PropTypes.object,
}

InputIcon.propTypes = {
   params: PropTypes.object.isRequired,
   inputParams: PropTypes.object,
   loading: PropTypes.bool,
   refLink: PropTypes.string,
   refIdData: PropTypes.object,
}

LoadingIcon.propTypes = {
   loading: PropTypes.bool,
   endAdornment: PropTypes.object
}

ComboboxMetaDriven.propTypes = {
   name: PropTypes.string.isRequired,
   hasLabel: PropTypes.bool,
   onChange: PropTypes.func,
}

ComboboxRef.propTypes = {
   name: PropTypes.string,
   field: PropTypes.object.isRequired,
   hasLabel: PropTypes.bool,
   onChange: PropTypes.func,
}

ComboboxRefInput.defaultProps = {
   params: {},
   inputParams: {},
}

InputIcon.defaultProps = {
   params: {},
   inputParams: {},
}

ComboboxMetaDriven.defaultProps = {
   hasLabel: true,
}
//#endregion
