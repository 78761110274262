import React from 'react'
import Input from './Input/Input'
import PropTypes from 'prop-types'
import { trim } from 'lodash'
import useField from 'components/form/hooks/useField'

/**
 * Komponent input number
 *
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @returns {Node}
 */
export default function InputNumber({ name, hasLabel }) {
	const field = useField(name)
	const DEFAULT_PRECISION = 1
	const precision = field?.meta?.precision || DEFAULT_PRECISION
	const stepPrecision = (1 / Math.pow(10, precision)) //Zmienna zawsze będzie zawierać liczbę typu: 0.1 | 0.01 | 0.001 w zależności od wartości precision

	const onValueParse = value => {
		if(!trim(value).length) //Sytuacja gdy kontrolka jest "wyczyszczona" z tekstu
			return null
	
		const localizedValueString = value.replace(/,/g, '.') // Umożliwia wpisywanie liczby z przecinkiem zamiast z kropką
		const parsedValue = parseFloat(localizedValueString) // Ze względu na typ kontrolki wszystkie wartości będą możliwe do parsowania na liczbę

		return parsedValue	
	}

	return (
		<Input
			type='number'
			name={name}
			hasLabel={hasLabel}
			InputProps={{ inputProps: { step: stepPrecision }}}
			onValueParse={value => onValueParse(value)}
		/>
	)
}

InputNumber.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool
}

InputNumber.defaultProps = {
	hasLabel: true
}
