export class GlobalStrings {

    //#region Linki
    /**
     * Link pozwalający na przejście po referencji
     */
    static ResourceGoToRefLinkName = "ref";
    /**
     * Link pozwalający na przejście po referencji
     */
    static LinkFileListParam = "fileList";
    /**
     * Prefix nazwy linku do pobrania pliku
     */
    static DOWNLAD_FILES_LINK_PREFIX = "download";
    /**
     * Prefix nazwy linku do pobrania wszystkich plików
     */
    static DOWNLAD_ALL_FILES_LINK_PREFIX = "allFilesDownload";
    /**
     * Nazwa linku do aktualizacji notyfikacji użytkownika
     */
    static UpdateNotifySourceMessageLinkName = "updateNotifySourceMessage";
    //#endregion

    //#region Nazwy eventów
    /**
     * Nazwa wydarzenia anulowania edycji formularza
     */
    static CancelEventName = "cancel";
    //#endregion

    //#region Requesty
    /**
     * Typ requestu do BE wysyłany w momencie kiedy zmieniliśmy wartość pola oznaczonego flagą "reloadOnChange"
     */
    static reloadOnChangeSubmitType = "reloadOnChange";
    //#endregion

    //#region Typy guzików

    //#region typy uiAction
    /**
     * Typy guzika, po kliknięciu którego wyświetlamy okienko modalne
     */
    static displayModalUpaButtonuiActionType = "DisplayModalUpa";
    /**
     * Typ guzika służący do pobrania plików w innym miejscu niż dany plik występuje w UI (definicja guzika dostarczona z BE)
     */
    static downloadFilesButtonuiActionType = "DownloadFiles";
    //#endregion
    //#endregion
    /**
     * Nazwa typu danych jaką ma obiekt wiadomości, która jest powiadomieniem w postaci ikony
     */
    static NotifySourceMessageDataType = "ObjectInfoMessage";
    /*
     * Typ pola, które po kliknięciu otwiera w nowym oknie link, który jest wartością tego pola
     */
    static FieldUiInputTypeButtonLink = 'ButtonLink';
    /*
    * Typ pola, które po kliknięciu otwiera w nowym oknie link, który jest wartością tego pola
    */
    static FieldUiInputTypeButtonVideoLink = 'ButtonVideoLink';
    /*
    * Typ pola w modalu, w które wpisywane jest hasło
    */
    static FieldUiInputTypeButtonPassword = 'Password';
    /**
     * Nazwa typu danych jaką ma Tlumaczenie maszynowe
     */
    static SpecificationDataType = "Specification";
    /**
     * Nazwa typu CRM
     */
    static CrmPartName = 'AllCRM';
    static CRMCustomerContactsDataType = "CRMCustomerContacts" 
    /**
     * Nazwa okna zwiazanego z CRM - zawiera oferty danego klienta 
     */
    static CrmOffersWindowName = "CRMOffers"
    static OfferCustomerRefFieldName = "CustomerRef";
    static EmptyFilterAttibuteName = "Empty";


    static LinguaLabOrganizationId = '32db0743-f839-4337-8512-0b5b8378df04';
    static PunktOrganizationId = '5cd2f016-db88-48af-b929-e0c8d95378b9';
    static IuridicoOrganizationId = '4330c025-3d1b-4ce0-ad8b-99121886fa6f';
}