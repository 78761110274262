export class LocaleClass{
	constructor(initValues){
		let cultureCode
		let localizedString={}
		let languages=[]
		let defaultLocalizedStrings

		if(initValues){
			if(initValues.CulturePreferences){
				cultureCode=initValues.CulturePreferences.cultureCode
				localizedString=initValues.CulturePreferences.localizedStrings
			}
			if(initValues.Languages)
				languages=initValues.Languages
			if(initValues.defaultLocalizedStrings)
				defaultLocalizedStrings=initValues.defaultLocalizedStrings
		}

		if(!localizedString)
			localizedString={}

		this._cultureCode=cultureCode
		this._localizedStrings=new Map()
		Object.keys(localizedString).forEach(k=>this._localizedStrings.set(k,localizedString[k]))
		this._languages=languages
		this._defaultLocalizedStrings=defaultLocalizedStrings
	}

	get languages(){
		return this._languages
	}

	get currentCultureCode(){
		return this._cultureCode
	}

	get currentLanguage(){
		return this._languages.find(l=>l.cultureCode===this._cultureCode)
	}

	translate(id){
		let res=this._localizedStrings.get(id)

		if(res)
			return res

		if(!this._defaultLocalizedStrings)
			return id

		return this._defaultLocalizedStrings[id]
	}
}