import React from 'react';
import { Launch } from '@material-ui/icons'
import { useTranslate } from 'locale/Locale'
import { InputAdornment } from '@material-ui/core'
import PropTypes from 'prop-types'
import { isEmpty, trim } from 'lodash'
import { GlobalStrings } from 'GlobalStrings';

/**
 * Funckja, która zwraca typ danych dla podanej nazwy pola
 * 
 * @param {String} name - nazwa kontrolki z BE
 * @param {Object} field - Obiekt pola z właściwościamy  
 */
 const getDataType = (name, field) => (
	field?._form?._data?.data[0]?.links[`${GlobalStrings.ResourceGoToRefLinkName}${name}`]?.params?.dataType
)

/**
 * Funkcja, która zwraca typ danych ref dla podanej nazwy pola
 * 
 * @param {String} name - nazwa kontrolki z BE
 * @param {Object} field - Obiekt pola z właściwościamy  
 */
const getRefDataType = (name, field) => (
	field?._form?._data?.data[0]?.links[`${GlobalStrings.ResourceGoToRefLinkName}${name}`]?.rel.slice(3)
)

/**
 * Funkcja powstała na potrzeby karteczki 505 - generująca link dla pola "SupplierPersonRef"
 * @param {Object} field - Obiekt pola z właściwościami  
 * @param {String} refDataType dataType tworzonego refLinku, używany do budowania ścieżki adresu URL
 * @param {String} refDataTypeValue atrybut zawierająy ID danego pola refowego potrzebny do budowania ścieżki adresu URL 
 * @returns {String / undefined}
 */
const makeSupplierPersonRefLink = (field, refDataType, refDataTypeValue) => {
	const fieldFormValues = field.form?.values //Pobieramy cały obiekt "values" z formy zawierający wartości wszystkich pól dla aktualnego widoku 

	if(!isEmpty(fieldFormValues)){
		const SUPPLIER_COMPANY_REF_FIELD_NAME =  'SupplierCompanyRef' //Nazwa pola z formy zawierającego referencje do klienta do którego należy dany dostawca
		const supplierCompanyID = fieldFormValues?.[SUPPLIER_COMPANY_REF_FIELD_NAME]?.id // Pobieramy ID klienta do którego należy dostawca na potrzeby wygenerowania odpowiedniego linku

		//W sytuacji gdy nie mamy ID (pole nie zostało znalezione w formie lub brak ID) zwracamy "undefined" i podnosimy ostrzeżenie, dzięki temu ikonka reflinku nie pojawi się w kontrolce
		if(!isEmpty(trim(supplierCompanyID))){ 
			const COMPANIES_STRING = 'companies' //Pierwsza część linku który tworzymy aby przejść do danego dostawcy
			return `/${COMPANIES_STRING}/${supplierCompanyID}/${refDataType}/${refDataTypeValue}/Main` //Gdy mamy ID klienta, zwracamy odpowiedni link
		}
		else {
			console.warn(`${SUPPLIER_COMPANY_REF_FIELD_NAME} field error. Supplier does not have a company `)
			return undefined
		}
	}
}

/**
 * Funkcja która na podstawie danych generuje link do pola
 * 
 * @param {Object} field - Obiekt pola z właściwościamy  
 * @param {String} name - nazwa kontrolki z BE
 */
//TODO Rozszerzyć funkcjonalność generowania reflinków przez FE o możliwość przechodzenia przez n-tą liczbę zakładek w ramach karteczki 505
const makeRefLink = (name, field) => {
   const refDataType = getDataType(name, field)
   const SUPPLIER_PERSON_REF_FIELD_NAME = 'SupplierPersonRef' // Zmienna zawierająca nazwę zhardcodowanego pola na potrzeby karteczki 505 

   if(!refDataType) {
      //console.error(`RefDataType not found for name ${name}`)
      return undefined
   }

   const refType = getRefDataType(name, field)
   const refDataTypeValue = field?.value?.id

   if(!refDataTypeValue) {
      console.error(`Nie znaleziono wartości dla pola: ${refType}. Funkcja makeRefLink(), z pliku RefLinkIcon.js`)
      return undefined
   }

   if(refType === SUPPLIER_PERSON_REF_FIELD_NAME) {//Zahardcodowana sytuacja dla pola "SupplierPersonRef" znajdującego się w Projekty -> Pozycje Projektu -> Dostawcy
		const supplierPersonRefLink = makeSupplierPersonRefLink(field, refDataType, refDataTypeValue)
		return supplierPersonRefLink 
	}
   
   return `/${refDataType}/${refDataTypeValue}/Main`
}

/**
 * Komponent wyświetlający przycisk z linkiem do wybranej opcji 
 * @param {String} refLink - Link do wybranej wartości
 * @param {Object} refIdData - zawiera dane potrzebne do nadania id
 * @param {Object} endAdornment - Obiekt zawierający elemnty z prawej strony inputa
 */

const RefLinkIcon = ({ refLink, refIdData, endAdornment }) => {
	const translate = useTranslate()

	return (
		<InputAdornment>
			{endAdornment.props.children[0]}
			{
				refLink &&
				<a
					className='combobox-ref-link'
					target="_blank"
					href={refLink}
					title={translate('WebSpa/Inputs/Combobox/seeMore')}
					id={`${refIdData.name}-${refIdData.dataType}-${refIdData.inputName}`}
					
				>
					<Launch />
				</a>
			}
			{endAdornment.props.children.filter((el, index) => index !== 0)}
		</InputAdornment>
	)
}

getDataType.propTypes = {
	name: PropTypes.string,
	field: PropTypes.object,
}

getRefDataType.propTypes = {
	name: PropTypes.string,
	field: PropTypes.object,
}

makeRefLink.propTypes = {
	name: PropTypes.string.isRequired,
	field: PropTypes.object.isRequired,
}

makeSupplierPersonRefLink.propTypes = {
	field: PropTypes.object.isRequired,
	refDataType: PropTypes.string.isRequired,
	refDataTypeValue: PropTypes.string.isRequired,
}

RefLinkIcon.propTypes = {
	refIdData: PropTypes.object,
	refLink: PropTypes.string,
	endAdornment: PropTypes.object,
}

export { RefLinkIcon, makeRefLink, getDataType }
export default RefLinkIcon
