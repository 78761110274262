/**
   ##### Pełna Dokumentacja kontrolki: https://material-ui.com/api/autocomplete/ ######
   Komponent Comboboxa używany do budowania kontrolki Combobox.
   Parametry wymagane to: options - zawierające tablice opcji oraz value 
   Pozwala na dodanie Comboboxa niezależnego od Formy i od BE
   

   Bez tego nadpisania lokalizacja dla inputa jest realizowana poprzez komponent MaterialLocaleThemaProvider
   więcej informacji na ten temat pod linkiem: https://material-ui.com/guides/localization/
   */


import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StyledAutocompleteForCombobox, StyledAutocompleteForComboboxEnumList } from './Combobox.styled'
import { useTextFieldStyles } from 'components/topology/StyledTextField'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { TextField, Chip } from '@material-ui/core'
import toString from 'lodash/toString'
import { InputLabel } from '../input/Input/Input'
import { Tooltip } from 'components/Tooltip'

/**
 * Komponent renderujący Tag używany do wyświetlania wybranych opcji 
 * @param {Function} getTagProps - Funkcja dostarczona wraz z propsem renderTags - pochodząca z biblioteki material-UI 
 * @param {String} value - Tekst wyświetlany w Tagu
 * @param {Function} index - Indeks danego Tagu
 * @returns 
 */
 const ComboboxTag = ({ getTagProps, value, index }) => (
   <Chip
      key={index}
      variant="outlined"
      label={value}
      size="small"
      {...getTagProps({ index })}
   />
)

/**
 * Komponent wyświetlający wszystkie opcje. Pozwala on na "pogrubienie" opcji które zgadzją 
 * się z treścią którą użytkownik wpisuje w kontrolce czyli tzw matchowanie opcji.
 * @param {String} option - wartość wszystkich pasujących do porównania opcji
 * @param {String} inputValue - Aktualna wartość inputa
 */

const ComboboxMenu = ({ option, inputValue, name }) => {

   const matches = match(option, inputValue)
   const parts = parse(option, matches)
   const COMBOBOX_ITEM_NAME = 'ComboboxItem'

   return (
      <div style={{ width: '100%' }} onPointerDown={e => e.stopPropagation()}>
         {
            parts.map((part, index) => {
               const content = part.text ?? ""

               //Eliminujemy tabulatory ze stringa, dzielimy go względem spacji oraz łączymy podzieloną tablicę za pomocą "-", powstaje string wg. wzoru: "a-b-c"
               const idPartFromContent = toString(content?.replaceAll('\t', '')?.split(' ')?.join('-'))

               return ( 
                  <span 
                     key={index} 
                     id={`${COMBOBOX_ITEM_NAME}-${toString(name)}-${idPartFromContent}`} 
                     style={{ fontWeight: part.highlight ? 700 : 400, whiteSpace: 'nowrap' }}
                  >
                     {content.replaceAll('\t', '─\t')}
                  </span>
               )
            })
         }
      </div>
   )
}


/**
 * 
 * @param {Function} name - Nazwa kontrolki
 * @param {Object} params - Obiekt przychodzący jako parametr, generowany i wymagany przez bibliotekę material-ui
 * @param {Object} inputParams -  Właściwości dla inputa kontrolki pobrane z formy,
 *  podstawowe to: {  name, label, error, placeholder, disabled, required, hasLabel}
 */
const ComboboxInput = ({ name, params, inputParams, ...other }) => {

   const { label, error, placeholder, disabled, hasLabel, required, hint } = inputParams ? inputParams : {}
   const { root } = useTextFieldStyles({ hasLabel, error: Boolean(error) })

   const textFieldComponent = (<TextField
      className={root}
      {...params}
      name={name}
      fullWidth
      variant="outlined"
      type="text"
      size="small"
      label={hasLabel && <InputLabel label={label} isRequired={required} />}
      error={Boolean(error)}
      helperText={(error && hasLabel && !disabled) && error}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      InputProps={params.InputProps}
      {...other}
   />);

   if(!hasLabel)
   {
      return textFieldComponent;
   }

   return (
       <Tooltip title={label + (hint ? '- ' + hint : '')}>
           {textFieldComponent}
      </Tooltip>
   )
}
/**
 * 
 * @param {String} name = nazwa pola 
 * @param {Function} onChange = Funkcja wywoływana przy zmianie wartości w kontrolce 
 * @param {Array} options = !!! Tablica zawierająca wszystkie opcje wyświetlane w menu !!!
 * @param {Object} inputParams = Właściwości dla inputa kontrolki pobrane z formy, podstawowe to: {  name, label, error, placeholder, disabled, required, hasLabel}
 * @param {Object} localizations = obiekt dzięki któremu możemy tłumaczenia domyślnych tekstów związanych z Comboboxem, 
 * nazwy właściwości koniecznie muszą odpowiadać nazwą propsów przyjmowanych przez komponent Autocomplete z biblioteki Material Ui
 * Wszystkie dopuszczalne właściwości wypisane w obiekcie propTypes  u dołu tego pliku
 * @param {Function} renderOption = Funkcja która ma zwracać komponent.
 *  Jeśli ta funkcja jest podana menu pojawi się w postaci zwracanego z tej funkcji komponentu,
 *  Jeśli ta funkcja nie jest podana, pojawi się domyślnie w formie opcji jedna pod drugą
 */

const Combobox = ({ name, onChange, inputParams, localizations, options, value, renderOption, isComboboxList, onClick, ...other }) => {

   const { disabled } = inputParams
   const [open, setOpen] = useState(false)
   
   let StyledAutocomplete = StyledAutocompleteForCombobox;
   if (isComboboxList) {
      StyledAutocomplete = StyledAutocompleteForComboboxEnumList;
   }
   
   return (
         <StyledAutocomplete
            name={name}
            options={options}
            openOnFocus//Menu otwiera się po focusie (podczas nawigacji tabulatorem)
            selectOnFocus//Po kliknięciu w Comboboxa wartośc która w nim jest zostaje "wybrana", pozwala to na szybkie kasowanie wybranego rekordu
            handleHomeEndKeys//Pozwala na obsługę klawiszy Home oraz End aby przejść odpowiednio na poczatek lub koniec listy
            disabled={disabled}
            value={value || null}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={e => {
               if((e.type === 'mousedown' || e.type === 'click') && (e.ctrlKey || e.shiftKey  || e.altKey  || e.metaKey)) {
                  setOpen(false)
               }
               else {
                  setOpen(true)
               }
            }}
            renderInput={(params) => <ComboboxInput name={name} params={params} inputParams={inputParams} />}
            renderOption={(option, { inputValue }) => {
               if (renderOption) return renderOption(option, inputValue)
               else return option
            }}
            getOptionSelected={(option, value) => {
               if (value === option) return true
            }}
            onChange={(e, newValue, reason) => {
               if ((reason === 'clear') && (e.ctrlKey || e.shiftKey  || e.altKey  || e.metaKey)) return
               
               if (onChange) return onChange(e, newValue)
               
               else return
            }}
            {...localizations}
            {...other}
         />
   )
}

Combobox.propTypes = {
   options: PropTypes.array.isRequired,
   value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.any
   ]),
   name: PropTypes.string,
   onChange: PropTypes.func,
   inputParams: PropTypes.object,
   localizations: PropTypes.shape({
      clearText: PropTypes.string,//Tekst wyświetlany w popupie po najechaniu myszką na ikonkę "krzyżyka" w kontrolce która czyści zawartość Comboboxa
      closeText: PropTypes.string,//Tekst wyświetlany w popupie po najechaniu myszką na ikonkę "strzałki" w górę która zamyka Comboboxa
      openText: PropTypes.string,//Tekst wyświetlany w popupie po najechaniu myszką na ikonkę "strzałki" w dół która otwiera Comboboxa
      getLimitTagsText: PropTypes.string,//Tekst wyświetlany w przypadku kontrolki pozwalającej na wybranie więcej niż jednej opcji (z propsem multiple)
      loadingText: PropTypes.oneOfType([ //Tekst wyświetlany w rozwijanym menu Comboboxa podczas ładowania danych
         PropTypes.string,
         PropTypes.node,
      ]),
      noOptionsText: PropTypes.oneOfType([ //Tekst wyświetlany w rozwijanym menu Comboboxa podaczas gdy wpisywany przez nas tekst nie pasuje do żadnej z dostępnych w menu opcji
         PropTypes.string,
         PropTypes.node,
      ]),
   }),//Więcej informacji o powyższych właściwościach w dokumentacji: https://material-ui.com/api/autocomplete/
   renderOption: PropTypes.func,
   onClick: PropTypes.func,
}

ComboboxTag.propTypes = {
   getTagProps: PropTypes.func,
   value: PropTypes.string,
   index: PropTypes.number, 
}

ComboboxInput.propTypes = {
   name: PropTypes.string,
   params: PropTypes.object,
   inputParams: PropTypes.object,
}

ComboboxMenu.propTypes = {
   option: PropTypes.string.isRequired,
   inputValue: PropTypes.string.isRequired,
}

Combobox.defaultProps = {
   options: [],
   value: '',
   renderOption: undefined,
   inputParams: {},
}

ComboboxInput.defaultProps = {
   inputParams: {},
}

ComboboxMenu.defaultProps = {
   option: "",
   inputValue: "",
}

export { Combobox, ComboboxInput, ComboboxMenu, ComboboxTag }
export default Combobox