import React from "react"
import useForm from "../../hooks/useForm"
import joinClassNames from "../../../joinClassNames"
import PropTypes from "prop-types"
import "./Preloader.scss"

const Spiner = () => (
	<div>
		<div>
			<div id="bars6">
				<span></span>
				<span></span>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	</div>
)

/**
 * Komponent preloadera
 *
 * @returns {Node}
 */
export default function Preloader({ className, children, ...other }) {
	const form = useForm('loading')

	return (
		<div className='o-preloader'>
			<div className={form.isLoading ? 'blur' : ''}>
				{children}
			</div>

			{form.isLoading && (
				<div
					className={joinClassNames(
						'o-preloader-spinner',
						className
					)}
					{...other}
				>
					<Spiner />
				</div>
			)}
		</div>
	)
}

Preloader.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
}
