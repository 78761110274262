import styled from 'styled-components'
import logoBig from 'icons/logo/logo.svg'
import { MixinMargin, MixinFont, MixinBackground } from 'components/topology/Topology'
import { customElements } from 'cssStatic'

export const LoginFormWrapper = customElements('o-login-form-wrapper', ['grid-ver', 'grid-gapy-35', 'width-450'] )

export const StyledLoginWrapper = styled.main`
	height: 100vh;
	display: grid;
	grid-template-columns: 3fr 8fr;
	grid-template-rows: 1fr 1fr;
	${MixinFont('s-md')};
	grid-row-gap: 40px;
	grid-template-areas: "aside content" "aside content";
	${MixinBackground('primaryDarken900')};
`

export const StyledLoginAside = customElements('o-login-aside', [
	'white', 'grid', 'grid-acy-center', 'grid-acx-center', 'p-lr-30', 'grid-aix-start',
	{
		'grid-area': 'aside',
		'background-color': 'var(--o-primaryDarken900)',
		'color': '#EDEDED',
		//${MixinBackground('primaryDarken900')};
		//${MixinColor('grey100')};
		'& > h3': [ 'f-weight-400', 'letter-spacing-1','m-tb-25', {
			'font-size': '2.3rem',
		}]
	}
])

///TODO style
export const StyledFormWrapper = styled.section`
	grid-area: content;
	/* background-image: url(${logoBig});
	background-repeat: no-repeat;
	background-size: 60%; */
	background-color: ${({ theme }) => theme.colors.primaryBackground};
	background-position: 65% 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 9%;
	clip-path: circle(100% at 90% 50%);

	& > div {
		/* width: 50%; */

		& > h1 {
			font-weight: 400;
			font-size: 1.9rem;
		 	${MixinMargin(['none', 'none', '15px', '0'])};
		}
	}
`

///TODO style
export const StyledLoginHeader = styled.h1`
	letter-spacing: 1px;
::first-letter{
	/* font-size:140%; */
	/* color: ${({ theme }) => theme.colors.primary}; */
}
`
