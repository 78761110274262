import { useAppCtrl, appCtrl } from 'AppCtrl'

/**
 * Pobranie locali.
 * Żeby dokonać tłumaczenia:
 * @example
 * const locale=useLocale()
 * const title=locale.translate('WebSpa/MainMenu/MyTasks/title')
 *
 * @returns {Locale}
 */
export function useLocale() {
	return useAppCtrl().locale
}

/**
 * Pobranie funkcji translatora
 * @example 
 * const translate=useTranslate('WebSpa/MainMenu/MyTasks')
 * const title=translate('title')
 * 
 * //równoważne:
 * 
 * const locale=useLocale()
 * const title=locale.translate('WebSpa/MainMenu/MyTasks/title')
 * 
 * @param {String} path Poprzedzająca scieżka translacji. (nie dodawać na koncu '/') 
 * @returns {Function} Funkcja tlumacząca
 */
export function useTranslate(path) {
	const app = useAppCtrl()

	if (!path)
		path = ''

	return id => app.locale.translate(path + '/' + id)
}

/**
 * Bezpośrednie wywołanie funkcji translatora
 */
export function translate(path) {
	return appCtrl.locale.translate(path)
}