import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { MouseEventHandler } from 'react'


export type SidebarContentHeaderProps = {
    title: string,
    onCloseClick: (event: any) => void
}

const SidebarContentHeader = ({title, onCloseClick}: SidebarContentHeaderProps) => {
    return (
        <Box>
            <Typography variant="overline" display="block" style={{fontWeight: 'bolder', color: '#316197', display: 'inline-table'}} gutterBottom>
                {title}
            </Typography>
            <CloseIcon style={{float: 'inline-end', cursor: 'pointer'}} onClick={onCloseClick} />
        </Box>
    );
};

export default SidebarContentHeader;