import { Tooltip, DialogContent, withStyles } from '@material-ui/core'
import styled from 'styled-components'
import NewIcons from 'components/icon/NewIcons'
import { customElements } from 'cssStatic'

///TODO style
export const StyledIcon = styled(NewIcons)`
   background: ${({ theme }) => theme.colors.grey400};
   color: white;
   position: absolute;
   top: 0;
   right: 0;
   height: 100%;
   width: 40px;
   border-radius: 0 4px 4px 0;
   cursor: not-allowed;
   padding: '3px 8px';
   border-color:${({ theme }) => theme.colors.grey400};
   display:flex;
   justify-content: center;
   align-items: center;
   z-index: 100;

   ${({ error, theme }) => error && `background: ${theme.colors.error}; cursor: not-allowed`};
   ${({ disabled, theme }) => !disabled && `background: ${theme.colors.primaryDarken800}; cursor: pointer`};
`

export const StyledDialogTitle = customElements('o-nip-dialog-title', [
	'grid-hor', 'grid-acx-space-between', 'grid-acy-center', {
		padding: '15px 24px'
	}
])

// export const StyledDialogTitle = styled.div`
//    display: flex;
//    justify-content: space-between;
//    align-items: center;
//    padding: 15px 24px;
// `

///TODO style
export const StyledTooltip = withStyles((theme) => ({
	tooltip: {
		bottom: -15,
		fontSize: 13,
	},
}))(Tooltip)

///TODO style
export const StyledDialogContent = withStyles(({ palette }) => ({
	root: {
		minWidth: 400,
		minHeight: 200,
		position: 'relative',
	}
}))(DialogContent)
