import React from 'react'
import useForm from 'components/form/hooks/useForm'
import RefreshableContext from 'components/refreshableContext/RefreshableContext'
import {
	StyledWrapper,
	Dropdown,
	StyledPageSelector
} from './Paginators.styled'
import Data from './PaginatorClass'
import { useTranslate } from 'locale/Locale'

const context = RefreshableContext.create()

/**
 * Klasa sterująca
 * @function handleOpen - funkcja sterujaca otwieraniem i zamykaniem Dropdowna z opcjami
 * @function handleClick - funkcja ustawiajaca właściwość limit dla formy
 * @function createOptions - funkcja która na podstawie przekazanych parametrów tworzy tablicę elementów (opcji)
 */
// class Data {
//    constructor() {
//       this.max = 30 //edytować tę wartość gdy chcemy zmienić maksymalny zakres wyświetlanych opcji 
//       this.step = 5 //Edytować tę wartość gdy chcemy zmienić przerwę między opcjami
//    }
//    onRenderProvider({ isOpen, setOpen, form }) {
//       this.isOpen = isOpen
//       this.setOpen = setOpen
//       this.limit = form.limit
//       this.form = form
//    }
//    /**
//     * 
//     * @param {Bool} value - funkcja sterująca otwieraniem i zamykaniem Dropdowna z opcjami 
//     */
//    handleOpen(value) {
//       this.setOpen(value)
//    }
//    /**
//     * @param {Number} value - nowa wartość  właściwości limit 
//     */

//    /**
//     * 
//     * @param {Number} value - funkcja zmieniajaca wartość limit oraz currentPage w formie oraz odświeżająca całą formę 
//     */
//    handleClick(value) {
//       this.form.limit = value
//       this.form.currentPage = 1 //Zerowanie strony na której się obecnie znajdujemy
//       this.form.asyncLoad()
//    }
//    /**
//     * Funkcja która zwraca tablicę z opcjami na podstawie właściwości max oraz step z konstruktora
//     */
//    createOptions() {
//       const options = []
//       for (let i = this.step;i <= this.max;i += this.step) {
//          options.push(i)
//       }
//       return options
//    }
// }

/**
 * Funkcja wyświetlająca ikonkę oraz Dropdowna z opcjami
 */
// const DropDownIcon = () => {

//    const data = context.useContext()
//    const { isOpen } = data
//    return (
//       <>
//          <Icon name="arrow_drop_down" onClick={() => data.handleOpen(!isOpen)} />
//          {
//             isOpen && <DropDownContent />
//          }
//       </>
//    )
// }

/**
 * Funkcja iterująca po tablicy z opcjami i zwracająca wszystkie opcje w liście 
 */
// const DropDownContent = () => {
//    const data = context.useContext()
//    const options = data.createOptions()

//    return (
//       <DropdownWrapper onMouseLeave={() => data.handleOpen(false)}>
//          {
//             options.map((option, index) => {
//                return (
//                   <DropdownItem key={index} onClick={() => {
//                      data.handleClick(option)
//                      data.handleOpen(false)
//                   }}>{option}</DropdownItem>
//                )
//             })
//          }
//          <DropdownItem onClick={() => {
//             data.handleClick(null) //Powoduje wyświetlenie na raz wszystkich opcji
//             data.handleOpen(false)
//          }} >
//             All
//          </DropdownItem>
//       </DropdownWrapper>
//    )
// }

/**
 * Wrapper dla komponentu RowsPerPage
 */
const RowsPerPageWrapper = () => {
	const data = context.useContext()
	const translate = useTranslate('WebSpa/Placeholders')

	const { rowsOnOnePage, rowValue, pages } = data

	return (
		rowsOnOnePage > 1 || pages > 1 ?
			<StyledWrapper>
				<p>{translate('paginator')}</p>
				<Dropdown >
					<StyledPageSelector type="number" value={rowValue} onChange={(e) => {
						data.handleRowChange(e.target.value)
					}}
						onKeyPress={(e) => {
							data.handleRowsKeyPress(e)
						}}
					/>
					{/* <DropDownIcon /> */}
				</Dropdown>
			</StyledWrapper>
			:
			null
	)
}

/**
 * Funkcja oplatająca komponent w Context, oraz udostępniająca metody do obsługi otwierania i zamykania Dropdown'a
 */
export default function RowsPerPage() {
	// const [isOpen, setOpen] = useState(false)
	const form = useForm('data')
	return (
		<context.Provider
			fixedData
			// isOpen={isOpen}
			// setOpen={setOpen}
			form={form}
			createData={() => new Data()}
		>
			<RowsPerPageWrapper />
		</context.Provider>
	)
}