/**
 * Moduł komunikacji z serwerem Http poprzez protokół REST
 * @module services/RestService
 */

import HttpService from './HttpService'

/**
 * Klasa komunikacyjna z serwerem Http poprzez protokół REST
 * @class RestService
 */
export default class RestService extends HttpService {
	/**
	 * Http GET
	 * 
	 * @param {*} urlPath
	 * @param {*} parameters
	 * @param {*} payload
	 * @returns {*}
	 * @method GET
	 */
	 async get(urlPath, parameters, payload) {
		return await super.get(urlPath, parameters, payload)
	}
	
	/**
	 * Http PATCH
	 *
	 * @param {string} urlPath
	 * @param {Object} parameters
	 * @param {Object} payload
	 * @returns {Object}
	 * @method PATCH
	 */
	async patch(urlPath, parameters, payload) {
		return await super.patch(urlPath, parameters, payload)
	}

	/**
	 * Http POST
	 *
	 * @param {string} urlPath
	 * @param {Object} parameters
	 * @param {Object} payload
	 * @returns {Object}
	 * @method POST
	 */
	async post(urlPath, parameters, payload) {
		return await super.post(urlPath, parameters, payload)
	}

}
