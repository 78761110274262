import React from 'react'
import { Group, Elements, Observers } from 'components/positionObserver/PositionObserver'
import { customElements } from 'cssStatic'
import { context } from './Context'
import { LeftObserved, RightObserved } from './Observed'
import { ZipLeft, ZipRight } from './Zip'
import Button from 'components/form/components/list/components/buttons/Button'
import { useTranslate } from 'locale/Locale'
import { Tooltip } from '@material-ui/core'

const StyledZipListContent = customElements('o-zip-list-content', ['grid-hor', 'grid-gap-60', 'overflow-hidden', 'relative'])
const StyledZipList = customElements('o-zip-list', ['grid-ver', 'overflow-hidden', 'grid-acy-start'])
const StyledZipTop = customElements('o-zip-top', ['grid-hor', 'grid-acx-space-between'])
const StyledZipButtons = customElements('o-zip-buttons', ['grid-hor', 'grid-acx-start', 'grid-acy-center', 'grid-gap-5'])
const StyledZipButton = customElements('o-zip-button', ['f-26', 'fi-google', 'p-5', 'cursor-pointer', 'transition-5', {
	'&:hover': {
		'background-color': '#f9f9ff'
	},
	'color': '#336699'
}])

export function ZipListContent({ additionalButtons, onSave, readOnly }) {
	const data = context.useContext()
	const translate = useTranslate('WebSpa/Buttons')
	const translateZip = useTranslate('WebSpa/ZipList')

	return (
		<Group>
			<Elements>
				<StyledZipList>
					<StyledZipTop>
						<StyledZipButtons>
							{additionalButtons && additionalButtons()}
							{
								readOnly || <>
									 { /* <Tooltip title={translateZip('link')}>
										<StyledZipButton
											onClick={() => { data.link() }}
										>
											link
										</StyledZipButton>
									</Tooltip> */ }
									<Tooltip title={translateZip('unlink')}>
										<StyledZipButton
											onClick={() => data.unlink()}
										>
											link_off
										</StyledZipButton>
									</Tooltip>
									<Tooltip title={translateZip('auto')}>
										<StyledZipButton
											onClick={() => data.autoLink()}
										>
											hdr_auto
										</StyledZipButton>
									</Tooltip>
								</>
							}
						</StyledZipButtons>
						{
							(readOnly || !data.changes) ? null :
								<Button
									type='submit'
									id={'ZipList-ButtonSubmit'}
									onClick={() => onSave?.(data)}
									content={translate('submitButton')}
								/>
						}
					</StyledZipTop>
					<StyledZipListContent>
						<ZipLeft onDoubleClick = { data.link() }/>
						<ZipRight />
						<Observers>
							{data.left.filter(v => v.next && v._nextFirst && v._nextLast).map(v => <LeftObserved key={v.id} id0={v.id} id1={v._nextFirst} id2={v._nextLast} />)}
							{data.right.filter(v => v.next && v._nextFirst && v._nextLast).map(v => <RightObserved key={v.id} id0={v.id} id1={v._nextFirst} id2={v._nextLast} />)}
						</Observers>
					</StyledZipListContent>
				</StyledZipList>
			</Elements>
		</Group>
	)
}