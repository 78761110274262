import SegmentElementType from "../../model/SegmentElementType";

export const getTagsFromAllowedTagsArrayObjects = (allowedTags) => {
    let result = []
    let tags = allowedTags.allowedTags
    tags.forEach((item, index) => {
        if (item._typeName === SegmentElementType.StartTag) {
            result.push(
                {
                    name: item._crossFormatId + ' START'
                },
            )
        }
        else if ( item._typeName === SegmentElementType.EndTag){
            let pairedTag = allowedTags.sourceTags.find(i => i._anchor==item._anchor && i._typeName.includes(SegmentElementType.StartTag))
            result.push(
                {
                    name: pairedTag._crossFormatId + ' END'
                },
            )
        }
        else
        {
            result.push(
                {
                    name: item._crossFormatId
                },
            )
        }
    })
    return result
}

export const getTagObjectFromTagName = (name, tags) => {
    let tagId = null;
    const indexOfSpace = name.indexOf(' ');
    if(indexOfSpace === -1)
        tagId = name;
    else
        tagId = name.substr(0, indexOfSpace);

    for (let index = 0; index < tags.sourceTags.length; index++) {
        const sourceTag = tags.sourceTags[index];

        if(tagId === sourceTag.crossFormatId)
        {
            if(sourceTag.isStartTag && name.includes('START'))
                return sourceTag;
            else if(sourceTag.isStandaloneTag)
                return sourceTag;
        }
        else if(name.includes('END') && sourceTag.isEndTag)
        {
            const startTag = tags.sourceTags.find(tag => tag.isStartTag && tag.anchor === sourceTag.anchor);
            if(startTag && startTag.crossFormatId === tagId)
            {
                return sourceTag;
            }
        }
    }

    return null;
}
