import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'components/form/Form'
import { useTranslate } from 'locale/Locale'
import Button from './Button'

function isVisible(form, isList) {
	if (!isList)
		return form.serviceDelete && !form.isChanged

	const marksData = form.rowManager.marksOrSelectedDatas

	if (!marksData.data.length)
		return false

	return !marksData.data.some(row => !row.links?.delete)
}

/**
 * Przycisk Delete pozwalający na usunięcie konkretnego obiektu
 * @param {Node} content -  Zawartość przycisku, może nim być tekst lub ikona, jeśli go nie przekażemy, zostanie wyświetlony domyślny tekst dla tego przycisku 
 * @returns Przycisk typu Delete, pozwalający na usunięcie danego obiektu w którym się aktualnie znajdujemy
 */
export function ButtonDelete({ isList, content }) {
	const form = useForm()
	const translate = useTranslate('WebSpa/Buttons')

	//Mock potrzebny do tego aby przycisk zawsze korzystał z modala z potwierdzeniem z pliku ButtonWithModal.js 
	//z domyślnymi wartościami dla przycisku o type='delete'
	const button = { confirmation: {} }

	if (!isVisible(form, isList))
		return null

	return (
		<>
			<Button
				type='delete'
				id={`${form._dataType ?? ''}-ButtonDelete`}
				content={content || translate('cancel')}
				button={button} 
				onClick={() => form.asyncSubmit('delete') }
			/>
		</>
	)
}

ButtonDelete.propTypes = {
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.elementType
	]),
}