import React from 'react'
import useForm from '../hooks/useForm'
import { Redirect } from 'react-router-dom'

/**
 * Komponent przekierowania do logowania jesli brak uprawnień
 *
 * @returns {Node}
 */
export default function RedirectToLogin() {
	const form = useForm('data')

	if (form.errorFromService && form.errorFromService.status === 401)
		return (<Redirect to="/login" />)
	return null
}