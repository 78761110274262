import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'locale/Locale'
import Button, { ButtonsFactoryClass } from './Button'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { appCtrl } from 'AppCtrl'
import { toString } from 'lodash'


/**
 * Funkcja zwracająca obiekt {content, title} używany do wyświetlenia  zawartości tekstowej modala
 * 
 * @param { String } buttonType - Typ buttona
 * @param { Object } button - Obiekt buttona
 * @returns 
 */
const mapModalContentByButtonType = (buttonType, button) => {

	const buttonTypes = ButtonsFactoryClass.TYPES

	switch(buttonType){
		case buttonTypes.DELETE: 
			return { 
				content: toString(button?.confirmation?.confirmOperationText) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/Delete/content')), 
				title: 	toString(button?.confirmation?.confirmationTitle) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/title')) 
			}
		case buttonTypes.SUBMIT:
			return { 
				content: toString(button?.confirmation?.confirmOperationText) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/Submit/content')), 
				title: 	toString(button?.confirmation?.confirmationTitle) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/title')) 
			}
		case buttonTypes.CANCEL:
			return { 
				content: toString(button?.confirmation?.confirmOperationText) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/Cancel/content')), 
				title: 	toString(button?.confirmation?.confirmationTitle) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/title')) 
			}
		default: 
			return { 
				content: toString(button?.confirmation?.confirmOperationText) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/content')), 
				title:	toString(button?.confirmation?.confirmationTitle) || toString(appCtrl?.locale?.translate('WebSpa/ConfirmationModal/title')) 
			}
	}
}

/**
 * Komponent zwracający okno modalne z potwierdzeniem operacji
 * 
 * @param { Object } button - Obiekt buttona
 * @param { String } buttonType - Typ buttona
 * @param { Boolean } open - Informacja o tym czy modal jest otwarty
 * @param { Function } setOpen - Funkcja sterująca otwarciem modala
 * @param { Function } buttonAction - Funkcja onClick przypisana do pierwotnego buttona
 * @returns { Node }
 */
const ButtonModal = ({ button, buttonType, open, setOpen, buttonAction }) => {

	const translateConfirmationModal = useTranslate('WebSpa/ConfirmationModal')
	const modalContentObject = mapModalContentByButtonType(buttonType, button) ?? ''

   return (
		<Dialog open={open} onBackdropClick={() => setOpen(false)}>
			<DialogTitle>
				{modalContentObject.title}
			</DialogTitle>
			<DialogContent dividers>
				{modalContentObject.content}
			</DialogContent>
			<DialogActions>
				<Button
					type="submit"
					content={translateConfirmationModal('agreeButton')}
					onClick={ (e) =>{buttonAction && buttonAction(e); setOpen(false) } }
				/>
				<Button
					type="cancel"
					content={translateConfirmationModal('disagreeButton')}
					onClick={() => setOpen(false)}
					style={{ padding: '6px 16px' }}
				/>
			</DialogActions>
		</Dialog>
	)
}

/**
 * Komponent używający patternu "Render props" z Reacta. Pozwala on na współdzielenie logiki pomiędzy różnymi komponentami buttonów
 * Renderuje on przycisk (za pomocą propsa "render") wraz z obsługą okienka modalnego, oraz przekazuje funkcję sterującą otwarciem modala 
 * za pomocą propsa "render".
 * 
 * @param { Object } button 
 * @param { Object } buttonType 
 * @param { Object } render 
 * @returns 
 */
export const ButtonWithModal = ({ button, buttonType, render, buttonAction }) => {
   const [open, setOpen] = useState(false)

   if(!render){
      console.error('Nie przekazano propsa "render" - nie można wyświetlić modala z przyciskiem')
      return null
   }

   return (
      <>
        	<ButtonModal open={open} setOpen={setOpen} buttonAction={buttonAction} button={button} buttonType={buttonType} />
         {render(setOpen)}
      </>
   )
}


//#region PropTypes 
ButtonWithModal.propTypes = {
	button: PropTypes.object,
	buttonType: PropTypes.string,
	render: PropTypes.func,
}

ButtonModal.propTypes = {
	button: PropTypes.object, 
	buttonType: PropTypes.string, 
	open: PropTypes.bool, 
	setOpen: PropTypes.func, 
	buttonAction: PropTypes.func, 
}

mapModalContentByButtonType.propTypes = {
	buttonType: PropTypes.string,
	button: PropTypes.object,
}

//#endregion