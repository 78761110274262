import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Chip, Grid, Typography, Box, styled  } from '@material-ui/core'
import { splitFilterStringValueBySpacesAndQuotes } from '../list/components/filtration/FilterButton';
import { useField } from 'components/form/Form';
import { useTextFieldStyles } from 'components/topology/StyledTextField';
import { InputLabel } from './Input/Input';


const ListItem = styled('li')(({ theme }) => ({
	margin: theme.spacing(0.5),
  }));

/**
 * Komponent input email
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @returns {Node}
 */
export default function InputTags({ name, hasLabel }) {
	const field = useField(name);

	let parsedArray = [];
	if (field.value) {
		if (Array.isArray(field.value))
			parsedArray = field.value;
		else
			parsedArray = field.value.split(',');
	}
	//const [values, setValues] = useState(fieldValues);

	const { label, error, placeholder, disabled, required } = field
	const { root } = useTextFieldStyles({ hasLabel, error: Boolean(error) })

	return (
		<TextField
			className={root}
			required={required}
			disabled={disabled}
			type='text'
			name={name}
			variant='outlined'
			fullWidth
			size='small'
			style={{listStyle: 'none'}}
			label={hasLabel && <InputLabel label={label} isRequired={required} />}
			hasLabel={hasLabel}
			InputProps={{
				startAdornment: (<div>
					{parsedArray?.map((data, index) => {
						let label = data;
						return (
							<ListItem key={index}>
								<Chip
									variant="outlined"
									label={label}
									size="small"
									onDelete={() => { 
										field.handleChange(parsedArray.filter(v => v !== data));
									}}
								/>
							</ListItem>	
						);
					})}
				</div>
				)
			}}
			onBlur={(e) => {
				const savedValues = parsedArray !== undefined ? parsedArray : [];
				let inputValue = e.target.value; 
				if ( inputValue !== '' && !savedValues?.includes(inputValue)) {
					savedValues.push(inputValue);
					field.handleChange(savedValues);
				}
				e.target.value = '';
			}}
			InputLabelProps={{ shrink: true }}
		/>
	)
}

InputTags.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool
}

InputTags.defaultProps = {
	hasLabel: true
}
