import React from 'react'
import { useForm } from 'components/form/Form'
import Icon from 'components/icon/Icon'

export function GroupButtonAdd({ rowIndex, onClickGroupBreakLine }) {
	const form = useForm('data')

	if (!form.parentForm || !form.parentForm.serviceCreate)
		return <div />

	return (
		<Icon
			name="add"
			md={16}
			style={{ justifySelf: 'right', margin: 3}}
			onClick={e => {
				onClickGroupBreakLine && onClickGroupBreakLine({ rowIndex })
				e.stopPropagation()
			}}
		/>
	)
}