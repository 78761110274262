import styled, { css } from "styled-components"
import { GetFromTheme } from './GetFromTheme'
import { customElements } from 'cssStatic'
import React from 'react'

/**
 * Mixin tworzący kawałek css dla gridu
 * @param {*} tx grid-template-columns
 * @param {*} ty grid-template-rows
 * @param {*} gx grid-column-gap
 * @param {*} gy grid-row-gap
 * @param {*} aix ustawienie dziecka w kierunku x: start | end | center | stretch
 * @param {*} aiy ustawienie dziecka w kierunku y: start | end | center | stretch
 * @param {*} acx ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
 * @param {*} acy ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
 * @param {*} x grid-auto-columns Rozmiary column: auto|max-content|min-content|length
 * @param {*} y grid-auto-rows Rozmiary wierszy:  auto|max-content|min-content|length
 * @param {*} f grid-auto-flow Kierunek wypełniania grida celami : row|column|dense|row dense|column dense
 * @return {Array} 
 */
export function MixinGrid({ tx, ty, gx, gy, aix, aiy, acx, acy, x, y, f, mw, height }) {
	return css`
		display: grid;
		grid-template-columns: ${tx};
		grid-template-rows: ${ty};
		grid-column-gap: ${props => GetFromTheme(props, 'gapX', gx)};
		grid-row-gap: ${props => GetFromTheme(props, 'gapY', gy)};
		justify-items: ${aix};
		align-items: ${aiy};
		justify-content: ${acx};
		align-content: ${acy};
		grid-auto-columns: ${x};
		grid-auto-rows: ${y};
		grid-auto-flow: ${f};
		max-width: ${mw};
		height: ${height};
	`
}

/**
 * Mixin tworzący kawałek css dla dziecka grid'a
 * @param {*} aix ustawienie dziecka w kierunku x: start | end | center | stretch
 * @param {*} aiy ustawienie dziecka w kierunku y: start | end | center | stretch
 * @param {*} colStart kolumna startowa :<number> | <name> | span <number> | span <name> | auto
 * @param {*} colEnd kolumna końcowa :<number> | <name> | span <number> | span <name> | auto
 * @param {*} rowStart wiersz startowa :<number> | <name> | span <number> | span <name> | auto
 * @param {*} rowEnd wiersz końcowa :<number> | <name> | span <number> | span <name> | auto
 * @return {Array} 
 */
export function MixinGridItem({ aix, aiy, colStart, colEnd, rowStart, rowEnd, colSpan, rowSpan }) {
	return css`
		justify-self: ${aix};
		align-self: ${aiy};
		grid-column-start: ${colStart};
		grid-column-end: ${colEnd};
		grid-row-start: ${rowStart};
		grid-row-end: ${rowEnd};
		grid-column-end: ${colSpan ? 'span ' + colSpan : undefined};
		grid-row-end: ${rowSpan ? 'span ' + rowSpan : undefined};
	`
}

///TODO style
export const GridItem = styled.div`
	${props => MixinGridItem({ ...props })}
`

// /**
//  * Komponet gridu
//  * @param {*} tx grid-template-columns
//  * @param {*} ty grid-template-rows
//  * @param {*} gx grid-column-gap
//  * @param {*} gy grid-row-gap
//  * @param {*} aix ustawienie dziecka w kierunku x: start | end | center | stretch
//  * @param {*} aiy ustawienie dziecka w kierunku y: start | end | center | stretch
//  * @param {*} acx ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
//  * @param {*} acy ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
//  * @param {*} x grid-auto-columns Rozmiary column: auto|max-content|min-content|length
//  * @param {*} y grid-auto-rows Rozmiary wierszy:  auto|max-content|min-content|length
//  * @param {*} f grid-auto-flow Kierunek wypełniania grida celami : row|column|dense|row dense|column dense
//  */
// export const Grid = styled.div`
// 	${props => MixinGrid({ ...props })}
// `

const GridStyled = customElements('o-grid', ['grid'])

/**
 * Komponet gridu
 * @param {*} tx grid-template-columns
 * @param {*} ty grid-template-rows
 * @param {*} gx grid-column-gap
 * @param {*} gy grid-row-gap
 * @param {*} aix ustawienie dziecka w kierunku x: start | end | center | stretch
 * @param {*} aiy ustawienie dziecka w kierunku y: start | end | center | stretch
 * @param {*} acx ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
 * @param {*} acy ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
 * @param {*} x grid-auto-columns Rozmiary column: auto|max-content|min-content|length
 * @param {*} y grid-auto-rows Rozmiary wierszy:  auto|max-content|min-content|length
 * @param {*} f grid-auto-flow Kierunek wypełniania grida celami : row|column|dense|row dense|column dense
 */
export function Grid({ tx, ty, gx, gy, aix, aiy, acx, acy, x, y, f, mw, height, style, ...props }) {
	const _style = { ...style }

	if (tx !== undefined)
		_style.gridTemplateColumns = tx
	if (ty !== undefined)
		_style.gridTemplateRows = ty
	if (gx !== undefined)
		_style.gridColumnGap = gx
	if (gy !== undefined)
		_style.gridRowGap = gy
	if (aix !== undefined)
		_style.justifyItems = aix
	if (aiy !== undefined)
		_style.alignItems = aiy
	if (acx !== undefined)
		_style.justifyContent = acx
	if (acy !== undefined)
		_style.alignContent = acy
	if (x !== undefined)
		_style.gridAutoColumns = x
	if (y !== undefined)
		_style.gridAutoRows = y
	if (false !== undefined)
		_style.gridAutoFlow = f
	if (mw !== undefined)
		_style.maxWidth = mw
	if (height !== undefined)
		_style.height = height

	return <GridStyled style={_style} {...props}></GridStyled>
}