import React, { useContext, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import DataView from 'components/dataView/DataView'
import { useListItemStyles, StyledMenu, StyledTooltip } from './NavLinks.styled'
import NewIcons from 'components/icon/NewIcons'
import OnClickListener from 'contents/main/OnClickListener'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { SidebarContext } from './SideBar'
import { appCtrl } from 'AppCtrl'
import getRestService from 'services/getRestService'

/**
 * Komponent pojedyńczego linku
 * @param {string} icon nazwa ikony z material ikons
 * @param {string} label Labelka
 * @param {function} onOpen Powiadomienie na otwarcie
 * @returns {Node}
 */
function NavLinkElement({ name, onOpen, isOpen }) {
	const dv = DataView.useDataView()
	const sidebarContextValue = useContext(SidebarContext)
	const history = useHistory()
	const { root, selected } = useListItemStyles({ isMenuOpen: sidebarContextValue.isMenuOpen })

	if (!dv.root)
		return null

	const part = dv.root.findChildByName(name)

	if (!part || !part.reachable)
		return null

	const useLinkName = part.props && part.props.useLinkName
	const currentLinkBase = useLinkName ? useLinkName : part.props.dataType

	return (
		<StyledTooltip title={part.props.title} placement="right" disableHoverListener={sidebarContextValue.isMenuOpen} arrow>
			<div
				onClick={e => {
					history.push('/' + part.props.name)
					onOpen && onOpen()
				}}
			>
				<Link id={currentLinkBase} to={`/${currentLinkBase}`} onClick={e => e.preventDefault()}>
					<ListItem
						classes={{root, selected}}
						button
						selected={isOpen}
					>
						<ListItemIcon>
							<NewIcons name={part.props.icon} width={30} />
						</ListItemIcon>
						{sidebarContextValue.isMenuOpen && <ListItemText primary={part.props.title} /> }
					</ListItem>
				</Link>
			</div>
		</StyledTooltip>
	)
}

function NavLinkToJasperReport({ reportName, reportLabel, href, onOpen, isOpen}) {

	const sidebarContextValue = useContext(SidebarContext)
	const { root, selected } = useListItemStyles({ isMenuOpen: sidebarContextValue.isMenuOpen });

	async function getJasperReportLink(appCtrl,getRestService, reportName){
		let link = appCtrl.session.findLinkByName("jaspersoftReports");
		if (link) {
			let service = getRestService(link);
			let jasperservices = await service.get('', {}, {});
			
			const linksForReport = jasperservices.data.filter(d => {
				return d.attributes.JasperPath === reportName;
			});
	
			if (linksForReport.length===1) {
				return linksForReport[0].attributes.Link;
			}

			return undefined;
		}
	}
	
	return (
		<StyledTooltip title={reportLabel} placement="right" disableHoverListener={sidebarContextValue.isMenuOpen} arrow>
			<div
				onClick={async (e) => {
					href = await getJasperReportLink(appCtrl,getRestService, reportName);
					if (href){
						window.open(href, '_blank', 'noopener,noreferrer');
						onOpen && onOpen();
					}
				}}
			>
				<Link onClick={e => e.preventDefault()}>
					<ListItem
						classes={{root, selected}}
						button
						selected={isOpen}
					>
						<ListItemIcon>
							<NewIcons name='reportJasperSoft' width={30} />
						</ListItemIcon>
						<ListItemText primary={reportLabel} />
					</ListItem>
				</Link>
			</div>
		</StyledTooltip>
	)
}

/**
 * Komponent pojedyńczego linku wskazujący na zewnętrzny zasób
 * @param {function} onOpen Powiadomienie na otwarcie
 * @param {string} href Adres linku
 * @returns {Node}
 */
 function NavLinkToExternalHref({ name, onOpen, isOpen, href }) {
	const dv = DataView.useDataView()
	const sidebarContextValue = useContext(SidebarContext)
	const { root, selected } = useListItemStyles({ isMenuOpen: sidebarContextValue.isMenuOpen })

	if (!dv.root)
		return null

	const part = dv.root.findChildByName(name)

	if (!part || !part.reachable)
		return null

	return (
		<StyledTooltip title={part.props.title} placement="right" disableHoverListener={sidebarContextValue.isMenuOpen} arrow>
			<div
				onClick={e => {
					window.open(href, '_blank', 'noopener,noreferrer');
					onOpen && onOpen()
				}}
			>
				<Link onClick={e => e.preventDefault()}>
					<ListItem
						classes={{root, selected}}
						button
						selected={isOpen}
					>
						<ListItemIcon>
							<NewIcons name={part.props.icon} width={30} />
						</ListItemIcon>
						<ListItemText primary={part.props.title} />
					</ListItem>
				</Link>
			</div>
		</StyledTooltip>
	)
}

function NavLinksMenu({ children, isMenuOpen, anchorEl, handleClose }) {
	return (
		<StyledMenu
			onClose={() => handleClose()}
			elevation={0}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				horizontal: 'left',
				vertical: 'center'
			}}
			anchorEl={anchorEl}
			open={isMenuOpen}
		>
			{children}
		</StyledMenu>
	)
}

/**
 * Komponent linków bocznych
 *
 * @param {string} icon Nazwa ikony z material ikons
 * @param {string} label Labelka
 * @param {boolean} isOpen Czy jest otwarty
 * @param {function} onOpen Powiadomienie na otwarcie
 * @param {Node} children Zawartość
 * @returns {Node}
 */
function NavLinks({ icon, label, isOpen, onOpen, children }) {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const sidebarContextValue = useContext(SidebarContext)
	const dv = DataView.useDataView()
	const { root, selected } = useListItemStyles({ isMenuOpen: sidebarContextValue.isMenuOpen })

	if (!dv.root)
		return null

	const canChildApper = React.Children.map(children, child => child.props.name).some((name) => {
		const part = dv.root.findChildByName(name)
		return part && part.reachable
	})

	if (!canChildApper)
		return null

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget)
		onOpen && onOpen()
		e.stopPropagation()
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<StyledTooltip title={label} placement="right" disableHoverListener={sidebarContextValue.isMenuOpen} arrow>
				<ListItem
					classes={{root, selected}}
					onClick={e => handleClick(e)}
					button
					selected={isOpen}
				>
					<ListItemIcon>
						<NewIcons name={icon} width={30} />
					</ListItemIcon>
					<ListItemText primary={label} />
					<NewIcons name='next' height={30} />
				</ListItem>
			</StyledTooltip>
			<OnClickListener onClickAway={() => setAnchorEl(null)}>
				<div
					onClick={() => setAnchorEl(null)}
				>
					<NavLinksMenu handleClose={handleClose} isMenuOpen={Boolean(anchorEl)} anchorEl={anchorEl}>
						<NavLinksRoot>{children}</NavLinksRoot>
					</NavLinksMenu>
				</div>
			</OnClickListener>
		</>
	)
}

/**
 *	Komponent zawierający <NavLinks\>
 *
 * @param {Node} children Zawartość
 * @returns {Node}
 */
function NavLinksRoot({ children }) {
	const [openChildIndex, setOpenChildIndex] = React.useState()

	return (
		<div className='grid-acy-start'>
			{React.Children.map(children, (child, index) => {
				return React.cloneElement(child, {
					isOpen: index === openChildIndex,
					onOpen: () => {
						setOpenChildIndex(index)
					}
				})
			})}
		</div>
	)
}

export { NavLinksRoot, NavLinks, NavLinkElement as NavLink, NavLinkToExternalHref, NavLinkToJasperReport }

