import { Tooltip } from '@material-ui/core'
import { Css, withStyles } from 'cssStatic'

//#region Style komponentu
Css.add({
	'.o-tooltip-only-when-ellipsis-active': { 'font-size': '0.8rem' },
	'.o-tooltip': { 'font-size': '0.8rem' },
})

export const StyledTooltipOnlyWhenEllipsisActive = withStyles({tooltip: 'o-tooltip-only-when-ellipsis-active'}, Tooltip)

export const StyledTooltip = withStyles({tooltip: 'o-tooltip'}, Tooltip)
//#endregion
