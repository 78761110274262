import React from 'react'
import useForm from 'components/form/hooks/useForm'
import { useTranslate } from 'locale/Locale'
import Button from 'components/form/components/list/components/buttons/Button'

export function ColumnButton({ close }) {
	const form = useForm('data')
	const translate = useTranslate('WebSpa/ColumnModal')
	const parentForm = form.parentForm

	const handleButtonClick = () => {
		//Ta pętla pozwala na zapis wszystkich zmian w momencie gdy naciśniemy przycisk "Filtruj", nie musimy "tracić" focusu z pola które edytujemy
		form.childForms.forEach(childForm => {
			if (childForm.isChanged)
				childForm.changesToValues()
		})

		let dialogColumns = form.data.data
		let positions = dialogColumns.reduce((resultPositions, c, index) => {
			let attribute = c.attributes
			resultPositions[attribute.FieldName] = {
				index,
				visible: attribute.FieldVisible,
				name: attribute.FieldName
			}
			return resultPositions
		}, {})
		parentForm.columnManager.updatePositions(positions)
		parentForm.columnManager.initColumnFromData()
		parentForm.trigger('data')
		parentForm.saveConfiguration()
		close()
	}

	return (
		<Button
			type="submit"
			onClick={() => handleButtonClick()}
			content={translate('submitButton')}
		/>
	)
}