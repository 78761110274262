import { useEffect } from 'react';
import EditorMediator from '../model/EditorMediator';

const globalEditorController = new EditorMediator();

export type EditorControllerHookProps =
{
    subscribeTo?: (controller: EditorMediator) => void;
    unsubscribeFrom?: (controller: EditorMediator) => void;
}

const useEditorMediator = (props: EditorControllerHookProps = null): EditorMediator => {
    
    useEffect(() => {
        if(props?.subscribeTo)
        {
            props.subscribeTo(globalEditorController);
        }

        return () => {
            if(props?.unsubscribeFrom)
            {
                props.unsubscribeFrom(globalEditorController);
            }
        };
    }, []);


    return globalEditorController;
}

export default useEditorMediator;