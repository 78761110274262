import React from 'react'
import { useFetchProjectTms } from 'contents/cat/hooks/useApi';
import useSessionTask from 'contents/cat/hooks/useSessionTask';
import MaterialTableMetaDriven from 'contents/cat/MaterialTableMetaDriven';

// Komponent prezentuje liste dokumentów przynależących do zadania
export default function ProjectTranslationMemories({ match, history }) {
	const currentTask = useSessionTask();
	const request = useFetchProjectTms({}, currentTask.projectId);
	const resourceDocument = request.resourceDocument;

	let resourceMeta = null
	let resourceData = null
	let detailPanel = []
	if (resourceDocument) {
		resourceMeta = resourceDocument.meta
		resourceData = resourceDocument.data
	}

	return (
		<MaterialTableMetaDriven
			style={{ marginBottom: '5px' }}
			meta={resourceMeta}
			data={resourceData}
			title="Translation memories"
			isLoading={request.isLoading}
			options={{
				pageSize: 15,
				pageSizeOptions: [15, 20],
				emptyRowsWhenPaging: false,
				padding: 'dense',
				filtering: false,
				selection: false,
				search: false,
				showTitle: true,
				maxBodyHeight: null
			}}
		/>
	)
}