import { withStyles, } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Css } from 'cssStatic'

/**
 *  Style dla komponentu Comboboxa, możliwe do użycia klasy i dokumentacja:
 *  https://material-ui.com/api/autocomplete/#css
 */

///TODO style
export const StyledAutocompleteForCombobox = withStyles(() => ({
	inputRoot: {
		padding: '6px !important',
		flexWrap: 'nowrap',
	},
	option: {
		height: '30px',
		padding: '3px 15px',
		maxWidth: '100%',
	},
	listbox: {
		padding: '3px 0'
	},
	tag: {
		margin: '4px 5px 0 0',
		padding: 0,
	},
}))(Autocomplete)

export const StyledAutocompleteForComboboxEnumList = withStyles(() => ({
	inputRoot: {
		padding: '6px !important',
		flexWrap: 'wrap',
	},
	option: {
		height: '30px',
		padding: '3px 15px',
		maxWidth: '100%',
	},
	listbox: {
		padding: '3px 0'
	},
	tag: {
		margin: '4px 5px 0 0',
		padding: 0,
	},
}))(Autocomplete)


Css.add({
	'.combobox-ref-link': ['text-link', {
		'transition': 'color .2s ease-out',
		'&:hover': ['text-mainDarken900']
	}]
})