import React from 'react';
import SingleBoxContainer from "./SingleBoxContainer";
import { isEmpty } from 'lodash';

const defaultStatusToErrorMessage = {
    "400" : "Something went wrong. Try again or contact with the system administrator.",
    "401" : "You are not authorized to access this service or resource.",
    "403" : "You have no permissions to access this service or resource.",
    "404" : "The page or resource does not exists.",
    "405" : "Remote service does not supported requested method",
    "408" : "Timeout occurred. The service might be busy or the operation takes too long. Try again later."
}

const unexpectedErrorMessage = "Sorry, we have encountered an unknown issue. " +
    "Please refresh application and try again or contact with the system administrator";

const SimpleErrorView = ({errorObject, responseData, customStatusErrorMessages}) => {

    if(!errorObject)
        return null;

    let errorMessage = "";
    if(!responseData){
        errorMessage = errorObject?.detail ?? errorObject?.title;
    }
    else if(responseData?.status)
    {
        let statusToErrorMessage = defaultStatusToErrorMessage;
        if(customStatusErrorMessages)
            statusToErrorMessage = {...defaultStatusToErrorMessage, ...customStatusErrorMessages};

        const status = toString(responseData.status);
        if(status in statusToErrorMessage)
            errorMessage = statusToErrorMessage[status];
        else
            errorMessage = unexpectedErrorMessage;
    }

    if(isEmpty(errorMessage))
        errorMessage = unexpectedErrorMessage;

    return (
        <SingleBoxContainer>
            <h1>{errorMessage}</h1>
        </SingleBoxContainer>
    );
}

export default SimpleErrorView;