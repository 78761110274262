import { ActionRequestStart, ActionRequestSuccess, ActionRequestFailure, ActionPerformRequestDeferred } from './RequestAction';
import { IEntity, ResourceContextState, IEntityContextStateController } from './DataContextModel';
import {ResourceContextsContainerState} from './DataContext';
import { BaseRequestOptions, RequestResults, RequestInfo, RequestState, RequestExecutionStrategy, SaveRequestOptions, LoadRequestOptions } from './RequestModel';
import EntityContextController from './EntityContextStateController';
import { ActionPerformRequest } from './DataContextAction';

const getRequestInfo = (contextState: ResourceContextState, options: BaseRequestOptions): RequestInfo => {
    const existingRequest: RequestInfo = contextState.requests.find(
        (req) => req.key === options.key
    );
    return existingRequest;
};

export const reducerRequestStart = ( draftContext: ResourceContextState, action: ActionRequestStart ) => {
    let existingRequest = getRequestInfo(draftContext, action.options);
    if (existingRequest) {
      existingRequest.state = RequestState.Pending;
      existingRequest.startedAt = new Date();
    } else {
      draftContext.requests.push({
        state: RequestState.Pending,
        key: action.options.key,
        startedAt: new Date()
      });
    }

    if(draftContext?.deferredRequest && draftContext.deferredRequest.options.key === action.options.key)
        draftContext.deferredRequest = null;

    if(action.onRequestAction)
        action.onRequestAction(action.options.key, RequestState.Pending, new EntityContextController(draftContext));
};

export const reducerRequestSuccess = (draftContext: ResourceContextState, action: ActionRequestSuccess ) => {
    let existingRequest: RequestInfo = getRequestInfo(
      draftContext,
      action.options
    );
    existingRequest.state = RequestState.Successed;
    existingRequest.completedAt = new Date();
  
    action.options.dataMapping.onFinishedRequest(
      action.contextId,
      action.options,
      action.requestResult
    );

    const contextController = new EntityContextController(draftContext);
    action.options.dataMapping.mergeWithEntityIndex(contextController, action.options, action.requestResult);

    if(action.onRequestAction)
        action.onRequestAction(action.options.key, RequestState.Successed, contextController);
  };

export const reducerRequestFailure = (draftContext: ResourceContextState, action: ActionRequestFailure) => {
    let existingRequest = getRequestInfo(draftContext, action.options);
    existingRequest.state = RequestState.Failed;
    existingRequest.completedAt = new Date();

    console.error(action.error);

    if(action.onRequestAction)
        action.onRequestAction(action.options.key, RequestState.Failed, new EntityContextController(draftContext));
};

export const reducerPerformRequestDeferred = (draftContext: ResourceContextState, action: ActionPerformRequestDeferred) => {
    draftContext.deferredRequest = action;
};

const performRequestStrategyLoadAsync = async (action: ActionPerformRequest, requestOptions: LoadRequestOptions) : Promise<RequestResults> => {
    return await requestOptions.fnAsync(action.abortController);
}

const performRequestStrategySaveAsync = async (
    action: ActionPerformRequest, 
    requestOptions: SaveRequestOptions, 
    contextState: ResourceContextState) : Promise<RequestResults> => {

    const contextController = new EntityContextController(contextState);
    const requestPayload = requestOptions.dataMapping.createRequestPayload(contextController, requestOptions);
    return await requestOptions.fnAsync(requestPayload);
}

const performRequestStrategyDeleteAsync = async (
    action: ActionPerformRequest, 
    requestOptions: SaveRequestOptions, 
    contextState: ResourceContextState) : Promise<RequestResults> => {

    const contextController = new EntityContextController(contextState);
    const requestPayload = requestOptions.dataMapping.createRequestPayload(contextController, requestOptions);
    return await requestOptions.fnAsync(requestPayload);
}

export const recuderPerformRequestAsync = ({ dispatch, getState }) => async (action: ActionPerformRequest) => 
{
    let requestResult: RequestResults = null;
    const currentState: ResourceContextsContainerState = getState();
    const currentContextState: ResourceContextState =
        currentState.contexts[action.contextId];

    // const pendingRequest: RequestInfo = currentContextState?.requests?.find(
    //     (req) => req.state === RequestState.Pending
    // );
    // if (pendingRequest) {
    //     console.log("Cannot perform request " + action.options.key + ". The request " + pendingRequest.key + " is already sent.");
    //     return;
    // }

    try 
    {
        dispatch({
            type: "REQUEST_START",
            contextId: action.contextId,
            options: action.options,
            abortController: action.abortController,
            onRequestAction: action.onRequestAction
        });

        if(action.onPerformRequestExecution)
        {
            action.onPerformRequestExecution(action.options.key, RequestState.Pending)
        }

        switch(action.options.executionStrategy){
            case RequestExecutionStrategy.Load:
                requestResult = await performRequestStrategyLoadAsync(action, action.options);
                break;
            case RequestExecutionStrategy.Update:
            case RequestExecutionStrategy.Create:
                requestResult = await performRequestStrategySaveAsync(action, action.options, currentContextState);
                break;
            case RequestExecutionStrategy.Delete:
                requestResult = await performRequestStrategyDeleteAsync(action, action.options, currentContextState);
                break;
            default:
                throw new Error("Unknown execution strategy");
        }
    } catch (error) {
        dispatch({
            type: "REQUEST_FAILURE",
            contextId: action.contextId,
            options: action.options,
            error,
            onRequestAction: action.onRequestAction
        });
        if(action.onPerformRequestExecution){
            action.onPerformRequestExecution(action.options.key, RequestState.Failed);
        }
    } finally {
        if (requestResult?.ok) {
            dispatch({
                type: "REQUEST_SUCCESS",
                contextId: action.contextId,
                options: action.options,
                requestResult,
                onRequestAction: action.onRequestAction
            });
            if(action.onPerformRequestExecution){
                action.onPerformRequestExecution(action.options.key, RequestState.Successed);
            }
        } else {
            dispatch({
                type: "REQUEST_FAILURE",
                contextId: action.contextId,
                options: action.options,
                requestResult,
                onRequestAction: action.onRequestAction
            });
            if(action.onPerformRequestExecution){
                action.onPerformRequestExecution(action.options.key, RequestState.Failed);
            }
        }
    }
}