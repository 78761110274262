/**
 * Nazwy konkretnych guzików jakie mogą być przekazane z BE
 */
export enum BackendButtons
{
    CreateOCRFile = 'ButtonCreateOCRFile',
	AddNewFileVersion = 'ButtonAddNewFileVersion',
	AddResultFile = 'ButtonAddResultFile',
	AddDTPFile = 'ButtonAddDTPFile',
	AddReturnPackageFile = 'ButtonAddReturnPackageFile',
	AddTradosProjectFile = 'ButtonAddTradosProjectFile',
	AddTradosProjectTemplateFile = 'ButtonAddTradosProjectTemplateFile',
	AddNewFinalFileVersion = 'ButtonAddNewFinalFileVersion',
	ChangePassword = 'ButtonChangePassword',
	CreateIdentity = 'ButtonCreateIdentity',
	ButtonCreateSupplierinvoice = 'ButtonCreateSupplierinvoice',
	ButtonGenerateInvoice = 'ButtonGenerateInvoice',
	ButtonExportElixirSupplierPayments = 'ButtonGenerateElixirPaymentOrders'
}