function add(object, nextObjectId) {
	if (!object.next)
		object.next = [nextObjectId]
	else
		object.next.push(nextObjectId)
}


function connectNextObjIfExists(map, firstObjId, secObjId) {
	const firstObj = map.get(firstObjId)
	if ( firstObj !== undefined )
		add(firstObj, secObjId)
}


export function fromForm(form, { dependentObjectsFieldName, isDependentObjectFieldName }) {
	const filesData = form.data.data

	const values = divideFormRowsValuesBetWeenLeftRightSides(filesData, isDependentObjectFieldName)

	const map = new Map()

	values.left.forEach(file => map.set(file.id, file))
	values.right.forEach(file => map.set(file.id, file))

	filesData.forEach(objData => {
		if (!Array.isArray(objData.attributes[dependentObjectsFieldName]))
			return

		objData.attributes[dependentObjectsFieldName].forEach(dependentObj => connectNextObjIfExists(map, objData.id, dependentObj.id))
	})

	return values
}

/**
 * Dzielimy listę wejściową objectsData na dwie listy:
	lewa - objekty ktore maja ustawiona wartość isDependentObjectFieldName = false
	prawa - objekty ktore maja ustawiona wartość isDependentObjectFieldName = true
	Jesli to pole nie występuje to wtedy objekt nie zostanie przydzielony do żadnej z list
 * @param {*} Dane objektów  
 * @param {*} nazwa pola które zawiera informacje czy dany objekt jest objektem zależnym 
 * @returns Objekt zawierający dwie listy objektów podzielone na lewą i prawą stronę
 */
function divideFormRowsValuesBetWeenLeftRightSides(objectsData, isDependentObjectFieldName) {
	return {
		left: objectsData.filter(row => row.attributes?.[isDependentObjectFieldName] === false)
			.map(row => {
				return {
					id: row.id,
					value: row.attributes?.FileIdent?.value
				}
			}),
		right: objectsData.filter(row => row.attributes?.[isDependentObjectFieldName])
			.map(row => {
				return {
					id: row.id,
					value: row.attributes?.FileIdent?.value
				}
			})
	}
}