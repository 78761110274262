export interface IChangeTrackingEvents
{
    onAcceptChangeTrackingChanges: (tuId: number) => void;
    onDiscardChangeTrackingChanges: (tuId: number) => void;
}

export interface IChangeTrackingEventsSubscription
{
    subscribeChangeTracking: (subscriber: IChangeTrackingEvents) => void;
    unsubscribeChangeTracking: (subscriber: IChangeTrackingEvents) => void;
}

export default class EditorTranslationUnitEvents implements IChangeTrackingEvents, IChangeTrackingEventsSubscription
{
    changeTracking: IChangeTrackingEvents[];

    constructor()
    {
        this.changeTracking = [];
    }

    subscribeChangeTracking(subscriber: IChangeTrackingEvents)
    {
        const indexOfElement = this.changeTracking.indexOf(subscriber);
        if(indexOfElement === -1)
            this.changeTracking.push(subscriber);
    }

    unsubscribeChangeTracking(subscriber: IChangeTrackingEvents)
    {
        const indexOfElement = this.changeTracking.indexOf(subscriber);
        if(indexOfElement > -1)
            this.changeTracking.splice(indexOfElement, 1);
    }

    onAcceptChangeTrackingChanges (tuId: number): void
    {
        for (const handler of this.changeTracking) 
        {
            try
            {
                handler.onAcceptChangeTrackingChanges(tuId);
            }
            catch(e)
            {
                console.error(e);
            }
        }
    }

    onDiscardChangeTrackingChanges(tuId: number): void
    {
        for (const handler of this.changeTracking) 
        {
            try
            {
                handler.onDiscardChangeTrackingChanges(tuId);
            }
            catch(e)
            {
                console.error(e);
            }
        }
    }
}