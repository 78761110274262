import styled, { css } from 'styled-components'

/**
 * Mixin flex
 * @param {*} d kierunek flexa: row | row-reverse | column | column-reverse;
 * @param {*} w czy zawijany: nowrap | wrap | wrap-reverse;
 * @param {*} jc justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
 * @param {*} ai align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
 * @param {*} ac align-content: flex-start | flex-end | center | space-between | space-around | space-evenly | stretch | start | end | baseline | first baseline | last baseline + ... safe | unsafe;
 */
export function MixinFlex({ d, w, jc, ai, ac }) {
	return css`
		display:flex;
		flex-direction: ${d};
		flex-wrap: ${w};
		justify-content: ${jc};
		align-items: ${ai};
		align-content: ${ac};
	`
}

/**
 * Mixin flex poziomy
 * @param {*} w czy zawijany: nowrap | wrap | wrap-reverse;
 * @param {*} jc justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
 * @param {*} ai align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
 * @param {*} ac align-content: flex-start | flex-end | center | space-between | space-around | space-evenly | stretch | start | end | baseline | first baseline | last baseline + ... safe | unsafe;
 */
export function MixinFlexRow(props) {
	return MixinFlex({ d: 'row', ...props })
}

/**
 * Mixin flex pionowy
 * @param {*} w czy zawijany: nowrap | wrap | wrap-reverse;
 * @param {*} jc justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
 * @param {*} ai align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
 * @param {*} ac align-content: flex-start | flex-end | center | space-between | space-around | space-evenly | stretch | start | end | baseline | first baseline | last baseline + ... safe | unsafe;
 */
export function MixinFlexColumn(props) {
	return MixinFlex({ d: 'column', ...props })
}

/**
 * Flex poziomy
 * @param {*} d kierunek flexa: row | row-reverse | column | column-reverse;
 * @param {*} w czy zawijany: nowrap | wrap | wrap-reverse;
 * @param {*} jc justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
 * @param {*} ai align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
 * @param {*} ac align-content: flex-start | flex-end | center | space-between | space-around | space-evenly | stretch | start | end | baseline | first baseline | last baseline + ... safe | unsafe;
 */
export const FlexRow = styled.div`
	${props => MixinFlexRow(props)}
`

/**
 * Flex pionowy
 * @param {*} d kierunek flexa: row | row-reverse | column | column-reverse;
 * @param {*} w czy zawijany: nowrap | wrap | wrap-reverse;
 * @param {*} jc justify-content: flex-start | flex-end | center | space-between | space-around | space-evenly | start | end | left | right ... + safe | unsafe;
 * @param {*} ai align-items: stretch | flex-start | flex-end | center | baseline | first baseline | last baseline | start | end | self-start | self-end + ... safe | unsafe;
 * @param {*} ac align-content: flex-start | flex-end | center | space-between | space-around | space-evenly | stretch | start | end | baseline | first baseline | last baseline + ... safe | unsafe;
 */
export const FlexColumn = styled.div`
	${props => MixinFlexColumn(props)}
`