import React from 'react'
import { ObservableContextProvider, useObservableContext } from '../../../observableContext/ObservableContaxt'
import ErrorModal from '../ErrorModal/ErrorModal'
import { ErrorModalFormEx } from 'components/ErrorModalEx'
import RedirectToLogin from '../RedirectToLogin'
import useForm from '../../hooks/useForm'

function FormBase1({ onLoadParam, parentForm, children }) {
	const form = useObservableContext()

	const [load, setLoad] = React.useState({})
	const forceLoad = React.useCallback(
		(loadType) => {
			setLoad(loadType)
		},
		[setLoad]
	)

	React.useEffect(() => {
		if (!form._onAsyncLoad)
			return

		const callLoad = async () => {
			form.isLoading = true
			form.onLoadParam = onLoadParam
			await form._onAsyncLoad(form,load.loadType)
			form.isLoading = false
		}
		callLoad()
		// eslint-disable-next-line
	}, [load, onLoadParam])

	const [submit, setSubmit] = React.useState()
	const forceSubmit = React.useCallback(
		(submitProps) => {
			setSubmit(submitProps)
		},
		[setSubmit]
	)

	React.useEffect(() => {
		if (submit === undefined || !form._onAsyncSubmit)
			return

		const callSubmit = async () => {
			form.isLoading = true
			await form._onAsyncSubmit(form, submit.submitType, submit.props)
			form.isLoading = false
		}
		callSubmit()
		// eslint-disable-next-line
	}, [submit])

	form._forceAsyncLoad = forceLoad
	form._forceAsyncSubmit = forceSubmit
	form._parentForm = parentForm
	if(parentForm)
		parentForm._childForms.add(form)

	return children
}

/**
 * Domyślny sposób obsługi braku zalogowania
 */
let defaultRedirectToLogin = () => { return <RedirectToLogin /> }
/**
 * Domyślny sposób obsługi błedów BE
 */
let defaultErrorModal = () => { return <ErrorModalFormEx /> }

/**
 * Komponent formy bazowej
 *
 * @param {function} createFormClass Funkcja zwracajaca klasę kontekstu
 * @param {*} data Dane początkowe formy
 * @param {Node} children Zawartość
 * @param {*} onLoadParam Parametry potrzebne do stwierdzenia czy trzeba redrawować komponent 
 * @returns {Node}
 */
function FormBase({
	createFormClass, data, children, onChange, onLoadParam,
	redirectToLogin, errorModal,
	...other }) {

	const parentForm = useForm()
	errorModal = errorModal || defaultErrorModal
	redirectToLogin = redirectToLogin || defaultRedirectToLogin

	return (
		<ObservableContextProvider
			data={data}
			createObservableContextClass={() => createFormClass(other)}
			onChange={onChange}
		>
			<FormBase1 onLoadParam={onLoadParam} parentForm={parentForm}>
				{/* {(redirectToLogin && redirectToLogin()) || defaultRedirectToLogin()}
				{(errorModal && errorModal()) || defaultErrorModal()} */}
				{redirectToLogin()}
				{errorModal()}
				{children}
			</FormBase1>
		</ObservableContextProvider>
	)
}

export {
	defaultRedirectToLogin,
	defaultErrorModal,
	FormBase,
}