import { Reducer, useReducer } from "react";
import produce from "immer";
import { createContainer } from "react-tracked";
import LanguageDocument from "contents/cat/model/LanguageDocument";

/** Definiuje stan edytora */
export type EditorContextState =
{
    /** Wybrany dokument prezentowany w edytorze */
    activeDocumentId?: string;
    selectedTranslationUnitNumber?: number
}

export type ActionActiveDocumentChanged = {
    type: "ACTIVE_DOCUMENT_CHANGED";
    documentId: string;
}

export type ActionSelectedTranslationUnit = {
    type: "SELECTED_TRANSLATION_UNIT";
    selectedTranslationUnitNumber: number;
}

export type Action = ActionActiveDocumentChanged | ActionSelectedTranslationUnit;

export function createDefaultContext(): EditorContextState
{
    return {
        activeDocumentId: null,
    }
}

const reducer: Reducer<EditorContextState, Action> = (state, action) => 
{
    console.log("[Action] " + action.type);
    return produce(state, (draftState) => {
        switch(action.type)
        {
            case "ACTIVE_DOCUMENT_CHANGED":
                draftState.activeDocumentId = action.documentId;
                break;
            case "SELECTED_TRANSLATION_UNIT":
                draftState.selectedTranslationUnitNumber = action.selectedTranslationUnitNumber;
        }
    });
}

const initialState: EditorContextState = createDefaultContext();
const useValue = () => useReducer(reducer, initialState);  

export const {
    Provider,
    useTrackedState,
    useUpdate: useDispatch
} = createContainer(useValue);