import React from "react"
import PropTypes from "prop-types"
import "./Card.scss"
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'

/**
 * Komponent białej kartki na której umieszczane są podkomponenty
 *
 * @param {string} classNameBorder css klasa ramki kartki domyślnie: 'card-border'
 * @param {string} classNameContent css klasa zawartości domyślnie: 'card-content'
 * @param {Node} children Zawartość
 * @param {*} other Dodatkowe props dla <div/> ramki
 * @returns {Node}
 */
export default function Card({ classNameBorder, classNameContent, children, ...other }) {
	return (
		<div
			{...other}
			className={classNameBorder ? classNameBorder : "card-border"}
		>
			<div className={classNameContent ? classNameContent : "card-content"}>
				{children}
			</div>
		</div>
	)
}

Card.propTypes = {
	classNameConntent: PropTypes.string,
	classNameBorder: PropTypes.string,
	children: PropTypes.node
}

Card.defaultProps = {
	classNameBorder: ""
}
