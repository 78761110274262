import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types"
import Box from '@material-ui/core/Box';

/**
 * Komponent preloadera
 */
export default function CircularPreloader(props) {

	if (props.label) {
		return (
			<React.Fragment>
				<Box component="span" textAlign="center" display="block" p={1} m={1}>
					<CircularProgress />
				</Box>
				<Box component="span" textAlign="center" display="block" p={1} m={1}>
					{props.label}
				</Box>
			</React.Fragment>
		)
    }
	return ( <CircularProgress />)
}

CircularPreloader.propTypes = {
	label: PropTypes.string
}
