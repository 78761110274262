import { saveAs } from 'file-saver';

const saveTextToFileOnLocalDisk = (content, fileName) => {
    let blobContent = new Blob([content], { type: "text/plain;charset=utf-8" });
    saveAs(blobContent, fileName);
  }
  
const serializeLogToText = (logSerializer) => {
    return logSerializer.toStrings().join('\r\n');
 }
  
const saveLogsToFileOnLocalDisk = (logSerializer) => {
    const fileName = "LivoLink_WebLogs_" + new Date().getTime() + ".log";
    console.log("Log file name: " + fileName);
    const content = serializeLogToText(logSerializer);
    console.log("Writing log to file.");
    saveTextToFileOnLocalDisk(content, fileName);
}

export {saveLogsToFileOnLocalDisk};