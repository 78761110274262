/**
 * Komponent tymczasowy, pozwala na tworzenie tablic powtarzających się ListPartów i zwracanie ich w formie tablicy.
 * Ma to na celu uniknięcie powtarzania dokładnie tych samych ListPartów w różnych miejsach w pliku Parts.js
 */

import React from 'react'
import ListPart from './dataView/ListPart'
import Person from './Person'
import { useTranslate } from 'locale/Locale'
import EditOnePart from './dataView/EditOnePart'

/**
 * Funkcja CompanyTab używana dla Partów o dataType równym Company (Company oraz MyProfile)
 * @type {String} [parentTabName="undefined"] Nazwa zakładki rodzica
 */

export const CompanyTabs = (parentTabName=undefined) => {
	const translate = useTranslate('WebSpa/MainMenu/Company')

	let titlePriceListItemOrganization, titleEditPriceListItemOrganization, titleCreatePriceListItemOrganization;
	if (parentTabName == 'MyProfile')
	{
		titlePriceListItemOrganization = translate('PriceListItemOrganizationInMyProfile/title')
		titleEditPriceListItemOrganization = translate('PriceListItemOrganizationInMyProfile/titleEdit')
		titleCreatePriceListItemOrganization = translate('PriceListItemOrganizationInMyProfile/titleCreate')
	}
	else{
		titlePriceListItemOrganization = translate('PriceListItemOrganization/title')
		titleEditPriceListItemOrganization = translate('PriceListItemOrganization/titleEdit')
		titleCreatePriceListItemOrganization = translate('PriceListItemOrganization/titleCreate')
	}

	const companyTabsArray = [
		<ListPart
			key='1'
			dataType='Person'
			title={translate('Person/title')}
			titleEdit={(form) => {
				return translate('Person/titleEdit') + form.getValueInput('Surname') + ' ' + form.getValueInput('FirstName')
			}}
			titleCreate={translate('Person/titleCreate')}
			createEditForm={() => <Person />}
		>
			<ListPart dataType='AgreementFile' title={translate('Person/AgreementFile/title')} titleEdit={translate('Person/AgreementFile/titleEdit')} titleCreate={translate('Person/AgreementFile/titleCreate')} />
			<ListPart dataType='PriceListItemCompanySupplier' polymorphic title={translate('Person/PriceListItemCompanySupplier/title')} titleEdit={translate('Person/PriceListItemCompanySupplier/titleEdit')} titleCreate={translate('Person/PriceListItemCompanySupplier/titleCreate')} />
			<ListPart dataType='SupplierLanguageInfo' title={translate('Person/SupplierLanguageInfo/title')} titleEdit={translate('Person/SupplierLanguageInfo/titleEdit')} titleCreate={translate('Person/SupplierLanguageInfo/titleCreate')} />
			<ListPart dataType='CustomerSupplierPref' title={translate('CustomerSupplierPref/title')} titleEdit={translate('CustomerSupplierPref/titleEdit')} titleCreate={translate('CustomerSupplierPref/titleCreate')} />
			<ListPart dataType='PersonEducation' title={translate('Education/title')} titleEdit={translate('Education/titleEdit')} titleCreate={translate('Education/titleCreate')} />
			<ListPart dataType='PersonCertificate' title={translate('Certificates/title')} titleEdit={translate('Certificates/titleEdit')} titleCreate={translate('Certificates/titleCreate')} />
			<ListPart dataType='PersonFile' title={translate('PersonFile/title')} titleEdit={translate('PersonFile/titleEdit')} titleCreate={translate('PersonFile/titleCreate')} group={['PersonFileType']}/>
		</ListPart>,
		<ListPart key='2' dataType='Address' title={translate('Address/title')} titleEdit={translate('Address/titleEdit')} titleCreate={translate('Address/titleCreate')} />,
		<ListPart key='3' dataType='Departments' title={translate('Departments/title')} titleEdit={translate('Departments/titleEdit')} titleCreate={translate('Departments/titleCreate')} />,
		<ListPart key='4' dataType='GeneralContacts' title={translate('GeneralContacts/title')} titleEdit={translate('GeneralContacts/titleEdit')} titleCreate={translate('GeneralContacts/titleCreate')} />,
		<ListPart key='5' dataType='AgreementFile' title={translate('AgreementFile/title')} titleEdit={translate('AgreementFile/titleEdit')} titleCreate={translate('AgreementFile/titleCreate')} />,
		<ListPart key='6' dataType='TemplateString' title={translate('TemplateString/title')} titleEdit={translate('TemplateString/titleEdit')} titleCreate={translate('TemplateString/titleCreate')} />,
		<ListPart key='7' dataType='DocumentFile' title={translate('DocumentFile/title')} titleEdit={translate('DocumentFile/titleEdit')} titleCreate={translate('DocumentFile/titleCreate')} />,
		<ListPart key='8' dataType='TranslationMemoryFile' title={translate('TranslationMemoryFile/title')} titleEdit={translate('TranslationMemoryFile/titleEdit')} titleCreate={translate('TranslationMemoryFile/titleCreate')} />,
		<ListPart key='9' dataType='PriceListItemOrganization' polymorphic title={titlePriceListItemOrganization} titleEdit={titleEditPriceListItemOrganization} titleCreate={titleCreatePriceListItemOrganization} />,
		<ListPart key='10' dataType='TranslationPriceModifierCustomer' title={translate('TranslationPriceModifierCustomer/title')} titleEdit={translate('TranslationPriceModifierCustomer/titleEdit')} titleCreate={translate('TranslationPriceModifierCustomer/titleCreate')} />,
		<ListPart key='11' dataType='TranslationPriceModifierSupplier' title={translate('TranslationPriceModifierSupplier/title')} titleEdit={translate('TranslationPriceModifierSupplier/titleEdit')} titleCreate={translate('TranslationPriceModifierSupplier/titleCreate')} />,
		<ListPart key='12' dataType='CustomerSupplierPref' title={translate('Person/CustomerSupplierPref/title')} titleEdit={translate('Person/CustomerSupplierPref/titleEdit')} titleCreate={translate('Person/CustomerSupplierPref/titleCreate')} />,
		<ListPart key='13' dataType='TranslationEnginePref' title={translate('TranslationEnginePref/title')} titleEdit={translate('TranslationEnginePref/titleEdit')} titleCreate={translate('TranslationEnginePref/titleCreate')} />
	]

	return companyTabsArray
}

/**
 * Funkcja ProjectItemTabs używana dla Partów o dataType równym ProjectItem 
 */
export const ProjectItemTabs = ({useSupplierProjectItemOrder}={}) => {

	const translateMainMenu = useTranslate('WebSpa/MainMenu')
	const translate = useTranslate('WebSpa/MainMenu/Project/ProjectItem')

	if ( useSupplierProjectItemOrder ){
		return [
			<ListPart key='2' dataType='ProjectItemSupplier' polymorphic title={translate('ProjectItemDelivery/title')} titleEdit={translate('ProjectItemDelivery/titleEdit')} titleCreate={translate('ProjectItemDelivery/titleCreate')} />,
			<ListPart key='3' dataType='ProjectItemFile' title={translate('ProjectItemFile/title')} titleEdit={translate('ProjectItemFile/titleEdit')} titleCreate={translate('ProjectItemFile/titleCreate')} sort={[{ name: 'FilePackage', dir: 1 }]} />,
			<ListPart key='4' useLinkName='ProjectItemReferenceFile' dataType='ProjectItemFile' title={translate('ProjectItemReferenceFile/title')} titleEdit={translate('ProjectItemReferenceFile/titleEdit')} titleCreate={translate('ProjectItemReferenceFile/titleCreate')} />,
			<ListPart key='5' dataType='TranslationStat' title={translate('TranslationStat/title')} titleEdit={translate('TranslationStat/titleEdit')} titleCreate={translate('TranslationStat/titleCreate')} />,
			<ListPart key='6' dataType='ChangeLog' title={translate('ChangeLog/title')} titleEdit={translate('ChangeLog/titleEdit')} titleCreate={translate('ChangeLog/titleCreate')} />,
			<ListPart key='7' name="complaint" dataType='Complaint' title={translateMainMenu('Offer/OfferItem/Complaint/title')} titleEdit={translateMainMenu('Offer/OfferItem/Complaint/titleEdit')} titleCreate={translateMainMenu('Offer/OfferItem/Complaint/titleCreate')} >
				<ListPart dataType='ComplaintEntry' title={translateMainMenu('Complaint/ComplaintEntry/title')} titleEdit={translateMainMenu('Complaint/ComplaintEntry/titleEdit')} titleCreate={translateMainMenu('Complaint/ComplaintEntry/titleCreate')} />
				<ListPart dataType='InquiryItemFile' title={translateMainMenu('Complaint/InquiryItemFile/title')} titleEdit={translateMainMenu('Complaint/InquiryItemFile/titleEdit')} titleCreate={translateMainMenu('Complaint/InquiryItemFile/titleCreate')} />
			</ListPart>,
			<ListPart key='8' useLinkName='ProjectItemFinalFile' dataType='ProjectItemFile' title={translate('ProjectItemFinalFile/title')} titleEdit={translate('ProjectItemFinalFile/titleEdit')} titleCreate={translate('ProjectItemFinalFile/titleCreate')} />,
			<ListPart key='9' useLinkName='ProjectItemSourceFile' dataType='ProjectItemFile' title={translate('ProjectItemSourceFile/title')} titleEdit={translate('ProjectItemSourceFile/titleEdit')} titleCreate={translate('ProjectItemSourceFile/titleCreate')} />
		];
	}

	const projectItemTabsArray = [
		<EditOnePart key='1' dataType='QualityAssurance' title={translate('QualityAssurance/title')} />,
		<ListPart key='2' dataType='ProjectItemSupplier' polymorphic title={translate('ProjectItemDelivery/title')} titleEdit={translate('ProjectItemDelivery/titleEdit')} titleCreate={translate('ProjectItemDelivery/titleCreate')} />,
		<ListPart key='3' dataType='ProjectItemFile' title={translate('ProjectItemFile/title')} titleEdit={translate('ProjectItemFile/titleEdit')} titleCreate={translate('ProjectItemFile/titleCreate')} sort={[{ name: 'FilePackage', dir: 1 }]} />,
		<ListPart key='4' useLinkName='ProjectItemReferenceFile' dataType='ProjectItemFile' title={translate('ProjectItemReferenceFile/title')} titleEdit={translate('ProjectItemReferenceFile/titleEdit')} titleCreate={translate('ProjectItemReferenceFile/titleCreate')} />,
		<ListPart key='5' dataType='TranslationStat' title={translate('TranslationStat/title')} titleEdit={translate('TranslationStat/titleEdit')} titleCreate={translate('TranslationStat/titleCreate')} />,
		<ListPart key='6' dataType='ChangeLog' title={translate('ChangeLog/title')} titleEdit={translate('ChangeLog/titleEdit')} titleCreate={translate('ChangeLog/titleCreate')} />,
		<ListPart key='7' name="complaint" dataType='Complaint' title={translateMainMenu('Offer/OfferItem/Complaint/title')} titleEdit={translateMainMenu('Offer/OfferItem/Complaint/titleEdit')} titleCreate={translateMainMenu('Offer/OfferItem/Complaint/titleCreate')} >
			<ListPart dataType='ComplaintEntry' title={translateMainMenu('Complaint/ComplaintEntry/title')} titleEdit={translateMainMenu('Complaint/ComplaintEntry/titleEdit')} titleCreate={translateMainMenu('Complaint/ComplaintEntry/titleCreate')} />
			<ListPart dataType='InquiryItemFile' title={translateMainMenu('Complaint/InquiryItemFile/title')} titleEdit={translateMainMenu('Complaint/InquiryItemFile/titleEdit')} titleCreate={translateMainMenu('Complaint/InquiryItemFile/titleCreate')} />
		</ListPart>
	]

	return projectItemTabsArray
}