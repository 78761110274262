import React from 'react'
import { Horizontal, Vertical } from 'components/topology/Topology'
import { Task } from './Task'

export function RectsGanttElement({ start, mapElements, onClickTreeChild }) {
	if (start.length === 0)
		return null

	return (
		<Vertical>
			{
				start.map((id, index) => {
					let e = mapElements.get(id)
					return (
						<Horizontal>
							<Task row={e.tree.row} rowIndex={e.tree.rowIndex} onClickTreeChild={onClickTreeChild} />
							<RectsGanttElement start={e.next} mapElements={mapElements} />
						</Horizontal>
					)
				})
			}
		</Vertical>
	)
}