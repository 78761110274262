
import LoggerSinkInterface from './LoggerSinkInterface';

/* 
Wysyła logi do session storage.
*/
export default class InMemoryStorageSink extends LoggerSinkInterface {
    constructor(maxEntries, excludeCleanupOfModules) {
        super();

        this._logs = [];
        this._maxEntries = maxEntries ?? 2000;
        this._excludeCleanupOfModules = excludeCleanupOfModules ?? [];
        this._numberOfThatHasBeenExcludedFromCleanup = 0;
    }

    initialize() {
        super.initialize()

        // Nie chcemy spowodować zapchania pamięci, dlatego będziemy czyścić stare wpisy
        const self = this;
        setInterval(() => {self.cleanupEntries();}, 1 * 60 * 1000)
    }

    get id(){
        return 'InMemory';
    }

    trace(module, ...args) {
        this._logs.push(this._createLogEntry(module, 'trace', args));
    }

    info(module, ...args) {
        this._logs.push(this._createLogEntry(module, 'info', args));
    }

    warn(module, ...args) {
        this._logs.push(this._createLogEntry(module, 'warn', args));
    }

    error(module, ...args) {
        this._logs.push(this._createLogEntry(module, 'error', args));
    }

    _createLogEntry(module, level, args) {
        return {
            module,
            level,
            timestamp: new Date(),
            args: [...args]
        }
    }

    cleanupEntries() {
        console.log("Execution of cleanupEntries()");
        if(this._logs.length < this._maxEntries)
            return;

        const numberOfEntriesToDelete = this._logs.length - this._maxEntries;
        const startAtIndex = this._numberOfThatHasBeenExcludedFromCleanup;
        this._numberOfThatHasBeenExcludedFromCleanup += this._cleanupEntriesInternal(startAtIndex, numberOfEntriesToDelete);
    }

    /*
    * Czyści pozycje wykraczające ponad dozwoloną liczbę obiektów zachowując te porządane.
    * @return Liczba elementów pominiętych podczas usuwania
    */
    _cleanupEntriesInternal(startAtIndex, numberOfEntriesToDelete) {
        this.info('Logger.Sinks.InMemory', 'Entries to delete: ' + numberOfEntriesToDelete + ' starting at ' + startAtIndex);
        if(startAtIndex >= this._logs.length)
            return 0;

        const deletedEntries = this._logs.splice(startAtIndex, numberOfEntriesToDelete);
        const entriesToReInsertToLogs = deletedEntries.filter(entry => this._excludeCleanupOfModules.includes(entry.module));
        if(entriesToReInsertToLogs.length > 0)
        {
            this.info('Logger.Sinks.InMemory', 'Reinserting number of elements: ' + entriesToReInsertToLogs.length);
            this._logs.unshift(...entriesToReInsertToLogs);
            return entriesToReInsertToLogs.length;
        }

        return 0;
    }

    get length(){
        return this._logs.length;
    }

    elementAt(index) {
        try {
            return this._logs[index];
        } catch (error) {
            return null;
        }
    }
}