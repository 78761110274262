import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Preloader,  useForm, } from 'components/form/Form'
import Button from 'components/form/components/list/components/buttons/Button'
import { Link} from '@material-ui/core'
import { useLocale } from 'locale/Locale'
import config from 'config'
import UploadFile from 'components/uploadFile/UploadFile'
import Accordion from 'components/accordion/Accordion'
import AccordionCard from 'components/accordion/AccordionCard'
import { Combobox, ComboboxMenu } from 'components/form/components/combobox/Combobox'
import { Checkbox } from 'components/form/components/checkbox/Checkbox'
import { appCtrl } from 'AppCtrl'
import Card from 'components/card/Card'
import { isEmpty, isUndefined, omitBy } from 'lodash'
import { Horizontal } from 'components/topology/Horizontal'
import { useSnackbar } from 'notistack'

/**
 * Komponent zwracający przycisk do pobrania pliku z tłumaczeniami
 * @param {Object} referenceLanguageField - Obiekt Field dla kontrolki "ReferenceLanguage"
 * @param {Object} targetLanguageField - Obiekt Field dla kontrolki "TargetLanguage"
 * @param {Boolean} isOrganizationContext - Parametr mówiący o tym czy komponent jest wyświetlany w kontekście organizacji
 * @param {Boolean} onlyNotTranslatedField - Parametr mówiący o tym, że chcemy otrzymać tylko nieprzetłumaczone stringi
 * @returns 
 */
const LocaleDownloadButton = ({ referenceLanguageField, targetLanguageField, isOrganizationContext, onlyNotTranslatedField }) => {

	const locale = useLocale()
	const { enqueueSnackbar } = useSnackbar()
	
	if(isEmpty(referenceLanguageField?.value))
		return null

	//Funkcja tworząca url do pobrania pliku oraz obsługująca błędy 
	const createDownloadTranslationUrl = () => {
		const serviceURL = config.serviceUrl
 		const params = omitBy({
			referenceLanguage: referenceLanguageField.value?.id || undefined,
			targetLanguage: targetLanguageField.value?.id || undefined,
			organizationId: (isOrganizationContext && appCtrl.session.currentOrganizationInfo.id) || undefined,
			onlyNotTranslated: onlyNotTranslatedField.value || undefined,
		}, isUndefined)//omitBy - isUndefined -> Jeśli któryś z atrybutów obiektu będzie miał wartość 'undefined', wtedy nie zostanie on zwrócony w wynikowym obiekcie 

		const translationUnitsParams = new URLSearchParams(params)

		try {
			if(translationUnitsParams.has('targetLanguage') || translationUnitsParams.has('referenceLanguage')){
				return new URL(`${serviceURL}/system/translationunits?${translationUnitsParams.toString()}`).href
			}
			else {//Sytuacja gdy nie ma parametrów 'targetLanguage' lub 'referenceLanguage'
				enqueueSnackbar(locale.translate('WebSpa/Snackbar/InternationalizationAdminPage/Export/noRequiredParams'), { variant: 'error' })
				console.error(locale.translate('WebSpa/Snackbar/InternationalizationAdminPage/Export/noRequiredParams'))
				return null
			}
		}
		catch(error){
			enqueueSnackbar(locale.translate('WebSpa/Snackbar/InternationalizationAdminPage/Export/defaultError'), { variant: 'error' })
			console.error(locale.translate('WebSpa/Snackbar/InternationalizationAdminPage/Export/defaultError'), error)
			return null
		}
	}	

	const downloadTranslationUnitsUrl = createDownloadTranslationUrl()

	if(!downloadTranslationUnitsUrl)
		return null

	return (
		<Link href={downloadTranslationUnitsUrl} underline='none' >
			<Button type='submit' content={locale.translate('WebSpa/Buttons/download')} />
		</Link>
	)
}

/**
 * Komponent zwracający widok "Eksportu" danych dla pozycji "Internacjonalizacja"
 * @param {Boolean} isOrganizationContext - Parametr mówiący o tym czy komponent jest wyświetlany w kontekście organizacji
 * @returns 
 */
const ExportLanguageFileContent = ({ isOrganizationContext }) => {
	const form = useForm('data')
	const REFERENCE_LANGUAGE_FIELD_NAME = 'ReferenceLanguage'
	const TARGET_LANGUAGE_FIELD_NAME = 'TargetLanguage'
	const ONLY_NOT_TRANSLATED_FIELD_NAME = 'OnlyNotTranslated'
	const referenceLanguageField = form.getField(REFERENCE_LANGUAGE_FIELD_NAME)
	const targetLanguageField = form.getField(TARGET_LANGUAGE_FIELD_NAME)
	const onlyNotTranslatedField = form.getField(ONLY_NOT_TRANSLATED_FIELD_NAME)
	
	const referenceLanguageInputParams = {
		label: referenceLanguageField.label,
		hasLabel: true,
	}
	const targetLanguageInputParams = {
		label: targetLanguageField.label,
		hasLabel: true,
	}

	const onChange = useCallback(() => {
		onlyNotTranslatedField.handleChange(!onlyNotTranslatedField.value)
		onlyNotTranslatedField.form.trigger('data')
		onlyNotTranslatedField.form.parentForm.trigger('data')
	}, [onlyNotTranslatedField])

	return (
		<>
			<Horizontal g="20%">
				<Combobox 
					disableClearable
					name={REFERENCE_LANGUAGE_FIELD_NAME}
					value={referenceLanguageField.value || {}}
					inputParams={referenceLanguageInputParams}
					options={referenceLanguageField?.meta?.values || []}
					getOptionLabel={(option) => option.value || ""}
					getOptionSelected={((option, value) => !isEmpty(option) && option.id === value.id)}
					renderOption={(option, currentInputValue) =>  <ComboboxMenu option={option.value || ""} inputValue={currentInputValue} name={REFERENCE_LANGUAGE_FIELD_NAME}/> }
					onChange={ (e, newValue) =>  referenceLanguageField.handleChange(newValue) }
				/>
				<Combobox 
					name={TARGET_LANGUAGE_FIELD_NAME}
					value={targetLanguageField.value || {}}
					inputParams={targetLanguageInputParams}
					options={targetLanguageField?.meta?.values || [] }
					getOptionLabel={(option) => option.value || ""}
					getOptionSelected={((option, value) => !isEmpty(option) && option.id === value.id)}
					renderOption={(option, currentInputValue) =>  <ComboboxMenu option={option.value || ""} inputValue={currentInputValue} name={TARGET_LANGUAGE_FIELD_NAME}/> }
					onChange={ (e, newValue) => targetLanguageField.handleChange(newValue) }
				/>
				<Checkbox
					name={ONLY_NOT_TRANSLATED_FIELD_NAME}
					value={onlyNotTranslatedField.value || undefined}
					disabled={false}
					label={onlyNotTranslatedField.label}
					//dataType={confirmedField.dataType}
					hasLabel={true}
					onChange={onChange}
				/>
			</Horizontal>
			<LocaleDownloadButton 
				targetLanguageField={targetLanguageField} 
				isOrganizationContext={isOrganizationContext}
				referenceLanguageField={referenceLanguageField} 
				onlyNotTranslatedField={onlyNotTranslatedField} 
			/>
		</>
	)
}


/**
 * Komponent zwracający widok "Importu" danych dla pozycji "Internacjonalizacja"
 * @param {Boolean} isOrganizationContext - Parametr mówiący o tym czy komponent jest wyświetlany w kontekście organizacji
 * @returns 
 */
const ImportLanguageFileContent = ({ isOrganizationContext }) => {

	const FILE_FIELD_NAME = 'FileImport'

	const createUploadTranslationUrl = () => {

		const params = omitBy({
			organizationId: (isOrganizationContext && appCtrl.session.currentOrganizationInfo.id) || undefined,
		}, isUndefined)//omitBy - isUndefined -> Jeśli któryś z atrybutów obiektu będzie miał wartość 'undefined', wtedy nie zostanie on zwrócony w wynikowym obiekcie 
		
		const fileFieldParams = new URLSearchParams(params).toString()
			
		return `/system/translationunits${fileFieldParams.length ? '?'+ fileFieldParams : '' }`
	}

	const uploadTranslationUnitsUrl = createUploadTranslationUrl()

	if(!uploadTranslationUnitsUrl)
		return null

	return (
		<UploadFile name={FILE_FIELD_NAME} hasLabel={true} serviceName={uploadTranslationUnitsUrl}/>
	)	
}

/**
 * Komponent zwracający dwa Komponenty Accordeon dla Importu oraz Eksportu lokalizacji
 * @param {Boolean} isOrganizationContext - Parametr mówiący o tym czy komponent jest wyświetlany w kontekście organizacji
 * @returns 
 */
 const InternationalizationAdminPage = ({ isOrganizationContext }) => {
	const locale = useLocale()
	
	return (
		<Card style={{marginTop: 15}}>
			<Preloader>
				<Accordion isOpen={true} title={locale.translate('WebSpa/Internationalization/Accordion/export')}>
					<AccordionCard>
						<ExportLanguageFileContent isOrganizationContext={isOrganizationContext}/>
					</AccordionCard>
				</Accordion>
				<Accordion isOpen={true} title={locale.translate('WebSpa/Internationalization/Accordion/import')}>
					<AccordionCard>
						<ImportLanguageFileContent isOrganizationContext={isOrganizationContext}/>
					</AccordionCard>
				</Accordion>
			</Preloader>
		</Card>
	)
}

/* #region Export komponentu */
export default InternationalizationAdminPage
//#endregion


/* #region  PropTypes */
InternationalizationAdminPage.propTypes = {
	isOrganizationContext: PropTypes.bool
}

ImportLanguageFileContent.propTypes = {
	isOrganizationContext: PropTypes.bool
}

ExportLanguageFileContent.propTypes = {
	isOrganizationContext: PropTypes.bool
}

LocaleDownloadButton.propTypes = {
	referenceLanguageField: PropTypes.object.isRequired,
	targetLanguageField: PropTypes.object.isRequired,
	isOrganizationContext: PropTypes.bool,
	onlyNotTranslatedField: PropTypes.bool
}
/* #endregion */


/* #region  Domyślne propsy */
LocaleDownloadButton.defaultProps = {
	referenceLanguageField: {},
	targetLanguageField: {},
	onlyNotTranslatedField: {},
}
/* #endregion */