import React from 'react'
import useForm from 'components/form/hooks/useForm'
import Icon from 'components/icon/Icon'

export function HeaderSort({ column }) {
	const form = useForm('data')
	const sortDir = form.columnManager.getSortDir(column.name)
	const groupDir = form.columnManager.getGroupDir(column.name)

	if (sortDir !== 0)
		return <Icon name={sortDir > 0 ? 'arrow_drop_down' : 'arrow_drop_up'} />

	if (groupDir !== 0)
		return <Icon name={groupDir > 0 ? 'arrow_downward' : 'arrow_upward'} />

	return null
}