import React from 'react'
import useField from '../../hooks/useField'
import { customElements } from 'cssStatic'

const LabelFieldStyled = customElements('o-label-field', [
	'noselect', 'f-primary', 'f-16', 'text-grey700', {
		padding: '7px 10px'
	}
])

export default function LabelField({ name }) {
	const field = useField(name)

	return (
		<LabelFieldStyled>
			{field.valueInput}
		</LabelFieldStyled>
	)
}