import { GlobalStrings } from "GlobalStrings.js";


/*{
	meta: {
		dataType: "Nazwa typu",
		attributes: {},
		sections: {},
		buttons: {}
	},
	data: [
		{
			type: "PriceList",
			id: "09bcff12-80e9-4799-a157-9cb261e38e4c",
			attributes: {},
			meta: {
				attributes: {},
				dataType: "PriceList",
				sections: {},
				buttons: {},
			},
			ui: { attributes: { name: {name:'nazwa', sections: [] } } },
			links: {}
		}
	],
	messages: [],
	links: {},
	ui: { attributes: { name: {name:'nazwa', sections: [] } } },
}*/

/**
 * Zmerguj linki z BE z linkami z FE
 * Zasady mergowania:
 * - linki referencyjne do pól zostają mergowane (aktualne linki i nowe linki z BE)
 * - inne rodzaje linków zostają całkowicie zastąpione linkami dostarczonymi BE
 * @param {dictionary} linki w aplikacji
 * @param {dictionary} nowe linki z BE
 * @returns {dictionary} zmergowane linki Ref
 */
export const mergeLinks = (currentLinks,linksFromBE) => {

	if (!currentLinks)
		return linksFromBE;

	if (!linksFromBE)
		return _filterRefRedirectLinks(currentLinks);

	let mergedLinks = {};
	let refLinksFromBE = _filterRefRedirectLinks(linksFromBE);
	

	for (const [key, link] of Object.entries(currentLinks)) {

		if (!_isLinkRefRedirectLink(key))
			continue;

		if (refLinksFromBE[key]) {
			mergedLinks[key] = refLinksFromBE[key];
			continue;
		}

		mergedLinks[key] = link;	
	}

	for (const [key, link] of Object.entries(linksFromBE)) {			
		mergedLinks[key]  = link;
	}

 	return mergedLinks;
}

/**
 * @param {dictionary} Przefitruj linki, które nie są referencyjne
 */
const _filterRefRedirectLinks = (links) => {

	if (!links)
		return links;

	return Object.fromEntries(Object.entries(links).filter( ([key,link]) => _isLinkRefRedirectLink(key) ))
}

/**
 * Sprawdź czy link jest linkiem referencyjnym do pola
 * @param {string} nazwa linku
 * @returns {boolen} 
 */
const _isLinkRefRedirectLink = (linkName) => linkName.substring(0, 3) === GlobalStrings.ResourceGoToRefLinkName;

function uiToMeta(obj) {
	const helper = o => {
		if (o.ui?.attributes)
			for (let key in o.ui.attributes)
				o.meta.attributes[key] = { ...o.meta.attributes[key], ...o.ui.attributes }
	}

	helper(obj)
	obj.data.forEach(row => helper(row))
}


function _mergeLinks(dstLinks, srcLinks) {
	if (!srcLinks)
		return dstLinks

	// const newLinks = Object.values(dstLinks)
	// 	.filter(l => l.rel.startsWith('get'))
	// 	.reduce((p, c) => {
	// 		p[c.rel] = c
	// 		return p
	// 	}, {})

	//return { ...srcLinks, ...newLinks }
	return { ...dstLinks, ...srcLinks }
}


function _merge(dst, src) {
	if (src?.meta?.dataType)
		dst.meta.dataType = src.meta.dataType

	if (src?.meta?.attributes)
		dst.meta.attributes = {
			...dst.meta.attributes,
			...src.meta.attributes
		}

	dst.links = _mergeLinks(dst.links, src.links)

	if (src.ui)
		dst.ui = src.ui
}

function _mergeDataAttributes(dstAttr, srcAttr) {
	for (var i in srcAttr) {
		if (srcAttr[i] === null)
			delete dstAttr[i]
		else
			dstAttr[i] = srcAttr[i]
	}
}

function _mergeRow(dstRow, srcRow) {
	if (srcRow.id)
		dstRow.id = srcRow.id

	if (srcRow.attributes)
		_mergeDataAttributes(dstRow.attributes, srcRow.attributes)

	if (srcRow.links)
		dstRow.links = srcRow.links
	else
		dstRow.links = []

	if (srcRow?.meta?.attributes)
		dstRow.meta.dataType = srcRow.meta.dataType
}

function _mergeByOrder(dst, src) {
	if (dst.data?.length !== src.data?.length)
		throw new Error('Error in merge dst.data?.length!==src.data?.length')

	dst.data.forEach((dstRow, index) => {
		const srcRow = src.data[index]
		_mergeRow(dstRow, srcRow)
	})
}

function _mergeById(dst, src) {
	const map = new Map()

	dst.data.forEach(row => map.set(row.id, row))

	src.data.forEach(srcRow => {
		const dstRow = map.get(srcRow.id)

		if (!dstRow)
			throw new Error('Error in merge dstRow not found')

		_mergeRow(dstRow, srcRow)
	})
}

export function merge(dst, src, byOrder) {
	_merge(dst, src)

	if (byOrder)
		_mergeByOrder(dst, src)
	else
		_mergeById(dst, src)

	uiToMeta(dst)
}

