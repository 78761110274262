import React from "react"

/**
 * Pomocniczego hook'a opóźniającego rządania.
 * 
 * @param {function} handler Funkcja do ktorej dochodzi powiadomienie
 * @param {integer} delay Min czas pomiedzy uruchomieniami
 *
 * @example 
 * //Uruchamia handler() nie częsciej niż delay
 * const [setDebounceValue]=useDebounce(()=>{
 *	asyncLoad()
 * },500)
 *
 * //Każde uruchomienie z nową wartością uruchamia czasomierz. 
 * setDebounceValue(newValue)
 * 
 */
export default function useDebounce(handler, delay) {
	const [first, setFirst] = React.useState(true)
	const [value, setValue] = React.useState()
	const [stop, setStop] = React.useState(false)

	React.useEffect(
		() => {
			let timer

			if (first) {
				setFirst(false)
				return
			}

			if (stop) {
				clearTimeout(timer)
				return
			}

			timer = setTimeout(() => {
				handler(value)
			}, delay)

			return () => {
				clearTimeout(timer)
			}
			// eslint-disable-next-line
		}, [value, delay, stop])

	return [
		v => { setValue(v); setStop(false) },
		() => { setValue(value ? 1 : value + 1) },
		() => setStop(true)
	]
}