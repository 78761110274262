import React from 'react'
import { useForm } from 'components/form/Form'
import Icon from 'components/icon/Icon'

export function TreeAddChild({ tree, onClickTreeChild, rowIndex }) {
	const form = useForm('data')

	if (!form.serviceCreateTreeChildHRef)
		return null

	return (
		<Icon
			style={{ marginRight: '15px' }}
			name="add"
			md={16}
			onClick={e => {
				onClickTreeChild && onClickTreeChild({ formRow: form, rowIndex })
				e.stopPropagation()
			}}
		/>
	)
}