import React from 'react'
import * as PositionObserver from 'components/positionObserver/PositionObserver'
import { Css } from 'cssStatic'

Css.add({
	'.o-gantt-rectangle':[
		'blue','height-20','br-radius-3','z-index-2'
	]
})

export function GanttRectangle({ form, rowIndex }) {
	const row = form.parentForm.rowManager.visibleRows[rowIndex]
	const ref = PositionObserver.useAddElement(row.id + '')
	const positionGroup = PositionObserver.useGroup()
	const ganttManager = form.parentForm.ganttManager
	const ganttPrev = 'DependOnItemsListId'
	const metaGanttPrev = form.getMeta(ganttPrev)

	return (
		<div className='o-gantt-rectangle'
			ref={ref}
			onDragStart={e => {
				if (e.shiftKey) {
					e.preventDefault()
					return
				}

				if (!form.serviceUpdate || !metaGanttPrev) {
					e.preventDefault()
					return
				}

				let img = new Image()
				e.dataTransfer.setDragImage(img, 0, 0)
				e.dataTransfer.effectAllowed = 'link'
				ganttManager.dragId = row.id

				//let img = new Image()
				//e.dataTransfer.setDragImage(img, 0, 0)

				// let div=document.createElement('div')

				// div.innerHTML='konrad to ja'
				// e.target.parentNode.appendChild(div)
				// e.dataTransfer.setDragImage(div, 0, 0)

				// e.dataTransfer.dropAllowed='link'
				// e.dataTransfer.effectAllowed = 'link'
				// e.dataTransfer.setData("text/plain", 'hhh')
				// console.log('setData '+'hhh')
				// console.log(`start ${e.clientX} ${e.clientY} ${positionGroup ? 'g' : '_'}`)

				// if (positionGroup) {
				// 	positionGroup.newLine = { begin: { x: e.clientX, y: e.clientY } }
				// 	positionGroup.observers.delayedRefresh(10)
				// }
			}}
			onDragOver={e => {
				// console.log(`over  ${e.clientX} ${e.clientY} ${positionGroup ? 'g' : '_'}`)
				// //e.dataTransfer.effectAllowed='link'
				// let id=e.dataTransfer.getData('text','a')
				// console.log('getData '+id)
				// e.preventDefault()

				if (!form.serviceUpdate || !metaGanttPrev)
					return
				if (ganttManager.dragId === row.id)
					return
				e.preventDefault()

				// if (positionGroup) {
				// 	positionGroup.newLine.end = { x: e.clientX, y: e.clientY }
				// 	positionGroup.observers.delayedRefresh(10)
				// }
			}}
			onDragEnd={e => {
				//console.log('end')
			}}
			onDrop={e => {
				const field = form.getField(ganttPrev)
				let value = field.value

				if (!Array.isArray(value))
					value = []

				if (value.some(v => v.id === ganttManager.dragId))
					return

				value = [...value]
				value.push({ id: ganttManager.dragId })
				form.handleChange(ganttPrev, value)
				form.asyncSubmit('ganttLink')
				ganttManager.dragId = undefined
				//console.log('drop')
				e.preventDefault()
				// if (positionGroup) {
				// 	positionGroup.newLine = undefined
				// 	positionGroup.observers.delayedRefresh(10)
				// }
			}}
			draggable
		>

		</div>
	)
}