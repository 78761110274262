import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'
import React from 'react'
import DV from '../../../components/dataView/DataView'
import EditOnePartInner from './EditOnePartInner'
import EditOnePartOuter from './EditOnePartOuter'

function InnerDecoration({ child1, child2 }) {
	if (!child1)
		return null

	return <div className='layout-part'>
		{child1}
		{child2}
	</div>
}

export default function EditOnePart({ part, name, dataType, useLinkName, title, children, createEditForm, polymorphic, onLoad }) {
	return (
		<ErrorBoundary>
			<DV.Part
				part={part}
				name={name}
				title={title}
				outerContent={<EditOnePartOuter useLinkName={useLinkName} dataType={dataType} onLoad={onLoad}/>}
				innerContent={<EditOnePartInner createEditForm={createEditForm} polymorphic={polymorphic} />}
				innerDecoration={<InnerDecoration />}
			>
				{children}
			</DV.Part>
		</ErrorBoundary>
	)
}
