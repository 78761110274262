import React from 'react'
import { Preloader, MessageListener } from 'components/form/Form'
import Card from 'components/card/Card'
import { Sections } from 'components/form/components/sections/Sections'
import { Buttons } from 'components/form/components/list/components/buttons/Buttons'
import Tab from 'contents/main/dataView/Tab'
import { HorizontalPair } from 'components/topology/Topology'
import { IconMessages } from 'components/iconMessages/IconMessages'

export default function EditPartInner({ part, createEditForm, polymorphic }) {
	if (createEditForm)
		return (
			<React.Fragment>
				<MessageListener />
				<Tab tab={part.editTabs} />
				{
					part.isSelected &&
					createEditForm({ part, polymorphic })
				}
			</React.Fragment>
		)

	return (
		<React.Fragment>
			<MessageListener />
			<Tab tab={part.editTabs} />
			{
				part.isSelected &&
				<Card>
					<Preloader>
						<HorizontalPair
							left={<IconMessages />}
							right={<Buttons part={part}/>}
						/>
						<Sections polymorphic={polymorphic}/>
					</Preloader>
				</Card>
			}
		</React.Fragment>
	)
}
