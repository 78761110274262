import { Menu, withStyles, makeStyles } from '@material-ui/core'
import {  Tooltip } from '@material-ui/core'

//Meterial UI Styles

export const StyledMenu = withStyles(({ palette }) => ({
	list: {
		paddingRight: '0 !important', 
	},
	paper: {
		background: palette.primary.mainDarken800,
		borderRadius: '0 4px 4px 0',
		border: `1px solid ${palette.primary.mainDarken1000}`	
	},
}))(Menu)

export const useListItemStyles = makeStyles(({ palette }) => ({
	root: {
		color: `${palette.secondary.white} !important`,
		paddingTop: 10,
		paddingBottom: 10,
		width: props => props.isMenuOpen ? '100% !important' : '70px !important',

		'& .MuiListItemText-root':{
			marginBottom: 0,
			color: `${palette.secondary.white} !important`,
		},

	},

	selected: {
		background: palette.primary.mainDarken900,
	}
}))

export const StyledTooltip = withStyles(() => ({
	tooltip: {
		fontSize: '16px',
	},
	popper: {
		marginLeft: '-5px',
	},

}))(Tooltip)
