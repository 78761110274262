import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
/**
 * Klasa menagera organizującego wiersze w listach
 */
export default class RowManager {
	constructor(form) {
		this._form = form
		this._visibleRows = []
		this._selectedRowId = null
		this._editRowId = null
		this.inlineEdition = false
	}

	get form() { return this._form }

	//--------- Zaznaczony wiersz ---------

	get selectedRowId() { return this._selectedRowId }
	set selectedRowId(v) { this._selectedRowId = v }
	getRowIndexFromId(id) {
		return this._form.rows.findIndex(row => row.id === id)
	}

	//--------- Zaznaczanie wielu wierszy ---------

	get enableMultiMarked() { return this._enableMultiMarked }
	set enableMultiMarked(v) { this._enableMultiMarked = v }

	/**
	 * Wszystkie rekordy odznaczane.
	 * Zaznaczane rekordy od id do selectedRow lub
	 * zaznaczane od selectedRow to id
	 */
	markToSelectedRow(id) {
		this.clearMarks()
		if (!this._selectedRowId)
			return

		let index1 = this.getRowIndexFromId(id)
		let index2 = this.getRowIndexFromId(this._selectedRowId)

		if (index1 === -1 || index2 === -1)
			return

		if (index1 > index2) {
			let h = index1
			index1 = index2
			index2 = h
		}

		this._marks = {}

		for (let index = index1; index <= index2; ++index) {
			const row = this.visibleRows[index]
			this._marks[row.id] = true
		}
		//Udało się zamarkować
		return true
	}

	clearMarks() {
		this.marks = undefined
	}

	setIsMarked(id, isMarked) {
		if (!this._marks)
			this._marks = {}

		if (isMarked)
			this._marks[id] = true
		else
			delete this._marks[id]
	}

	isMarked(id) {
		return this._marks && (id in this._marks)
	}

	toogleIsMarked(id) {
		this.setIsMarked(id, !this.isMarked(id))
	}

	get marks() {
		if (!this._marks)
			return []
		return Object.keys(this._marks)
	}

	set marks(v) {
		if (!v) {
			this._marks = {}
			return
		}

		this._marks = v.reduce((p, c) => {
			p[c] = true
			return p
		}, {})
	}

	get marksOrSelectedIds() {
		let marks = this.marks

		if (this._selectedRowId && !marks.includes(this._selectedRowId))
			marks.push(this._selectedRowId)
		return marks
	}

	get marksOrSelectedDatas() {
		const data = { ...this._form._data }
		const marks = new Set(this.marksOrSelectedIds)
		
		data.data=data.data.filter(d=>marks.has(d.id))
		return data
	}

	//--------- Widoczność edit create ---------

	get isCreateVisible() { return this._isCreateVisible }
	set isCreateVisible(v) { this._isCreateVisible = v }
	get isEditVisible() { return this._isEditVisible }
	set isEditVisible(v) { this._isEditVisible = v }

	get rowData() { return this._rowData }
	set rowData(v) { this._rowData = v }

	//--------- Edycja wiersza ---------

	get editRowId() { return this._editRowId }
	set editRowId(v) { this._editRowId = v }

	//--------- Wypełnianie zaznaczonego wiersza ---------

	getEmptyRow() {
		return {
			type: this._form.dataType,
			meta: {
				dataType: this._form.dataType,
				attributes: {}
			},
			attributes: {},
			links: {}
		}
	}

	getEmptyData() {
		return {
			meta: this._form.data.meta,
			data: [
				this.getEmptyRow()
			],
			links: this._form.data.links
		}
	}

	getDataFromRow(row, ui) {
		return {
			meta: this._form.data.meta,
			data: [
				row
			],
			links: this._form.data.links,
			messages: this._form.data.messages,
			ui
		}
	}

	getDataFromId(id) {
		let index = this.getRowIndexFromId(id)

		if (index === -1)
			return

		return this.getDataFromRow(this._form.rows[index], this._form._data.ui)
	}

	setRowFromFormChild(formChild) {
		const id = formChild.id
		const index = this.getRowIndexFromId(id)

		if (index !== -1) {
			this._form.rows[index] = formChild.data.data[0]

			if (!this._form._data?.messages || !isArray(this._form._data.messages)) {
				this._form._data.messages = []
			}

			if (isEmpty(formChild.data.messages)) {//Jeśli "message" z BE przychodzą jako pusta tablica, usuwamy z formy wszystkie powiązane z danym rekordem "message"
				this._form._data.messages = this._form._data.messages.filter(message => message.resourceId !== formChild.data.data?.[0]?.id)
			}
			else { //Jeśli z BE przychodzą "message" dla danego rekordu, dodajemy je do wszystkich  "message'ów" z formy

				//TODO usuwać duplikaty po id wiadomości zamist po opisie
				const messagesWithoutDuplicates = uniqBy([...this._form._data.messages, ...formChild.data.messages], 'operationDepiction') 
				this._form._data.messages = messagesWithoutDuplicates
			}
		}

		if (this._rowData && this._rowData.data[0].id === id)
			this._rowData.data[0] = formChild.data.data[0]

	}

	setRowDataAsCreate() {
		this._rowData = this.getEmptyData()
	}

	setRowDataFromSelectedRow() {
		this._rowData = this.getDataFromId(this._selectedRowId)
	}

	/**
	 * Metoda zwracająca obiekt 'attributes' obecnie zaznaczonego wiersza.
	 * @returns {Object}
	 */
	getCurrentSelectedRowAttributes() {
		const currentSelectedRow = this.getDataFromId(this._selectedRowId)

		if (!isEmpty(currentSelectedRow))
			return currentSelectedRow.data[0].attributes
	}

	/**
	 * Po zmianie this._form._data.data
	 * Wymagane jest odswieżenie this._rowData
	 * I to wykonuje ta funkcja 
	 */
	refreshRowData() {
		if (!this._rowData || !this._selectedRowId)
			return

		const data = this._rowData?.data?.[0]
		const newRowData = this.getDataFromId(this._selectedRowId)

		//Sprawdzenie czy zawartość rowData uległa zmianie
		if (data === newRowData?.data?.[0])
			return

		this._rowData = newRowData
		this._form.trigger('data')
	}

	pushRowFromRowData(newIndex) {
		if (!this._rowData)
			return

		if (newIndex)
			this._form.rows.splice(newIndex, 0, this._rowData.data[0])
		else
			this._form.rows.push(this._rowData.data[0])
	}

	//--------- Zarządzanie wierszami wirtualnymi ---------

	get visibleRows() {
		return this._visibleRows
	}

	initVisibleRowsFromData() {
		if (!this._form.rows) {
			this._visibleRows = []
			return
		}

		this._visibleRows = this._form.rows.slice()

		if (this._form.serviceCreate)
			this._visibleRows.push(this.getEmptyRow())
	}
}