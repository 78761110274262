import React from 'react'
import { Preloader } from 'components/form/Form'
import { Buttons } from 'components/form/components/list/components/buttons/Buttons'
import Card from 'components/card/Card'
import { Sections } from 'components/form/components/sections/Sections'
import { HorizontalPair } from 'components/topology/Topology'
import { IconMessages } from 'components/iconMessages/IconMessages'


export default function Company({ part, polymorphic }) {
	return (
		<Card>
			<Preloader>
				<HorizontalPair
					left={<IconMessages />}
					right={<Buttons part={part} />}
				/>
				<Sections polymorphic={polymorphic} />
			</Preloader>
		</Card>
	)
}
