import FormMetaObject from './FormMetaObject';

export default class ModalUPAFormMetaObject extends FormMetaObject
{
    constructor(params)
    {
        super(params);
    }

    shouldExcludeFieldFromRendering(field)
    {
        return field.name === "RelatedObjectsRefList";
    }
}