import React from 'react'
import DV from '../../../components/dataView/DataView'
import { FormMetaObject, useForm, FormConsumer } from 'components/form/Form'
import getFormRestService from "services/getFormRestService"
import { linkManager } from 'components/LinkManager'
import ModalUpa from 'components/modalUpa/ModalUpa'
import { GlobalStrings } from 'GlobalStrings'
import { MESSAGE_ACTIONS, useIconMessageDispatch } from 'components/AppMessages';

export default function EditOnePartOuter({ dataType, useLinkName, children, part, onLoad }) {
	const form = useForm('data')
	const dataView = DV.useDataView()
	const [modalButton, setModalButton] = React.useState(undefined)

	const iconMessageDispatch = useIconMessageDispatch();

	let readOne = form.getServiceRelativeReadOneHRef(useLinkName ? useLinkName : dataType)

	return (
		<FormMetaObject
			isFormUsingServices
			configurationPath={part.dataTypePath}
			editMode='edit'
			onLoad={async (form) => {
				part.props.form = form

				if(onLoad){
					onLoad(form)
					return
				}
				
				if (readOne && await getFormRestService(readOne).getOne(form)) {
					if (!linkManager.synchronize(dataView))
						dataView.refresh()
				}

				iconMessageDispatch({type: MESSAGE_ACTIONS.APPEND_MESSAGE, 
					payload: { 
						messages: form.data?.objectInfoMessages,
						updatedDataType: form.dataType,
						messagesOwnersId: [ form.data.data[0].id ]
					 }});
			}}
			dataType={dataType}
			useLinkName={useLinkName}
			onSubmit={async (form, submitType, props) => {
				if (submitType === GlobalStrings.reloadOnChangeSubmitType) {
					await form.handleReloadOnChangeSubmitType(props);
				}
				else if (!form.isOk)
					return
				else if (await form.onSubmitButton(submitType)) {
					if (form.parentForm) {
						form.parentForm.rowManager.rowData = { ...form.parentForm.rowManager.rowData }
						form.parentForm.trigger('data')
					}
				}
				else if (form.onDisplayModalUpa(submitType, button => setModalButton(button))) {}
				else {
					await getFormRestService(form.serviceUpdateHRef).patch(form, '')
				}
				
				iconMessageDispatch({type: MESSAGE_ACTIONS.APPEND_MESSAGE, 
					payload: { 
						messages: form.data?.objectInfoMessages,
						updatedDataType: form.dataType,
						messagesOwnersId: [ form.data.data[0].id ]
					 }});

				dataView.refresh()
			}}
		>
			<FormConsumer>
				{form => {
					if (typeof part.props.title === 'function')
						part.props.functionTitle = part.props.title
					if (part.props.functionTitle) {
						let t = part.props.title
						part.props.title = part.props.functionTitle(form)
						if (t !== part.props.title)
							dataView.refresh()
					}
					part.parts.forEach(p => {
						let dataType = p.props.useLinkName || p.props.dataType
						let r = form.getServiceRelativeRead(dataType) || form.getServiceRelativeReadOne(dataType)
						p.reachable = r !== undefined
					})
					return null
				}}
			</FormConsumer>
			<ModalUpa modalButton={modalButton} setModalButton={setModalButton} part={part}/>
			{children}
		</FormMetaObject>
	)
}
