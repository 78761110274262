import React from 'react'
import Paginator from 'components/form/components/paginator/Paginator'
import { Card, ListMode, ListButtons, GanttGroupElements, GanttObserveds, ListModeView } from 'components/form/components/list/components'
import { Preloader } from 'components/form/Form'
import { Vertical, HorizontalPair } from 'components/topology/Topology'
import { useHistory } from 'react-router-dom'
import { linkManager } from 'components/LinkManager'

export function List({ part, dataTypeAdd, isGantt, group, sort, inlineEditionOnly, onClickRow, onDoubleClickRow, onLaunch, additionalButtons }) {
	const history = useHistory()

	return (
		<>
			<HorizontalPair className='sticky top-0p z-index-1 white p-lr-15 p-tb-5'
				left={<ListMode part={part} onLaunch={onLaunch} additionalButtons={additionalButtons} />}
				right={<ListButtons part={part} />}
			/>
			<Card>
				<GanttGroupElements>
					<Preloader>
						<Vertical className='grid-gap-5'>
							<ListModeView part={part} dataTypeAdd={dataTypeAdd} isGantt={isGantt} group={group} sort={sort} inlineEditionOnly={inlineEditionOnly} onClickRow={onClickRow} onDoubleClickRow={onDoubleClickRow} />

							<HorizontalPair right={<Paginator onPage={() => {
								linkManager.handleListNewPage(history, part, true)
							}} />} />
						</Vertical>
					</Preloader>
					<GanttObserveds />
				</GanttGroupElements>
			</Card>
		</>
	)
}