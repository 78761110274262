import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import logo from 'icons/logo/livocat.png'
import Backdrop from '@material-ui/core/Backdrop';
import shouldClose from './DialogUtils';

export type PromiseResult = {
    errorMessage?: string
}

export type DialogYesNoProps = {
	isOpen: boolean,
    id?: string,
    title: string,
    text: string,
    onClose: () => void,
    onYes: () => Promise<PromiseResult>,
    onNo?: () => void
}

const DialogYesNo =  (props : DialogYesNoProps) => {

    const [showLoading, setShowLoading] = useState(false);

    const handleOnClose = (event: {}, reason: string) => {
        if(!shouldClose(showLoading, reason))
            return;

        props.onClose();
    }

    const handleOnYes = () => {
        setShowLoading(true);
        props.onYes()
            .then((value) => {
                props.onClose();
            })
            .catch((error) =>{
                console.error(error);
            })
            .finally(() => {
                setShowLoading(false);
            })
    }

    const handleOnNo = () => {
        if(props.onNo)
            props.onNo();

        props.onClose();
    }

    return (
    <Dialog 
        open={props.isOpen}
        onClose={handleOnClose}
        id={props.id}
    >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
        <DialogContentText>{props.text}</DialogContentText>
        </DialogContent>
        
        <DialogActions>
            <Button onClick={handleOnNo} color="primary" disabled={showLoading}>
                No
            </Button>
            <Button onClick={handleOnYes} color="primary"  disabled={showLoading} autoFocus>
                Yes {showLoading &&
                    <CircularProgress size={20} color='inherit'  style={{marginLeft: '10px'}} />
                } 
            </Button>
        </DialogActions>
    </Dialog>)
}

export default DialogYesNo;