import React from 'react'
import Fields from '../fields/Fields'
import useForm from '../../hooks/useForm'
import { SectionMain } from './SectionMain'
import { SectionLabel } from './SectionLabel'
import { SectionAccordion } from './SectionAccordion'
import { SectionDefault } from './SectionDefault'
import { SectionTab } from './SectionTab'
import { Sections } from './Sections'
import { getSectionFields } from '../fields/getSectionFields'
import isEmpty from 'lodash/isEmpty'
import './Sections.scss'

/**
 * Renderuje sekcje w zależności od section.displayType
 * @param {object} props
 * @param {object} section Opis sekcji
 * @param {boolean} polymorphic Czy polimorficzny
 * @returns {Node}
 */
export function Section({ section, polymorphic, parentSectionName }) {
	const form = useForm('data')
	const fields = getSectionFields(form, section.name)

	const childs = <>
		<Fields section={section.name} fieldStyle={section.style} />
		<Sections parentSectionName={section.name} polymorphic={polymorphic} />
	</>

	if(isEmpty(fields) && !section.hasChildren) //Jeśli sekcja nie ma pól i nie posiada innych sekcji to jej nie wyświetlamy (jest pusta)
		return null

	switch (section.displayType) {
		case 'label':
			return <SectionLabel section={section}>{childs}</SectionLabel>
		case 'main':
			return <SectionMain section={section}>{childs}</SectionMain>
		case 'accordion':
			return <SectionAccordion section={section}>{childs}</SectionAccordion>
		case 'tab':
			return <SectionTab section={section}>{childs}</SectionTab>
		//case 'accordionGroup':
		case 'default':
		default:
			return <SectionDefault section={section}>{childs}</SectionDefault>
	}
}