import React from 'react'
import { useForm } from 'components/form/Form'
import { RectsGanttRow } from './RectsGanttRow'
import { customElements } from 'cssStatic'

const RectsGanttStyled = customElements('o-rects-gantt', ['grid', 'grid-aix-start', 'grid-col-1'])

export function RectsGantt({ part }) {//Wchodzi part
	const form = useForm('data')
	const tree = form.treeManager.tree
	const tree2Rows = []

	tree.children.forEach(t1 => {
		t1.children.forEach(t2 => {
			tree2Rows.push(t2)
		})
	})

	return (
		<RectsGanttStyled>
			{
				tree2Rows.map(t => {
					return <RectsGanttRow tree={t}
						onClickTreeChild={({ rowIndex }) => {
							let row = form.rowManager.visibleRows[rowIndex]

							form.rowManager.selectedRowId = null
							form.rowManager.setRowDataAsCreate()

							const treeParentRef = form.data.meta.treeParentRef

							form.rowManager.rowData.data[0].attributes[treeParentRef] = { id: row.id }
							if (part)
								part.handleSelectChildByName('create', true)
						}}
					/>
				})
			}
		</RectsGanttStyled>
	)
}