/**
 * Klasa do przechowywania danych w storygach przeglądarki
 */
export default class Storage {
	/**
	 * Konstruktor
	 * @param {*} storage localStorage lub sessionStorage. 
	 * @param {*} crypto Jeśli ustawiony to umożliwia szyfrowanie przechowywanych danych
	 * @memberof Storage
	 */
	constructor(storage, crypto) {
		this._storage = storage
		this._crypto = crypto
	}

	/**
	 * Ilość pozycji w storygu
	 */
	get length() {
		return this._storage.length
	}

	/**
	 * Pobranie zawartości z pod pozycji index
	 *
	 * @param {integer} index Pozycja
	 * @returns {*} Dane
	 */
	key(index) {
		return this._storage.key(index)
	}

	/**
	 * Ustawia wartoś klucza
	 *
	 * @param {string} key Nazwa klucza
	 * @param {*} value Wartość
	 */
	set(key, value) {
		if (value === undefined) {
			this.remove(key)
			return
		}

		value = JSON.stringify(value)

		if (this._crypto)
			value = this._crypto.encrypt(value)

		this._storage.setItem(key, value)
	}

	/**
	 * Pobranie wartości pod kluczem
	 *
	 * @param {strig} key Nazwa klucza 
	 * @param {*} defaultValue Wartość zwracana jeśli brak klucza w storygu
	 * @returns {*} Wartość trzymana pod kluczem
	 */
	get(key, defaultValue) {
		let value = this._storage.getItem(key)

		if (value === null)
			return defaultValue

		if (this._crypto)
			value = this._crypto.decrypt(value)

		return JSON.parse(value)
	}

	/**
	 * Usuń klucz ze storygu
	 *
	 * @param {string} key Klucz do usunięcia
	 */
	remove(key) {
		this._storage.removeItem(key)
	}

	/**
	 * Usuwa wszystkie klucze
	 */
	clear() {
		this._storage.clear()
	}
}
