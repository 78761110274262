import React from 'react'
import DV from '../../../components/dataView/DataView'
import { ModulePartOuter } from './ModulePartOuter'
import { ModulePartInner } from './ModulePartInner'
import ErrorBoundary from 'components/errorBoundary/ErrorBoundary'

function ModuleDecoration({ child1, child2, part }) {
	return (
		<div className='layout-part'>
			<div></div>
			{child1}
			{child2}
		</div>
	)
}

/**
 * Part do wyswietlania wielu ListPart'ow ktorych linki są pobierane z modułów
 *
 * @param {*} { children, part, name, title }
 */
export function ModulePart({ children, part, name, title, autoOpenTab }) {
	return (
		<ErrorBoundary>
			<DV.Part
				part={part}
				name={name}
				dataType={name}
				title={title}
				outerContent={<ModulePartOuter autoOpenTab={autoOpenTab} />}
				innerContent={<ModulePartInner part={part} />}
				innerDecoration={<ModuleDecoration />}
			>
				{children}
			</DV.Part>
		</ErrorBoundary>
	)
}
