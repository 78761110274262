import useSWR from 'swr'

let errorObject = null;
const useGlobalError = () => {
    const {data, mutate} = useSWR("cat.state.globalError", () => errorObject);

    return {
        error: data,
        setError: (newErrorObject) => {
            if(newErrorObject)
                errorObject = {...newErrorObject};
            else
                errorObject = null;
                
            mutate();
        }
    };
};

export default useGlobalError;