import React, { useContext } from 'react';
import Button from '../Button'
import { GetAppRounded } from '@material-ui/icons'
import { StyledButtonTooltip } from '../Button.styled'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useTranslate } from 'locale/Locale'
import { ButtonDownloadFileContext } from './DownloadFileButtons'
import { saveAs } from 'file-saver'

/**
 * Komponent renderujący przycisk pozwalający na pobranie plików
 * Po kliknięciu przycisku w zależności od tego czy w wierszu jest jedno pole z plikiem czy jest ich więcej,
 * przycisk pobiera plik lub paczkę ZIP ze spakowanymi wszystkimi plikami
 * @returns {Node} 
 * @param {bool} Informacja czy dany guzik jest guzikiem do pobrania wszystkich plików
 */
export const ButtonDownloadFiles = ({allFilesButton}) => {
   const BUTTON_NAME = 'ButtonDownloadFiles';
   const { allFiles, allSelectedFiles, dataType, downloadFileBlobFromBE, handleGenerateZipFile, hardcodedDownloadButtonMeta} = useContext(ButtonDownloadFileContext)
   const { enqueueSnackbar } = useSnackbar();
   const translate = useTranslate('WebSpa/Snackbar');

   	let files; 
	let buttonLabel;
	
	if (allFilesButton) {
		files = allFiles;
		//Nazwa guzika do pobrania pliku dostarczona z BE
		if (hardcodedDownloadButtonMeta) {
			buttonLabel = hardcodedDownloadButtonMeta.label;
		}
		else {
			//Nazwa guzika do pobrania wszystkich plików
			buttonLabel = translate('ButtonDownloadMultipleFiles/tooltip');
		}
	}
	else {
		files = allSelectedFiles;
		buttonLabel = translate('ButtonDownloadSingleFile/tooltip');
	}

	const handleOnClick = async () => {
		
		if(files.length === 1){ //Sytuacja gdy w wierszu jest tylko jedno pole z plikiem do pobrania
			const singleFileObject = files[0]
			try
			{
				const fileBLOB = await downloadFileBlobFromBE(singleFileObject, allFilesButton, hardcodedDownloadButtonMeta);
		
				if(fileBLOB && singleFileObject.value){
					saveAs(fileBLOB, singleFileObject.value);
				}
			}
			catch(error)
			{
				enqueueSnackbar(error.message, { variant: 'error', preventDuplicate: true })
			}
			
		}
		else if(files.length > 1){//Sytuacja gdy w wierszu z którego pobieramy pliki jest więcej niż jedno pole z plikami
			handleGenerateZipFile(files, allFilesButton, hardcodedDownloadButtonMeta);
		}
	}

	if(isEmpty(files))
		return null;

	return (<div>
		{allFilesButton ? (<Button
			type='submit'
			id={`${dataType}-${BUTTON_NAME}`}
			onClick={() => handleOnClick(files, hardcodedDownloadButtonMeta)}
			content={buttonLabel}
		/>) : <Button
			type='icon'
			id={`${dataType}-${BUTTON_NAME}`}
			style={{ padding: '3px 10px', minWidth: 35 }}
			onClick={() => handleOnClick()}
			icon={
				<StyledButtonTooltip title={buttonLabel} arrow>
					<GetAppRounded style={{ fontSize: 28 }} />
				</StyledButtonTooltip>
			}
		/>
		}
	</div>);
}

