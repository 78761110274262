import React, { useEffect } from 'react'
import { FormMetaObject, Preloader, MessageListener } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import Fields from 'components/form/components/fields/Fields'
import { CardWrapper, StyledCardMedia, StyledCardActions } from './userProcessAction.styled'
import { Card, CardContent } from '@material-ui/core'
import { Buttons } from 'components/form/components/list/components'
import { useSnackbar } from 'notistack'
import { isEmpty } from 'lodash'
import { Close } from '@material-ui/icons'
import { useAppCtrl } from 'AppCtrl'

export default function UserProcessActions({ match, part }) {
	const { organizationId, microserviceId, token } = match.params
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	/// TODO Dodać poprawki związane z re-renderingiem
	/// UWAGA - Nie usuwać wywołania tego hooka, powoduje on re-rendering komponentu
	useAppCtrl();

	return (
		<FormMetaObject
			editMode='edit'
			onLoad={async form => {
				await getFormRestService(`/${organizationId}/userprocessactions/${microserviceId}/${token}`)
					.getOne(form)
			}}
			onSubmit={async (form, submitType, props) => {
				if (!form.isOk)
					return

				await form.onSubmitButton(submitType)

				if (!isEmpty(form?.errorFromService) && (form.errorFromService.detail || form.errorFromService.title)) { //Obsługa komunikatów z błędami za pomocą snnackbara
					enqueueSnackbar(form.errorFromService.detail || form.errorFromService.title,
						{
							variant: 'error',
							persist: true,
							action: key => <Close onClick={() => closeSnackbar(key)} style={{ cursor: 'pointer', marginRight: 5, fontSize: '1.3rem' }} />
						})
				}
			}}
		>
			<CardWrapper>
				<Card>
					<MessageListener />
					<Preloader>
						<StyledCardMedia children={<></>} />
						<CardContent>
							<Fields section='user' />
						</CardContent>
						<StyledCardActions>
							<Buttons part={part} />
						</StyledCardActions>
					</Preloader>
				</Card>
			</CardWrapper>
		</FormMetaObject>
	)
}