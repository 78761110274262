import React from 'react'
import { useForm } from 'components/form/Form'

/**
 * Rysowanie wierszy drzewa
 *
 * @param {*} { children }
 * @returns {Node}
 */
export function RowsTypeTree({ children }) {
	const form = useForm('data')
	const tree = form.treeManager.tree
	const child = React.Children.only(children)

	return React.cloneElement(child, { tree })
}