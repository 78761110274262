import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Section } from './Section'
import useForm from '../../hooks/useForm'
import { Tab, makeStyles, Card } from '@material-ui/core'
import { darken } from '@material-ui/core/styles'
import { TabContext, TabPanel, TabList } from '@material-ui/lab';
import { isEmpty, isArray } from 'lodash'
import { styleToColor } from 'Tools/styleToColor'
import { getSectionsDescriptions } from './getSectionsDescriptions.js'
import './Sections.scss'

//#region  Style
const useTabSectionStyles = makeStyles(({ palette }) => ({
	cardWrapperRoot: {
		overflow: 'hidden',
	},
	tabPanelRoot: {
		padding: 0,
	},
	wrapper: {
		fontSize: '14px'
	},
	tabListRoot: {
		backgroundColor: props => props.style === 'default' ? palette.secondary.white : styleToColor(props.style, palette),
		color: props => props.style === 'default' ? palette.primary.mainDarken800 : palette.primary.grey200,
	},
	tabListIndicator: {
		backgroundColor: props => darken(styleToColor(props.style, palette), 0.4),
		height: 3,
	}
}))
//#endregion

/**
 * Komponent wyświetlajacy zawartość Tab Panelu
 * 
 * @param {Array} sections - Tablica wszystkich sekcji (tabów)
 * @returns {Node}
 */
function TabPanelContent({ sections }) {
	const { tabPanelRoot } = useTabSectionStyles()

	return (
		sections.map((section, index) => {
			return (
				<TabPanel key={index} value={section.name} className={tabPanelRoot} >
					<Section section={section} />
				</TabPanel>
			)
		})
	)
}

/**
 * Komponent wyświetlajacy przewijalne menu dla Tab Panelu
 * 
 * @param {Array} sections - Tablica wszystkich sekcji (tabów)
 * @param {Object} rootSection - Obiekt sekcji nadrzędnej - rodzic grupujący inne sekcje z tabami
 * @param {Function} setCurrentSelectedSectionName - Funkcja zmieniająca obecną nazwę na nazwę nowo wybranej sekcji
 * @returns {Node}
 */
function TabListContent({ sections, setCurrentSelectedSectionName, rootSection }) {
	const { wrapper, tabListRoot, tabListIndicator } = useTabSectionStyles({ style: rootSection?.style ?? "default" })

	return (
		<TabList
			variant="scrollable"
			scrollButtons="auto"
			classes={{ root: tabListRoot, indicator: tabListIndicator }}
			onChange={(e, tabName) => setCurrentSelectedSectionName(tabName)}
		>
			{
				sections.map((section, index) => <Tab key={index} classes={{ wrapper }} label={section.label} value={section.name} />)
			}
		</TabList>
	)
}

/**
 * Komponent wyświetlający sekcję z tabami
 * 
 * @param {Object} section Obiekt sekcji nadrzędnej - rodzic grupujący inne sekcje z tabami
 * @returns {Node}
 */
export function SectionTab({ section, children }) {
	const form = useForm('data')
	const sections = getSectionsDescriptions(form, section.name)
	const [currentSelectedSectionName, setCurrentSelectedSectionName] = useState(sections?.[0]?.name)
	const { cardWrapperRoot } = useTabSectionStyles({ style: section?.style ?? "default" })

	if (isEmpty(sections) || !isArray(sections))
		return null

	return (
		<Card className={cardWrapperRoot}>
			<TabContext value={currentSelectedSectionName}>
				<TabListContent sections={sections} setCurrentSelectedSectionName={setCurrentSelectedSectionName} rootSection={section} />
				<TabPanelContent sections={sections} />
			</TabContext>
		</Card>
	)
}

//#region PropTypes & DefaultProps
SectionTab.propTypes = {
	section: PropTypes.object.isRequired,
	children: PropTypes.node
}

TabListContent.propTypes = {
	sections: PropTypes.array,
	setCurrentSelectedSectionName: PropTypes.func.isRequired,
	rootSection: PropTypes.object
}

TabPanelContent.propTypes = {
	sections: PropTypes.array,
}
//#endregion