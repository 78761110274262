import { useTrackedState, useDispatch } from 'contents/cat/store/global'
import ResourceDocument from 'contents/cat/model/ResourceDocument';

const useTmLookups = () => {
    const globalState = useTrackedState();

    let result = {
        resource: null,
        isLoading: globalState.components.tmLookups.request.isLoading
    };

    if (globalState.components.tmLookups.resource) {
        result.resource = new ResourceDocument(globalState.components.tmLookups.resource.payload);
    }
    else {
        result.resource = new ResourceDocument(null);
    }

    return result;
}

export default useTmLookups;