import React from 'react'
import { useForm } from 'components/form/Form'
import { StyledAlert } from './GlobalError.styled'

/**
 * Komponent wyswietlający Alert z błędem na podstawie obiektu błędu errorFromService
 * @returns {Node}
 */
export default function GlobalError() {
	const form = useForm('data')

	let errorMessage = form.errorFromService?.detail && form.errorFromService?.detail !== '' ? form.errorFromService.detail : form.errorFromService?.title
	if (!errorMessage)
		return null

	return (
		<StyledAlert variant="filled" severity="error">
			{errorMessage}
		</StyledAlert>
	)
}

