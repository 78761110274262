const stringResources = {
    finishJobButton : {
        active: {
            label: "Save & Export Translation",
            tooltip: "Mark the task as completed, generate a return file and export translation to LivoLink. Once completed, editing documents won't be possible.",
            confirmation: "Do you want to complete this LivoCAT task and generate the translation? The process might take up to a few minutes depending on the document’s size."
        },
        inactive: {
            label: "Translation Exported",
            tooltip: "The job has been finished and return file were exported to LivoLink. "
        },
        loading: {
            label: "Save & Export Translation",
            tooltip: "Operation is in progress. We are creating return file."
        }
    },
    filterDialogSegmentsGroup: {
        label: "Segments"
    },
    filterDialogSegmentsTerminologyCheckbox: {
        label: "With Terminology"
    },
    tryFixTagsInCurrentDocument: {
        tooltip: "Try fix tags in current document"
    }
};

export default stringResources;