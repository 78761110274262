import {ErrorModalEx} from "components/ErrorModalEx";
import useGlobalError from "./hooks/useGlobalError";
import Logger from "Tools/Logging/Logger";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "./store/global";

const ErrorModal = () => {
    const globalError = useGlobalError();
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const globalDispatch = useDispatch();
    const onCloseHandler = () => {
        setIsErrorModalOpen(false);
        // W ten sposób czyscimy błąd z globalnego storage'u. Tymczasowo póki się go nie pozbędziemy.
        globalDispatch({ type: 'REQUEST_SUCCESS'}); 
    }

    useEffect(() =>{
        setIsErrorModalOpen(true);
    }, [globalError.error])

    if(!globalError.error || isEmpty(globalError.error) || !globalError.error?.shouldErrorModalAppear)
        return null;

    return <ErrorModalEx 
        open={isErrorModalOpen} 
        errorObject={globalError.error} 
        logSerializer={Logger.memorySinkSerializer} 
        onClose={onCloseHandler}/>
}

export default ErrorModal;