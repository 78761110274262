import { FormBase } from './FormBase'
import FormMetaRootClass from '../../classes/FormMetaRoot'

/**
 * Komponent FormMetaRoot
 *
 * @param {function} createFormClass Funkcja zwracajaca klasę kontekstu
 * @param {*} data Dane początkowe formy
 * @param {Node} children Zawartość
 * @param {*} onLoadParam Parametry potrzebne do stwierdzenia czy trzeba redrawować komponent 
 * @returns {Node}
 */
export default function FormMetaRoot({ data, children, onChange, onLoadParam, redirectToLogin, errorModal, ...other }) {
	return FormBase({
		data, children, onChange, onLoadParam, redirectToLogin, errorModal,
		createFormClass: (params) => new FormMetaRootClass(other)
	})
}