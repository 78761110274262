/*
Dla atrybutów dodajemy parametry (w meta.attributes.[nazwaAtrybutu] lub w data[].meta.attributes.[nazwaAtrybutu])

	1. width
		Opis: Szerokość pola
		Typ: String
		Wymagalność: nie wymagany
		Default: '16u' - (w zaleznosci od typu)
		Format: numer+jednostka
			
		numer - liczby całkowite
		Dostępne jednostki to:
			a) 'u' - Nasz umowna jednostka szerokości która po stronie FE jest przeliczana na piksle
			b) '%' - Jeśli === 100% to pole na całą szerokość 
			c) 'px' - rozmiar w px
			
		np:
			1u - pole będzie miało szerokość 1*stala_po_stronie_FE
			6u - a to 6*stala_po_stronie_FE
			16u - domyślna szerokość i powinna odpowiadać mniej więcej szerokośći przed wprowadzaniam zmian
			
	2. height
		Opis: Wysokość pola. Ma znaczenie tylko dla pól których można zmieniać wysokość np: 'TextArea'. Dla pozostałych jest ignorowana.
		Typ: String
		Wymagalność: nie wymagany
		Default: W zależności od typy będzie różny default tak by zachować wsteczną kompatybilność
		Format: numer+jednostka
			
		numer - liczby całkowita (locale z '.')
		Dostępne jednostki to:
			a) 'u' - Nasz umowna jednostka szerokości która po stronie FE jest przeliczana na piksle
			
		Przykłady tak jak wyżej (dla width)

Dla sekcji dodajemy (w meta.sections.[sectionName]):
	
	1. sequenceIndex
		Opis: Liczba określajaca pozycje sekcji względem innych sekcji.
			Jeśli ustawiony parenSectionName to określa położenie wobec sekcji które mają tego samego parenta.
		Typ: Integer
		Wymagalność: nie wymagany
		Default: 0
		Uwaga: Jeśli sekcje mają ten sam sequenceIndex to wyświetlają się w kolejności w meta.sections		
		
	2. parentSectionName
		Opis: Nazwa sekcji wewnątrz której ma pojawiać się ta sekcja.
			Uwaga: Jeśli nie istnieje taka sekcja parentSectionName to sekcja nie zostanie wyświetlona. (Error in console?)
			Uwaga: Algorytm bedzie uwzgledniał możliwość niepoprawnego zapętlenia sekcji i nie będzie ich rysował (Error in console?)
		Typ: String
		Wymagalność: nie wymagany
		Default: undefined - czyli jest to sekcja bez parenta
	3. displayType
		Opis: Określa jakiego typu jest ta sekcja.
		Typ: String który może przyjmować wartości: 
			'default' - sekcja bez rzadnych obramowań i innych dodatkowych funkcjonalności
			'label' - sekcja z widoczną labelką mniejszym fontem i oznaczeniem obszaru który zajmuję. Mniej ważna niż 'main'
			'main' - sekcja z widoczną pogrubioną labelką i oznaczeniem obszaru który zajmuję
			'accordion' - sekcja która może składać się jak akordeon
			'tab' - ? sekcja której podsekcje są zakładkami tab'a
			'accordionGroup' - ?
			...
		Wymagalność: nie wymagany
		Default: 'default'
	4. width
		Opis: Szerokość sekcji
		Typ: String
		Wymagalność: nie wymagany
		Default: undefined
		Format: number+jednostka | 'auto' ?
	
		Dostępne jednostki to:
			a) 'u' - Nasz umowna jednostka szerokości która po stronie FE jest przeliczana na piksle
			b) '%' - procent zajmowania nadrzednego elementu w poziomie
					'100%' - powoduje że sekcja zajmuje cały wiersz
			c) 'px' - ilość pixli zajmowania ekranu w poziomie (nie zalecane)
			d) 'fr' - ilość frakcji zajmowania ekranu w poziomie
			e) 'vw' - Procent względem szerokości okna
			
		'auto': Sekcja zajmuje w poziomie resztę miejsca po pozostałych kolumnach
			
		Przykłady:
			//TODO
			10px 2f 40px 5fr auto
Uwaga: 
	- Sekcja o nazwie 'list' jest sekcją specjalną na potrzeby gridów i nigdy nie jest wyświetlana
*/

import React from 'react'
import useForm from '../../hooks/useForm'
import './Sections.scss'
import { Section } from './Section'
import { getSectionsDescriptions } from './getSectionsDescriptions'

/**
 * Renderuje sekcje z odpowiednim parenem
 * @param {String} parentSectionName Nazwy parenta sekcji
 * @returns {Node}
 */
export function Sections({ parentSectionName, polymorphic }) {
	const form = useForm('data')
	let sections = getSectionsDescriptions(form, parentSectionName, polymorphic)

	sections = sections.reduce((p, section) => {
		if (section.width === '100%') {
			p.push(section)
			return p
		}

		let last

		if (!p.length)
			p.push(last = [])
		else
			if (Array.isArray(p[p.length - 1]))
				last = p[p.length - 1]
			else
				p.push(last = [])

		last.push(section)
		return p
	}, [])

	if (!sections.length)
		return null

	return (
		<div className='Sections'>
			{
				sections.map((section, index) => {
					if (!Array.isArray(section))
						return <Section key={index} section={section} polymorphic={polymorphic} />

					return (
						<div key={index} className='Sections' style={{ gridTemplateColumns: section.map(section => section.width).join(' ') }}>
							{
								section.map((section, index) => {
									return <Section key={index }section={section} polymorphic={polymorphic} />
								})
							}
						</div>
					)
				})
			}
		</div>
	)
}