import React from 'react'
import RefreshableContext from '../refreshableContext/RefreshableContext'
import DataViewClass from './DataViewClass'

const context = RefreshableContext.create()

function useDataView() {
	return context.useContext()
}

function Provider({ children }) {
	return (
		<context.Provider fixedData createData={() => new DataViewClass()}>
			{children}
		</context.Provider>
	)
}

export { useDataView, Provider }