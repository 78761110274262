import FormMetaArrayObjects from './FormMetaArrayObjects';
import Field from './Field';
import Validate from '../components/Validate';
import config from 'config';
import getFormRestService from '../../../services/getFormRestService';
import { isEmpty, isObject, isUndefined, toString, } from 'lodash';
import { mergeLinks } from "./mergeData";
import { GlobalStrings } from 'GlobalStrings';
import { isFileFieldUploadFile } from '../components/file/File';

/*
	Format this.data

	{
		pages:1,
		currentPage:1,
		meta:{
			dataType:"Nazwa typu",
			treeParentRef:"Nazwa kolumny tworząca drzewo z listy",
			attributes:{
				nazwaAtrybutu:{
					name:"Name",
					label:"Nazwa",
					type:"String",
					uiInputType:null,
					placeholder:null,
					editableOnUpdate: true,
					editableOnCreate: true,
					visibleOnCreate: true,
					multiEdition:true,
					sequenceIndex:10,
					shouldBreakRow:false,
					sections:["user","list"],
					values:null,
					validations:[
						{type:"Length",min:0,max:100},
						{"type":"Required"},
						{type:"Range",min:0,max:100},
						{type:"RegEx",value:""}
					]
				},...
			},
			buttons:{
				NazwaButtonu:{
					name:"Nazwa",
					label:"Wyślij",
					uiInputType:"Button",
					sequenceIndex:10,
					sections:null,
					// czy button puszcza update
					updateAllowed:true|false 
					uiAction: "InvokeRequest" | "DisplayModalUpa" | "Link" | "ClientAction" | "ZipList",
					//Dla uiAction=== "ClientAction" atrybuty do wypełniania formy
					uiButtonActionArguments, 
					//Czy pokazywać popup
					showPopupWindow
				},...
			},
			{
				sections: {
					list: {
						name: "list",
						label: "list"
					},
					user: {
						name: "user",
						label: "user"
					}
				}
			}
		},
		data:[
			{
				type:"PriceList",
				id:"09bcff12-80e9-4799-a157-9cb261e38e4c",
				meta:{
					//Dodatkowe atrybuty dla polimorficznych obiektów
					attributes:{},
					buttons:{},
					dataType:"PriceList"
				},
				attributes:{
					"Nazwa atrybutu":"wartość",
					"Nazwa atrybutu Ref":{
						id:"e8c9a200-158c-cc4a-9f85-af6d1c53cf00",
						value:"PLN"
					}
				},
				links:{
					read[podrzednyDataType]:{
						"href":"/api/v1/...",
						"rel":"read...",
						"method":GET,
						"params":{}
					},
					create[podrzednyDataType]:{
						"href":"/api/v1/...",
						"rel":"create...",
						"method":POST,
						"params":{},
						"createChildByDefault":false //Jeśli true to w drzewkiu pojawi się przycisk dodawania dziecka
					},
					update:{
						"href":"/api/v1/offeritems/eadb06f6-f072-45d0-904e-603eedf30094",
						"rel":"update",
						"method":PATCH,
						"params":{}
					},
					delete:{
						"href":"/api/v1/offeritems/eadb06f6-f072-45d0-904e-603eedf30094",
						"rel":"delete",
						"method":DELETE,
						"params":{}
					},
					//Link potrzebny do pokazywania buttonow patrz: meta->buttons
					ButtonInquiryCancel:{
						href:"/api/v1/inquiries/448085af-b979-4b77-8004-eac1397588e1/state/inquirycancel",
						rel:"ButtonInquiryCancel",
						method:"PATCH"
						params:{}
					},...
				}
			}
		]
		messages:[
			{
				"subResourceDataTypeName":"ProjectItemDelivery",
				"resourceDataTypeName":"ProjectItem",
				"resourceId":"b933b953-6a83-460c-9802-abcbed8c31d2",
				"correlationId":"a315848e-8c71-4161-9dea-00e83f6f4a40",
				"type":"ReloadSubResourceMessage"
			},
			{
				"resourceDataTypeName":"ProjectItem",
				"resourceId":"b933b953-6a83-460c-9802-abcbed8c31d2",
				"correlationId":"a315848e-8c71-4161-9dea-00e83f6f4a40",
				"type":"ReloadResourceMessage"
			}
			{
				"resourceDataTypeName": "Inquiry",
				"resourceId": "afb0e98a-5299-4192-bd90-6aa1a0b3660e",
				"infoMessage": "Wiadomość z backendu",
				"correlationId": "00000000-0000-0000-0000-000000000000",
				"type": "UserInfoMessage"
			},
			{
				"operationId": "00000000-0000-0000-0000-000000000000",
				"operationType": 110,
				//Dostępne states
				//	Start		= 10,
				//	Processing	= 30,
				//	Failure		= 100,
				//	Finished	= 110,
				"operationState": 10,	
				"operationDepiction": "Start TradosStatRequest",
				"resourceDataTypeName": "Inquiry",
				"resourceId": "aff94403-fe1d-4d66-8bf6-91daf0468256",
				"correlationId": "63284547-d142-4f1e-9475-e3742072b642",
				"type": "ObjectInfoMessage"
			}...
		]
		links:{
			create[this]:{
				"href":"/api/v1/offeritems",
				"rel":"createPriceList",
				"method":POST,
				"params":{}
			},
			read[this]:{
				"href":"/api/v1/offeritems",
				"rel":"readPriceList",
				"method":POST,
				"params":{}
			},
			getCurrencyRef:{
				"href":"/api/v1/currencies?includeData=True&includeMeta=True&limit=100&attributes=Symbol",
				"rel":"getCurrencyRef",
				"method":"GET",
				"params":{}
			}
		}	
	}

*/

export const FORM_EDIT_MODES = Object.freeze({
	CREATE: 'create',
	EDIT: 'edit',
	MODAL_UPA: 'modalUpa'
})

export const EDIT_MODE_MODAL_UPA = 'modalUpa';
export const UPA_UPLOAD_LINK_NAME = 'UploadFileUPA';
/**
 * Klasa formy z polami
 */
export default class FormMetaObject extends FormMetaArrayObjects {
	constructor(params) {
		super(params)
		this._changes = {}
		this._errors = {}
		this._internalError = {}
		this._values = {}
		this._defaultValues = {}

		/**
		 * Objekt zawierający starą metawiedzę przed wykonania requestu reloadOnChange
		 * @property { globalMeta: object, objectMeta: object } 
		 */
		this._metaBeforeReloadOnChange = {}

		this._editMode = params.editMode
		this._dataType = params.dataType
		this._useLinkName = params.useLinkName

		this._onRowUpdate = params.onRowUpdate
		this._onRowCreate = params.onRowCreate
		this._onRowDelete = params.onRowDelete
		this._onCanRowUpdate = params.onCanRowUpdate
		this._onCanRowCreate = params.onCanRowCreate
		this._onCanRowDelete = params.onCanRowDelete
		this._isFormUsingServices = params.isFormUsingServices //Parametr mówiący o tym że dana forma korzysta z linków przekazywanych przez BE
	}

	initData(v) {
		this.data = v
		//this._metaBeforeReloadOnChange = {};
		// KCH: Cierpi na tym performance kiedy odświeżamy listę
		this.trigger('data')
	}

	clearChanges() {
		this._changes = {}
		this._errors = {}
		this._defaultValues = {}
		this._restoreMetaDataBeforeReloadOnChange();
	}

	/**
	 * Zapisz metadane przed wykonaniem pierwszego requesta o typu ReloadOnChanage  
	 * @param {object} globalMeta Globalne metadane 
	 * @param {object} objectMeta Metadane konkretnego obiektu
	 */
	setMetaDataBeforeReloadOnChange(globalMeta, objectMeta){
		if (Object.keys(this._metaBeforeReloadOnChange).length === 0){
			this._metaBeforeReloadOnChange.globalMeta = { ...globalMeta };
			this._metaBeforeReloadOnChange.objectMeta = { ...objectMeta };
		}
	}

	/**
	 * Przywróć poprzednią metawiedzę, która była używana przed wykonaniem requesta reloadOnChange
	 *  - wykorzystywane w celu przywrócenia poprzednich metadanych, jeśli anulujemy edycje obiektu
	 */
	_restoreMetaDataBeforeReloadOnChange() {
		if (Object.keys(this._metaBeforeReloadOnChange).length !== 0){
			this.data.meta = { ...this._metaBeforeReloadOnChange?.globalMeta };
			this.data.data[0].meta = { ...this._metaBeforeReloadOnChange?.objectMeta };
		}
		this._metaBeforeReloadOnChange = {};
	}

	/**
	 * Obsłuż wydarzenie: @see {GlobalStrings.reloadOnChangeSubmitType}
	 * @param {*} props Argumenty przekazane podczas wysłania, powinny zawierać nazwę pola którego wartość została zmieniona
	 */
	async handleReloadOnChangeSubmitType(props) {
		const { triggeredFieldName } = props;

		if(!triggeredFieldName){
			console.error(`CreatePartOuter.js - submitType: ${GlobalStrings.reloadOnChangeSubmitType} - Nie podano nazwy pola dla którego wywołana została akcja.`);
			return;
		}

		if (!this.serviceReadHRef)
			return;

		await getFormRestService(this.serviceReadHRef)
			.getReloadOnChangeMeta(this, triggeredFieldName);

		return;
	}


	/**
	 * Ustawienie danych dla this
	 * @type {object}
	 */
	get data() {
		return this._data
	}
	set data(v) {
		//Przeniesienie zawartości nowych danych do tthis._data
		//{...v} jest konieczne żeby v i this._data nie były tymi samymi objektami..
		//Przyszłe płytkie zmiany w this._data nie mogą przenosić się do v
		this._data = { ...v }

		//Czyszczenie this._data jeśli nowe dane są puste
		if (!v) {
			this.setEmptyData()
			return
		}

		//jeśli brak links to dodajemy puste
		if (!v.links)
			this._data.links = {}


		super.copyUIAttribiuteInfoToUIMetaAttribiute()


		if (!v.data.length) {
			this._values = {}
			this.validate()
			return
		}

		//skróty
		const vData0 = v.data[0], dataData0 = this._data.data[0]

		//Jeśli brak jakichś sekcji to tworzymy puste

		if (!vData0.links)
			dataData0.links = {}
		if (!vData0.meta)
			dataData0.meta = { attributes: {} }
		if (!vData0.meta.attributes)
			dataData0.meta.attributes = {}
		if (!vData0.attributes)
			dataData0.attributes = {}

		//Przeniesienie ui attributes do data dla sekcji data
		if (dataData0?.ui?.attributes) {
			for (let key in dataData0.ui.attributes){
				dataData0.meta.attributes[key] = { ...dataData0.meta.attributes[key], ...dataData0.ui.attributes[key] };
			}
		}

		this._values = vData0.attributes

		//Przenosimy do this tylko wiadomości dotyczące this
		if (v.messages) {
			this._data.messages =
				v.messages.filter(message => {
					const row = this._data.data[0]
					return message.resourceId === row.id
				})
		}

		//ustawienie domyślnych wartości
		this.defaultValuesToObject(v.meta?.attributes);
		//Walidacja pól po ustawieniu nowych  danych
		this.validate();
	}

	get _metas() {
		return this._data?.meta?.attributes ?? {}
	}



	setEmptyData() {
		this.data = {
			meta: { attributes: {}, sections: {} },
			data: [
				{
					attributes: {},
					links: {},
					meta: { attributes: {} }
				}
			],
			links: {},
			messages: []
		}
	}

	_mergeDataDatas(vDatas) {
		const map = new Map()
		const thisDataData = this._data.data[0]
		let isParentFormMerged = false

		vDatas.forEach(data => map.set(data.id, data))
		this._mergeDataData(map.get(thisDataData.id))

		this.parentForm.childForms.forEach(form => {
			const vData = map.get(form.id)
			if (vData)
				isParentFormMerged = true
			form._mergeDataData(vData)
		})

		if (isParentFormMerged)
			this.parentForm.trigger('data')
	}

	_mergeDataData(vData) {
		if (!vData)
			return;
		const thisDataData = this._data.data[0];

		if (vData.id)
			thisDataData.id = vData.id;
		if (vData.attributes) {
			const newValues = vData.attributes;

			for (const i in newValues) {
				if (newValues[i] === null)
					delete this._values[i];
				else
					this._values[i] = newValues[i];
			}
		}

		thisDataData.links = mergeLinks(thisDataData.links,vData.links);

		if (vData.meta && vData.meta.attributes) {
			thisDataData.meta.dataType = vData.meta.dataType;

			if (vData?.ui?.attributes)
				for (let key in vData.ui.attributes)
					thisDataData.meta.attributes[key] = { ...thisDataData.meta.attributes[key], ...vData.ui.attributes }
		}
	}

	/**
	 * Mergowanie zmian przychodzących w odpowiedzi na patch do BE
	 * @param {object} zmiany które maja być wprowadzone do this.data
	 */
	mergeData(v) {
		if (!v)
			return

		//merge meta.attributes
		if (v.meta?.dataType) {
			this._data.meta.dataType = v.meta.dataType
			if (v.meta.attributes)
				this._data.meta.attributes = { ...this._data.meta.attributes, ...v.meta.attributes, ...v?.ui?.attributes }
		}

		//merge danych
		if (v.data) {
			switch (v.data.length) {
				//Dla braku danych
				case 0:
					break
				//Mamy tylko jeden rekord
				case 1:
					this._mergeDataData(v.data[0])
					break
				//Dla wielu rekordów
				default:
					this._mergeDataDatas(v.data)
			}
		}

		//merge linków

		let newLinks = {}

		if (Object.keys(this._data.links).length) {
			newLinks = Object.values(this._data.links)
				.filter(l => l.rel.startsWith('get'))
				.reduce((p, c) => {
					p[c.rel] = c
					return p
				}, {})
		}

		if (v.links)
			this._data.links = { ...v.links, ...newLinks }

		if (this.parentForm?.isMetaArray) {
			const messages = v.messages?.filter(message => message.resourceId === this.id)

			if (!this.parentForm._data.messages)
				this.parentForm._data.messages = []

			const parentMessages = this.parentForm._data.messages

			if (messages) {
				messages.forEach(message => {

					//Na ten moment wiadomosci karteczkowe działają na nowym systemie - przechowujemy je w AppMessages
					if (message.type !== GlobalStrings.NotifySourceMessageDataType) {
						const m = parentMessages.find(parentMessage => parentMessage.type === message.type && parentMessage.resourceId === message.resourceId)

						if (!m)
							parentMessages.push(message)
						else
							Object.keys(message).forEach(key => { m[key] = message[key] })
					}
				})
			}
		}

		if (v.messages) {
			this._data.messages =
				v.messages.filter(message => {
					const row = this._data.data[0]
					return message.resourceId === row.id
				})
		}
		//Odświeżamy walidację	
		this.validate()
	}

	//Getter dla parametru '_isFormUsingServices' mówiącego o tym że dana forma korzysta z linków przekazywanych przez BE
	get isFormUsingServices() { return this._isFormUsingServices }

	get links() { return this._data?.links }

	/**
	 * Funkcja ma za zadanie zwracać jeden konkretny link  zawierający podany jako parametr ciąg znaków
	 * @param {String} serchingText  - Wzorzec według którego poszukujemy linku
	 * @param {String} fieldName  - Nazwa pola z BE
	 */
	getSpecifiedLink(linkPrefix, fieldName) {
		const pattern = linkPrefix.concat(fieldName).toLocaleLowerCase()
		let findedLink = undefined

		if (!isEmpty(this._data.links)) {
			const keys = Object.keys(this._data.links)
			for (let key of keys) {
				if (key.toLocaleLowerCase() === pattern) {
					findedLink = this._data.links[key]
				}
			}
		}

		return findedLink
	}

	serviceUploadFile(field) {
		const LINK_PREFIX = "upload"
		const name = field ? field.name : ""
		const link = this.getSpecifiedLink(LINK_PREFIX, name)

		if (link) {
			return link.href.replace("api/v1/", "/")
		}
		else {
			const error = "Link dla tego pola nie istnieje"
			this.setInternalError(name, error)
			this.printErrorsToConsole("Błąd wewnętrzny", this._internalError)
		}
	}

	get serviceRead() {
		if (this._parentForm && this._parentForm.getServiceRelativeRead)
			return this._parentForm.getServiceRelativeRead(this._useLinkName ? this._useLinkName : this._dataType)

		return this._parentForm.serviceRead
	}



	get serviceReadOne() {
		if (!isEmpty(this._data?.links)) {
			const link = this._data.links[`readOne${this._useLinkName ? this._useLinkName : this._dataType}`]
			if (link)
				return link
		}

		if (!this._parentForm)
			return undefined

		if (this._parentForm.getServiceRelativeReadOne)
			return this._parentForm.getServiceRelativeReadOne(this._useLinkName ? this._useLinkName : this._dataType)

		return this._parentForm.serviceReadOne
	}

	get serviceUploadUPAFileHRef() {
		if (this.dataType && this._data.links) {
			const link = this.data.links[UPA_UPLOAD_LINK_NAME];
			if(link) {
				return link.href;
			}
		}
		return undefined;
	}


	get serviceCreate() {
		if (this.dataType && this._data.links) {
			const link = this._data.links[`create${this._useLinkName ? this._useLinkName : this._dataType}`];
			if (link) {
				return link;
			}	
		}

		if (this._parentForm && this._parentForm.getServiceRelativeCreate) {
			const parentRelativeCreate = this._parentForm.getServiceRelativeCreate(this._useLinkName ? this._useLinkName : this._dataType);
			return parentRelativeCreate;
		}

		const parentCreate = this._parentForm?.serviceCreate;
		return parentCreate;
	}
	
	get serviceCreateHRef() {
		let s = this.serviceCreate
		if (s)
			return s.href
		return undefined
	}

	get serviceUpdate() {

		if (this._data && this._data.data[0] && this._data.data[0].links) {
			return this._data.data[0].links.update
		}

		return undefined;
	}
	get serviceUpdateHRef() { return this.serviceUpdate ? this.serviceUpdate.href : undefined }
	get serviceDelete() { return this._data.data[0].links['delete'] }
	get serviceDeleteHRef() { return this.serviceDelete ? this.serviceDelete.href : undefined }

	hasUpdateLink() { return !isEmpty(this.serviceUpdate) }

	getServiceRelativeRead(relativeDataType, useLinkName) { return this._data.data[0].links[`read${useLinkName ? useLinkName : relativeDataType}`] }
	getServiceRelativeReadHRef(relativeDataType) {
		let s = this.getServiceRelativeRead(relativeDataType)
		if (s) return s.href
	}

	getServiceRelativeReadOne(relativeDataType) { return this._data.data[0].links[`readOne${this._useLinkName ? this._useLinkName : relativeDataType}`] }
	getServiceRelativeReadOneHRef(relativeDataType) {
		let s = this.getServiceRelativeReadOne(relativeDataType)
		if (s) return s.href
	}

	get serviceCreateTreeChild() {
		const link = Object.values(this._data.data[0].links).find(l => l.createChildByDefault)
		return link
	}

	get serviceCreateTreeChildHRef() {
		const s = this.serviceCreateTreeChild
		if (s)
			return s.href
		return undefined
	}

	setReloadOnChangeMeta(res) {

		const objectResourceObject = this._data.data[0]
		this._setMetaAndUI(objectResourceObject,res.data[0])

		const globalResourceObject = this._data;
		this._setMetaAndUI(globalResourceObject, res)

		if (this._data.meta.sections = res.meta.sections)
			this._data.meta.sections = res.meta.sections

		this.defaultValuesToObject(objectResourceObject.meta.attributes);
		this.addPolymorphicLinks(res)
		//this.addPolymorphicSection(this._data)
		this.validate() //Odświeżamy walidację
	}

	_setObjectMetaAndUI(res) {
		const objectResourceObject = this._data.data[0]
		this.setMetaAndUI(objectResourceObject,res.data[0])
	}

	_setGlobalMetaAndUI(res) {
		const globalResourceObject = this._data;
		this.setMetaAndUI(globalResourceObject, res)
	}

	_setMetaAndUI(resourceToChange, newResource) {

		if (!newResource)
			return;

		if (!!Object.keys(newResource?.meta?.attributes).length)
			resourceToChange.meta.attributes = newResource?.meta?.attributes;

		if (!!Object.keys(newResource?.meta?.sections).length)
			resourceToChange.meta.sections = newResource?.meta?.sections;

		if (!!Object.keys(newResource?.ui?.attributes).length)
			resourceToChange.ui = newResource?.ui;

		//przeniesienie attrybutow z ui do meta
		if (resourceToChange.ui?.attributes && resourceToChange.meta?.attributes)
			for (let key in resourceToChange?.ui.attributes)
				resourceToChange.meta.attributes[key] = { ...resourceToChange.meta.attributes[key], ...resourceToChange.ui.attributes[key] }
	}



	/**
	 * Funkcja która z obiektu atrybutów odczytuje informację o polu 'defaultValue' i przypisuje ją do obiektu według wzoru:
	 * defaultValues = {
	 * 	nazwaPola: domyślnaWartośćDlaPola
	 * }
	 * @param {Object} attributes 
	 */
	defaultValuesToObject(attributes) {
		if(!this._defaultValues) // Nie czyścimy wartości domyślnych jeżeli jakieś są - inaczej reload on change sprawia, że poprzednie wartości domyślne nie są wysyłane do backendu
			this._defaultValues = {}

		if (isEmpty(attributes) || !isObject(attributes))
			return

		for (const attribute of Object.values(attributes)) {
			// Warunek przepuszcza takie wartości domyślne jak: null, 0, false, pusty obiekt, pusty string, pusta tablica - w razie potrzeby dopisać kolejne if'y
			if (isUndefined(attribute?.defaultValue))
				continue
			else {
				this._defaultValues[attribute.name] = attribute?.defaultValue
			}
		}
	}

	addPolymorphicLinks(res) {
		let newLinks = {}

		if (res.links && Object.keys(res.links).length) {
			newLinks = Object.values(res.links)
				.filter(link => link.rel.startsWith('get'))
				.reduce((prev, current) => {
					prev[current.rel] = current
					return prev
				}, {})
		}

		if (res.links)
			this._data.links = { ...this._data.links, ...newLinks }
	}

	addPolymorphicSection(res) {
		if (!res.data)
			return

		for (var i in res.data) {
			var d = res.data[i]

			if (!d.meta || !d.meta.attributes)
				continue

			var a = d.meta.attributes

			for (var j in a) {
				var m = a[j]

				if(m === null) 
					continue

				if (!m.sections)
					m.sections = []

				m.sections.push('polymorphic')
			}
		}
	}

	get dataType() {
		return this._data?.meta?.dataType ?? ''
	}

	get id() {
		if (this._data?.data?.length > 0)
			return this._data.data[0].id
		return undefined
	}

	get editMode() { return this._editMode }
	set editMode(v) { this._editMode = v }

	get errorFromService() { return this._errorFromService }
	set errorFromService(v) {
		this._errorFromService = v
		if (!v)
			return

		if (v.responseError) {
			this.globalError = v.responseError.detail
			if (v.responseError.errors)
				this._errors = { ...this._errors, ...v.responseError.errors }
			return
		}
		if (v instanceof Error) {
			this.globalError = v.message
			return
		}
		this.globalError = v.statusText
	}

	get errorFromBackEnd() { return this._errorFromBackEnd }
	set errorFromBackEnd(v) { this._errorFromBackEnd = v }

	get forceAllAttributesUpdate() {
		if (
			this._data &&
			this._data.data &&
			this._data.data.length > 0 &&
			this._data.data[0].meta
		) {
			let m = this._data.data[0].meta

			if ('forceAllAttributesUpdate' in m)
				return m.forceAllAttributesUpdate
		}

		return this._data.meta.forceAllAttributesUpdate
	}

	/**
	 * Pomocnicza prywatna właściwośc do pobierania zmian w attrybutach
	 * Zmiany bedą teraz trzymana w this._data.data[0]._changes
	 */
	get _changes() {
		if (!this._data)
			return {}

		const dd0 = this._data.data[0]

		if (!dd0)
			return {}

		if (!dd0._changes)
			dd0._changes = {}

		return dd0._changes
	}

	set _changes(v) {
		if (!this._data)
			return

		const dd0 = this._data.data[0]

		if (!dd0)
			return

		dd0._changes = v
	}

	get isChanged() { return Object.values(this._changes).length > 0 }
	set isChanged(v) { }

	get changes() {
		return this._changes
	}

	changesToValues() {
		for (var i in this._changes) {
			if (this._changes[i] === null)
				delete this._values[i]
			else
				this._values[i] = this._changes[i]
		}
		this._changes = {}
	}

	get values() {
		return this._values
	}
	get currentValues() {
		let values = { ...this._values }

		for (var i in this._changes) {
			if (this._changes[i] === null)
				delete values[i]
			else
				values[i] = this._changes[i]
		}
		return values
	}

	get currentValuesToSend() {
		return { ...this._defaultValues, ...this._values, ...this._changes }
	}

	getField(name) {
		return new Field(name, this)
	}

	resourceHasRequiredField() {
		if (this.data && this.data.meta) {
			const polymorphicFields = this.getMetasSection('polymorphic')
			const keys = Object.keys({ ...this.data.meta.attributes, ...polymorphicFields })

			const results = keys.map(key => { //Tworzenie tablicy rezultatów wg wzoru: [false, false, false, true....]
				const field = this.getField(key)
				const currentValue = typeof field.value === 'number' ? toString(field.value) : field.value

				if (field.required && !field.disabled)
					if (isEmpty(currentValue))//Sprawdzenie czy dane pole jes puste. Jeśli tak, jest ono wymagane do uzupełnienia 
						return true

					else return false
			})

			if (results.some(result => result))//Sprawdzanie czy jakikolwiek rezultat jest pozytywny (true)
				return true

			else return false
		}

		return false
	}

	/**
	 * 
	 */
	get allFileFields() {
		const filesMataObjectArray = Object.values(this.metas).filter(meta => meta?.type === 'File');
		const fileFieldsObjectArray = []

		if (!isEmpty(filesMataObjectArray)) {
			for (const fileMeta of filesMataObjectArray) {
				const field = this.getField(fileMeta?.name)

				if (!isEmpty(field?.value) && isFileFieldUploadFile(field)) {
					fileFieldsObjectArray.push(field)
				}
			}
		}

		return fileFieldsObjectArray
	}

	getRequired(name) {
		const meta = this.getMeta(name)

		if ( meta === null )
			return false;

		if (this.editMode === 'create' && !meta?.visibleOnCreate)
			return false

		if (!meta?.validations)
			return false

		return meta.validations.some(v => v.type === 'Required')
	}

	getDisabled(name) {
		if (!this.editMode)
			return false

		//Gdy forma jest w trybie edycji wyglądu wszystkie pola są aktywne	
		if (this._isUiEditionActive)
			return false

		// Sytuacja gdy Forma korzysta z linków oraz nie ma linku 'update'
		// i próbujemy zaktualizować dane
		if (this._isFormUsingServices && !this.serviceUpdate && this.editMode === 'edit')
			return true

		const meta = this.getMeta(name)

		if ((this.editMode === 'create' || this.editMode === 'modalUpa') && meta?.editableOnCreate)
			return false
		else if ((this.editMode === 'edit' || this.editMode === 'modalUpa') && meta?.editableOnUpdate)
			return false
		else
			return true
	}

	setError(name, error) { this._errors[name] = error }
	setInternalError(name, error) { this._internalError[name] = error }
	getError(name) { return this._errors[name] }
	getInternalError(name) { return this._internalError[name] }
	get errors() { return this._errors }
	printErrorsToConsole(type, error) { console.log(type, ": ", error) }

	get globalError() { return this._errors['__globalError'] }
	set globalError(error) { this._errors['__globalError'] = error }
	get isOk() { return Object.values(this._errors).length === 0 }

	getPlaceholder(name) { return this.getMeta(name)?.placeholder }

	getHint(name) { return this.getMeta(name)?.hint }

	getLabel(name) { return this.getMeta(name)?.label }

	getType(name) { return this.getMeta(name)?.type }


	/**
	 * Pobierz wartość domyślną pola z metadanych.
	 * Przeszukujemy zarówno dane obiektu (FormMetaObject), jeśli nie znaleziemy to sprawdzamy globalne dane w FormMetaArray
	 * @param {} fieldName Nazwa pola dla którego szukamy wartości
	 * @returns Wartość domyślną pola.
	 */
	getFieldDefaultValue(fieldName) {
		let fieldDefaultValue = this._defaultValues[fieldName];

		if (fieldDefaultValue) {
			return fieldDefaultValue;
		}

		const parentForm = this.parentForm;

		if (!parentForm)
			return fieldDefaultValue;

		const fieldMetaFromParent = parentForm?.data?.meta?.attributes[fieldName];
		
		if (!fieldMetaFromParent) {
			return fieldDefaultValue;
		}

		return fieldMetaFromParent.defaultValue;
	}
	

	getValue(name) {
		if (name in this._changes) { // Sytuacja gdy znajdujemy wartość w obiekcie ze zmianami 
			let v = this._changes[name]
			return v === null ? undefined : v
		}

		if (!(name in this._values) && !isUndefined(this.getFieldDefaultValue(name))  && this._editMode === 'create')
			return this.getFieldDefaultValue(name)

		if (name in this._values)
			return this._values[name]
	}


	getValueInput(name) {
		let value = this.getValue(name)

		if (!value && typeof value !== 'number')
			return ''

		if (typeof value === 'object')
			return value.value

		return value
	}

	handleChange(name, newValue, bFromParent) {
		console.log(name, newValue, bFromParent);
		let oldValue = this._values[name]

		this._changes[name] = newValue
		if (newValue === oldValue)
			delete this._changes[name]
		else
			if (newValue === undefined && oldValue !== undefined)
				this._changes[name] = null

		this.validate()

		if (!bFromParent && this.parentForm && this.parentForm.isMetaArray) {
			const meta = this.getMeta(name)

			if (meta.multiEdition)
				this.parentForm.handleMultiEditionFieldValueChange(this, name, newValue);

			this.parentForm.onFieldValueChange(this, name, newValue);
		}

		this.trigger('data')
	}

	handleChanges(newValues) {
		for (let name in newValues) {
			let newValue = newValues[name]
			let oldValue = this._values[name]

			this._changes[name] = newValue
			if (newValue === oldValue)
				delete this._changes[name]
			else
				if (newValue === undefined && oldValue !== undefined)
					this._changes[name] = null
		}

		this.validate()
		this.trigger('data')
	}

	validate() {
		this._errors = {}
		Validate(this, this._errors)
		if (!this.isOk && this.isChanged)
			this.printErrorsToConsole("Błąd walidacji", this._errors)
	}

	/**
	 * Sprawdź czy dany atrybut ma metawiedzę
	 * @param {string} attribiuteName Nazwa atrybutu
	 * @returns 
	 */
	hasMeta(attribiuteName) {

		if(attribiuteName in this._metas )
		{
			return this._metaHasNameAttribiute(this.metas[attribiuteName]);
		}

		if (attribiuteName in this._data.data[0].meta.attributes)
		{
			return this._metaHasNameAttribiute(this.metas[attribiuteName]);
		}

		return false;
	}

	_metaHasNameAttribiute(meta){
		return meta?.name !== undefined;
	}

	getMeta(name) {
		if (name in this.metas)
			return this.metas[name]

		if (!isEmpty(this._data.data[0].meta?.attributes) && name in this._data.data[0].meta.attributes)
			return this._data.data[0].meta.attributes[name]

		return {
			name,
			label: name
		}
	}

	get metas() {
		return { ...this._metas, ...this._data.data[0].meta.attributes }
	}

	getMetasSection(section) {
		let metas = this.metas

		let f = (section) => {
			const findedSectionsArray = {}

			const metaObjectsArray = Object.values(metas).filter(e => {
				return e?.sections && section.some(s => e.sections.some(ee => ee === s))
			})

			if (!isEmpty(metaObjectsArray)) {
				for (const field of metaObjectsArray) {
					findedSectionsArray[field.name] = field
				}
			}

			return findedSectionsArray
		}

		if (typeof section === 'string')
			return f([section])

		if (Array.isArray(section))
			return f(section)

		return undefined
	}

	getButton(buttonName) {
		var data = this._data.data
		if (data.length === 0 || !this._data.meta.buttons)
			return

		var links = data[0].links

		if (!links)
			return

		return Object.values(this._data.meta.buttons)
			.filter(button => (button.name in links))
			.find(button => button.name === buttonName)
	}

	async onSubmitButton(submitType) {
		let button = this.getButton(submitType)

		if (!button || button.uiAction !== 'InvokeRequest')
			return

		let link = this.getDataLink(submitType).href
		link = link.slice(config.serviceApiVer.length)

		return await getFormRestService(link).patch(this, '', submitType === 'ButtonPayByP24' || submitType === 'ButtonPayByP24UPA')
	}

	onDisplayModalUpa(submitType, open) {
		let button = this.getButton(submitType)

		if (!button || button.uiAction !== GlobalStrings.displayModalUpaButtonuiActionType )
			return;

		let link = this.getDataLink(submitType).href
		link = link.slice(config.serviceApiVer.length)
		button.href = link

		open(button)
		return true
	}

	/**
	 * Metoda sprawdza czy button jest linkiem i jeśli nim jest wywołuje akcję.
	 * Akcja wywoływana kiedy mamy do czynienia z linkiem w czystej postaci: 
	 * Ten link należy wywołać użyć bez edycji (link zawiera pełną ścieżkę) w nowym oknie.
	 * @param {string} buttonName Nazwa guzika
	 * @returns {Boolean} Zwraca {true} jeśli znaleziono link wpp zwraca {false}
	 */
	onLinkButton(buttonName) {
		let button = this.getButton(buttonName)

		if (!button || button.uiAction !== 'Link')
			return false

		let link = this.getDataLink(buttonName).href

		window.open(link, button.label, '')

		return true
	}

	async onDeleteButton(submitType) {
		if (submitType !== 'delete')
			return false

		let link = this.serviceDeleteHRef
		link = link.slice(config.serviceApiVer.length)
		return getFormRestService(link).deleteOne(this)
	}

	getDataLink(name) {
		var data = this._data.data
		if (data.length === 0 || !this._data.meta.buttons)
			return

		var links = data[0].links

		if (!links)
			return

		return links[name]
	}

	async handleRowUpdate() {
		const f = this._onRowUpdate || (this.parentForm && this.parentForm._onRowUpdate)

		if (!f)
			return false
		return await f(this)
	}

	async handleRowCreate() {
		const f = this._onRowCreate || (this.parentForm && this.parentForm._onRowCreate)

		if (!f)
			return false
		return await f(this)
	} 

	async handleRowDelete() {
		const f = this._onRowDelete || (this.parentForm && this.parentForm._onRowDelete)

		if (!f)
			return false
		return await f(this)
	}

	handleCanRowUpdate() {
		const f = this._onCanRowUpdate || (this.parentForm && this.parentForm._onCanRowUpdate)

		if (!f)
			return false
		return f(this)
	}

	handleCanRowCreate() {
		const f = this._onCanRowCreate || (this.parentForm && this.parentForm._onCanRowCreate)

		if (!f)
			return false
		return f(this)
	}

	handleCanRowDelete() {
		const f = this._onCanRowDelete || (this.parentForm && this.parentForm._onCanRowDelete)

		if (!f)
			return false
		return f(this)
	}
}

