import React from 'react'
import { GridResizable } from 'components/topology/GridResizable'
import Icon from 'components/icon/Icon'

function GridStretchChildren({ children, style, ...other }) {
	return <div
		style={{
			display: 'grid',
			justifyItems: 'stretch',
			alignItems: 'stretch',
			overflow: 'hidden',
			...style
		}} {...other}>
		{children}
	</div>
}

export function GridScrollBar({ children, style, ...other }) {
	return <div
		style={{
			display: 'grid',
			overflow: 'auto',
			...style
		}} {...other}>
		{children}
	</div>
}

function GridRelative({ children, style, ...other }) {
	return <div
		style={{
			display: 'grid',
			overflow: 'hidden',
			position: 'relative',
			...style
		}} {...other}>
		{children}
	</div>
}

function GridAbsoluteLeft({ children, style, ...other }) {
	return <div
		style={{
			display: 'grid',
			position: 'absolute',
			zIndex: 1,
			bottom: '50%',
			transform: 'translate(-50%,50%)',
			left: '0%',
			overflow: 'hidden',
			...style
		}} {...other}>
		{children}
	</div>
}

function GridAbsoluteRight({ children, style, ...other }) {
	return <div
		style={{
			display: 'grid',
			position: 'absolute',
			zIndex: 1,
			bottom: '50%',
			transform: 'translate(50%,50%)',
			right: '0%',
			overflow: 'hidden',
			...style
		}} {...other}>
		{children}
	</div>
}

function GridAbsoluteBottom({ children, style, ...other }) {
	return <div
		style={{
			display: 'grid',
			position: 'absolute',
			zIndex: 1,
			right: '50%',
			bottom: '0%',
			transform: 'translate(50%, 50%)',
			overflow: 'hidden',
			...style
		}} {...other}>
		{children}
	</div>
}

function GridAbsoluteTop({ children, style, ...other }) {
	return <div
		style={{
			display: 'grid',
			position: 'absolute',
			zIndex: 1,
			right: '50%',
			top: '0%',
			transform: 'translate(50%, -50%)',
			overflow: 'hidden',
			...style
		}} {...other}>
		{children}
	</div>
}

function GridAbsolute({ children, isFirstChild, vertical, ...other }) {
	if (isFirstChild) {
		if (vertical)
			return <GridAbsoluteRight {...other}>{children}</GridAbsoluteRight>
		return <GridAbsoluteBottom {...other}>{children}</GridAbsoluteBottom>
	} else {
		if (vertical)
			return <GridAbsoluteLeft {...other}>{children}</GridAbsoluteLeft>
		return <GridAbsoluteTop {...other}>{children}</GridAbsoluteTop>
	}
}

function SplitterButtons({ isFirstChild }) {

}

export function Splitter({ dataType, child1, child2, vertical, sizeHorizontal, sizeVertical, onChangeSize, onClose, isMaximize }) {
	if (!sizeHorizontal)
		sizeHorizontal = 100
	if (!sizeVertical)
		sizeVertical = 100

	const [state, setState] = React.useState({ vertical, sizeVertical, sizeHorizontal, isMaximize })
	const _onChangeSize = v => {
		if (!onChangeSize)
			return
		onChangeSize(v)
	}

	const styleBorder = state.vertical ?
		{ borderRightWidth: '1px', borderRightStyle: 'solid', borderRightColor: '#316197' }
		:
		{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: '#316197' }

	return (
		<GridResizable
			templateX={state.vertical ? [state.isMaximize ? 10 : state.sizeVertical, 'auto'] : []}
			templateY={!state.vertical ? [state.isMaximize ? 10 : state.sizeHorizontal, 'auto'] : []}
			splitterModeX
			splitterModeY
			style={{
				justifyItems: 'stretch',
				alignItems: 'stretch',
				overflow: 'hidden'
			}}
			onResized={({ templateX, templateY }) => {
				const newState = { ...state }

				newState.isMaximize = false
				if (state.vertical)
					newState.sizeVertical = templateX[0]
				else
					newState.sizeHorizontal = templateY[0]

				setState(newState)
				_onChangeSize(newState)
			}}
		>
			<GridRelative>
				<GridAbsolute isFirstChild vertical={state.vertical} style={{ gridAutoFlow: state.vertical ? 'row' : 'column' }}>
					<div
						id={`${dataType}-splitter-button-rotate`}
						onPointerDown={event => {
							event.stopPropagation()
						}}
						onPointerMove={event => {
							event.stopPropagation()
						}}
						onPointerUp={event => {
							event.stopPropagation()
						}}
						style={{ backgroundColor: 'white', display: 'grid', borderRadius: '10px', userSelect: 'none' }}
						onClick={event => {
							const newState = { ...state }
							newState.vertical = !state.vertical
							setState(newState)
							_onChangeSize(newState)
							event.stopPropagation()
						}}
					>
						<Icon name='loop' />
					</div>
					<div
						id={`${dataType}-splitter-button-arrow-${!state.isMaximize ? (state.vertical ? 'left' : 'up') : (state.vertical ? 'right' : 'down')}`}
						onPointerDown={event => {
							event.stopPropagation()
						}}
						onPointerMove={event => {
							event.stopPropagation()
						}}
						onPointerUp={event => {
							event.stopPropagation()
						}}
						style={{ backgroundColor: 'white', display: 'grid', borderRadius: '10px', userSelect: 'none' }}
						onClick={event => {
							const newState = { ...state }
							newState.isMaximize = !state.isMaximize
							setState(newState)
							event.stopPropagation()
						}}
					>
						<Icon name={!state.isMaximize ? (state.vertical ? 'arrow_back' : 'arrow_upward') : (state.vertical ? 'arrow_forward' : 'arrow_downward')} />
					</div>
					<div
						id={`${dataType}-splitter-button-close`}
						onPointerDown={event => {
							event.stopPropagation()
						}}
						onPointerMove={event => {
							event.stopPropagation()
						}}
						onPointerUp={event => {
							event.stopPropagation()
						}}
						style={{ backgroundColor: 'white', display: 'grid', borderRadius: '10px', userSelect: 'none' }}
						onClick={event => {
							if (onClose)
								onClose()
							event.stopPropagation()
						}}
					>
						<Icon name='close' />
					</div>
				</GridAbsolute>
				<GridStretchChildren style={{ ...styleBorder }}>
					{child1}
				</GridStretchChildren>
			</GridRelative>

			<GridRelative>
				<GridAbsolute vertical={state.vertical} style={{ gridAutoFlow: state.vertical ? 'row' : 'column' }}>
					<div
						id={`${dataType}-splitter-button-rotate`}
						onPointerDown={event => {
							event.stopPropagation()
						}}
						onPointerMove={event => {
							event.stopPropagation()
						}}
						onPointerUp={event => {
							event.stopPropagation()
						}}
						style={{ backgroundColor: 'white', display: 'grid', borderRadius: '10px', userSelect: 'none' }}
						onClick={event => {
							const newState = { ...state }
							newState.vertical = !state.vertical
							setState(newState)
							_onChangeSize(newState)
							event.stopPropagation()
						}}
					>
						<Icon name='loop' />
					</div>
					<div
						id={`${dataType}-splitter-button-arrow-${!state.isMaximize ? (state.vertical ? 'left' : 'up') : (state.vertical ? 'right' : 'down')}`}
						onPointerDown={event => {
							event.stopPropagation()
						}}
						onPointerMove={event => {
							event.stopPropagation()
						}}
						onPointerUp={event => {
							event.stopPropagation()
						}}
						style={{ backgroundColor: 'white', display: 'grid', borderRadius: '10px', userSelect: 'none' }}
						onClick={event => {
							const newState = { ...state }
							newState.isMaximize = !state.isMaximize
							setState(newState)
							event.stopPropagation()
						}}
					>
						<Icon name={!state.isMaximize ? (state.vertical ? 'arrow_back' : 'arrow_upward') : (state.vertical ? 'arrow_forward' : 'arrow_downward')} />
					</div>
					<div
						id={`${dataType}-splitter-button-close`}
						onPointerDown={event => {
							event.stopPropagation()
						}}
						onPointerMove={event => {
							event.stopPropagation()
						}}
						onPointerUp={event => {
							event.stopPropagation()
						}}
						style={{ backgroundColor: 'white', display: 'grid', borderRadius: '10px', userSelect: 'none' }}
						onClick={event => {
							if (onClose)
								onClose()
							event.stopPropagation()
						}}
					>
						<Icon name='close' />
					</div>
				</GridAbsolute>
				<GridStretchChildren>
					{child2}
				</GridStretchChildren>
			</GridRelative>

		</GridResizable>
	)
}

// export function Splitter({ child1, child2, vertical, sizeHorizontal, sizeVertical, onChangeSize }) {
// 	if (!sizeHorizontal)
// 		sizeHorizontal = 100
// 	if (!sizeVertical)
// 		sizeVertical = 100

// 	const [state, setState] = React.useState({ vertical, sizeVertical, sizeHorizontal })
// 	const _onChangeSize = v => {
// 		if (!onChangeSize)
// 			return
// 		onChangeSize(v)
// 	}

// 	const styleBorder = state.vertical ?
// 		{ borderRightWidth: '1px', borderRightStyle: 'solid', borderRightColor: '#316197' }
// 		:
// 		{ borderBottomWidth: '1px', borderBottomStyle: 'solid', borderBottomColor: '#316197' }

// 	return (
// 		<GridResizable
// 			templateX={state.vertical ? [state.sizeVertical, 'auto'] : []}
// 			templateY={!state.vertical ? [state.sizeHorizontal, 'auto'] : []}
// 			splitterModeX
// 			splitterModeY
// 			style={{
// 				justifyItems: 'stretch',
// 				alignItems: 'stretch',
// 				overflow: 'hidden'
// 			}}
// 			onResized={({ templateX, templateY }) => {
// 				const newState = { ...state }

// 				if (state.vertical)
// 					newState.sizeVertical = templateX[0]
// 				else
// 					newState.sizeHorizontal = templateY[0]

// 				setState(newState)
// 				_onChangeSize(newState)
// 			}}
// 		>
// 			<GridRelative
// 				style={{ ...styleBorder }}>
// 				<GridAbsolute vertical={state.vertical}>
// 					<div
// 						onPointerDown={event => {
// 							event.stopPropagation()
// 						}}
// 						onPointerMove={event => {
// 							event.stopPropagation()
// 						}}
// 						onPointerUp={event => {
// 							event.stopPropagation()
// 						}}
// 						style={{ backgroundColor: 'white', display: 'grid', borderRadius: '10px' }}
// 						onClick={event => {
// 							const newState = { ...state }
// 							newState.vertical = !state.vertical
// 							setState(newState)
// 							_onChangeSize(newState)
// 							event.stopPropagation()
// 						}}
// 					>
// 						<Icon name='loop' />
// 					</div>
// 				</GridAbsolute>
// 				<GridStretchChildren>
// 					{child1}
// 				</GridStretchChildren>
// 			</GridRelative>

// 			<GridStretchChildren>
// 				{child2}
// 			</GridStretchChildren>
// 		</GridResizable>
// 	)
// }