import React from 'react'
import { Grid, DivResizable } from 'components/topology/Topology'
import { useForm } from 'components/form/Form'
import { CellBorderStyled } from '../cell'
import { GanttRectangle } from './GanttRectangle'
import getFormRestService from 'services/getFormRestService'

function RelativeGantt({ form, rowIndex, range, row }) {
	const ref = React.useRef(null)
	//x1 - relatywna pozycja w x paska
	//cx - dlugość poaska w x
	const [state, setState] = React.useState({ x1: 0, cx: 0, scale: 0 })

	React.useEffect(() => {
		if (!ref.current)
			return

		let [begin, end] = range
		let scale = ref.current.offsetWidth
		let x1 = begin, cx = end - begin

		x1 *= scale
		cx *= scale
		setState({ x1, cx, scale })
	}, [ref.current])

	return (
		<div ref={ref} className='grid relative height-16'>
			{
				state.scale &&
				<DivResizable
					onCheckBefore={ev => ev.shiftKey && form.serviceUpdateHRef}
					onResized={async ({ x1, cx }) => {
						const positionBegin = x1 / state.scale
						const positionEnd = (x1 + cx) / state.scale
						form.parentForm.ganttManager.positionRangeToForm(positionBegin, positionEnd, form)
						await getFormRestService(form.serviceUpdateHRef)
							.patch(form, '')
						//form.parentForm.ganttManager.recalculateTimeRangeFromRows(true)
						form.parentForm.trigger('data')
					}}
					x={state.x1}
					y={0}
					width={state.cx}
					height={20}
					moveableY={false}
					resizableY={false}
				//boundary={false}
				>
					<GanttRectangle form={form} rowIndex={rowIndex} />
				</DivResizable>
			}
		</div>
	)
}

export function CellGantt({ rowIndex, row }) {
	const form = useForm('data')
	const isInlineEdition = Boolean(form.parentForm?.rowManager?.inlineEdition)

	if (!form.parentForm.ganttManager.isGantt)
		return null

	const range = form.parentForm.ganttManager.positionRangeFromRow(row)

	if (!range)
		return <CellBorderStyled backgroundColor='white' isInlineEdition={isInlineEdition} />

	return (
		<CellBorderStyled backgroundColor='white' tx='auto' aiy='center' isInlineEdition={isInlineEdition}>
			<RelativeGantt form={form} rowIndex={rowIndex} range={range} row={row} />
		</CellBorderStyled>
	)
}