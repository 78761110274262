import React from 'react'
import {  usePositionElements } from 'components/positionObserver/PositionObserver'
import { Brace } from './Brace'

export function LeftObserved({ id0, id1, id2 }) {
	const [r0, r1, r2] = usePositionElements([id0, id1, id2], true)

	if (!r0 || !r1 || !r2)
		return null

	const p0 = r0.rgCn
	const p1 = r1.lfCn

	if (id1 === id2)
		return <Brace xb={p0.x} xe={p1.x} yb1={p0.y} yb2={p0.y} ye1={p1.y} ye2={p1.y} />

	return <Brace xb={p0.x} xe={p1.x} yb1={p0.y} yb2={p0.y} ye1={r1.y1} ye2={r2.y2} />
}

export function RightObserved({ id0, id1, id2 }) {
	const [r0, r1, r2] = usePositionElements([id0, id1, id2], true)

	if (!r0 || !r1 || !r2)
		return null

	const p0 = r0.lfCn
	const p1 = r1.rgCn

	if (id1 === id2)
		return <Brace xe={p1.x} xb={p0.x} ye1={p1.y} ye2={p1.y} yb1={p0.y} yb2={p0.y} />

	return <Brace xe={p1.x} xb={p0.x} ye1={r1.y1} ye2={r2.y2} yb1={p0.y} yb2={p0.y} />
}