import styled, { css } from 'styled-components'
import { GetFromTheme } from './GetFromTheme'

/** Mixin padding'u. Działa dokladnie tak jak css: 'padding x x x x;' 
 * z taką różnicą że x może być pobrane z theme.
 * 
 * Przypomnienie parametrów:
 * padding top right bottom left;
 * 
 * Przypomnienie skrótów:
 * padding x; -> paddnig x x x x;
 * padding topBottom leftRight; -> padding topBottom leftRight topBottom leftRight;
 * padding top leftRight bottom; -> padding top leftRight bottom leftRight;
 * 
 * @param {Array} paddings - Tablica padding'ów
 * @returns {Array}
 */
export function MixinPadding(paddings) {
	if (!Array.isArray(paddings) || paddings.length === 0)
		return ''

	let f = padding => props => GetFromTheme(props, 'padding', padding, '0px')

	switch (paddings.length) {
		case 1:
			return css`padding: ${f(paddings[0])};`
		case 2:
			return css`padding: ${f(paddings[0])} ${f(paddings[1])};`
		case 3:
			return css`padding: ${f(paddings[0])} ${f(paddings[1])} ${f(paddings[2])};`
		case 4:
			return css`padding: ${f(paddings[0])} ${f(paddings[1])} ${f(paddings[2])} ${f(paddings[3])};`
		default:
			return ''
	}
}

///TODO style
export const Padding = styled.div`
	${props => MixinPadding(props.paddings)}
`