import {Editor,EditorState} from 'draft-js';
import React, {useEffect, useRef, useState} from "react";

import {InputWithSuggestion} from '../decorators/decorators'
import SegmentEditorController from './SegmentEditorController';

// This object provides the styling information for our custom color
      // styles.
      const colorStyleMap = {
        red: {
          backgroundColor: 'rgba(255, 0, 0, 1.0)',
        },
        orange: {
          backgroundColor: 'rgba(255, 127, 0, 1.0)',
        },
        yellow: {
          backgroundColor: 'rgba(180, 180, 0, 1.0)',
        },
        green: {
          backgroundColor: 'rgba(0, 180, 0, 0.34)',
        },
        darkGreen: {
          backgroundColor: 'rgba(0, 120, 0, 0.5)',
        },
        blue: {
          backgroundColor: 'rgba(0, 0, 255, 1.0)',
        },
        indigo: {
          backgroundColor: 'rgba(75, 0, 130, 1.0)',
        },
        violet: {
            backgroundColor: 'rgba(127, 0, 255, 1.0)',
        },
        olive: {
            backgroundColor: 'rgba(134, 120, 54, 0.3)',
        },
        oliveDark: {
            backgroundColor: 'rgba(103, 84, 0, 0.5)',
        },
      };

const EditorWithTrackingMode = props => {
    const [editorStateHook, setEditorStateHook] = useState(() => EditorState.createEmpty());
    const [isTagging, setIstagging] = useState(false)
    const [coordinatesHook, setCoordinatesHook] = useState(null)
    const [allowedTags, setAllowedTags] = useState({allowedTags: []})
    const [isFocused, setIsFocused] = useState(false);
    const editorRef = useRef(null);
    const editorController = useRef(new SegmentEditorController());

    editorController.current.IsChangeTrackingEnabled = props.trackingMode;
    editorController.current.SegmentAllowedTags = props.allowedTags;
    editorController.current.SourceSegmentObject = props.sourceSegmentObject;
    editorController.current.editorStateHook = editorStateHook;
    editorController.current.setEditorStateHook = setEditorStateHook;
    editorController.current.isTaggingStateHook = isTagging;
    editorController.current.setIsTagingStateHook = setIstagging;
    editorController.current.setAllowedTagsStateHook = setAllowedTags;
    editorController.current.setCoordinatesStateHook = setCoordinatesHook;
    editorController.current.editorRef = editorRef;

    useEffect(() => {
        if(props.editorEventSubscription)
        {
            props.editorEventSubscription.subscribeChangeTracking(editorController.current);
        }
        

        return () => {
            if(props.editorEventSubscription)
            {
                props.editorEventSubscription.unsubscribeChangeTracking(editorController.current);
            }
        };
    })

    //console.log("Render DraftJS Control - Segment Object", props.segmentObject);
    useEffect(() => {
        // if(!props.readOnly)
        // {
        //     console.log("On Segment Object Change DraftJS Control - Init segment content", props.segmentObject)
        // }
        
        editorController.current.initContent(props.segmentObject, props.allowedTags);
    }, [props.segmentObject]);

    const onChange = editorState => {
        const res = editorController.current.createSegmentObjectFromEditorStateEx(editorState);
        console.log("onChange", res);
        setEditorStateHook(editorState)
    }

    const handleKeyCommand = (command, editorState) => {
        const res = editorController.current.createSegmentObjectFromEditorStateEx(editorState);
        console.log("handleKeyCommand", res);
        return editorController.current.DjsHandleKeyCommand(command, editorState, 0);
    }


    const keyBindingFn = (e, editorState, onKeyDown) => {
        const res = editorController.current.createSegmentObjectFromEditorStateEx(editorState);
        console.log("keyBindingFn", res);
        return editorController.current.DjsKeyBindingFn(e, onKeyDown);
    }

    const closeAutoSuggest = (e)  => {
        if (e === 'lostFocus'){
            setIstagging(false);
        } else {
            editorController.current.insertChars("@");
            setIstagging(false);
        }
    }

    const submitTag = (e, tagObject) => {
        editorController.current.insertTag(e, tagObject);
    }

    const onSegmentChange = (isTagging, editorState) => {
        const res = editorController.current.createSegmentObjectFromEditorStateEx(editorState);
        console.log("onSegmentChange", res);
        const newSegment = editorController.current.createSegmentObjectFromEditorState();
        if(newSegment === false)
            return false;

        if (props && props.onSegmentChange){
            props.onSegmentChange(newSegment)
        }

        return newSegment;
    }

    const handlePastedText = (text, html, editorState) => {
        return editorController.current.DjsHandlePastedText(text, html, editorState);
    }
    const handleBeforeInput = (chars, editorState, eventTimeStamp) => {
        
        return editorController.current.DjsHandleBeforeInput(chars, editorState, eventTimeStamp);
    }

    return (
        <>
            <Editor
                editorState={editorStateHook}
                handleKeyCommand={handleKeyCommand}
                onChange={onChange}
                keyBindingFn={(e) =>
                    keyBindingFn(e, editorStateHook, props.onKeydown)
                }
                onBlur={() => {
                    setIsFocused(false);
                    onSegmentChange(isTagging, editorStateHook);
                    if(props.onBlur)
                        props.onBlur();
                }}
                onFocus={() => {
                    setIsFocused(true);
                    if(props.onSelect)
                        props.onSelect();
                }}
                ref={editorRef}
                hasFocus={isFocused}
                handlePastedText={handlePastedText}
                handleBeforeInput={handleBeforeInput}
                stripPastedStyles={true}
                readOnly={props.readOnly}
                customStyleMap={colorStyleMap}
            />
            {isTagging &&
            <InputWithSuggestion
                onSubmit={submitTag}
                allowedTags={allowedTags.allowedTags}
                onClose={closeAutoSuggest}
                coordinates={coordinatesHook}
            />
            }
        </>
    );
}
export default EditorWithTrackingMode
