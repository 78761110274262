import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'locale/Locale'
import { useForm } from 'components/form/Form'
import { FormMetaObject, InputText, InputPassword } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import Button from 'components/form/components/list/components/buttons/Button'
import { ButtonSubmit } from '../list/components'
import { useSnackbar } from 'notistack'
import { isEmpty, trim } from 'lodash'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

/**
 * Widok okienka modalnego z tworzeniem tożsamości
 * @param {Boolean} isModalOpen - Informacja czy modal jest otwarty czy nie
 * @param {Function} setIsModalOpen - Funkcja służąca do zamykania okienka modalnego
 * @param {Object} FIELDS_NAMES - Obiekt z nazwami wykorzystywanych w kontrolce pól
 * @returns 
 */
const CreateIdentityModal = ({ isModalOpen, setIsModalOpen, FIELDS_NAMES }) => {
	const translate = useTranslate('WebSpa')
	const form = useForm()

	const handleSaveOnEnter = e => {
		if (e.key === 'Enter') {
			if (!isEmpty(form.changes) && isEmpty(form.errors)) {
				form.asyncSubmit()
			}
		}

		return
	}

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			maxWidth='sm'
			open={isModalOpen}
			onClose={e => setIsModalOpen(false)}
			onPointerDown={e => 	e.stopPropagation() }
		>
			<DialogTitle>
				{translate('CreateIdentityView/title')}
			</DialogTitle>
			<DialogContent dividers onKeyDown={e => handleSaveOnEnter(e)}>
				<form onSubmit={e => e.preventDefault()}>
					<InputText
						autoFocus
						icon='user_dark'
						name={FIELDS_NAMES.Login}
						inputProps={{
							autocomplete: 'new-password',
							form: {
								autocomplete: 'off',
							},
						}}
					/>
					<InputPassword
						name={FIELDS_NAMES.Password}
						style={{ marginTop: 25, paddingBottom: 12 }}
						inputProps={{
							autocomplete: 'new-password',
							form: {
								autocomplete: 'off',
							},
						}}
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<ButtonSubmit content={translate('Buttons/createButton')} />
				<Button type='cancel' onClick={() => setIsModalOpen(false)} content={translate('Buttons/cancelButton')} />
			</DialogActions>
		</Dialog>
	)
}

/**
 * Wrapper dla okienka modalnego pozwalającego na tworzenie tożsamości. Oplata okienko modalne w dane z Formy
 * @param {Boolean} isModalOpen - Informacja czy modal jest otwarty czy nie
 * @param {Function} setIsModalOpen - Funkcja służąca do zamykania okienka modalnego
 * @param {String} personId - ID osoby dla której tworzymy tożsamość
 * @returns {Node}
 */
const CreateIdentityMetaDriven = ({ isModalOpen, setIsModalOpen, createIdentityLink, personId }) => {
	const translate = useTranslate('WebSpa')
	const { enqueueSnackbar } = useSnackbar()
	const PASSWORD_PATTERN = new RegExp('[a-zA-Z0-9_].{3,}', 'g')
	const FIELDS_NAMES = Object.freeze({
		Password: "Password",
		Login: 'Login',
	})

	const DATA = {
		data: [
			{	
				meta: {
					forceAllAttributesUpdate: true, // Potrzebne aby wysłać atrybut "PersonId" do BE
				},
				attributes: {
					PersonId: personId
				}
			}
		],
		meta: {
			dataType: 'Identity',
			attributes: {
				Login: {
					name: FIELDS_NAMES.Login,
					label: translate('MainMenu/Company/Person/CreateIdentity/Login/label'),
					type: 'String',
					validations: [{ type: 'Required' }, { type: 'RegEx', value: PASSWORD_PATTERN, errors: translate('HelperText/login') }]
				},
				Password: {
					name: FIELDS_NAMES.Password,
					label: translate('MainMenu/Company/Person/CreateIdentity/Password/label'),
					type: 'String',
					validations: [{ type: 'Required' }, { type: 'PasswordValidate' }]
				}
			}
		}
	}

	if (!isModalOpen) {
		return null
	}

	return (
		<FormMetaObject
			data={DATA}
			errorModal={() => null}
			onSubmit={async form => {
				const response = await getFormRestService(createIdentityLink).patch(form, '')
				
				if (response) {
					enqueueSnackbar(translate('Snackbar/CreateIdentity/succes'), { variant: 'success', preventDuplicate: true })
				}
				else {
					form.globalError = form?.errorFromService?.detail ?? null
					form.trigger('data')
					const snackbarErrorText = form.errorFromService?.detail || form.errorFromService?.title || translate('Snackbar/CreateIdentity/error/badServerResponse')
					enqueueSnackbar(snackbarErrorText, { variant: 'error', preventDuplicate: true })
				}

				setIsModalOpen(false)
			}}
		>
			<CreateIdentityModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} FIELDS_NAMES={FIELDS_NAMES} />
		</FormMetaObject>
	)
}

/**
 * Przycisk wraz z okienkiem modalnym do tworzenia tożsamości
 * @param {String} personId - ID osoby dla której tworzymy tożsamość
 * @returns 
 */
const CreateIdentity = ({ createIdentityLink, personId }) => {
	const translate = useTranslate('WebSpa/Buttons')
	const [isModalOpen, setIsModalOpen] = useState(false)

	if (isEmpty(trim(createIdentityLink))){
		console.error(`Brak linku do utworzenia tożsamości lub id osoby. Link: ${createIdentityLink}, id osoby: ${personId}`)
		return null
	}

	return (
		<>
			<CreateIdentityMetaDriven
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				createIdentityLink={createIdentityLink}
				personId={personId}
			/>
			<Button
				type='submit'
				onClick={() => setIsModalOpen(true)}
				content={translate('createIdentit')}
			/>
		</>
	)
}

//#region Export Komponentu
export default CreateIdentity
//#endregion


//#region PropTypes & DefaultProps
CreateIdentity.propTypes = {
	personId: PropTypes.string.isRequired,
}

CreateIdentityMetaDriven.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
	personId: PropTypes.string,
}

CreateIdentityModal.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
	FIELDS_NAMES: PropTypes.shape({
		Password: PropTypes.string,
		Login: PropTypes.string,
	})
}

CreateIdentity.defaultProps = {
	personId: '',
}

//#endregion 