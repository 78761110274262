import React from 'react'
import PropTypes from 'prop-types'
import { useAppCtrl } from 'AppCtrl'
import { StyledInfoWrapper, StyledInfo } from './Info.styled'

//#region Global variables
	//Tablica wartości atrybutu severity dla konttolki alert z biblioteki material-ui.
	const EXTERNAL_CONTROL_SEVERITY_VALUES = Object.freeze({
		success: 'success',
		info: 'info',
		error: 'error',
		warning: 'warning',
		default: 'default',
})
//#endregion

/**
 * Funkcja mapująca wartości atrybutu "severity" na wartości akceptowane przez kontrolkę Alert
 * z biblioteki material-ui. Link do dokumentacji: https://mui.com/api/alert/
 * @param {String} severity - Typ komunikatu otrzymywany z BE 
 * @returns 
 */
const mapSeverity = ( severity ) => {

	if (!severity){
		console.error('Brak atrybutu "severity" w funkcji "mapSeverity" z pliku Info.js')
		return EXTERNAL_CONTROL_SEVERITY_VALUES.default
	}

	switch (severity){
		case 'Error':
			return EXTERNAL_CONTROL_SEVERITY_VALUES.error
		case 'Warning': 
			return EXTERNAL_CONTROL_SEVERITY_VALUES.warning
		case 'Info': 
			return EXTERNAL_CONTROL_SEVERITY_VALUES.info
		case 'Confirmation': 
			return EXTERNAL_CONTROL_SEVERITY_VALUES.success
		default:
			console.warn(`Nieobsługiwana wartość atrybutu "severity" w obiekcie wiadomości z BE: ${severity}.`)
			return EXTERNAL_CONTROL_SEVERITY_VALUES.default
	}
}

/**
* Komponent Alert wyświetlający wiadomości z BE w zależności od parametru severit
* @param {Object} message - obiekt wiadomości z BE
* @param {Object} appCtrl - obiekt appCtrl
* @returns {Node}
*/
const InfoWrapper = ({ message, appCtrl }) => {
	const severity = mapSeverity(message.severity)
	
	return (		
		<StyledInfo variant="filled" severity={severity} onClose={() => appCtrl.removeUserInfoMessage(message.infoMessage)}>
			{message.infoMessage}
		</StyledInfo>
	)
}
/**
 * Wyświetla UserInfoMessage z BE.
 * @param {String} section - określa sekcję w której ma znajdować się wiadomość user - w formularzu, list - przy liście
 * @returns {Node}
 */
export default function Info({ section }) {
	const appCtrl = useAppCtrl()

	return (
		<StyledInfoWrapper>
			{
				appCtrl.userInfoMessages.map((message, index) => {
					if(!appCtrl.canMessageAppear(section, message))
						return null
					
					return <InfoWrapper  message={message} appCtrl={appCtrl} key={index} />	
				})
			}
		</StyledInfoWrapper>
	)
}

mapSeverity.propTypes = {
	severity: PropTypes.shape(
		Object.keys(EXTERNAL_CONTROL_SEVERITY_VALUES).map(key => {
			return {[key]: PropTypes.string}
		})
	)
}

Info.propTypes = {
   section: PropTypes.string.isRequired,
}

InfoWrapper.propTypes = {
   message: PropTypes.object,
   appCtrl: PropTypes.object,
}

Info.defaultProps = {
   section: "user",
}	

InfoWrapper.defaultProps = { 
	message: {},
	appCtrl: {}
}