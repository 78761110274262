import React from 'react'
import useForm from '../../hooks/useForm'
import useDebounce from 'components/useDebounce'
import { useStyles } from './Search.styled'
import { useTranslate } from 'locale/Locale'

import { toString } from 'lodash'
import { InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'


/**
 * Komponent wyszukiwania
 *
 * @returns {Node}
 */

export default function Search() {
	const form = useForm('data')
	const dataType = form._dataType ? form._dataType : ""
	const INPUT_NAME = "SearchInput"
	const translate = useTranslate('WebSpa/Placeholders')
	const [setDebounceValue] = useDebounce(() => {
		form.currentPage = 1
		form.asyncLoad()
	}, 500)

	const handleOnInput = e => {
		const v = e.target.value
		form.filter = v.trimLeft()
		setDebounceValue(v.trimLeft())
	}

	const classes = useStyles()
	return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon  style={{color: '#1a314b'}} />
			</div>
			<InputBase
				id={`${dataType}-${INPUT_NAME}`}
				type='text'
				value={toString(form.filter)}
				inputProps={{ 'aria-label': 'search' }}
				placeholder={translate('search')}
				classes={{ root: classes.inputRoot, input: classes.inputInput }}
				onInput={e => handleOnInput(e)}
			/>
		</div>
	)
}