import React from 'react'
import Box from '@material-ui/core/Box';

export default function SingleBoxContainer(props) {
	return (
		<Box display="flex" justifyContent="center" m={1} p={1} >
			<Box p={1}>
				{props.children}
			</Box>
		</Box>
	)
}