import React from 'react'
import useForm from 'components/form/hooks/useForm'
import { GridResizable } from 'components/topology/Topology'
import { customElements } from 'cssStatic'

const FocusDiv = customElements('o-focus-div', {
	'&:focus': ['o-none', 'bs-none', 'br-transparent']
})

export function ListGrid({ children, onKeyUp }) {
	const form = useForm('data')
	const columns = form.columnManager.visibleColumnArray
	const templateX = columns.map(c => c.width ? c.width : 100)

	if (form.ganttManager.isGantt)
		templateX.push(400)

	return (
		<FocusDiv tabIndex='0'
			onKeyUp={e => {
				if (onKeyUp)
					onKeyUp(e, form)
			}}
			onMouseDown={e => {
				//Zapobieganie selekcji tekstu w liscie
				if (e.shiftKey)
					e.preventDefault()
			}}
		>
			<GridResizable templateX={templateX} onlyFirstRow canResizeEndX
				onResized={({ templateX }) => {
					if (!Array.isArray(templateX))
						return

					for (let i = 0; i < templateX.length && i < columns.length; ++i) {
						columns[i].width = templateX[i]
						form.columnManager._widths[columns[i].name] = {
							name: columns[i].name,
							width: templateX[i]
						}
					}
					form.saveConfiguration()
				}}
			>
				{children}
			</GridResizable>
		</FocusDiv>
	)
}