import React from 'react';
import Tooltip from '../Tooltip';
import { default as MaterialButton } from '@material-ui/core/Button';

const Button = ({label, tooltip, onClick, disabled, ...other}) => 
{
    return (
        <Tooltip title={!disabled ? tooltip : null}>
            <MaterialButton 
                variant="outlined" 
                onClick={onClick} 
                color="inherit" 
                disabled={disabled}
                {...other}>
                    {label}
            </MaterialButton>
        </Tooltip>
    );
};

export default Button;