
export default function shouldClose(isLoading: boolean, reason?: string): boolean
{
    if(reason)
    {
        if(reason === "backdropClick")
            return false;
        else if(reason === "escapeKeyDown" && isLoading)
            return false;
    }

    return true;
}