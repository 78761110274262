/**
 * Metoda scalająca parametry przesyłane w linku
 * @param {String} serviceHostUrl - adres
 * @param {integer} queryParamsStartIndex - indeks znaku który rozpoczyna query string'a
 * @param {String} urlPath - String zawierający wartości które chcemy dokleić do bazowego linku (może ale nie musi zawierać query string'a)
 * @returns {String} - Zwraca string ze wszystkimi parametrami
 */

export function mergeQueryParams ( serviceHostUrl, queryParamsStartIndex, urlPath ){
	let serviceHostUrlQueryParams = serviceHostUrl.slice(queryParamsStartIndex);

	if ( serviceHostUrlQueryParams.indexOf('/') !== -1 ){
		serviceHostUrlQueryParams = serviceHostUrlQueryParams.slice(0, serviceHostUrlQueryParams.indexOf('/'))
	}
	if( urlPath.indexOf('?') !== -1 ){
		let serviceHostUrlParams = new URLSearchParams( serviceHostUrlQueryParams );
		let urlPathParams = new URLSearchParams(urlPath.slice(urlPath.indexOf('?')));

		for (const [key, value] of urlPathParams) {
			if( serviceHostUrlParams.has(key) ){
				serviceHostUrlParams.set(key, value);
			}
			else if ( !serviceHostUrlParams.has( key )){
				serviceHostUrlParams.append(key, value);
			}
		}
		serviceHostUrlQueryParams = '?'+serviceHostUrlParams.toString();
	}

	return serviceHostUrlQueryParams;
}