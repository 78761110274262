import React, { useMemo, useRef } from 'react'
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import Grid from '@material-ui/core/Grid';
import './TranslationUnitsDocument.css'
import TranslationUnit from './TranslationUnit';


import LivoCatApiDataMapper, {performApiRequest} from '../contexts/data/LivoCatApiDataMapper';
import { RequestOptionsCreator, RequestState } from '../contexts/data/RequestModel';
import useUser from '../hooks/useUser';
import useLanguageDocument from '../hooks/useLanguageDocument';
import { DataContextApi, DataContextOptions, useDataContextApi } from '../hooks/useDataContext';
import TranslationUnitViewModel, { TranslationUnitAttributes, hasSegmentSelectedElement, markNextHighlightSelected, removeHighlightedEntitiesFromDraftIndex } from './model/TranslationUnitViewModel';
import { useCallback } from 'react';
import { useState } from 'react';
import localConfiguration from '../model/LocalConfiguration';
import useMemoEntitiesProjection from '../hooks/useMemoEntitiesProjection';
import { EntityId, IEntityContextStateController } from '../contexts/data/DataContextModel';
import {EditorFilters} from '../EditorFiltersDialog';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularPreloader from '../../../components/preloader/CircularPreloader';
import EditorFindAndReplaceSearch from '../model/EditorFindAndReplaceSearch';
import { DocumentCommentChangeState, DocumentCommentChangedArgs, IFindAndReplaceEvents, ObjectChangeOperationType } from '../model/EditorMediator';
import useEditorMediator from '../hooks/useEditorMediator';
import TranslationUnitDataMapper from './model/TranslationUnitDataMapper';
import useSessionTask from '../hooks/useSessionTask';
import TranslationUnitsDocumentController from './TranslationUnitsDocumentController';
import { getDocumentStartIndex } from '../model/Utils';
import { useEditor } from '../hooks/useEditor';
import { addComments, removeComments } from '../model/TranslationUnit';
import ConfirmationLevelType from './model/ConfirmationLevelType';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function getTUDocumentName(languageDocuments: Array<any>, translationUnitId: number): string
{
	return languageDocuments.find(doc => 
		!doc.hasMergedDocuments && 
		translationUnitId >= doc.segmentsRange.start && 
		translationUnitId <= doc.segmentsRange.end)?.name;
}

function getTUDocumentId(languageDocuments: Array<any>, translationUnitId: number): string
{
	return languageDocuments.find(doc => 
		!doc.hasMergedDocuments && 
		translationUnitId >= doc.segmentsRange.start && 
		translationUnitId <= doc.segmentsRange.end)?.id;
}

function tryScrollToMergedDocumentHeader(documentId: string) : boolean
{
	const documentHeader = document.getElementById('tu-h-' + documentId);
	if(!documentHeader)
		return false;

    documentHeader.scrollIntoView();
	return true;
}

function tryScrollToTranslationUnit(sequenceNumberId: number): boolean
{
	const tuRow = document.getElementById('tu-' + sequenceNumberId);
	if(!tuRow)
		return false;

    tuRow.scrollIntoView();
	return true;
}

function findNotConfirmedTuSequenceNumber(dataContext: DataContextApi, startingFromSequenceNumber: number)
{
	if(startingFromSequenceNumber < 0)
		startingFromSequenceNumber = 0;

	for(const tuId of dataContext.contextState.entityOrderedSet)
	{
		const tuSequenceNumber = parseInt(tuId);
		if(tuSequenceNumber < startingFromSequenceNumber)
			continue;

		const tu = dataContext.getEntity(tuId);
		const confirmationLevel = tu[TranslationUnitAttributes.ConfirmationLevel];
		if(ConfirmationLevelType.isNotTranslated(confirmationLevel))
		{
			return tuSequenceNumber;
		}
	}

	return -1;
}

const ConfigItemsPerPage: string = 'Components:TranslationUnitsDocument:ItemsPerPage';
localConfiguration.registerDefaultNumber(ConfigItemsPerPage, 50);

/** Hook udostępniający funkcjonalność zmiany ilości TranslationUnit na stronie */
const useItemsPerPage = () => {
	const [itemsPerPage, setItemsPerPage] = useState(localConfiguration.getValueNumber(ConfigItemsPerPage));
	const setItemsPerPageConfig = (value: number) => {
		localConfiguration.setValueNumber(ConfigItemsPerPage, value);
		setItemsPerPage(value);
	};

	return [itemsPerPage, setItemsPerPageConfig] as const;
}

export type TranslationUnitsDocumentTsProps = {
	languageDocumentId: string,
	trackingMode: boolean,
	filters: EditorFilters,
	findSearch: EditorFindAndReplaceSearch
}

export default function TranslationUnitsDocumentTs(props : TranslationUnitsDocumentTsProps) {
	const classes = useStyles();
	const user = useUser();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useItemsPerPage();
	const selectedTuSequenceNumber = useRef(-1);
	const isMounted = useRef(false);
	const documentController = useRef(new TranslationUnitsDocumentController());
	const allowBackendToChangePage = useRef(props.findSearch.findWhat != null);
	const actualRenderedPage = useRef(currentPage);
	const currentTask = useSessionTask();
	const documentIdToScrollTo = useRef(null); // Identyfikator dokumentu, do którego chcemy się zescrollować.
	const tuSequenceNumberToScrollTo = useRef(null); // Numer segmentu do którego chcemy się zescrolować
	const goToNextPageWithTuToConfirm = useRef(false); // Czy chcemy przejść do kolejnej strony gdzie znajduje się segment do potwierdzenia?

	const editor = useEditor({
        subscribeTo: (controller) => {
            controller.subscribeFindAndReplace(documentController.current);
			controller.subscribeEditorEvents(documentController.current);
			controller.subscribeEditorDocumentEvents(documentController.current);
			controller.subscribeTranslationUnitEvents(documentController.current);
        },
        unsubscribeFrom: (controller) => {
            controller.unsubscriveFindAndReplace(documentController.current);
			controller.unsubscribeEditorEvents(documentController.current);
			controller.unsubscribeEditorDocumentEvents(documentController.current);
			controller.unsubscribeTranslationUnitEvents(documentController.current);
        }
    });

	const backendStorageOptions: DataContextOptions = useMemo(() => {
		const isActiveFindRoutine = props.findSearch.findWhat != null && props.findSearch.findWhat !== "";
		return {
			load: RequestOptionsCreator.load(
				async () => {
					const paginationOptions = { 
						page: 1, 
						limit: itemsPerPage, 
						allowPageChange: allowBackendToChangePage.current, 
						clientCurrentPage: actualRenderedPage.current,
						mergedDocumentId: undefined,
						sequenceIndex: undefined,
						nextTuToConfirm: undefined };
					paginationOptions.page = currentPage;
					if(documentIdToScrollTo.current !== null)
					{
						paginationOptions.mergedDocumentId = documentIdToScrollTo.current;
						paginationOptions.allowPageChange = true;
					}
					else if(tuSequenceNumberToScrollTo.current !== null)
					{
						paginationOptions.sequenceIndex = tuSequenceNumberToScrollTo.current;
						paginationOptions.allowPageChange = true;
					}
					else if(goToNextPageWithTuToConfirm.current)
					{
						paginationOptions.nextTuToConfirm = true;
						paginationOptions.allowPageChange = true;
					}

					return await performApiRequest(user, "GET", "getTranslationUnits", [props.languageDocumentId, paginationOptions, props.filters, props.findSearch]);
				},
				"getTranslationUnits_" + props.languageDocumentId + "_" + currentPage + "_" + itemsPerPage + "_" + new Date().getTime(),
				new TranslationUnitDataMapper(isActiveFindRoutine)
			),
			update: RequestOptionsCreator.update(
				async (payload: any) => {
					return await performApiRequest(user, "UPDATE", "updateTranslationUnits", [props.languageDocumentId, payload]);
				},
				"updateTranslationUnits",
				 new TranslationUnitDataMapper(false)
			)
		}
	}, [props.languageDocumentId, currentPage, itemsPerPage, props.filters, props.findSearch]);

	const handleChangePage = useCallback((event: React.ChangeEvent<unknown>, newPage: number) => {
		selectedTuSequenceNumber.current = -1;
		setCurrentPage(newPage + 1);
		actualRenderedPage.current = newPage;
		allowBackendToChangePage.current = false;
		console.log("Handle page change", newPage);
	}, [setCurrentPage]);

	const languageDocument = useLanguageDocument()
	const dataContext = useDataContextApi("TranslationUnits:" + props.languageDocumentId, backendStorageOptions);
	const onTargetSegmentChanged = useCallback( (translationUnit, newSegmentValue) => {
		console.log("onTargetSegmentChanged");
		translationUnit.targetSegment = newSegmentValue;
		dataContext.dispatchDeferredUpdateRequest();
	}, [props.languageDocumentId]);

	const onTranslationUnitConfirmation = useCallback( (confirmedTranslationUnit, newSegmentValue) => {
		console.log("onTranslationUnitConfirmation");
		if (newSegmentValue) {
			confirmedTranslationUnit.targetSegment = newSegmentValue
        }
		if (confirmedTranslationUnit.confirmTranslation(newSegmentValue != null))
		{
			dataContext.dispatchDeferredUpdateRequest();
		}

		editor.mediator.onTuConfirmationLevelChanged(props.languageDocumentId, confirmedTranslationUnit.id, confirmedTranslationUnit.confirmationLevel);

	}, [props.languageDocumentId, dataContext.contextState]);

	const onTranslationUnitSelected = useCallback( (translationUnit) => {
		languageDocument.notifySegmentSelected(props.languageDocumentId, translationUnit.sequenceNumber);
		selectedTuSequenceNumber.current = translationUnit.sequenceNumber;
		editor.mediator.onSelectedTranslationUnit(props.languageDocumentId, translationUnit.sequenceNumber);
		editor.selectedTranslationUnit(translationUnit.sequenceNumber);
		console.log("Translation unit selected: " + translationUnit.sequenceNumber);
    }, [props.languageDocumentId]);

	const translationUnits = useMemoEntitiesProjection((entityId: EntityId, dataContextScoped: DataContextApi, index: number, memoScopeState: any) => {
			const translationUnitEntity = dataContextScoped.getEntity(entityId);
			const translationUnit = new TranslationUnitViewModel(translationUnitEntity, dataContext);
			let fileStartHeaderLabel = null;
			let fileStartHeaderId = null;
			if(currentTask?.languageDocuments)
			{
				const document = currentTask.languageDocuments.find(doc => doc.id === props.languageDocumentId);
				if(document?.hasMergedDocuments && props.filters?.qeFilters?.orderByScore == null)
				{
					const originalDocumentName = getTUDocumentName(currentTask.languageDocuments, parseInt(translationUnit.id));

					if(index === 0)
					{
						fileStartHeaderLabel = originalDocumentName;
						fileStartHeaderId = getTUDocumentId(currentTask.languageDocuments, parseInt(translationUnit.id));
					}
					else
					{
						if(!memoScopeState.previousTuDocumentName)
						{
							const previousEntityId = dataContextScoped.contextState.entityOrderedSet[index - 1];
							memoScopeState.previousTuDocumentName = getTUDocumentName(currentTask.languageDocuments, parseInt(previousEntityId));
						}

						if(memoScopeState.previousTuDocumentName !== originalDocumentName)
						{
							fileStartHeaderLabel = originalDocumentName;
							fileStartHeaderId = getTUDocumentId(currentTask.languageDocuments, parseInt(translationUnit.id));
						}

					}
					
					memoScopeState.previousTuDocumentName = originalDocumentName;
				}
			}

			return (
				<>
				{fileStartHeaderLabel &&
					<tr id={"tu-h-" + fileStartHeaderId} style={{backgroundColor: '#f7bd8d'}}>
						<td colSpan={5} style={{padding: '5px'}}>{fileStartHeaderLabel}</td>
					</tr>
				}
				<TranslationUnit
					languageDocumentId={props.languageDocumentId}
					translationUnit={translationUnit}
					trackingMode={props.trackingMode}
					key={`${props.languageDocumentId}_${translationUnit.id}`}
					onTargetSegmentChanged={onTargetSegmentChanged}
					onTranslationUnitConfirmation={onTranslationUnitConfirmation}
					onTranslationUnitSelected={onTranslationUnitSelected}
				/>
				</>)
		}, dataContext.contextState.entityOrderedSet, dataContext, props.trackingMode);
		
	const lastGetTranslationUnitsRequest = dataContext.getLastRequestByKeyStartsWith('getTranslationUnits');
	useEffect(() => {
		// Tutaj sprawdzamy czy nastąpiła zmiana strony ale juz po odebraniu danych.
		// Chodzi o to, że chcemy zaznaczyć szukany segment jeśli mamy jakies wyniki wyszukiwania, 
		// a żaden segment jeszcze nie jest zaznaczony
		//console.log("Calling onFindNext from internal", lastGetTranslationUnitsRequest)
		if(translationUnits.length === 0 || 
			lastGetTranslationUnitsRequest == null || 
			lastGetTranslationUnitsRequest.state !== RequestState.Successed ||
			selectedTuSequenceNumber.current !== -1)
			return;

		if(documentIdToScrollTo.current !== null)
		{
			tryScrollToMergedDocumentHeader(documentIdToScrollTo.current);
			documentIdToScrollTo.current = null;
		}
		else if(tuSequenceNumberToScrollTo.current !== null)
		{
			if(tryScrollToTranslationUnit(tuSequenceNumberToScrollTo.current))
			{
				editor.mediator.selectTranslationUnit(props.languageDocumentId, tuSequenceNumberToScrollTo.current);
			}
			tuSequenceNumberToScrollTo.current = null;
		}
		else if(goToNextPageWithTuToConfirm.current)
		{
			goToNextPageWithTuToConfirm.current = false;
			const notConfirmedTuSequenceNumber = findNotConfirmedTuSequenceNumber(dataContext, 0);
			console.log("Not confirmed segment: ", notConfirmedTuSequenceNumber);
			if(notConfirmedTuSequenceNumber > 0)
			{
				editor.mediator.onGoToDocumentTU(notConfirmedTuSequenceNumber);
			}
			return;
		}

		const didChange = true;
		documentController.current.selectNextFindResultOrGoToNextPage(props.findSearch, didChange, false);
	}, [lastGetTranslationUnitsRequest]);

	useEffect(() => {
		console.log("Document mount: " + props.languageDocumentId);
		isMounted.current = true;

		return () => {
			console.log("Document unmount: " + props.languageDocumentId);
			isMounted.current = false;
		}
	}, [])

	if(translationUnits.length === 0)
	{
		return null;
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	  ) => {
		setItemsPerPage(parseInt(event.target.value, 10));
		setCurrentPage(1);
		actualRenderedPage.current = 1;
	  };

	
	const showPreloader = lastGetTranslationUnitsRequest && lastGetTranslationUnitsRequest.state === RequestState.Pending;
	documentController.current.onFindAndReplaceCancel = () => {
		dataContext.performStateUpdate((stateController) => {
            removeHighlightedEntitiesFromDraftIndex(stateController);
        });
	}

	documentController.current.onFindNext = (findSearch: EditorFindAndReplaceSearch, didChange: boolean) => 
	{
		console.log(documentController.current.getHandlerDebugId() + "Document onFindNext", props.languageDocumentId);
		documentController.current.selectNextFindResultOrGoToNextPage(findSearch, didChange, false);
	}

	documentController.current.onReplace = (findSearch: EditorFindAndReplaceSearch, replaceAll: boolean, didChange: boolean) => 
	{
		if(!replaceAll)
		{
			let isSelectedElement = false;
			for (const entityId of dataContext.contextState.entityOrderedSet) 
			{
				const translationUnitEntity = dataContext.getEntity(entityId);
				const sequenceNumber = translationUnitEntity[TranslationUnitAttributes.SeqenceNumber] as number;

				if(sequenceNumber < selectedTuSequenceNumber.current)
					continue;

				if(hasSegmentSelectedElement(translationUnitEntity, findSearch.findInSource))
				{
					isSelectedElement = true;
					break;
				}
			}

			if(!isSelectedElement)
			{
				documentController.current.onFindNext(findSearch, didChange);
				return;
			}
		}

		console.log(documentController.current.getHandlerDebugId() + "Document onReplace", props.languageDocumentId);
		const paginationOptions = { 
			page: replaceAll ? 1 : actualRenderedPage.current, 
			limit: itemsPerPage, 
			allowPageChange: allowBackendToChangePage.current, 
			clientCurrentPage: actualRenderedPage.current 
		};
		paginationOptions.page = currentPage;
		dataContext.dispatchUpdateRequestWithOptions(
			RequestOptionsCreator.update(
				async (payload: any) => {
					return await performApiRequest(user, "UPDATE", "updateTranslationUnitsReplace", [props.languageDocumentId, payload, paginationOptions, props.filters, findSearch, replaceAll]);
				},
				"updateTranslationUnitsReplace",
				new TranslationUnitDataMapper(false))
		)
	}

	documentController.current.selectNextFindResultOrGoToNextPage = (findSearch: EditorFindAndReplaceSearch, didChange: boolean, userChangedPageNavigation: boolean) =>
	{
		let nextTu = -1;
		dataContext.performStateUpdate((stateController: IEntityContextStateController) => {
			if(didChange)
			{
				removeHighlightedEntitiesFromDraftIndex(stateController);
			}
			
			const translationUnitsIds = stateController.getEntityOrderSet();
			// Szukamy następnego segmentu, do którego przechodzimy na podstawie wartości selectedTuSequenceNumber.
			for (const entityId of translationUnitsIds) 
			{
				const translationUnitEntity = stateController.getEntityActual(entityId);
				const sequenceNumber = translationUnitEntity[TranslationUnitAttributes.SeqenceNumber] as number;

				if(sequenceNumber < selectedTuSequenceNumber.current)
					continue;

				if(!TranslationUnitViewModel.hasFindResult(translationUnitEntity, findSearch.findInSource))
					continue;

				if(sequenceNumber === selectedTuSequenceNumber.current)
				{
					if(markNextHighlightSelected(translationUnitEntity, stateController, findSearch.findInSource ) != null)
					{
						nextTu = sequenceNumber;
						return;
					}
					else
						continue;
				}
				else
				{
					markNextHighlightSelected(translationUnitEntity, stateController, findSearch.findInSource );
				}

				nextTu = sequenceNumber;
				break;
			}
		});

		if(nextTu !== -1)
		{
			editor.mediator.selectTranslationUnit(props.languageDocumentId, nextTu);
		}
		else
		{
			// Nie znaleźłiśmy żadnego segmentu na aktualnej stronie.
			actualRenderedPage.current = dataContext.contextState.pagination.currentPage;
			if(didChange)
			{
				return;
			}
				
			// Go to next page
			setCurrentPage(dataContext.contextState.pagination.currentPage + 1);
			allowBackendToChangePage.current = !userChangedPageNavigation;
			selectedTuSequenceNumber.current = -1;
		}
	}

	documentController.current.onActiveDocumentChanged = (documentId: string) =>
	{

	}

	documentController.current.onGoToDocument = (documentId: string) => 
	{
		const documentStartSegmentSequenceIndex = getDocumentStartIndex(documentId, currentTask.languageDocuments);
		if(documentStartSegmentSequenceIndex === -1)
			return;

		console.log(documentStartSegmentSequenceIndex);
		if(!tryScrollToMergedDocumentHeader(documentId))
		{
			// Nie udało się zeskrolować do TU przynależnego go podanego dokumentu. To najpewniej oznacza, 
			// że dokument nie jest na obecnie prezentowanej stronie. Triggerujemy odpytanie backendu o dane.
			documentIdToScrollTo.current = documentId;
			handleChangePage(null, currentPage);
		}
		else
		{
			documentIdToScrollTo.current = null;
		}
	};

	documentController.current.onGoToDocumentTU = (tuSequenceNumber: number) => 
	{
		console.log(tuSequenceNumber);
		if(tuSequenceNumber == null || tuSequenceNumber === -1)
			return;

		if(!tryScrollToTranslationUnit(tuSequenceNumber))
		{
			// Nie udało się zeskrolować do TU przynależnego go podanego dokumentu. To najpewniej oznacza, 
			// że dokument nie jest na obecnie prezentowanej stronie. Triggerujemy odpytanie backendu o dane.
			tuSequenceNumberToScrollTo.current = tuSequenceNumber;
			handleChangePage(null, currentPage);
		}
		else
		{
			tuSequenceNumberToScrollTo.current = null;
			editor.mediator.selectTranslationUnit(props.languageDocumentId, tuSequenceNumber);
		}
	};

	documentController.current.onSelectedTranslationUnit = (languageDocumentId: string, sequenceNumber: number) =>
	{
	}

	documentController.current.onDocumentCommentChanged = (languageDocumentId: string, args: DocumentCommentChangedArgs) =>
	{
		if(languageDocumentId !== props.languageDocumentId || args == null || args.changes == null)
			return;

		dataContext.performStateUpdate((stateController: IEntityContextStateController) => 
		{
			for (const commentChange of args.changes)
			{
				if(commentChange.operation === ObjectChangeOperationType.DELETED)
				{
					removeComments(commentChange, stateController);
				}
				else if(commentChange.operation === ObjectChangeOperationType.CREATED)
				{
					addComments(commentChange, stateController);
				}
			}
		});
	}

	documentController.current.onTuConfirmationLevelChanged = (languageDocumentId: string, sequenceNumber: number, confirmationLevel: number) =>
	{
		const notConfirmedTuSequenceNumber = findNotConfirmedTuSequenceNumber(dataContext, sequenceNumber + 1);
		if(notConfirmedTuSequenceNumber > 0)
		{
			editor.mediator.selectTranslationUnit(props.languageDocumentId, notConfirmedTuSequenceNumber);
		}
		else
		{
			// Pytamy backend o strone z następnym segmentem do zatwierdzenia
			// Go to next page
			setCurrentPage(dataContext.contextState.pagination.currentPage + 1);
			allowBackendToChangePage.current = true;
			goToNextPageWithTuToConfirm.current = true;
			selectedTuSequenceNumber.current = -1;
		}
	}

	documentController.current.onTryFixTuTags = (languageDocumentId: string, sequenceNumber: number) =>
	{
		console.log("onTryFixTuTags");
		dataContext.dispatchCreateRequestWithOptions(
			RequestOptionsCreator.update(async (payload) => {
				return await performApiRequest(user, "UPDATE", "tryFixTagsInTu", [languageDocumentId, sequenceNumber]);
			},
			"tryFixTagsInTu:" + editor.state.activeDocumentId,
			new LivoCatApiDataMapper()),
			(key, state) => {
				
			}
		);
	}

	// Nie renderujemy wierszy zanim nie załadujemy danych. Nie chcemy pokazywać istniejących wartości przed przeładowaniem.
	if(!isMounted.current)
	{
		return null;
	}

	console.log(`[Pagination] Current: ${dataContext?.contextState?.pagination?.currentPage}, Count: ${dataContext?.contextState?.pagination?.pagesCount}, Local State Current: ${currentPage}, CTX: ${dataContext?.contextState?.revision}`);
	return (
		<Paper elevation={3} style={{'overflowY': 'auto'}}>
			<table className='translationUnitsGrid'>
				<tbody>
					{translationUnits}
				</tbody>
			</table>
			<Grid container justifyContent="flex-end">
				<TablePagination
					component="div"
					count={dataContext.contextState.pagination.pagesCount * itemsPerPage}
					page={dataContext.contextState.pagination.currentPage - 1}
					onPageChange={handleChangePage}
					rowsPerPage={itemsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					rowsPerPageOptions={[5, 15, 25, 50, 100, 250, 500, 750, 1000]}
					labelRowsPerPage={"Segments per page:"}
					/>
			</Grid>
			<Backdrop 
				className={classes.backdrop} 
				open={showPreloader}>
				<CircularPreloader label="Please wait" />
        	</Backdrop>
		</Paper>
	)
}