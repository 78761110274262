import React from 'react';
import { isEmpty, remove, join, result } from 'lodash'
import getFormRestService from 'services/getFormRestService'
import { appCtrl } from 'AppCtrl'
import {  Close } from '@material-ui/icons'
     
export default class Data {
   constructor( enqueueSnackbar, closeSnackbar) {
      this.files = []
      this.enqueueSnackbar = enqueueSnackbar //Funkcja dodająca Snackbara
      this.closeSnackbar = closeSnackbar //Funkcja usuwająca Snackbara

      this._localeClass = appCtrl.locale //klasa odpowiedzialna za tłumaczenie stringów
   }

   onRenderProvider({ field, name, hasLabel, serviceName }) {
      this.serviceName = serviceName
      this.field = field
      this.disabled = field.disabled
      this.label = field.label
      this.required = field.required
      this.placeholder = field.placeholder
      this.fieldName = name 
      this.hasLabel = hasLabel
   }

   /**
    * Funkcja która jako argument przyjmuje rozmiar pliku w bitach a zwraca string z rozmiarem zamienionym na jednostę B, KB, MB, GB lub TB
    * @param {Number} bytes - rozmiar pliku w bitach
    * @param {Numer} decimals - ilość miejsc po przecinku do których zaokrąglany jest wynik
    * @returns {String} - Zaokrąglony zapis rozmiaru pliku wraz z odpowiednią jednostką 
    */
   formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 B'
      
      if (!bytes) return ''

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = Object.freeze(['B', 'KB', 'MB', 'GB', 'TB'])
      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  /**
   * Metoda dodająca kolejny obiekt do tablicy ze wszystkimi plikami
   * @param {File Object} newFile 
   */
   addFileToArray(newFile){ 
      this.files.push(newFile) 
   }

   /**
    * Metoda usuwająca dany plik z tablicy plików, zapisująca zmiany w tablicy plików do Formy oraz odświeżająca widok. 
    * Usuwany obiekt jest znajdowany w tablicy wszystkich plików na podstawie przekazanego id
    * @param {String} id  - id pliku który chcemy usunąć
    */
   deleteFile(id){
      if(!id)
         return null
        
      remove(this.files, (file) => id === file.id )
      
      if(isEmpty(this.files)){
         this.field.form.handleChange(this.fieldName, undefined) 
      }
      else {
         this.field.form.handleChange(this.fieldName, {files: this.files})  //TODO dodać request do BE usuwający wgrane wcześniej pliki.
      }

      this.refresh()
   }

   /**
    * Metoda zapisująca aktualną tablicę z plikami do BE. Z tej tablicy korzysta w późniejszym etpaie komponent CreatePartOuter
    */
   addFilesToCurrentForm(){
      if(isEmpty(this.files))
         return null
      
      this.field.form.handleChange(this.fieldName, {files: this.files})
   }

   /**
    * Metoda pozwalająca na zapisanie podanego jako argument pliku w BE. Oprócz zapisu plików w BE metoda ta obsługuje wyświetlanie się Snackbarów
    * różnych typów na różnych etapach wgrywania pliku oraz zapisuje to obiektu File na którym operuje najważniejsze informacje takie jak id, result (odpowiedź z BE)
    * oraz rozmiar pliku z odpowiednią jednostką
    * @param {File Object} file - Obiekt typu File posiadający wszystkie istotne informacje o wgrywanym pliku 
    */
   async uploadFilesToBE(files, fileProgress) {
      const fileIdToFileHandleMap = {};
      for (const file of files) 
      {
         // Nie mamy identyfikatora pliku, ponieważ jeszcze nie istnieje. Spróbujmy jakiś nadać.
         file.tempInternalId = file.name + "_" + Object.keys(fileIdToFileHandleMap).length;
         const fileHandle = fileProgress.addFile(file.tempInternalId, file.name);
         fileIdToFileHandleMap[file.tempInternalId] = fileHandle;
      }

      try
      {
         const results = await getFormRestService(this.serviceName).postMultipleFiles(files, (progressData) => {
            const tempInternalId = progressData.files[0].tempInternalId; // Na ten moment obsługujemy upload pliku pojedynczo
            const fileHandle = fileIdToFileHandleMap[tempInternalId];
            // Od wyliczonego procentowego postępu odejmujemy 1% aby zasygnalizować, że jeszcze request nie został zakończony.
            // Możemy wysłać binaria na serwer w 100% ale dopóki nie mamy odpowiedzi z serwera, to nie możemy oznaczyć tego pliku
            // na 100%. Robmimy to dopiero po przyjściu odpowiedzi. Ma to szczególne znaczenie, że odpowiedź nie musi przyjść od razu.
            // Uploadowany plik jest jeszcze buforowany przez reverse proxy, a dopiero później uploadowany na serwer plików.
            const percentageProgress = Math.max(0, Math.round((progressData.filesUploadedContentLength/progressData.filesContentLength)*100) - 1);
            fileProgress.setProgress(fileHandle, percentageProgress);
         });
   
         for (const file of files) 
         {
            const fileHandle = fileIdToFileHandleMap[file.tempInternalId];
            fileProgress.successed(fileHandle);
         }

         results.forEach(result => {
            if(!isEmpty(result?.value?.data) && !isEmpty(result.file)) {
               result.file.id = result.value.data?.[0]?.id //Przepisujemy id z odpowiedzi z BE do obiektu File
               result.file.result = result.value  //Przypisujemy odpowiedź z BE do obiektu File
               result.file.fileSizeString = this.formatBytes(result.file.size) //Pobieramy tekst o rozmiarze pliku
               this.addFileToArray(result.file) //Tak przygotowany plik dodajemy do tablicy wszystkich plików
            }
         })
      }
      catch(err)
      {
         // TODO: obsługa błędów
         for (const file of files) 
         {
            const fileHandle = fileIdToFileHandleMap[file.tempInternalId];
            fileProgress.failed(fileHandle, this._localeClass.translate("WebSpa/Snackbar/UploadFile/SnackbarMessage/errorDuringUpload"));
         }
      }
   }
}
