import React from 'react'
import { useAddElement } from 'components/positionObserver/PositionObserver'
import { customElements } from 'cssStatic'
import { context } from './Context'

const StyledZipElement = customElements('o-zip-element', [
	'grid', 'f-primary', 'f-16', 'overflow-x-hidden', 'min-width-0', 'noselect', 'ellipsis', 'nowrap', 'cursor-pointer',
	'br-3', 'text-grey700',
	{
		'& > div': ['m-tb-5', 'p-5', {
			'background-color': '#f9f9ff',
			'padding': '10px',
			'border-radius': '10px',
			'border-color': `var(--o-mainDarken700)`
		}],
		'&[is-mark="true"] > div': { 'background-color': '#eeeeff' },
		'&[left="false"] > div': ['br-left-solid', 'm-right-5'],
		'&[left="true"] > div': ['br-right-solid', 'm-left-5']
	}
])

const onClick = function ( evt, data, row, mark ) {


	if (evt.ctrlKey) {
		mark.toggleMark(row.id)
		data.refresh()
		return
	}

	if (evt.shiftKey) {
		mark.selectRange(row.id, data.left)
		data.refresh()
		return
	}

	mark.select(row.id)
	data.refresh()
}

export function ZipElementLeft({ row }) {
	const ref = useAddElement(row.id)
	const data = context.useContext()

	return (
		<StyledZipElement
			left
			is-mark={data._markLeft.isMark(row.id)}
			onClick={evt => onClick(evt, data, row, data._markLeft)
		}>
			<div ref={ref}>
				{row.value}
			</div>
		</StyledZipElement>
	)
}



export function ZipElementRight({ row }) {
	const ref = useAddElement(row.id)
	const data = context.useContext()

	return (
		<StyledZipElement
			left={false}
			is-mark={data._markRight.isMark(row.id)}
			onClick={evt => onClick(evt, data, row, data._markRight)
		}>
			<div ref={ref}>
				{row.value}
			</div>
		</StyledZipElement>
	)
}