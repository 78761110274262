import React from 'react'
import { useHistory } from 'react-router-dom'
import { ListRawComponentsTree } from './ListRawComponentsTree'
import { useForm } from 'components/form/Form'
import { linkManager } from 'components/LinkManager'

export function ListRaw({ part, dataTypeAdd, isGantt, inlineEditionOnly, onClickRow, onDoubleClickRow }) {
	const form = useForm('data')
	const history = useHistory()

	return (
		<div style={{ overflowX: 'auto' }}>
			<ListRawComponentsTree
				dataTypeAdd={dataTypeAdd}
				onAdd={({ marks }) => {
					form.asyncSubmit('createFromList', { marks })
				}}
				onKeyUp={e => {
					// switch (e.key) {
					// 	case 'Escape': {
					// 		const changedChildForm = form.changedChildForm

					// 		if (changedChildForm)
					// 			changedChildForm.clearChanges()

					// 		form.rowManager.editRowId = null
					// 		form.trigger('data')
					// 		break
					// 	}
					// 	case 'Enter': {
					// 		const changedChildForm = form.changedChildForm

					// 		if (changedChildForm)
					// 			changedChildForm.asyncSubmit('saveEditRow')
					// 		else {
					// 			if (form.rowManager.editRowId !== null) {
					// 				form.rowManager.editRowId = null
					// 				form.trigger('data')
					// 			}
					// 		}
					// 		break
					// 	}
					// 	default:
					// 		break
					// }
				}}
				onClickRow={(props) => {
					if (onClickRow) {
						onClickRow(props)
						return
					}

					const { rowIndex, e } = props
					const row = form.rowManager.visibleRows[rowIndex]

					if (!form.rowManager.inlineEdition) {
						if (form.rowManager.selectedRowId === row.id)
							return

						const id = linkManager.getNextPartId(part)

						//Zaznaczanie wielu rekordów
						if (e.shiftKey && form.rowManager.selectedRowId) {
							if (form.rowManager.markToSelectedRow(row.id))
								form.trigger('data')
							return
						}

						if (e.ctrlKey) {
							//Markujemy/odmarkowujemy wiersz
							form.rowManager.toogleIsMarked(row.id)
							if (form.rowManager.selectedRowId === undefined) {
								form.rowManager.setRowDataFromSelectedRow()
								form.rowManager.selectedRowId = row.id
							}
							form.trigger('data')
							return
						}

						form.rowManager.clearMarks()

						if (id) {
							//Czyścimy formatkę "edit" przed zmianą selekcji w gridzie 
							const formEdit = part.next?.props?.form
							formEdit && formEdit.clearChanges()
							linkManager.handleListId(history, part, row.id)
							return
						}

						form.rowManager.selectedRowId = row.id
						form.rowManager.setRowDataFromSelectedRow()
						form.trigger('data')
						return
					}

					if (!part) {
						form.rowManager.selectedRowId = row.id
						form.rowManager.setRowDataFromSelectedRow()
						form.trigger('data')
						return
					}

					if (row.id === undefined) {
						if (form.rowManager.selectedRowId === undefined)
							return
						//Zapis edit
						//Przechodzimy z wiersza/y edit do create
						form.asyncSaveEditRows(() => {
							form.rowManager.clearMarks()
							form.rowManager.selectedRowId = row.id
							form.rowManager.setRowDataFromSelectedRow()
							form.trigger('data')
						})
						return
					}

					if (form.rowManager.selectedRowId === undefined) {
						//zapis create
						//kliknięto na wiersz edit
						form.asyncSaveEditRows(() => {
							form.rowManager.clearMarks()
							form.rowManager.setIsMarked(row.id, true)
							form.rowManager.selectedRowId = row.id
							form.rowManager.setRowDataFromSelectedRow()
							form.trigger('data')
						})
						return
					}

					//Zaznaczanie wielu rekordów
					if (e.shiftKey && form.rowManager.selectedRowId) {
						if (form.rowManager.markToSelectedRow(row.id))
							form.trigger('data')
						return
					}

					if (e.ctrlKey) {
						//Markujemy/odmarkowujemy wiersz
						form.rowManager.toogleIsMarked(row.id)
						if (form.rowManager.selectedRowId === undefined) {
							form.rowManager.setRowDataFromSelectedRow()
							form.rowManager.selectedRowId = row.id
						}
						form.trigger('data')
						return
					}

					if (!form.rowManager.isMarked(row.id)) {
						//zapis edit
						//Mamy zaznaczone inne wiersze a klikniety wiersz niezamarkowany
						form.asyncSaveEditRows(() => {
							form.rowManager.clearMarks()
							form.rowManager.setIsMarked(row.id, true)
							form.rowManager.selectedRowId = row.id
							form.rowManager.setRowDataFromSelectedRow()
							form.trigger('data')
						})
						return
					}

					//Jeśli klikniety ten sam wiersz to nic nie robimy
					//(Przejście pomiedzy polami w wierszu)
					if (row.id === form.rowManager.selectedRowId)
						return

					//zapis edit
					//Przejscie pomiędzy wierszami zapisujemy ostatni
					form.asyncSaveEditRows(() => {
						form.rowManager.selectedRowId = row.id
						form.rowManager.setRowDataFromSelectedRow()
						form.trigger('data')
					})
				}}
				onDoubleClickRow={(props) => {
					if (onDoubleClickRow) {
						onDoubleClickRow(props)
						return
					}

					const { rowIndex, e } = props
					const row = form.rowManager.visibleRows[rowIndex]

					if (!form.rowManager.inlineEdition) {
						props.form.clearChanges()
						linkManager.handleListId(history, part, row.id)
						return
					}
				}}
				onClickGroupBreakLine={({ rowIndex }) => {
					let row = form.rowManager.visibleRows[rowIndex]

					//Ustawiamy dane potrzebne do ustawienia dodatkowych grupowanych danych
					form.rowManager._groupRow = row
					//Przełączenie do widoku create
					linkManager.handleListId(history, part)
				}}
				onClickAdd={() => {
					form.rowManager.selectedRowId = null
					form.rowManager.setRowDataAsCreate()
					if (part)
						part.handleSelectChildByName('create', true)
				}}
				onClickTreeChild={({ formRow, rowIndex }) => {
					let row = form.rowManager.visibleRows[rowIndex]

					form.rowManager.selectedRowId = null
					form.rowManager.setRowDataAsCreate()

					const treeParentRef = form.data.meta.treeParentRef
					const rowData = form.rowManager.rowData

					//Link tworzacy nowy rekord
					const createLink = formRow.serviceCreateTreeChild
					//dataType nowego rekordu
					const createDataType = createLink.rel.replace(/^(create)/, "")

					//Zmiana typu na nowy
					rowData.data[0].meta.dataType = createDataType
					rowData.meta.dataType = createDataType
					//Dodanie id parenta nowotworzonego wiersza
					rowData.data[0].attributes[treeParentRef] = { id: row.id }
					//Dodanie linka tworzenia tego wiersza
					rowData.links = { ['create' + createDataType]: formRow.serviceCreateTreeChild }
					if (part)
						part.handleSelectChildByName('create', true)
				}}
			/>
		</div>
	)
}