import React from 'react'
import useForm from 'components/form/hooks/useForm'

/**
 * Nagłówek listy
 *
 * @param {FormMetaArray} form Forma
 * @returns {Node}
 */
export function Header({ children }) {
	const form = useForm('data')
	const child = React.Children.only(children)

	return form.columnManager.visibleColumnArray.map(column => {
		return (
			<React.Fragment key={column.name}>
				{React.cloneElement(child, { key: column.name, column })}
			</React.Fragment>
		)
	})
}