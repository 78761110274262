import { inRange, isEmpty, toString} from 'lodash'
import { appCtrl } from 'AppCtrl'
// import { ClientErrorHandler } from './ClientErrorHandler'
// import { ServerErrorHandler } from './ServerErrorHandler'
// import { DefaultErrorHandler } from './DefaultErrorHandler'

/**
 * Klasa nadrzędna której zadaniem jest odpowiednia klasyfikacja błędu oraz wywołanie konstruktora klasy dziedziczącej
 * Z tej klasy dziedziczą pozostałe klasy obsługujące poszczególne błędy.
 * Należy w niej umieszczać TYLKO właściwości lub metody które są wspólne dla obsługi każdego z rodzajów błędów 
 */
export default class ErrorHandler {
   constructor(errorObject){
      this.errorObject = errorObject
      this._errorTypes = Object.freeze({
         API_ERROR: 'API ERROR',
         FRONTEND_ERROR: 'Frontend Error'
      })
   }

   /**
    * Funkcja uzupełniająca informacje o błędzie na podstawie jego typu jego instancji. 
    * Kiedy instancja jest typu Response mamy doczynienia z błędem w komunikacji z API
    * Kiedy instancja jest typu Error, mamy doczynienia z błędami zgłaszanymi przez JS
    * @returns {Error Object}
    */

   async errorProcesing(){
      if(this.errorObject instanceof Response) {//Obsługa błędów podczas komunikacji z API

         let errorDetailsFromResponse = {}

         try{
             errorDetailsFromResponse = await this?.errorObject?.clone()?.json()
         }
         catch(error){
           console.error(appCtrl?.locale?.translate("WebSpa/ErrorHandlerClasses/detailsFetchingError"))
         }
         
         if(inRange(this.errorObject?.status, 400, 499))//Obsługa błędów klienta z zakresu od 400 do 499
            throw new ClientErrorHandler(this.errorObject, errorDetailsFromResponse)

         if(inRange(this.errorObject?.status, 500, 599))//Obsługa błędów serwera z zakresu od 500 do 599
            throw new ServerErrorHandler(this.errorObject, errorDetailsFromResponse)

      }

      if(this.errorObject instanceof Error) { //Obsługa pozostałych błędów (Obiekt Error w JS)
         throw new DefaultErrorHandler(this.errorObject)       
      }

      // throw new Error(appCtrl?.locale?.translate('WebSpa/ErrorHandlerClasses/undefinedError')) //Sytuacja gdy instancja błędu nie zostanie poprawnie rozpoznana i sklasyfikowana
   }

   /**
    * Funkcja drukująca informacje o błędzie do konsoli na podstawie jego typu 
    */
   async printErrorToConsole(){
      if(!isEmpty(this.errorObject) && this.errorObject instanceof Response) { //Obsługa błędów podczas komunikacji z API
         
         let errorDetailsFromResponse = {}
         
         try{
             errorDetailsFromResponse = await this?.errorObject?.clone()?.json()
         }
         catch(error){}

         console.groupCollapsed(`%c***[id: ${toString(errorDetailsFromResponse?.traceId)}] Error ${this.status} - ${toString(this.title || this.type)}`, "background-color: #290000; color:#da6a6a ")
            console.error(`${toString(errorDetailsFromResponse?.detail)} \nMethod: ${toString(this.method)}`, `\nUrl:  `,this.url, `\nDetails: `, errorDetailsFromResponse)
         console.groupEnd()
      }

      if(this.errorObject instanceof Error){ //Obsługa pozostałych błędów (Obiekt Error w JS)
         console.groupCollapsed(`%c***[${toString(this.type)}] ${toString(this.title)}`, "background-color: #290000; color:#da6a6a")
            console.error(`Details: `, toString(this.errorObject?.stack))
         console.groupEnd()
      }
   }
}

/**
 * Klasa obsługująca błędy po stronie klienta - Czyli o typie błędu 'Response' oraz statusie między 400 a 499
 */
export class ClientErrorHandler extends ErrorHandler {
   constructor(errorResponse, errorDetailsFromResponse){
      super(errorResponse)
     
      
         this.ok = errorResponse?.ok
         this.status = errorDetailsFromResponse?.status || errorResponse?.status
         
         this.method = toString(errorResponse?.method)
         this.detail = toString(errorDetailsFromResponse?.detail)
         this.traceId = toString(errorDetailsFromResponse.traceId)
         this.url = toString(`${errorResponse?.method || ''} ${errorResponse?.url}`)
         this.responseData = isEmpty(errorResponse?.data) ? {} : errorResponse.data 
         this.messages = errorDetailsFromResponse?.messages
         this.errorDetailsFromResponse = errorDetailsFromResponse

         switch(this.status){
            case 400: {
               this.type = 'Bad Request'
               this.shouldErrorModalAppear = false
               break
            } 
            case 401: {
               this.type = 'Unauthorized'
               const isLoginRequest = this.url.includes("api/v1/auth/identities/login") && this.method === "POST";
               this.shouldErrorModalAppear = !isLoginRequest;
               break
            } 
            case 403: {
               this.type =  'Forbidden'
               this.shouldErrorModalAppear = true
               break
            } 
            case 404: {
               this.type = 'Not Found'
               this.shouldErrorModalAppear = true
               break
            } 
            case 405: {
               this.type = 'Method Not Allowed'
               this.shouldErrorModalAppear = true
               break
            } 
            case 413: {
               this.type = 'Payload Too Large'
               this.shouldErrorModalAppear = true
               break
            } 
            case 414: {
               this.type = 'URI Too Long'
               this.shouldErrorModalAppear = true
               break
            } 

            default: {
               this.type = this._errorTypes.API_ERROR
               this.shouldErrorModalAppear = true
               break
            }
            //W razie potrzeby dodać pozostałe kody błedów wraz ze zwracanym tytułem
            //Pozostałe kody błędów można znaleźć na stronie: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#information_responses
         }

         this.title = this.formatTitle();
         appCtrl.addMessages(this.messages, true) 
         this.printErrorToConsole()
   }

   /**
    * Metoda pozwalająca na ustawienie tytułu błędu
    * @returns {String}
    */
    formatTitle(){
      if(this.errorDetailsFromResponse?.title)
         return this.errorDetailsFromResponse.title
      else if(this.statusText)
         return this.statusText
      else if (this.status && this.type)
         return `Error ${this.status} - ${this.type}`
      else
         return ''   
   }
}

/**
 * Klasa obsługująca błędy po stronie serwera - Czyli o typie błędu 'Response' oraz statusie między 500 a 599
 */
export class ServerErrorHandler extends ErrorHandler {
   constructor(errorResponse, errorDetailsFromResponse){
      super(errorResponse)
     
         this.ok = errorResponse?.ok
         this.status = errorDetailsFromResponse?.status || errorResponse?.status
         
         this.method = toString(errorResponse?.method)
         this.detail = toString(errorDetailsFromResponse?.detail)
         this.traceId = toString(errorDetailsFromResponse.traceId)
         this.url = toString(`${errorResponse?.method || ''} ${errorResponse?.url}`)
         this.messages = errorDetailsFromResponse?.messages
         this.responseData = isEmpty(errorResponse?.data) ? {} : errorResponse.data 
         this.errorDetailsFromResponse = errorDetailsFromResponse

         switch(this.status){
            case 500: {
               this.type = 'Internal Server Error'
               this.shouldErrorModalAppear = true
               break;
            } 
            case 501: {
               this.type = 'Not Implemented'
               this.shouldErrorModalAppear = true
               break;
            } 
            case 502: {
               this.type = 'Bad Gateway'
               this.shouldErrorModalAppear = true
               break;
            } 
            case 503: {
               this.type = 'Service Unavailable'
               this.shouldErrorModalAppear = true
               break;
            } 
            case 504: {
               this.type = 'Gateway Timeout'
               this.shouldErrorModalAppear = true
               break;
            } 
            //W razie potrzeby dodać pozostałe kody błedów wraz ze zwracanym tytułem
            //Pozostałe kody błędów można znaleźć na stronie: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#information_responses
         }

         this.title = this.formatTitle();
         appCtrl.addMessages(this.messages, true) 
         this.printErrorToConsole()
   }

   /**
    * Metoda pozwalająca na ustawienie tytułu błędu
    * @returns {String}
    */
   formatTitle(){
      if(this.errorDetailsFromResponse?.title)
         return this.errorDetailsFromResponse.title
      else if(this.statusText)
         return this.statusText
      else if (this.status && this.type)
         return `Error ${this.status} - ${this.type}`   
      else
         return ''   
   }
}

const ERROR_INFO_LABEL = 'There has been a problem accessing the Internet or the remote server is unavailable.' +
                         'Please try again later or contact your system administrator.';
/**
 * Klasa obsługująca błędy po stronie Frontendu - Czyli o typie błędu 'Error'
 */
export class DefaultErrorHandler extends ErrorHandler {
   constructor(errorResponseObject){
      super(errorResponseObject)

         this.ok = false
         this.status = 0

         this.detail = toString(this.errorObject?.message)
         this.stack = toString(this.errorObject?.stack)
         this.type = toString(this.errorObject?.message)

         this.shouldErrorModalAppear = true

         if(this.type?.toLowerCase()?.includes('failed to fetch')){
            //W przypadku niezalogowanych użytkowników dajemy wiadomość po angielsku.
            if (!appCtrl?.locale.currentCultureCode) {
               this.detail = ERROR_INFO_LABEL;
            } else {
               this.detail = appCtrl?.locale?.translate("WebSpa/ErrorHandlerClasses/DefaultErrorHandler/failedToFetch");
            }
         }
         //W razie potrzeby dodać pozostałe typy błedów wraz ze zwracanym tytułem
         //Pozostałe typy błędów można znaleźć na stronie: https://developer.mozilla.org/pl/docs/Web/JavaScript/Reference/Global_Objects/Error

         this.title = this.formatTitle();
         this.printErrorToConsole()
   }

   formatTitle() {
      return this._errorTypes.FRONTEND_ERROR;
   }
}

export class LivoCatErrorHandler extends ErrorHandler {
   constructor(errorObject){
      super(errorObject);
   }

   async errorProcesing(){
      if(this.errorObject instanceof Response) {//Obsługa błędów podczas komunikacji z API

         let errorDetailsFromResponse = {}

         try{
             errorDetailsFromResponse = await this?.errorObject?.clone()?.json()
         }
         catch(error){
           console.error(appCtrl?.locale?.translate("WebSpa/ErrorHandlerClasses/detailsFetchingError"))
         }
         
         if(inRange(this.errorObject?.status, 400, 499))//Obsługa błędów klienta z zakresu od 400 do 499
            throw new ClientErrorHandler(this.errorObject, errorDetailsFromResponse)

         if(inRange(this.errorObject?.status, 500, 599))//Obsługa błędów serwera z zakresu od 500 do 599
            throw new ServerErrorHandler(this.errorObject, errorDetailsFromResponse)

      }

      if(this.errorObject instanceof Error) { //Obsługa pozostałych błędów (Obiekt Error w JS)
         throw new DefaultErrorHandler(this.errorObject)       
      }
   }
}

/**
 * Klasa obsługująca błędy po stronie serwera - Czyli o typie błędu 'Response' oraz statusie między 500 a 599
 */
 export class LivoCatAppErrorHandler extends ErrorHandler {
   constructor(errorResponse, errorDetailsFromResponse){
      super(errorResponse)
     
      this.ok = errorResponse?.ok
      this.status = errorDetailsFromResponse?.status || errorResponse?.status
      
      this.method = toString(errorResponse?.method)
      this.detail = toString(errorDetailsFromResponse?.detail)
      this.traceId = toString(errorDetailsFromResponse.traceId)
      this.url = toString(`${errorResponse?.method || ''} ${errorResponse?.url}`)
      this.messages = errorDetailsFromResponse?.messages
      this.responseData = isEmpty(errorResponse?.data) ? {} : errorResponse.data 
      this.errorDetailsFromResponse = errorDetailsFromResponse

      switch(this.status){
         case 500: {
            this.type = 'Internal Server Error'
            this.shouldErrorModalAppear = true
            break;
         } 
         case 501: {
            this.type = 'Not Implemented'
            this.shouldErrorModalAppear = true
            break;
         } 
         case 502: {
            this.type = 'Bad Gateway'
            this.shouldErrorModalAppear = true
            break;
         } 
         case 503: {
            this.type = 'Service Unavailable'
            this.shouldErrorModalAppear = true
            break;
         } 
         case 504: {
            this.type = 'Gateway Timeout'
            this.shouldErrorModalAppear = true
            break;
         }
         default :
            this.shouldErrorModalAppear = true
            break;
      }

      this.title = this.formatTitle();
      this.printErrorToConsole()
   }

   /**
    * Metoda pozwalająca na ustawienie tytułu błędu
    * @returns {String}
    */
   formatTitle(){
      if(this.errorDetailsFromResponse?.title)
         return this.errorDetailsFromResponse.title
      else if(this.statusText)
         return this.statusText
      else if (this.status && this.type)
         return `Error ${this.status} - ${this.type}`   
      else
         return ''   
   }
}