import React, {useEffect, useRef, useState} from "react";
import AutoSuggestComponent from "../autosuggest/Autosuggest";
import { List, ListItem, ListItemText, ListItemIcon, withStyles, Tooltip } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';

const styles = {
    delText: {
        color: '#A73A38',
    },
    addedText: {
        color: '#316197',
    },
    tag: {
        background: '#f57ac6',
        margin: '2px 1px',
        padding: '2px 5px',
        borderRadius: 3,
        display: 'inline'

    },
    insTag: {
        background: '#e8e5e5',
        color: '#316197',
        margin: '2px 1px',
        padding: '2px 5px',
        borderRadius: 3,
        display: 'inline',
        textDecoration: 'underline',

    },
    delTag: {
        background: '#e8e5e5',
        color: '#A73A38',
        margin: '2px 1px',
        padding: '2px 5px',
        borderRadius: 3,
        display: 'inline',
        textDecoration: 'line-through',

    }
};

const StyledList = withStyles(() => ({
    root: {
        padding: 0,
        caretColor: 'transparent'
    }
}))(List);

const StyledListItem = withStyles(() => ({
    root: {
        padding: '0.4rem 0.8rem',
        cursor: 'pointer'
    }
}))(ListItem);

const StyledListItemText = withStyles(() => ({
    root: {
        margin: 0
    },
    primary: {
        whiteSpace: 'nowrap',
        fontSize: '1em',
        '&:hover': {
            fontWeight: '500'
        }
    }
}))(ListItemText);

const HTMLTooltip = withStyles(() => ({
	tooltip: {
		fontSize: '13px',
        color: '#000',
        padding: 0,
        border: '1px solid #000',
        boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
        backgroundColor: '#fff'
	}
}))(Tooltip);

const approve =  props => {
    props.controller.approveChanges(props);
    document.activeElement.blur();
};

const discard =  props => {
    props.controller.discardChanges(props);
    document.activeElement.blur();
};


const TooltipList = props => {
    return (
        <React.Fragment>
            <StyledList disabled>
                <StyledListItem style={{borderBottom: '1px solid #000'}}
                    onMouseDown={e => {
                        e.preventDefault();
                        props.acceptChangesFunction();
                    }}
                >
                    <ListItemIcon style={{minWidth: 'auto', paddingRight: '0.5rem'}}>
                        <Check style={{color: '#009A34'}} />
                    </ListItemIcon>
                    <StyledListItemText primary="Accept changes" />
                </StyledListItem>
                <StyledListItem
                    onMouseDown={e => {
                        e.preventDefault();
                        props.discardChangesFunction();
                    }}
                >
                    <ListItemIcon style={{minWidth: 'auto', paddingRight: '0.5rem'}}>
                        <Clear style={{color: '#E40613'}} />
                    </ListItemIcon>
                    <StyledListItemText primary="Discard changes" />
                </StyledListItem>
            </StyledList>
        </React.Fragment>
    );
};

export const DeletedText = props => {
    return (
        <div style={{position: 'relative', display: 'inline-block'}}>
            <HTMLTooltip interactive={true} arrow title={
                props.controller.editorRef.current.props.hasFocus ? '' : <TooltipList acceptChangesFunction={() => approve(props)} discardChangesFunction={() => discard(props)} />
            }>
                <del style={styles.delText}>
                    {props.children}
                </del>
            </HTMLTooltip>
        </div>
    );
}

// export const SourceText = props => {
//     return (
//         <span>
//             {props.children}
//         </span>
//     )
// }

export const AddedText = props => {
    return (
        <div style={{position: 'relative', display: 'inline-block'}}>
            <HTMLTooltip interactive={true} arrow title={
                props.controller.editorRef.current.props.hasFocus ? '' : <TooltipList acceptChangesFunction={() => approve(props)} discardChangesFunction={() => discard(props)} />
            }>
                <ins style={styles.addedText}>
                    {props.children}
                </ins>
            </HTMLTooltip>
        </div>
    )
}

export const Tag = props => {
    return (
        <span style={styles.tag} >
             {props.children}
         </span>
    );
}

export const InsertTag = props => {
    return (
        
        <div style={{position: 'relative', display: 'inline-block'}}>
        <HTMLTooltip interactive={true} arrow title={
            props.controller.editorRef.current.props.hasFocus ? '' : <TooltipList acceptChangesFunction={() => approve(props)} discardChangesFunction={() => discard(props)} />
        }>
            <span style={styles.insTag}>
             {props.children}
            </span>
        </HTMLTooltip>
    </div>
    );
}

export const DeleteTag = props => {
    return (
        
        <div style={{position: 'relative', display: 'inline-block'}}>
        <HTMLTooltip interactive={true} arrow title={
            props.controller.editorRef.current.props.hasFocus ? '' : <TooltipList acceptChangesFunction={() => approve(props)} discardChangesFunction={() => discard(props)} />
        }>
            <span style={styles.delTag}>
             {props.children}
            </span>
        </HTMLTooltip>
    </div>
    );
}

export const InputWithSuggestion = props => {
    return (
        <div contentEditable={true} style={
            {
                position: 'absolute',
                left: props.coordinates.x,
                top: props.coordinates.y+20 + window.scrollY
            }}>
            <AutoSuggestComponent
                allowedTags={props.allowedTags}
                onSubmit={props.onSubmit}
                onClose={props.onClose}

            />
        </div>
    );
}
