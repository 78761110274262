import { GlobalStrings } from 'GlobalStrings'
import { translate } from 'locale/Locale'

/**
* Nazwa kolumny, która przechowuje wiadomości dla użytkownika w formie 'karteczek'
*/
export const messageColumnName = 'message_column';
/**
 * Klasa menagera organizujaca kolumny w listach
 */
export default class ColumnManager {
	/**
	 * Konstruktor
	 * @param {Object} form Forma do której jest podłączony ten menager kolumn
	 */
	constructor(form) {
		this._form = form
		/*
			{
				name:{
					name,
					dir,
					index
				},...
			}
		*/
		this._sort = {}
		/*
			{
				name:{
					name,
					dir,
					index
				},...
			}
		*/
		this._group = {}

		/*
		{
			"nazwa kolumny":{
				name,					//nazwa kolumny
				visible,				//boolean
				index,				//int - resultat obliczenia kolejnosci rekordow
				sequenceIndex,		//int - index pobrany z meta.sequenceIndex 
				meta,					//Opis meta
				group, 				//boolean
				groupIndex,			//int	- kolejność kolumn grupujących
				sortDir,				// -1 0 1 - malejąco / nie ma / rosnąco 
				sortIndex,			//int - index sortowania ktory sort jest pierwszy nie posortowane muszą być === -1
				polymorphic,		//boolean
				polymorphicIndex 	//int - kolejnosc kolumn polimorficznych
			},...
		}
		*/
		this._columns = {}

		/*
			{
				'nazwa kolumny':{
					name,		//Nazwa kolumny
					width		//szerokość
				},...
			}
		*/
		this._widths = {}

		/*
			{
				'nazwa kolumny':{
					name,				//Nazwa kolumny
					index,			//pozycja
					visible		//widocznosc
				},...
			}
		*/
		this._positions = {}
	}

	get form() { return this._form }

	/**
	 * Czy jest kolumna 'message_column'
	 * @type {boolean}
	 */
	get hasMessageColumn() { return this._hasMessageColumn }

	/**
	 * 
	 * @param {Array} v  - tablica wg. wzoru: ['nazwa_kolumny']
	 */
	setGroup(v) {
		this._group = {}
		if (!v)
			return
		this._group = v.reduce((p, name, index) => {
			p[name] = {
				name,
				dir: 1,
				index,
			}
			return p
		}, {})
	}

	/**
	 * Funkcja ustawiająca sortowanie
	 * @param {Array} v - tablica obiektów wg wzoru: [{nazwa_kolumny: 'nazwa_kolumny', dir: -1/0/1}] 
	 */
	setSort(v) {
		this._sort = {}
		if (!v)
			return
		this._sort = v.reduce((p, object, index) => {
			const { name, dir } = object
			p[name] = {
				name,
				dir: dir !== undefined ? dir : 1,//-1 / 0 / 1 - malejąco / brak / rosnąco  
				index,
			}
			return p
		}, {})

	}

	get firstGroup() {
		if (this.visibleColumnArray.length === 0 || this.visibleColumnArray[0].groupIndex === -1)
			return undefined

		return this.visibleColumnArray[0]
	}

	initColumnFromData() {
		let _this = this
		function getSort(name) {
			if (name in _this._sort)
				return _this._sort[name]
			return { dir: 0, index: -1 }
		}
		function getGroup(name) {
			if (name in _this._group)
				return _this._group[name]
			return { dir: 0, index: -1 }
		}

		let columns = Object.values(this._form._metas).reduce((p, meta) => {
			let sort = getSort(meta.name);
			let group = getGroup(meta.name);
			p[meta.name] = {
				name: meta.name,
				visible: (meta.sections && meta.sections.findIndex(e => e === 'list') !== -1),
				sequenceIndex: (meta.sequenceIndex === undefined ? 0 : meta.sequenceIndex),
				meta,
				groupDir: group.dir,
				groupIndex: group.index,
				sortDir: sort.dir,
				sortIndex: sort.index,
				polymorphic: false
			};
			return p;
		}, {});

		this._form._rows.forEach(row => {

			if (!row.meta || !row.meta.attributes)
				return;

			Object.values(row.meta.attributes).forEach(meta => {
				
				if (meta.name in columns)
					return;

				if (meta.type === _this?._form?.placeholderEmptyField.type)
					return;

				let sort = getSort(meta.name);
				let group = getGroup(meta.name);

				columns[meta.name] = {
					name: meta.name,
					visible: (meta.sections && meta.sections.findIndex(e => e === 'list') !== -1),
					polymorphicIndex: (meta.sequenceIndex === undefined ? 0 : meta.sequenceIndex),
					meta,
					groupDir: group.dir,
					groupIndex: group.index,
					sortDir: sort.dir,
					sortIndex: sort.index,
					polymorphic: true,
					sequenceIndex: (meta.sequenceIndex === undefined ? 0 : meta.sequenceIndex),
				};
			});
		});

		this._hasMessageColumn = false
		if (this._form?.data?.messages?.find(message => message.type === GlobalStrings.NotifySourceMessageDataType && message.resourceDataTypeName === this._form.dataType)) {
			let sort = getSort(messageColumnName)
			let group = getGroup(messageColumnName)

			columns[messageColumnName] = {
				name: messageColumnName,
				visible: true,
				meta: { name: messageColumnName, label: translate('WebSpa/List/MessageColumn/label') },
				groupDir: group.dir,
				sortDir: sort.dir,
				sortIndex: sort.index,
				polymorphic: false,
				sequenceIndex: -1,
				groupIndex: -1,
				width: 40	//Domyślny rozmiar kolumny z messagami
			}
			this._hasMessageColumn = true
		}

		Object.values(this._positions).forEach(pos => {
			let column = columns[pos.name]

			if (!column || column.name === messageColumnName)
				return

			column.sequenceIndex = pos.index
			column.visible = pos.visible
		})

		Object.values(columns)
			.sort((a, b) => {
				let r

				r = (a.groupIndex === -1) - (b.groupIndex === -1)

				if (r)
					return r

				if (a.groupIndex !== -1)
					return a.groupIndex - b.groupIndex

				return a.sequenceIndex - b.sequenceIndex
			})
			.forEach((e, index) => e.index = index)

		Object.values(this._widths).forEach(width => {
			let column = columns[width.name]

			if (!column)
				return

			column.width = width.width
		})

		this._columns = columns

		this._visibleColumnArray = Object.values(this._columns)
			.filter(c => c.visible)
			.sort((a, b) => a.index - b.index)
	}

	getColumn(name) {
		return this._columns[name]
	}

	get columnArray() {
		return this.objectToArray(this._columns);
	}

	get visibleColumnArray() {
		return this._visibleColumnArray
	}

	get sortValue() {
		let res = this.sortArray
			.map(e => (e.dir === -1 ? "-" : "+") + e.name)
			.join(',')

		if (res === '')
			return undefined
		return res
	}

	get sortArray() {
		const group = this.objectToArray(this._group);
		const sort = this.objectToArray(this._sort);

		return [...group, ...sort]
	}

	get groupArray() {
		return this.objectToArray(this._group);
	}

	getSortDir(columnName) {
		if (columnName in this._sort)
			return this._sort[columnName].dir
		return 0
	}

	getGroupDir(columnName) {
		if (columnName in this._group)
			return this._group[columnName].dir
		return 0
	}

	removeSort(columnName) {
		let dir = 1

		if (columnName in this._sort) {
			dir = this._sort[columnName].dir
			delete this._sort[columnName]

			Object.values(this._sort)
				.sort((a, b) => a.index - b.index)
				.forEach((e, index) => e.index = index)
		}

		return dir
	}

	removeGroup(columnName) {
		let dir = 1

		if (columnName in this._group) {
			dir = this._group[columnName].dir
			delete this._group[columnName]

			Object.values(this._group)
				.sort((a, b) => a.index - b.index)
				.forEach((e, index) => e.index = index)
		}

		return dir
	}

	_toggleSort(columnName, bAdd) {
		let switchSort = (c) =>
			c.dir = (!c.dir ? 1 : -c.dir)

		if (bAdd) {
			if (columnName in this._sort) {
				switchSort(this._sort[columnName])
				this._form.asyncLoad()
				return
			}

			let dir = this.removeGroup(columnName)

			this._sort = {
				...this._sort,
				[columnName]: {
					name: columnName,
					dir,
					index: Object.values(this._sort).length
				}
			}

			this._form.asyncLoad()
			return
		}

		if (columnName in this._sort) {
			if (Object.values(this._sort).length === 1)
				switchSort(this._sort[columnName])
			this._sort = { [columnName]: this._sort[columnName] }
			this._form.asyncLoad()
			return
		}

		let dir = this.removeGroup(columnName)

		this._sort = {
			[columnName]: {
				name: columnName,
				dir,
				index: 0
			}
		}
		this._form.asyncLoad()
	}

	toggleSort(columnName, bAdd) {
		this._form.currentPage = 1 // Przechodzenie do pierwszej strony po sortowaniu
		this._toggleSort(columnName, bAdd)
		this._form.saveConfiguration()
	}

	_toggleGroup(columnName, bAdd) {
		let switchGroup = (c) =>
			c.dir = (!c.dir ? 1 : -c.dir)

		if (bAdd) {
			if (columnName in this._group) {
				switchGroup(this._group[columnName])
				this._form.asyncLoad()
				return
			}

			let dir = this.removeSort(columnName)

			this._group = {
				...this._group,
				[columnName]: {
					name: columnName,
					dir,
					index: Object.values(this._group).length
				}
			}

			this._form.asyncLoad()
			return
		}

		if (columnName in this._group) {
			if (Object.values(this._group).length === 1)
				switchGroup(this._group[columnName])
			this._group = { [columnName]: this._group[columnName] }
			this._form.asyncLoad()
			return
		}

		let dir = this.removeSort(columnName)

		this._group = {
			[columnName]: {
				name: columnName,
				dir,
				index: 0
			}
		}
		this._form.asyncLoad()
	}

	updatePositions(positions){
		this._positions = {...this._positions, ...positions}
	}

	/**
	 * Transformuj obiekt, do tablicy, w której elementami są wartości obiektu. W ramach
	 * tej operacji elementy są sortowanę po właściwości 'index' o ile istnieje.
	 * @param {*} object 
	 * @returns Tablica lub pusta tablica jeśli object nie jest typu obiekt.
	 */
	objectToArray(object) {
		if (typeof object === 'object')
			return Object.values(object)
				.sort((a, b) => { 
					if(a.visible === undefined || a.visible === null)
						a.visible = false;
					if(b.visible === undefined || b.visible === null)
						b.visible = false;

					if(a.visible == b.visible)
					{
						return a.index - b.index;
					}
					return a.visible > b.visible ? -1 : 1;
				});

		return []
	}

	//Zmiana tablicy na obiekt potrzeba przy ładowaniu konfiguracji
	ArrayToObject(array) {
		if (Array.isArray(array)) {
			let obj = {}

			obj = array.reduce((p, object, index) => {
				const { name, dir } = object
				p[name] = {
					name,
					dir: dir !== undefined ? dir : 1,//-1 / 0 / 1 - malejąco / brak / rosnąco  
					index,
				}
				return p
			}, {})
			return obj
		}
	}

	toggleGroup(columnName, bAdd) {//Funkcja publiczna która pozwala dodatkowo na zapis do konfiguracji 
		this._toggleGroup(columnName, bAdd)
		this._form.saveConfiguration()
	}

	loadConfiguration(c) {
		this._group = this.ArrayToObject(c.group)
		this._sort = this.ArrayToObject(c.sort)

		if (c.widths) {
			this._widths = {}
			c.widths.forEach(width => this._widths[width.name] = width)
		}

		if (c.positions) {
			this._positions = {}
			c.positions.forEach(p => this._positions[p.name] = p)
		}
	}

	saveConfiguration(c) {
		c.group = this.objectToArray(this._group)
		c.sort = this.objectToArray(this._sort)

		const widthSettings = {...this._widths, ...this._columns};
		c.widths = this.objectToArray(widthSettings).reduce((result, column) => {
			if (column.width)
				result.push({ name: column.name, width: column.width })
			return result
		}, [])

		if (this._positions)
			c.positions = this.objectToArray(this._positions)
	}
}