import React from 'react'
import Event from './Event'

/**
 * Obsługa nazwanych zdarzeń
 *
 * @class Events
 */
export default class Events {

	/**
	 * Konstruktor
	 */
	constructor() {
		this.events = {}
	}

	/**
	 * Rejestrowanie funkcji nasłuchującej na zdarzenie o nazwie eventName
	 *
	 * @param {string} eventName Nazwa zdarzenia
	 * @param {function} listener Funkcja nasłuchująca na zdarzenie
	 */
	bind(eventName, listener) {
		if (!(eventName in this.events))
			this.events[eventName] = new Event()
		this.events[eventName].bind(listener)
	}

	/**
	 * Wyrejestrownie funkcji nasłuchującej na zdarzenie
	 *
	 * @param {string} eventName Nazwa zdarzenia
	 * @param {function} listener Funkcja nasłuchująca
	 */
	unbind(eventName, listener) {
		if (!(eventName in this.events))
			return
		this.events[eventName].unbind(listener)
	}

	/**
	 * Wysłanie zdarzenia o nazwie eventName
	 *
	 * @param {string} eventName Nazwa zdarzenia
	 * @param {...} args Argumenty wysyłane do funkcji powiadamiających
	 */
	trigger(eventName, ...args) {
		if (!(eventName in this.events))
			return
		this.events[eventName].trigger(...args)
	}

	/**
	 * Hook rejestrowania zdarzenia
	 *
	 * @param {string} eventName Nazwa zdarzenia
	 * @param {function} listener Funkcja nasłuchująca
	 */
	useListener(eventName, listener) {
		var _this = this

		React.useEffect(() => {
			_this.bind(eventName, listener)
			return () => {
				_this.unbind(eventName, listener)
			}
			// eslint-disable-next-line
		}, [])
	}
}