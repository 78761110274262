import React from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionSummary, Typography, Card, makeStyles } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { styleToColor } from 'Tools/styleToColor'
import { SectionContentWrapper } from './SectionContentWrapper'
import './Sections.scss'

//#region  Style
const useAccordionSectionStyles = makeStyles(({ palette }) => ({
	accordeonRoot: {
		margin: '5px !important',
	},
	cardRoot: {
		padding: '8px 13px',
	},
	accordionSummaryRoot: {
		height: 45,
		color: 'white',
		background: props => styleToColor(props.style, palette),
		'&.Mui-expanded': {
			height: 48,
			minHeight: 48,
		},
	},
}))
//#endregion

/**
 * Komponent wyświetlający sekcję w formie Accordeona
 * 
 * @param {Object} section Obiekt sekcji nadrzędnej - rodzic grupujący inne sekcje z tabami
 * @returns {Node}
 */
export function SectionAccordion({ section, children }) {
	const { accordionSummaryRoot, accordeonRoot, cardRoot } = useAccordionSectionStyles({ style: section.style })

	return (
		<Accordion defaultExpanded className={accordeonRoot} >
			<AccordionSummary
				className={accordionSummaryRoot}
				expandIcon={<ExpandMore style={{ fill: 'white' }} />}
			>
				<Typography>{section.label}</Typography>
			</AccordionSummary>
			<Card className={cardRoot}>
				<SectionContentWrapper>
					{children}
				</SectionContentWrapper>
			</Card>
		</Accordion>
	)
}


//#region PropTypes & DefaultProps
SectionAccordion.propTypes = {
	section: PropTypes.object.isRequired,
	children: PropTypes.node
}
//#endregion