import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Radio, RadioGroup, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import EditorFindAndReplaceSearch from './model/EditorFindAndReplaceSearch';
import './FindAndReplaceDialog.css';
import ChipBeta from './components/ChipBeta';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: '350px',
    },
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
  }),
);

export interface FindAndReplaceDialog
{
    onClose: () => void;
    onFindNext: (parameters: EditorFindAndReplaceSearch) => void;
    onReplace: (parameters: EditorFindAndReplaceSearch, replaceAll: boolean) => void;
    findAndReplaceSearch: EditorFindAndReplaceSearch;
}

export function createDefaultEditFindAndReplaceSearch(): EditorFindAndReplaceSearch {
    return {findWhat: '', replaceWith: '', findInSource: true, matchCase: false, matchWholeWord: false};
}

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function radioButtonModeValue(findInSource: boolean, replaceMode: boolean)
{
    if(replaceMode)
        return 'replace';

    return findInSource ? 'source' : 'target';
}

export default function FindAndReplaceDialog({onClose, onFindNext, onReplace, findAndReplaceSearch} : FindAndReplaceDialog) {
    const classes = useStyles();
    const [findParameters, setFindParameters] = React.useState<EditorFindAndReplaceSearch>(findAndReplaceSearch)
    const [replaceMode, setReplaceMode] = React.useState<boolean>(false);

    return (
        <div>
        <Dialog
            hideBackdrop
            disableEnforceFocus
            disableBackdropClick // Remove the backdrop click (just to be sure)
            disableScrollLock
            style={{ // This was the key point, reset the position of the dialog, so the user can interact with other elements
                top: '0px', // Position however you like
                left: '15%',
                height: 'fit-content',  // Ensures that the dialog is 
                width: 'fit-content'
            }}
            //fullWidth={true}
            //maxWidth={"xs"}
            open={true}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            Find and Replace <ChipBeta />
            </DialogTitle>
            <DialogContent>
            <div className={classes.form} >
                <FormControl className={classes.formControl} margin="dense" size="small">
                    <FormGroup>
                    <TextField
                        id="find-and-replace-what"
                        label="Find what"
                        type="text"
                        size="small"
                        value={findParameters.findWhat}
                        onChange={(e) => {
                            console.log(e?.target?.value);
                            const newValue = e.target.value;
                            setFindParameters(prevState => {
                                return { ...prevState, findWhat: newValue}
                            });
                        }}  
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {replaceMode &&
                        <TextField
                            id="find-and-replace-what-replace"
                            label="Replace with"
                            type="text"
                            size="small"
                            style={{marginTop: '10px'}}
                            value={findParameters.replaceWith}
                            onChange={(e) => {
                                console.log(e?.target?.value);
                                const newValue = e.target.value;
                                setFindParameters(prevState => {
                                    return { ...prevState, replaceWith: newValue}
                                });
                            }}  
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    }
                    
                    </FormGroup>
                </FormControl>
                <FormControl className={classes.formControl} margin="dense" size="small">
                    <RadioGroup row aria-label="position" name="position" 
                        value={radioButtonModeValue(findParameters.findInSource, replaceMode)}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            let newFindInSourceValue = false;
                            let newReplaceModeValue = false;
                            switch(newValue)
                            {
                                case 'source':
                                case 'target':
                                    newFindInSourceValue = newValue === 'source';
                                    newReplaceModeValue = false;
                                    break;
                                case 'replace':
                                    newFindInSourceValue = false;
                                    newReplaceModeValue = true;
                            }
                            
                            setFindParameters(prevState => {
                                return { ...prevState, findInSource: newFindInSourceValue}
                            });
                            setReplaceMode(newReplaceModeValue);
                        }}>
                            <FormControlLabel className='denseInput' value="source" control={<Radio color="primary" />} label="In source" />
                            <FormControlLabel className='denseInput' value="target" control={<Radio color="primary" />} label="In target" />
                            <FormControlLabel className='denseInput' value="replace" control={<Radio color="primary" />} label="Replace" />
                    </RadioGroup>
                </FormControl>
                <FormControl className={classes.formControl} margin="dense" size="small">
                    <FormGroup>
                        <FormControlLabel
                                className='denseInput'
                                control={<Checkbox name={"match-case"} />}
                                checked={findParameters.matchCase}
                                onChange={(e, checked) => {
                                    setFindParameters(prevState => {
                                        return { ...prevState, matchCase: checked}
                                    });
                                }}  
                                label={"Match case"}
                            />
                        <FormControlLabel
                            className='denseInput'
                            control={<Checkbox name={"match-whole-word"} />}
                            checked={findParameters.matchWholeWord}
                            onChange={(e, checked) => {
                                setFindParameters(prevState => {
                                    return { ...prevState, matchWholeWord: checked}
                                });
                            }}  
                            label={"Match whole word"}
                        />
                    </FormGroup>
                </FormControl>
                    </div>
            </DialogContent>
            <DialogActions>
            <Button onClick={(e) =>{ onFindNext(findParameters); e.preventDefault();}} color="primary">
                Find Next
            </Button>
            {replaceMode &&
            <>
                <Button onClick={(e) =>{ onReplace(findParameters, false); e.preventDefault();}} color="primary">
                    Replace
                </Button>
                <Button onClick={(e) =>{ onReplace(findParameters, true); e.preventDefault();}} color="primary">
                    Replace All
                </Button>
            </>
            }
            <Button autoFocus onClick={onClose} color="primary">
                Cancel
            </Button>
            </DialogActions>
        </Dialog>
        </div>
    );
}