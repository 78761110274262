import React from 'react'
import DV from '../../../components/dataView/DataView'
import HomePartOuter from './HomePartOuter'

export default function HomePart({ children }) {
	return (
		<DV.Part outerContent={<HomePartOuter />}>
			{children}
		</DV.Part>
	)
}
