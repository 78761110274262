import React from 'react'
import { useTranslate } from 'locale/Locale'
import FilterList from './FilterList'
import { Dialog } from '@material-ui/core'
import { StyledDialogTitle } from './StyledDialogModal'

const dialogTitleId = "dialog-grid-filters-title";
const dialogContentId = "dialog-grid-filters-body";

export default function FilterModal({ isOpen, close }) {
	const translate = useTranslate('WebSpa/FilterModal')

	return (
		<Dialog open={isOpen} fullWidth maxWidth="lg" onClose={() => close()}
			scroll="paper" aria-labelledby={dialogTitleId} aria-describedby={dialogContentId}>

			<StyledDialogTitle disableTypography id={dialogTitleId}>
				{translate('title')}
			</StyledDialogTitle>
			<FilterList close={close} dialogContentId={dialogContentId}/>
		</Dialog>
	)
}