import React from 'react'

function _Brace(x1, y1, x2, y2) {
	const vx = x2 - x1, vx2 = vx / 2, vx4 = vx / 4
	const vy = y2 - y1, vy2 = vy / 2, vy4 = vy / 4

	return `c0 0 ${vx4} 0 ${vx2} ${vy2} c0 0 ${vx4} ${vy2} ${vx2} ${vy2}`
}


export function Brace({ xb, xe, yb1, yb2, ye1, ye2 }) {
	let width = Math.max(xb, xe) - Math.min(xb, xe) + 1 + 2
	let height = Math.max(yb1, yb2, ye1, ye2) - Math.min(yb1, yb2, ye1, ye2) + 1 + 2
	const x = Math.min(xb, xe)
	const y = Math.min(yb1, yb2, ye1, ye2)
	const d = Math.abs(yb1 - yb2) > Math.abs(ye1 - ye2) ? 1 : -1


	xb -= x
	xe -= x
	yb1 -= y
	yb2 -= y
	ye1 -= y
	ye2 -= y

	if (yb1 === yb2 && ye1 === ye2)
		return (
			<svg className='absolute' width={width} height={height} style={{ left: x, top: y }}>
				<path d={`M${xe} ${ye1} ${_Brace(xe, ye1, xb, yb1)}`} stroke= 'var(--o-mainDarken700)' strokeWidth='2px' fill='none' />
			</svg>
		)

	return (
		<svg className='absolute' width={width} height={height} style={{ left: x, top: y }}>
			<path d={`M${xe} ${ye1} ${_Brace(xe, ye1, xb, yb1)} ${_Brace(xb, yb1, xe, ye2)}`} stroke = 'var(--o-mainDarken700)' strokeWidth='2px' fill='#ccdffc' />
		</svg>
	)
}