import { useDispatch } from 'contents/cat/store/global'

const useLanguageDocument = () => {
    const globalDispatch = useDispatch();

    return {
        notifySegmentSelected: (languageDocumentId, segmentNumber) => {
            globalDispatch({
                type: 'SEGMENT_SELECTED_NOTIFICATION',
                'languageDocumentId': languageDocumentId,
                'segmentNumber': segmentNumber
            });
        }
    }
}

export default useLanguageDocument;