import styled from 'styled-components'
import { MixinVertical } from 'components/topology/Topology'
import posed from 'react-pose'
import { customElements } from 'cssStatic'

///TODO style
const PosedContent = posed.div({
	closed: {
		opacity: 0,
		height: '0',

		transition: {
			y: { type: 'spring', stiffness: 600, damping: 25 },
			duration: 300
		}
	},
	open: {
		opacity: 1,
		height: '100%',
		transition: {
			y: { type: 'spring', stiffness: 900, damping: 25 },
			duration: 350
		}
	}
})

export const AccordionHead = customElements('o-accordion-head', [
	'grid-hor', 'grid-acx-space-between', 'grid-aiy-center', 'f-16', 'f-primary',
	'text-grey700', 'grey100',
	{
		'font-weight': 'bold',
		'padding': '5px 8px 5px 15px',
		'border-bottom': '1px solid',
		'border-top-right-radius': '5px',
		'border-top-left-radius': '5px',
		'box-shadow': '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
		'cursor': 'pointer'
	}
])

///TODO style
export const AccordionContent = styled(PosedContent)`
   box-shadow: 0 1px 8px -2px rgba(0, 0, 0, 0.1);
   ${({ isOpen }) => isOpen && MixinVertical({ g: 'md' })};
   ${({ isOpen }) => !isOpen && `display: none`};
   `

export const CardBorder = customElements('o-accordion-card-border', ['p-15', 'white',
	{ 'box-shadow': '0 1px 8px -2px rgba(0, 0, 0, 0.1)' }
])