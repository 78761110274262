import React from 'react'
import PropTypes from 'prop-types';
import MaterialTable, {MTableBodyRow} from 'material-table';
import {TooltipNoWrapping} from './components/Tooltip';
import { dateTimeStringToFormatedString } from './model/Utils';

function metaTypeToTableType(metaType) {
	if (metaType) {
		switch (metaType.toLowerCase()) {
			case 'timestamp':
				return 'datetime'
			case 'number':
				return 'numeric'
			case 'bool':
				return 'boolean'
			case 'executionmessages':
				return 'string';
			default:
				return metaType.toLowerCase()
        }
	}

	return metaType
}

export default function MaterialTableMetaDriven({ meta, data, options, isLoading, title, ...other }) {
	let columns = [];
	let rowTitleAttributeName = undefined;
	let rows = [];
	let detailPanel = [];

	if (meta) {
		columns = meta.attributes
			.filter(attribute => {
				return attribute.sections && (attribute.sections.includes('list') || attribute.sections.includes('listDetails'))
			})
			.map(attribute => {
				let column = {
					title: attribute.label,
					field: attribute.name,
					hidden: attribute.sections && attribute.sections.includes('listDetails'),
					type: metaTypeToTableType(attribute.type)
				}

				if (attribute.width) {
					column.cellStyle = { width: attribute.width }
				}

				return column
			});


		const rowTitleAttribute = meta.attributes.find(attribute => {
			return attribute.sections && attribute.sections.includes('rowTitle');
		})
		if(rowTitleAttribute)
			rowTitleAttributeName = rowTitleAttribute.name;
	}

	if (data) {
		rows = data.map((resourceObject) => {
			let row = {};
			meta.attributes.forEach((attribute) => {
				let value = resourceObject.attributes[attribute.name];
				if (metaTypeToTableType(attribute.type) === 'datetime') {
					if (value) {
						value = dateTimeStringToFormatedString(value);
                    }
					
                }
				row[attribute.name] = value;
			});

			return row;
		});
    }

	let defaultOptions = {
		paging: false,
		showTitle: false,
		emptyRowsWhenPaging: false,
		padding: 'dense',
		filtering: false,
		selection: false,
		search: false,
		maxBodyHeight: '250px',
		...options
	}

	return (
		<MaterialTable
			columns={columns}
			data={rows}
			title={title}
			options={defaultOptions}
			isLoading={isLoading}
			components={{
				Row: props => (
					<TooltipNoWrapping title={props.data[rowTitleAttributeName]} >
						<MTableBodyRow {...props} />
					</TooltipNoWrapping>
					
				)
			}}
			{...other}
		/>
	)
}

MaterialTableMetaDriven.propTypes = {
	// Ten obiekt ma inny shape, bo sa jeszcze atrybuty po drodze, do poprawy
	// meta: PropTypes.objectOf(
	// 	PropTypes.shape({
	// 		label: PropTypes.string.isRequired,
	// 		name: PropTypes.string.isRequired
	// 	})
	// ),
	data: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.any.isRequired,
			type: PropTypes.string.isRequired,
			attributes: PropTypes.object
		})
	)
}

MaterialTableMetaDriven.defaultProps = {
	isLoading: false,
	meta: {
		attributes: {}
	},
	data: [],
	title: null
}