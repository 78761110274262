class MetaAttribute {
    constructor(attributeObject) {
        if (!attributeObject) {
            throw "Parameter 'attributeObject' of MetaAttribute.ctor must be valid object";
        }

        this._name = attributeObject.name || '__unknown__';
        this._label = attributeObject.label || '__unknown__';
        this._hint = attributeObject.hint || '__unknown__';
        this._type = attributeObject.type || '__unknown__';
        this._sections = attributeObject.sections || [];

        this._type = this._type.toUpperCase();
    }

    get name() {
        return this._name;
    }

    get label() {
        return this._label;
    }

    get hint() {
        return this._hint;
    }

    get type() {
        return this._type;
    }

    get sections() {
        return this._sections;
    }

    get hasListSection() {
        return this._sections.contains('list');
    }
}

class Metadata {
    constructor(metadataObject) {
        this._meta = metadataObject;
        this._attributes = null;
    }

    get attributes() {
        if (!this._attributes) {

            this._attributes = [];

            if (this._meta.attributes) {

                for (const attributeName in this._meta.attributes) {
                    this._attributes.push(new MetaAttribute(this._meta.attributes[attributeName]));
                }
            }
        }

        return this._attributes;
    }
}

class ResourceObject {
    constructor(resourceObject) {
        this._resourceObject = resourceObject || {};
    }

    get id() {
        return this._resourceObject.id
    }

    get type() {
        return this._resourceObject.type || null;
    }

    get attributes() {
        return this._resourceObject.attributes || {};
    }
}

const defaultPagesCount = 1
const defaultCurrentPage = 1

export default class ResourceDocument {
    constructor(resourceDocumentObject) {
        this._document = resourceDocumentObject;
        this._meta = null;
        this._data = null;
    }

    get meta() {
        if (!this._meta) {
            if (!this._document || !this._document.meta) {
                this._meta = new Metadata({ attributes: {} });
            }
            else {
                this._meta = new Metadata(this._document.meta);
            }
        }

        return this._meta;
    }

    get data() {
        if (!this._data) {
            this._data = [];

            if (this._document && this._document.data) {
                for (const entity of this._document.data) {
                    this._data.push(new ResourceObject(entity));
                }
            }
        }

        return this._data;
    }

    get pagesCount() {
        return this._document && this._document.pages 
            ? this._document.pages 
            : defaultPagesCount
    }

    get currentPage() {
        return this._document && this._document.currentPage
            ? this._document.currentPage
            : defaultCurrentPage
    }
}