import FormMetaArrayObjects from './FormMetaArrayObjects'
import RowManager from './RowManager'
import ColumnManager from './ColumnManager'
import { GroupManager } from './GroupManager'
import { TreeManager } from './TreeManager'
import { GanttManager } from './GanttManager'
import { FilterManager } from './FilterManager'
import { appCtrl } from 'AppCtrl'
import { isArray, isEmpty } from 'lodash'
import { merge } from './mergeData'
import { GlobalStrings } from 'GlobalStrings'
import { FORM_EDIT_MODES, UPA_UPLOAD_LINK_NAME, FormMetaObject } from './FormMetaObject'


/**
 * Klasa formy obsługującej listę rekordów
 */
export default class FormMetaArray extends FormMetaArrayObjects {
	constructor(params) {
		super(params)
		this._pages = 1
		this._currentPage = 1
		this._limit = 15
		this._includeSummary = false;
		this._metas = {}
		this._rows = []
		this._aggregateOption = undefined
		this._preventRenderErrorModal = false

		if ('limit' in params)
			this._limit = params.limit;

		if('includeSummary' in params)
			this._includeSummary = params.includeSummary;

		this._splitter = {}

		this._configurationPath = params.configurationPath

		this.rowManager = new RowManager(this)
		this.columnManager = new ColumnManager(this)

		if (params.group)
			this.columnManager.setGroup(params.group)
		if (params.sort)
			this.columnManager.setSort(params.sort)

		this._dataType = params.dataType
		this._useLinkName = params.useLinkName
		this._useCreateLinkName = params.useCreateLinkName
		this.rowManager.enableMultiMarked = params.enableMultiMarked
		this.rowManager.inlineEdition = params.inlineEdition

		this.ganttManager = params.isGantt ? new GanttManager(this) : {}
		this.groupManager = new GroupManager(this)
		this.treeManager = new TreeManager(this)
		this.filterManager = new FilterManager(this)

		this._onRowUpdate = params.onRowUpdate
		this._onRowCreate = params.onRowCreate
		this._onRowDelete = params.onRowDelete
		this._onCanRowUpdate = params.onCanRowUpdate
		this._onCanRowCreate = params.onCanRowCreate
		this._onCanRowDelete = params.onCanRowDelete
		this._onSaveEditRows = params.onSaveEditRows
		/**
		 * Parametr mówiący o tym że dana forma korzysta z linków przekazywanych przez BE
		 */
		this._isFormUsingServices = params.isFormUsingServices 

		this.loadConfiguration()
	}

	get isMetaArray() { return true }

	/**
	 * Zwraca obiekt pustego pola - wykorzystywany w przypadku gdy z BE przyjdzie null jako wartość atrybutu
	 * W razie potrzeby można dołożyć inne wartości
	 */
	get placeholderEmptyField(){
		return {
			type: 'Empty'
		}
	}

	initData(v) {
		this.data = v
		this.trigger('data')
	}
	get data() { return this._data }
	set data(v) {
		this._data = v


		if (!v) {
			this.setEmptyData()
			return
		}

		super.copyUIAttribiuteInfoToUIMetaAttribiute()

		if (this._data?.data)
			this._data.data.forEach(row => {
				if (row?.ui?.attributes) {	
					for (let key in row?.ui.attributes) {
						const attribiuteMeta = row?.meta?.attributes[key];
						if(attribiuteMeta !== null) {
							row.meta.attributes[key] = { ...attribiuteMeta, ...row?.ui?.attributes[key] };
						}
					}
				}
				
				for(let key in row?.meta?.attributes){
					if(row.meta.attributes[key] === null) {
						row.meta.attributes[key] = this.placeholderEmptyField
					}
				}		
			})

		this._rows = v.data
		this._metas = v.meta.attributes
		this.isChanged = false
		this.columnManager.initColumnFromData()
		this.rowManager.initVisibleRowsFromData()
	}

	get isOk() {
		for (const childForm of this.childForms.values())
		{
			if(!childForm.isOk)
				return false
		}
		
		return true
	}

	/*
	 Aktualizuje dane i metadane formy "create", tak aby np. po zmianie uprawnień i odświeżeniu formy zmiany były widoczne 
	 bez zamykania widoku.
	*/
	updateChildCreateFormData(){
		if(this.childForms.size < 2)
			return;

		// Szukamy drugiej z kolei formy gdzie tryb edycji to 'create'. Drugiej z kolei, ponieważ zostają śmieci lub po prostu usuwanie 
		// podrzędnych form nie działa. Zamknięcie widoku "create" i wejście w nie ponownie skutkuje tym, że 
		// childForms zawiera dwie (3 licząc forme, która jest wyświetlana na liście) formy "create". 
		let createForm = null
		let createFormsCount = 0

		for (const childForm of this.childForms.values())
		{
			if(childForm.editMode !== 'create')
				continue

			createFormsCount++
			if(createFormsCount === 2)
			{
				createForm = childForm
				break
			}
		}

		if(createForm && this.rowManager.rowData?.data && this.rowManager.rowData?.data.length > 0)
			createForm.data = this.rowManager.getDataFromRow(this.rowManager.rowData.data[0], this._data.ui)
		else
			console.error("Could not update 'create' form data because could not found proper form")
	}

	setEmptyData() {
		this.data = {
			meta: { attributes: {} },
			data: [],
			links: {}
		}
	}

	merge(data) {
		merge(this._data, data, false)
	}

	get isFormUsingServices() { return this._isFormUsingServices } //TODO Opisac

	// /**
	// * Metoda pobierająca wszystkie przyciski o właściwości 'uiAction' równej 'ClientAction'.
	// * Metoda pobiera przyciski tylko z głównego obiektu meta!!
	// * @returns {Array}
	// */
	// getClientActionButtons() {
	// 	const buttonFromMeta = this._data.meta?.buttons

	// 	if (isEmpty(buttonFromMeta))
	// 		return []

	// 	return Object.values(buttonFromMeta)
	// 		.filter(button => button.uiAction === 'ClientAction')
	// 		.sort((a, b) => a.sequenceIndex > b.sequenceIndex)
	// }

	get serviceRead() {
		if (this._parentForm && this._parentForm.getServiceRelativeRead)
			return this._parentForm.getServiceRelativeRead(this._useLinkName ? this._useLinkName : this._dataType)
		return undefined
	}

	get serviceReadOne() {
		if (this._parentForm && this._parentForm.getServiceRelativeReadOne)
			return this._parentForm.getServiceRelativeReadOne(this._useLinkName ? this._useLinkName : this._dataType)
		return undefined
	}

	get serviceUploadUPAFileHRef() {
		if (this.dataType && this._data.links) {
			const link = this.data.links[UPA_UPLOAD_LINK_NAME];
			if(link) {
				return link.href;
			}
		}
		return undefined;
	}

	get serviceCreate() {
		if (this._dataType && this._data.links)
		{
			if(this._useCreateLinkName){
				return this._data.links[`create${this._useCreateLinkName}`]
			}
			return this._data.links[`create${this._useLinkName ? this._useLinkName : this._dataType}`]
		}

		if (this._parentForm && this._parentForm.getServiceRelativeCreate) {
			const parentCreate = this._parentForm.getServiceRelativeCreate(`create${this._useLinkName ? this._useLinkName : this._dataType}`);
			return parentCreate;
		}
			
		return undefined
	}
	get serviceCreateHRef() { return this.serviceCreate?.href }

	get serviceUpdate() { return this._data?.links?.['update'] }
	get serviceUpdateHRef() { return this.serviceUpdate?.href }

	getServiceCreateRead(dataType) {
		if (!dataType || !this._data.links)
			return
		return this._data.links[`read${this._useLinkName ? this._useLinkName : this._dataType}`]
	}

	async handleSaveEditRows() {
		const f = this._onSaveEditRows

		if (!f)
			return false
		return await f(this)
	}

	asyncSaveEditRows(after) {
		if (this.changedChildForm) {
			this.asyncSubmit('saveEditRows', { after })
			return
		}
		after()
	}

	/**
	 * Metoda wołana po zmianie wartości pola w formularzu podrzędnym w przypadku kiedy pole jest polem multiedycyjnym
	 * @param {FormMetaObject} fieldChildForm Formularz podrzędny, do którego należy pole, które zmieniło wartość
	 * @param {string} fieldName Nazwa pola
	 * @param {any} newValue Nowa wartość pola
	 * @returns 
	 */
	handleMultiEditionFieldValueChange(fieldChildForm, fieldName, newValue) 
	{
		if (!this.rowManager.isMarked(fieldChildForm.id))
			return
		
		this.childForms.forEach(childForm => {
			if (childForm === fieldChildForm || !this.rowManager.isMarked(childForm.id))
				return
			childForm.handleChange(fieldName, newValue, true)
		})
	}

	/**
	 * Metoda wołana po zmianie wartości pola w formularzu podrzędnym. W tym miejscu możemy reagować na 
	 * zmiany pola
	 * @param {*} fieldChildForm 
	 * @param {*} fieldName 
	 * @param {*} newValue 
	 */
	onFieldValueChange(fieldChildForm, fieldName, newValue)
	{
	}

	get dataType() {
		if (this._data && this._data.meta)
			return this._data.meta.dataType
		return undefined
	}

	get aggregateOption() { return this._aggregateOption }
	set aggregateOption(v) { this._aggregateOption = v }

	get errorFromService() { return this._errorFromService }
	set errorFromService(v) { this._errorFromService = v }

	get errorFromBackEnd() { return this._errorFromBackEnd }
	set errorFromBackEnd(v) { this._errorFromBackEnd = v }

	get preventRenderErrorModal() { return this._preventRenderErrorModal }
	set preventRenderErrorModal(v) { this._preventRenderErrorModal = v }

	get pages() { return this._pages }
	set pages(v) { this._pages = v ? v : 1 }
	get currentPage() { return this._currentPage }
	set currentPage(v) {
		this._currentPage = v ? v : 1
		this.saveConfiguration()
	}
	get limit() { return this._limit }
	set limit(v) {
		this._limit = v
		this.saveConfiguration()
	}

	get includeSummary() 
	{
		return this._includeSummary;
	}

	set includeSummary(value)
	{
		this._includeSummary = value ? value : false;
		this.saveConfiguration();
	}

	get metas() { return this._metas }
	get rows() { return this._rows }
	getMeta(name, index) {
		if (name in this._metas)
			return this._metas[name]
		if (index !== undefined &&
			this._data.data &&
			this._data.data.length > index &&
			this._data.data[index].meta &&
			this._data.data[index].meta.attributes)
			return this._data.data[index].meta.attributes[name]
	}

	getRowValue(index, columnName) {
		return this._rows[index].attributes[columnName]
	}

	/**
	 * @deprecated Metoda dodaje sekcje "polimorphic" do wszystkich attrybutów
	 */
	addPolymorphicSection(res) {
		if (!res.data)
			return

		for (var i in res.data) {
			var d = res.data[i]

			if (!d.meta || !d.meta.attributes)
				continue

			var a = d.meta.attributes

			for (var j in a) {
				var m = a[j]

				if(m === null) 
					continue

				if (!m.sections)
					m.sections = []

				m.sections.push('polymorphic')
			}
		}
	}

	handleMoveRow(idRow, offset) {
		this._moveRowUpOrDown(idRow, offset)
		this.trigger('data')
	}

	/**
	 * Przesuń wiersz w górę lub w dół dla podanego wiersza o podanym identyfikatorze.
	 * Metoda nie powoduje odświeżenia danych.
	 * @param {*} idRow 
	 * @param {*} offset 
	 * @returns 
	 */
	_moveRowUpOrDown(idRow, offset) {
		const index = this.rowManager.getRowIndexFromId(idRow)
		const newIndex = index + offset
		const arr = this.rows

		if (newIndex < 0 || newIndex >= arr.length)
			return

		const element = arr.splice(index, 1)
		arr.splice(newIndex, 0, ...element)
		this.rowManager.initVisibleRowsFromData()
	}

	get splitter() { return this._splitter }
	set splitter(v) { this._splitter = v }

	/**
	 * Funkcja ma za zadanie zwracać jeden konkretny link  zawierający podany jako parametr 'linkPrefix' ciąg znaków
	 * @param {String} serchingText  - Wzorzec według którego poszukujemy linku
	 * @param {String} fieldName  - Nazwa pola z BE
	 */
	getSpecifiedLink(linkPrefix, fieldName) {
		const pattern = linkPrefix.concat(fieldName).toLocaleLowerCase()
		let findedLink = undefined

		if (!isEmpty(this._data.links)) {
			const keys = Object.keys(this._data.links)
			for (let key of keys) {
				if (key.toLocaleLowerCase() === pattern) {
					findedLink = this._data.links[key]
				}
			}
		}

		return findedLink
	}

	


	/**
	 * Funkcja która zwraca tablicę z nazwami pól typu 'File' w danym widoku
	 * @returns {Array}
	*/
	get allFileFieldsNamesArray() {
		if (isEmpty(this.metas)) {
			return []
		}

		return Object.values(this.metas).filter(meta => meta.type === 'File').map(meta => meta.name)
	}

	/**
	 * Metoda pozwalająca na zwrócenie tablicy ze wszystkimi plikami w danej tabeli
	 * @returns {Array}
	 */
	get allFiles() {
		const allFilesObjectsArray = []

		if (isEmpty(this.allFileFieldsNamesArray) || isEmpty(this.rows)) {
			return allFilesObjectsArray
		}

		for (const row of this.rows) {
			for (const fieldName of this.allFileFieldsNamesArray) {

				if (!(fieldName in row.attributes)) {
					continue
				}

				const filesFromAttributes = row.attributes[fieldName]

				if (isArray(filesFromAttributes)) {
					for (const singleFileFromAttributes of filesFromAttributes) {
						allFilesObjectsArray.push({ ...singleFileFromAttributes, fieldName })
					}
				}
				else {
					allFilesObjectsArray.push({ ...filesFromAttributes, fieldName })
				}

			}
		}

		return allFilesObjectsArray
	}

	/**
	 * Pobierz listę plików z zaznaczonych wierszy
	 */
	get allSelectedFiles() {
		const selectedRows = this.rowManager.marksOrSelectedDatas;
		const fileFields = this.allFileFieldsNamesArray;

		return selectedRows.data.reduce((res, row) => {
			fileFields.forEach(fieldName => {
				if (row.attributes?.[fieldName])
					res.push({ ...row.attributes[fieldName], fieldName });
			});
			return res;
		}, []);
	}

	/**
	 * Metoda pozwalająca na zwrócenie tablicy ze wszystkimi plikami z obecnie zaznaczonego wiersza
	 * @returns {Array}
	 */
	get allFilesFromCurrentSelectedRow() {
		const filesFromCurrentSelectedRow = []
		const currentSelectedRowAttributes = this.rowManager.getCurrentSelectedRowAttributes()

		if (isEmpty(this.allFileFieldsNamesArray) || isEmpty(currentSelectedRowAttributes)) {
			return filesFromCurrentSelectedRow
		}

		for (const fieldName of this.allFileFieldsNamesArray) {

			if (!(fieldName in currentSelectedRowAttributes)) {
				continue
			}

			const filesFromAttributes = currentSelectedRowAttributes[fieldName]

			if (isArray(filesFromAttributes)) {
				for (const singleFileFromAttributes of filesFromAttributes) {
					filesFromCurrentSelectedRow.push({ ...singleFileFromAttributes, fieldName })
				}
			}
			else {
				filesFromCurrentSelectedRow.push({ ...filesFromAttributes, fieldName })
			}
		}

		return filesFromCurrentSelectedRow
	}
	
	/**
	 * Ustaw wiadomości karteczkowe. Metodę tą należy wywoływać bezpośrednio w FormRestService.
	 * Wynika to z tego, że wiadomości karteczkowe przechowywane są w AppMessages zamiast bezpośrednio w obiekcie FormMetaArray.
	 * Nie należy ustawiać tych danych bezpośrednio w metodach set, ponieważ są one wielokrotnie wywoływane obniża wydajność aplikacji.
	 * Docelowo aplikacja powinna przechowywać wszystkie swoje dane w repozytorium opartym na stanie react, np z użyciem biblioteki Redux.
	 * @param result Wynik operacji z BE.
	 */
	setObjectInfoMessages(messages) {
		this.data.objectInfoMessages = messages?.filter(m => m.type === GlobalStrings.NotifySourceMessageDataType);
	}
	
	/**
	 * Pobierz i jednocześnie usuń listę powiadomien karteczkowych z danego obiektu, 
	 * dane odrazu czyścimy, ponieważ te dane trzymamy w repozytorium AppMessages, dotyczy to wyłącznie powiadomień karteczkowych ('ikonowych')
	 * @returns Lista powiadomień ikonowych
	 */
	popObjectInfoMessages() {
		const objectInfoMessages = this?.data?.objectInfoMessages;
		if(objectInfoMessages){
			this.data.objectInfoMessages = [];
		}
		return objectInfoMessages;
	}

	loadConfiguration() {
		if (!this._configurationPath || !appCtrl.configuration)
			return

		let c = appCtrl.configuration.getValue(this._configurationPath)
		if (!c)
			return

		if (c.limit)//różne od undefined
			this._limit = c.limit

		if(c.includeSummary)
			this._includeSummary = c.includeSummary;

		if (c.currentPage)
			this._currentPage = c.currentPage

		if (c.splitter)
			this._splitter = c.splitter

		if (this.ganttManager.isGantt)
			this.ganttManager.loadConfiguration(c)

		this.columnManager.loadConfiguration(c)
		this.filterManager.loadConfiguration(c)

	}

	saveConfiguration() {
		if (!this._configurationPath || !appCtrl.configuration)
			return

		let c = {
			limit: this._limit,
			includeSummary: this._includeSummary,
			currentPage: this._currentPage,
			splitter: this._splitter
		}

		if (this.ganttManager.isGantt)
			this.ganttManager.saveConfiguration(c)

		this.columnManager.saveConfiguration(c)
		this.filterManager.saveConfiguration(c)

		appCtrl.configuration.setValue(this._configurationPath, c)
	}
}