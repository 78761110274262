import React, { useCallback, useEffect, useState } from 'react'
import FormMetaArray from '../form/FormMetaArray'
import FormMetaObject from '../form/FormMetaObject'
import LoginLayout from './LoginLayout'
import useForm from '../../hooks/useForm'
import {Checkbox}  from '../checkbox/Checkbox';
import { appCtrl, useAppCtrl } from 'AppCtrl'
import { Redirect, useHistory } from 'react-router-dom'
import { useField } from 'components/form/Form'
import { ButtonSubmit } from '../list/components'
import { useTranslate } from 'locale/Locale'
import GlobalError from '../fields/GlobalError/GlobalError'
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core'
import getFormRestService from 'services/getFormRestService'
import { isString } from 'lodash'
import { useSnackbar } from 'notistack'
import { saveAs } from 'file-saver'
import Button from '../list/components/buttons/Button'
import { StyledButtonTooltip } from '../list/components/buttons/Button.styled'
import { GetAppRounded } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid';
import Logger from 'Tools/Logging/Logger';
import { ErrorModalFormEx } from 'components/ErrorModalEx'

const ButtonDownloadAgreement = ({fileUrl, fileName}) => {
	const BUTTON_NAME = 'ButtonDownloadFileAgreement'
   	const { enqueueSnackbar } = useSnackbar()
   	const singleFileTranslate = useTranslate('WebSpa/Snackbar/ButtonDownloadSingleFile')
	const translate = useTranslate('WebSpa/Snackbar/ButtonDownloadFile')

	const handleOnClick = async () => {
		let fileBLOB = null
		try {
			fileBLOB = await getFormRestService(fileUrl).getFile('')
		}
		catch (error) {
			console.warn(`${translate('error')} ${fileName} | status: ${error.status} | url: ${error.url}`)
			enqueueSnackbar(`${translate('error')} ${fileName}`, { variant: 'error', preventDuplicate: true })
		}

		if(fileBLOB){
			saveAs(fileBLOB, fileName)
		}
		else {
			enqueueSnackbar(singleFileTranslate('fileNameError'), { variant: 'error', preventDuplicate: true })
		}
	}

   return(
		<Button
			type='icon'
			id={`${BUTTON_NAME}_${fileName}`}
			style={{ marginLeft: '32px', padding: '3px 10px', minWidth: 150 }}
			onClick={() => handleOnClick()}
			icon={
            <StyledButtonTooltip title={singleFileTranslate('tooltip')} arrow>
               <React.Fragment>
					<GetAppRounded style={{ fontSize: 28 }} />
					<span>{fileName}</span>
				</React.Fragment>
            </StyledButtonTooltip>
         }
		/>
   )
}

const TermOfServiceItemView = () => {
	const form = useForm('data')
	const confirmedField = useField('Confirmed')
	const confirmedLabelField = useField('Description')
	const fileIdentField = useField('FileIdent')

	const onChange = useCallback(() => {
		confirmedField.handleChange(!confirmedField.value)
		confirmedField.form.trigger('data')
		confirmedField.form.parentForm.trigger('data')
	}, [confirmedField])

	const fileUrl = form.serviceDownloadFile(fileIdentField.name, fileIdentField.value.id)

	return (
		<React.Fragment>
				<Checkbox 
					name={confirmedField.name}
					value={confirmedField.value}
					required={confirmedField.required}
					error={confirmedField.error}
					disabled={confirmedField.disabled}
					label={confirmedLabelField.value}
					dataType={confirmedField.dataType}
					hasLabel={true}
					onChange={onChange}
					rootClassName={"checkbox-with-label"}
				/>
				<ButtonDownloadAgreement fileUrl={fileUrl} fileName={fileIdentField.value.value} />
		</React.Fragment>
	)
}

/**
 * Wrapper dla TermOfServiceItemView, który osadza widok w formularzu dla pojedynczego obiektu
 */
const TermsOfServiceItemForm = ({row, rowIndex}) => {
	const form = useForm('data')
	return (
		<FormMetaObject
			isFormUsingServices={form.isFormUsingServices}
			key={rowIndex}
			editMode={'edit'}
			data={form.rowManager.getDataFromId(row.id)}
			onSubmit={async (form, submitType) => {
			}}
		>
			<TermOfServiceItemView />
		</FormMetaObject>
	)
}

/**
 * Komponent odpowiada za wyświetlenie kolekcji formularzy, gdzie formularz jest pojedynczą
 * zgoda użytkownika
 */
const TermsOfServiceItems = (props) => {
	const buttonsTranslate = useTranslate('WebSpa/Buttons')
	const termsOfServiceTranslate = useTranslate('WebSpa/TermsOfServicePage')
	const hintsTranslate = useTranslate('WebSpa/MainPage/Hints')
	const form = useForm('data')
	const history = useHistory()
	const [disableSubmitButton, setDisableSubmitButton] = useState(true);

	const termOfServiceItems = form.rowManager.visibleRows.map((row, rowIndex) => {
		return (
			<TermsOfServiceItemForm row={row} rowIndex={rowIndex} key={rowIndex} />
		)
	});

	useEffect(() => {
		setDisableSubmitButton(!form.isOk)
	}, [form.isOk, form.childForms.size])

	return (
		<LoginLayout>
			<Typography variant="h3" component="h3">
				{termsOfServiceTranslate('title')}
			</Typography>
			<br/>
			
			<FormControl >
				{termOfServiceItems}
				<br/>
				<GlobalError />
			</FormControl>

			<br/>
			<Grid container>
				<Grid item xs={12}>
					<Grid container justifyContent="center" >
						<Grid item style={{margin: '0px 15px'}}>
							<ButtonSubmit
								type="submit"
								id="ButtonTermsOfServiceApprove"
								disabled={disableSubmitButton}
								isList={true}
								fullWidth = {false}
								alwaysVisibleCondition
								style={{ backgroundColor: `var(--o-mainDarken700)` }}
								content={buttonsTranslate('submitButton')}
							/>
						</Grid>
						<Grid item style={{margin: '0px 15px'}}>
							<Button id={"test"} 
								onClick={() => {
									appCtrl.logout();
									history.push('/');
								}}
								 content={hintsTranslate('logout')} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</LoginLayout>
	)
}

/**
 * Strona akceptacji warunków usługi i innych niezbędnych (na ten moment nie obsługujemy opcjonalnych) zgód użytkownika
 */
const TermsOfService = (props) => {
	const appCtrl = useAppCtrl()
	if (!appCtrl.session.isAuthorized)
		return <Redirect to='/' />

	return (
		<FormMetaArray
			data={appCtrl.session.termsOfServiceApprovalRequired}
			// data={{
			// 	meta: {
			// 		dataType: 'AuthLogin',
			// 		attributes: {
			// 			Approved: {
			// 				name: 'Approved',
			// 				label: 'Approved',
			// 				type: 'Bool',
			// 				validations: [{ type: 'Required' }],
			// 				editableOnUpdate : true
			// 			},
			// 			ApprovedLabel: {
			// 				name: 'ApprovedLabel',
			// 				label: 'ApprovedLabel',
			// 				type: 'String'
			// 			},
			// 			FileIdent: {
			// 				name: 'FileIdent',
			// 				label: 'FileIdent',
			// 				type: 'String'
			// 			},
			// 		}
			// 	},
			// 	data: [
			// 		{
			// 			id: "c081c268-507c-4977-b418-4b6941c68b54",
			// 			type: "AgreementFile",
			// 			attributes: {
			// 				Approved : false,
			// 				ApprovedLabel : "Oświadczam, że zapoznałem się z klauzulą informacyjną i prawami przysługującym mi w ramach ochrony danych osobowych oraz wyrażam zgodę na przetwarzanie moich danych osobowych zamieszczonych w niniejszym formularzu przez 3Oceans Sp. z o.o. z siedzibą w Warszawie (00-564), ul. Koszykowa 3/160, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego, prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie XII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0000352793, NIP: 701-02-22-415, REGON: 142258398 w celu realizacji procesu rekrutacji w tym rejsie.",
			// 				FileIdent : {
			// 					Id: "94838b15-e574-4dbc-b646-b459d9b2fe86",
			// 					value: "tos.pdf"
			// 				}
			// 			}
			// 		},
			// 		{
			// 			id: "238dfc18-afc3-43c8-96f5-626a8947df9f",
			// 			type: "AgreementFile",
			// 			attributes: {
			// 				Approved : false,
			// 				ApprovedLabel : "Warunki korzystania z uslugi #2",
			// 				FileIdent : {
			// 					Id: "41aa5ca5-0a9a-41bc-a1c5-0f71d5c4b846",
			// 					value: "tos2.pdf"
			// 				}
			// 			}
			// 		},
			// 		{
			// 			id: "e9c380c2-2db6-4b4e-9d6b-49d9b2c1e699",
			// 			type: "AgreementFile",
			// 			attributes: {
			// 				Approved : false,
			// 				ApprovedLabel : "Oświadczam, że zapoznałem się z klauzulą informacyjną i prawami przysługującym mi w ramach ochrony danych osobowych oraz wyrażam zgodę na przetwarzanie moich danych osobowych zamieszczonych w niniejszym formularzu przez 3Oceans Sp. z o.o. z siedzibą w Warszawie (00-564), ul. Koszykowa 3/160, wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego, prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie XII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS: 0000352793, NIP: 701-02-22-415, REGON: 142258398 w celu realizacji procesu rekrutacji w tym rejsie.",
			// 				FileIdent : {
			// 					Id: "94838b15-e574-4dbc-b646-b459d9b2fe86",
			// 					value: "tos.pdf"
			// 				}
			// 			}
			// 		},
			// 		{
			// 			id: "cf18623f-49b0-4204-8272-75d502c906c9",
			// 			type: "AgreementFile",
			// 			attributes: {
			// 				Approved : false,
			// 				ApprovedLabel : "Warunki korzystania z uslugi #2",
			// 				FileIdent : {
			// 					Id: "41aa5ca5-0a9a-41bc-a1c5-0f71d5c4b846",
			// 					value: "tos2.pdf"
			// 				}
			// 			}
			// 		}
			// 	]
			// }}
			onSubmit={async form => {
				Logger.of('TermsOfService.onSubmit').info('Save terms of service');

				const mapForms = new Map()
				form.changedChildForms.forEach(childForm => {
					if (childForm.id)
						mapForms.set(childForm.id, childForm)
				})

				if (mapForms.size) {
					const forms = []
					mapForms.forEach(f => forms.push(f))

					const [errorObject, isOk] = await getFormRestService('/{organizationId}/termsOfService').acceptTermsOfService(forms)
					if (isOk) {
						Logger.of('TermsOfService.onSubmit').info('ToS Accepted');
						Logger.of('TermsOfService.onSubmit').info('Refreshing access token');
						if(await getFormRestService(`/auth/identities/${appCtrl.session.identityId}/token`).refreshAccessToken())
						{
							Logger.of('TermsOfService.onSubmit').info('Access token refreshed');
							props.history.push('/')
						}
						else
						{
							Logger.of('TermsOfService.onSubmit').error("Could not refresh access token once terms of service has been approved. Logging out.")
							appCtrl.logout()
							props.history.push('/')
						}
					}
					else {
						let errorMessage = errorObject.detail;
						if (!isString(errorObject.detail)) {
							Logger.of('TermsOfService.onSubmit').error('TermsOfService.js - Brak szczegółów błędu (Właściwości "detail"): ', errorObject.detail)
						}
						else
							errorMessage = errorObject.title

						form.globalError = errorMessage
						form.trigger('data')
					}
				}
			}}
			isMounted={true}
			redirectToLogin={() => null}
			errorModal={() => null}
		>
			<ErrorModalFormEx />
			<TermsOfServiceItems />
		</FormMetaArray>
	)
}


export default TermsOfService;