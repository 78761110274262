import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'components/form/Form'
import Combobox from './Combobox'
import { ComboboxMenu } from './Combobox'
import { useTranslate } from 'locale/Locale'
import { GlobalStrings } from 'GlobalStrings';

const ComboboxEnum = ({ name, onChange, hasLabel, hasIcon, onClick, ...other }) => {
	const field = useField(name)
	const translate = useTranslate('WebSpa/Inputs/Combobox')

	const values = field.meta?.values ?? []
	const { label, error, placeholder, disabled, required, hint } = field
	const inputParams = { label, error, placeholder, disabled, required, hasLabel, hint }

	const localizations = {
		noOptionsText: translate('noOptionText'),
		clearText: translate('clearText'),
		closeText: translate('closeText'),
		openText: translate('openText'),
	}

	const handleChange = (e, newValue) => {

		if (field?.form?.data?.meta?.reloadOnChangeAttributes?.includes(field.name)) {
			field.form.asyncSubmit(GlobalStrings.reloadOnChangeSubmitType, { triggeredFieldName: name });
		}

		//Sytuacja gdy wartość jest stringiem
		if (newValue && typeof newValue.value === 'string') {
			const valueToSend = { value: newValue.value, id: newValue.id }
			field.handleChange(valueToSend)
			onChange && onChange(field)
			return
		}

		//Sytuacja gdy wpiszemu Nulla
		if (newValue === null && !required) {
			field.handleChange(null)
			return
		}

		return
	}


	return (
		<Combobox
			name={name}
			required={required}
			disabled={disabled}
			options={values}
			value={field.value}
			hint={field.hint}
			getOptionLabel={(option) => option.value || ""}
			inputParams={inputParams}
			localizations={localizations}
			onChange={(e, newValue) => handleChange(e, newValue)}
			getOptionDisabled={(option) => option.disabled}
			getOptionSelected={(option, value) => option?.value === value }
			renderOption={(option, currentInputValue) => <ComboboxMenu option={option.value} inputValue={currentInputValue} name={name} />}
			{...other}
		/>
	)
}

//#region Export  
export default ComboboxEnum
//#endregion

//#region PropTypes & DefaultProps
ComboboxEnum.propTypes = {
	name: PropTypes.string,
	onChange: PropTypes.func,
	hasLabel: PropTypes.bool,
	hint: PropTypes.string,
	hasIcon: PropTypes.bool,
	onClick: PropTypes.func,
}

ComboboxEnum.defaultProps = {
	hasLabel: true,
}
//#endregion