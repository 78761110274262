import React, { createContext } from 'react'
import { useForm } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import { isEmpty, trim, toString } from 'lodash'
import JSZip from "jszip"
import { saveAs } from 'file-saver'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useTranslate } from 'locale/Locale'
import {  ButtonDownloadFiles as ButtonDownloadFiles} from '../index';
import { GlobalStrings } from 'GlobalStrings';
import { useFileProgress } from 'components/FileDownloadProgressDialog';


//#region Context
export const ButtonDownloadFileContext = createContext({})
//#endregion

/**
 * Funkcja mówiąca o tym czy przycisk do pobierania wszystkich plików z listy ma się pojawić
 * !!! Przycisk pojawia się tylko: !!!
 * 1) W widoku listy
 * 2) W zahardcodowanych w tablicy "DOWNLOAD_FILES_BUTTON_DATA_TYPES" miejscach
 * 3) Gdy w danej liście są co najmniej 2 pliki do pobrania
 * @param { Object } form - Aktualna forma z której pobieramy dane
 * @param { Boolean } isList - Informacja czy znajdujemy się obecnie w widoku listy
 * @returns { Boolean }
 */
const canDownloadAllFilesButtonAppear = (form, isList) => {


	/*if (isList)
	{
		const numberOfSelectedFiles = form?.allSelectedFiles(true).length;
		if(numberOfSelectedFiles> 1 &&  numberOfSelectedFiles === form?.rowManager?.marksOrSelectedDatas?.data?.length) {
			return true;
		}
	}*/


	return true;
}

/**
 * Metoda służąca do zamiany niedozwolonych znaków w nazwie pliku
 * https://stackoverflow.com/questions/1976007/what-characters-are-forbidden-in-windows-and-linux-directory-names
 * @param {String} fileName 
 * @param {bool} replaceDot Jeśli na true to wtedy też usuwamy kropkę
 * @returns string z usuniętymi niedozwolonymi znakami. Dodatkowo kropka jest niedozwolonym znakiem w paczce zip.
 */
const replaceIllegalCharactersInFileName = (fileName, replaceDot) => {
	if (replaceDot){
		return fileName.replace(/[<:"\\/|.?*]/g, ' ');
	}
	return fileName.replace(/[<:"\\/|?*]/g, ' ');
};

/**
 * Komponent obsługujący pojawianie się przycisków do pobierania plików
 * Zawiera metody współdzielone przez pliki ButtonDownloadSingleFile.js oraz ButtonDownloadMultipleFile.js
 * Tworzy oraz udostępnia kontekst wyżej wspomnianym pliką
 * @returns Button typu Download z predefiniowaną ikonką pozwalający na wykonanie akcji pobrania pliku
 * @param hardcodedDownloadButtonMeta - Metadane zahardkodowanego przycisku dostarczonego do pobierania plików, który jest dostarczony z BE
 */
export function ButtonDownloadFile({ isList, hardcodedDownloadButtonMeta }) {
	const form = useForm('data')
	const dataType = toString(form._dataType)	
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const translate = useTranslate('WebSpa/Snackbar/ButtonDownloadFile')
	const fileProgress = useFileProgress();

	/// pliki należące do rekordów zaznaczonych przez użytkownika 
	let allSelectedFiles;
	/// wszystkie pliki do pobrania
	let allFiles;

	const existDownLoadAllFilesLink = form.getSpecifiedLink(GlobalStrings.DOWNLAD_ALL_FILES_LINK_PREFIX, '');

	// Sytuacja kiedy obsługujemy zahardkodowany guzik (wysłany z BE) do pobrania pliku
	if(hardcodedDownloadButtonMeta) {	
		allFiles = form.getHardCodedDownloadFilesFromLink(hardcodedDownloadButtonMeta);
	}
	//Szukamy linku do pobrania wszystkich plików w glownym meta
	else if (isList && form?.data?.data?.length > 0 && existDownLoadAllFilesLink){
		allFiles = (isList ? form?.allFiles : form.parentForm?.allFiles) ?? [];
		allFiles = form.filterFilesWithDownloadLink(allFiles);
	}
	if ( !hardcodedDownloadButtonMeta && ((isList && existDownLoadAllFilesLink) || !isList))
	{	
		allSelectedFiles = (isList ? form?.allSelectedFiles : form.parentForm?.allFilesFromCurrentSelectedRow) ?? [];
		allSelectedFiles = form.filterFilesWithDownloadLink(allSelectedFiles);
	}

	/**
	 * Funkcja pozwalająca na pobranie pojedynczego pliku z BE
	 * @param {Object} fileObject - Obiekt pojedynczego pliku. Struktura: { value: string, id: string, fieldName: string }
	 * @param {bool} downLoadAllFiles informacja czy wykonujemy akcje pobierania wszystkich plików
	 * @param {object} hardcodedDownloadButtonMeta Metadane guzika dostarczonego z BE
	 * @returns {BLOB} - Pobrany plik z BE 
	*/

	const downloadFileBlobFromBE = async (fileObject, downLoadAllFiles,  hardcodedDownloadButtonMeta)  => {

		const { fieldName, value: fileName, id: fileID, token: fileToken } = fileObject;
		// enqueueSnackbar(`${translate('ButtonDownloadSingleFile/DownloadingInformation')} ${fileName}`, 
		// 	{variant: 'info', preventDuplicate: true, key: fileID});

		let downLoadFileLink = undefined;
		
		// Obsługa zahardcodowanego guzika do pobrania plików
		if (hardcodedDownloadButtonMeta) {
			if ( hardcodedDownloadButtonMeta.name == "ButtonDownloadFilesByUPA"){
				downLoadFileLink = form.getHardCodedDownloadFilesLink(hardcodedDownloadButtonMeta, fileToken);
			}
			else{
				downLoadFileLink = form.getHardCodedDownloadFilesLink(hardcodedDownloadButtonMeta, fileID);
			}
		}
		// Obsługa guzika do pobrania wszystkich plików
		else if (downLoadAllFiles) {
			downLoadFileLink = form.findAllFilesDownLoadLink(fileID);
		}
		// Obsługa guzika zwykłego guzika do pobierania plików
		else {
			downLoadFileLink = form.serviceDownloadFile(fieldName,fileID, false);
		}

		if(fileProgress.isDownloading(fileID))
		{
			console.log("File is already downloading: " + fileID);
			throw new Error('Plik jest w trakcie pobierania');
		}

		if (trim(downLoadFileLink)) {
			const fileHandle = fileProgress.addFile(fileID, fileName);
			try {
				//throw new Error('Plik jest w trakcie pobierania');
				const BLOB = await getFormRestService(downLoadFileLink).downloadFileFromLivo((loaded, contentLength) => {
					fileProgress.setProgress(fileHandle, Math.round((loaded/contentLength)*100));
				});
				fileProgress.successed(fileHandle);
				return BLOB
			}
			catch (error) {
				console.warn(`${translate('error')} ${fileName} | status: ${error.status} | url: ${error.url}`)
				fileProgress.failed(fileHandle, `${translate('error')} ${fileName}`);
				return null
			}
		}

		return null
	}

	/**
	 * Funkcja obsługująca generowanie paczki ZIP na podstawie tablicy z obiektami plików
	 * @param {Array} filesArray Tablica zawierająca obiekty plików. 
	 * @param {bool} downLoadAllFiles informacja czy wykonujemy akcje pobierania wszystkich plików
	 * @param {object} hardcodedDownloadButtonMeta Metadane guzika dostarczonego z BE
	 * Struktura pojedynczego obiektu pliku: { value: string, id: string, fieldName: string }
	 */
	const handleGenerateZipFile = async (filesArray, downLoadAllFiles, hardcodedDownloadButtonMeta) => {

		let startGeneratingZipSnackbar = undefined
		let finishGeneratingZipSnackbar = undefined

		if (!isEmpty(filesArray)) {

			const ZIP = JSZip()
			startGeneratingZipSnackbar = enqueueSnackbar(translate('startGeneratingZip'), { variant: 'info', preventDuplicate: true })

			for (const file of filesArray) {//TODO Dodać przydzielanie plików do konkretnych folderów
				const fileBLOB = await downloadFileBlobFromBE(file,downLoadAllFiles,hardcodedDownloadButtonMeta);

				if (fileBLOB && file.value) {
					let fileName = replaceIllegalCharactersInFileName(file.value);
					ZIP.file(fileName, fileBLOB);
				}
			}
			if (Object.keys(ZIP.files).length > 0) {
				ZIP.generateAsync({ type: 'blob' }).then(zipFile => {
					const actualTime = moment().format("DD-MM-YYYY_HH-mm")
	
					let zipFileName;
					if (form?.data?.meta?.groupFileName) {
						zipFileName = form?.data?.meta?.groupFileName;
					}
					else if (form?.parentForm?.data?.meta?.groupFileName){
						zipFileName = form?.parentForm?.data?.meta?.groupFileName;
					}
					else{
						zipFileName = `${dataType}-${actualTime}`;
					}
	
					finishGeneratingZipSnackbar = enqueueSnackbar(translate('finishGeneratingZip'), { variant: 'success', preventDuplicate: true })
					zipFileName = replaceIllegalCharactersInFileName(zipFileName, true);
	
					return saveAs(zipFile, zipFileName);
	
				}).catch(error => {
					console.warn(`${translate('zipError')} ${error}`)
					enqueueSnackbar(translate('zipError'), { variant: 'error', preventDuplicate: true })
				})
			}
			else
				enqueueSnackbar(translate('zipError'), { variant: 'error', preventDuplicate: true })
		}

		closeSnackbar(startGeneratingZipSnackbar)
		closeSnackbar(finishGeneratingZipSnackbar)
	}

	return (
		<ButtonDownloadFileContext.Provider
			value={{
				allFiles,
				allSelectedFiles,
				dataType,
				handleGenerateZipFile,
				downloadFileBlobFromBE,
				hardcodedDownloadButtonMeta
			}}
		>
		{allSelectedFiles?.length > 0 && <ButtonDownloadFiles allFilesButton={false}/>}
		<ButtonDownloadFiles allFilesButton={true} />
		</ButtonDownloadFileContext.Provider>
	)
}



/** !!NIE USUWAĆ!! -  Kawałek kodu pozwalający na pojawianie się przycisku "Pobierz wszystkie pliki" sterowane przez BE. 
 
	const allButonsInCurrentList = form.parentForm.getClientActionButtons && form.parentForm.getClientActionButtons()//Pobieramy wszystkie przyciski w liście z metody z FormMetaArray
	
	//Szukamy w pobranych przyciskach, obiektu pozwalającego na wyświetlenie przycisku do pobierania wielu plików
	const canDownloadAllFilesButtonAppear = !isEmpty(takeWhile(allButonsInCurrentList, {'uiAction': 'ClientAction', 'name': 'ButtonDownloadAllFiles'})) 
 
*/