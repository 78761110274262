import React from "react";
import { CompositeDecorator, DraftHandleValue, EditorState, getDefaultKeyBinding, Modifier, RichUtils, SelectionState } from "draft-js"
import decoratorsId from "../../../consts/decoratorsId";
import {AddedText, DeletedText, InsertTag, Tag, DeleteTag} from "../decorators/decorators";
import SegmentElementType from "../../model/SegmentElementType";
import draftjsMyCommands from "../../../consts/draftjsMyCommands";
import SegmentViewModel from "../../model/SegmentViewModel";
import SegmentElementViewModel from "../../model/SegmentElementViewModel";
import { 
    Range, EntityFromState, EntityMetadata, newLineCharacter,
    getEntitiesFromContentBlock, getEntitiesFromEditorState, getCurrentAllowedTagsAndMetaDataForEditorState, 
    insertTagAsEntity, insertCharsAsEntity,
    forceSelection, checkIfStartCaretIsOnTag, replaceEntity, doesRangesOverlap, initEditorContent, convertEntitiesToBackendForm, mergeEntities, MergedEntity, 
    appendSegmentObjectToEditorState} from "../editorFunctionsTs";
import { IChangeTrackingEvents } from "../../../model/EditorTranslationUnitEvents";


type CheckTagsDeleteResult = {
    isOnTag: boolean;
    newState: EditorState;
    isNewState: boolean;
    tagsOffsets: any[];
    isIns: boolean;
};



interface ControllerDecoratorProps
{
    controller: SegmentEditorController
}

interface DecoratorProps extends ControllerDecoratorProps
{
    start?: number,
    end?: number
}


type ChangeTrackingChangeType = 'NOT_TRACKED' | 'ADD' | 'DELETE';

export default class SegmentEditorController implements IChangeTrackingEvents {
    /**
     * Stan edytora, dostarczony przez draftjs. Tylko do odczytu. Posługujemy się tutaj stanem reactowym.
     */
    editorStateHook: EditorState;
    /**
     * Setter umożliwiający zmiane stanu edytora. Jest to metoda dostarczona przez useState().
     */
    setEditorStateHook: React.Dispatch<React.SetStateAction<EditorState>>;
    /** Stan reaktowy przechowujacy informacje o tym czy jestesmy w trybie tagowania? */
    isTaggingStateHook: boolean;
    /**
     * Setter umożliwaijący zmianę stanu komponentu wstawiającego tagi. 
     */
    setIsTagingStateHook: React.Dispatch<React.SetStateAction<boolean>>;
    /**
     * Setter umożliwiający zmiany stanu dozowolonych tagów prezentowanych w komponencie autosugesti tagów
     */
    setAllowedTagsStateHook: React.Dispatch<React.SetStateAction<{allowedTags: any;}>>;
    /** Hook stanu pozwalajacy na ustawienie koordynatów okienka autosugestii */
    setCoordinatesStateHook: React.Dispatch<any>;
    /**
     * Referencja na edytor
     */
    editorRef: React.MutableRefObject<any>;
    /**
     * Czy tryb śledzenia zmian jest włączony? Tylko w tym trybie śledzimy zmiany.
     */
    IsChangeTrackingEnabled: boolean;
    /** Dozwolone tagi na poziomie segmentu */
    SegmentAllowedTags: any[];
    /**
     * Dekorator edytora DraftJs
     */
    editorDecorator: CompositeDecorator;
    /**
     * Segment źrółówy tego segment edytora
     */
    SourceSegmentObject: any;

    constructor() {
        const decoratorProps = {
            controller: this
        };
        this.editorDecorator = new CompositeDecorator([
            {
                strategy: this._decoratorStrategyFindDeletedTextWrapper(),
                component: DeletedText,
                props: decoratorProps
            },
            {
                strategy: this._decoratorStrategyFindInsertedTextWrapper(),
                component: AddedText,
                props: decoratorProps
            },
            {
                strategy: this._decoratorStrategyFindSpecifiedEntityByType(decoratorsId.TAG),
                component: Tag,
            },
            {
                strategy: this._decoratorStrategyFindSpecifiedEntityByType(decoratorsId.INS_TAG),
                component: InsertTag,
                props: decoratorProps
            },
            {
                strategy: this._decoratorStrategyFindSpecifiedEntityByType(decoratorsId.DEL_TAG),
                component: DeleteTag,
                props: decoratorProps
            },
        ]);
    }

    onAcceptChangeTrackingChanges(tuId: number): void
    {
        let editorState = this.editorRef.current.props.editorState;
        while(true)
        {
            
            const contentState = editorState.getCurrentContent();
            const rawEntities = getEntitiesFromEditorState(editorState);
            const mergedEntites = mergeEntities(rawEntities, contentState);
            const changeTrackingEntity = mergedEntites.find(me => decoratorsId.isChangeTracking(me.entityType));
            if(changeTrackingEntity)
            {
                editorState = this.approveOrDiscardChanges(
                    {start: changeTrackingEntity.location.startIdx, end: changeTrackingEntity.location.endIdx, controller: this}, 
                    true, 
                    editorState);
            }
            else
            {
                break;
            }
        }
        this.setEditorStateHook(editorState);
    }

    onDiscardChangeTrackingChanges(tuId: number): void
    {
        let editorState = this.editorRef.current.props.editorState;
        while(true)
        {
            const contentState = editorState.getCurrentContent();
            const rawEntities = getEntitiesFromEditorState(editorState);
            const mergedEntites = mergeEntities(rawEntities, contentState);
            const changeTrackingEntity = mergedEntites.find(me => decoratorsId.isChangeTracking(me.entityType));
            if(changeTrackingEntity)
            {
                editorState = this.approveOrDiscardChanges(
                    {start: changeTrackingEntity.location.startIdx, end: changeTrackingEntity.location.endIdx, controller: this}, 
                    false, 
                    editorState);
            }
            else
            {
                break;
            }
        }
        this.setEditorStateHook(editorState);
    }

    initContent(segmentObject: any, allowedTags: any[]) {
        const content = initEditorContent(segmentObject, allowedTags);
        this.setEditorStateHook(EditorState.createWithContent(content, this.editorDecorator))
    }

    _deleteText(range: Range, editorState: EditorState) : EditorState {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();
        let anchorKey = currentSelection.getAnchorKey();
        const targetRange = new SelectionState({
            anchorKey: anchorKey,
            anchorOffset: range.startIdx,
            focusKey: anchorKey,
            focusOffset: range.endIdx,
        });
        const newContent = Modifier.replaceText(
            currentContent,
            targetRange,
            ''
        );
        const newEditorState = EditorState.push(editorState, newContent,  'remove-range');
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter());
    }

    _checkTagsDelete(editorState: EditorState, delType: string, index? : number) : CheckTagsDeleteResult {
        const selection = editorState.getSelection();
        let text = editorState.getCurrentContent().getPlainText(newLineCharacter);
        let startOffset = selection.getStartOffset();
        if(index){
            startOffset = index;
        }

        const entities: EntityFromState[] = getEntitiesFromEditorState(editorState);
        let isCursorOnTag = false
        let newState = null
        let isIns = false
        let tagsOffsets = []
        entities.forEach((item, index) => {
            if (delType === draftjsMyCommands.myBackspace)
            {
                if (item.location.startIdx < startOffset && startOffset <= item.location.endIdx && startOffset !== item.location.startIdx)
                {
                    tagsOffsets.push([item.location.startIdx, item.location.endIdx])
                    if (item.entity.getType() === decoratorsId.TAG)
                    {
                        let meta = item.entity.getData();
                        newState = replaceEntity(text.slice(item.location.startIdx, item.location.endIdx), editorState, decoratorsId.DEL_TAG, item.location, meta)
                        newState = forceSelection(newState, item.location)
                        isCursorOnTag = true
                    }
                    else if (item.entity.getType() === decoratorsId.DEL_TAG){
                        console.log("Cursor is on tag delete");
                        isCursorOnTag = true
                        // ustawiamy kursor przed tagiem
                        newState = forceSelection(editorState, {startIdx: item.location.startIdx, endIdx: item.location.startIdx});
                    }
                    else if (item.entity.getType() === decoratorsId.INS_TAG) {
                        newState = this._deleteText(item.location, editorState)
                        newState = forceSelection(newState, item.location)
                        isCursorOnTag = true
                        isIns = true
                    }
                }
            }
            else if (delType === draftjsMyCommands.myDelete){
                if (item.location.startIdx <= startOffset && startOffset < item.location.endIdx && startOffset !== item.location.endIdx){
                    tagsOffsets.push([item.location.startIdx, item.location.endIdx])
                    if (item.entity.getType() === decoratorsId.TAG){
                        let meta = item.entity.getData();
                        newState = replaceEntity(text.slice(item.location.startIdx, item.location.endIdx), editorState, decoratorsId.DEL_TAG, item.location, meta)
                        newState = forceSelection(newState, item.location)
                        isCursorOnTag = true
                    }
                    else if (item.entity.getType() === decoratorsId.DEL_TAG){
                        isCursorOnTag = true
                        newState = forceSelection(editorState, item.location)
                    }
                    else if (item.entity.getType() === decoratorsId.INS_TAG
                        /// wyłączony tryb śledzenia zmian
                        || (!this.IsChangeTrackingEnabled && item.entity.getType() === decoratorsId.SOURCE_TEXT)) {
                        newState = this._deleteText(item.location, editorState)
                        isCursorOnTag = true
                        isIns = true
                    }
                }
            }
        })
        if (newState){
            return {isOnTag: isCursorOnTag, newState: newState, isNewState: true, tagsOffsets: tagsOffsets, isIns: isIns}
        }
        return {isOnTag: isCursorOnTag, newState: editorState, isNewState: false, tagsOffsets: tagsOffsets, isIns: isIns}
    }

    _deleteMoreThanOneSelection(editorState: EditorState, text: string, startCaret: number, endCaret: number, entities: EntityFromState[]) : EditorState {
        let newState = editorState
        const charToDel = text.slice(startCaret, endCaret)
        let entIsIns = true
        let deletedCharCounter = 0

        for (let i = 0; i < charToDel.length; i++) 
        {
            entIsIns = true
            let tagDeletion = this._checkTagsDelete(newState, draftjsMyCommands.myDelete, startCaret+i-deletedCharCounter)
            if (tagDeletion.isOnTag)
            {
                if(tagDeletion.isIns) 
                {
                    newState = tagDeletion.newState
                    entIsIns = false
                    i =  tagDeletion.tagsOffsets[0][1] + deletedCharCounter - startCaret - 1
                    if (tagDeletion.tagsOffsets[0][0] < endCaret && endCaret < tagDeletion.tagsOffsets[0][1]){
                        deletedCharCounter = endCaret - tagDeletion.tagsOffsets[0][0]
                    } else {
                        deletedCharCounter += tagDeletion.tagsOffsets[0][1] - tagDeletion.tagsOffsets[0][0]
                    }
                    continue

                }
                else if(tagDeletion.isNewState){
                    newState = tagDeletion.newState
                    entIsIns = false
                    i =  tagDeletion.tagsOffsets[0][1] + deletedCharCounter - startCaret - 1
                    continue
                }
            }
            const charToDel = text.slice(startCaret+i, startCaret+1+i)
            entities.forEach((item, index) => {
                if (item.location.startIdx === startCaret+i)
                {
                    if(this.IsChangeTrackingEnabled && item.entity.getType() === decoratorsId.INS){
                        newState = this._deleteText({startIdx: startCaret+i-deletedCharCounter, endIdx: startCaret+1+i-deletedCharCounter}, newState);
                        deletedCharCounter = deletedCharCounter + 1;
                        entIsIns = false;
                    }else{
                        newState = replaceEntity(charToDel, newState, this.IsChangeTrackingEnabled ? decoratorsId.DEL : decoratorsId.SOURCE_TEXT, {startIdx:startCaret+i-deletedCharCounter, endIdx:startCaret+1+i-deletedCharCounter }, {});
                        entIsIns = false;
                    }
                }
            })
            if(entIsIns)
            {
                newState = this._deleteText({startIdx: startCaret+i-deletedCharCounter, endIdx: startCaret+1+i-deletedCharCounter}, newState);
                deletedCharCounter = deletedCharCounter + 1;
            }
        }
        newState = forceSelection(newState, {startIdx: endCaret - deletedCharCounter, endIdx: endCaret - deletedCharCounter});
        return newState
    }

    /**
     * Wstaw TAG w miejsce gdzie aktualnie znajduje się kursor
     * @param tagName Prezentowana nazwa tagu
     * @param tagObject Element reprezentujący TAG
     */
    insertTag(tagName: string, tagObject: SegmentElementViewModel){
        const selection = this.editorStateHook.getSelection()
        const startCaret = selection.getStartOffset()
        const entityType = this.IsChangeTrackingEnabled ? decoratorsId.INS_TAG : decoratorsId.TAG;
        let newState = insertTagAsEntity(tagName, this.editorStateHook, entityType, 'IMMUTABLE', {element: tagObject})

        const anchorKey = selection.getAnchorKey();
        const newSelection = new SelectionState({
            anchorKey: anchorKey,
            anchorOffset:startCaret + tagName.length,
            focusKey: anchorKey,
            focusOffset: startCaret + tagName.length,
        });
        newState = EditorState.forceSelection(newState, newSelection);
        this.setIsTagingStateHook(false)
        this.setEditorStateHook(newState)
    }

    /**
     * Wstaw podany ciąg znaków w miejsce gdzie aktualnie znajduje się kursor
     * @param chars 
     */
    insertChars(chars: string) 
    {
        const newState = this._insertChars(chars, this.editorStateHook);
        this.setEditorStateHook(newState);
    }

    createSegmentObjectFromEditorState(): any {
        if(!this.isTaggingStateHook){
            const entities = getEntitiesFromEditorState(this.editorStateHook)
            const newSegment = convertEntitiesToBackendForm(entities, this.editorStateHook)
            return newSegment;
        }

        return false;
    }

    createSegmentObjectFromEditorStateEx(state: EditorState): any {
        if(!this.isTaggingStateHook){
            const entities = getEntitiesFromEditorState(state)
            const newSegment = convertEntitiesToBackendForm(entities, state)
            return newSegment;
        }

        return false;
    }

     approveChanges(decoratorProps: DecoratorProps) 
    {
        const editorState = this.approveOrDiscardChanges(decoratorProps, true);
        this.setEditorStateHook(editorState);
    }

     discardChanges(decoratorProps: DecoratorProps) 
    {
        const editorState = this.approveOrDiscardChanges(decoratorProps, false);
        this.setEditorStateHook(editorState);
    }

    approveOrDiscardChanges(decoratorProps: DecoratorProps, approve: boolean, editorState: EditorState = null): EditorState 
    {
        editorState = editorState ?? this.editorRef.current.props.editorState;
        const contentState = editorState.getCurrentContent();
        const rawEntities = getEntitiesFromEditorState(editorState);
        const mergedEntites = mergeEntities(rawEntities, contentState);
        
        // Zakres encji których dotyczy operacja.
        const approveRange: Range = {startIdx: decoratorProps.start, endIdx: decoratorProps.end};
        const indexOfEntityAtRange = mergedEntites.findIndex(mr => doesRangesOverlap(mr.location, approveRange));
        const changeType = this._getChangeTrackingType(mergedEntites[indexOfEntityAtRange].entityType);
        
        // Szukamy encji o takim samym typie zmiany przed i po encji pierwotnej. Chodzi o to, że chcemy przeprcesować 
        // cały blok wchodzący w ten sam typ zmiany.
        const entitiesToApprove: MergedEntity[] = [];
        for(let entityIdx = indexOfEntityAtRange - 1; entityIdx >= 0; entityIdx--)
        {
            const precedingEntity = mergedEntites[entityIdx];
            if(this._getChangeTrackingType(precedingEntity.entityType) === changeType)
                entitiesToApprove.push(precedingEntity);
            else
                break;
        }

        entitiesToApprove.push(mergedEntites[indexOfEntityAtRange]);

        for(let entityIdx = indexOfEntityAtRange + 1; entityIdx < mergedEntites.length; entityIdx++)
        {
            const followingEntity = mergedEntites[entityIdx];
            if(this._getChangeTrackingType(followingEntity.entityType) === changeType)
                entitiesToApprove.push(followingEntity);
            else
                break;
        }

        console.log("Entities to approve", entitiesToApprove);
        // W tym miejscu mamy wszystkie encje, które musimy zatwierdzić
        for(let entityIdx = entitiesToApprove.length - 1; entityIdx >= 0; entityIdx--)
        {
            const entity = entitiesToApprove[entityIdx];
            const newEntityType = this._getNonChangeTrackingEntityType(entity.entityType);
            const replaceEntityInCaseOfChangeType: ChangeTrackingChangeType = approve ? "ADD" : "DELETE";
            if(changeType === replaceEntityInCaseOfChangeType)
            {
                //editorState = replaceEntity(entity.text, editorState, newEntityType, entity.location, entity.metadata);
                //editorState = this._removeChangeTrackingState(editorState, entity, newEntityType);
                // Powyższe nie działało zgodnie z oczekiwaniami. Edytor nie emitował zdarzenia informującego o zmianie stanu.
                editorState = this._deleteText(entity.location, editorState);
                const newMetadata = {...entity.metadata};
                newMetadata.changeTracking = undefined;
                if(decoratorsId.isText(entity.entityType))
                    editorState = insertCharsAsEntity(entity.text, editorState, newEntityType, newMetadata);
                else
                    editorState = insertTagAsEntity(entity.text, editorState, newEntityType, 'IMMUTABLE', newMetadata);
            }
            else
            {
                editorState = this._deleteText(entity.location, editorState);
            }
        }

        return editorState;
    }

    _removeChangeTrackingState(editorState: EditorState, actualEntity: MergedEntity, newEntityType: string)
    {
        const currentContent = editorState.getCurrentContent();
        const newMetadata = {...actualEntity.metadata};
        newMetadata.changeTracking = undefined;
        const entity = currentContent.createEntity(newEntityType, 'SEGMENTED', newMetadata);
        const entityKey = currentContent.getLastCreatedEntityKey();

        const selection = currentContent.getSelectionAfter();
        let anchorKey = selection.getAnchorKey();
        const targetRange = new SelectionState({
            anchorKey: anchorKey,
            anchorOffset: actualEntity.location.startIdx,
            focusKey: anchorKey,
            focusOffset: actualEntity.location.endIdx,
        });

        let newContentState = Modifier.applyEntity(currentContent, targetRange, entityKey);
        return EditorState.push(editorState, newContentState, 'apply-entity');
    }

    _getChangeTrackingType(entityType: string): ChangeTrackingChangeType
    {
        if(decoratorsId.isInsertChangeTracking(entityType))
            return "ADD";
        
        if(decoratorsId.isDeleteChangeTracking(entityType))
            return "DELETE";

        return "NOT_TRACKED";
    }

    _getNonChangeTrackingEntityType(entityType: string): string
    {
        switch(entityType)
        {
            case decoratorsId.INS:
            case decoratorsId.DEL:
                return decoratorsId.SOURCE_TEXT;
            case decoratorsId.INS_TAG:
            case decoratorsId.DEL_TAG:
                return decoratorsId.TAG;
            default:
                throw new Error('Unexpected entity type: ' + entityType + '. Cannot resolve non change tracking entity type.');
        }
    }

    /** Metoda usuwa wybrany zakres znaków na podstawie dekoratora */
    async deleteTextAsync(decoratorProps) {
        const editorState = this.editorRef.current.props.editorState;
        const newState = this._deleteText({startIdx: decoratorProps.start, endIdx: decoratorProps.end}, editorState);
        this.setEditorStateHook(newState);
    }

    

    

    _insertChars(chars: string, editorState: EditorState) { 
        const selection = editorState.getSelection();
        const text = editorState.getCurrentContent().getPlainText(newLineCharacter)
        const startCaret = selection.getStartOffset()
        const endCaret = selection.getEndOffset();
        const insertedTextEntityType = this.IsChangeTrackingEnabled ? decoratorsId.INS : decoratorsId.SOURCE_TEXT;

        const isReplacingSelectedText = startCaret !== endCaret;
        if (isReplacingSelectedText) 
        {
            const allEntities = getEntitiesFromEditorState(editorState)
            let newState = this._deleteMoreThanOneSelection(editorState, text, startCaret, endCaret, allEntities)
            const newSelection = newState.getSelection()
            const newCaret = newSelection.getStartOffset()
            const newEntities = getEntitiesFromEditorState(newState, decoratorsId.DEL_TAG)
            newEntities.forEach((item, index) => {
                if (item.location.startIdx < newCaret && newCaret < item.location.endIdx) {
                    newState = forceSelection(newState, item.location);
                }
            })

            return insertCharsAsEntity(chars, newState, insertedTextEntityType, {});
        }

        return insertCharsAsEntity(chars, editorState, insertedTextEntityType, {});
    }

    // Handlery komponentu DraftJs
    DjsHandleKeyCommand(command: string, editorState: EditorState, eventTimeStamp: number) : DraftHandleValue {
        if (command === draftjsMyCommands.myAutoSuggestTrigger){
            return 'handled'
        }
        if(this.IsChangeTrackingEnabled)
        {
            const selection = editorState.getSelection()
            let text = editorState.getCurrentContent().getPlainText(newLineCharacter)
            let startCaret = selection.getStartOffset()
            let endCaret = selection.getEndOffset();
            let entities = getEntitiesFromEditorState(editorState)
            let anchorKey = selection.getAnchorKey();
            let tagDeletion = this._checkTagsDelete(editorState, command, null)
            if (tagDeletion.isOnTag && selection.isCollapsed()){
                if(tagDeletion.isNewState){
                    this.setEditorStateHook(tagDeletion.newState)
                }
                return 'handled'
            }
            if (selection.isCollapsed())
            {
                if (command === draftjsMyCommands.myBackspace && startCaret !== 0) 
                {
                    let lefTypIsIns = false
                    entities.forEach((item, index) => 
                    {
                        if (startCaret === item.location.endIdx)
                        {
                            if(!decoratorsId.isInsertChangeTracking(item.entity.getType()))
                            {
                                const charToIns = text.slice(startCaret - 1, startCaret)
                                let newState = replaceEntity(charToIns, editorState, decoratorsId.DEL, {startIdx: startCaret - 1, endIdx: startCaret}, {});
                                let newSelection = new SelectionState({
                                    anchorKey: anchorKey,
                                    anchorOffset:startCaret-1,
                                    focusKey: anchorKey,
                                    focusOffset: startCaret-1,
                                });
                                newState = EditorState.forceSelection(newState, newSelection);
                                this.setEditorStateHook(newState)
                                lefTypIsIns = true
                            }
                            
                        }
                    })
                    if (lefTypIsIns){
                        return 'handled'
                    }
                    let newState = this._deleteText({startIdx: startCaret - 1, endIdx: startCaret}, editorState)
                    this.setEditorStateHook(newState)
                    return 'handled'
                }
                else if (command == draftjsMyCommands.myDelete) 
                {
                    let entIsIns = false
                    entities.forEach((item, index) => {
                        if (startCaret === item.location.startIdx) 
                        {
                            if(!decoratorsId.isInsertChangeTracking(item.entity.getType()))
                            {
                                const charToIns = text.slice(startCaret, startCaret + 1)
                                let newState = replaceEntity(charToIns, editorState, decoratorsId.DEL, {startIdx: startCaret, endIdx: startCaret+1}, {});
                                let newSelection = new SelectionState({
                                    anchorKey: anchorKey,
                                    anchorOffset:startCaret+1,
                                    focusKey: anchorKey,
                                    focusOffset: startCaret+1,
                                });
                                newState = EditorState.forceSelection(newState, newSelection);
                                this.setEditorStateHook(newState)
                                entIsIns = true
                            }
                        }
                    })
                    if (entIsIns){
                        return 'handled'
                    }
                    let newState = this._deleteText({startIdx: startCaret, endIdx: startCaret+1}, editorState)
                    this.setEditorStateHook(newState)
                    return 'handled'

                }
            }
            else if (startCaret !== endCaret) {
                if (command === draftjsMyCommands.myBackspace || command == draftjsMyCommands.myDelete) {
                    let newState = this._deleteMoreThanOneSelection(editorState, text, startCaret, endCaret, entities)
                    this.setEditorStateHook(newState)

                    return 'handled'
                }
            }
        }

        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.setEditorStateHook(newState)
            return 'handled';
        }
        return 'not-handled';
    }

    DjsKeyBindingFn(e: React.KeyboardEvent<{}>, onKeyDown: any ) : string {
        const selection = this.editorStateHook.getSelection()
        let startCaret = selection.getStartOffset()
        let endCaret = selection.getEndOffset()

        if (e.ctrlKey)
        {
            console.log(e.key);
            if(e.key === 'Enter')
            {
                let entities = getEntitiesFromEditorState(this.editorStateHook)
                let newSegment = convertEntitiesToBackendForm(entities, this.editorStateHook)
                onKeyDown(e, newSegment)
            }
            else if(e.key === 'Insert')
            {
                const editorState = this.editorStateHook;
                let newState = null;
                if(this.IsChangeTrackingEnabled)
                {
                    const allEntities = getEntitiesFromEditorState(editorState);
                    const text = editorState.getCurrentContent().getPlainText(newLineCharacter);
                    newState = this._deleteMoreThanOneSelection(editorState, text, 0, text.length, allEntities);
                    newState = appendSegmentObjectToEditorState(newState, this.SourceSegmentObject, this.SegmentAllowedTags);
                }
                else
                {
                    newState = EditorState.createEmpty();
                    newState = appendSegmentObjectToEditorState(newState, this.SourceSegmentObject, this.SegmentAllowedTags);
                }
                
                this.setEditorStateHook(newState);
                let entities = getEntitiesFromEditorState(newState)
                let newSegment = convertEntitiesToBackendForm(entities, newState)
                onKeyDown(e, newSegment);
            }
        }

        if (e.key === 'Enter') {
            return draftjsMyCommands.enterDisabled
        }
        if (e.key === 'Tab') {
            return draftjsMyCommands.enterDisabled
        }
        if (e.key.length === 1 && checkIfStartCaretIsOnTag(this.editorStateHook) && selection.isCollapsed()){
            return draftjsMyCommands.disabledInsertOnTag;
        }
        if (this.isTaggingStateHook){
            return draftjsMyCommands.myTagging
        }

        if(this.IsChangeTrackingEnabled){
            if (e.key === 'Delete') {
                return draftjsMyCommands.myDelete
            }
            if (e.key === 'Backspace') {
                return draftjsMyCommands.myBackspace
            }
            if (startCaret !== endCaret) {
                if (e.key === 'ArrowLeft' ||
                    e.key === 'ArrowRight' ||
                    (e.key === 'c' && e.ctrlKey)
                ){
                    return getDefaultKeyBinding(e)
                } else if (e.key == 'x' && e.ctrlKey){
                    return 'myMoreThanOneSelection'
                }
                getDefaultKeyBinding(e)
            }
        }
        
        if (
            e.key === '@' && this.SegmentAllowedTags.length !== 0 && selection.isCollapsed()
        ){
            let result = getCurrentAllowedTagsAndMetaDataForEditorState(this.editorStateHook, this.SegmentAllowedTags);
            let coordinates = window.getSelection().getRangeAt(0).getBoundingClientRect()
            if (coordinates.x ===0 && coordinates.y ===0){
                coordinates = this.editorRef.current.editor.getBoundingClientRect()
            }
            this.setAllowedTagsStateHook({allowedTags: result})
            this.setCoordinatesStateHook(coordinates)
            this.setIsTagingStateHook(true)
            return draftjsMyCommands.myAutoSuggestTrigger
        }
        return getDefaultKeyBinding(e)
    }

    DjsHandlePastedText(text: string, html: string, editorState: EditorState): DraftHandleValue {
        const newEditorState = this._insertChars(text, editorState);
        this.setEditorStateHook(newEditorState);
        return 'handled';
    }

    DjsHandleBeforeInput(chars: string, editorState: EditorState, eventTimeStamp: number): DraftHandleValue {
        const newEditorState = this._insertChars(chars, editorState);
        this.setEditorStateHook(newEditorState);
        return 'handled';
    }
    // END Handlery komponentu DraftJs

    // Dekoratory
    _decoratorStrategyFindSpecifiedEntityByType(type: string): any {
        return function(contentBlock, callback, contentState) {
            contentBlock.findEntityRanges((character) => {
                const entityKey = character.getEntity();
                if (entityKey){
                    let entityType = contentState.getEntity(entityKey).getType()
                    return (
                        entityType === type
                    );
                }
    
            }, callback);
        }
    }

    _decoratorStrategyFindDeletedTextWrapper() {
        return function (contentBlock, callback, contentState) {
            const entities = getEntitiesFromContentBlock(contentBlock, contentState)
            const mergedEntities = mergeEntities(entities, contentState, contentBlock)
            mergedEntities.forEach((entity) => {
                if (entity.entityType === decoratorsId.DEL) {
                    callback(entity.location.startIdx, entity.location.endIdx)
                }
            })
        }
    }



    _decoratorStrategyFindInsertedTextWrapper() {
        return function (contentBlock, callback, contentState) {
            const entities = getEntitiesFromContentBlock(contentBlock, contentState)
            const mergedEntities = mergeEntities(entities, contentState, contentBlock)
            mergedEntities.forEach((entity) => {
                if (entity.entityType === decoratorsId.INS) {
                    callback(entity.location.startIdx, entity.location.endIdx)
                }
            })
        }
    }
    // END Dekoratory
}