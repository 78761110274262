import { LocalStorage } from 'components/Storages'

export class ConfigurationClass {
	constructor() {
		this._configuration = new Map()
		this._changes = new Map()
	}

	parseJSON(json){
		try {
		  return JSON.parse(json)
		} catch (e) {
		  console.error(`[ConfigurationClass.js: 13 ] Error parsing JSON: ${json}`)	
		  return undefined
		}
	 }
	 
	getValue(key) {
		let value = this._changes.get(key)

		if (value)
			return this.parseJSON(value)

		value = this._configuration.get(key)

		if (value)
			return this.parseJSON(value)
	}

	setValue(key, value) {
		let oldValue = this._configuration.get(key)
		let newValue = JSON.stringify(value)

		if (oldValue !== newValue)
			this._changes.set(key, newValue)
	}

	get toKeyValues() {
		let res = {}

		this._configuration.forEach((v, k) => {
			res[k] = v
		})
		return res
	}

	get changesToKeyValues(){
		let res = {}

		this._changes.forEach((v, k) => {
			res[k] = v
		})
		return res
	}

	get hasChanges() {
		return this._changes.size > 0
	}

	changesToConfiguration() {
		this._changes.forEach((v, k) => {
			this._configuration.set(k, v)
		})
		this._changes.clear()
	}

	setValues(values) {
		this._configuration = new Map()
		this._changes = new Map()

		if (values)
			Object.keys(values).forEach(k => this._configuration.set(k, values[k]))
		this.toLocalStorage()
	}

	toLocalStorage() {
		LocalStorage.set('configuration', this.toKeyValues)
	}

	fromLocalStorage() {
		this._configuration = new Map()
		this._changes = new Map()

		let s = LocalStorage.get('configuration')

		if (s)
			Object.keys(s).forEach(k => this._configuration.set(k, s[k]))
	}
}