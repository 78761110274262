import React, { useCallback } from "react";
import { useCKEditor, CKEditorEventAction } from "ckeditor4-react";
import { useField } from "components/form/Form";
import { useLocale } from "locale/Locale";
import { FluidTemplateSyntaxHtmlDecoder } from "Tools/Tools";
import isEmpty from 'lodash/isEmpty'
import { useState, useEffect } from 'react';
import { GlobalStrings } from 'GlobalStrings';
import { Dialog, DialogTitle, DialogContent, DialogActions, Divider } from "@material-ui/core";
import { useTranslate } from 'locale/Locale'
import Button from 'components/form/components/list/components/buttons/Button'


/**
 * Klucz do wartości w sessionStorage, która przechowuje informacje o tym czy zmieniono szablon,
 * Używamy go ponieważ ,za każdym razem gdy zmieniamy szablon musimy ręcznie ustawić dane szablonu,
 * co powoduje wywołania się eventu 'onChange' 
 * - w tamtym miejscu czytamy czy dane zostały zmienione czy został zmieniony szablon 
 */
const shouldHandleChange1 = 'shouldHandleChange1';
/**
 * Rozwiazanie tymczasowe - komponent zawsze jest renderowany tyle razy ile występuje pole z edycją szablonu
 * z tego powodu trzeba mieć 2 zmienne ktore zarządzają stanem każdego z szablonu odzielnie, wykorzystywane podczas:
 * - anulowania edycji szablonu (aby nie wywoływać 2-krotnie )
 * - zmiany objektu szablonu w tabeli
 */
const shouldHandleChange2 = 'shouldHandleChange2';
/**
 * Komponent edytora WYSIWYG, objecnie korzystamy z wersji ckeditor4
 * 17.05.2022 UWAGA!! nie należy aktualizować tej biblioteki do nowszej wersji (ckeditor5),
 * ponieważ nie obsługuje ona "inline style" i wiele innych funkcjonalności, należy pisać ręcznie pluginy, co wymaga dodatkowych nakładów
 * 
 * Dodatkowe wskazówki:
 *  - CKEditor4 v2 - ustawia on tylko dane podczas inicjalizacji, jeśli chcemy ustawić ponownie dane poza edytorem 
 * to musimy wykonać taką operacje ręcznie za pomocą: editor.setData (np. podczas zmiany objektu szablonu)
 * @param {string} Nazwa pola  
 */
export default function HTMLEditor({ name }) {

	const field = useField(name);

	const currentLanguage =
		useLocale().currentCultureCode || window.navigator.language;

	//obsługa poputa z informacją o błędzie
	const [isDialogErrorOpen, setIsDialogErrorOpen] = React.useState(false);

	const [element, setElement] = useState();
	const { status, editor } = useCKEditor({
		initContent: parseInput(field.valueInput),
		element: element,
		style: { borderColor: "#1a314b" },
		config: {
			language: currentLanguage,
			allowedContent: true,
			entities_latin: false,
			extraPlugins: "autogrow,placeholder",
			autoGrow_minHeight: 200,
			removePlugins: "resize",
			fullPage: true,
			readOnly: !field.meta.editableOnUpdate
		},
		/**
		 * Lista eventów CKeditora w trybie 'wysiwyg', pozostałe eventy trzeba obsługiwać w innym miejscu
		 */
		dispatchEvent: ({ type, payload }) => {
			if (type === CKEditorEventAction.change) {
				if (getShouldHandleChange() == 'false') {
					setShouldHandleChange('true');
					return;
				}

				if (!payload.editor)
					return;

				let data = payload.editor.getData();

				if (!isEmpty(data)) {
					field.handleChange(parseBeforeChangeState(data));
				}
			}
			if (type === CKEditorEventAction.paste) {
				if (matchValue(payload.data.dataValue, "{%") || matchValue(payload.data.dataValue, "%}")) {
					setIsDialogErrorOpen(true);
					payload.editor.setData(payload.editor.getData());
					field.form.clearChanges();
				}
			}
		},
		subscribeTo: ['change', 'paste'],
	});

	if (editor) {
		handleEditorModeChanged(editor, field);
	}

	//obsluga zmiany instancji szablonu
	useEffect(() => {
		if (editor) {
			setShouldHandleChange('false');
			editor.setData(parseInput(field.valueInput));
		}
	}, [field?.form?.id]);

	//obsługa kliknięca przycisku 'cancel'
	//dane w CKEditor4 są tylko raz ustawiane 
	//co jest ważne to musimy to wywołać w tym miejscu
	const [dummyState, setState] = React.useState(true);
	React.useEffect(() => {
		if (editor) {
			setShouldHandleChange('false');
			editor.setData(parseInput(field.valueInput));
		}
	}, [dummyState])

	const handleCancelButtonPressed = useCallback(event => {
		setState(Object.create(null));
	}, []);

	field.form._events.useListener(GlobalStrings.CancelEventName, handleCancelButtonPressed);

	const translate = useTranslate('WebSpa');
	return (
		<div>
			<div ref={setElement} style={status !== "ready" ? { visibility: "hidden" } : undefined} />
			{ErrorDialog(isDialogErrorOpen, translate, setIsDialogErrorOpen)}
		</div>
	);
}


/**
 * Obsługa natywnych wydarzeń reacta
 * @param {*} editor edytor
 * @param {*} field  pole z zawartością szablonu
 */
const handleEditorModeChanged = (editor, field) => {
	//nasłuchiwanie zmian w trybie edycji source
	editor.on('mode', function () {
		if (this.mode == 'source') {
			const editable = editor.editable();
			if (!editable.hasListeners('input')){
				editable.attachListener(editable, 'input', debounce(() => {
					field.handleChange(parseBeforeChangeState(editor.getData()));
				}, 2000));
			}
		}
	});
	//parsowanie danych przed zmianą trybu
	editor.on('beforeSetMode', function () {
		//zmiana z 'wysiwyg' na 'source'
		if (this.mode == 'wysiwyg') {
			if(matchValue(editor.getData(),'<!--{%') || matchValue(editor.getData(),'%}-->')){
				editor.setData(parseOutPut(editor.getData()));
				//zapobiegamy odpaleniu handleChange
				setShouldHandleChange('false');
			}
		}
		if (this.mode == 'source') {
			if(!matchValue(editor.getData(),'<!--{%') && !matchValue(editor.getData(),'%}-->')){
				editor.setData(parseInput(editor.getData()));
				//zapobiegamy odpaleniu handleChange
				setShouldHandleChange('false');
			}
		}
	});
}

/** 
 * Metoda służy do sprasowania odpowiednio kodu silnika szablonu @see https://github.com/sebastienros/fluid
 * Polecenia nie są akceptowalne przez przeglądarki, nie są w standardzie HTML więc zostałyby one automatycznie przesunięte przez przeglądarkę
 * np. polecenia języka szablonów ({% polecenie %}) w tagu: <table> </table> zostało by 
 * automatycznie wyrzucone poza ten tag (nie jest możliwe wstawianie innych danych poza tr)
 * dlatego zostaną one zakomentowane
*/
const parseInput = (fieldValue) => {
	fieldValue = replaceAll(fieldValue, "{%", '<!--{%');
	fieldValue = replaceAll(fieldValue, "%}", '%}-->');
	return fieldValue;
}

/**
 * Odkomentowujemy zakomentowany kod języka szablonów
 */
 const parseOutPut = (fieldValue) => {
	fieldValue = replaceAll(fieldValue, "<!--{%", "{%");
	fieldValue = replaceAll(fieldValue, "%}-->", '%}');
	return fieldValue;
}

/**
 * Metoda do sparsowania danych przed handleChange
 */
const parseBeforeChangeState = (fieldValue) => {
	fieldValue = parseOutPut(fieldValue);

	//dekodujemy znaki znajdujące się w środku języka szablonów 'Liqud'
	fieldValue = FluidTemplateSyntaxHtmlDecoder(fieldValue);
	return fieldValue;
}

const replaceAll = (str, find, replace) => {
	return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

/**
 * Funkcja wstawiająca znak "ucieczki" '\' przed znakami specjalnymi - chcemy je traktować jak zwykłe iterały stringowe
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 */
const escapeRegExp = (string) => {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const matchValue = (str, find) => {
	let res = [...str.matchAll(new RegExp(escapeRegExp(find), 'g'))]
	return res.length > 0;
}

const debounce = (callback, delay) => {
	let timeout;
	return () => {
		clearTimeout(timeout);
		timeout = setTimeout(callback, delay);
	}
}

const getShouldHandleChange = () => {
	if (sessionStorage.getItem(shouldHandleChange1) === 'false' || sessionStorage.getItem(shouldHandleChange2) === 'false')
		return 'false';
	else
		return 'true';
}

const setShouldHandleChange = (shouldHandleChange) => {
	if (sessionStorage.getItem(shouldHandleChange1) !== shouldHandleChange)
		sessionStorage.setItem(shouldHandleChange1, shouldHandleChange);
	else
		sessionStorage.setItem(shouldHandleChange2, shouldHandleChange);
}

/**
 * Popout z informacją o błędzie
 */
function ErrorDialog(isDialogErrorOpen, translate, setIsDialogErrorOpen) {
	return (<Dialog open={isDialogErrorOpen}>
		<DialogTitle>
			{translate('WYSIWYGEditorError/title')}
		</DialogTitle>
		<DialogContent dividers>
			{translate('WYSIWYGEditorError/content')}
		</DialogContent>
		<Divider />
		<DialogActions>
			<Button type='submit' content={translate('Buttons/closeButton')} onClick={() => setIsDialogErrorOpen(false)} style={{ margin: '15px 10px' }} />
		</DialogActions>
	</Dialog>);
}

