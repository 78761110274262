import moment from "moment";
import { RefEnumAttributeValue, RefAttributeValue } from "./ResourceDocument";

export class ValueConverter
{
    private locale: string;

    setLocale(locale: string){
        this.locale = locale;
    }

    dtoRefToString(value?: RefAttributeValue)
    {
        if(value == null)
            return null;

        return value.value ?? '';
    }

    dtoEnumToString(value?: RefEnumAttributeValue) : string
    {
        if(value == null)
            return null;

        return value.value ?? '';
    }

    dtoEnumListToString(value?: Array<RefEnumAttributeValue>): string
    {
        if(value == null)
            return null;

        return value.map(v => v.value ?? '').join(', ');
    }

    dtoGuidListToString(value?: Array<RefAttributeValue>) : string 
    {
        if(value == null)
            return null;

        return Object.values(value).map( v => {
            if (!v.value) {
                console.warn('Undefined value in GuidList: ', v);
                return v.id;
            }
            return v.value;
        }).join(', ');
    }

    dtoFileListToString(value?: Array<RefAttributeValue>): string 
    {
        if(value == null)
            return null;

        return Object.values(value).map( v => {
            if (!v.value) {
                console.warn('Undefined value in GuidList: ', v);
                return v.id;
            }
            return v.value;
        }).join(', ');
    }

    dtoBoolToString(value?: boolean) : string 
    {
        if(value == null)
            return '';

        return value ? '1' : '0';
    }

    dtoNumberToString(value?: number, precision?: number): string
    {
        if(value == null)
            return null;

        if(precision == null)
            precision = 2; // //Jeśli precision występuje to go zwracamy, jeśli nie zaokrąglamy do 2 miejsc

        return value.toLocaleString(undefined, { minimumFractionDigits: precision, maximumFractionDigits: precision });
    }

    dtoIntToString(value?: number): string
    {
        if(value == null)
            return null;

        return value.toString();
    }
    
    /**
     * Konwertuje ciąg znaków reprezentujący tylko datę w postaci daty i czasu UTC 
     * do daty w formacie YYYY-MM-DD i czasie lokalnym.
     */
    dtoDateToString(value?: string): string
    {
        if(value == null)
            return null;

        if(value === '')
            return '';

        const date = new Date(value);
        return moment(date).format('YYYY-MM-DD');
    }

    /**
     * Konwertuje ciąg znaków reprezentujących date i czas w UTC
     * do daty w formacie YYYY-MM-DD HH:mm
     * @param value 
     */
    dtoTimestampToString(value?: string): string
    {
        if(value == null)
            return null;

        if(value === '')
            return '';

        const date = new Date(value);
        return moment(date).format('YYYY-MM-DD HH:mm');
    }

    dtoFileToString(value?: RefAttributeValue): string
    {
        if(value?.value == null)
            return null;

        return value.value;
    }
}