/**
 * Metoda tworząca poprawny adres url wraz z przekazanymi w obiekcie data parametrami
 * @param {String} baseURL - url do którego doklejamy wartości z obiektu data
 * @param {Object} data - obiekt zawierający wartości które chcemy dokleić do bazowego linku
 * @returns {String} - Zwraca string z poprawnym adresem url oraz doklejonymi do niego parametrami
 */
export function jsonToUrl(baseURL, data) {
	if (!data || !Object.keys(data).length)
		return baseURL

	let params = {}
	//Jeżeli są parametry typu object to są 'wypłaszczane' do struktury params
	for (const [key, value] of Object.entries(data))
		if (value !== null && (typeof value === 'object') && !Array.isArray(value))
			params = { ...params, ...value }
		else
			params[key] = value

	const url = new URL(baseURL)

	//Mergowanie istniejacych searchParams z params
	for (let [key, value] of url.searchParams) {
		if (key !== 'search') {
			if(!(key in params)){
				params[key] = value
			}
			continue
		}

		//jesli key jest search to dodajemy parametr search z params i z url'a
		if ('search' in params)
			params.search = `${params.search},${value}`
		else
			params.search = value
	}

	const paramsToUrl = new URLSearchParams(params).toString()
	url.search = paramsToUrl
	return url.toString()
}