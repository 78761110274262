import React from 'react'
import useForm from 'components/form/hooks/useForm'
import { GradientHidder, Horizontal } from 'components/topology/Topology'
import { HeaderSort } from './HeaderSort'
import { HeaderCellBorder } from './HeaderCellBorder'
import { toString } from 'lodash'
import { customElements } from 'cssStatic'
import './HeaderCell.css'

export const HeaderHolder = customElements('o-header-holder', [
	'overflow-hidden', 'br-2', 'p-left-3', 'grey100', {
		'border-right': '2px solid var(--o-grey400)',
		'border-bottom': '1px solid #dcdcdc',
		transform: 'translateX(1px)',
		'&:first-child': {
			'border-left': '2px solid var(--o-grey400)'
		}
	}
])

export function HeaderCell({ column }) {
	const form = useForm('data')
	const dataType = form.dataType

	return (
		<HeaderHolder id={`${toString(dataType)}-${toString(column.name)}-header`}>
			<HeaderCellBorder
				onClick={e => {
					if (e.ctrlKey) {
						form.columnManager.toggleGroup(column.name, e.shiftKey)
						return
					}
					form.columnManager.toggleSort(column.name, e.shiftKey)
				}}
			>
				<Horizontal className='grid-acx-start grid-aiy-center' >
						<div className='header-innner'>	
							{column.meta.label}
						</div>
					<HeaderSort column={column} />
				</Horizontal>
			</HeaderCellBorder>
		</HeaderHolder>
	)
}