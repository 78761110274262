import { customElements } from 'cssStatic'

export const StyledLabel = customElements('o-dowload-file-label', [
	'absolute', 'white', 'f-weight-400', 'f-14',
	{
		'top': '-11px',
		'left': '10px',
		'padding': '0 8px 0 8px'
	}
])


///TODO style sprawdzić
export const StyledDownloadFileList=customElements('o-dowload-file-list',[
	'grid-ver','grid-gap-10','grid-acx-start','relative','br-radius-4',
	{
		border: `1px solid var(--o-mainDarken900)`,
		padding: '25px 8px 10px 8px'
	}
])

// ///TODO styled
// export const StyledFileListGrid = withStyles(({ palette }) => ({
// 	root: {
// 		border: `1px solid ${palette.primary.mainDarken800}`,
// 		position: 'relative',
// 		padding: '25px 8px 10px 8px',
// 		borderRadius: '4px',
// 		gridGap: '10px',
// 	}
// }))(Grid)
