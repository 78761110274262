import React from 'react'
import { useHistory } from "react-router-dom"
import TabItem from './TabItem'
import { linkManager } from 'components/LinkManager'

export default function Tab({ tab, tabIndex }) {
	const history = useHistory()

	return (
		<div className='dataView-tab'>
			{tab.map((part, index) => {
				return (
					<TabItem
						key={index}
						part={part}
						onClick={() => {
							linkManager.handleTab(history,part)
						}}
					/>
				)
			})}
		</div>
	)
}
