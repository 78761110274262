import React from 'react'
import { useForm } from 'components/form/Form'
import * as PositionObserver from 'components/positionObserver/PositionObserver'
import { GanttObserved } from './GanttObserved'

export function GanttObserveds() {
	const form = useForm('data')

	if (!form.ganttManager.isGantt)
		return null

	return (
		<PositionObserver.Observers>
			{
				form.rowManager.visibleRows.map(row => <GanttObserved row={row} />)
			}
		</PositionObserver.Observers>
	)
}