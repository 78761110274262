import React from 'react'

export function GridResizable({
	onlyFirstRow,
	holdSizeX, canResizeBeginX, canResizeEndX, minSizeX, splitterModeX, templateX, disableX,
	holdSizeY, canResizeBeginY, canResizeEndY, minSizeY, splitterModeY, templateY, disableY,
	onResized,
	children,
	style,
	...other
}) {
	const gridRef = React.useRef()
	const [state, setState] = React.useState({ templateX, templateY })
	
	React.useEffect(() => {
		setState({ templateX, templateY })
	}, [templateX, templateY])

	if (!minSizeX)
		minSizeX = 5
	if (!holdSizeX)
		holdSizeX = 5
	if (!minSizeY)
		minSizeY = 5
	if (!holdSizeY)
		holdSizeY = 5

	//Liczenie sumy tablicy z wyłączniem wartości auto
	const templateSum = (t) => t.reduce((p, c) => c === 'auto' ? p : c + p, 0)
	//Odejmowanie p1-p2 p1 i p2 muszą zawierać {x: ,y:}
	const minusXY = (p1, p2) => { return { x: p1.x - p2.x, y: p1.y - p2.y } }

	const templateToString = t => {
		if (typeof (t) === 'string')
			return t

		if (Array.isArray(t))
			return t.map(t => typeof (t) === 'string' ? t : t + 'px').join(' ')
	}

	//Zwraca strukturę z niezbędnymi danymi do wyswietlania i zmian 
	//{
	// gridSize: rozmiary całego grida
	// gridPos:  pozycje grida na cliencie 	
	// pointerPos: pozycja wskaznika wzgledem clienta
	// pos: pozycja pointera wzgledem tego grida 
	// indexX/Y: indeks noda nad ktorym jest pointer
	// bFirstX/Y: czy indexX/Y===0
	// bX/Y: Czy jest nad 
	//}
	const getResize = ev => {
		//Jeśli splitterMode to nie można zmieniać rozmiaru ostatniego elementu 
		if (splitterModeX)
			canResizeEndX = false
		if (splitterModeY)
			canResizeEndY = false

		//Rozmiary this
		let rect = gridRef.current.getBoundingClientRect()
		//Zwracana struktura
		let resize = {
			gridSize: { x: rect.width, y: rect.height },
			gridPos: { x: rect.left, y: rect.top },
			pointerPos: { x: ev.clientX, y: ev.clientY }
		}

		resize.pos = minusXY(resize.pointerPos, resize.gridPos)

		//Jeśli włączone zmiany to przetwarzamy
		if (Array.isArray(state.templateX) && !disableX) {
			let tx = state.templateX
			let pos = resize.pos.x

			//Jesli włączone przesuwanie na początku to patrzymy czy trafiono 
			if (canResizeBeginX && pos <= holdSizeX) {
				resize.indexX = 0
				resize.bFirstX = true
				resize.bX = true
			}
			else {
				//Sprawdzenie czy jest trafienie dalej

				//suma przesuniecie dla poprzednich tx
				let tpos = 0
				tx.forEach((t, index) => {
					if (t === 'auto')
						return

					//Jesli jest onlyFirstRow to sprawdzamy czy pointer jest w wyskości pierwszego dziecka grida 
					if (onlyFirstRow) {
						let pos = resize.pos.y
						let childNode = gridRef.current.childNodes[0]

						if (childNode && (pos < 0 || pos > childNode.offsetHeight))
							return
					}

					//Jezeli !canResizeEndX to opuszczamy sprawdzanie ostatniego
					if (!canResizeEndX && index + 1 === tx.length)
						return

					//Czy mieści się w zakresie
					if (pos >= tpos + t - holdSizeX && pos <= tpos + t + holdSizeX) {
						resize.indexX = index
						resize.bX = true
					}

					tpos += t
				})
			}
		}

		//Jeśli włączone zmiany to przetwarzamy
		if (Array.isArray(state.templateY) && !disableY) {
			let ty = state.templateY
			let pos = resize.pos.y

			//Jesli włączone przesuwanie na początku to patrzymy czy trafiono 
			if (canResizeBeginY && pos <= holdSizeY) {
				resize.indexY = 0
				resize.bFirstY = true
				resize.bY = true
			}
			else {
				//Sprawdzenie czy jest trafienie dalej

				//suma przesuniecie dla poprzednich tx
				let tpos = 0
				ty.forEach((t, index) => {
					if (t === 'auto')
						return

					//Jezeli !canResizeEndY to opuszczamy sprawdzanie ostatniego
					if (!canResizeEndY && index + 1 === ty.length)
						return

					//Czy mieści się w zakresie
					if (pos >= tpos + t - holdSizeY && pos <= tpos + t + holdSizeY) {
						resize.indexY = index
						resize.bY = true
					}

					tpos += t
				})
			}
		}

		return resize
	}

	const cursor = resize => {
		let cursor = ''
		let { bX, bY } = resize

		if (bX && bY)
			cursor = 'crosshair'
		else
			if (bX)
				cursor = 'col-resize'
			else
				if (bY)
					cursor = 'row-resize'

		gridRef.current.style.cursor = cursor
	}

	const tx = templateToString(state.templateX)
	const ty = templateToString(state.templateY)

	return (
		<div
			ref={gridRef}
			style={{ display: 'grid', gridTemplateColumns: tx, gridTemplateRows: ty, ...style }}
			onPointerDown={(ev) => {
				const resize = getResize(ev)

				if (!resize.bX && !resize.bY)
					return

				setState({ ...state, resize })
				//Przechwytujemy wskaznik
				gridRef.current.setPointerCapture(ev.pointerId)
				ev.preventDefault()
			}}
			onPointerMove={(ev) => {
				//Jeśli nie naciśnieto myszy to tylko zmieniamy cursor
				if (!state.resize) {
					cursor(getResize(ev))
					return
				}

				let resize = state.resize
				cursor(resize)

				//Pobieramy oktualne położenie wskaznika
				let pointerPos = { x: ev.clientX, y: ev.clientY }
				//Położenie wskaznika wzgledem this
				let pos = minusXY(pointerPos, resize.gridPos)

				if (Array.isArray(state.templateX) && !disableX) {
					let tx = state.templateX
					let tpos = 0

					for (let i = 0; i < resize.indexX; tpos += tx[i++])
						;

					//Rozmiar zmienianego elementu
					let t0 = tx[resize.indexX]
					//Nowy rozmiar zmienianego elementu
					let nt0 = pos.x - tpos

					//Jeśli jest zamały to korygujemy 
					if (nt0 < minSizeX)
						nt0 = minSizeX

					//Wstawiamy nową wartość
					tx[resize.indexX] = nt0

					//W splitter mode modyfikujemy rownież nastepny element
					//zabierając z niego tyle ile dodajemy w t0
					if (splitterModeX) {
						//Długość nastepnego elementu
						let t1 = tx[resize.indexX + 1]

						//Jeśli t1 nie jest ostatnie to liczymy
						if (t1 !== 'auto') {
							//Nowa długość
							let nt1 = t1 - nt0 + t0

							//Czy nie zamały
							if (nt1 < minSizeX)
								nt1 = minSizeX

							//Wstawiamy nową wartość
							tx[resize.indexX + 1] = nt1
						}

						//Teraz sprawdzimy czy nie zmieniamy długość całego grida

						let sum = templateSum(tx)

						if (t1 === 'auto') {
							if (sum > resize.gridSize.x - minSizeX) {
								//Korygujemy bo zadługie

								nt0 -= sum + minSizeX - resize.gridSize.x
								tx[resize.indexX] = nt0
							}
						} else {
							if (sum > resize.gridSize.x) {
								//Korygujemy bo zadługie

								nt0 -= sum - resize.gridSize.x
								tx[resize.indexX] = nt0
							}
						}
					}
				}

				if (Array.isArray(state.templateY) && !disableY) {
					let ty = state.templateY
					let tpos = 0

					for (let i = 0; i < resize.indexY; tpos += ty[i++])
						;

					//Rozmiar zmienianego elementu
					let t0 = ty[resize.indexY]
					//Nowy rozmiar zmienianego elementu
					let nt0 = pos.y - tpos

					//Jeśli jest zamały to korygujemy 
					if (nt0 < minSizeY)
						nt0 = minSizeY

					//Wstawiamy nową wartość
					ty[resize.indexY] = nt0

					//W splitter mode modyfikujemy rownież nastepny element
					//zabierając z niego tyle ile dodajemy w t0
					if (splitterModeY) {
						//Długość nastepnego elementu
						let t1 = ty[resize.indexY + 1]

						//Jeśli t1 nie jest ostatnie to liczymy
						if (t1 !== 'auto') {
							//Nowa długość
							let nt1 = t1 - nt0 + t0

							//Czy nie zamały
							if (nt1 < minSizeY)
								nt1 = minSizeY

							//Wstawiamy nową wartość
							ty[resize.indexY + 1] = nt1
						}

						//Teraz sprawdzimy czy nie zmieniamy długość całego grida

						let sum = templateSum(ty)

						if (t1 === 'auto') {
							if (sum > resize.gridSize.y - minSizeY) {
								//Korygujemy bo zadługie
								nt0 -= sum + minSizeY - resize.gridSize.y
								ty[resize.indexY] = nt0
							}
						} else {
							if (sum > resize.gridSize.y) {
								//Korygujemy bo zadługie
								nt0 -= sum - resize.gridSize.y
								ty[resize.indexY] = nt0
							}
						}
					}
				}

				setState({ templateX: state.templateX, templateY: state.templateY, resize })
			}}
			onPointerUp={(ev) => {
				if (!state.resize)
					return

				gridRef.current.releasePointerCapture(ev.pointerId)
				setState({ templateX: state.templateX, templateY: state.templateY })
				if (onResized)
					onResized({ templateX, templateY })
			}}
			{...other}
		>
			{children}
		</div>
	)
}
