const ConfirmationLevelType = {
    NotSpecified: 0,
    Draft: 1,
    Translated: 2,
    RejectedSignOff: 3,
    RejectedTranslation: 4,
    ApprovedSignOff: 5,
    ApprovedTranslation: 6,

    isNotTranslated: function (confirmationLevel) {
        return confirmationLevel == ConfirmationLevelType.NotSpecified || confirmationLevel == ConfirmationLevelType.Draft || 
            confirmationLevel == ConfirmationLevelType.RejectedTranslation;
    }
}

export default ConfirmationLevelType