const SegmentElementType = {
    StandaloneTag: "StandaloneTag",
    StartTag: "StartTag",
    EndTag: "EndTag",
    StartIns: "StartIns",
    EndIns: "EndIns",
    StartDel: "StartDel",
    EndDel: "EndDel",
    Text: "Text",
    StartHighlight: "StartHighlight",
    EndHighlight: "EndHighlight",
    values: () => {
        return [
            SegmentElementType.StandaloneTag,
            SegmentElementType.StartTag,
            SegmentElementType.EndTag,
            SegmentElementType.StartIns,
            SegmentElementType.EndIns,
            SegmentElementType.StartDel,
            SegmentElementType.EndDel,
            SegmentElementType.Text,
            SegmentElementType.StartHighlight,
            SegmentElementType.EndHighlight
        ]
    },
    changeTrackingValues: () => {
        return [
            SegmentElementType.StartIns,
            SegmentElementType.EndIns,
            SegmentElementType.StartDel,
            SegmentElementType.EndDel
        ];
    },
    highlightValues: () => {
        return [
            SegmentElementType.StartHighlight,
            SegmentElementType.EndHighlight
        ]
    },
    saveableValues: () => {
        return [
            SegmentElementType.StandaloneTag,
            SegmentElementType.StartTag,
            SegmentElementType.EndTag,
            SegmentElementType.StartIns,
            SegmentElementType.EndIns,
            SegmentElementType.StartDel,
            SegmentElementType.EndDel,
            SegmentElementType.Text,
        ]
    }
}

export default SegmentElementType
