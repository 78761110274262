import React from "react";
import { Helmet as ReactHelmet } from "react-helmet";
import { defaultAttributes } from './defaultAttributes'

const Helmet = ({ currentLanguage, organizationName }) => {

	const { charSet, title, defaultLanguage, translate, htmlTagClass } = defaultAttributes
	const currentCode = currentLanguage || defaultLanguage

	let pageTitle = title;
	if (organizationName) {
		pageTitle = `${pageTitle} - ${organizationName}`;
	}

	return (
		<ReactHelmet htmlAttributes={{ lang: currentCode, translate, className: htmlTagClass }}>
			<meta charSet={charSet} />
			<meta name="google" content="notranslate" />
			<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>
			<meta name="theme-color" content="#000000"></meta>
			<link rel="canonical" href="http://mysite.com/example" />
			<title>{pageTitle}</title>
		</ReactHelmet>
	)
}

export default Helmet