import React from 'react'
import { Preloader, MessageListener } from 'components/form/Form'
import Card from 'components/card/Card'
import { Sections } from 'components/form/components/sections/Sections'
import { Buttons } from 'components/form/components/list/components/buttons/Buttons'

export default function CreatePartInner({ part, createEditForm, polymorphic }) {
	if (createEditForm)
		return (
			<React.Fragment>
				<MessageListener />
				{createEditForm({ part })}
			</React.Fragment>
		)

	let fields = <Sections polymorphic={polymorphic} />

	return (
		<React.Fragment>
			<MessageListener />
			<Card>
				<Preloader>
					<Buttons part={part}/>
					{fields}
				</Preloader>
			</Card>
		</React.Fragment>
	)
}
