import FormBase from './FormBase'

/**
 * Klasa głownej formy aplikacji
 */
export default class FormMetaRoot extends FormBase {
	constructor(params){
		super(params)

		this._links=params.links
	}

	getServiceRelativeRead(relativeDataType){return this._links['read'+relativeDataType]}
	getServiceRelativeReadHRef(relativeDataType){
		let s=this.getServiceRelativeRead(relativeDataType)
		if(s)
			return s.href
	}

	getServiceRelativeReadOne(relativeDataType){return this._links['readOne'+relativeDataType]}
	getServiceRelativeReadOneHRef(relativeDataType){
		let s=this.getServiceRelativeReadOne(relativeDataType)
		if(s)
			return s.href
	}

	getServiceRelativeCreate(relativeDataType){return this._links['create'+relativeDataType]}
	getServiceRelativeCreateHRef(relativeDataType){
		let s=this.getServiceRelativeCreate(relativeDataType)
		if(s)
			return s.href
	}
}