export class LocalConfiguration {
    keyPrefix: string;

    constructor(keyPrefix: string)
    {
        this.keyPrefix = keyPrefix;
    }

    registerDefaultNumber(key: string, value: number)
    {
        if(value === null)
            throw Error("Value cannot be null");

        const internalKey = this._getInternalKey(key);
        if(localStorage.getItem(internalKey) !== null)
            return;

        this._setValue(internalKey, value.toString());
    }

    registerDefaultString(key: string, value: string)
    {
        if(value === null)
            throw Error("Value cannot be null");

        const internalKey = this._getInternalKey(key);
        if(localStorage.getItem(internalKey) !== null)
            return;

        this._setValue(internalKey, value);
    }

    registerDefaultBool(key: string, value: boolean) 
    {
        if(value === null)
            throw Error("Value cannot be null");

        const internalKey = this._getInternalKey(key);
        if(localStorage.getItem(internalKey) !== null)
            return;

        this._setValue(internalKey, value.toString());
    }

    registerDefaultObject(key: string, value: any)
    {
        if(value === null)
            throw Error("Value cannot be null");

        const internalKey = this._getInternalKey(key);
        if(localStorage.getItem(internalKey) !== null)
            return;

        this._setValue(internalKey, JSON.stringify(value));
    }

    getValueNumber(key: string): number 
    {
        const internalKey = this._getInternalKey(key);
        const stringValue = localStorage.getItem(internalKey);
        return Number(stringValue);
    }

    getValueString(key: string): string 
    {
        const internalKey = this._getInternalKey(key);
        return localStorage.getItem(internalKey);
    }

    getValueBool(key: string): boolean 
    {
        const internalKey = this._getInternalKey(key);
        const stringValue = localStorage.getItem(internalKey);
        return stringValue === 'true';
    }

    getValueObject(key: string): any
    {
        const stringValue = this.getValueString(key);
        if(stringValue == null)
            return {};
        
        return JSON.parse(stringValue);
    }

    setValueNumber(key: string, value: number)
    {
        const internalKey = this._getInternalKey(key);
        this._setValue(internalKey, value.toString());
    }

    setValueString(key: string, value: string)
    {
        const internalKey = this._getInternalKey(key);
        this._setValue(internalKey, value);
    }

    setValueBool(key: string, value: boolean) 
    {
        const internalKey = this._getInternalKey(key);
        this._setValue(internalKey, value.toString());
    }

    setValueObject(key: string, value: any)
    {
        const internalKey = this._getInternalKey(key);
        this._setValue(internalKey, value ? JSON.stringify(value) : null);
    }

    scope(key: string): LocalConfigurationScope
    {
        return new LocalConfigurationScope(this, key);
    }

    _getInternalKey(key: string): string 
    {
        return `${this.keyPrefix}:${key}`;
    }

    _setValue(key: string, value: string) 
    {
        localStorage.setItem(key, value);
    }
}

export class LocalConfigurationScope extends LocalConfiguration
{
    _rootConfiguration: LocalConfiguration;

    scopeKeyPrefix: string;
    scopeKey: string;

    constructor(rootConfiguration: LocalConfiguration, scopeKey: string)
    {
        super(`${rootConfiguration.keyPrefix}_Scope:${scopeKey}`);
        this.scopeKeyPrefix = `${rootConfiguration.keyPrefix}_Scope`;
        this.scopeKey = scopeKey;
        this._rootConfiguration = rootConfiguration;
    }

    getValueNumber(key: string): number 
    {
        const stringValue = super.getValueString(key);
        if(stringValue === null)
        {
            return this._rootConfiguration.getValueNumber(key);
        }

        this._setLastUsage(key);
        return super.getValueNumber(key);
    }

    getValueString(key: string): string 
    {
        const stringValue = super.getValueString(key);
        if(stringValue === null)
        {
            return this._rootConfiguration.getValueString(key);
        }

        this._setLastUsage(key);
        return stringValue;
    }

    getValueBool(key: string): boolean 
    {
        const stringValue = super.getValueString(key);
        if(stringValue === null)
        {
            return this._rootConfiguration.getValueBool(key);
        }

        this._setLastUsage(key);
        return super.getValueBool(key);
    }

    /** Zapamiętujemy ostatnie użycie. Tak na potrzeby późniejszego czyszczenia danych. */
    _setLastUsage(key: string)
    {
        this._setValue(`${this.scopeKeyPrefix}_Usage:${this.scopeKey}:${key}`, new Date().toISOString());
    }
}

const localConfiguration = new LocalConfiguration('LivoCat_Config');
export default localConfiguration;