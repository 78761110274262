/**
 * @author Konrad Majewski
 * @license _ Tylko do użytku omero w projekcie LivoLINK
 */

 class Iterator {
	/**
	 * constructor
	 * @param {iterable} iterator
	 */
	constructor(iterator) {
		this._it = iterator;
	}

	/**
	 * Tworzy Iterator  
	 * @param {iterator} iterator
	 * @returns {Iterator} 
	 */
	static fromIterator(iterator) {
		return new Iterator(iterator)
	}

	/**
	 * Tworzy Iterator z array
	 * @param {Array} array
	 * @returns {Iterator} 
	 */
	static fromArray(array) {
		return new Iterator(function* () {
			for (let i = 0; i < array.length; ++i)
				yield array[i];
		})
	}

	/**
	 * Tworzy Iterator z object.
	 * Kolejne iterowane elementy to [key,value]
	 * 
	 * @param {Object} object
	 * @returns {Iterator} 
	 */
	static fromObject(object) {
		return new Iterator(function* () {
			for (let key in object)
				yield [key, object[key]];
		})
	}

	/**
	 * Tworzy Iterator wartości od 0 do c-1
	 *
	 * @param {numer} c
	 * @returns {Iterator}
	 */
	static count(c) {
		return new Iterator(function* () {
			for (let i = 0; i < c; ++i)
				yield i;
		})
	}

	/**
	 * Tworzy Iterator wartosci od start do <=stop z krokiem step
	 * Jeśli step nie zdefiniowane to step=1
	 *
	 * @param {number} start Poczatek liczenia
	 * @param {number} stop	Koniec liczenia
	 * @param {number} step	Krok liczenia
	 * @returns {Iterator}
	 */
	static range(start, stop, step) {
		if (step === undefined)
			step = 1;
		return new Iterator(function* () {
			for (let i = start; i <= stop; i += step)
				yield i;
		})
	}

	/**
	 * Tworzy terator z wartościami z wielu zakresów [start,stop,step],...
	 * Jesli step ne zdefiniowany to step=1
	 *
	 * @param {Array} ranges [start,stop,step],...
	 * @returns {Iterator}
	 */
	static ranges(ranges) {
		return new Iterator(function* () {
			for (let j = 0; j < ranges.length; ++j) {
				let [start, stop, step] = ranges[j];

				if (step === undefined)
					step = 1;
				for (let i = start; i <= stop; i += step)
					yield i;
			}
		})
	}

	/**
	 * Tworzy Iterator z przemnorzenia przez siebie dwoch Iteratorow
	 *
	 * @param {Iterator} it1
	 * @param {Iterator} it2
	 * @returns {Iterator}
	 */
	static mul2(it1, it2) {
		return new Iterator(function* () {
			for (let i of it1._it())
				for (let j of it2._it())
					yield [i, j];
		})
	}

	/**
	 * Tworzy Iterator z wielu innych iteratorów
	 *
	 * @param {Array} iterators
	 * @returns {Iterator}
	 */
	static concat(...iterators) {
		return new Iterator(function* () {
			for (let iterator of iterators)
				yield* iterator._it();
		})
	}

	/**
	 * Zwraca Iterator przefiltrowanych wartości
	 *
	 * @param {function} callback (value,index)=>true|false
	 * @returns {Iterator}
	 */
	filter(callback) {
		const it = this._it;

		return new Iterator(function* () {
			let index = 0;
			for (let value of it())
				if (callback(value, index++))
					yield value;
		})
	}

	/**
	 * Zwraca Iterator nowych wartości
	 *
	 * @param {function} callback (value,index)=>new_value
	 * @returns {Iterator}
	 */
	map(callback) {
		const it = this._it;

		return new Iterator(function* () {
			let index = 0;
			for (let value of it()) {
				let res = callback(value, index++);
				yield res;
			}
		})
	}

	/**
	 * Szuka pierwszego elementu
	 *
	 * @param {function} callback (value,index)=>true|false
	 * @returns {object} Znaleziona wartość lub undefined jeśli brak
	 */
	find(callback) {
		let index = 0;
		for (let value of this._it())
			if (callback(value, index++))
				return value
	}

	/**
	 * Sprawdza czy wystepuje element który spelnia warunek
	 *
	 * @param {function} callback (value,index)=>true|false
	 * @returns true|false
	 */
	some(callback) {
		let index = 0;
		for (let value of this._it())
			if (callback(value, index++))
				return true
		return false
	}

	/**
	 * Przejście po wszystkich elementach i wywolanie callback
	 *
	 * @param {function} callback (value,index)=>{}
	 */
	forEach(callback) {
		let index = 0;
		for (let value of this._it())
			callback(value, index++);
	}

	/**
	 * Przeprowadzenie redukcji na this
	 *
	 * @param {function} callback (result,value,index)=>{res[]=value}
	 * @param {Object} res
	 * @returns {*} res
	 */
	reduce(callback, res) {
		let index = 0;
		for (let value of this._it())
			res = callback(res, value, index++);
		return res
	}

	/**
	 * Zamienia this na array
	 *
	 * @param {function|undefined} callback (value,index)=> wartość_do_array. Jeśli nie zdefiniowany to do array ladują kolejne elementy
	 * @returns {Array} 
	 */
	toArray(callback) {
		const res = [];

		if (callback) {
			let index = 0;
			for (let value of this._it())
				res.push(callback(value, index++));
		} else
			for (let value of this._it())
				res.push(value);
		return res
	}

	/**
	 * Zamienia this na Object
	 *
	 * @param {function} callback (result,value,index)=>{result[]=value}
	 * @returns {Object}
	 */
	toObject(callback) {
		const res = {};
		let index = 0;
		for (let value of this._it())
			callback(res, value, index++);
		return res
	}

	/**
	 * Zamienia this do Set
	 *
	 * @param {function} callback (result,value,index)=>{ result.set(value) }
	 * @returns {Set}
	 */
	toSet(callback) {
		const res = new Set();

		if (callback) {
			let index = 0;
			for (let value of this._it())
				callback(res, value, index++);
		} else
			for (let value of this._it())
				res.set(value);
		return res
	}

	/**
	 * Zamienia this do Map
	 *
	 * @param {function} callback (result,value,index)=>{result.add('jakiś klucz',value)}
	 * @returns {Map}
	 */
	toMap(callback) {
		let index = 0;
		const res = new Map();

		for (let value of this._it())
			callback(res, value, index++);
		return res
	}
}

/**
 * @param {any} s Sprawdzana wartość 
 * @returns {boolean} Czy s jest typu Array
 */
function isArray(s) {
	return typeof (s) === 'object' && (s instanceof Array)
}

/**
 * @param {any} s Sprawdzana wartość 
 * @returns {boolean} Czy s jest stringiem
 */
function isString(s) {
	return typeof (s) === 'string'
}

/**
 * @param {any} s Sprawdzana wartość 
 * @returns {boolean} Czy s jest funkcją
 */
function isFunction(s) {
	return typeof (s) === 'function'
}

/**
 * @param {any} s Sprawdzana wartość 
 * @returns {boolean} Czy s jest tak zwanym czystym objectem
 */
function isPlainObject(s) {
	if (Object.prototype.toString.call(s) !== '[object Object]')
		return false

	const prototype = Object.getPrototypeOf(s);
	return prototype === null || prototype === Object.prototype
}

/**
 * Płytkie łączenie objektu a1 i a2. 
 * Do objektu a1 dodawane są wszystkie elementy z a2 wartości z a2 nadpisują z a1 
 * @param {object} a1 objekt do którego zostanie włączony objekt a2
 * @param {object} a2 objekt włączany do a1
 */
function merge(a1, a2) {
	for (let i in a2)
		a1[i] = a2[i];
}

/**
 * Tworzy glęboką kopie plain objektu, jeśli są jakieś obiekty to dodaje ich referencje
 * @param {any} o Wartość do głębokiego kopiowania 
 * @returns {any} skopiowana wartość
 */
function deepCopy(o) {
	if (isArray(o)) {
		let oo = [];

		for (let i = 0; i < o.length; ++i)
			oo.push(deepCopy(o[i]));
		return oo
	}

	if (isPlainObject(o)) {
		let oo = {};

		for (let i in o)
			oo[i] = deepCopy(o[i]);
		return oo
	}

	return o
}

/**
 * Przepisuje wartości z src do desc robiąc kopie src
 * @param {object} dest 
 * @param {object} src 
 */
function deepMergeCopy(dest, src) {
	if (!isPlainObject(dest) || !isPlainObject(src))
		throw Error("Can't merge non plain objects")

	for (let i in src) {
		if ((i in dest) && isPlainObject(dest[i])) {
			deepMergeCopy(dest[i], src[i]);
			continue
		}
		dest[i] = deepCopy(src[i]);
	}
}

/**
 * Przepisuje wartosci z src do desc, jeśli to konieczne mieszając je
 * @param {object} dest 
 * @param {object} src 
 */
function deepMerge(dest, src) {
	if (!isPlainObject(dest) || !isPlainObject(src))
		throw Error("Can't merge non plain objects")

	for (let i in src) {
		if ((i in dest) && isPlainObject(dest[i])) {
			deepMerge(dest[i], src[i]);
			continue
		}
		dest[i] = src[i];
	}
}

/**
 * Pojaśnia pociemnia color o procent
 *
 * @param {string} color kolor do zmiany
 * @param {number} percent od -1 do 1
 * @returns {string} Wynikowy kolor
 */
function lightenDarkenColor(color, percent) {

	if (color[0] == "#") {
		color = color.slice(1);
	}

	const num = parseInt(color, 16);
	const bound = v => {
		if (v > 255) return 255
		if (v < 0) return 0
		return Math.round(v)
	};

	let r = num >> 16;
	let g = (num >> 8) & 0x00FF;
	let b = num & 0x0000FF;
	let fd = (v) => {
		if (percent > 0)
			return (255 - v) * percent
		return v * percent
	};

	r = bound(r + fd(r)).toString(16);
	g = bound(g + fd(g)).toString(16);
	b = bound(b + fd(b)).toString(16);

	if (r.length == 1)
		r = "0" + r;
	if (g.length == 1)
		g = "0" + g;
	if (b.length == 1)
		b = "0" + b;
	return "#" + r + g + b
}

function isAt(name) {
	return name.startsWith('@')
}

function isAtKeyFrames(name) {
	return name.startsWith('@keyframes')
}

function isAtFontFace(name) {
	return name.startsWith('@font-face')
}

/**
 * Znajduje wszystkie klucze w postaci '_dowolnytext' z wartoscią array funkcji i 
 * merguje wyniki wykonania funkcji z definitions
 * np:
 * Css.add({
 * 	_ala:[
 * 		settings=> { return { propName:'value' } }
 * 	]
 * })
 *
 * @param {object} definitions
 * @param {object} settings
 */
function compileFase0(definitions, settings) {
	const keys = Object.keys(definitions).filter(key => key.length >= 1 && key[0] === '_' && isArray(definitions[key]));

	keys.forEach(key => {
		const value = definitions[key];
		const res = {};

		delete definitions[key];
		value.forEach(f => merge(res, f(settings)));
		merge(definitions, res);
	});
}

/**
 * Pomoc przy compileFase1
 *
 * @param {*} mainD
 * @param {*} toCompile
 * @param {*} compiled
 * @returns
 */
function _compileFase1(mainD, toCompile, compiled, settings) {
	if (isPlainObject(toCompile)) {
		for (let i in toCompile)
			toCompile[i] = _compileFase1(mainD, toCompile[i], compiled, settings);
		return toCompile
	}

	if (!isArray(toCompile))
		return toCompile

	let result = {};

	for (let i = 0; i < toCompile.length;) {
		let e = toCompile[i];

		if (isFunction(e)) {
			const res = e(settings);

			if (isArray(res)) {
				toCompile.splice(i, 1, ...res);
				continue
			}

			e = res;
		}

		if (isString(e)) {
			const dotE = '.' + e;
			if (!(dotE in mainD))
				throw Error('Style not defined: ' + e)

			if (!compiled.has(e)) {
				compiled.add(e);
				mainD[dotE] = _compileFase1(mainD, mainD[dotE], compiled, settings);
			}
			deepMergeCopy(result, mainD[dotE]);
			++i;
			continue
		}

		if (isPlainObject(e)) {
			deepMergeCopy(result, _compileFase1(mainD, e, compiled, settings));
			++i;
			continue
		}

		throw Error('Merge style wrong type')
	}
	return result
}

/**
 * Znajduję [] w definicji na dowolnym poziomie i zamienia na {} zamieniajac kolejny element z [] i sklejając je ze sobą.
 * W Array mogą być {}, string ,function
 * {} - bez zmian sklejamy do wyniku  
 * string - po dodaniu . z przodu szukany w głownych kluczach i wstawianych w miejsce wystapienia 
 * 	np: 'blue' szukamy .blue znajdujemy zawartość {&:{p1:v1}} i string 'blue' zastępujemy {&:{p1:v1}}
 * function - jako parametr dostaje settings. Rezultat tej funkcji jest przetwarzany aż do uzyskania {}
 * 
 * {
 * 	.blue:{
 * 		&:{
 * 			p1:v1
 * 		}
 * 	},
 *  	.red:{
 * 		&{
 * 			p2:v2
 * 		}
 * 	},
 * 	.blueRedP3:[
 * 		blue,red,{
 * 			&:{
 * 				p3:v3
 * 			}
 * 		}
 * 	],
 * 	'.hover-blue':{
 * 		'&:hover':[
 * 			blue
 * 		]
 * 	}
 * }
 * zamienia na:
 * {
 * 	.blue:{
 * 		&:{
 * 			p1:v1
 * 		}
 * 	},
 *  	.red:{
 * 		&{
 * 			p2:v2
 * 		}
 * 	},
 * 	.blueRedP3:{
 * 		&:{
 * 			p1:v1,
 * 			p2:v2
 * 			p3:v3
 * 		}
 * 	},
 * 	'.hover-blue':{
 * 		'&:hover':{
 * 			&:{
 *		 			p1:v1
 * 			}
 * 		}
 * 	}
 * }
 *
 * @param {object} d
 * @returns {object} skompilowana definicja
 */
function compileFase1(d, settings) {
	let compiled = new Set();

	for (let name in d) {
		if (isAt(name))
			continue
		compiled.add(name);
		d[name] = _compileFase1(d, d[name], compiled, settings);
	}
	return d
}

/**
 * Zamienia 
 *{
 * 	{
 * 		'.&':{
 * 			'&:hover':{
 * 				name1:'value1'
 * 			}
 * 		}
 * 	}
 * }
 * zamienia na:
 * {
 * 	'.name:hover':{
 * 		name1:'value1'
 * 	}
 * }
 * 
 * gdzie parentName='name'
 * 
 * @param {object} d Definicja do wyplaszczenia
 * @param {string} parentName
 * @returns {object}
 */
function flat(d, parentName) {
	let result = {};

	for (let selector in d) {
		let value = d[selector];

		if (!isPlainObject(value)) {
			deepMerge(result, { [parentName]: { [selector]: value } });
			continue
		}

		let newSelector = selector.replace(/\&/g, parentName);
		let flatResult = flat(value, newSelector);
		deepMerge(result, flatResult);
	}
	return result
}

/**
 * Definicje z wieloma zagłębieniami {} zamienia na pojedyńcze zagłębienie.
 * Chodzi o 'wyplaszczenie' zaglebionych {}. 
 * Znak & w kluczach głębszych jest zastępowany wartością klucza wyższego.
 *
 * {
 * 	name:{
 * 		'.&':{
 * 			name1:'value1'
 * 		}
 * 	}
 * }
 * 
 * zamienia na:
 * 
 * {
 * 	'.name':{
 * 		name1:'value1'
 * 	}
 * }
 * 
 * @param {object} d Jest w postaci:
 * @returns {object} skompilowana definicja
 */
function compileFase2(d) {
	let result = {};

	for (let selector in d) {
		if (isAt(selector)) {
			result[selector] = d[selector];
			continue
		}

		let flatResult = flat(d[selector], selector);
		deepMerge(result, flatResult);
	}
	return result
}

/**
 * Zamienia { propName:propValue,... } na string
 *
 * @param {*} d
 * @param {*} settings
 * @returns {string} 
 */
function propsToString(d, settings) {
	let result = '';

	for (let propName in d) {
		let propValue = d[propName];

		if (typeof (propValue) === 'function')
			propValue = propValue(settings);

		if (propValue === undefined)
			continue

		result += propName + ':' + propValue + ';';
	}
	return result
}

/**
 * Zamienia {step1{prop:v1;prop:v2} ... } na string
 *
 * @param {object} d
 * @param {object} setting
 * @returns {string}
 */
function keyframes(d, setting) {
	let result = '';

	for (let step in d) {
		result += step + '{';

		for (let propName in d[step])
			result += propName + ':' + d[step][propName] + ';';
		result += '}';
	}
	return result
}

function fontFace(d, fontFamily, settings) {
	let result = '';

	result += 'font-family:' + fontFamily + ';\n';
	for (let propName in d) {
		let propValue = d[propName];

		if (typeof (propValue) === 'function')
			propValue = propValue(settings);

		if (propValue === undefined)
			continue

		result += propName + ':' + propValue + ';\n';
	}
	return result
}

/**
 * Definicje zamienia na string css.
 * Jeśli klucz zaczyna się od:
 * 	'@face-font dowolna_nazwa':
 * 		'@font-face dowolna_nazwa':{ prop:value,...} => '@font-face {font-family:dowolna_nazwa;  prop:value;...}'
 * 	'@':	
 * 		'@nazwa':{k:{prop:value,...},...} => '@nazwa{k {prop:value;...},...}'
 * 	pozostałe:
 * 		selector:{prop:value,...}  => 'selector{prop:value;...}'
 * Jeśli w powyższych przypadkach value jest funkcją to value w wyniku staje sie rezultatem wykonania tej funkcji z parametrem settings
 * 	selector:{prop:settings=>'val',...} => 'selector{prop:val;,...}
 *
 * @param {object} d Jest w postaci:
 * 	{
 * 		selector:{
 * 			propName:value;
 * 			...
 * 		},
 * 		...
 * 	}
 * @returns {string} skompilowana definicja 
 */
function compileFase3(d, settings) {
	let result = [];

	for (let selector in d)
		if (isAtKeyFrames(selector))
			result.push(selector + ' {' + keyframes(d[selector]) + '}');
		else
			if (isAtFontFace(selector)) {
				const fontFamily = selector.slice('@font-face '.length);

				result.push('@font-face {' + fontFace(d[selector], fontFamily, settings) + '}');
			} else
				result.push(selector + ' {' + propsToString(d[selector], settings) + '}');
	return result.join('\r\n')
}

/**
 * Kompiluje definicje i zamienia na string css
 * Kompilacja sklada się z 4 faz:
 * faza 0:
 *		Znajduje wszystkie klucze w postaci '_dowolnytext' z wartoscią array funkcji i 
 * 	merguje wyniki wykonania funkcji z definitions
 * 	np:
 * 	Css.add({
 * 		_ala:[
 * 			settings=> { return { propName:'value' } }
 * 		]
 * 	})
 * faza 1:
 * 	Znajduję [] w definicji na dowolnym poziomie i zamienia na {} zamieniajac kolejny element z [] i sklejając je ze sobą.
 *		W Array mogą być {}, string ,function
 * 	{} - bez zmian sklejamy do wyniku  
 * 	string - po dodaniu . z przodu szukany w głownych kluczach i wstawianych w miejsce wystapienia 
 * 		np: 'blue' szukamy .blue znajdujemy zawartość {&:{p1:v1}} i string 'blue' zastępujemy {&:{p1:v1}}
 * 	function - jako parametr dostaje settings. Rezultat tej funkcji jest przetwarzany aż do uzyskania {}
 * 	{
 * 		.blue:{
 * 			&:{
 * 				p1:v1
 * 			}
 * 		},
 *  		.red:{
 * 			&{
 * 				p2:v2
 * 			}
 * 		},
 * 		.blueRedP3:[
 * 			blue,red,{
 * 				&:{
 * 					p3:v3
 * 				}
 * 			}
 * 		],
 * 		'.hover-blue':{
 * 			'&:hover':[
 * 				blue
 * 			]
 * 		}
 * 	}
 * 	zamienia na:
 * 	{
 * 		.blue:{
 * 			&:{
 * 				p1:v1
 * 			}
 * 		},
 *  		.red:{
 * 			&{
 * 				p2:v2
 * 			}
 * 		},
 * 		.blueRedP3:{
 * 			&:{
 * 				p1:v1,
 * 				p2:v2
 * 				p3:v3
 * 			}
 * 		},
 * 		'.hover-blue':{
 * 			'&:hover':{
 * 				&:{
 *			 			p1:v1
 * 				}
 * 			}
 * 		}
 * 	}
 * faza 2:	
 * 	Definicje z wieloma zagłębieniami {} zamienia na pojedyńcze zagłębienie.
 * 	Chodzi o 'wyplaszczenie' zaglebionych {}. 
 * 	Znak & w kluczach głębszych jest zastępowany wartością klucza wyższego.
 *
 * 	{
 * 		name:{
 * 			'.&':{
 * 				name1:'value1'
 * 			}
 * 		}
 * 	}
 * 	zamienia na:
 * 	{
 * 		'.name':{
 * 			name1:'value1'
 * 		}
 * 	}
 * faza 3:
 * 	Definicje zamienia na string css.
 * 	Jeśli klucz zaczyna się od:
 * 		'@face-font dowolna_nazwa':
 * 			'@font-face dowolna_nazwa':{ prop:value,...} => '@font-face {font-family:dowolna_nazwa;  prop:value;...}'
 * 		'@':	
 * 			'@nazwa':{k:{prop:value,...},...} => '@nazwa{k {prop:value;...},...}'
 * 		pozostałe:
 * 			selector:{prop:value,...}  => 'selector{prop:value;...}'
 * 	Jeśli w powyższych przypadkach value jest funkcją to value w wyniku staje sie rezultatem wykonania tej funkcji z parametrem settings
 * 		selector:{prop:settings=>'val',...} => 'selector{prop:val;,...}
 *
 * Przykladowe możliwe użycia:
 *	'.blue': { 'p1': 'val_blue' }, 		// => '.blue {p1:val_blue;}'
 *	'.red': { 'p2': 'red' },				// => '.red {p2:red;}'
 *	'.green': { 'p3': 'val_green' },		// => '.green {p3:val_green;}'
 *	'.yellow': { 'p4': 'val_yellow' },	// => '.yellow {p4:val_yellow;}'
 *	'.orange': { 'p5': 'val_orange' },	// => '.orange {p5:val_orange;}'
 *	'.blueRed': [								
 *		//	=>	'.blueRed {p1:val_blue;p2:red;p5:v5;p6:v6;p3:val_green;p7:v7;}'
 *		//	=>	'.blueRed:hover {p6:v6;}'
 *		//	=>	'.blueRed:action {p5:val_orange;}'
 *		'blue', 'red',
 *		{
 *			'p5': 'v5'
 *		},
 *		{
 *			'&': {
 *				'p6': 'v6'
 *			}
 *		},
 *		{
 *			'&:hover': {
 *				'p6': 'v6'
 *			}
 *		},
 *		{
 *			'&:action': ['orange']
 *		},
 *		s => 'green',
 *		s => { return { 'p7': 'v7' } }
 *	],
 *	'fun': { 'p1': s => 'val1' },			// => 'fun {p1:val1;}'
 *	'qa': {										// => 'alaqaelaqaw {prop:val;}'
 *		'ala&ela&w': {
 *			'prop': 'val'
 *		}
 *	}
 * 
 * @param {object} definitions 
 * @param {object} settings
 * @returns {string} 
 */
function cssCompile(definitions, settings) {
	compileFase0(definitions, settings);
	definitions = compileFase1(definitions, settings);
	definitions = compileFase2(definitions);
	return compileFase3(definitions, settings)
}

/**
 * Wrapper cssCompile. 
 * Umożliwia gromadzenie nowych definicji oraz nowych ustawień do tych definicji.
 * Następnie poleceniem Css.addStyle() można wyrenderować wszystkie klasy css i dodać je do przeglądarki
 * @example
 * 	//Dodanie nowej klasy css
 * 	Css.add({
 * 		'.red':{color:s=>s.color.red}
 * 	})
 * 	//Dodanie ustawień do potrzebnych dla tej klasy css
 * 	Css.addSettings({
 * 		color:{red:'red'}
 * 	})
 * 	//Dodanie <style/> zawierającego wygenerowane klasy css do przegladarki
 * 	Css.addStyle()
 */
class Css {
	static #definitions = {}
	static #settings = {}
	static #style

	/**
	 * Wszystkie obecnie zgromadzone ustawiania
	 * @type object
	 * @readonly
	 */
	static get settings() { return Css.#settings }
	/**
	 * Wszystkie obecnie zgromadzone definicje
	 * @type object
	 * @readonly
	 */
	static get definitions() { return Css.#definitions }

	/**
	 * Dodanie nowej definicji Css. Format definicji z cssCompile
	 * @param {object} definitions Dodawana definicja
	 * @example
	 * 	Css.add({
	 * 		'.red':{color:'red'}
	 * 	})
	 */
	static add(definitions) {
		merge(Css.#definitions, definitions);
	}

	/**
	 * Dodanie nowych ustawień Css. 
	 * @param {object} settings 
	 */
	static addSettings(settings) {
		deepMergeCopy(Css.#settings, settings);
	}

	/**
	 * Definuje customElement
	 * @param {string} name Nazwa elemenu
	 * @param {true | new HTMLElement} definition  
	 */
	static customElements(name, definition = true) {
		customElements.define(name, definition === true ? class extends HTMLElement { } : definition);
	}

	/**
	 * Usuwa wyrenderowane klasy css z przeglądarki.
	 */
	static removeStyle() {
		if (!Css.#style)
			return

		document.body.removeChild(Css.#style);
		Css.#style = undefined;
	}

	/**
	 * Usuwa poprzednio wyrenderewane klasy css.
	 * Renderuje nowe i dodaje je do przegladarki
	 */
	static addStyle() {
		Css.removeStyle();

		let cssText = cssCompile(Css.#definitions, Css.#settings);
		let style = document.createElement('style', );
		style.setAttribute("id", "CssStaticLib");
		document.body.appendChild(style);

		if (style.styleSheet)
			style.styleSheet.cssText = cssText;
		else
			style.innerHTML = cssText;
		Css.#style = style;
	}
}

/** 
 misc:
	noselect -> { 'user-select': 'none' }
	nowrap -> { 'white-space': 'nowrap' }
	ellipsis -> { 'text-overflow': 'ellipsis' }
	border-box -> { box-sizing: 'border-box'}

	group-hor -> tworzy grupe horyzontalna. (Usuwa zaokroglenia po odpowiednich stronach dzieci)
	group-ver -> tworzy grupe wertykalną. (Usuwa zaokroglenia po odpowiednich stronach dzieci)

	direction-ltr -> { direction: 'ltr' }
	direction-rtl -> { direction: 'rtl' }

	resize-[none|both|horizontal|vertical]
	
	overflow-{visible|hidden|scroll|auto}
	overflow-x-{visible|hidden|scroll|auto}
	overflow-y-{visible|hidden|scroll|auto}

	position:
		{static|absolute|fixed|relative|sticky}
	display:
		{inline|block|contents|flex|grid|inline-block|inline-flex|inline-grid|inline-table|list-item|run-in|table|table-caption|table-column-group|table-header-group|table-footer-group|table-row-group|table-cell|table-column|table-row|none}
		hover-{inline|block|contents|flex|grid|inline-block|inline-flex|inline-grid|inline-table|list-item|run-in|table|table-caption|table-column-group|table-header-group|table-footer-group|table-row-group|table-cell|table-column|table-row|none}
	opacity:
		opacity-{0..100}
		opacity-hover-{0..100}
	cursor:
		cursor-{alias|all-scroll|auto|cell|context-menu|col-resize|copy|crosshair|default|e-resize|ew-resize|grab|grabbing|help|move|n-resize|ne-resize|nesw-resize|ns-resize|nw-resize|nwse-resize|no-drop|none|not-allowed|pointer|progress|row-resize|s-resize|se-resize|sw-resize|text|vertical-text|w-resize|wait|zoom-in|zoom-out|initial}
	width,height:
		width-{0..100|100..300/10|300..1000/50|-30..0|-300..-30/10|-1000..-300/50}
		min-width-{0..100|100..300/10|300..1000/50|-30..0|-300..-30/10|-1000..-300/50}
		max-width-{0..100|100..300/10|300..1000/50|-30..0|-300..-30/10|-1000..-300/50}
		height-{0..100|100..300/10|300..1000/50|-30..0|-300..-30/10|-1000..-300/50}
		min-height-{0..100|100..300/10|300..1000/50|-30..0|-300..-30/10|-1000..-300/50}
		max-height-{0..100|100..300/10|300..1000/50|-30..0|-300..-30/10|-1000..-300/50}
	move:
		{left|right|top|bottom}-p{-100..100/50} - pozycja w %
	z-index:
		z-index-{0..4}
	pointer-events:
		pointer-events-{auto|none|visiblePainted|visibleFill|visibleStroke|visible|painted|fill|stroke|all}
*/
function misc() {
	Css.add({
		'.noselect': { 'user-select': 'none' },
		'.nowrap': { 'white-space': 'nowrap' },
		'.ellipsis': { 'text-overflow': 'ellipsis' },
		'.border-box': { 'box-sizing': 'border-box' },
		'.group-hor > *': {
			'&:first-child:not(:last-child)': {
				'border-top-right-radius': '0px !important',
				'border-bottom-right-radius': '0px !important',
			},
			'&:not(:first-child):not(:last-child)': { 'border-radius': '0px !important' },
			'&:not(:first-child):last-child': {
				'border-top-left-radius': '0px !important',
				'border-bottom-left-radius': '0px !important',
			}
		},
		'.group-ver > *': {
			'&:first-child': {
				'border-bottom-right-radius': '0px !important',
				'border-bottom-left-radius': '0px !important',
			},
			'&:not(:first-child):not(:last-child)': { 'border-radius': '0px !important' },
			'&:last-child': {
				'border-top-left-radius': '0px !important',
				'border-top-right-radius': '0px !important',
			}
		},

		'.direction-ltr': { direction: 'ltr' },
		'.direction-rtl': { direction: 'rtl' },

		...Iterator.fromArray(['none', 'both', 'horizontal', 'vertical'])
			.toObject((res, v) => {
				res['.resize-' + v] = { resize: v };
			}),

		...Iterator.fromArray(['visible', 'hidden', 'scroll', 'auto'])
			.toObject((res, v) => {
				res['.overflow-' + v] = { 'overflow': v };
				res['.overflow-x-' + v] = { 'overflow-x': v };
				res['.overflow-y-' + v] = { 'overflow-y': v };
			}),

		...Iterator.fromArray(['static', 'absolute', 'fixed', 'relative', 'sticky'])
			.toObject((res, v) => res['.' + v] = { 'position': v }),

		...Iterator.fromArray(['inline', 'block', 'contents', 'flex', 'grid', 'inline-block', 'inline-flex', 'inline-grid', 'inline-table', 'list-item', 'run-in', 'table', 'table-caption', 'table-column-group', 'table-header-group', 'table-footer-group', 'table-row-group', 'table-cell', 'table-column', 'table-row', 'none'])
			.toObject((res, v) => {
				res['.' + v] = { 'display': v };
				res['.hover-' + v] = { '&:hover': { 'display': v } };
			}),

		...Iterator.count(101)
			.toObject((res, v) => {
				res['.opacity-' + v] = { 'opacity': v / 100 };
				res['.opacity-hover-' + v] = { '&:hover': { 'opacity': v / 100 } };
			}),

		...Iterator.fromArray(['alias', 'all-scroll', 'auto', 'cell', 'context-menu', 'col-resize', 'copy', 'crosshair', 'default', 'e-resize', 'ew-resize', 'grab', 'grabbing', 'help', 'move', 'n-resize', 'ne-resize', 'nesw-resize', 'ns-resize', 'nw-resize', 'nwse-resize', 'no-drop', 'none', 'not-allowed', 'pointer', 'progress', 'row-resize', 's-resize', 'se-resize', 'sw-resize', 'text', 'vertical-text', 'w-resize', 'wait', 'zoom-in', 'zoom-out', 'initial'])
			.toObject((res, v) => res['.cursor-' + v] = { 'cursor': v }),

		...Iterator.ranges([[0, 100], [100, 300, 10], [300, 1000, 50], [-30, 0], [-300, -30, 10], [-1000, -300, 50]])
			.toObject((res, k) => {
				const v = k + 'px';
				res['.width-' + k] = { 'width': v };
				res['.min-width-' + k] = { 'min-width': v };
				res['.max-width-' + k] = { 'max-width': v };
				res['.height-' + k] = { 'height': v };
				res['.min-height-' + k] = { 'min-height': v };
				res['.max-height-' + k] = { 'max-height': v };
			}),

		...Iterator.range(-100, 100, 50)
			.toObject((res, k) => {
				const v = k + '%';
				res['.left-' + k + 'p'] = { 'left': v };
				res['.right-' + k + 'p'] = { 'right': v };
				res['.top-' + k + 'p'] = { 'top': v };
				res['.bottom-' + k + 'p'] = { 'bottom': v };
			}),

		...Iterator.count(4)
			.toObject((res, v) => res['.z-index-' + v] = { 'z-index': v }),

		...Iterator.fromArray(['auto', 'none', 'visiblePainted', 'visibleFill', 'visibleStroke', 'visible', 'painted', 'fill', 'stroke', 'all'])
			.toObject((res, v) => {
				res['.pointer-events-' + v] = { 'pointer-events': v };
			})
	});
}

/** 
 animation:
	animation-{spin|fading|opacity|top|left|right|bottom|zoom|zoom-x|zoom-y|height}

	animation-delay-{0..20|20..100/5}
	animation-duration-{0..20|20..100/5}
	animation-iteration-count-{0..10}
	animation-direction-{normal|reverse|alternate|alternate-reverse}
	animation-timing-function-{linear|ease|ease-in|ease-out|ease-in-out}
	animation-fill-mode-{none|forwards|backwards|both}
*/
function animation() {
	Css.add({
		...Iterator.ranges([[0, 20, 1], [20, 100, 5]])
			.toObject((res, k) => {
				const v = (k / 10) + 's';
				res['.animation-delay-' + k] = { 'animation-delay': v };
				res['.animation-duration-' + k] = { 'animation-duration': v };
			}),

		...Iterator.count(11)
			.toObject((res, v) => res['.animation-iteration-count-' + v] = { 'animation-iteration-count': v }),

		...Iterator.fromArray(['normal', 'reverse', 'alternate', 'alternate-reverse'])
			.toObject((res, v) => res['.animation-direction-' + v] = { 'animation-direction': v }),

		...Iterator.fromArray(['linear', 'ease', 'ease-in', 'ease-out', 'ease-in-out'])
			.toObject((res, v) => res['.animation-timing-function-' + v] = { 'animation-timing-function': v }),

		...Iterator.fromArray(['none', 'forwards', 'backwards', 'both'])
			.toObject((res, v) => res['.animation-fill-mode-' + v] = { 'animation-fill-mode': v })
	});

	Css.add({
		'.animation-spin': { 'animation-name': 'animate-spin', 'animation-duration': '2s', ' animation-timing-function': 'linear' },
		'.animation-fading': { 'animation-name': 'animation-fading', 'animation-duration': '1s' },
		'.animation-opacity': { 'animation-name': 'animation-opacity', 'animation-duration': '0.8s' },
		'.animation-top': { 'position': 'relative', 'animation-name': 'animation-top', 'animation-duration': '0.4s' },
		'.animation-left': { 'position': 'relative', 'animation-name': 'animation-left', 'animation-duration': '0.4s' },
		'.animation-right': { 'position': 'relative', 'animation-name': 'animation-right', 'animation-duration': '0.4s' },
		'.animation-bottom': { 'position': 'relative', 'animation-name': 'animation-bottom', 'animation-duration': '0.4s' },
		'.animation-zoom': { 'animation-name': 'animation-zoom', 'animation-duration': '0.4s' },
		'.animation-zoom-x': { 'animation-name': 'animation-zoom-x', 'animation-duration': '0.4s' },
		'.animation-zoom-y': { 'animation-name': 'animation-zoom-y', 'animation-duration': '0.4s' },
		'.animation-height': { 'animation-name': 'animation-height', 'animation-duration': '1s' },

		'@keyframes animate-spin': {
			'0%': { 'transform': 'rotate(0deg)' },
			'100%': { 'transform': 'rotate(359deg)' }
		},
		'@keyframes animation-fading': {
			'0%': { 'opacity': 0 },
			'50%': { 'opacity': 1 },
			'100%': { 'opacity': 0 }
		},
		'@keyframes animation-opacity': {
			'from': { 'opacity': 0 },
			'to': { 'opacity': 1 }
		},
		'@keyframes animation-top': {
			'from': { 'top': '-300px', 'opacity': 0 },
			'to': { 'top': 0, 'opacity': 1 }
		},
		'@keyframes animation-left': {
			'from': { 'left': '-300px', 'opacity': 0 },
			'to': { 'left': 0, 'opacity': 1 }
		},
		'@keyframes animation-right': {
			'from': { 'right': '-300px', 'opacity': 0 },
			'to': { 'right': 0, 'opacity': 1 }
		},
		'@keyframes animation-bottom': {
			'from': { 'bottom': '-300px', 'opacity': 0 },
			'to': { 'bottom': 0, 'opacity': 1 }
		},
		'@keyframes animation-zoom': {
			'from': { 'transform': 'scale(0)' },
			'to': { 'transform': 'scale(1)' }
		},
		'@keyframes animation-zoom-x': {
			'from': { 'transform': 'scaleX(0)' },
			'to': { 'transform': 'scaleX(1)' }
		},
		'@keyframes animation-zoom-y': {
			'from': { 'transform': 'scaleY(0)' },
			'to': { 'transform': 'scaleY(1)' }
		},
		'@keyframes animation-height': {
			'from': { 'max-height': '0' },
			'to': { 'max-height': '300px' }
		}
	});
}

/**
	  absolute+menu:
		absolute[-{outer|center}]-{lt|mt|rt|lm|mm|rm|lb|mb|rb}
		absolute-menu[-{lt|rt|lb|rb}]
		menu[-{lt|rt|lb|rb}]
 */
function absolute() {
	Css.add({
		'.absolute-full': ['absolute', 'z-index-1', 'left-0p', 'top-0p', 'right-0p', 'bottom-0p'],
		'.absolute-lt': ['absolute', 'z-index-1', 'left-0p', 'top-0p'],
		'.absolute-mt': ['absolute', 'z-index-1', 'right-50p', 'top-0p', 'translate-50-0p'],
		'.absolute-rt': ['absolute', 'z-index-1', 'right-0p', 'top-0p'],
		'.absolute-lm': ['absolute', 'z-index-1', 'left-0p', 'bottom-50p', 'translate-0-50p'],
		'.absolute-mm': ['absolute', 'z-index-1', 'bottom-50p', 'translate-50-50p', 'right-50p'],
		'.absolute-rm': ['absolute', 'z-index-1', 'bottom-50p', 'translate-0-50p', 'right-0p'],
		'.absolute-lb': ['absolute', 'z-index-1', 'left-0p', 'bottom-0p'],
		'.absolute-mb': ['absolute', 'z-index-1', 'right-50p', 'bottom-0p', 'translate-50-0p'],
		'.absolute-rb': ['absolute', 'z-index-1', 'right-0p', 'bottom-0p'],
		'.absolute-outer-lt': ['absolute-lt', 'translate--100--100p'],
		'.absolute-outer-mt': ['absolute-mt', 'translate-50--100p'],
		'.absolute-outer-rt': ['absolute-rt', 'translate-100--100p'],
		'.absolute-outer-lm': ['absolute-lm', 'translate--100-50p'],
		'.absolute-outer-rm': ['absolute-rm', 'translate-100-50p', 'right-0p'],
		'.absolute-outer-lb': ['absolute-lb', 'translate--100-100p'],
		'.absolute-outer-mb': ['absolute-mb', 'translate-50-100p'],
		'.absolute-outer-rb': ['absolute-rb', 'translate-100-100p'],
		'.absolute-center-lt': ['absolute-lt', 'translate--50--50p'],
		'.absolute-center-mt': ['absolute-mt', 'translate-50--50p'],
		'.absolute-center-rt': ['absolute-rt', 'translate-50--50p'],
		'.absolute-center-lm': ['absolute-lm', 'translate--50-50p'],
		'.absolute-center-rm': ['absolute-rm', 'translate-50-50p'],
		'.absolute-center-lb': ['absolute-lb', 'translate--50-50p'],
		'.absolute-center-mb': ['absolute-mb', 'translate-50-50p'],
		'.absolute-center-rb': ['absolute-rb', 'translate-50-50p'],
		'.absolute-menu-lt': ['absolute-lt', 'translate--100-0p'],
		'.absolute-menu-rt': ['absolute-rt', 'translate-100-0p'],
		'.absolute-menu-lb': ['absolute-lb', 'translate-0-100p'],
		'.absolute-menu-rb': ['absolute-rb', 'translate-0-100p'],
		'.menu': ['none', { '*:hover > &': ['block'] }],
		'.menu-lt': ['menu', 'absolute-menu-lt'],
		'.menu-rt': ['menu', 'absolute-menu-rt'],
		'.menu-lb': ['menu', 'absolute-menu-lb'],
		'.menu-rb': ['menu', 'absolute-menu-rb']
	});
}

/** 
	Border:
		br-circle - 'border-radius': '50%'
		br-{dotted|dashed|solid|double|groove|ridge|inset|outset|none|hidden} - border-style
		br-top-{dotted|dashed|solid|double|groove|ridge|inset|outset|none|hidden} - border-top-style
		br-bottom-{dotted|dashed|solid|double|groove|ridge|inset|outset|none|hidden} - border-bottom-style
		br-left-{dotted|dashed|solid|double|groove|ridge|inset|outset|none|hidden} - border-left-style
		br-{0..64} - border-width
		br-[{tl|tr|bl|br}-]radius-{0..64} - border-radius
		o-{dotted|dashed|solid|double|groove|ridge|inset|outset|none|hidden} - outline-style
		o-{0..64} - outline-width
		o-offset-{0..64} - outline-offset
		om-{0..64} - o-offset-x + m-x
*/
function border() {
	Css.add({
		'.br-circle': { 'border-radius': '50%' },
		...Iterator.fromArray(['dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', 'none', 'hidden'])
			.toObject((res, v) => {
				res['.br-' + v] = { 'border-style': v };
				res['.br-top-' + v] = { 'border-top-style': v };
				res['.br-bottom-' + v] = { 'border-bottom-style': v };
				res['.br-right-' + v] = { 'border-right-style': v };
				res['.br-left-' + v] = { 'border-left-style': v };
				res['.o-' + v] = { 'outline-style': v };
			}),
		...Iterator.count(65)
			.toObject((res, k) => {
				const v = k + 'px';
				res['.br-' + k] = { 'border-width': v };
				res['.br-radius-' + k] = { 'border-radius': v };
				res['.br-tl-radius-' + k] = { 'border-top-left-radius': v };
				res['.br-tr-radius-' + k] = { 'border-top-right-radius': v };
				res['.br-bl-radius-' + k] = { 'border-bottom-left-radius': v };
				res['.br-br-radius-' + k] = { 'border-bottom-right-radius': v };
				res['.o-' + k] = { 'outline-width': v };
				res['.o-offset-' + k] = { 'outline-offset': v };
				res['.om-' + k] = ['o-offset-' + k, 'm-' + k];
			})
	});
}

/**
	margin:
		m-{0..64} - margin
		m-hover--{0..64} - :hover margin
		m-top-{0..64} - margin-top
		m-bottom-{0..64} - margin-bottom
		m-left-{0..64} - margin-left
		m-right-{0..64} - margin-right
		m-lr-{0..64} - m-right-x i 'm-left-x
		m-tb-{0..64} - m-top-x i m-bottom-x
 */
function margin() {
	Css.add({
		...Iterator.count(65)
			.toObject((res, k) => {
				const v = k + 'px';
				res['.m-' + k] = { 'margin': v };
				res['.m-hover-' + k] = { '&:hover': { 'margin': v } };
				res['.m-top-' + k] = { 'margin-top': v };
				res['.m-bottom-' + k] = { 'margin-bottom': v };
				res['.m-left-' + k] = { 'margin-left': v };
				res['.m-right-' + k] = { 'margin-right': v };
				res['.m-lr-' + k] = ['m-right-' + k, 'm-left-' + k];
				res['.m-tb-' + k] = ['m-top-' + k, 'm-bottom-' + k];
			})

	});
}

/**
	padding:
		p-{0..64} - padding
		p-hover--{0..64} - :hover padding
		p-top-{0..64} - padding-top
		p-bottom-{0..64} - padding-bottom
		p-left-{0..64} - padding-left
		p-right-{0..64} - padding-right
		p-lr-{0..64} - p-right-x i 'p-left-x
		p-tb-{0..64} - p-top-x i p-bottom-x
 */
function padding() {
	Css.add({
		...Iterator.count(65)
			.toObject((res, k) => {
				const v = k + 'px';
				res['.p-' + k] = { 'padding': v };
				res['.p-hover-' + k] = { '&:hover': { 'padding': v } };
				res['.p-top-' + k] = { 'padding-top': v };
				res['.p-bottom-' + k] = { 'padding-bottom': v };
				res['.p-left-' + k] = { 'padding-left': v };
				res['.p-right-' + k] = { 'padding-right': v };
				res['.p-lr-' + k] = ['p-right-' + k, 'p-left-' + k];
				res['.p-tb-' + k] = ['p-top-' + k, 'p-bottom-' + k];
			})
	});
}

/**
	transition:
		transition-{0..19|20..100/5} - transition: (v / 10) + 's'
		transition-{linear|ease|ease-in|ease-out|ease-in-out} - transition-timing-function
 */
function transition() {
	Css.add({
		...Iterator.ranges([[0, 20, 1], [20, 100, 5]])
			.toObject((res, v) => res['.transition-' + v] = { 'transition': (v / 10) + 's' }),
		...Iterator.fromArray(['linear', 'ease', 'ease-in', 'ease-out', 'ease-in-out'])
			.toObject((res, v) => res['.transition-' + v] = { 'transition-timing-function': v }),
	});
}

/**
box-shadow:
	bs-none - box-shadow: 'none'
	bs-hover-none - :hover box-shadow: 'none'
	bs-{0..32} - box-shadow 0 ${k}px ${k * 2}px 0 rgba(0,0,0,0.2)
	bs-hover-{0..32} - :hover box-shadow 0 ${k}px ${k * 2}px 0 rgba(0,0,0,0.2)
 */
function boxShadow() {
	Css.add({
		'.bs-none': { 'box-shadow': 'none' },
		'.bs-hover-none': { '&:hover': { 'box-shadow': 'none' } },
		...Iterator.count(33)
			.toObject((res, k) => {
				const v = `0 ${k}px ${k * 2}px 0 rgba(0,0,0,0.2);`;
				res['.bs-' + k] = { 'box-shadow': v };
				res['.bs-hover-' + k] = { '&:hover': { 'box-shadow': v } };
			})
	});
}

/** 
	text
		text-decoration-{'none|underline|overline|line-through} - text-decoration-line
		text-decoration-{solid|double|dotted|dashed|wavy} - text-decoration-style
		text-align-{left|right|center|justify} - text-align
	letter-spacing:
		letter-spacing[-hover]-{0..64} - letter-spacing
	vertical-align: 
		vertical-align-{baseline|length|sub|super|top|text-top|middle|bottom|text-bottom} - vertical-align
*/
function text() {
	Css.add({
		...Iterator.fromArray(['none', 'underline', 'overline', 'line-through'])
			.toObject((res, v) => res['.text-decoration-' + v] = { 'text-decoration-line': v }),
		...Iterator.fromArray(['solid', 'double', 'dotted', 'dashed', 'wavy'])
			.toObject((res, v) => res['.text-decoration-' + v] = { 'text-decoration-style': v }),
		...Iterator.fromArray(['left', 'right', 'center', 'justify'])
			.toObject((res, v) => res['.text-align-' + v] = { 'text-align': v }),
		...Iterator.count(65)
			.toObject((res, k) => {
				const v = k + 'px';
				res['.letter-spacing-' + k] = { 'letter-spacing': v };
				res['.letter-spacing-hover-' + k] = { '&:hover': { 'letter-spacing': v } };
			}),
		...Iterator.fromArray(['baseline', 'length', 'sub', 'super', 'top', 'text-top', 'middle', 'bottom', 'text-bottom'])
			.toObject((res, v) => {
				res['.vertical-align-' + v] = { 'vertical-align': v };
			})
	});
}

/** 
	font:
		f-{serif|sans-serif|cursive|monospace|verdana|segoe-ui|helvetica|geneva|tahoma} - font-family
		f-weight-{normal|lighter|bold|100|200|300|400|500|600|700|800|900} - font-weight
		f-{0..64} - font-size
		f-style-{normal|italic|oblique} - font-style
*/
function font() {
	Css.add({
		...Iterator.fromObject({
			'serif': 'serif',
			'sans-serif': 'sans-serif',
			'cursive': 'cursive',
			'monospace': 'monospace',
			'verdana': 'Verdana,sans-serif',
			'segoe-ui': 'Segoe UI, sans-serif',
			'helvetica': 'Helvetica, Arial, sans-serif',
			'geneva': 'Geneva, Verdana, sans-serif',
			'tahoma': 'Tahoma, sans-serif'
		})
			.toObject((res, [k, v]) => res['.f-' + k] = { 'font-family': v }),

		...Iterator.fromArray(['normal', 'lighter', 'bold'])
			.toObject((res, v) => res['.f-weight-' + v] = { 'font-weight': v }),

		...Iterator.range(100, 900, 100)
			.toObject((res, v) => res['.f-weight-' + v] = { 'font-weight': v }),

		...Iterator.count(65)
			.toObject((res, v) => res['.f-' + v] = { 'font-size': v + 'px' }),

		...Iterator.fromArray(['normal', 'italic', 'oblique'])
			.toObject((res, v) => res['.f-style-' + v] = { 'font-style': v })
	});
}

/** 
	grid:	
		grid-{hor|ver} -  'display': 'grid', 'grid-auto-flow': 'column' i  'display': 'grid', 'grid-auto-flow': 'row'
		grid-gap[x|y]-{0..64} - grid-gap
		grid-ai[x|y]-{stretch|center|start|end} - justify-items i align-items
		grid-a[x|y]-{stretch|center|start|end} - justify-self i align-self
		grid-ac[x|y]-{stretch|center|start|end|space-between|space-around|space-evenly} - justify-content i align-self
		grid-auto[x|y]-{0..64} - grid-auto-columns i grid-auto-rows
		grid-col-{0..12} - grid-template-columns: `repeat(${v},auto)
		grid-col-start-{-10..10} - grid-column-start
		grid-col-end-{-10..10} - grid-column-end
		grid-row-start-{-10..10} - grid-row-start
		grid-row-end-{-10..10} - grid-row-end
		grid-col-start-span-{-10..10} - grid-column-start span 
		grid-col-end-span-{-10..10} - grid-column-end span 
		grid-row-start-span-{-10..10} - grid-row-start span 
		grid-row-end-span-{-10..10} - grid-row-end span 
		grid-full-row - ['grid-col-start-1', 'grid-col-end--1']
*/
function grid() {
	Css.add({
		'.grid-hor': { 'display': 'grid', 'grid-auto-flow': 'column' },
		'.grid-ver': { 'display': 'grid', 'grid-auto-flow': 'row' },

		...Iterator.count(65)
			.toObject((res, k) => {
				const v = k + 'px';
				res['.grid-gapx-' + k] = { 'grid-column-gap': v };
				res['.grid-gapy-' + k] = { 'grid-row-gap': v };
				res['.grid-gap-' + k] = { 'grid-gap': v };
				res['.grid-autox-' + k] = { 'grid-auto-columns': v };
				res['.grid-autoy-' + k] = { 'grid-auto-rows': v };
			}),

		...Iterator.fromArray(['stretch', 'center', 'start', 'end'])
			.toObject((res, v) => {
				res['.grid-aix-' + v] = { 'justify-items': v };
				res['.grid-aiy-' + v] = { 'align-items': v };
				res['.grid-ax-' + v] = { 'justify-self': v };
				res['.grid-ay-' + v] = { 'align-self': v };
			}),

		...Iterator.fromArray(['stretch', 'center', 'start', 'end', 'space-between', 'space-around', 'space-evenly'])
			.toObject((res, v) => {
				res['.grid-acx-' + v] = { 'justify-content': v };
				res['.grid-acy-' + v] = { 'align-content': v };
			}),

		...Iterator.count(13)
			.toObject((res, v) => res['.grid-col-' + v] = { 'grid-template-columns': `repeat(${v},auto)` }),

		...Iterator.range(-10, 10)
			.toObject((res, v) => {
				res['.grid-col-start-' + v] = { 'grid-column-start': v };
				res['.grid-col-end-' + v] = { 'grid-column-end': v };
				res['.grid-row-start-' + v] = { 'grid-row-start': v };
				res['.grid-row-end-' + v] = { 'grid-row-end': v };
			}),

		...Iterator.range(-10, 10)
			.toObject((res, v) => {
				res['.grid-col-start-span-' + v] = { 'grid-column-start': 'span ' + v };
				res['.grid-col-end-span-' + v] = { 'grid-column-end': 'span ' + v };
				res['.grid-row-start-span-' + v] = { 'grid-row-start': 'span ' + v };
				res['.grid-row-end-span-' + v] = { 'grid-row-end': 'span ' + v };
			}),
		'.grid-full-row': ['grid-col-start-1', 'grid-col-end--1']
	});
}

/**
	flex-basis-{0..30|30..300/5|300..1000/50} - flex-basis
	flex-{row|row-reverse|column|column-reverse} - flex-direction
	flex-grow-{0..10} - flex-grow
	flex-shrink-{0..10} - flex-shrink
	flex-wrap-{nowrap|wrap|wrap-reverse} - flex-wrap
	flex-gap-{0..64} - flex-gap
 */
function flex() {
	Css.add({
		...Iterator.ranges([[0, 30], [30, 300, 5], [300, 1000, 50]])
			.toObject((res, v) => {
				res['.flex-basis-' + v] = { 'flex-basis': v + 'px' };
			}),
		...Iterator.fromArray(['row', 'row-reverse', 'column', 'column-reverse'])
			.toObject((res, v) => {
				res['.flex-' + v] = { 'flex-direction': v };
			}),
		...Iterator.count(11)
			.toObject((res, v) => {
				res['.flex-grow-' + v] = { 'flex-grow': v };
				res['.flex-shrink-' + v] = { 'flex-shrink': v };
			}),
		...Iterator.fromArray(['nowrap', 'wrap', 'wrap-reverse'])
			.toObject((res, v) => {
				res['.flex-' + v] = { 'flex-wrap': v };
			}),
		...Iterator.count(65)
			.toObject((res, v) => {
				res['.flex-gap-' + v] = { 'gap': v + 'px' };
			})
	});
}

/** 
	modify:
		rotate-{0..355/5} - transform:rotate(${k}deg)
		rotate-hover-{0..355/5} - :hover transform:rotate(${k}deg)
		scale-{0..100/5|100..1000/50} - transform: scale(${k / 100})
		scale-hover-{0..100/5|100..1000/50} - :hover transform: scale(${k / 100})
		origin-{lt|lc|lb|ct|cc|cb|rt|rc|rb} - transform-origin
		scale-{x|y}-{0..100/5|100..1000/50} - transform: scaleX(${k / 100}) i transform: scaleY(${k / 100})
		scale-{x|y}-hover-{0..100/5|100..1000/50} - :hover transform: scaleX(${k / 100}) i transform: scaleY(${k / 100})
		translate-{-100..100/50}-{-100..100/50} - transform: `translate(${x}%,${y}%)
		translate-[X|Y|XY|_XY]-{0..30|30..300/10|300..1000/50|-30..0|-300..-30/10|-1000..-300/50} - transform: translateX(${v}px) i transform: translateY(${v}px) i transform: translate(${v}px,${v}px) i transform: translate(${-v}px,${v}px)
*/
function modify() {
	Css.add({
		...Iterator.range(0, 360, 5)
			.toObject((res, k) => {
				const v = `rotate(${k}deg)`;
				res['.rotate-' + k] = { 'transform': v };
				res['.rotate-hover-' + k] = { '&:hover': { 'transform': v } };
			}),

		...Iterator.fromObject({
			'lt': 'left top', 'lc': 'left center', 'lb': 'left bottom',
			'ct': 'center top', 'cc': 'center center', 'cb': 'center bottom',
			'rt': 'right top', 'rc': 'right center', 'rb': 'right bottom'
		})
			.toObject((res, [k, v]) => res['.origin-' + k] = { 'transform-origin': v }),

		...Iterator.ranges([[0, 100, 5], [100, 1000, 50]])
			.toObject((res, k) => {
				let v = `scale(${k / 100})`;
				res['.scale-' + k] = { 'transform': v };
				res['.scale-hover-' + k] = { '&:hover': { 'transform': v } };
				v = `scaleX(${k / 100})`;
				res['.scale-x-' + k] = { 'transform': v };
				res['.scale-x-hover-' + k] = { '&:hover': { 'transform': v } };
				v = `scaleY(${k / 100})`;
				res['.scale-y-' + k] = { 'transform': v };
				res['.scale-y-hover-' + k] = { '&:hover': { 'transform': v } };
			}),

		...Iterator.mul2(Iterator.range(-100, 100, 50), Iterator.range(-100, 100, 50))
			.toObject((res, [x, y]) => res[`.translate-${x}-${y}p`] = { 'transform': `translate(${x}%,${y}%)` }),

		...Iterator.ranges([[0, 30], [30, 300, 10], [300, 1000, 50], [-30, 0], [-300, -30, 10], [-1000, -300, 50]])
			.toObject((res, v) => {
				res['.translateX-' + v] = { 'transform': `translateX(${v}px)` };
				res['.translateY-' + v] = { 'transform': `translateY(${v}px)` };
				res['.translateXY-' + v] = { 'transform': `translate(${v}px,${v}px)` };
				res['.translate_XY-' + v] = { 'transform': `translate(${-v}px,${v}px)` };
			})
	});
}

/** 
	filter:
		filter-blur - filter: blur(${k}px)
		filter[-hover]-{brightness|contrast}-{0..100/10|100..1000/10} - filter: brightness(${k}%) i filter: contrast(${k}%)
		filter[-hover]-{grayscale|invert|opacity|saturate|sepia}-{0..100/10} - filter: rodzaj(${k}%)
		filter[-hover]-hover-hue-rotate-{0..360/10} - filter: saturate(${k}%)
*/
function filter() {
	Css.add({
		...Iterator.count(21)
			.toObject((res, k) => {
				const v = `blur(${k}px)`;
				res['.filter-blur-' + k] = { 'filter': v };
				res['.filter-hover-blur-' + k] = { '&:hover': { 'filter': v } };
			}),

		...Iterator.ranges([[0, 100, 10], [100, 1000, 10]])
			.toObject((res, k) => {
				let v = `brightness(${k}%)`;
				res['.filter-brightness-' + k] = { 'filter': v };
				res['.filter-hover-brightness-' + k] = { '&:hover': { 'filter': v } };
				v = `contrast(${k}%)`;
				res['.filter-contrast-' + k] = { 'filter': v };
				res['.filter-hover-contrast-' + k] = { '&:hover': { 'filter': v } };
				v = `grayscale(${k}%)`;
				res['.filter-grayscale-' + k] = { 'filter': v };
				res['.filter-hover-grayscale-' + k] = { '&:hover': { 'filter': v } };
				v = `invert(${k}%)`;
				res['.filter-invert-' + k] = { 'filter': v };
				res['.filter-hover-invert-' + k] = { '&:hover': { 'filter': v } };
				v = `opacity(${k}%)`;
				res['.filter-opacity-' + k] = { 'filter': v };
				res['.filter-hover-opacity-' + k] = { '&:hover': { 'filter': v } };
				v = `saturate(${k}%)`;
				res['.filter-saturate-' + k] = { 'filter': v };
				res['.filter-hover-saturate-' + k] = { '&:hover': { 'filter': v } };
				v = `sepia(${k}%)`;
				res['.filter-sepia-' + k] = { 'filter': v };
				res['.filter-hover-sepia-' + k] = { '&:hover': { 'filter': v } };
			}),

		...Iterator.range(0, 360, 10)
			.toObject((res, k) => {
				const v = `hue-rotate(${k}deg)`;
				res['.filter-hue-rotate-' + k] = { 'filter': v };
				res['.filter-hover-hue-rotate-' + k] = { '&:hover': { 'filter': v } };
			}),
	});
}

function base() {
	misc();
	animation();
	absolute();
	border();
	margin();
	padding();
	transition();
	boxShadow();
	text();
	font();
	grid();
	flex();
	modify();
	filter();
}

//{pink|magenta|purple|red|orange|yellow|green|cyan|blue|brown|white|grey|black}

function getColors(isFullColors) {
	const _colors = {
		pink: '#FFC0CB',
		magenta: '#FF00FF',
		purple: '#800080',
		red: '#FF0000',
		orange: '#FFA500',
		yellow: '#FFFF00',
		green: '#008000',
		cyan: '#00FFFF',
		blue: '#0000FF',
		brown: '#A52A2A',
		white: '#ffffff',
		grey: '#000000',
		black: '#000000'
	};

	const colors = {};

	Object.keys(_colors).forEach(colorName => {
		const color = _colors[colorName];
		let i;

		colors[colorName] = colorName;

		if (colorName === 'black' || colorName === 'white' || !isFullColors)
			return

		if (colorName !== 'grey')
			for (i = 9; i >= 1; --i)
				colors[colorName + '--' + i] = lightenDarkenColor(color, -i * 0.1);
		for (i = 1; i <= 9; ++i)
			colors[colorName + '-' + i] = lightenDarkenColor(color, i * 0.1);
	});

	return colors
}

/** 
	colors
		{colors}	- background-color
		hover-{colors}	- :hover background-color
		text[-hover]-{colors} - color i :hover color
		br[-hover]-{colors} - border color i :hover border color
		text-decoration-{colors} - text-decoration-color
		o[-hover]-{colors} - outline-color i :hover outline-color
*/
function _colors(colors) {
	Css.add({
		'.br-transparent':{'border-color':'transparent'},
		'.transparent':{'background-color':'transparent'},
		...Iterator.fromObject(colors)
			.toObject((res, [k, v]) => {
				res['.' + k] = { 'background-color': v };
				res['.hover-' + k] = { '&:hover': { 'background-color': v } };
				res['.text-' + k] = { 'color': v };
				res['.text-hover-' + k] = { '&:hover': { 'color': v } };
				res['.br-' + k] = { 'border-color': v };
				res['.br-hover-' + k] = { '&:hover': { 'border-color': v } };
				res['.text-decoration-' + k] = { 'text-decoration-color': v };
				res['.o-' + k] = { 'outline-color': v };
				res['.o-hover-' + k] = { '&:hover': { 'outline-color': v } };
			})
	});
}

function colors() {
	return _colors(getColors())
}

/**
 * fi-google - Font-face google-icons
 */
function fontGoogle() {
	Css.add({
		//Font do tworzenia icon
		//orginalny link <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
		'@font-face google-icons': {
			src: 'url(https://fonts.gstatic.com/s/materialicons/v85/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format("woff2")'
		},
		'.fi-google': { 'font-family': 'google-icons' },
	});
}

/**
 * Inicjacja podstawowych pluginów bibloteki 
 */
base();
colors();
fontGoogle();

export { Css, Iterator, cssCompile };


