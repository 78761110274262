import { Logotype } from 'components/icon/NewIcons';
import React from 'react'
import Preloader from '../preloader/Preloader'
import { StyledLoginAside, StyledLoginWrapper, StyledFormWrapper, } from './Login.styled'

const LoginLayout = ({ children }) => {
    return (
		<Preloader>
			<StyledLoginWrapper>
				<StyledLoginAside>
					<Logotype/>
					<h3> Translation Management System</h3>
				</StyledLoginAside>
				<StyledFormWrapper>
					{children}
				</StyledFormWrapper>
			</StyledLoginWrapper>
		</Preloader>
	)
};

export default LoginLayout;