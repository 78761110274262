/**
 * @module services/CatOnlineService
 */

import HttpService from 'services/HttpService';
import config from 'config';
import ResourceDocument from 'contents/cat/model/ResourceDocument';

class CatOnlineService {
	constructor(httpService, config = {}) {
		this._config = {
			authToken: null,
			...config
		};

		this._httpService = httpService;
	}

	/**
	 * @param {string} taskToken 
	 */
	async authenticateTaskAccess(taskToken) {
		try {
			const results = await this._httpService.sendRequest(
				`/cat/auth/tasktoken/${taskToken}`,
				'POST',
				null,
				this.defaultRequestConfig);

			return await this.fromJsonResponseObject(results);
		}
		catch (err) {
			this.printErrorsToConsole(err)
			throw err
		}
	}

	async getTaskBasedOnAuthToken() {
		try {
			const results = await this._httpService.sendRequest(
				`/cat/auth/task`,
				'GET',
				null,
				this.defaultRequestConfig);

			return await this.fromJsonResponseObject(results);
		}
		catch (err) {
			this.printErrorsToConsole(err)
			throw err
		}
	}

	async getLanguageDocuments() {
		return await this._sendRequest(
			`/cat/languagedocuments?limit=2000`,
			'GET',
			null);
	}

	async getTranslationUnits(languageDocumentId, paginationOptions, filters, findSearch) {
		paginationOptions = this.createDefaultPaginationOptions(paginationOptions)
		const filtersQs = this.createTranslationUnitsFilterQueryParams(filters);
		const findSearchQs = this.createTranslationUnitsFindSearchQueryParams(findSearch);

		let url = `/cat/languagedocuments/${languageDocumentId}/translationunits?limit=${paginationOptions.limit}&page=${paginationOptions.page}&allowPageChange=${paginationOptions.allowPageChange}&currentPage=${paginationOptions.clientCurrentPage}`;
		if(filtersQs !== '')
		{
			url += `&${filtersQs}`;
		}
		if(findSearchQs !== '')
		{
			url += `&${findSearchQs}`;
		}
		if(paginationOptions.mergedDocumentId)
		{
			url += `&mergedDocumentId=${paginationOptions.mergedDocumentId}`;
		}
		else if(paginationOptions.sequenceIndex)
		{
			url += `&sequenceIndex=${paginationOptions.sequenceIndex}`;
		}
		else if(paginationOptions.nextTuToConfirm)
		{
			url += `&nextTuToConfirm=${paginationOptions.nextTuToConfirm}`;
		}

		return await this._sendRequest(
			url,
			'GET',
			null);
	}

	async getDocumentComments(languageDocumentId) {
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/comments`,
			'GET',
			null);
	}

	async createDocumentComment(languageDocumentId, resource)
	{
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/comments`,
			'POST',
			resource);
	}

	async deleteDocumentComment(languageDocumentId, commentId)
	{
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/comments/${commentId}`,
			'DELETE');
	}

	async deleteDocumentComments(languageDocumentId)
	{
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/comments`,
			'DELETE');
	}

	createTranslationUnitsFilterQueryParams(filters) 
	{
		if(filters == null)
			return '';

		let qs = [];
		if(filters.qaFilters?.selectedQaChecks)
		{
			for(let i = 0; i < filters.qaFilters.selectedQaChecks.length; i++)
			{
				const qaCheck = filters.qaFilters.selectedQaChecks[i];
				qs.push(`qaChecks=${qaCheck}`);
			}

			if(filters.qaFilters.numberOfSegmentsInContext)
			{
				qs.push(`segmentsInContext=${filters.qaFilters.numberOfSegmentsInContext}`);
			}
		}

		if(filters.qeFilters?.minScore != null)
			qs.push(`QeScore.Min=${filters.qeFilters.minScore}`);

		if(filters.qeFilters?.maxScore != null)
			qs.push(`QeScore.Max=${filters.qeFilters.maxScore}`);

		if(filters.qeFilters?.orderByScore)
		{
			qs.push(`OrderBy.Field=QeScore`);
			qs.push(`OrderBy.Dir=${filters.qeFilters.orderByScore === 'ASC' ? 0 : 1}`);
		}

		if(filters.segmentsWithTerminology)
		{
			qs.push(`SegmentsWithTerminology=true`);
		}

		return qs.join("&");
	}

	createTranslationUnitsFindSearchQueryParams(findSearch)
	{
		if(findSearch?.findWhat == null || findSearch.findWhat === '')
			return '';

		let qs = [];
		qs.push(`Find.Value=${encodeURIComponent(findSearch.findWhat)}`);
		if(!findSearch.findInSource)
			qs.push(`Find.Segment=${findSearch.findInSource ? 0 : 1}`);
		if(findSearch.matchCase)
			qs.push(`Find.MatchCase=${findSearch.matchCase}`);
		if(findSearch.matchWholeWord)
			qs.push(`Find.MatchWholeWord=${findSearch.matchWholeWord}`);

		if(findSearch.replaceWith != null)
			qs.push(`Find.ReplaceValue=${findSearch.replaceWith}`);

		return qs.join("&");
	}

	async updateTask(projectId, taskId, entity) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/tasks/${taskId}`,
			'PATCH',
			entity);
	}

	async updateTranslationUnits(languageDocumentId, resource) {
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/translationunits`,
			'PATCH',
			resource);
    }

	async updateTranslationUnitsReplace(languageDocumentId, resource, paginationOptions, filters, findSearch, replaceAll)
	{
		paginationOptions = this.createDefaultPaginationOptions(paginationOptions)
		const filtersQs = this.createTranslationUnitsFilterQueryParams(filters);
		const findSearchQs = this.createTranslationUnitsFindSearchQueryParams(findSearch);

		let url = `/cat/languagedocuments/${languageDocumentId}/translationunits/replace` + 
			`?limit=${paginationOptions.limit}&page=${paginationOptions.page}&allowPageChange=${paginationOptions.allowPageChange}` + 
			`&${filtersQs}&currentPage=${paginationOptions.clientCurrentPage}&Find.ReplaceAll=${replaceAll}`;
		if(filtersQs !== '')
		{
			url += `&${filtersQs}`;
		}
		if(findSearchQs !== '')
		{
			url += `&${findSearchQs}`;
		}
		return await this._sendRequest(
			url,
			'PATCH',
			resource);
	}

	async confirmAllSegmentsInDocument(languageDocumentId, confirmationLevel) {
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/translationunits/confirmationforall/${confirmationLevel}`,
			'PATCH');
    }

	async approveChangeTrackingChangesInDocument(languageDocumentId) {
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/translationunits/changetracking/approve`,
			'PATCH');
    }

	async discardChangeTrackingChangesInDocument(languageDocumentId) {
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/translationunits/changetracking/discard`,
			'PATCH');
    }

	async tryFixTagsInCurrentDocument(languageDocumentId) {
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/translationunits/fixtags`,
			'PATCH');
	}

	async tryFixTagsInTu(languageDocumentId, tuId) {
		return await this._sendRequest(
			`/cat/languagedocuments/${languageDocumentId}/translationunits/${tuId}/fixtags`,
			'PATCH');
	}

	async tmSearch(projectId, languageDocumentId, segmentSequenceNumber) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/tms/query?languageDocumentId=${languageDocumentId}&segmentSequenceIndex=${segmentSequenceNumber}`,
			'GET',
			null);
	}

	async terminologySearch(projectId, languageDocumentId, segmentSequenceNumber) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/terminology/query?languageDocumentId=${languageDocumentId}&segmentSequenceIndex=${segmentSequenceNumber}`,
			'GET',
			null);
	}

	async getProjectTms(projectId) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/tms`,
			'GET',
			null);
	}

	async getProjectTasks(projectId) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/tasks`,
			'GET',
			null);
	}

	async getProjectManualTaskSettings(projectId, taskId) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/tasks/${taskId}/settings`,
			'GET',
			null);
	}

	async getProjectTaskProblems(projectId, taskId) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/tasks/${taskId}/problems`,
			'GET',
			null);
	}

	async updateProjectManualTaskSettings(projectId, taskId, resource) {
		return await this._sendRequest(
			`/cat/projects/${projectId}/tasks/${taskId}/settings`,
			'PATCH',
			resource);
	}

	async _sendRequest(url, httpMethod, data) {
		try {
			const results = await this._httpService.sendRequest(
				url,
				httpMethod,
				data,
				this.defaultRequestConfig);

			let responseObject = await this.fromJsonResponseObject(results);
			responseObject.resourceDocument = new ResourceDocument(responseObject.payload);

			return responseObject;
		}
		catch (err) {
			this.printErrorsToConsole(err)
			throw err
		}
	}

	get defaultRequestConfig() {
		return {
			contentType: 'application/json',
			authorization: this._config.authToken ? `Bearer ${this._config.authToken}` : undefined,
		};
	}

	createDefaultPaginationOptions(paginationOptions) {
		return {
			limit: 15,
			page: 1,
			...paginationOptions
        }
    }

	async fromJsonResponseObject(response) {
		let result = {
			status: response.status,
			ok: response.ok,
			payload: await response.json().catch(() => { })
		}

		return result;
    }

	/**
	 * Logowanie błędów odpowiedzi serwera
	 *
	 * @param {*} error
	 * @method printErrorsToConsole
	 * @private
	 */
	printErrorsToConsole(error) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			console.log(error.response.data)
			console.log(error.response.status)
			console.log(error.response.headers)
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request)
		} else {
			// Something happened in setting up the request that triggered an Error
			if (error && error.message)
				console.log('Error', error.message)
		}

		console.log(error)
	}
}



/**
 * Pobranie instacji CatOnlineService używając domyślnego serwisu HTTP
 * @method getCatOnlineService
 * @returns {CatOnlineService}
 */
export default function getCatOnlineService(serviceConfig = {}) {
	let httpService = new HttpService(config.serviceUrl);
	return new CatOnlineService(httpService, serviceConfig);
}

