/**
 * Klasa opisu pola w formie FormMetaObject
 */
export default class Field {
	constructor(name, form) {
		this._form = form
		this._name = name
	}

	get dataType() { return this._form._dataType }
	get name() { return this._name }
	get label() { return this._form.getLabel(this._name) }
	get required() { return this._form.getRequired(this._name) }
	get disabled() { return this._form.getDisabled(this._name) }
	get error() { return this._form.getError(this._name) }
	get placeholder() { return this._form.getPlaceholder(this._name) }
	get hint() { return this._form.getHint(this._name) }
	get value() { return this._form.getValue(this._name) }
	get valueInput() { return this._form.getValueInput(this._name) }
	get type() { return this._form.getType(this._name) }
	get width() { return this.meta?.width }
	get height() { return this.meta?.height }

	handleChange(newValue) { this._form.handleChange(this._name, newValue) }
	get meta() { return this._form.getMeta(this._name) }

	trigger(eventName) { this._form.trigger(eventName) }

	get form() { return this._form }
}