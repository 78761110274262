import React from 'react'
import { useForm } from "components/form/Form";
import { translate } from "locale/Locale";
import Button from "./Button";
import { GlobalStrings } from 'GlobalStrings';
import { useAppCtrl } from 'AppCtrl';


export default function ButtonCRMCustomerOffers({isList}) {
	const appCtrlState = useAppCtrl()

	const form = useForm('data');

	if (form.editMode === 'create')
		return null;

	const existsOffersLink = appCtrlState.session.findLinkByName("Offer");
	if (!existsOffersLink){
		return null;
	}
	
	if (form._dataType !== GlobalStrings.CRMCustomerContactsDataType)
		return null;

	const label = translate('WebSpa/MainMenu/Offer/title');

	const companyId = isList ? form.parentForm.data.data[0].id : form.parentForm.parentForm.data.data[0].id;
	const windowName = `${GlobalStrings.CrmOffersWindowName}_${companyId}`;

	return <Button type='submit' content={label} onClick={e => {
			window.open('/offers', windowName, 'noopener,noreferrer');
	}}/>;
}
