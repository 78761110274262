import React from 'react'
import { MixinColor, MixinFont, Vertical, Grid, MixinGridItem } from 'components/topology/Topology'
import styled from 'styled-components'
import { customElements } from 'cssStatic'

/*
year >1
month

month >1
days

y
1 2 3 4 5 6 7 8 9 10 11 12

2010              |2011 
5 6 7 8 9 10 11 12 1 2 3

years
month
days
*/

function dayBegin(d) {
	let date = new Date(d)
	date.setHours(0)
	date.setMinutes(0)
	date.setSeconds(0)
	return date
}

// eslint-disable-next-line
function dayEnd(d) {
	let date = new Date(d)
	date.setHours(23)
	date.setMinutes(59)
	date.setSeconds(59)
	return date
}

function monthBegin(d) {
	let date = new Date(d)
	date.setDate(1)
	date.setHours(0)
	date.setMinutes(0)
	date.setSeconds(0)
	return date
}

// eslint-disable-next-line
function monthEnd(d) {
	let date = new Date(d)
	date.setMonth(date.getMonth() + 1)
	date.setDate(0)
	date.setHours(23)
	date.setMinutes(59)
	date.setSeconds(59)
	return date
}

function yearBegin(d) {
	let date = new Date(d)
	date.setMonth(0)
	date.setDate(1)
	date.setHours(0)
	date.setMinutes(0)
	date.setSeconds(0)
	return date
}

// eslint-disable-next-line
function yearEnd(d) {
	let date = new Date(d)
	date.setMonth(12)
	date.setDate(0)
	date.setHours(23)
	date.setMinutes(59)
	date.setSeconds(59)
	return date
}

function yearAdd(d, add) {
	let r = new Date(d)

	r.setFullYear(r.getFullYear() + add)
	return r
}

function monthAdd(d, add) {
	let r = new Date(d)

	r.setMonth(r.getMonth() + add)
	return r
}

function dayAdd(d, add) {
	let r = new Date(d)

	r.setDate(r.getDate() + add)
	return r
}

function dateRanges(db, de, next) {
	let res = []
	let d = new Date(db)
	let dNext

	for (; ;) {
		dNext = next(d)
		if (dNext >= de) {
			res.push([d, de, de.getTime() - d.getTime()])
			return res
		}
		res.push([d, dNext, dNext.getTime() - d.getTime()])
		d = dNext
	}
}

function yearRanges(db, de) {
	return dateRanges(db, de, d => yearBegin(yearAdd(d, 1)))
}

function monthRanges(db, de) {
	return dateRanges(db, de, d => monthBegin(monthAdd(d, 1)))
}

function dayRanges(db, de) {
	return dateRanges(db, de, d => dayBegin(dayAdd(d, 1)))
}

const DataRangeCell = customElements('o-data-range-cell', [
	'text-align-center', 'overflow-x-hidden',
	'grid-ax-stretch',
	'f-primary', 'text-grey700', 'f-12',
	{
		'border-left': '1px solid #dcdcdc'
	}
])

function DateRanges({ dateBegin, dateEnd, dateRanges, toText, children }) {
	const child = React.Children.only(children)
	const size = dateEnd.getTime() - dateBegin.getTime()
	const tx = dateRanges.map(r => (r[2] / size) + 'fr').join(' ')

	return (
		<Grid tx={tx} className='white'>
			{
				dateRanges.map((dateRange, index) => {
					return React.cloneElement(child, {
						key: index,
						index,
						children: toText(dateRange)
					})
				})
			}
		</Grid>
	)
}

function YearRanges({ dateBegin, dateEnd, dateRanges, children }) {
	return (
		<DateRanges
			dateBegin={dateBegin}
			dateEnd={dateEnd}
			dateRanges={dateRanges}
			toText={r => r[0].getFullYear() + ''}
		>
			{children}
		</DateRanges>
	)
}

const shortMonths = [
	'STY', 'LUT', 'MAR', 'KWI', 'MAJ', 'CZE', 'LIP', 'SIE', 'WRZ', 'PAŹ', 'LIS', 'GRU'
]

function monthToShortText(date) {
	return shortMonths[date.getMonth()]
}

function MonthRanges({ dateBegin, dateEnd, dateRanges, children }) {
	return (
		<DateRanges
			dateBegin={dateBegin}
			dateEnd={dateEnd}
			dateRanges={dateRanges}
			toText={r => monthToShortText(r[0])}
		>
			{children}
		</DateRanges>
	)
}

function DayRanges({ dateBegin, dateEnd, dateRanges, children }) {
	return (
		<DateRanges
			dateBegin={dateBegin}
			dateEnd={dateEnd}
			dateRanges={dateRanges}
			toText={r => r[0].getDate() + ''}
		>
			{children}
		</DateRanges>
	)
}

export function DateScale({ dateBegin, dateEnd }) {
	if (!(dateBegin instanceof Date && dateEnd instanceof Date && dateEnd > dateBegin))
		return null

	let yr = yearRanges(dateBegin, dateEnd)
	let mr = monthRanges(dateBegin, dateEnd)

	if (yr.length <= 1 && mr.length <= 2) {
		let dr = dayRanges(dateBegin, dateEnd)

		return (
			<Vertical className='grid-acy-stretch'>
				<MonthRanges
					dateBegin={dateBegin}
					dateEnd={dateEnd}
					dateRanges={mr}
				>
					<DataRangeCell />
				</MonthRanges>
				<DayRanges
					dateBegin={dateBegin}
					dateEnd={dateEnd}
					dateRanges={dr}
				>
					<DataRangeCell />
				</DayRanges>
			</Vertical>
		)
	}

	return (
		<Vertical className='grid-acy-stretch'>
			<YearRanges
				dateBegin={dateBegin}
				dateEnd={dateEnd}
				dateRanges={yr}
			>
				<DataRangeCell />
			</YearRanges>
			<MonthRanges
				dateBegin={dateBegin}
				dateEnd={dateEnd}
				dateRanges={mr}
			>
				<DataRangeCell />
			</MonthRanges>
		</Vertical>
	)
}