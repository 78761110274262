import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'components/form/Form';
import { useTranslate } from 'locale/Locale';
import { GlobalStrings } from 'GlobalStrings';
import Button from './Button';

function isVisible(form, isList) {
	if (isList)
		return !!form.changedChildForm && form.rowManager.inlineEdition;

	return form.isChanged;
}

/**
 * Przycisk Cancel pozwalający na wycofanie wprowadzonych w formie zmian
 * @param {Node} content -  Zawartość przycisku, może nim być tekst lub ikona, jeśli go nie przekażemy, zostanie wyświetlony domyślny tekst dla tego przycisku 
 * @returns Przycisk typu Cancel, wykonujący akcję clearChanges() na obecnej formie
 */
export function ButtonCancel({ isList, content }) {
	const form = useForm('data');
	const translate = useTranslate('WebSpa/Buttons');

	if (!isVisible(form, isList))
		return null;

	return (
		<Button
			type='cancel'
			id={`${form._dataType ?? ''}-ButtonCancel`}
			onClick={e => {
				if (isList) {						
					form.changedChildForms.forEach(f => f.clearChanges());
					form.trigger('data');
					form.trigger(GlobalStrings.CancelEventName);
				} else {
					form.clearChanges();
					form.trigger('data');
					form.trigger(GlobalStrings.CancelEventName);
				}
				e.stopPropagation();
			}}
			content={content || translate('cancelButton')}
		/>
	);
}

ButtonCancel.propTypes = {
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.elementType
	]),
};
