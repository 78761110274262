import React, { useRef, useMemo, useState } from 'react'
import PropTypes from 'prop-types';
import TranslationUnitViewModel from './model/TranslationUnitViewModel'
import EditorTranslationUnitEvents from '../model/EditorTranslationUnitEvents';
import OriginType from './model/OriginType';
import Segment from './Segment'
import Icon from 'components/icon/Icon'
import ConfirmationLevelType from './model/ConfirmationLevelType';
import { Chip } from '@material-ui/core';
import Tooltip from '../components/Tooltip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import useEditorMediator from '../hooks/useEditorMediator';
import TranslationUnitController from './TranslationUnitController';
import { AcceptColor, DiscardColor } from '../consts/Styles';
import { dateTimeStringToFormatedString } from '../model/Utils';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { CodeOutlined } from '@material-ui/icons';

const translationUnitIdPrefix = 'tu-'

function isElementTranslationUnit(element)
{
    if (element.id &&
        element.id.startsWith(translationUnitIdPrefix) &&
        element.tagName === "TR") {
        return true;
    }

    return false;
}

function findNextTranslationUnit(translationUnitRowRef) {
    const nextElementSibling = translationUnitRowRef.current ? translationUnitRowRef.current.nextElementSibling : null
    if (!nextElementSibling) {
        return null
    }

    if(isElementTranslationUnit(nextElementSibling))
        return nextElementSibling;

    const futherElementSibling = nextElementSibling.nextElementSibling;
    if(futherElementSibling && isElementTranslationUnit(futherElementSibling))
        return futherElementSibling;

    return null
}

function findTargetSegmentInput(translationUnit) {
    if (!translationUnit) {
        return null;
    }
    const targetSegment = translationUnit.querySelector("td[id^='seg-target']")
    if (!targetSegment) {
        return null
    }

    return targetSegment.firstChild.firstChild.firstElementChild
}

function setFocusOnTranslationUnitTargetSegment(translationUnitRowRef) {
    // Fix na race condition. Bez opóźnienia wybranie focusa powoduje wyczyszczenie segmentu. 
    // Fix taki sobie. Zdecydowanie powinno to być robione na skutek zdarzenia po stronei segmentu odpowiednio
    // sycnhronizujac wykonywanie działań
    setTimeout(() => {
        if (translationUnitRowRef) {
            const targetSegmentInput = findTargetSegmentInput(translationUnitRowRef);
            const draftjsEditor = targetSegmentInput.childNodes[0]
            if (targetSegmentInput && draftjsEditor && draftjsEditor.focus) {
                draftjsEditor.focus();
            }
        }
    }, 100);
    
}

function setFocusOnNextTranslationUnitTargetSegment(currentTranslationUnitRowRef) {
    const nextTranslationUnit = findNextTranslationUnit(currentTranslationUnitRowRef);
    setFocusOnTranslationUnitTargetSegment(nextTranslationUnit);
}

function getMissingTagsInTargetSegment(sourceSegment, targetSegment){
    const sourceTags = sourceSegment.elements.filter(element => element.isTag);
    if(!sourceTags || sourceTags.length === 0)
        return [];

    if(!targetSegment || !targetSegment.elements || targetSegment.elements.length === 0)
        return sourceTags;

    const targetElements = targetSegment.elements;
    const missingTags = [];
    for (let index = 0; index < sourceTags.length; index++) {
        const sourceTag = sourceTags[index];
        let targetTag = null;

        if(sourceTag.isStandaloneTag)
        {
            targetTag = targetElements.find(targetElement => 
                targetElement.isStandaloneTag && targetElement.crossFormatId === sourceTag.crossFormatId);
        }
        else
        {
            targetTag = targetElements.find(targetElement => targetElement.anchor === sourceTag.anchor && 
                targetElement.typeName === sourceTag.typeName);
        }

        if(!targetTag)
            missingTags.push(sourceTag);
    }

    return missingTags;
}

const ContextMatchShortName = "CM";
const ChipGreenColor = "#81c881";
const ChipBlueColor = "#6895c3";
const ChipYellowColor = "#b2a24d";

function getTranslationSourceInfoLabelOptions(origin, score, isContextMatch){
    const result = {
        label: "",
        color: ""
    };
    if(isContextMatch)
    {
        result.label = ContextMatchShortName;
        result.color = ChipGreenColor;
    }
    else if(origin.id === OriginType.AutomatedTranslation || origin.id === OriginType.NeuralMachineTranslation)
    {
        result.label = OriginType.getShortName(origin.id);
        result.color = ChipBlueColor;
    }
    else
    {
        result.label = score + "%";
        if(score >= 100)
            result.color = ChipGreenColor;
        else
        result.color = ChipYellowColor;
    }

    return result;
}

function TranslationSourceInfo({origin, score, providerName, isContextMatch}){
    if(!origin || !origin?.id)
        return null;

    const labelOptions = getTranslationSourceInfoLabelOptions(origin, score, isContextMatch);
    const tooltipTitle = (<span>Origin: {origin.name}<br/>Provider: {providerName}<br/>Score: {isContextMatch ? ContextMatchShortName : score + "%"}</span>);
        
    return <Tooltip
        style={{width: "48px", backgroundColor: labelOptions.color}} 
        title={tooltipTitle} 
        placement='bottom-start' arrow>
            <Chip size="small" label={labelOptions.label} />
        </Tooltip>
}

function formatQeScore(qeScore){
    return qeScore.toFixed(5).toString().slice(0, -1);
}

function TranslationQEInfo({qeScore}){
    if(!qeScore || qeScore < 0)
        return null;

    const label = formatQeScore(qeScore);
    const tooltipTitle = (<span>LivoQA Score: {qeScore}</span>);
        
    return <Tooltip
        style={{maxWidth: "68px", backgroundColor: "#d0c9eb"}} 
        title={tooltipTitle} 
        placement='bottom-start' arrow>
            <Chip size="small" label={label} />
        </Tooltip>
}

function hasTagsIssue(tu)
{
    if(!tu.warningMessages)
        return false;

    for (const message of tu.warningMessages) {
        // Tags missmatch, unexpected tag
        // Na ten moment w ten sposób. Ta informacja powinna przyjść w backendu
        if(message.kind && (message.kind.id === 1006 || message.kind.id === 1009)) 
            return true;
    }

    return false;
}

export default function TranslationUnit({ languageDocumentId,  translationUnit, trackingMode, onTargetSegmentChanged, onTranslationUnitConfirmation, onTranslationUnitSelected }) {

    const translationUnitRowRef = useRef(null)
    
    const editorEventsRef = useRef(new EditorTranslationUnitEvents());
    const tuController = useRef(new TranslationUnitController());
    const [isSelected, setIsSelected] = useState(false);

    const editorController = useEditorMediator({
        subscribeTo: (controller) => {
            controller.subscribeTranslationUnitCommands(tuController.current, languageDocumentId, translationUnit.sequenceNumber);
            controller.subscribeEditorDocumentEvents(tuController.current);
        },
        unsubscribeFrom: (controller) => {
            controller.unsubscribeTranslationUnitCommands(languageDocumentId, translationUnit.sequenceNumber);
            controller.unsubscribeEditorDocumentEvents(tuController.current);
        }
    });

    tuController.current.selectTranslationUnit = (documentId, sequenceNumber) => {
        console.log("Select TU: ", documentId, sequenceNumber);
        setFocusOnTranslationUnitTargetSegment(translationUnitRowRef.current);
    };

    tuController.current.onSelectedTranslationUnit = (documentId, sequenceNumber) => {
        if(documentId !== languageDocumentId)
            return;

        setIsSelected(sequenceNumber === translationUnit.sequenceNumber);
    }

    tuController.current.onDocumentCommentChanged = (languageDocumentId, args) => 
    {
    }

    tuController.current.onTuConfirmationLevelChanged = (languageDocumentId, sequenceNumber, confirmationLevel) => 
    {

    }

    const onTargetSegmentChangedInternal = newSegment => {
        if (onTargetSegmentChanged) {
            if(translationUnit.targetSegment.saveableContentEquals(newSegment))
            {
                return;
            }

            onTargetSegmentChanged(translationUnit, newSegment);
        }
    };

    const onKeyDown = (keyEvent, newSegmentValue) => {
        if (!keyEvent) {
            return
        }

        if (keyEvent.ctrlKey) 
        {
            if(keyEvent.key === 'Enter')
            {
                if (onTranslationUnitConfirmation) {
                    if(translationUnit.targetSegment.saveableContentEquals(newSegmentValue))
                    {
                        newSegmentValue = null
                    }
                    onTranslationUnitConfirmation(translationUnit, newSegmentValue)
                }
                //setIsSelected(false);
                // setTimeout(() => {
                //     setFocusOnNextTranslationUnitTargetSegment(translationUnitRowRef)
                // }, 1)
            }
            else if(keyEvent.key === 'Insert')
            {
                
            }
            
        }
    }

    const onTargetSegmentSelected = () => {
        if (onTranslationUnitSelected) {
            onTranslationUnitSelected(translationUnit)
        }

        setIsSelected(true);
    }

    const confirmationLevelIcon = useMemo(() => {
        let iconName = null
        let color = null
        let tooltipDescription = null
        switch (translationUnit.confirmationLevel) {
            case ConfirmationLevelType.Draft:
                iconName = 'create'
                color = 'blue700'
                tooltipDescription = 'Draft'
                break;
            case ConfirmationLevelType.Translated:
                iconName = 'check'
                color = 'green700'
                tooltipDescription = 'Translated'
                break;
            default:
                iconName = 'insert_drive_file'
                color = 'grey100'
                tooltipDescription = 'Not translated'
                break;
        }

        return (<Tooltip title={tooltipDescription} placement='right-end' arrow><Icon name={iconName} color={color} /></Tooltip>)
    }, [translationUnit.confirmationLevel])

    const isLockedIcon = useMemo(() => {
        if (translationUnit.isLocked) {
            return (<Tooltip title={"Translation locked"} placement='right-end' arrow><Icon name="lock" color="orange700" style={{float: 'right'}} /></Tooltip>)
        }
        return null
    }, [translationUnit.isLocked])

    const warningIcon = useMemo(() => {
        let warningMessages = [];

        for (const message of translationUnit.warningMessages) {
            warningMessages.push(message.message);
        }

        const missingTags = getMissingTagsInTargetSegment(translationUnit.sourceSegment, translationUnit.targetSegment);
        if(missingTags.length !== 0)
        {
            warningMessages.push("Type '@' in target segment to insert tags.");
        }

        if(warningMessages.length === 0) {
            return null;
        }

        const spanContent = warningMessages.map((msg) => {
            return (<>{msg}<br/></>)
        });

        return  <Tooltip 
                title={(<span>{spanContent}</span>)} 
                placement='left' 
                arrow interactive leaveDelay={500}>
                <Icon name="warning" color="warning" style={{float: 'right'}} />
            </Tooltip>
    }, [translationUnit.targetSegment, translationUnit.messages]);

    const commentsIcon = useMemo(() => {
        if(translationUnit.comments.length == 0)
            return null;

        const spanContent = translationUnit.comments.map((comment) => {
            return (<>{"[" + comment.userName + ", " + dateTimeStringToFormatedString(comment.createdAt) + "] " + comment.comment}<br/></>)
        });

        return  <Tooltip 
                title={(<span>{spanContent}</span>)} 
                placement='left' 
                arrow interactive leaveDelay={500}>
                <CommentOutlinedIcon fontSize='small' style={{float: 'right'}} />
            </Tooltip>
    });

    const errorIcon = useMemo(() => {
        let errorMessages = [];

        for (const message of translationUnit.errorMessages) {
            errorMessages.push(message.message);
        }

        if(errorMessages.length === 0) {
            return null;
        }

        const spanContent = errorMessages.map((msg) => {
            return (<>{msg}<br/></>)
        });

        return <Tooltip title={(<span>{spanContent}</span>)} placement='bottom' arrow><Icon name="error" color="error" style={{float: 'right'}} /></Tooltip>
    }, [translationUnit.targetSegment, translationUnit.messages]);


    const isTryFixTagIsAvailable = hasTagsIssue(translationUnit);

    const onSegmentBlur = () => {
        console.log("On Segment Blur", translationUnit.id)
    }

    const targetCellBorder = '1px solid #DDD';
    const rowStyle = isSelected ? {backgroundColor: '#f5f5dc'} : {};

	return (
        <tr id={translationUnitIdPrefix + translationUnit.id} ref={translationUnitRowRef} style={rowStyle} >
            <td style={{ minWidth: '20px', maxWidth: '70px', border: '1px solid #DDD', textAlign: 'center' }} >
                {translationUnit.sequenceNumber}
            </td>
            <td id={'seg-source-' + translationUnit.id} style={{ width: '45%', border: '1px solid #DDD' }}>
                <Segment
                    segmentObject={translationUnit.sourceSegment}
                    allowedTags={translationUnit.allowedTags()}
                    isReadOnly={true} />
            </td>
            <td style={{ minWidth: '55px',  border: '1px solid #DDD' }}>
                <div style={{ display: 'flex'}}>
                {confirmationLevelIcon}
                <TranslationSourceInfo 
                    origin={translationUnit.origin} 
                    score={translationUnit.score} 
                    providerName={translationUnit.providerName}
                    isContextMatch={translationUnit.isContextMatch} />
                <TranslationQEInfo qeScore={translationUnit.qeScore}/>
                {warningIcon}
                {errorIcon}
                {isLockedIcon}
                {commentsIcon}
                </div>
                
            </td>
            <td id={'seg-target-' + translationUnit.id} style={{ width: '45%', border: targetCellBorder }}>
                <Segment
                    segmentObject={translationUnit.targetSegment}
                    trackingMode={trackingMode}
                    sourceSegmentObject={translationUnit.sourceSegment}
                    allowedTags={translationUnit.allowedTags()}
                    onSegmentChange={onTargetSegmentChangedInternal}
                    onKeyDown={onKeyDown}
                    onSegmentSelected={onTargetSegmentSelected}
                    onSegmentBlur={onSegmentBlur}
                    isReadOnly={!translationUnit.isEnabled}
                    editorEventSubscription={editorEventsRef.current} />
            </td>
            <td id={'seg-actions-' + translationUnit.id} style={{  borderBottom: '1px solid #DDD' }} >
                <div style={{ display: 'flex'}}>
                {translationUnit.targetSegment.hasChangeTrackingElements() &&
                    <>
                        <IconButton aria-label="Accept changes" size='small' onClick={() => editorEventsRef.current.onAcceptChangeTrackingChanges(translationUnit.id)}>
                            <Tooltip title={"Accept changes"} placement='left-end' arrow>
                            <CheckCircleOutlineIcon style={{color: AcceptColor}} fontSize='medium'/>
                            </Tooltip>
                        </IconButton>
                        <IconButton aria-label="Discard changes" size='small' onClick={() => editorEventsRef.current.onDiscardChangeTrackingChanges(translationUnit.id)}>
                            <Tooltip title={"Discard changes"} placement='left-end' arrow>
                                <HighlightOffIcon style={{color: DiscardColor}} fontSize='medium'/>
                            </Tooltip>
                        </IconButton>
                    </>
                }
                {/* {isTryFixTagIsAvailable &&
                    <IconButton aria-label="Try Fix Tags" size='small' onClick={() => editorController.onTryFixTuTags(languageDocumentId, translationUnit.id)}>
                        <Tooltip title={"Try Fix Tags"} placement='left-end' arrow>
                            <CodeOutlined fontSize='medium'/>
                        </Tooltip>
                    </IconButton>
                } */}
                    
                </div>
            </td>
        </tr>
	)
}

TranslationUnit.propTypes = {
    translationUnit: PropTypes.instanceOf(TranslationUnitViewModel)
}
