import { MixinGrid } from './Grid'
import { customElements } from 'cssStatic'

/** 
 * Mixinustawiający pionowo kolejne dzieci
 * @param {string} g szerokość odstępu pomiedzy dziećmi w pionie: lg | md | sm | css rozmiar . Domyślnie md
 * @param {string} acx ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
 * @param {string} aix ustawienie dzieci w kierunku x:  start | end | center | stretch. Domyślnie center
 * @param {string} acy ustawienie komponetu w kierunku y: start | end | center | stretch | space-around | space-between | space-evenly
 * @param {string} aiy ustawienie dzieci w kierunku y:  start | end | center | stretch. Domyślnie center
 * @return {Node}
*/
export function MixinVertical({ g, acx, aix, acy, aiy }) {
	return MixinGrid({
		gy: g,
		acx, acy,
		aix, aiy
	})
}

// /** 
//  * Rysuje pionowo kolejne dzieci
//  * @param {string} g szerokość odstępu pomiedzy dziećmi w pionie: lg | md | sm | css rozmiar . Domyślnie md
//  * @param {string} acx ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
//  * @param {string} aix ustawienie dzieci w kierunku x:  start | end | center | stretch. Domyślnie center
//  * @param {string} acy ustawienie komponetu w kierunku y: start | end | center | stretch | space-around | space-between | space-evenly
//  * @param {string} aiy ustawienie dzieci w kierunku y:  start | end | center | stretch. Domyślnie center
//  * @return {Node}
// */
// export const Vertical = styled.div`
// 	${ props => MixinVertical(props)}
// `

export const Vertical = customElements('o-vertical', ['grid-ver'])