import React from 'react'
import Input from './Input/Input'
import PropTypes from 'prop-types'

/**
 * Komponent input text
 *
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @returns {Node}
 */
export default function InputText({ name, hasLabel, icon, ...other }) {
	if (!icon)
		icon = undefined
	return <Input type='text' name={name} hasLabel={hasLabel} icon={icon} {...other} />
}

InputText.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool
}

InputText.defaultProps = {
	hasLabel: true,
	icon: false,
}
