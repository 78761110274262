import Storage from './Storage'
import CryptoAES from './CryptoAES'

/**
 * Obsługa lokalnego magazynu
 */
const LocalStorage = new Storage(localStorage)
/**
 * Obsługa magazynu sesji
 */
const SessionStorage = new Storage(sessionStorage)

const cryptoKey='x]K3#z/6SqFBrP8]'

/**
 * Obsługa zaszyfrowanego localnego magazynu 
 */
const CryptoLocalStorage = new Storage(localStorage, new CryptoAES(cryptoKey))
/**
 * Obsługa zaszyfrowanego magazynu sessji
 */
const CryptoSessionStorage = new Storage(sessionStorage, new CryptoAES(cryptoKey))

export {
	LocalStorage,
	SessionStorage,
	CryptoLocalStorage,
	CryptoSessionStorage
}