import React from 'react';
import PropTypes from 'prop-types'
import { StyledDownloadFileList, StyledLabel } from './DownloadFile.styled'
import { Grid, Typography, } from '@material-ui/core'
import { AttachFileOutlined } from '@material-ui/icons'
import { InputIcon } from './DownloadFile';
import { isEmpty } from 'lodash'


/**
 * Komponent tworzący pojedynczy wiersz z plikiem w gridzie przechowującym listę plików
 * @param {Object} file - obiekt pojedynczego pliku: {value: '', id: ''}
 * @returns 
 */
const FileListItem = ({ name, handleDownload, errorsObject, dataType, file }) => {

	if(isEmpty(file) || !file.value)
		return null

	const error = errorsObject?.[file?.id] ?? false
	const fileObject = { fileName: file.value, fileID: file?.id }

	return (
		<Grid 
			item
			container
			direction="row"
			wrap="nowrap"
			style={{gridGap: 8}}
		>
			<AttachFileOutlined />
			<Typography paragraph>
				{file.value}
			</Typography>
			<InputIcon 
				name={name} 
				dataType={dataType} 
				fileObject={fileObject} 
				handleDownload={handleDownload}
				error={error}
				isFieldInputDisabled={false}
			/>
		</Grid>
	)
}

/**
 * Komponent tworzący grid w którym przechowywana jest lista plików 
 * @returns 
 */

const DownloadFileList = ({ name, handleDownload, errorsObject, dataType, files, label }) => {

   if(isEmpty(files))
      return null

	return(
		<StyledDownloadFileList>
		{
			files.map(file => (
				<FileListItem 
					name={name} 
					dataType={dataType} 
					file={file} 
					handleDownload={handleDownload}
					errorsObject={errorsObject} 
				/>
			))
		}
		<StyledLabel> { label } </StyledLabel>
		</StyledDownloadFileList>
	)
}

/* #region Export komponentu */
	export default DownloadFileList
/* #endregion */


/* #region  PropTypes */

DownloadFileList.propTypes = {
	name: PropTypes.string.isRequired,
	handleDownload: PropTypes.func,
	errorsObject: PropTypes.object,
	dataType: PropTypes.string,
	files: PropTypes.array ,
	label: PropTypes.string,
}

FileListItem.propTypes = {
	name: PropTypes.string.isRequired,
	handleDownload: PropTypes.func,
	errorsObject: PropTypes.object,
	dataType: PropTypes.string,
	file: PropTypes.object,
}

/* #endregion */

/* #region  Domyślne propsy */

FileListItem.defaultProps = {
	file: {},
}

/* #endregion */
