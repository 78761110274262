import styled from 'styled-components'
import { Tooltip, withStyles } from '@material-ui/core'
import { MixinMargin, MixinFont, MixinColor } from 'components/topology/Topology'

/**
 * Komponent stylizujący Labelkę
 * @param {Bool} required - gdy komponent jest wymagany, labelka ma grubszy font
 * @param {Bool} error - gdy komponent ma błąd kolor labelki zmieniany na czerwony
 * @param {Bool} disabled - gdy komponent jest nieaktywny kolor zmieniany na nieaktywny
 */
///TODO style
export const StyledLabel = styled.label`
	user-select: none;
	display: flex;
	justify-content: space-between;
   align-items: center;
	${MixinMargin(['none', 'none', '5px', 'none'])}
	${MixinFont('label')}
   ${MixinColor('grey700')};

   ${({ error, }) => error && MixinColor('error')};
   ${({ disabled, error }) => disabled && !error && 'color: rgba(0, 0, 0, 0.5)'};
   ${({ required, disabled }) => required && !disabled && `font-weight: 600;`};   
`

export const StyledTooltip = withStyles(() => ({
	tooltip: {
		fontSize: '14px'
	},
}))(Tooltip)