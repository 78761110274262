import FormBase from './FormBase'
import Field from './Field'

/**
 * Klasa formy
 */
export default class FormObject extends FormBase {
	constructor(params){
		super(params)
		this.changes={}
	}

	get isChanged(){
		for(var i in this.changes)
			return true
		return false
	}

	set isChanged(v){}

	getField(name){
		return new Field(name,this)
	}

	value(name){
		if(name in this.changes)
			return this.changes[name]
		return this.data[name]
	}

	handleChange(name,newValue){
		this.changes[name]=newValue
		this.trigger('data')
	}
}