import React from 'react'
import PropTypes from 'prop-types'
import useForm from '../../hooks/useForm'
import Field from './Field'
import { includes, isEmpty, } from 'lodash'
import { rowGap, colGap, getHeight, getWidth } from './getFieldDimentions.js'
import '../sections/Sections.scss'
import { getSectionFields } from './getSectionFields'

/**
 * Komponent wielu pól generowanych dynamicznie na podstawie metadanych
 *
 * @param {Array[string]} section Nazwy sekcji widocznych
 * @param {Array[string]} sectionNot Nazwy sekcji nie widocznych
 * @returns {Node}
 */
export default function Fields({ section, fieldStyle, sectionNot, ...other }) {
	const form = useForm('data')
	const fields = getSectionFields(form, section, sectionNot)
	
	if (fields.length === 0)
		return null

	let lastFieldIs100p = false

	const fieldRows = fields.reduce((p, field) => {
		if (getWidth(field) === '100%') {
			p.push([field])
			lastFieldIs100p = true
			return p
		}

		if (field.shouldBreakRow) {
			p.push([field])
			lastFieldIs100p = false
			return p
		}

		if (lastFieldIs100p)
			p.push([])
		lastFieldIs100p = false

		if (!p.length)
			p.push([])

		p[p.length - 1].push(field)
		return p
	}, [])

	return (
		<div style={{ display: 'grid', rowGap: rowGap, columnGap: colGap, }}>
			{fieldRows.map(fieldRow => {
				return (
					<div style={{ display: 'flex', rowGap: rowGap, columnGap: colGap, flexWrap: 'wrap' }}>
						{
							fieldRow.map(field => {
								const width = getWidth(field)
								const height = getHeight(field)
								const styleWidth = {}
								const styleHeight = {}

								if (height)
									styleHeight.height = height

								if (width === '100%')
									styleWidth.flexGrow = 1
								else
									styleWidth.flexBasis = width

								return (
									<div style={styleWidth}>
										<Field style={styleHeight} name={field.name} key={field.name} />
									</div>
								)
							})
						}
					</div>
				)
			})}
		</div>
	)

	// return (
	// 	<div className='Fields' {...other}>
	// 		{fields.map(field => {
	// 			const dimentions = getFieldDimentionsAndStyles(field)

	// 			return (
	// 				<div
	// 					key={field.name}
	// 					full-width-row={dimentions.fullWidthRow ? 'true' : undefined}
	// 					should-break-row={field.shouldBreakRow ? 'true' : undefined}
	// 					className='FieldWrapper'
	// 					style={dimentions.styleWrapper}
	// 				>
	// 					<Field name={field.name} key={field.name} />
	// 				</div>
	// 			)
	// 		})}
	// 	</div>
	// )
}

Fields.propTypes = {
	section: PropTypes.string,
	sectionNot: PropTypes.string
}