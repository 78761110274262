import { useTrackedState, useDispatch } from 'contents/cat/store/global'
import { useMemo } from 'react';

const useSessionTask = () => {
    const globalState = useTrackedState();
    const globalDispatch = useDispatch();

    return useMemo(() =>{
        return {
            id: globalState.session.task.id,
            projectId: globalState.session.task.projectId,
            languageDocuments: globalState.session.task.languageDocuments,
            canComplete: globalState.session.task.canComplete,
            useMinimalCatFeatures: globalState.session.task.useMinimalCatFeatures,
            taskName: globalState.session.task.name,
            setTask: (taskId, projectId, languageDocuments, canComplete, useMinimalCatFeatures, name) => {
                globalDispatch({
                    type: 'SET_SESSION_TASK',
                    'id': taskId,
                    'projectId': projectId,
                    'languageDocuments': languageDocuments,
                    'canComplete': canComplete,
                    'name': name,
                    useMinimalCatFeatures
                });
            },
            setTaskLanguageDocuments: (languageDocuments) => {
                globalDispatch({
                    type: 'SET_SESSION_TASK_LANGUAGE_DOCUMENTS',
                    'languageDocuments': languageDocuments
                });
            },
            completeTask: () => {
                globalDispatch({
                    type: 'UPDATE_SESSION_TASK',
                    'entity': {
                        'id': globalState.session.task.id,
                        'status': 5 // complete
                    }
                });
            }
    
        }
    }, [globalState.session.task, globalState.session.task.canComplete])
    
}

export default useSessionTask;