import React from 'react'
import { customElements } from 'cssStatic'

const FocusDiv = customElements('o-click-listener-focus-div', {
	'&:focus': ['o-none', 'bs-none', 'br-transparent']
})

export default function OnClickListener({ children, setOpen, open }) {
	return (
		<FocusDiv tabIndex='0' onKeyDown={e => {
			if (e.key === "F1") {
				setOpen && setOpen(!open)
				e.preventDefault()
				e.stopPropagation()
			}
			return
		}}>
			{children}
		</FocusDiv>
	)
}