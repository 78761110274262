import useForm from './useForm'

/**
 * Hook powiadomienia o zmianie w formie
 *
 * @param {string or Array[string]} name na jakie zdarzenia ma być powiadomiony komponet
 * @returns {Node}
 */
export default function useField(name) {
	const form = useForm('data')
	
	return form.getField(name)
}