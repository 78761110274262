import React, {
    useEffect,
    useReducer,
} from 'react';
import getCatOnlineService from 'services/CatOnlineService';
import useMySwr from './useMySwr';
import useUser from './useUser'

const dataFetchReducer = (state, action) => {
    console.log("Api Fetch: " + action.type + ". Params: " + JSON.stringify(action));
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                payload: action.payload,
                resourceDocument: action.resourceDocument,
                status: action.status
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
                status: action.status
            };
        default:
            throw new Error();
    }
};

const useCatOnlineService = (initialData, serviceMethodName, serviceMethodParams = []) => {
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        payload: initialData,
        resourceDocument: null,
        status: 0
    });

    const user = useUser();

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT', endpoint: serviceMethodName });

            try {
                let catOnlineServiceConfig = {};
                if (user.isLoggedIn) {
                    catOnlineServiceConfig.authToken = user.accessToken;
                }
                const catOnlineService = getCatOnlineService(catOnlineServiceConfig);
                const result = await catOnlineService[serviceMethodName](...serviceMethodParams);

                if (!didCancel) {
                    if (result.ok) {
                        dispatch({ type: 'FETCH_SUCCESS', payload: result.payload, status: result.status, resourceDocument: result.resourceDocument });
                    }
                    else {
                        dispatch({ type: 'FETCH_FAILURE', status: result.status });
                    }

                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: 'FETCH_FAILURE', status: 0, error });
                }
            }
        };

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [serviceMethodName]);

    return state;
};

export const useAuthenticateTaskAccess = (authToken, initialData) => {
    return useCatOnlineService(initialData, 'authenticateTaskAccess', [authToken])
}

export const useFetchLanguageDocuments = (initialData) => {
    return useCatOnlineService(initialData, 'getLanguageDocuments')
}

export const useUpdateTask = (projectId, taskId, entity, initialData) => {
    return useCatOnlineService(initialData, 'updateTask', [projectId, taskId, entity])
}

export const useFetchProjectTms = (initialData, projectId) => {
    return useCatOnlineService(initialData, 'getProjectTms', [projectId])
}

export const useFetchProjectTasks = (initialData, projectId) => {
    return useCatOnlineService(initialData, 'getProjectTasks', [projectId])
}

export const useAuthenticateTaskAccessSwr = (authToken) => {
    return useMySwr(['authenticateTaskAccess', authToken])
}

export const useFetchTaskBasedOnAuthTokenSwr = () => {
    return useMySwr(['getTaskBasedOnAuthToken'])
}

export const useFetchLanguageDocumentsSwr = () => {
    return useMySwr(['getLanguageDocuments'])
}

export const useFetchProjectTasksSwr = (projectId) => {
    return useMySwr(['getProjectTasks', projectId])
}