import NativeConsoleSink from './NativeConsoleSink';
import InMemoryStorageSink from './InMemoryStorageSink';
import InMemoryStorageSinkSerializer from './InMemoryStorageSinkSerializer';

class LoggerInstance {
    static calls = { trace: 0, info: 0, warn: 0, error: 0 };
    static options = { 
        // tzw. zlew, do którego trafiają komunikaty, może istnieć kilka, np. 
        // na konsole, do pliku, do zdalnego serwisu po HTTP
        sinks: [] 
     };
}

const setOption = (option, val) => { LoggerInstance.options[option] = val; };
const getOption = option => (LoggerInstance.options[option]);
const addSink = sink => { LoggerInstance.options.sinks.push(sink); };
const initializeSinks = (sinks) => {
    for (let index = 0; index < sinks.length; index++) {
        const sink = sinks[index];
        sink.initialize();
    }
};

const of = (module) => {
    const sinks = LoggerInstance.options.sinks;

    return {
        trace: (...args) => {
            LoggerInstance.calls.trace += 1;
            for (let i = 0; i < sinks.length; i++) {
                sinks[i].trace(module, ...args);
            }
        },
    
        info: (...args) => {
            LoggerInstance.calls.info += 1;
            for (let i = 0; i < sinks.length; i++) {
                sinks[i].info(module, ...args);
            }
        },
    
        warn: (...args) => {
            LoggerInstance.calls.warn += 1;
            for (let i = 0; i < sinks.length; i++) {
                sinks[i].warn(module, ...args);
            }
        },
    
        error: (...args) => {
            LoggerInstance.calls.error += 1;
            for (let i = 0; i < sinks.length; i++) {
                sinks[i].error(module, ...args);
            }
        }
    };
}

const memorySinkOptions = {maxEntries: 2000, excludeCleanupOfModules: ['App.BuildInfo', 'App.RuntimeEnvironment']}
const memorySink = new InMemoryStorageSink(memorySinkOptions.maxEntries, memorySinkOptions.excludeCleanupOfModules);
const memorySinkSerializer = new InMemoryStorageSinkSerializer(memorySink);

addSink(new NativeConsoleSink());
addSink(memorySink);
initializeSinks(LoggerInstance.options.sinks);

export const Logger = { of, calls: LoggerInstance.calls, setOption, getOption, memorySinkSerializer };
export default { of, calls: LoggerInstance.calls, setOption, getOption, memorySinkSerializer }