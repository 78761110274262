import React from 'react'
import { HeaderCellBorder } from 'components/form/components/list/components'
import { useForm } from 'components/form/Form'
import { DateScale } from 'components/dateScale/DateScale'

export function HeaderGantt() {
	const form = useForm('data')

	if (!form.ganttManager.isGantt)
		return null

	form.ganttManager.initTimeRangeFromRows('StartTime', 'EndTime', true)

	const b = form.ganttManager.startTime
	const e = form.ganttManager.endTime

	return (
		<HeaderCellBorder>
			<DateScale
				dateBegin={b}
				dateEnd={e}
			/>
		</HeaderCellBorder>
	)
}