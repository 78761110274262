import React from 'react'
import FormMetaObject from '../form/FormMetaObject'
import InputText from '../input/InputText'
import getFormRestService from 'services/getFormRestService'
import { Redirect } from 'react-router-dom'

import { appCtrl } from 'AppCtrl'
import LoginLayout from './LoginLayout'
import useForm from '../../hooks/useForm'
import GlobalError from 'components/form/components/fields/GlobalError/GlobalError'
import { Vertical } from 'components/topology/Topology'
import { ButtonSubmit } from '../list/components'
import { isString } from 'lodash'
import Logger from 'Tools/Logging/Logger';
import { ErrorModalFormEx } from 'components/ErrorModalEx'
import Info from 'components/info/Info'
import Label from '../label/Label'
import Button from 'components/form/components/list/components/buttons/Button'

export const LOGIN_DATATYPE = 'AuthLogin';
const INPUT_NAMES = Object.freeze({
	organization: 'Organization',
	login: 'Login',
	password: 'Password',
})

function ResetPasswordView(props) {
	const form = useForm('')
	const resetPasswordLabel = 'Reset Password';
	const cancelResetPasswordLabel = 'Cancel';

	return (
		<LoginLayout>
			<Vertical className='grid-aix-center grid-gap-5'
				onKeyDown={e => {
					if (e.key === 'Enter') form.asyncSubmit()
					e.stopPropagation()
				}}
			>
				<GlobalError />
				<div style={{ width: 450, display: 'grid', rowGap: 30 }}>
					<Label name={"Password reminder. "} style={{ fontSize: 16, padding: '3px 0' }} />
					<Label name={"Enter the organization and login associated with your account. We will send further instructions to it."} />
					<InputText name={INPUT_NAMES.organization} icon="organization_dark" autoFocus /> {/**name="domain" */}
					<InputText name={INPUT_NAMES.login} icon="user_dark" />
					<ButtonSubmit
						type="submit"
						id="ButtonResetPassword"
						fullWidth
						alwaysVisibleCondition
						style={{ backgroundColor: `var(--o-mainDarken700)` }}
						content={resetPasswordLabel}
					/>
					<Button
						type="submit"
						id="ButtonResetPassword"
						onClick={() => props.history.push('/' + props.location.search)}
						content={cancelResetPasswordLabel}
						style={{ backgroundColor: `var(--o-error)` }} />
				</div>
			</Vertical>
		</LoginLayout>
	)
}

/**
 * Logowanie
 *
 * @returns {Node}
 */
export default function ResetPassword(props) {
	if (appCtrl.session.isAuthorized)
		return <Redirect to='/' />

	return (
		<FormMetaObject
			data={{
				meta: {
					dataType: LOGIN_DATATYPE,
					attributes: {
						Login: {
							name: INPUT_NAMES.login,
							label: INPUT_NAMES.login,
							type: 'String',
							validations: [{ type: 'Required' }]
						},
						Organization: {
							name: INPUT_NAMES.organization,
							label: INPUT_NAMES.organization,
							type: 'String',
							validations: [{ type: 'Required' }]
						},
					}
				},
				data: [
					{
						attributes: {
							Organization: props.match.params.organization
						}
					}
				]
			}}
			onSubmit={async form => {
				const [errorObject, isOk] = await getFormRestService('/auth/identities/resetPassword').resetPassword(form);
				if (isOk) {
					Logger.of('ResetPassword.onSubmit').info('Wysłano wniosek o reset. Forwarduje.');
					props.history.push('/' + props.location.search);
				}
				else {
					Logger.of('ResetPassword.onSubmit').error(errorObject, 'Nieudany reset.');
					if (!isString(errorObject.detail)) {
						console.error('ResetPassword.js - Brak szczegółów błędu (Właściwości "detail"): ', errorObject.detail)
					}
					else {
						form.globalError = errorObject.detail
					}
					form.trigger('data')
				}
			}}
			redirectToLogin={() => null}
			errorModal={() => null}
		>
			<Info />
			<ErrorModalFormEx useDefaultTranslation={true} />
			<ResetPasswordView {...props} />
		</FormMetaObject>
	)
}

