import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'components/form/Form'
import { useTranslate } from 'locale/Locale'
import Button from './Button'
import { isUpdateAllowedButton } from './ButtonsBE'
import { GlobalStrings } from 'GlobalStrings'

function _isVisible_isList(form) {
	return !!form.changedChildForm && form.rowManager.inlineEdition
}

function _isVisible_isNotList(form) {
	//Sytuacja gdy jesteśmy w widoku "edit" i nie ma linku "update"
	if (form.editMode === 'edit' && !form.serviceUpdate)
		return false

	//Sytuacja jeśli forma jest w trybie create i jest jakieś pole wymagane - nie pozwalamy renderować się przyciskow
	if (form.resourceHasRequiredField() && form.editMode === 'create')
		return false

	//Sytuacje pozostałe - Jeśli forma jest w innym trybie niż create, jeśli zaszły jakieś zmiany itp
	if ((!form.isChanged && form.editMode !== 'create') || !form.isOk || isUpdateAllowedButton(form))
		return false

	//Jeśli powyższe warunki nie są spełnione, przycisk może się wyrenderować
	return true
}

/**
 * Metoda sprawdzająca czy przycisk "Zapisz" powinien się wyrenderować
 */
export function isVisible(form, isList, alwaysVisibleCondition) {
	if (alwaysVisibleCondition)
		return true

	return isList ? _isVisible_isList(form) : _isVisible_isNotList(form)
}

/**
 * Przycisk typu Submit, używany wszędzie tam gdzie potrzebujemy zapisać dane i wysłać je do BE.
 * @param {Boolean} alwaysVisibleCondition - Warunek pozwalający na ominięcie pozostałych warunków widoczności przycisku, 
 * jeśli jest "true" przycisk będzie pokazywany bez względu na pozostałe warunki na ekranie
 * @param {Node} content - Zawartość przycisku, może nim być tekst lub ikona, jeśli go nie przekażemy, zostanie wyświetlony domyślny tekst dla tego przycisku
 * @returns Przycisk typu Submit, wykonujący akcję asyncSubmit na aktualnej formie
 */

export function ButtonSubmit({ isList, alwaysVisibleCondition, content, ...other }) {
	const form = useForm('data')
	const translate = useTranslate('WebSpa/Buttons')

	if (!isVisible(form, isList, alwaysVisibleCondition))
		return null

	let translateString = 'submitButton';
	if ( form._dataType === GlobalStrings.SpecificationDataType){
		translateString = 'translateButton';
	}

	return (
		<Button
			type='submit'
			{...other}
			id={`${form._dataType ?? ''}-ButtonSubmit`}
			onClick={e => {
				if (isList)
					form.asyncSaveEditRows(() => {
						//form.trigger('data')
					})
				else
					form.asyncSubmit()
			}}
			content={content ? content : translate(translateString)}
		/>
	)
}

ButtonSubmit.propTypes = {
	alwaysVisibleCondition: PropTypes.bool,
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.elementType
	]),
}

ButtonSubmit.defaultProps = {
	alwaysVisibleCondition: false,
}
