import React from 'react'
import { useTranslate } from 'locale/Locale'
import { ColumnList } from './ColumnList'
import { Dialog, DialogContent } from '@material-ui/core'
import { StyledDialogTitle } from './StyledDialogModal'

const dialogTitleId = "dialog-column-visibility-title";
const dialogContentId = "dialog-column-visibility-body";

export function ColumnModal({ isOpen, close }) {
	const translate = useTranslate('WebSpa/ColumnModal')

	return (
		<Dialog open={isOpen} fullWidth maxWidth='md' onClose={() => close()} 
				scroll="paper" aria-labelledby={dialogTitleId} aria-describedby={dialogContentId}>

			<StyledDialogTitle disableTypography id={dialogTitleId}>
				{translate('title')}
			</StyledDialogTitle>
			<ColumnList close={close} dialogContentId={dialogContentId} />
		</Dialog>
	)
}