import React from 'react'
import { StyledPageSelector, DetailPaginatorWrapper, Placeholder } from './Paginators.styled'
import Data from './PaginatorClass'
import Icon from 'components/icon/Icon'
import RefreshableContext from 'components/refreshableContext/RefreshableContext'

const context = RefreshableContext.create()

/**
 * PageSelector - funkcja wyświetlająca i obsługująca kontrolkę do wprowadzania numeru strony
 * @returns {Node}
 */
const PageSelector = ({ onPage }) => {
	const form = context.useContext()
	return (
		< StyledPageSelector type="number" min={1} max={form.pages} value={form.value} onChange={(e) => {
			if (form.handleChange(e.target.value) && onPage)
				onPage()
		}}
			onKeyPress={(e) => {
				if (form.handleKeyPress(e) && onPage)
					onPage()
			}} />
	)
}

/**
 * DetailButtonLeft - kontrolka pozwalająca na odejmowanie stron. Pozwala na zmienianie stron wstecz
 * Gdy obecna strona jest pierwsza, Kontrolka się nie wyświetla
 * Komponent <Placeholder /> - pozwala na utrzymanie rozmiarów paginatora gdy zniknie Ikonka
 * @returns {Node}
 */

const DetailButtonLeft = ({ onPage }) => {
	const data = context.useContext()
	const { form: { currentPage } } = data
	return (
		<>
			{
				currentPage !== 1 ?
					<>
						<Icon name='first_page' md={30} onClick={() => {
							if (data.handleSubtract(true) && onPage)
								onPage()
						}} />
						<Icon name='chevron_left' md={30} onClick={() => {
							if (data.handleSubtract() && onPage)
								onPage()
						}} />

					</>
					:
					<Placeholder />
			}
		</>
	)

}

/**
 * DetailButtonLeft - kontrolka pozwalająca na dodawanie stron. Pozwala na zmienianie stron do przodu
 * Gdy obecna strona jest ostatnia, Kontrolka się nie wyświetla
 * Komponent <Placeholder /> - pozwala na utrzymanie rozmiarów paginatora gdy zniknie Ikonka
 * @returns {Node}
 */

const DetailButtonRight = ({ onPage }) => {
	const data = context.useContext()
	const { form: { currentPage, pages } } = data
	return (
		<>
			{
				currentPage !== pages ?
					<>
						< Icon name='chevron_right' md={30} onClick={() => {
							if (data.handleAdd() && onPage)
								onPage()
						}} />
						< Icon name='last_page' md={30} onClick={() => {
							if (data.handleAdd(true) && onPage)
								onPage()
						}} />
					</>
					:
					<Placeholder />
			}
		</>
	)

}

// const RowPerPage = () => {
//    return (
//       <>
//          <Icon name="arrow_drop_down" />
//       </>
//    )
// }
/**
 * DetailPaginatorContent - komponent stanowiący otoczkę paginatora
 * @returns {Node}
 */

const DetailPaginatorContent = ({ onPage }) => {
	const form = context.useContext()
	return (
		<>
			{
				form.pages > 1 &&
				<DetailPaginatorWrapper>
					<DetailButtonLeft onPage={onPage} />
					<PageSelector onPage={onPage} />
					<div > / </div>
					<div>
						{form.pages}
					</div>
					{/* <RowPerPage /> */}
					<DetailButtonRight onPage={onPage} />
				</DetailPaginatorWrapper>
			}

		</>
	)

}
/**
 * DetailPaginator - komponent stanowiący otoczkę oraz oplatający pozostałe komponenty w kontekst
 * @param {Object} form - objekt formy. W nim zawarte są informacje odnośnie bierzącej strony oraz ilości stron
 * @returns {Node}
 */
export default function DetailPaginator({ form, onPage }) {
	return (
		<context.Provider
			fixedData
			form={form}
			createData={() => new Data()}
		>
			<DetailPaginatorContent form={form} onPage={onPage} />
		</context.Provider>
	)
}