import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'
import { Drawer, } from '@material-ui/core'
import { NavLinksRoot, NavLinks, NavLink, NavLinkToExternalHref, NavLinkToJasperReport } from './NavLinks'
import { useStyles, StyledDivider, StyledSwitch, StyledNewIcon, SwitchWrapper } from "./StyledSidebar"
import { useTranslate } from 'locale/Locale'
import { Link } from "react-router-dom"
import { appCtrl } from 'AppCtrl'
import { GlobalStrings } from 'GlobalStrings'

//#region Context Creating
	export const SidebarContext = React.createContext()
//#endregion

/**
 * Komponent wyświetlajacy Switcha pozwalającego otwierać i zamykać menu boczne
 * @returns 
 */
const Switch = () => {
	const { isMenuOpen, setOpen } = useContext(SidebarContext)

	return (
		<SwitchWrapper>
			<StyledSwitch
				edge="end"
				onChange={() => setOpen(!isMenuOpen)}
				checked={isMenuOpen}
			/>
		</SwitchWrapper>
	)
}

function NavLinksToJasperReport() {
	const tmp = appCtrl.session.modules;
	const modules = tmp.filter(f=>f.name?.startsWith('JaspersoftReports'));

	if (modules.length===0)
		return React.Fragment;

	return modules.map((item,index)=>{
		return <NavLinkToJasperReport key={index} reportName={item.id} reportLabel={item.label} href={item.link}/>
	});
}

/**
 * Komponent bocznego panelu
 *
 * @returns {Node}
 */
export default function SideBar() {
	const SIDEBAR_CONFIGURATION_PATH = 'Sidebar' //Ścieżka pozwalająca na zapis stanu do konfiguracji
	const isMenuOpenFromConfiguration = appCtrl?.configuration?.getValue(SIDEBAR_CONFIGURATION_PATH) //Odczytywanie wartości inicjującej z konfiguracji
	const [isMenuOpen, setOpen] = useState(isMenuOpenFromConfiguration)
	const translate = useTranslate('WebSpa/MainMenu')
	const classes = useStyles()

	useEffect(() => {
		appCtrl.configuration.setValue(SIDEBAR_CONFIGURATION_PATH, isMenuOpen) //Zapisywanie aktualnej wartości do Konfiguracji
	},[isMenuOpen])

	return (
		<SidebarContext.Provider value={{isMenuOpen, setOpen}}>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: isMenuOpen,
					[classes.drawerClose]: !isMenuOpen,
				})}
				classes={{
					root: classes.root,
					paper: clsx(classes.root, {
						[classes.drawerOpen]: isMenuOpen,
						[classes.drawerClose]: !isMenuOpen,
					}),
				}}
				>
				<Link style={{ backgroundColor: GetLogoBackgroundColor() }} id="root-link" to='/' onClick={e => e.preventDefault()}>
					<StyledNewIcon
						name={GetLogoName(isMenuOpen)}
						width={GetLogoWidth(isMenuOpen)}
					/>
				</Link>
				<NavLinksRoot>
					<NavLink name='proposedJob' />
					<NavLink name='myTasksModule' />
					<NavLink name='inquiries' />
					<NavLink name='offers' />
					<NavLink name='projects' />
					<NavLink name='CRMModule' />
					<NavLink name='specification' />
					<NavLink name='companiesTS' />
					<NavLink name='qualityAssurance' />
					<NavLink name='complaint' />
					<NavLink name='priceLists' />
					<NavLink name='companies' />
					<NavLink name='myProfile' />
					<NavLink name='myProfileTS' />
					<NavLinks label={translate('Invoices/title')} icon='invoices'>
						<NavLink name='invoice' />
						<NavLink name='costInvoice' />
						<NavLink name='provisionalInvoice' />
						<NavLink name='receipt' />
						<NavLink name='prepaymentInvoice' />
						<NavLink name='correctingInvoice' />
					</NavLinks>
					<NavLinks label={translate('Reports/title')} icon='reports'>
						<NavLink name='priceListItemCompanySupplier' />
						<NavLink name='jaspersoftReports' label={translate('JaspersoftReports/title')} />
						<NavLink name='reportProfitability' />
						<NavLink name='reportProfitabilityGlobalDaily' />
						<NavLink name='reportProfitabilityGlobalMonthly' />
						<NavLink name='reportProfitabilityGlobalMonthlyLanguage' />
						<NavLink name='reportProfitabilityGlobalMonthlyPerson' />
						<NavLink name='reportProfitabilityGlobalMonthlyProduct' />
                        <NavLink name='appReportSupplierTasksToBeInvoiced' />
						<NavLink name='reportOfferEfficiency' />
						<NavLink name='reportQaEfficiency' />
						<NavLink name='persons' />
						<NavLinksToJasperReport/>
					</NavLinks>
					
					<NavLinks label={translate('SettlementModule/title')}  icon='reportProfitability'>
						<NavLink name='SettlementCompanies' />
						<NavLink name='settlementDocument' />
						<NavLink name='settlement' />
						<NavLink name='settlementSource' />
						<NavLink name='SettlementCompensation' />
						<NavLink name='CostDocument' />
						<NavLink name='payments' />
					</NavLinks>
					<NavLinks label={translate('Configuration/title')} icon='configuration'>
						<NavLinks label={translate('Product/title')} icon='configuration'>
							<NavLink name='featureTypes' />
							<NavLink name='productTypes' />
							<NavLink name='projDeliveryTemplate' />
						</NavLinks>
						<NavLink name='templates' />
						<NavLink name='group' />
						<NavLink name='currency' />
						<NavLink name='customerRetentionAlerts' />
						<NavLink name='templateDefModule' />
						<NavLink name='templateStringModule' />
						<NavLink name='translationPriceModifierCustomer' />
						<NavLink name='translationPriceModifierSupplier' />
						<NavLink name='customerSupplierTypePref' />
						<NavLink name='organizationConfiguration' />
						<NavLink name='organizationInternationalization' />
						<NavLink name='registryForDocuments' />
						<NavLink name='documentRegistryHistoryModule' />
						<NavLink name='vatTaxRate' />
						<NavLink name='translationSubengine' />
						<NavLink name='translationEnginePref' />
						<NavLink name='role' />
						<NavLink name='configurationModule' />
						<NavLink name='nonWorkingDays' />
						<NavLinks label={translate('AutomaticOffer/title')} icon='configuration'>
							<NavLink name='automaticOfferFileExtension' />
							<NavLink name='automaticOfferDocumentStyleMapping' />
						</NavLinks>
						<NavLink name='organizationProfile' />
					</NavLinks>
					<NavLinks label={translate('Administration/title')} icon='administration'>
						<NavLink name='organization' />
						<NavLink name='serviceUsage' />
						<NavLink name='internationalization' />
						<NavLink name='systemConfigurationModule' />
						<NavLink name='agreementFile' />
						<NavLink name='templateString' />
					</NavLinks>
					<NavLink name='teachingMaterials' />
				</NavLinksRoot>
				<StyledDivider />
				<Switch />
			</Drawer>
		</SidebarContext.Provider>
	)
}

function GetLogoName(isMenuOpen) {
	if (appCtrl?.session?.currentOrganizationInfo?.id === GlobalStrings.LinguaLabOrganizationId)
		return isMenuOpen ? "linguaLabLogo" : "linguaLabLogoType";

	if (appCtrl?.session?.currentOrganizationInfo?.id === GlobalStrings.PunktOrganizationId)
		return isMenuOpen ? "punktLogo" : "punktLogoType";

	if (appCtrl?.session?.currentOrganizationInfo?.id === GlobalStrings.IuridicoOrganizationId)
		return isMenuOpen ? "iuridicoLogo" : "iuridicoLogoType";

	return isMenuOpen ? "logotype" : "logo";
}

function GetLogoWidth(isMenuOpen) {
	if (appCtrl?.session?.currentOrganizationInfo?.id === GlobalStrings.LinguaLabOrganizationId)
		return isMenuOpen ? 230 : 38;

	if (appCtrl?.session?.currentOrganizationInfo?.id === GlobalStrings.PunktOrganizationId)
		return isMenuOpen ? 230 : 38;

	if (appCtrl?.session?.currentOrganizationInfo?.id === GlobalStrings.IuridicoOrganizationId)
		return isMenuOpen ? 230 : 38;
	
	return isMenuOpen ? 200 : 38;
}

function GetLogoBackgroundColor() {
	if (appCtrl?.session?.currentOrganizationInfo?.id === GlobalStrings.PunktOrganizationId)
		return "#FFFFFF";

	return "";
}
