/**
 * Moduł obsługi event
 * @module components/Event
 */

import React from 'react'


/**
 * Obsługa zdarzenia
 */
export default class Event {
	/**
	 * Tworzy zdarzenie
	 * @method Event
	 */
	constructor() {
		this.events = []
	}

	/**
	 * Rejestrowanie nasłuchującego na zdarzenie
	 *
	 * @param {function} listener Funkcja nasłuchująca na zdarzenie
	 */
	bind(listener) {
		this.events.push(listener)
	}

	/**
	 * Wyrejestrownie nasłuchującej funkcji na zdarzenie
	 *
	 * @param {function} listener funkcja nasłuchująca
	 */
	unbind(listener) {
		this.events.splice(this.events.indexOf(listener), 1)
	}

	/**
	 * Wysłanie powiadomienia do wszystkich zarejestrowanych funkcji
	 *
	 * @param {...} args Argumenty które zostaną przekazane do każdej nasłuchującej funkcji
	 */
	trigger(...args) {
		for (var i in this.events)
			this.events[i](...args)
	}

	/**
	 * Hook rejestrowania funkcji nasłuchującej
	 *
	 * @param {function} listener Funkcja nasłuchująca
	 */
	useListener(listener) {
		var _this = this

		React.useEffect(() => {
			_this.bind(listener)
			return () => {
				_this.unbind(listener)
			}
			// eslint-disable-next-line
		}, [])
	}
}