import React from 'react'
import { customElements } from 'cssStatic'
import {
	ButtonAdd,
	ButtonSubmit,
	ButtonsBE,
	ButtonReload,
	ButtonDownloadFile,
	ButtonDelete,
	ButtonCancel,
	ButtonExportData
} from './index'
import { Preloader } from 'components/form/Form'
import ButtonCRMCustomerOffers from './ButtonCRMCustomerOffers'

const ButtonsContainer = customElements('o-buttons', ['grid-hor', 'grid-gap-5', 'grid-aix-stretch', 'grid-acx-end', 'm-tb-10'])

export function Buttons({ isList, part }) {
	return (
		<Preloader>
			<ButtonsContainer>
				<ButtonAdd isList={isList} part={part} />
				<ButtonsBE isList={isList} part={part} />
				<ButtonSubmit isList={isList} />
				<ButtonCancel isList={isList} />
				<ButtonDelete isList={isList} />
				<ButtonDownloadFile isList={isList}/>
				<ButtonReload isList={isList}/>
				<ButtonCRMCustomerOffers isList={isList}/>
			</ButtonsContainer>
		</Preloader>
	)
}

