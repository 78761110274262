import React from 'react'
import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useFetchTaskBasedOnAuthToken, useFetchTaskBasedOnAuthTokenSwr } from './hooks/useApi';
import useSessionTask from 'contents/cat/hooks/useSessionTask';
import CircularPreloader from 'components/preloader/CircularPreloader';
import SingleBoxContainer from 'contents/cat/SingleBoxContainer'
import SimpleErrorView from './SimpleErrorView';
import { Provider as EditorContextProvider } from './contexts/editor/EditorContextState';
import { useEditor } from './hooks/useEditor';

const customStatusErrorMessages = {
	"404": "Could not fetch task description because it does not exists.",
	"401": "Could not fetch task description because you have no access rights.",
};

function FetchCurrentTaskInternal({ match, history }) {

	const { data, error, isLoading } = useFetchTaskBasedOnAuthTokenSwr();
	const currentTask = useSessionTask();
	const editor = useEditor();

	useEffect(() => {
		if (data?.payload) {
			currentTask.setTask(
				data.payload.id,
				data.payload.projectId,
				data.payload.languageDocuments,
				data.payload.canCompleteTask,
				data.payload.useMinimalCatFeatures,
				data.payload.name);
		}
	}, [data?.payload]);

	if(error)
	{
		return <SimpleErrorView errorObject={error} responseData={data} customStatusErrorMessages />
	}

	if (!data) {
		return (
			<SingleBoxContainer>
				<CircularPreloader label="Please wait, fetching task description.." />
			</SingleBoxContainer>
		);
	}

	if (currentTask.id && data?.payload) {
		if(currentTask.useMinimalCatFeatures)
			return (<Redirect to='/cat/task/editor' />)
		else
			return (<Redirect to='/cat/task' />)
		
	}

	return (<SingleBoxContainer></SingleBoxContainer>);
}

export default function FetchCurrentTask({ match, history }) {

	return (
		<EditorContextProvider>
			<FetchCurrentTaskInternal match={match} history={history} />
		</EditorContextProvider>
	)
}