import React from 'react'

export function GanttArrow({ x, y, cx, cy, color, onDoubleClick }) {
	return (
		<div
			style={{
				position: 'absolute',
				left: x + 'px',
				top: (y - cy / 2.0) + 'px',

				width: cx + 'px',
				height: cy + 'px',

				borderTop: (cy / 2.0) + 'px solid transparent',
				borderBottom: (cy / 2.0) + 'px solid transparent',
				borderLeft: cx + 'px solid ' + color
			}}
			onDoubleClick={onDoubleClick}
		/>
	)
}