import { Box, Button, Divider, FormControl, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Create, DeleteOutline } from '@material-ui/icons';
import React, { useEffect, useMemo, useState } from 'react'
import SidebarContentHeader from './SidebarContentHeader';
import { RequestOptionsCreator, RequestState } from 'contents/cat/contexts/data/RequestModel';
import LivoCatApiDataMapper, { performApiRequest } from 'contents/cat/contexts/data/LivoCatApiDataMapper';
import useUser from 'contents/cat/hooks/useUser';
import { useEditor } from 'contents/cat/hooks/useEditor';
import { DataContextOptions, useDataContextApi } from 'contents/cat/hooks/useDataContext';
import { dateTimeStringToFormatedString } from 'contents/cat/model/Utils';
import { DiscardColor, AcceptColor, InactiveColor } from 'contents/cat/consts/Styles';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../Tooltip';
import DialogYesNo, { PromiseResult } from '../dialogs/DialogYesNo';
import Dialog from '@material-ui/core/Dialog';
import { CircularProgress } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import shouldClose from '../dialogs/DialogUtils';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { CommentDtoProps, CommentDtoType, IDocumentCommentDto } from 'contents/cat/model/DocumentComment';
import { data } from 'msw/lib/types/context';
import { IEntityContextStateController } from 'contents/cat/contexts/data/DataContextModel';
import { ObjectChangeOperationType } from 'contents/cat/model/EditorMediator';
import useSessionTask from 'contents/cat/hooks/useSessionTask';

const useDialogAddCommentStyles = makeStyles((theme: Theme) =>
    createStyles({
      form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
      },
      formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
      },
      formControlLabel: {
        marginTop: theme.spacing(1),
      },
    }),
  );
  

export type DialogAddCommentProps = {
    documentId: string,
    translationUnitNumber: number,
	isOpen: boolean,
    user: any,
    onClose: (didSave: boolean, commentDto?: IDocumentCommentDto) => void
}

const DialogAddComment = (props: DialogAddCommentProps) => {
    const classes = useDialogAddCommentStyles();
    const [showLoading, setShowLoading] = useState(false);
    const [commentInternalId] = useState(crypto.randomUUID());

    const backendStorageOptions: DataContextOptions = useMemo(() => {
        return {
            create: RequestOptionsCreator.create(
                async (payload: any) => {
                    return await performApiRequest(props.user, "CREATE", "createDocumentComment", [props.documentId, payload] );
                },
                "createComment:" + props.documentId,
                new LivoCatApiDataMapper()
            ),
            dispose: true
        }
    }, [props.documentId]);
    const dataContext = useDataContextApi("AddComment:" + props.documentId, backendStorageOptions);

    useEffect(() => {
        if(!dataContext.contextState.bag.TriggerCloseAfterSave)
            return;

        props.onClose(true, dataContext.contextState.entityIndex[dataContext.contextState.entityOrderedSet[0]] as IDocumentCommentDto);
        dataContext.dispose();

    }, [dataContext.contextState.bag.TriggerCloseAfterSave]);

    const handleOnTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       dataContext.setAttributes(
        commentInternalId, 
        {
            [event.target.name]: event.target.value,
            [CommentDtoProps.translationSegmentId]: props.translationUnitNumber,
            __type: CommentDtoType
        });
    }

    const handleOnClose =(event: {}, reason: string) => {
        if(!shouldClose(false, reason))
            return;

        props.onClose(false);
        dataContext.dispose();
    }

    const handleOnSave = () => {
        if(!dataContext.hasChangesToUpdate)
            return;

        setShowLoading(true);
        dataContext.dispatchCreateRequest((key: string, state: RequestState) => {
            if(state !== RequestState.Pending)
                setShowLoading(false);
        },
        (key: string, state: RequestState, contextStateController: IEntityContextStateController) => {
            if(state === RequestState.Successed)
            {
                contextStateController.setBag('TriggerCloseAfterSave', true);
            }
        });
    }
    return (
        <Dialog
            open={props.isOpen}
            onClose={handleOnClose}
            fullWidth
        >
            <DialogTitle>Add Comment</DialogTitle>
            <DialogContent>
                <form className={classes.form}>
                    <FormControl className={classes.formControl} margin="dense">
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Comment"
                            placeholder="Type comment"
                            name={CommentDtoProps.comment}
                            onChange={handleOnTextChange}
                            multiline
                            fullWidth
                            disabled={showLoading}
                            />
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={() => props.onClose(false)} disabled={showLoading}>Dismiss</Button>
                <Button color="primary" onClick={handleOnSave}  disabled={showLoading}>
                    Save {showLoading &&
                        <CircularProgress size={20} color='inherit'  style={{marginLeft: '10px'}} />
                    }
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const SidebarListItemText = withStyles(() => ({
	root: {
		margin: 1
	},
    primary: {
        fontSize: '0.8rem'
    },
    secondary:{
        fontSize: '0.75rem'
    }
}))(ListItemText);

const SidebarContentComments = ({...other}) => {
    const user = useUser();
    const editor = useEditor();
    const task = useSessionTask();
    const [showDeleteAllDialog, setShowDeleteAllDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [deletingCommentsIds, setDeletingCommentsIds] = useState<string[]>([]);
    
    const backendStorageOptions: DataContextOptions = useMemo(() => {
        return {
            load: RequestOptionsCreator.load(
                !editor.state.activeDocumentId ? null : async () => {
                    return await performApiRequest(user, "GET", "getDocumentComments", [editor.state.activeDocumentId]);
                },
                "getDocumentComments:" + editor.state.activeDocumentId,
                new LivoCatApiDataMapper())
        };
    }, [editor.state.activeDocumentId]);
    const dataContext = useDataContextApi("DocumentComments", backendStorageOptions);

    const onDeleteComment = (commentId: string) => {
        if(!commentId)
            return;

        dataContext.dispatchCreateRequestWithOptions(
            RequestOptionsCreator.delete(async (payload: any) => {
                return await performApiRequest(user, "DELETE", "deleteDocumentComment", [editor.state.activeDocumentId, commentId]);
            },
            "deleteDocumentComment:" + commentId,
            new LivoCatApiDataMapper()),
            (key: string, state: RequestState) => {
                if(state == RequestState.Pending)
                {
                    setDeletingCommentsIds((prevState) => {
                        let newState = [...prevState];
                        newState.push(commentId);
                        return newState;
                    });
                }
                else
                {
                    setDeletingCommentsIds((prevState) => {
                        let newState = [...prevState];
                        newState = newState.filter( (val) => val !== commentId);
                        return newState;
                    });

                    if(state == RequestState.Successed)
                    {
                        const comment = dataContext.getEntity(commentId) as IDocumentCommentDto;
                        if(comment && comment.translationSegmentId)
                        {
                            editor.mediator.onDocumentCommentChanged(
                                editor.state.activeDocumentId,
                                {
                                    changes: [
                                        {
                                            operation: ObjectChangeOperationType.DELETED,
                                            languageDocumentId: editor.state.activeDocumentId,
                                            tuSequenceNumber: comment.translationSegmentId,
                                            objects: [comment]
                                        }
                                    ]
                                });
                        }
                        
                        dataContext.dispatchLoadRequest();
                    }
                }
            }
        );
    }

    const isEditionAllowed = task.canComplete;

    const items = dataContext.contextState.entityOrderedSet.map((objectId: string) => {
        const comment = dataContext.getEntity(objectId);
        const value = comment['comment'] as string;
        const userName = comment['userName'] as string;
        const createdAt = dateTimeStringToFormatedString(comment['createdAt'] as string);
        const isBeingDeleted = deletingCommentsIds.includes(comment.__internalId);
        const onClickItem = () => {
            const sequenceNumberId = 'translationSegmentId' in comment ? comment['translationSegmentId'] as number : undefined;
            console.log(sequenceNumberId);
            if(sequenceNumberId)
                editor.mediator.onGoToDocumentTU(sequenceNumberId);
        }

        return (
        <ListItem disableGutters button dense key={comment.__internalId} onClick={onClickItem} >
            <SidebarListItemText primary={value} secondary={userName + ", " + createdAt} />
            <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => onDeleteComment(comment.__internalId)} disabled={isBeingDeleted || !isEditionAllowed} >
                    <DeleteOutline style={{color: isEditionAllowed ? DiscardColor : InactiveColor}} /> 
                    {isBeingDeleted && 
                        <CircularProgress size={20} color='inherit'  style={{marginLeft: '10px'}} />
                    }
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>)
    });
    
    /* # Add Comment Dialog # */
    const onShowAddCommentDialog = () => {
        setShowAddDialog(true);
    }

    const onCloseAddComentDialog = (didSave: boolean, commentDto: IDocumentCommentDto) => {
        setShowAddDialog(false);
        if(didSave)
        {
            dataContext.dispatchLoadRequest();
            editor.mediator.onDocumentCommentChanged(
                editor.state.activeDocumentId,
                {
                    changes: [
                        {
                            operation: ObjectChangeOperationType.CREATED,
                            languageDocumentId: editor.state.activeDocumentId,
                            tuSequenceNumber: commentDto.translationSegmentId,
                            objects: [commentDto]
                        }
                    ]
                });
        }
    }

    /* # Delete All Comments Dialog # */
    const onShowDeleteAllCommentsDialog = () => {
        setShowDeleteAllDialog(true);
    }
    const onDeleteAllComments = () => {
        return new Promise<PromiseResult>((resolve, reject) => {
            dataContext.dispatchCreateRequestWithOptions(
                RequestOptionsCreator.delete(async (payload: any) => {
                    return await performApiRequest(user, "DELETE", "deleteDocumentComments", [editor.state.activeDocumentId]);
                },
                "deleteDocumentComments:" + editor.state.activeDocumentId,
                new LivoCatApiDataMapper()),
                (key: string, state: RequestState) => {
                    if(state == RequestState.Successed)
                    {
                        resolve({});
                        editor.mediator.onDocumentCommentChanged(
                            editor.state.activeDocumentId,
                            {
                                changes: [
                                    {
                                        operation: ObjectChangeOperationType.DELETED,
                                        languageDocumentId: editor.state.activeDocumentId
                                    }
                                ]
                            });
                        dataContext.dispatchLoadRequest();
                    }
                    else if(state == RequestState.Failed)
                    {
                        reject('failed');
                    }
                }
            );
        });
    }

    
    const isSelectedTu = editor.state.selectedTranslationUnitNumber && editor.state.selectedTranslationUnitNumber > 0;

    return (
        <>
        <div>
            <Tooltip title={'Add comment'} >
                <IconButton edge="end" disabled={!isSelectedTu || !isEditionAllowed} onClick={onShowAddCommentDialog}>
                    <AddIcon style={{
                        color: isSelectedTu ? AcceptColor : InactiveColor}} />
                </IconButton>
            </Tooltip>
            <Tooltip title={'Delete all comments'}>
                <IconButton edge="end" disabled={!items || !isEditionAllowed} onClick={onShowDeleteAllCommentsDialog} >
                    <DeleteOutline style={{color: !items || !isEditionAllowed ? InactiveColor : DiscardColor}} />
                </IconButton>
            </Tooltip>
        </div>
        <div>
        <List >
            {items}
        </List>
        </div>
        {editor.state.activeDocumentId && 
            <DialogAddComment 
                documentId={editor.state.activeDocumentId}
                translationUnitNumber={isSelectedTu ? editor.state.selectedTranslationUnitNumber : -1}
                user={user}
                isOpen={showAddDialog}
                onClose={onCloseAddComentDialog}
            />
        }
        
        <DialogYesNo 
            title='Delete all comments'
            text='Are you sure?'
            isOpen={showDeleteAllDialog} 
            onClose={() => {
                setShowDeleteAllDialog(false);
            }}
            onYes={onDeleteAllComments}
            />
        </>
        
    );
};

export default SidebarContentComments;