import React from 'react'
import useForm from 'components/form/hooks/useForm'

/**
 * Rysowanie wierszy normalnych to znaczy kolejnych wierszy
 */
export function Rows({ children }) {
	const form = useForm('data')
	const child = React.Children.only(children)

	return form.rowManager.visibleRows.map((row, rowIndex) => {
		return(
			<React.Fragment key={rowIndex}>
				{React.cloneElement(child, { key: row.id, rowIndex, row })}
			</React.Fragment>
		)
	})
}