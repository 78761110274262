import React from 'react'
import { MessageListener, useForm } from 'components/form/Form'
import Button from 'components/form/components/list/components/buttons/Button'
import ListPartInnerList from './dataView/ListPartInnerList'
import { Horizontal } from 'components/topology/Topology'
import { useTranslate } from 'locale/Locale'

export default function QualityAssuranceList({ part }) {
	const form = useForm('data')
	const translate = useTranslate('WebSpa/QualityAssurance/Agregation')
	const [aggregateOption, setAggregateOption] = React.useState(0)
	const set = (v) => {
		setAggregateOption(v)
		form.aggregateOption = v
		form.asyncLoad()
	}

	return (
		<div className='layout-part white'>
			<MessageListener />
			<div className='grid-ver'>
				<h6 className='p-lr-15'>{translate('title')}</h6>
				<Horizontal className='grid-acx-start grid-gap-5 p-lr-15'>
					<Button type={aggregateOption === 0 ? 'submit' : 'cancel'} onClick={() => set(0)} content={translate('Buttons/lack')} />
					<Button type={aggregateOption === 1 ? 'submit' : 'cancel'} onClick={() => set(1)} content={translate('Buttons/companyAndLanguage')} />
					<Button type={aggregateOption === 2 ? 'submit' : 'cancel'} onClick={() => set(2)} content={translate('Buttons/company')} />
					<Button type={aggregateOption === 3 ? 'submit' : 'cancel'} onClick={() => set(3)} content={translate('Buttons/personAndLanguage')} />
					<Button type={aggregateOption === 4 ? 'submit' : 'cancel'} onClick={() => set(4)} content={translate('Buttons/person')} />
				</Horizontal>
				<ListPartInnerList part={part} />
			</div>
		</div>
	)
}
