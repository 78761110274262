export class MarkManager {
	_marks = new Set()

	clear() {
		this._marks = new Set()
		this._select = undefined
	}

	isMark(id) {
		return this._marks.has(id)
	}

	setMark(id, bMark) {
		if (bMark) {
			this._marks.add(id)
			this._select = id
		} else {
			this._marks.delete(id)
			this._select = undefined
		}
	}

	select(id) {
		this._marks.clear()
		this.setMark(id, true)
		this._select = id
	}

	selectRange(toId, rows) {
		if (!this._select)
			return

		let index0, index1

		rows.forEach((row, index) => {
			if (row.id === this._select)
				index0 = index
			if (row.id === toId)
				index1 = index
		})

		if (index0 > index1) {
			let h = index0
			index0 = index1
			index1 = h
		}

		this._marks.clear()
		for (let i = index0; i <= index1; ++i)
			this._marks.add(rows[i].id)
	}

	toggleMark(id) {
		this.setMark(id, !this.isMark(id))
	}

	get count() { return this._marks.size }
	get ids() { return [...this._marks] }
}