import React from 'react'

export function GanttHorLine({ x1, y1, x2, lineWidth, color, onDoubleClick }) {
	let x = Math.min(x1, x2)
	let y = y1 - lineWidth / 2
	let width = Math.abs(x2 - x1) + 1
	let height = lineWidth

	return (
		<div
			style={{
				position: 'absolute',
				left: x + 'px',
				top: y + 'px',
				backgroundColor: color,
				width: width + 'px',
				height: height + 'px'
			}}
			onDoubleClick={onDoubleClick}
		/>
	)
}