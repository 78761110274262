import React from 'react'
import { useFetchProjectTasksSwr } from 'contents/cat/hooks/useApi';
import useSessionTask from 'contents/cat/hooks/useSessionTask';
import MaterialTableMetaDriven from 'contents/cat/MaterialTableMetaDriven';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';

// Komponent prezentuje liste dokumentów przynależących do zadania
export default function ProjectTasks({ match, history }) {
	const currentTask = useSessionTask();
	const { data, isLoading } = useFetchProjectTasksSwr(currentTask.projectId);
	const resourceDocument = data?.resourceDocument;

	let resourceMeta = null
	let resourceData = null
	let detailPanel = []
	if (resourceDocument) {
		resourceMeta = resourceDocument.meta
		resourceData = resourceDocument.data

		// Dane do panelu ze szczegółami
		const listDetailsAttribute = resourceMeta.attributes.find(attribute => { return attribute.sections.includes('listDetails') });
		if (resourceMeta && listDetailsAttribute) {
			detailPanel = (rowData) => {
				const executionMessages = rowData[listDetailsAttribute.name]
				if (executionMessages) {
					const detailPanelMeta = {
						attributes: [
							/*{
								label: 'Created At',
								name: 'createdAt',
								sections: ['list'],
								width: '50px',
								type: 'datetime'
							},*/
							{
								label: 'Message',
								name: 'message',
								sections: ['list']
							}
						]
					};

					const detailPanelData = executionMessages.map(em => {
						return {
							id: em.createdAt,
							type: "ExecutionMessages",
							attributes: {
								'createdAt': em.createdAt,
								'message': em.message
							}
						}
                    })
					return (
						<Box margin={1}>
							<MaterialTableMetaDriven
								meta={detailPanelMeta}
								data={detailPanelData}
								title="Messages"
								options={{
									pageSize: 15,
									pageSizeOptions: [15, 20],
									emptyRowsWhenPaging: false,
									padding: 'dense',
									filtering: false,
									selection: false,
									search: false,
									header: false,
									showTitle: true,
									maxBodyHeight: '250px'
								}}
							/>
						</Box>
					)
				}
				return null
			}
		}
	}

	return (
		<MaterialTableMetaDriven
			style={{ marginBottom: '5px' }}
			meta={resourceMeta}
			data={resourceData}
			title="Tasks"
			isLoading={isLoading}
			options={{
				pageSize: 15,
				pageSizeOptions: [15, 20],
				emptyRowsWhenPaging: false,
				padding: 'dense',
				filtering: false,
				selection: false,
				search: false,
				showTitle: true,
				maxBodyHeight: null
			}}
			detailPanel={detailPanel}
		/>
	)
}