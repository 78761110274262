import React from 'react'
import { useForm } from 'components/form/Form'
import { Refresh } from '@material-ui/icons'
import Button from './Button'

function isVisible(form, isList) {
	if (isList)
		return !!form.serviceRead

	return form.serviceReadOne && !form.isChanged && form.editMode !== 'create'
}

/**
 * Przycisk z ikoną "refresh" pozwalający na przeładowanie stanu aktualnej formy
 * @returns Przycisk typu Reload, wykonujący akcję asyncSubmit na aktualnej formie. Akcja ta pozwala na przeładowanie aktualnego stanu formy
 */
export function ButtonReload({ isList }) {
	const form = useForm('data')

	if (!isVisible(form, isList))
		return null

	// const isArray = form.parentForm && form.parentForm.rows !== undefined && form.editMode !== 'edit'

	// if (isArray) {
	// 	if (!form.serviceRead)
	// 		return null
	// } else {
	// 	if (!form.serviceReadOne || form.isChanged || form.editMode === 'create')
	// 		return null
	// }

	// const handleButtonClick = e => {
	// 	if (isArray)
	// 		form.parentForm.asyncLoad()
	// 	else
	// 		form.asyncLoad('reload')

	// 	e.stopPropagation()
	// }

	return (
		<Button
			type='icon'
			icon={<Refresh style={{ fontSize: 25 }} />}
			id={`${form._dataType ?? ''}-ButtonReload`}
			onClick={e => {
				if (isList)
					form.asyncLoad()
				else
					form.asyncLoad('reload')

				e.stopPropagation()
			}}
			style={{ padding: '3px 10px', minWidth: 35 }}
		/>
	)
}