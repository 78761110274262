import React, { useState } from 'react'
import { isEllipsisActive } from '../../Tools/Tools'
import { trim } from 'lodash'
import { StyledTooltipOnlyWhenEllipsisActive } from './Tooltip.styled'

/**
 * Komponent Tooltipa wyświetlający podpowiedź tylko wtedy, gdy tekst nie mieści się w ustalonych ramach (jest zakończony "...").
 * @param {String} title - Tekst do wyświetlenia w Tooltipie
 * @param {String} children - Komponent opleciony Tooltipem
 * @returns {Node} - Komponent warunkowo wyświetlający Tooltip
 */
export const TooltipOnlyWhenEllipsisActive = ({ title, children }) => {
	const [isTooltipActive, setIsTooltipActive] = useState(false)

	const handleIsTooltipActive = e => {
		setIsTooltipActive(isEllipsisActive(e))
	}

   if(!trim(title) || typeof title !== 'string')
      return null

   return(
     <StyledTooltipOnlyWhenEllipsisActive 
        title={title} 
        onOpen={e => handleIsTooltipActive(e)}
        onClose={e => setIsTooltipActive(false)}
        open={isTooltipActive}
      >
        {children}
     </StyledTooltipOnlyWhenEllipsisActive>
   )
}