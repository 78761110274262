import { useState } from 'react';
import { createContainer } from 'react-tracked';

/**
 * Służy do zapisania pobranych wartości kontrolki referencyjnej
 */
const useValue = () => useState(null);

export const {
  Provider: ComboboxRefListProvider,
  useTrackedState: useComboboxRefListState,
  useUpdate: useComboboxRefListSetState,
} = createContainer(useValue);