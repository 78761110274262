/**
 * Zamienia style na kolor 
 * Dostępne style to:
 * 	primary, secondary, success, danger, warning, info, error, default
 * @param {string} style - Nazwa stylu z BE
 * @param {object} palette - Obiekt palety kolorów z kontrolki Material-UI.
 * @returns {string} kolor 
 */
export function styleToColor(style, palette) {
	if (!palette)
		return '#1e272e'
		
	switch (style) { ///TODO DOAĆ POZOSTAŁE CASE
		case 'primary':
			return '#599ca4'
		case 'success':
			return palette.primary?.success
		case 'primary':
			return palette.primary?.mainDarken800
		case 'error':
			return palette.primary?.errorAlternative
		case 'warning':
			return palette.primary?.warning
		default:
			return '#1e272e' //TODO Przenieśc do theme
	}
}