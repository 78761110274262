
import React, { useMemo } from 'react'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { Radio, RadioGroup } from '@material-ui/core';
import { DataContextOptions, useDataContextApi } from './hooks/useDataContext';
import { RequestOptionsCreator, RequestState } from './contexts/data/RequestModel';
import LivoCatApiDataMapper, {performApiRequest} from './contexts/data/LivoCatApiDataMapper';
import useUser from './hooks/useUser';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
  }),
);

export interface ManualTaskSettingsDialog
{
    onClose: () => void;
    projectId: string,
    taskId: string
}

type EnumValue = {
  id: number,
  name: string
}

type SettingControl = {
  id: string,
  label: string,
  enumValues?: EnumValue[]
}

const startPositionControl: SettingControl = {
  id: 'startingPosition',
  label: 'Starting position',
  enumValues: [
    {
      id: 0,
      name: 'First segment in document'
    },
    {
      id: 1,
      name: 'Next segment in document'
    },
    {
      id: 2,
      name: 'Auto-propagate to all segments in all documents'
    }
  ]
};

const controlledSettings: SettingControl[] = [
  {
    id: 'enabled',
    label: 'Enabled'
  },
  {
    id: 'confirmSegmentAfterPropagation',
    label: "Confirm segment after propagation"
  },
  {
    id: 'propagateTranslationToConfirmedSegments',
    label: 'Propagate translation to confirmed segments'
  },
  startPositionControl
];

export default function ManualTaskSettingsDialog({onClose, projectId, taskId}: ManualTaskSettingsDialog)
{
    const classes = useStyles();
    const user = useUser();
    const backendStorageOptions: DataContextOptions = useMemo(() => {
      return {
        load: RequestOptionsCreator.load(
          async () => {
            return await performApiRequest(user, "GET", "getProjectManualTaskSettings", [projectId, taskId]);
          },
          "getProjectManualTaskSettings" + projectId + "_" + taskId + "_" + new Date().getTime(),
          new LivoCatApiDataMapper()
        ),
        update: RequestOptionsCreator.update(
          async (payload: any) => {
            return await performApiRequest(user, "UPDATE", "updateProjectManualTaskSettings", [projectId, taskId, payload]);
          },
          "updateProjectManualTaskSettings",
          new LivoCatApiDataMapper()
        ),
        dispose: true
      }
    }, [projectId, taskId]);

    const dataContext = useDataContextApi("ManualTaskSettings:" + taskId, backendStorageOptions);
    const isLoading = dataContext.contextState.requests.length == 0 || dataContext.contextState.requests.at(-1).state == RequestState.Pending;
    const entity = dataContext.contextState.entityOrderedSet.length > 0 ? dataContext.getEntity(dataContext.contextState.entityOrderedSet[0]) : null;
    const onCheckboxChange =  (event: React.ChangeEvent<{}>, checked: boolean) => {
      if(!entity)
        return;

        const targetControl = event.currentTarget as HTMLInputElement;
        dataContext.setAttribute(entity.__id, targetControl.name, checked);
    }

    const onRadioChange =  (event: React.ChangeEvent<{}>, value: string) => {
      if(!entity)
        return;

        const targetControl = event.currentTarget as HTMLInputElement;
        dataContext.setAttribute(entity.__id, targetControl.name, {id: parseInt(value)});
    }

    const onSave = () =>{
      if(dataContext.hasChangesToUpdate()){
        dataContext.dispatchUpdateRequest((key, state) => {
          console.log(key, state);
          if(state === RequestState.Successed)
          {
            onClose();
          }
        });
      }
      else
      {
        onClose();
      }
    };

    console.log(entity);
    const requestInfo = dataContext.getLastRequest();
    return (
        <Dialog
            fullWidth={false}
            maxWidth={'sm'}
            open={true}
            onClose={onClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Settings</DialogTitle>
            <DialogContent>
              {isLoading 
                ? <CircularProgress />
                : <form className={classes.form}>
                    <FormControl className={classes.formControl} margin="dense" size="small">
                        {requestInfo && requestInfo.state === RequestState.Failed &&
                          <FormHelperText error={true} >Could not load or save settings.</FormHelperText>
                        }
                        <FormLabel component="legend" style={{paddingBottom: '5px'}}>Auto propagation</FormLabel>
                        <FormGroup>
                          {
                            controlledSettings.filter(v => v.id !== startPositionControl.id).map( cs => {
                              const value = Boolean(entity[cs.id]);
                              return (<FormControlLabel
                                  key={cs.id}
                                  control={<Checkbox name={cs.id} />}
                                  checked={value}
                                  onChange={onCheckboxChange}
                                  label={cs.label}
                              />);
                            })
                          }
                            
                        </FormGroup>
                    </FormControl>
                    <FormControl component="fieldset"  style={{marginTop: '15px'}}>
                    <FormLabel component="legend">{startPositionControl.label}</FormLabel>
                    <RadioGroup name={startPositionControl.id}
                        value={(entity[startPositionControl.id] as any).id.toString()}
                        onChange={onRadioChange}>
                          {
                            startPositionControl.enumValues.map(ev => (
                              <FormControlLabel value={ev.id.toString()} control={<Radio />} label={ev.name} />
                            ))
                          }
                    </RadioGroup>
                    </FormControl>
                  </form>
              }
                
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={isLoading} >
                    Dismiss
                </Button>
                <Button onClick={onSave} color="primary" disabled={isLoading} >
                    Save
                </Button>
            </DialogActions>
      </Dialog>
    );
}