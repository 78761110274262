import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslate } from 'locale/Locale'
import Button from './Button'
import { linkManager } from 'components/LinkManager'
 
/**
 * Przycisk pozwalający na wgranie pliku wraz z określonymi przez BE argumentami
 * Tłumaczenie labelki tego przycisku trzymamy w FE
 * @param {Object} button - Obiekt przycisku otrzymywany z BE
 * @returns Przycisk typu AddNewFileVersion, pozwalający na dodanie pliku wraz z określonymi przez BE wartościami pól
 */
export function ButtonAddNewFileVersion({ button, form, part }) {

	const translate = useTranslate(`WebSpa/Buttons/${button.name}`)
	const history = useHistory()


	if(!form?.serviceCreate){
		console.log(`Nie wyświetlono przycisku ${button.name} - brak linku create`)
		return null
	}

	/**
	 * Funkcja odpowiedzialna za przepisanie otrzymanych w obiekcie przycisku atrybutów do formy
	 * oraz za przejście bezpośrednio do widoku  /create (z uzupełnionymi otrzymanymi z BE parametrami)
	 * @param {*} e 
	 */
	const handleAddNewFileVersion = () => {
		//Przepisujemy interesujące nas parametry do zmiennej prywatnej. Dalsze użycie w pliku LinkManager.js
		form.parentForm.rowManager._rowAttributes = { attributes: button.uiButtonActionArguments?.attribiutesValues } //TODO Te atrybuty powinny być przekazywane w inny sposób
		linkManager.handleListId(history, part) //Tworzymy ścieżkę /create
	}

	return (
		<>
			<Button
				type='submit'
				id={`${form.dataType ?? ''}-${button.name}`}
				content={translate('label')}
				button={button}
				onClick={() => handleAddNewFileVersion()}
			/>
		</>
	)
}

//#region PropTypes
ButtonAddNewFileVersion.propTypes = {
	button: PropTypes.object.isRequired,
}

//#endregion
