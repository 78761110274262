import { List, lighten } from '@material-ui/core'
import styled from 'styled-components'

 const getColor = ({disabled, error, isDragAccept, isDragReject, isDragActive, theme}) => {

	if (disabled)  return theme.colors.grey300

	if (error) return theme.colors.error

	if (isDragAccept)  return theme.colors.dropzoneDragActive

	if (isDragReject) return theme.colors.dropzoneDragReject

	if (isDragActive) return theme.colors.dropzoneDragActive

	return theme.colors.primaryDarken;
 }

 ///TODO style
 export const StyledDropzoneLabel = styled.div`
 	position: absolute;
	top: -14px;
	left: 10px;
	background: ${({theme}) => theme.colors.white};
	padding: 0 10px 0 8px;
	font-weight: ${({required}) => required ? '500' : '400'};
	color: ${props => getColor(props)};
	transition: color .1s linear;
 `
 
 ///TODO style
 export const StyledDropzoneWrapper = styled.div`
	border-color: ${props => getColor(props)};
	background: ${({theme}) => theme.none};
	color: ${props => getColor(props)};
	cursor: ${({disabled}) => disabled ? 'no-drop' : 'pointer'};
	border-radius: ${({theme, hasLabel}) => hasLabel === false ? '0' : theme.borders.defaultRadius};
	border: ${({hasLabel}) => hasLabel === false ? 'none' : '2px dashed'};
	margin: ${({hasLabel}) => hasLabel === false ? '0' : '7px 0'};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	outline: none;
	position: relative;
	min-height: 40px;
	
	&:hover{
		border: ${({theme, hasLabel}) => hasLabel === false ? `1px solid ${theme.colors.black}` : `2px dashed` };
	}
 `

///TODO style
export const StyledDropzone = styled.div`
	min-height: ${({hasLabel}) => hasLabel ? '80px' : '100%'};
	display: flex;
	justify-content: center;
	align-items: center;
`

///TODO style
export const StledFileList = styled(List)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	color: ${({theme}) => theme.colors.primaryDarken800};
`