import { Box } from '@material-ui/core'
import React from 'react'
import ProjectDocuments from './ProjectDocuments'
import ProjectTasks from './ProjectTasks'
import ProjectTranslationMemories from './ProjectTranslationMemories'

// Komponent prezentuje liste dokumentów przynależących do zadania
export default function ProjectDetails({ match, history }) {

	return (
		<Box style={{height: '100%', 'overflowY': 'scroll'}}>
			<ProjectDocuments />
			<ProjectTasks />
			<ProjectTranslationMemories />
		</Box>
	)
}