import React from 'react'
import PropTypes from 'prop-types'
import { Card, FormControl, FormLabel, makeStyles } from '@material-ui/core'
import { styleToColor } from 'Tools/styleToColor'
import './Sections.scss'
import { SectionContentWrapper } from './SectionContentWrapper'

//#region  Style
const useMainSectionStyles = makeStyles(({ palette }) => ({
	formLabelRoot: {
		color: props => styleToColor(props.style, palette),
		fontWeight: 500,
		margin: '0 0 21px 0',
		fontSize: 16,
		textTransform: 'capitalize'
	},
	cardRoot: {
		padding: '15px 15px 30px 15px',
		borderRadius: 0,
		borderLeft: '4px solid',
		borderColor: props => styleToColor(props.style, palette),
		overflowX: 'auto'
	}
}))
//#endregion

/**
 * Komponent wyświetlający sekcję z główną Main
 * 
 * @param {Object} section Obiekt sekcji nadrzędnej - rodzic grupujący inne sekcje z tabami
 * @returns {Node}
 */
export function SectionMain({ section, children }) {
	const { formLabelRoot, formControlRoot, cardRoot } = useMainSectionStyles({ style: section.style })

	return (
		<FormControl className={formControlRoot}>
			<Card className={cardRoot}>
				<FormLabel className={formLabelRoot} component="legend">
					{section.label}
				</FormLabel>
				<SectionContentWrapper>
					{children}
				</SectionContentWrapper>
			</Card>
		</FormControl>
	)
}


//#region PropTypes & DefaultProps
SectionMain.propTypes = {
	section: PropTypes.object.isRequired,
	children: PropTypes.node
}
//#endregion