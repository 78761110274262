
import React, { useRef } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { Radio, RadioGroup, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import stringResources from './StringResources';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(1),
      minWidth: 140,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
  }),
);

type CheckboxFilter = {
    id: number,
    label: string
};

const qaFilters: CheckboxFilter[] = [
    {
        id: 1001,
        label: 'Date format'
    },
    {
        id: 1002,
        label: 'Time format'
    },
    {
        id: 1003,
        label: 'Number format'
    },
    {
        id: 1004,
        label: 'Letter case'
    },
    {
        id: 1005,
        label: 'Spell check'
    },
    {
        id: 1006,
        label: 'Tags mismatch'
    },
    {
        id: 1009,
        label: 'Unexpected tag'
    },
    {
        id: 1007,
        label: 'Word repetitions'
    },
    {
        id: 1008,
        label: 'Inconsistent translation'
    },
];

/** Definiuje Filtr QA */
export type QAFilters = {
    /** Wybrane walidacje QA */
    selectedQaChecks?: number[],
    /** Liczba segmentów wchodzących w skład kontekstu. Czyli liczba segmentów poprzedzających i następujących segment
     * zawierający problemy wykryte w ramach walidacji QA.
     */
    numberOfSegmentsInContext: number
};

/** Kierunek sortowania */
export type SortDirection = 'ASC' | 'DESC';

/** Definiuje filtry QE (oceny tłumaczenia) */
export type QEFilters = {
    minScore?: number,
    maxScore?: number,
    orderByScore?: SortDirection,
}

export type EditorFilters = {
    qaFilters?: QAFilters,
    qeFilters?: QEFilters,
    /** Filtrowanie segmentów zawierających terminologie z glosariusza */
    segmentsWithTerminology?: boolean
};

function getNumber(event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>): number
{
    const value = Number.parseFloat(event.target.value)
    if(value === NaN)
        return null;

    return value;
}

function getSortDirection(event: React.ChangeEvent<HTMLInputElement>): SortDirection
{
    const value = (event.target as HTMLInputElement).value;
    if(value)
        return value === 'ASC' ? 'ASC' : 'DESC';

    return null;
}

export interface EditorFiltersDialogProps {
    isOpen: boolean;
    onClose: (filters: EditorFilters) => void;
    filters: EditorFilters
};

export default function EditorFiltersDialog({isOpen, onClose, filters}: EditorFiltersDialogProps)
{
    const classes = useStyles();
    const [checkedCheckboxes, setCheckedCheckboxes] = useState<Array<number>>(filters.qaFilters?.selectedQaChecks ?? []);
    const [segmentsInContext, setSegmentsInContext] = useState<number|null>(filters.qaFilters?.numberOfSegmentsInContext);
    const [qeScoreMin, setQeScoreMin] = useState<number|null>(filters.qeFilters?.minScore);
    const [qeScoreMax, setQeScoreMax] = useState<number|null>(filters.qeFilters?.maxScore);
    const [qeScoreSortDirection, setQeScoreSortDirection] = useState<SortDirection|null>(filters.qeFilters?.orderByScore);
    const [segmentsWithTerminology, setSegmentsWithTerminology] = useState<boolean|null>(filters.segmentsWithTerminology);

    if(isOpen === false)
        return null;

    const handleSelectAll = () => {
        if(checkedCheckboxes.length === qaFilters.length)
            setCheckedCheckboxes([]);
        else
            setCheckedCheckboxes(qaFilters.map(filter => filter.id));
    };

    const handleSegmentsWithTerminology = (e: any, checked: boolean) => {
        setSegmentsWithTerminology(checked);
    };

    const handleToggleCheckbox = (e: any, checked: boolean) => {
        const checkboxId: number = parseInt(e.target.name);
        if(checked)
        {
            setCheckedCheckboxes((prevValue: number[]) =>{
                return [...prevValue, checkboxId];
            } );
        }
        else
        {
            setCheckedCheckboxes((prevValue: number[]) =>{
                return prevValue.filter((value) => value !== checkboxId);
            } );
        }
    };

    const saveFiltersAndClose = (clear: boolean) => {
        const filters: EditorFilters = {};
        if(!clear)
        {
            if(checkedCheckboxes.length > 0){
                filters.qaFilters = {
                    selectedQaChecks: checkedCheckboxes,
                    numberOfSegmentsInContext: segmentsInContext
                }
            }
    
            if(qeScoreMin != null)
            {
                filters.qeFilters = {
                    ...filters.qeFilters,
                    minScore : qeScoreMin
                }
            }
    
            if(qeScoreMax != null)
            {
                filters.qeFilters = {
                    ...filters.qeFilters,
                    maxScore : qeScoreMax
                }
            }
    
            if(qeScoreSortDirection != null)
            {
                filters.qeFilters = {
                    ...filters.qeFilters,
                    orderByScore : qeScoreSortDirection
                }
            }

            if(segmentsWithTerminology != null)
            {
                filters.segmentsWithTerminology = segmentsWithTerminology;
            }
        }
        else
        {
            setCheckedCheckboxes([]);
            setSegmentsInContext(null);
            setQeScoreMin(null);
            setQeScoreMax(null);
            setQeScoreSortDirection(null);
            setSegmentsWithTerminology(null); 
        }
        
        console.log(filters);
        onClose(filters);
    };

    const handleOnClose = () => {
        saveFiltersAndClose(false);
    };

    const handleOnClear = () => {
        saveFiltersAndClose(true);
    };

    return (
        <Dialog
            fullWidth={false}
            maxWidth={'lg'}
            open={isOpen}
            onClose={handleOnClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">Filters</DialogTitle>
            <DialogContent>
                <form className={classes.form}>
                    <Grid container>
                        <Grid item sm={4} >
                            <FormControl className={classes.formControl} margin="dense" size="small">
                                <FormLabel component="legend" style={{paddingBottom: '5px'}}>QA Checks</FormLabel>
                                <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox name="gilad" onClick={handleSelectAll} checked={checkedCheckboxes.length === qaFilters.length} />}
                                    label="Select all"
                                />
                                {
                                    qaFilters.map((filter) => (
                                        <FormControlLabel
                                            key={filter.id}
                                            control={<Checkbox name={filter.id.toString()} />}
                                            checked={checkedCheckboxes.includes(filter.id)}
                                            onChange={handleToggleCheckbox}
                                            label={filter.label}
                                        />
                                    ))
                                }
                                </FormGroup>
                                <FormGroup>
                                <TextField
                                    id="standard-number"
                                    label="Segments in context"
                                    type="number"
                                    size="small"
                                    value={segmentsInContext}
                                    onChange={(e) => {
                                        setSegmentsInContext(getNumber(e));
                                    }}  
                                    InputProps={{
                                        inputProps: {
                                            min: 0, max: 50
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item sm={4}>
                            <FormControl className={classes.formControl} margin="dense" size="small">
                                <FormLabel component="legend" style={{paddingBottom: '5px'}}>QE Score</FormLabel>
                                <FormGroup>
                                <TextField
                                    id="qe-score-min"
                                    label="Min"
                                    type="number"
                                    size="small"
                                    value={qeScoreMin}
                                    onChange={(e) => {
                                        setQeScoreMin(getNumber(e));
                                    }}  
                                    InputProps={{
                                        inputProps: {
                                            min: 0, max: 5, step: 0.1
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                 <TextField
                                    id="qe-score-max"
                                    label="Max"
                                    type="number"
                                    size="small"
                                    value={qeScoreMax}
                                    onChange={(e) => {
                                        setQeScoreMax(getNumber(e));
                                    }}  
                                    InputProps={{
                                        inputProps: {
                                            min: 0, max: 5, step: 0.1
                                        }
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                </FormGroup>
                            </FormControl>

                            <FormControl component="fieldset"  style={{marginTop: '15px'}}>
                            <FormLabel component="legend">Sort by QE Score</FormLabel>
                            <RadioGroup aria-label="Order by QE Score" name="order-by-qe-score" 
                                value={qeScoreSortDirection ?? ''}
                                onChange={(e) => {
                                    setQeScoreSortDirection(getSortDirection(e));
                                }}>
                                <FormControlLabel value="" control={<Radio />} label="None" />
                                <FormControlLabel value="ASC" control={<Radio />} label="Ascending" />
                                <FormControlLabel value="DESC" control={<Radio />} label="Descending" />
                            </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item sm={4}>
                            <FormControl className={classes.formControl} margin="dense" size="small">
                                <FormLabel component="legend" style={{paddingBottom: '5px'}}>{stringResources.filterDialogSegmentsGroup.label}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox name="terminology" onChange={handleSegmentsWithTerminology} checked={segmentsWithTerminology} />}
                                        label={stringResources.filterDialogSegmentsTerminologyCheckbox.label}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>

                    
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClear} color="primary">
                    Clear
                </Button>
                <Button onClick={handleOnClose} color="primary">
                    Apply
                </Button>
            </DialogActions>
      </Dialog>
    )
}