import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
import { withStyles, Switch, Divider, Drawer } from '@material-ui/core'
import NewIcons from 'components/icon/NewIcons'
import { customElements } from 'cssStatic'

const drawerWidth = 260

///TODO style
export const StyledNewIcon = styled(NewIcons)`
   grid-area: logo;
   align-self: center;
   justify-self:flex-start;
   margin: 15px 0 0 12px;
`

///TODO style
export const SwitchWrapper = styled.div`
   padding-right: 30; 
   align-self:flex-start;
`

///TODO style
export const useStyles = makeStyles((theme) => ({
   root: {
      background: theme.palette.primary.mainDarken800,
      display: 'grid',
      gridTemplateColumns: '100%',
      gridTemplateRows: '90px 1fr 10px',
      gridTemplateAreas: `"logo" "content" "switch"`,
      gridRowGap: 0
   },
   drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
   },
   drawerOpen: {
      overflowX: 'hidden',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   },
   drawerClose: {
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
         width: theme.spacing(9) + 1,
      },
   },

}))

///TODO style
export const StyledDivider = withStyles(({ palette }) => ({
   root: {
      backgroundColor: palette.primary.mainDarken800
   },
}))(Divider)

///TODO style
export const StyledDrawer = withStyles(({ palette }) => ({
   root: {
      backgroundColor: 'palette.primary.mainDarken800'
   },
}))(Drawer)

///TODO style
export const StyledSwitch = withStyles(({ palette }) => ({
   root: {
      margin: '20px 0',
      gridArea: 'switch',
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
         backgroundColor: palette.primary.yellow,
         opacity: 0.8,
      }
   },
   thumb: {
      backgroundColor: palette.secondary.white,
   },
   track: {
      backgroundColor: palette.primary.error,
      opacity: 0.5,
   }
}))(Switch)