import { css } from 'styled-components'
import { GetFromTheme } from './GetFromTheme'

/**
 * 
 * @param {string} style: normal|italic|oblique|initial|inherit;
 * @param {string} weight: normal|bold|bolder|lighter|number|initial|inherit;
 * @param {string} size rozmiar fontu
 * @param {string} family: 
 * @returns {Array}
 */
export function MixinFont(p) {
	if (typeof (p) === 'string')
		return css`
			${props => {
				let f = GetFromTheme(props, 'fontDefinition', p, { fontFamily: 'primary', fontSize: 'md' })
				let style = f.style ? f.style : 'normal'
				let weight = f.weight ? f.weight : 'normal'
				let size = GetFromTheme(props, 'fontSize', f.fontSize)
				let family = GetFromTheme(props, 'fontFamily', f.fontFamily)

				if (!Number.isNaN(size))
					size = size + 'px'

				return `font ${style} ${weight} ${size} ${family};`
			}}
		`
	let { style, weight, size, family } = p

	style = style ? style : 'normal'
	weight = weight ? weight : 'normal'
	size = GetFromTheme(p, 'fontSize', size)
	family = GetFromTheme(p, 'fontFamily', family)

	if (!Number.isNaN(size))
		size = size + 'px'

	return css`
		font: ${style} ${weight} ${size} ${family};
	`
}