import { css } from 'styled-components'
import { MixinColor, MixinBackground } from './Color'
import { Border } from './Border'
/**
 * Mixin odpowiedzialny za style dla nieaktywnych inputów 
 */
export const MixinDisabled = () => {
	const disabled = css`
		cursor: not-allowed;
		box-shadow: none;
		${Border('primary')};
		${MixinBackground('grey200')};
		${MixinColor('disabled')};

		& > div {
			 ${MixinColor('disabled')};
		}

		&:focus {
			outline: none;
			${Border('primary')};
		}

		&:focus i {
			color: transparent;
		}

		::placeholder {
			${MixinColor('disabled')};
		}
	`
	return disabled
}
