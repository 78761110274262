import FormBase from './FormBase';
import FormMetaObject from './FormMetaObject';
import { GlobalStrings } from 'GlobalStrings';
import config from 'config';

export default class FormMetaArrayObjects extends FormBase {

	constructor(params) {
		super(params)
	}

	serviceRead() {
		throw new Error('You have to implement the method serviceRead!');
	}

	serviceReadOne() {
		throw new Error('You have to implement the method serviceReadOne!');
	}

	get serviceReadHRef() {
		let s = this.serviceRead
		if (s)
			return s.href
		return undefined
	}

	get serviceReadOneHRef() {
		let s = this.serviceReadOne
		if (s)
			return s.href
		return undefined
	}

	/**
	 * Metoda przeniosi attrybuty z ui do meta
	 */
	copyUIAttribiuteInfoToUIMetaAttribiute() {
		if (this._data?.ui?.attributes && this._data.meta?.attributes) {
			for (let key in this._data.ui.attributes) {
				const attribiuteMeta = this._data.meta.attributes[key];
				if (attribiuteMeta !== null) {
					this._data.meta.attributes[key] = { ...attribiuteMeta, ...this._data.ui.attributes[key] };
				}
			}
		}
	}

	/**
	 * Znajdź link do pobierania pliku
	 * @param {string} name nazwa pola z plikiem 
	 * @param {Guid} id identyfikator pliku
	 * @param {bool} throwError informacja czy rzucić błędem w przypadku nie znalezienia plików
	 * @returns Znaleziony link lub undefined
	 */
	serviceDownloadFile(name, id, throwError = true) {
		let link = this.getSpecifiedLink(GlobalStrings.DOWNLAD_FILES_LINK_PREFIX, name);

		if (!link || !link.href) {
			//Jeśli nie znajdujemy linku w FormMetaObject sięgamy do podobnej funkcji z FormMetaArray. 
			//Sytuajca gdy w danej tabeli żaden wiersz nie jest zaznaczony
			link = this.parentForm.getSpecifiedLink(GlobalStrings.DOWNLAD_FILES_LINK_PREFIX, name);
		}

		// sytuacja kiedy mamy edycje pojedynczego obiektu (formularz z edycją obiektu)
		if (this instanceof FormMetaObject) {
			if (!link || !link.href) {
				if (this._data && this._data.data[0] && this._data.data[0].links) {
					link = this._data.data[0].links[GlobalStrings.DOWNLAD_FILES_LINK_PREFIX + name];
				}
			}
		}

		// Szukamy linków w zaznaczonych rekordach
		if (this.rowManager?.marksOrSelectedDatas?.data?.length > 0) {
			if (!link || !link.href) {
				link = this._findFileDownLoadLinkInSelectedFiles(link, name);
			}
		}

		if (link && id && link.href) {
			return this._replacePatternFileIdWithId(link,id);
		}
		else if (throwError) {
			const error = "Link dla tego pola nie istnieje"
			this.setInternalError(name, error)
			this.printErrorsToConsole("Błąd wewnętrzny", this._internalError)
		}

		return undefined;

	}

	getLink(name) {
		if (this._data.data[0]?.links && name in this._data.data[0]?.links)
			return this._data.data[0]?.links[name]
		else if (this._data?.links && name in this._data.links)
			return this._data.links[name]

		else return undefined
	}
	
	getLinkHRef(linkName) {
		const link = this.getLink('get' + linkName)

		if (!link)
			return

		let href = link.href.slice(config.serviceApiVer.length)

		if (!link.params)
			return href

		for (const parameterName in link.params) {
			const objectAttributeName = link.params[parameterName];

			// KCH: Wydaje mi się, że this.getValue(parameterName) nie ma racji bytu dlatego zakomentuje ten kod
			// Do weryfikacji czy to nei zepsuje jakiegoś przypadku... 
			// let v = this.getValue(parameterName)
			// if (v)
			// 	href = href.replace(parameterValue, v.id)

			let attributeValue = this.getValue(objectAttributeName);
			if(attributeValue === undefined && objectAttributeName === "id")
				attributeValue = this._data?.data[0]; // Tak właściwie przypisujemy obiekt do attributeValue. Dzięki temu nie ma konieczności alokacji nowej referencji.

			if (attributeValue)
				href = href.replace(parameterName, attributeValue.id);
		}

		return href
	}

	/**
	 * Znajdź link do pobrania pliku w zaznaczonych wierszach
	 * @param {String} link 
	 * @param {String} name 
	 */
	_findFileDownLoadLinkInSelectedFiles(link, name) {

		let selectedRowData = this._data?.data?.
			filter(f => f.id === Object.values(this.rowManager.marksOrSelectedDatas.data)[0].id);

		for (const key in selectedRowData) {
			if (Object.hasOwnProperty.call(selectedRowData, key)) {
				const element = selectedRowData[key];
				link = element?.links[GlobalStrings.DOWNLAD_FILES_LINK_PREFIX + name];

				if (link) {
					return link;
				}
			}
		}

		return link;
	}

	/**
	 * Znajdź link do pobrania wszystkich plików
	 * @param {String} name Nazwa pola 
	 * @param {*} id Id pliku
	 */
	findAllFilesDownLoadLink(id) {

		// Szukamu globalnego linku do pobierania plików
		let link = this.getSpecifiedLink(GlobalStrings.DOWNLAD_ALL_FILES_LINK_PREFIX, '');

		if (!link) {
			return undefined;
		}

		return this._replacePatternFileIdWithId(link, id);
	}

	/**
	 * Zwróć pliki, które mają linki - jeśli jest globalny link to zwracamy wszystkie
	 * @param {Array[object]} files lista plików, które filtrujemy 
	 */
	filterFilesWithDownloadLink(files) {

		if (!files || files.length === 0)
			return files;

		const fieldName = files[0]?.fieldName;
		const pattern = GlobalStrings.DOWNLAD_FILES_LINK_PREFIX.concat(fieldName).toLocaleLowerCase();

		let fileList = [];

		// Szukamu globalnego linku do pobierania plików - jeśli go znajdujemy to zwracamy wszystkie pliki
		if (this.getSpecifiedLink(GlobalStrings.DOWNLAD_FILES_LINK_PREFIX, fieldName)) {
			return files;
		}

		// Szukamy linku w konkretnych rekordach
		if (this?._data?.data) {
			for (let rowData of this._data.data) {

				if (!rowData.links)
					continue;

				const keys = Object.keys(rowData?.links);
				for (let key of keys) {
					if (key.toLocaleLowerCase() === pattern) {
						const file = files.filter(f => f.id === rowData.id);
						if (file)
							fileList.push(...file);
					}
				}
			}
		}

		return fileList;
	}

	/**
	 * Znajdź link do zahardkodowanego guzika do pobrania plików
	 */
	getHardCodedDownloadFilesLink(hardcodedDownloadButtonMeta, id) {	
		let link = this._getTemplateHardCodedDownloadFilesLink(hardcodedDownloadButtonMeta);
		if ( hardcodedDownloadButtonMeta.name == "ButtonDownloadFilesByUPA"){
			return this._replacePatternFileTokenWithToken(link, id);
		}

		return this._replacePatternFileIdWithId(link, id);
	}
	/**
	 * Znajdź pliki do pobrania umieszczone w parametrach zahardkodowanego linku do pobrania plików
	 */
	getHardCodedDownloadFilesFromLink(hardcodedDownloadButtonMeta) {
		let link = this._getTemplateHardCodedDownloadFilesLink(hardcodedDownloadButtonMeta);
		let files = link?.params[GlobalStrings.LinkFileListParam];
		return files;
	}

	/**
	 * Znajdź szablon linku do pobrania pliku (bez id pliku)
	 */
	_getTemplateHardCodedDownloadFilesLink(hardcodedDownloadButtonMeta) {
		let link;
		if (this instanceof FormMetaObject) {
			link = this?.data?.data[0]?.links[hardcodedDownloadButtonMeta.name];
		}
		else {
			link = this.rowManager?.marksOrSelectedDatas?.data[0]?.links[hardcodedDownloadButtonMeta.name];
		}
		return link;
	}

	_replacePatternFileIdWithId(link, fileId) {
		if (link && fileId && link.href) {
			link.href = link.href.replace("api/v1/", "/");
			return link.href.replace("{fileId}", fileId);
		}
		return undefined;
	}

	_replacePatternFileTokenWithToken(link, token) {
		if (link && token && link.href) {
			link.href = link.href.replace("api/v1/", "/");
			return link.href.replace("{token}", token);
		}
		return undefined;
	}
}