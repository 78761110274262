import { DependencyList, useMemo, useRef } from "react";
import { EntityId, IEntity } from "../contexts/data/DataContextModel";
import { DataContextApi } from "./useDataContext";

class MemoizedEntityProjection
{
    readonly entity: IEntity;
    readonly projection: any;

    constructor(entity: IEntity, projection: any){
        this.entity = entity;
        this.projection = projection;
    }
}

/**
 * Hook, którego zadaniem jest memoizacja kolekcji jak i poszczególnych elementów tej kolekcji. Umożliwia tworzenie
 * nowych encji/obiektów lub komponentów na podstawie encji z DataContext. Przykład: mając pewną kolekcje obiektów
 * na podstawie której generujemy tabele nie chcemy aby zmiana jednego z nich spowodowała przerenderowanie 
 * całej tabeli tylko chcemy się ograniczyć np. do tego jedynego konkretnego wiersza. Ten hook może to ułatwić,
 * ponieważ zapamiętuje obiekt źródłowy i powstały obiekt docelowy. Jeśli źródło nie zostało zmienione, to hook
 * zwróci nową tablice z poprzednimi obiektami (o ile nie zostaly zmienione).
 * Standardowo u
 * @param factory - Fabryka projekcji dla podanej encji.
 * @param orderedSet - Tablica identyfikatorów encji, która określa ich kolejność w zbiorze.
 * @param dataContext - Kontekst danych, do którego należą encje.
 * @returns 
 */
const useMemoEntitiesProjection = (
        factory: (entityId: EntityId, dataContext: DataContextApi, index: number, memoScopeState: any ) => any, 
        orderedSet: Array<EntityId>, 
        dataContext: DataContextApi,
        trackingMode: boolean): any => {

    // Tutaj przechowujemy poprzednią wersje mapowania obiektu źródłowego na docelowy. 
    // Poprzednią wersje czyli taką na podstawie której hook zwrócił dane.
    const previousMemoizedProjections = useRef(Array<MemoizedEntityProjection>());
    const previousTrackingMode = useRef(false);
    return useMemo(() => {
        const results = [];
        const currentMemoizedProjections: Array<MemoizedEntityProjection> = [];
        const memoScopeState = {};

        for(let orderIndex = 0; orderIndex < orderedSet.length; orderIndex++)
        {
            const entityId: EntityId = orderedSet[orderIndex];
            const currentEntity = dataContext.getEntity(entityId);
            // Sprawdzamy czy aby nie mamy tej encji w poprzednim zbiorze danych.
            // Bierzemy tutaj pod uwagę kolejność występowania encji w poprzednim zbiorze.
            // Nie obsługujemy sytuacji, gdzie encje mogą znajdować sie na różnych indeksach w obu tablicach.
            if(orderIndex < previousMemoizedProjections.current.length){
                const previousMemoizedEntityProjection = previousMemoizedProjections.current[orderIndex];
                const previouMemoizedEntity = previousMemoizedEntityProjection.entity;
                if(currentEntity === previouMemoizedEntity && previousTrackingMode.current === trackingMode)
                {
                    // Obecna encja wskazuję na tą samą co poprzednio. Zwracamy zapamiętaną wartość.
                    const previousMemoizedProjection = previousMemoizedEntityProjection.projection;
                    results.push(previousMemoizedProjection);
                    currentMemoizedProjections.push(previousMemoizedEntityProjection);
                    continue;
                }
            }

            const currentProjection = factory(entityId, dataContext, orderIndex, memoScopeState);
            currentMemoizedProjections.push(new MemoizedEntityProjection(currentEntity, currentProjection));
            results.push(currentProjection);
        }

        previousMemoizedProjections.current = currentMemoizedProjections;
        previousTrackingMode.current = trackingMode;
        return results;
    }, [orderedSet, dataContext.contextState.entityIndex, dataContext.contextState.entityDraftIndex, trackingMode]);
};

export default useMemoEntitiesProjection;