import { appCtrl } from 'AppCtrl'
import { isEmpty } from 'lodash'

/**
 * Funkcja mapująca tablice języków dostępnych w aplikacji otrzymany z BE, na tablicę obiektów według wzoru: {id: cultureCod, value: languageName}.
 * Tylko taka tablica zostanie poprawnie zinterpretowana i wyświetlona w komponencie 'ComboboxEnum' użytym do wyświetlania listy języków.
 * @returns {Array}
 */
const mapLanguagesToEnumArray = () => {

  if(isEmpty(appCtrl?.locale?.languages))
    return []

  const languagesArray = appCtrl?.locale?.languages.map(language => {
    if(!isEmpty(language.cultureCode) && !isEmpty(language.name)){
      return {
        id: language.cultureCode,
        value: language.name
      }
    }
    else{
      console.error('in Internationalization.js at func mapLanguagesToEnumArray() - Brak parametru "cultureCode" lub "name" dla obiektu:', language)
    }
  })

  return isEmpty(languagesArray) ? [] : languagesArray
}

const  internationalizationObject = {
  data: [
    {
      id: "0052ef31-2a78-4e43-801f-96162be5654a",
      meta: {
        buttons: {},
        dataType: "LanguageInfo",
        sections: {},
        attributes: {},
        forceAllAttributesUpdate: false,
        reloadOnChangeAttributes: []
      },
      links: {
        update: {}
      },
      type: "LanguageInfo",
      attributes: {}
    }
  ],
  meta: {
    buttons: {},
    dataType: "LanguageInfo",
    sections: {
      list: {
        name: "list",
        label: "list"
      },
      user: {
        name: "user",
        label: "user"
      }
    },
    attributes: {
      FileImport: {
        name: "FileImport",
        type: "File",
        label:  appCtrl?.locale?.translate("WebSpa/MainMenu/Internationalization/Field/fileImport/Label"),
        sections: [
          "user",
          "list"
        ],
        placeholder:  appCtrl?.locale?.translate("WebSpa/MainMenu/Internationalization/Field/fileImport/Placeholder"),
        uiInputType: "DropZone",
        sequenceIndex: 30,
        shouldBreakRow: true,
        visibleOnCreate: true,
        editableOnCreate: true,
        editableOnUpdate: true,
      },
      TargetLanguage: {
        name: "TargetLanguage",
        type: "Enum",
        label: appCtrl?.locale?.translate("WebSpa/MainMenu/Internationalization/Field/targetLanguage/Label"),
        values: mapLanguagesToEnumArray(),
        sections: [
          "user",
          "list"
        ],
        sequenceIndex: 20,
        shouldBreakRow: false,
        visibleOnCreate: true,
        editableOnCreate: true,
        editableOnUpdate: true,
        shouldReloadOnChange: false
      },
      ReferenceLanguage: {
        name: "ReferenceLanguage",
        type: "Enum",
        label: appCtrl?.locale?.translate("WebSpa/MainMenu/Internationalization/Field/referenceLanguage/Label"),
        values: mapLanguagesToEnumArray(),
        sections: [
          "user",
          "list"
        ],
        sequenceIndex: 10,
        shouldBreakRow: false,
        visibleOnCreate: true,
        editableOnCreate: true,
        editableOnUpdate: true,
      },
        OnlyNotTranslated: {
        name: "OnlyNotTranslated",
        type: "Bool",
        label: appCtrl?.locale?.translate("WebSpa/MainMenu/Internationalization/Field/onlyNotTranslated/Label"),
        sections: [
          "user",
          "list"
        ],
        sequenceIndex: 30,
        shouldBreakRow: false,
        visibleOnCreate: true,
        editableOnCreate: true,
        editableOnUpdate: true,
      },
    },
    forceAllAttributesUpdate: false,
    reloadOnChangeAttributes: []
  },
  messages: [
    {
      name: "Context",
      type: "InternalMessage",
      correlationId: "fe90e771-2e41-4bb4-8b9c-3a2c0992d3aa"
    }
  ],
  pages: 1,
  currentPage: 2
}

export default internationalizationObject