export class TreeManager {

	/**
	 * Konstruktor
	 * @param {Object} form Forma do której jest podłączony ten menager drzewa
	 */
	constructor(form) {
		this._form = form
	}

	/**
	 * @returns {boolean} Czy ta forma ma wyswietlać się jako drzewo
	 */
	get isTree() {
		return this.parentColumnName in this._form.data.meta.attributes
	}

	/**
	 * Nazwa kolumny w której jest przechowywana wartość parenta wiersza
	 */
	get parentColumnName() {
		return this._form.data.meta.treeParentRef
	}

	/**
	 * @returns {Object} Zwraca drzewo wierszy w formacie:
		{
			parent			// wskazanie na parenta tego (jest undefined jesli to root)
			rowIndex,		// index wierwsza w form.rowManager.visibleRows
			row,				// Wiersz z formy	(jest undefined jesli to root)
			children: []	// Tablica dzieci w tym formacie
		} 
	 */
	get tree() {
		return this._makeTreeRows(this._form.rowManager.visibleRows)
	}

	/**
	 * Sprawdzenie czy child jest pod parentem
	 *
	 * @param {*} parent
	 * @param {*} child
	 * @returns {boolean}
	 */
	_isChild(parent, child) {
		if (!parent.row)
			return true

		const parentChildValue = child.row.attributes[this.parentColumnName]

		if (!parentChildValue)
			return false

		// eslint-disable-next-line
		return parent.row.id == parentChildValue.id
	}

	/**
	 * Dodaje child do parenta
	 *
	 * @param {*} parent 
	 * @param {*} child
	 * @returns 
	 */
	_addChildRows(parent, child) {
		if (this._isChild(parent, child)) {
			child.parent = parent
			parent.children.push(child)
			return child
		}

		return this._addChildRows(parent.parent, child)
	}


	_makeTreeRows(rows) {
		let parent = { children: [] }

		if (rows.length < 1)
			return parent

		let lastChild = parent
		rows.forEach((row, rowIndex) => {
			lastChild = this._addChildRows(lastChild, { rowIndex, row, children: [] })
		})

		return parent
	}
}