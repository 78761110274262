import React from 'react'
import { MessageListener } from 'components/form/Form'
import ListPartInnerList from './ListPartInnerList'
import { ZipList } from 'components/zipList/ZipList'
import { fromForm } from 'components/zipList/fromForm'
import { toForm } from 'components/zipList/toForm'
import { customElements } from 'cssStatic'
import useForm from 'components/form/hooks/useForm'
import { Card } from 'components/form/components/list/components'
import Button from 'components/form/components/list/components/buttons/Button'
import { Tooltip } from '@material-ui/core'
import { useTranslate } from 'locale/Locale'

const SwitchButtonBack = customElements('o-inqueries-zip-list-switch-button-back', ['f-26', 'fi-google', 'p-5', 'cursor-pointer', 'transition-5', {
	'&:hover': {
		'background-color': '#f9f9ff'
	},
	'color': `var(--o-primaryDarken700)`
}])

export function ZipListWrapper(props) {
	const [state, setState] = React.useState(false)
	const { part, group, sort, dataTypeAdd, isGantt, inlineEditionOnly, onClickRow, onDoubleClickRow, onLaunch } = props
	const form = useForm('data')
	const zipButtons=Object.values(form.parentForm?.data?.meta?.buttons ?? {})?.filter(button => button.uiAction === 'ZipList' && button?.params?.childDataType === (form._useLinkName ? form._useLinkName : form.dataType))
	const zipButton = zipButtons.find(button=>form.parentForm?.data?.data?.[0]?.links[button.name])
	const translateZip = useTranslate('WebSpa/ZipList')

	if (state && zipButton)
		return <Card>
			<ZipList
				additionalButtons={() =>
					<Tooltip title={translateZip('back')}>
						<SwitchButtonBack onClick={() => setState(!state)}>
							arrow_back
						</SwitchButtonBack>
					</Tooltip>
				}
				values={fromForm(form, zipButton.params)}
				onSave={zipContext => {
					if (!form.serviceUpdateHRef)
						return

					const dataToSend = toForm(form, zipContext.values, zipButton.params)
					const formData = { data: dataToSend }

					if (dataToSend.length > 0)
						form.asyncSubmit('saveRows', formData)
					zipContext.changes = false
				}}
				readOnly={!form.serviceUpdateHRef}
			/>
		</Card>

	return (
		<ListPartInnerList
			part={part} group={group} sort={sort}
			dataTypeAdd={dataTypeAdd} isGantt={isGantt} inlineEditionOnly={inlineEditionOnly}
			onClickRow={onClickRow} onDoubleClickRow={onDoubleClickRow} onLaunch={onLaunch}
			additionalButtons={(!form.serviceUpdate || !zipButton) ? false : (() =>
				<Button
					type="submit"
					id={`${form.dataType}-ZipList`}
					onClick={() => setState(!state)}
					content={zipButton.label}
				/>)
			}
		/>
	)
}

export default function ListPartInner(props) {
	return (
		<div className='layout-part'>
			<MessageListener />
			<ZipListWrapper {...props} />
		</div>
	)
}
