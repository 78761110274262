import React from 'react'
import RefreshableContext from 'components/refreshableContext/RefreshableContext'

const contextGroup = RefreshableContext.create()
const contextElements = RefreshableContext.create()
const contextObservers = RefreshableContext.create()

class Rect {
	constructor(boundingClientRect) {
		this.x1 = boundingClientRect.left + window.scrollX
		this.y1 = boundingClientRect.top + window.scrollY
		this.x2 = boundingClientRect.right + window.scrollX
		this.y2 = boundingClientRect.bottom + window.scrollY
		this.cx = boundingClientRect.width
		this.cy = boundingClientRect.height
	}

	get rgCn() {
		return { x: this.x2, y: (this.y1 + this.y2) / 2.0 }
	}

	get lfCn() {
		return { x: this.x1, y: (this.y1 + this.y2) / 2.0 }
	}

	move(x, y) {
		this.x1 -= x
		this.x2 -= x
		this.y1 -= y
		this.y2 -= y
	}
}

class GroupClass {
	constructor(delay) {
		this.delay = delay
		this._observed = new Map()
	}

	addRef(id, ref) {
		this._observed.set(id, ref)
	}

	getRef(id) {
		return this._observed.get(id)
	}

	deleteRef(id) {
		this._observed.delete(id)
	}

	getRect(id, correctionForAbsolutePosition) {
		const ref = this.getRef(id)

		if (!ref)
			return

		const current = ref.current

		if (!current)
			return

		const r = new Rect(current.getBoundingClientRect())

		if (correctionForAbsolutePosition) {
			let firstRelative

			for (let e = current.parentElement; e; e = e.parentElement) {
				let d = window.getComputedStyle(e)
				d = d.position
				if (d === 'relative') {
					firstRelative = e
					break
				}
			}

			if (firstRelative) {
				const rr = new Rect(firstRelative.getBoundingClientRect())
				r.move(rr.x1, rr.y1)
			}
		}

		return r
	}

	getRects(ids, correctionForAbsolutePosition) {
		return ids.map(id => this.getRect(id, correctionForAbsolutePosition))
	}
}

class ElementsClass {
	constructor(group) {
		this.group = group
		group.elements = this
	}
}

class ObserversClass {
	constructor(group) {
		this.group = group
		group.observers = this
	}
}

export function Group({ children, delay }) {
	if (!delay)
		delay = 1

	return (
		<contextGroup.Provider
			fixedData
			createData={() => new GroupClass(delay)}
		>
			{children}
		</contextGroup.Provider>
	)
}

export function Elements({ children }) {
	const group = contextGroup.useContext()

	return (
		<contextElements.Provider
			fixedData
			createData={() => new ElementsClass(group)}
		>
			{children}
		</contextElements.Provider>
	)
}

export function Observers({ children }) {
	const group = contextGroup.useContext()

	return (
		<contextObservers.Provider
			fixedData
			createData={() => new ObserversClass(group)}
		>
			{children}
		</contextObservers.Provider>
	)
}

export function useAddElement(id) {
	const group = contextGroup.useContext()
	const ref = React.useRef()

	React.useEffect(() => {
		group.addRef(id, ref)
		if (!ref.current)
			group.elements.refresh()

		ref.current._id = id

		let lastBoundingClientRect
		const interval = setInterval(() => {
			if (!ref.current)
				return

			let boundingClientRect = ref.current.getBoundingClientRect()

			if (!lastBoundingClientRect ||
				boundingClientRect.left !== lastBoundingClientRect.left ||
				boundingClientRect.right !== lastBoundingClientRect.right ||
				boundingClientRect.top !== lastBoundingClientRect.top ||
				boundingClientRect.bottom !== lastBoundingClientRect.bottom) {
				lastBoundingClientRect = boundingClientRect
				if (group.observers)
					group.observers.delayedRefresh(1)
				return
			}
		}, group.delay)

		return () => {
			clearInterval(interval)
			if (group.getRef(id) === ref)
				group.deleteRef(id)
		}
	}, [id])

	return ref
}

export function useGroup() {
	return contextGroup.useContext()
}

export function useObservers() {
	return contextObservers.useContext()
}

export function usePositionObserver() {
	//const g=contextGroup.useContext()

	// return g
	const observeds = contextObservers.useContext()

	//console.log(`use ${g?'g':'_'} ${observeds?'o':'_'}`)

	if (!observeds)
		return
	return observeds.group
}

export function usePositionElement(id, correctionForAbsolutePosition) {
	return usePositionObserver().getRect(id, correctionForAbsolutePosition)
}

export function usePositionElements(ids, correctionForAbsolutePosition) {
	return usePositionObserver().getRects(ids, correctionForAbsolutePosition)
}