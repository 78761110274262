import { customElements } from 'cssStatic'
import { MixinGrid } from './Grid'

/** 
 * Mixin ustawiajacy poziomo kolejne dzieci
 * @param {string} g szerokość odstępu pomiedzy dziećmi w poziomie: lg | md | sm | css rozmiar. Domyślnie md
 * @param {string} ac ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
 * @param {string} ai ustawienie dzieci w kierunku y:  start | end | center | stretch. Domyślnie center
 * @return {Node}
*/
export function MixinHorizontal({ g, ac, ai, mw, height }) {
	return MixinGrid({
		aiy: ai ? ai : 'center',
		gx: g,
		acx: ac,
		f: 'column',
		mw: mw,
		height: height
	})
}

// /** 
//  * Rysuje poziomo kolejne dzieci
//  * @param {string} g szerokość odstępu pomiedzy dziećmi w pionie: lg | md | sm | css rozmiar. Domyślnie md
//  * @param {string} ac ustawienie komponetu w kierunku x: start | end | center | stretch | space-around | space-between | space-evenly
//  * @param {string} ai ustawienie dzieci w kierunku y:  start | end | center | stretch. Domyślnie center
//  * @return {Node}
// */
// export const Horizontal = styled.div`
// 	${props => MixinHorizontal(props)}
// `

export const Horizontal = customElements('o-horizontal', ['grid-hor'])