import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import RefreshableContext from 'components/refreshableContext/RefreshableContext'
import  Data  from './UploadFileClass'
import { Grid, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography, Paper, Grow} from '@material-ui/core'
import { StyledDropzoneLabel, StyledDropzone, StyledDropzoneWrapper, StledFileList } from './UploadFile.styled'
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack'
import { AttachFileOutlined, BackupOutlined, Clear} from '@material-ui/icons'
import { useField } from 'components/form/Form'
import { isEmpty } from 'lodash'
import { useFileProgress } from 'components/FileDownloadProgressDialog';

//TODO:  2) Dodać memoryzację komponentów 

//#region Tworzenie kontekstu
	const context = RefreshableContext.create()
//#endregion


/**
 * Komponent Labelki. W zależności czy pole jest wymagana, labelka zmienia style.
 * @param {Object} dropzoneProps - Obiekt zawierajacy najważniejsze właściwości Dropzone'y. Potrzebny w prawidłowym stylowaniu komponentu
 * @returns {Node} komponent Labelki dla Dropzone
 */
const DropzoneLabel = ({ dropzoneProps }) => {
	const data = context.useContext()

	if(!data.hasLabel)
		return null

	return (
		<StyledDropzoneLabel {...dropzoneProps} >
			{`${data.label} ${data.required ? "*" : ""}`}
		</StyledDropzoneLabel>
	)
}

/**
 * Treść wyświetlana w Dropzone, w zależności od propsa hasLabel
 * @returns { Node }
 */
const DropzoneContent = () => {
	const data = context.useContext()

	if (data.hasLabel) return <Typography component={"p"} noWrap>{data.placeholder}</Typography>

	else return <BackupOutlined style={{fontSize: 30}}/>
}

/**
 * Komponent tworzący podgląd plików. Iteruje po całej tablicy z plikami oraz wyświetla je w liście poniżej komponentu Dropzone
 * @returns Komponent listy wyświetlający pliki
 */
const FilesPreview = () => {
	const data = context.useContext()

	if(isEmpty(data.files) || !data.hasLabel)
		return null

	return (
		<StledFileList >	
				{data.files.map((file, index) => (	
					<Grow in={true} key={index}>
						<Paper elevation={2} style={{margin: '5px 0', width: '100%'}}>
							<ListItem>
								<ListItemAvatar>	
									<AttachFileOutlined style={{color: '#1a314b'}} />	
								</ListItemAvatar>	
								<ListItemText primary={`${file.name}, ${file.fileSizeString}`} style={{marginRight: 20, whiteSpace: 'nowrap'}} />	
								<ListItemSecondaryAction>
									<IconButton  edge="end" aria-label="delete" onClick={(e) => data.deleteFile(file.id)}>
										<Clear />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</Paper>
					</Grow>
				))}
		</StledFileList>	
	)
}

/**
 * Komponent Dropzone wraz z obsługą wszystkich zdarzeń.
 * @returns Komponent Dropzone
 */
const Dropzone = () => {
	const data = context.useContext()
	const fileProgress = useFileProgress();

	const onDrop = useCallback(async (allFiles, rejFiles) => { 
		await  data.uploadFilesToBE(allFiles, fileProgress)
		data.addFilesToCurrentForm()
	}, [data.files])
	
	const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({ onDrop, disabled: data.disabled}) 

	const dropzoneProps = {
		isDragActive, 
		isDragAccept,
		isDragReject,
		disabled: data.field?.disabled,
		required: data.field?.required,
		error: Boolean(data.field?.error),
	}

	return (
		<Grid item>
			<StyledDropzoneWrapper hasLabel={data.hasLabel} {...getRootProps({...dropzoneProps})}>
				<StyledDropzone hasLabel={data.hasLabel}>
					<DropzoneLabel dropzoneProps={dropzoneProps} />
					<input {...getInputProps()} />
					<DropzoneContent />
				</StyledDropzone>
			</StyledDropzoneWrapper>
			<FilesPreview />
		</Grid>
	)
}

/**
 * @param {String} name - nazwa kontrolki
 * @param {String} serviceName - Link pod który wysyłamy pliki do BE
 * @param {Boolean} hasLabel - Informacja czy komponent jest wyświetlany normalnie czy w widoku Edycji wiersza
 * @returns {Node} Komponent wyświetlający Dropzone wraz z podglądaem przesłanych plików
 */
const UploadFile = ({name, hasLabel, serviceName,  ...other}) => 
{	
	const { enqueueSnackbar, closeSnackbar} = useSnackbar()
	const field = useField(name)

	return (
		<context.Provider
			fixedData
			field={field}
			name={name}
			hasLabel={hasLabel}
			serviceName={serviceName || field.form.serviceUploadFile(field)}
			createData={() => new Data(enqueueSnackbar, closeSnackbar)}
		>
			<Dropzone />
		</context.Provider>
	)
}

/* #region Export komponentu */
	export default UploadFile
/* #endregion */

/* #region  PropTypes */

UploadFile.propTypes = {
	name: PropTypes.string.isRequired,
	serviceName: PropTypes.string,
	hasLabel: PropTypes.bool,
}

DropzoneLabel.propTypes = {
	dropzoneProps: PropTypes.shape({
		isDragActive: PropTypes.bool,
		isDragAccept: PropTypes.bool,
		isDragReject: PropTypes.bool,
		disabled: PropTypes.bool,
		required: PropTypes.bool,
	})
}
/* #endregion */

/* #region  Domyślne propsy */

UploadFile.defaultProps = {
	hasLabel: true
}

DropzoneLabel.defaultProps = {
	dropzoneProps: {}
}

/* #endregion */