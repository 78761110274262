import React from 'react'

export function DivResizable({
	//rozmiar poczatkowy div'a
	//x,y - pozycja gornego lewego rogu
	//width,height - szerokość i wysokość
	x, y, width, height,
	//Rozmiar obszaru chwytania w którym pokazyje się kursor ciagania
	holdSize,
	//Minimalny rozmiar w x i y
	minSize,
	//Minimalny rozmiar w x
	minSizeX,
	//Minimalny rozmiar w y
	minSizeY,
	//Czy można zmieniać rozmiar we wszystkich kierunkach
	resizable,
	//Czy można przemieszczać w x i y
	moveable,
	//Czy można zmieniać rozmiar w x
	resizableX,
	//Czy można przemieszczać w x
	moveableX,
	//Czy można zmieniać rozmiar w y
	resizableY,
	//Czy można przemieszczać w y
	moveableY,
	//Czy można zmieniać rozmiar góry
	resizableTop,
	//Czy można zmieniać rozmiar prawo
	resizableRight,
	//Czy można zmieniać rozmiar dół
	resizableBottom,
	//Czy można zmieniać rozmiar lewo
	resizableLeft,
	//Czy można przekraczać granice parenta relatywnego (z position:'relative')
	boundary,
	//Czy następuje przerwanie przesuwania w jednym kierunku jeśli wyjdziemy poza obszar this
	escapeMoving,
	//Powiadomienie o zakończeniu przesuwania / zmianie rozmiaru
	onResized,
	//Powiadomienie o przesuwaniu / zmianie rozmiaru
	onResizing,
	//Wolane przed zaczęciem ciagania czy mozna przesuwać,lub zmieniać rozmiar
	//return true można
	onCheckBefore,
	children,
	style,
	...other
}) {
	let x1 = x, y1 = y, cx = width, cy = height
	const divRef = React.useRef()
	const [state, setState] = React.useState({ x1, y1, cx, cy })

	x1 = state.x1
	y1 = state.y1
	cx = state.cx
	cy = state.cy

	//Domyślne ustawienia
	if (!onCheckBefore)
		onCheckBefore = () => true
	if (!onResized)
		onResized = () => { }
	if (!onResizing)
		onResizing = () => { }
	if (escapeMoving === undefined)
		escapeMoving = true
	if (boundary === undefined)
		boundary = true
	if (!holdSize)
		holdSize = 5
	if (!minSizeX)
		minSizeX = 10
	if (!minSizeY)
		minSizeY = 10
	if (minSize !== undefined)
		minSizeX = minSizeY = minSize
	if (moveableX === undefined)
		moveableX = true
	if (moveableY === undefined)
		moveableY = true
	if (moveable !== undefined)
		moveableX = moveableY = moveable
	if (resizableLeft === undefined)
		resizableLeft = true
	if (resizableRight === undefined)
		resizableRight = true
	if (resizableTop === undefined)
		resizableTop = true
	if (resizableBottom === undefined)
		resizableBottom = true
	if (resizableX !== undefined)
		resizableLeft = resizableRight = resizableX
	if (resizableY !== undefined)
		resizableBottom = resizableTop = resizableY
	if (resizable !== undefined)
		resizableLeft = resizableRight = resizableBottom = resizableTop = resizable

	const addPx = (v) => (typeof (v) === 'number') ? v + 'px' : v
	const getX1 = () => divRef.current.offsetLeft
	const getY1 = () => divRef.current.offsetTop
	// const setX1 = (v) => divRef.current.style.left = addPx(v)
	// const setY1 = (v) => divRef.current.style.top = addPx(v)
	const getCx = () => divRef.current.offsetWidth
	const getCy = () => divRef.current.offsetHeight
	// const setCx = () => divRef.current.style.width = addPx(v)
	// const setCy = () => divRef.current.style.height = addPx(v)

	//Czy kursor na górze 
	const inTop = (x, y) => resizableTop && y >= 0 && y < holdSize
	//Czy kursor na dole
	const inBottom = (x, y) => resizableBottom && y > getCy() - holdSize && y < getCy()
	//Czy kursor na lewo
	const inLeft = (x, y) => resizableLeft && x >= 0 && x < holdSize
	//Czy kursor na prawo
	const inRight = (x, y) => resizableRight && x > getCx() - holdSize && x < getCx()
	//Czy kursor na przesuwanie x
	const inMoveX = (x, y) => moveableX && x >= 0 && x < getCx() && y >= 0 && y < getCy()
	//Czy kursor na przesuwanie y
	const inMoveY = (x, y) => moveableY && x >= 0 && x < getCx() && y >= 0 && y < getCy()

	//Pobranie statusu przesuwania
	const getStatus = (x, y) => {
		const res = {
			// Pierwszy punkt w którym jest mysz
			x, y,
			//Początkowy rozmiar this
			x1: getX1(),
			y1: getY1(),
			cx: getCx(),
			cy: getCy(),
			//Gdzie jest mysz
			inTop: inTop(x, y),
			inBottom: inBottom(x, y),
			inLeft: inLeft(x, y),
			inRight: inRight(x, y),
			inMoveX: inMoveX(x, y),
			inMoveY: inMoveY(x, y)
		}
		//Jeżeli zmieniamy boczne granice to nie przesuwamy
		if (res.inTop || res.inBottom || res.inLeft || res.inRight)
			res.inMoveX = res.inMoveY = false
		return res
	}
	//Na podstawie statusu okreslamy kursor
	const getCursor = (status) => {
		if (!status)
			return ''
		if (status.inMoveX && status.inMoveY)
			return 'move'
		if (status.inMoveX)
			return 'e-resize'
		if (status.inMoveY)
			return 'n-resize'
		if (status.inTop && status.inLeft || status.inBottom && status.inRight)
			return 'nw-resize'
		if (status.inTop && status.inRight || status.inBottom && status.inLeft)
			return 'ne-resize'
		if (status.inTop || status.inBottom)
			return resizableLeft && resizableRight ? 'n-resize' : 'row-resize'
		if (status.inLeft || status.inRight)
			return resizableTop && resizableBottom ? 'e-resize' : 'col-resize'
		return ''
	}
	//Ustawianie kursora
	const cursor = (status) => {
		divRef.current.style.cursor = getCursor(status)
	}

	//Zaprzestanie przesuwania i przywrocenie poprzedniej pozycji
	const stopResizeAndRestore = (ev) => {
		divRef.current.releasePointerCapture(ev.pointerId)
		setState({
			x1: state.status.x1,
			y1: state.status.y1,
			cx: state.status.cx,
			cy: state.status.cy,
			status: false
		})
		cursor()
	}

	//Zaprzestanie przesuwania i przywrocenie poprzedniej pozycji
	const stopResize = (ev) => {
		divRef.current.releasePointerCapture(ev.pointerId)
		setState({ ...state, status: false })
		cursor()
	}

	//Zaczynamy zmiane rozmiaru
	const onPointerDown = ev => {
		if (!onCheckBefore(ev))
			return
		const status = getStatus(ev.nativeEvent.offsetX, ev.nativeEvent.offsetY)
		status.clientX = ev.clientX
		status.clientY = ev.clientY
		cursor(status)
		setState({ ...state, status })
		divRef.current.setPointerCapture(ev.pointerId)
	}
	//Przesuwanie
	const onPointerMove = ev => {
		const x = ev.nativeEvent.offsetX, y = ev.nativeEvent.offsetY

		if (!state.status) {
			if (!onCheckBefore(ev)) {
				cursor('')
				return
			}
			cursor(getStatus(x, y))
			return
		}

		cursor(state.status)

		//Parent w którym przesuwamy this
		const parent = divRef.current.offsetParent
		//Rozmiar parent'a w x i y
		const parentCx = parent.offsetWidth, parentCy = parent.offsetHeight
		const status = state.status
		//całkowite przesuniecie wskaznika myszy od poczatku przesuwania w x i y
		let dx = ev.clientX - status.clientX
		let dy = ev.clientY - status.clientY

		if (status.inMoveX) {
			if (escapeMoving && !status.inMoveY && (y < 0 || y > getCy())) {
				stopResizeAndRestore(ev)
				return
			}
			if (boundary) {
				if (status.x1 + dx < 0)
					dx = -status.x1
				if (status.x1 + status.cx + dx > parentCx)
					dx = parentCx - status.x1 - status.cx
			}
			x1 = status.x1 + dx
			cx = status.cx
		}
		if (status.inMoveY) {
			if (escapeMoving && !status.inMoveX && (x < 0 || x > getCx())) {
				stopResizeAndRestore(ev)
				return
			}
			if (boundary) {
				if (status.y1 + dy < 0)
					dy = -status.y1
				if (status.y1 + status.cy + dy > parentCy)
					dy = parentCy - status.y1 - status.cy
			}
			y1 = status.y1 + dy
			cy = status.cy
		}
		if (status.inTop) {
			if (boundary) {
				if (status.cy - dy < minSizeY)
					dy = status.cy - minSizeY
				if (status.y1 + dy < 0)
					dy = -status.y1
			}
			y1 = status.y1 + dy
			cy = status.cy - dy
		}
		if (status.inBottom) {
			if (boundary) {
				if (status.cy + dy < minSizeY)
					dy = minSizeY - status.cy
				if (status.y1 + status.cy + dy > parentCy)
					dy = parentCy - status.y1 - status.cy
			}
			cy = status.cy + dy
		}
		if (status.inLeft) {
			if (boundary) {
				if (status.cx - dx < minSizeX)
					dx = status.cx - minSizeX
				if (status.x1 + dx < 0)
					dx = -status.x1
			}
			x1 = status.x1 + dx
			cx = status.cx - dx
		}
		if (status.inRight) {
			if (boundary) {
				if (status.cx + dx < minSizeX)
					dx = minSizeX - status.cx
				if (status.x1 + status.cx + dx > parentCx)
					dx = parentCx - status.x1 - status.cx
			}
			cx = status.cx + dx
		}

		//Oznaczamy że dokonano jakiego kolwiek przemieszczenia 
		state.status.moved = true
		setState({ x1, y1, cx, cy, status: state.status })
		onResizing({ x1, y1, cx, cy })
	}

	//Koniec przesuwania
	const onPointerUp = ev => {
		//Jeśli dokonano przesuniecia to zglaszamy zakończenie przesuwania
		if (state.status && state.status.moved)
			onResized({ x1, y1, cx, cy })
		stopResize(ev)
	}

	return (
		<div
			ref={divRef}
			style={{ boxSizing: 'border-box', userSelect: 'none', position: 'absolute', left: state.x1, top: state.y1, width: state.cx, height: state.cy, ...style }}
			onPointerDown={onPointerDown}
			onPointerMove={onPointerMove}
			onPointerUp={onPointerUp}
			{...other}
		>
			{children}
		</div>
	)
}

// export const cssNodeAbsoluteResizable = Css.create`
// 	&{
// 		position:absolute;
// 		box-sizing:border-box;
// 		user-select: none; 
// 	}
// `