import React from 'react'
import PropTypes from 'prop-types'
import EnumListView from './EnumListView'
import useField from 'components/form/hooks/useField'

export default function EnumList({ name, useRefValuesArray }) {
	const field = useField(name)
	const meta = field.meta
	const values = (useRefValuesArray ? meta?.refValues : meta?.values) ?? []
	let selected = field.value
	let disabledValues = field.meta?.usedValues ?  field.meta.usedValues : undefined

	if (!Array.isArray(selected))
		selected = []

	return(
		<EnumListView
			name={name}
			values={values}
			selected={selected}
			onChange={newSelected => field.handleChange(newSelected)}
			disabledValues={disabledValues}
			sourceBoxLabel={field.meta?.sourceBoxLabel}
			destinationBoxLabel={field.meta?.destinationBoxLabel}
		/>
	)
}

EnumList.defaultProps = {
	useRefValuesArray: false,
}

EnumList.propTypes = {
	name: PropTypes.string,
	useRefValuesArray: PropTypes.bool,
}
