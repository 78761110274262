import Logger from 'Tools/Logging/Logger';
import { toString } from 'lodash'
import PropTypes from 'prop-types'

/**
 * Funkcja pozwalająca na wywołanie pobierania pliku.
 * @param {String} filename - nazwa pliku wraz z rozszerzeniem, np. example.txt
 * @param {String} blob - Zawartość na podstawie której generowany jest obiekt URL z odpowiednim linkiem.
 */
export function Download(filename, blob) {
    const ATTRIBUTES = Object.freeze({
        element: 'a',
        href: 'href',
        download: 'download',
        mouseEvents: 'MouseEvents',
        click: 'click',
    })

    const windowUrl = window.URL || window.webkitURL
    const url = windowUrl.createObjectURL(blob)
    const anchor = document.createElement(ATTRIBUTES.element)
    anchor.setAttribute(ATTRIBUTES.href, url)
    anchor.setAttribute(ATTRIBUTES.download, filename)

    if (document.createEvent) {
        const event = document.createEvent(ATTRIBUTES.mouseEvents)
        event.initEvent(ATTRIBUTES.click, true, true)
        anchor.dispatchEvent(event)
    }
    else {
        anchor.click()
    }
}

/**
 * Komponent warunkowo oplatający inny komponent. W zależności od podanego warunku - condition - zwracamy albo 
 * sam komponent (children) albo komponent oplceiony wrapperem. Przykład zastosowania w pliku EnumList.js 
 * @param {Boolean} condition - warunek mówiący o tym czy zwracamy sam komponent czy komponent opleciony wrapperem
 * @param {Node} wrapper - Komponent Wrapper który ma oplatać dzieci. Należy zwrócić go w funkcji podając jako argument dzieci komponentu
 * jak w przykładzie => wrapper={children => <Wrapper>{children}</Wrapper>} - taką funkcję należy podać jako propsa, wykorzystując 
 * w miejscu "<Wrapper />" własny komponent który ma oplatać warunkowo dzieci (children).  
 * @param {Node} children - Komponent dzieci który ma być zwracany
 */
export const ConditionalWrapper = ({ condition, wrapper, children }) =>( condition ? wrapper(children) : children) 

/**
 * Komponent który w zależności od  warunku zwraca element lub (jeśli warunek ma wartość "false") zwraca null. 
 * Pozwala to w zależności od warunku pokazać element albo go ukryć
 * @param {*} condition Warunek - jeśli true zwracamy komponent, jeśli false zwracamy null
 * @returns {Node || null}
 */
export const ConditionalShowElement = ({ condition, children }) => (
    condition ? children : null 
) 

/**
 * Funkcja otwierajaca nowe okno przeglądarki i zapisująca do niego zawartość która przyszła jako zmienna 'html'
 * @param {Any} html - Zawartość która ma się wyświetlić w nowym oknie przeglądarki
 * @param {String} windowUrl - adres url nowego okna przeglądarki
 * @param {String} windowName - nazwa nowego okna przeglądarki
 * @param {String} windowProps - Właściwości nowego okna przeglądarki
 * Więcej informacji na temat funkcji window.open() pod adresem: https://developer.mozilla.org/pl/docs/Web/API/Window/open
 */
export const WindowOpen = (html, windowName, windowUrl, windowProps) => {
    if(!html)  
        return

    const browserWindow = window.open(toString(windowUrl), toString(windowName), toString(windowProps))
    browserWindow.document.open()
    browserWindow.document.write(`<code>${html}</code>`)
    browserWindow.document.close()
}

export const isEllipsisActive = (e) => {
    if(e?.target){
        return e.target.offsetWidth < e.target.scrollWidth
    }
    
    else return false
}


ConditionalWrapper.propTypes = {
	condition: PropTypes.bool,
	wrapper: PropTypes.func,
	children: PropTypes.node,
}

ConditionalShowElement.propTypes = {
	condition: PropTypes.bool,
	children: PropTypes.node,
}

WindowOpen.propTypes = {
	html: PropTypes.string.isRequired,
	windowName: PropTypes.string,
	windowUrl: PropTypes.string,
	windowProps: PropTypes.string,
}

/**
 * Dekoduj znaki specjalne html z stringowej wartości na wartości znakowe np. &quot na "
 * w kodzie szablonu zawierającym kod do silnika renderowania szablonu
 * @param {String} templateContent 
 * @returns {String} Zmodyfikowany kod szablonu
 */
export function FluidTemplateSyntaxHtmlDecoder(templateContent) {

     if (!templateContent)  
        return templateContent;
    

    const decodeTags = {
         '&lt;': '<',
         '&gt;': '>',
         '&quot;': '"',
         '&#39;': '\''
     };

    return templateContent.replace(/((?<={%.*)((&gt;)|(&lt;)|(&quot;)|(&#39;))(?=.*%}))|((?<={.*)((&quot;)|(&#39;))(?=.*}))/g, (match) => {
                 return (typeof decodeTags[match] === 'string') ? decodeTags[match] : match;
    });
}

export function LogBuildInfo(config) {
    Logger.of('App.BuildInfo').info("Version number: " + config.appVersion.versionNumber);
	Logger.of('App.BuildInfo').info("Commit hash: " + config.appVersion.commitHash);
	Logger.of('App.BuildInfo').info("Branch: " + config.appVersion.branchName);
	Logger.of('App.BuildInfo').info("Build time: " + config.appVersion.buildTime);
}

export function LogBrowserInfo(){
    Logger.of('App.RuntimeEnvironment').info("User Agent: " + navigator?.userAgent);
    try
    {
        Logger.of('App.RuntimeEnvironment').info("User Agent Data: " + navigator?.userAgentData ? JSON.stringify(navigator.userAgentData) : 'undefined');
    }
    catch(error)
    {
        console.error(error);
    }
    Logger.of('App.RuntimeEnvironment').info("Build ID: " + navigator?.buildId);
    Logger.of('App.RuntimeEnvironment').info("Device memory: " + navigator?.deviceMemory);
    Logger.of('App.RuntimeEnvironment').info("Cookie enabled: " + navigator?.cookieEnabled);
    try
    {
        Logger.of('App.RuntimeEnvironment').info("Languages: " + navigator?.languages?.length ? navigator.languages.join(',') : 'undefined');
    }
    catch(error)
    {
        console.error(error);
    }
    

    // Więcej info: https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
    Logger.of('App.RuntimeEnvironment').info("Connection - downlink: " + navigator?.connection?.downlink);
    Logger.of('App.RuntimeEnvironment').info("Connection - downlinkMax: " + navigator?.connection?.downlinkMax);
    Logger.of('App.RuntimeEnvironment').info("Connection - effectiveType: " + navigator?.connection?.effectiveType);
    Logger.of('App.RuntimeEnvironment').info("Connection - rtt: " + navigator?.connection?.rtt);
    Logger.of('App.RuntimeEnvironment').info("Connection - saveData: " + navigator?.connection?.saveData);
    Logger.of('App.RuntimeEnvironment').info("Connection - type: " + navigator?.connection?.type);
}

export function LogAppRuningEnvironemntInfo(config) {
    LogBuildInfo(config);
    LogBrowserInfo();
}

