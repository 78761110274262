import { ListItemText, Typography, withStyles } from '@material-ui/core'

export const StyledHeaderText = withStyles(() => ({
   h1: {
      fontWeight: '600',
      fontSize: '1.5rem',
      letterSpacing: '1px',
      flex: '0 0 auto',
      margin: 0,
      padding: '16px 24px',
   }
}))(Typography)

export const StyledListItemText = withStyles(() => ({
   root: {
      display: 'inline-flex',
   },
   primary: {
      fontSize: '1.1rem',
      fontWeight: 600,
   },
   secondary: {
      fontSize: '1.1rem',
      marginLeft: 5,
      color: 'inherit',
   },
}))(ListItemText)
