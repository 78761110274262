/**
 * Klasa umożliwiająca synchronizację linkow i dataView.
 */
class LinkManager {
	/**
	 * @returns {Array} Na podstawie aktualnie ustawionego adresu w przegladarce zwracamy tablice kolejnych kawałków scieżki.
	 * 	@example /ala/id1/ola -> ['ala','id1','ola']
	 */
	currentParsedPath() {
		const res = window.location.pathname.split('/').slice(1)

		if (res[res.length - 1] === '')
			return res.slice(0, - 1)
		return res
	}

	/**
	 * Na podstawie aktualnego adresu w przegladarce i stanu dataView tworzymy tablice opisująca pełny stan aplikacji
	 * @param {DataViewClass} dataView 
	 * @returns {Array} 
	 * [
	 * 	{
	 * 		title		//tytuł part
	 * 		name		//name z part
	 * 		dataType	//dataType z part
	 * 		id			//id z formy zwiazanej z part
	 * 		part		//part
	 * 		form		//forma zwiazana z part
	 * 		path		//kawałek scieżki przegladarki
	 * 	},...
	 * ]
	 */
	partPath(dataView) {
		if (!dataView.root)
			return []

		let partPath = []
		//przechodzily po wszystkich partach począwszy od dataView.select w góre do root
		for (let part = dataView.selected; part; part = part.parent) {
			const form = part.props.form
			partPath.push({
				title: part.props.title,
				name: part.props.name,
				dataType: part.props.dataType,
				id: form ? form.id : undefined,
				part,
				form
			})
		}
		//odwracamy odwroconą kolejność
		partPath.reverse()
		//i usywamy root
		partPath = partPath.slice(1)

		const currentParsedPath = this.currentParsedPath()

		//Dodajemy kawałki scieżek
		for (let i = 0; i < currentParsedPath.length; ++i)
			if (i < partPath.length)
				partPath[i].path = currentParsedPath[i]
			else
				partPath.push({ path: currentParsedPath[i] })

		return partPath
	}

	/**
	 * Zamiana adresu z parametrem redirect na adres z tego parametru
	 * @returns {boolean} return true jesli jest redirect
	 */
	parseSearchParams() {
		const currentUrlParams = new URLSearchParams(window.location.search)
		const redirect = currentUrlParams.get('redirect')
		if (redirect) {
			window.history.pushState('', document.title, redirect)
			return true
		}
		const isMaximize = currentUrlParams.get('maximize')
		this.isMaximizeAll = (isMaximize === 'all')
	}

	/**
	 * Synchronizacja scieżki z przegladarki z dataView. Polega to na kolejnym handleSelect w odpowiednich part'ach aż do pełnej zgodności
	 * @param {DataViewClass} dataView 
	 * @returns {boolean} return true jeśli następuje dataView.refresh()
	 */
	synchronize(dataView) {
		if (this.parseSearchParams())
			return

		//Pobieramy informacje na temat scieżki i dataView
		const partPath = this.partPath(dataView)

		//Idziemy po kolejnych elementach scieżki i synchronizujemy
		for (let i = 0; i < partPath.length; ++i) {
			const p = partPath[i]
			const parentPart = (i > 0) ? partPath[i - 1].part : dataView.root

			//Scieżka brak a otwarty part -> to zamykamy parta
			if (!p.path && p.part) {
				p.part.parent.closeChildren()
				p.part.parent.select()
				dataView.refresh()
				return true
			}

			//Poprzednia scieżka to lista to próbujemy otworzyć dzieci
			if (i > 0 && partPath[i - 1].form && partPath[i - 1].form.isMetaArray) {
				const parentForm = partPath[i - 1].form

				//Jesli jeszcze parent forma nie zaladowana to czekamy na ponowną synchronizacje
				if (!parentForm.rowManager.visibleRows.length)
					return

				//Parent ma wybrany wiersz i selectedRowId zgadza się z tym w scieżce to idziemy dalej 
				if (parentForm.rowManager.rowData && (parentForm.rowManager.selectedRowId === p.path || parentForm.rowManager.selectedRowId === undefined && p.path === 'create') && p.part)
					continue

				//Zamykamy niepotrzebnego parta
				if (!p.path && p.part) {
					parentPart.closeChildren()
					parentPart.select()
					dataView.refresh()
					return true
				}

				//Otwieramy part z nowym wierszem
				if (p.path === 'create') {
					parentForm.rowManager.selectedRowId = undefined
					parentForm.rowManager.setRowDataAsCreate()

					//Jeśli jest grupowanie to dodajemy do danych danego wiersza informacje o wybranym grupowaniu
					const row = parentForm.rowManager._groupRow
					//Atrybut używany w pliku ButtonAddNewFileVersion.js pozwalający na przepisywanie interesujących nas atrybutów do widoku create.
					const rowAttributes = parentForm.rowManager._rowAttributes
					parentForm.rowManager._rowAttributes = undefined //Czyszczenie atrybutu w formie po przypisaniu do zmiennej rowAttributes

					if (rowAttributes?.attributes) {
						//Przepisujemy wszystkie parametry które otrzymaliśmy z obiektu przycisku z BE jako właściwość 'params' do atrybutów
						parentForm.rowManager.rowData.data[0].attributes = rowAttributes.attributes
					}

					if (row)
						parentForm.columnManager.groupArray.forEach(group => {
							/// wyłączone z powodu atrybutów z ReloadOnChange - nie jest triggerowane, co powoduje złe wartości formularza
							// TODO: zmienić, aby działało z reloadOnChange
							if(!parentForm.rowManager.rowData?.meta.reloadOnChangeAttributes?.includes(group.name))
								parentForm.rowManager.rowData.data[0].attributes[group.name] = row.attributes[group.name]
						})
					return parentPart.handleSelectChildByName('create', true)
				}

				//Otwieramy part z edycja
				parentForm.rowManager.selectedRowId = p.path
				parentForm.rowManager.setRowDataFromSelectedRow()
				if (!parentPart.handleSelectChildByName('edit', true)) {
					parentForm.trigger('data')
					return false
				}

				//Po zmianie pozycji w liscie wszystkie poniższe listy muszą stracić selekcję  
				for (++i; i < partPath.length; ++i) {
					const form = partPath[i].form

					if (form && form.isMetaArray)
						form.rowManager.selectedRowId = null
				}

				return true
			}

			//Jeśli jest niezgodność otwieramy odpowiedniego parta
			if (!p.part || p.name !== p.path) {
				if (p?.part?.props.useLinkName) {
					if (p.part.props.useLinkName !== p.path) {
						return parentPart.handleSelectChildByComplexName(p.path, true)
					}
				}
				else {
					if (p.dataType !== p.path) {
						return parentPart.handleSelectChildByComplexName(p.path, true)
					}
				}
			}

			if (i === partPath.length - 1 && p.part.next) {
				let part = p.part
				part.closeChildren()
				part.select()
				dataView.refresh()
				return true
			}
		}
	}

	/**
	 * Wołane na zmiane zaznaczonego id na liscie. Zmieniane jest w scieżce odpowiednie id oraz odpowiednie przycinanie scieżki.
	 * Scieżka może mieć po przycięci max 1 pozycje po id.
	 * np: 
	 * 	name1 odpowiada miejscu określonego przez part	
	 * 
	 * 	.../name1/id1/name2 -> .../name1/newId/name2
	 * 	.../name1/id1 -> .../name1/newId
	 * Jeśli !id
	 * 	.../name1/id1/name2 -> .../name1/create
	 * 	.../name1/id1 -> .../name1/create
	 * @param {History} history 
	 * @param {PartClass} part  
	 * @param {string} id nowe id
	 */
	handleListId(history, part, id) {
		//Popranie aktualnej scieżki
		let partPath = this.partPath(part.dataView)
		//Znalezienie indexu w ktorym miejscu wystepuje part
		const index = partPath.findIndex(p => p.part === part)

		//Coś nie dobrze nie znaleziono part
		if (index === -1) {
			console.error('Błąd! Nie znaleziono odpowiedniego obiektu "part" - Plik LinkManager.js - Funkcja handleListId')
			return
		}

		//Miejsce występowania name2 z przykładu (może nie być)
		let next = partPath[index + 2]
		//Zamieniamy na tablicę tylko ze stringami scieżki
		partPath = partPath.slice(0, index + 1).map(p => p.path)

		if (id) {
			//jeśli jest id to dodajemy id i next.path
			partPath.push(id)
			if (next)
				partPath.push(next.path)
		} else
			//Jeśli brak id to dodajemy tylko create 
			partPath.push('create')

		//To nowa scieżka
		const path = '/' + partPath.join('/')
		//ustawiamy
		history.push(path)
	}

	/**
	 * Pobranie scieżki dla wskazanego part
	 * np: 
	 * 	name1 odpowiada miejscu określonego przez part	
	 * 
	 * 	.../name1/id1/name2/... -> .../name1
	 * @param {PartClass} part
	 */
	getPathFromPart(part, removeId) {
		//Popranie aktualnej scieżki
		let partPath = this.partPath(part.dataView)
		//Znalezienie indexu w ktorym miejscu wystepuje part
		const index = partPath.findIndex(p => p.part === part)

		//Coś nie dobrze nie znaleziono part
		if (index === -1) {
			console.error('Błąd! Nie znaleziono odpowiedniego obiektu "part" - Plik LinkManager.js - Funkcja getPathFromPart')
			return
		}

		//To nowa scieżka bez id
		let path = '/' + partPath.slice(0, index + 1).map(p => p.path).join('/')

		//zwracamy na rządanie bez id
		if (removeId)
			return path

		//Pobieramy wybrane id w liscie
		let id = partPath[index].form.rowManager.selectedRowId

		//Wybrane jest dodaj
		if (id === undefined)
			id = 'create'

		//Sprawdzamy czy jest cos wybrane
		if (id)
			path += '/' + id

		return path
	}

	/**
	 * Wołane na nacisnięcie pagera w liscie. 
	 * Scieżka jest przycinana tylko do nazwy listy
	 * np: 
	 * 	name1 odpowiada miejscu określonego przez part	
	 * 
	 * 	.../name1/id1/name2/... -> .../name1
	 * 
	 * @param {History} history 
	 * @param {PartClass} part
	 */
	handleListNewPage(history, part, removeId) {
		history.push(this.getPathFromPart(part, removeId))
	}

	/**
	 * Wołane na naciśnięcie taba.
	 * 
	 * Jeśli tab jest typu edit (part.props.name === 'edit')
	 * 	.../id1/name/... -> .../id1
	 * 	gdzie id1 odpowiada miejscy wskazanego przez part
	 * 
	 * Jeśli tab nie jest typu edit
	 * 	.../id1/name2/... -> .../id1/newName
	 * 	gdzie id1 odpowiada miejscy wskazanego przez part.parent a newName to part.props.dataType
	 * 
	 * @param {History} history 
	 * @param {PartClass} part
	 */
	handleTab(history, part) {
		//Popranie aktualnej scieżki
		let partPath = this.partPath(part.dataView)
		const isEdit = part.props.name === 'edit' || part.parent === part.dataView.root
		const dataType = part.props.useLinkName || part.props.dataType

		//Jeśli nie jest edit tab to interesuje nas parent part  
		if (!isEdit)
			part = part.parent

		//Znalezienie indexu w ktorym miejscu wystepuje part
		const index = partPath.findIndex(p => p.part === part)

		//Coś nie dobrze nie znaleziono part
		if (index === -1) {
			console.error('Błąd! Nie znaleziono odpowiedniego obiektu "part" - Plik LinkManager.js - Funkcja handleTab')
			return
		}

		//Zamieniamy na tablicę tylko ze stringami scieżki
		partPath = partPath.slice(0, index + 1).map(p => p.path)

		//Jeśli nie jest edit to dodajemy dataType do scieżki
		if (!isEdit)
			partPath.push(dataType)

		//To nowa scieżka
		const path = '/' + partPath.join('/')
		//ustawiamy
		history.push(path)
	}

	handleAutoTab(history, part, autoOpenTab) {
		let locationPath = this.currentParsedPath().map(p => '/' + p).join('')
		let path = this.getPathFromPart(part, true)

		if (autoOpenTab === undefined || locationPath !== path)
			return

		if (Number.isInteger(autoOpenTab)) {
			if (!part.parts || autoOpenTab >= part.parts.length || autoOpenTab < 0)
				return
			this.handleTab(history, part.parts[autoOpenTab])
			return
		}

		let partAuto = part.findChildByDataType(autoOpenTab)

		if (partAuto)
			this.handleTab(history, partAuto)
	}

	/**
	 * Zwraca id nastepnego parta
	 * @param {PartClass} part
	 * @returns {string} Na podstawie part szukamy nastepnego elementu scieżki z id i zwracamy to id
	 */
	getNextPartId(part) {
		const partPath = this.partPath(part.dataView)
		const index = partPath.findIndex(p => p.part === part)

		if (index >= 0 && index + 1 < partPath.length) {
			let id = partPath[index + 1].path
			const next = partPath[index + 1]
			return id
		}
	}

	/**
	 * Zwraca id jeśli występuje w scieżce przeglądarki a nie otwarte w partach
	 * @param {PartClass} part
	 * @returns {string} Na podstawie part szukamy nastepnego elementu scieżki z id i zwracamy to id
	 */
	getNextPartIdToOpen(part) {
		const partPath = this.partPath(part.dataView)
		const index = partPath.findIndex(p => p.part === part)

		if (index >= 0 && index + 1 < partPath.length) {
			let id = partPath[index + 1].path
			const next = partPath[index + 1]

			//Jeżeli isnieje nastepny element scieżki i jest wyswietlony to brak id
			//zabezpieczenie przed skracaniem listy dla np reload
			if (next && next.part)
				return

			if (!part.findChildByName(id) && !part.findChildByDataType(id) || id === 'create')
				return id
		}
	}
}

export const linkManager = new LinkManager()