/*
Klasa definiuje interfejs tzw. 'zlewu' loggera. Do niego trafiają wszystkie komunikaty
przesłane do loggera. Sink może je logować na konsoli, do pliku lub do zewnętrznego serwisu.
*/
export default class LoggerSinkInterface {
    get id(){
        throw new Error("Getter 'id' must be implemented.");
    }

    initialize() {
    }

    trace(module, ...args) {
        throw new Error("Method 'trace()' must be implemented.");
    }

    info(module, ...args) {
        throw new Error("Method 'info()' must be implemented.");
    }

    warn(module, ...args) {
        throw new Error("Method 'warn()' must be implemented.");
    }

    error(module, ...args) {
        throw new Error("Method 'error()' must be implemented.");
    }
}