import { css } from 'styled-components'


/**
 * Modyfikator, Funkcja która zwraca funkcję darken z parametrem kolor podanym w funkcji  MixinColor
 * @param {Number} amount - procent o który chcemy przyciemnić podany kolor 
 */

export function MixinDarken(amount) {
   return (color) => darken(color, amount)
}

/**
 * Modyfikator, Funkcja która zwraca funkcję lighten z parametrem kolor podanym w funkcji  MixinColor
 * @param {Number} amount - procent o który chcemy pojaśnić podany kolor 
 */

export function MixinLighten(amount) {
   return (color) => lighten(color, amount)
}

/**
 * Działa dokładnie jak css: color: color, z tą różnicą że pozwala na pobieranie właściwości z tematu
 * @param {String} color - kolor który chcemy ustawić jako color, można podać nazwę koloru z tematu
 * @param {Function} mod - modyfikator - funkcja zmieniająca podany kolor -  parametr opcjonalny
 */
export function MixinColor(color, mod) {

   if (mod) {
      return css`
      color: ${({ theme }) => {
            color = theme.colors[color] || color
            return mod(color)
         }};
      `
   }
   return css`
   color: ${({ theme }) => theme.colors[color] || color};
   `
}


/**
 * Działa dokładnie jak css: background: color, z tą różnicą że pozwala na pobieranie właściwości z tematu
 * @param {String} color - kolor który chcemy ustawić jako background, można podać nazwę koloru z tematu
 * @param {Function} mod - modyfikator - funkcja zmieniająca podany kolor, parametr opcjonalny
 */
export function MixinBackground(color, mod) {
   if (mod) {
      return css`
      background: ${({ theme }) => {
            color = theme.colors[color] || color
            return mod(color)
         }};
      `
   }
   return css`
   background: ${({ theme }) => theme.colors[color] || color};
   `
}


//______________________________________________DARKEN____________________________________________________//
/**
 * Funkcja przyciemniająca kolor o przekazaną wartość, używana w styled-components
 * @param {String} color //Kolor bazowy
 * @param {Number} amount  //Wartość o którą kolor ma się zmienić
 */
export const darken = (color, amount) => {
   const subtractLight = function (color, amount) {
      let cc = parseInt(color, 16) - amount
      let c = cc < 0 ? 0 : cc
      c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`
      return c
   }

   color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color
   amount = parseInt((255 * amount) / 100)
   return (color = `#${subtractLight(
      color.substring(0, 2),
      amount
   )}${subtractLight(color.substring(2, 4), amount)}${subtractLight(
      color.substring(4, 6),
      amount
   )}`)
}

//__________________________________________LIGHTEN______________________________________________________//
/**
 * Funkcja rozjaśniająca kolor o przekazaną wartość, używana w styled-components
 * @param {String} color //Kolor bazowy
 * @param {Number} amount  //Wartość o którą kolor ma się zmienić
 */
export const lighten = (color, amount) => {
   const addLight = function (color, amount) {
      let cc = parseInt(color, 16) + amount
      let c = cc > 255 ? 255 : cc
      c = c.toString(16).length > 1 ? c.toString(16) : `0${c.toString(16)}`
      return c
   }

   color = color.indexOf('#') >= 0 ? color.substring(1, color.length) : color
   amount = parseInt((255 * amount) / 100)
   return (color = `#${addLight(color.substring(0, 2), amount)}${addLight(
      color.substring(2, 4),
      amount
   )}${addLight(color.substring(4, 6), amount)}`)
}

