import { IDocumentCommentDto } from "./DocumentComment";
import EditorFindAndReplaceSearch from "./EditorFindAndReplaceSearch";
import LanguageDocument from "./LanguageDocument";

let globalHandlerIdSeed = 0;

/** Dostarcza następny globalny identyfikator dla handlerów mediatora */
export function nextGlobalHandlerId() : number
{
    return globalHandlerIdSeed++;
}

export interface IHandler
{
    getHandlerId: () => number;
    getHandlerDebugId: () => string;
}

/** Zdarzenia związane z znajdź i zamień */
export interface IFindAndReplaceEvents extends IHandler
{
    onFindAndReplaceCancel: () => void;
    onFindNext: (findSearch: EditorFindAndReplaceSearch, didChange: boolean) => void;
    onReplace: (findSearch: EditorFindAndReplaceSearch, replaceAll: boolean, didChange: boolean) => void;
}

/** Rejestracja na zdarzenia związane ze znajdź i zamień */
export interface IFindAndReplaceEventsSubscription
{
    subscribeFindAndReplace: (subscriber: IFindAndReplaceEvents) => void;
    unsubscriveFindAndReplace: (subscriber: IFindAndReplaceEvents) => void;
}

/** 
 * Polecenia, które mogą być wysyłane do pojedynczych jednostek tłumaczeniowych.
 * Mediator dba o to aby wydać polecenia tylko podanej jednostce tłumaczeniowej na podstawie identyfikatora dokumentu
 * oraz numerowi segmentu.
 */
export interface IEditorTranslationUnitCommands extends IHandler
{
    /** Wyślij do jednostki tłumaczeniowej informacje, że została ona wybrana/aktywna */
    selectTranslationUnit: (languageDocumentId: string, sequenceNumber: number) => void;
}

/** Rejestracja na zdarzenia związane z jednostkami tłumaczeniowymi */
export interface IEditorTranslationUnitCommandsSubscription
{
    /** 
     * Zasubskrybuj na otrzymywanie poleceń dla danej jednostki tłumaczeniowej
     */
    subscribeTranslationUnitCommands: (tu: IEditorTranslationUnitCommands, languageDocumentId: string, sequenceNumber: number) => void;
    unsubscribeTranslationUnitCommands: (languageDocumentId: string, sequenceNumber: number) => void;
}

/** Zdarzenia wyzwalane przez komponent TranslationUnit */
export interface IEditorTranslationUnitEvents extends IHandler
{
    onTryFixTuTags: (languageDocumentId: string, sequenceNumber: number) => void;
}

export interface IEditorTranslationUnitEventsSubscription
{
    subscribeTranslationUnitEvents: (subscriber: IEditorTranslationUnitEvents) => void;
    unsubscribeTranslationUnitEvents: (subscriber: IEditorTranslationUnitEvents) => void;
}

/** Globalne zdarzenia związane z edytorem */
export interface IEditorEvents extends IHandler
{
    /** Zdarzenie mówiące o zmianie aktywnego dokumentu edytora */
    onActiveDocumentChanged: (documentId: string) => void;
    /** Zdarzenie mówiące o chęci przejścia na podaną stronę. */
    onGoToDocument: (documentId: string) => void;
    /** Zdarzenie mówiące o chęci przejścia na stronę, gdzie znajduje się segment o podanym indeksie. */
    onGoToDocumentTU: (tuSequenceNumber: number) => void;
}

/** Rejestracja na globalne zdarzenia związane z edytorem */
export interface IEditorEventsSubscription
{
    subscribeEditorEvents: (subscriber: IEditorEvents) => void;
    unsubscribeEditorEvents: (subscriber: IEditorEvents) => void;
}

/** Typ operacji zmiany obiektu, który może zostać rozgłoszony w aplikacji */
export enum ObjectChangeOperationType
{
    CREATED = 1,
    UPDATED = 2,
    DELETED = 3
}

/** Reprezentuje informacje o zmianie stanu komentarza dokumentu */
export type DocumentCommentChangeState =
{
    /** Typ operacji zmiany komentarza  */
    operation: ObjectChangeOperationType,
    /** Identyfikator dokumentu, do którego przypięty jest komentarz */
    languageDocumentId: string,
    /** 
     * Jeśli komentarz przypięty jest do jednostki tłumaczeniowej, to ta zmienna wskazuje
     * na numer segmentu do którego przynalezy komentarz.
     */
    tuSequenceNumber?: number,
    /** Dla operacji CREATE/UPDATED możemy przesyłać właściwości obiektu */
    objects?: IDocumentCommentDto[],
}

/** Argumenty zdarzenia mówiącego o zmianie komentarzy w edytorze */
export type DocumentCommentChangedArgs =
{
    changes: DocumentCommentChangeState[]
}

/** Zdarzenia edytora związane z aktywnym dokumentem */
export interface IEditorDocumentEvents extends IHandler
{
    /** Zdarzenie mówiące o zmianie wybranej jednostki tłumaczeniowej */
    onSelectedTranslationUnit: (languageDocumentId: string, sequenceNumber: number) => void;
    /** Zdarzenie mówiące o zmianie komentarzy dokumentu. Zdarzenia używamy aby zasygnalizować zmianę i odświeżyć dane. */
    onDocumentCommentChanged: (languageDocumentId: string, args: DocumentCommentChangedArgs) => void;
    /** Zdarzenie mówiące o zmianie poziomu potwierdzenia jednostki tłumaczeniowej */
    onTuConfirmationLevelChanged: (languageDocumentId: string, sequenceNumber: number, confirmationLevel: number) => void;
}

/** Rejestracja na zdarzenia IEditorDocumentEvents  */
export interface IEditorDocumentEventsSubscription
{
    subscribeEditorDocumentEvents: (subscriber: IEditorDocumentEvents) => void;
    unsubscribeEditorDocumentEvents: (subscriber: IEditorDocumentEvents) => void;
}

/** Definiuje interfejs, który przechowuje odbiorców poleceń do jednostki tłumaczeniowej */
interface ITranslationUnitCommandMap {
    [key: string]: IEditorTranslationUnitCommands;
}

export default class EditorMediator implements 
    IFindAndReplaceEvents, IFindAndReplaceEventsSubscription, IEditorTranslationUnitCommands, IEditorTranslationUnitCommandsSubscription, IEditorEvents,
    IEditorEventsSubscription, IEditorDocumentEvents, IEditorDocumentEventsSubscription, IEditorTranslationUnitEvents, IEditorTranslationUnitEventsSubscription
{
    findAndReplaceEventsConsumers: IFindAndReplaceEvents[];
    editorEventsConumers: IEditorEvents[];
    editorDocumentEventsConsumer: IEditorDocumentEvents[];
    translationUnitCommandsConsumers: ITranslationUnitCommandMap;
    translationUnitEventsConsumers: IEditorTranslationUnitEvents[];

    private handlerId: number;
    private debugId: string;
    private selectedTranslationUnitNumber: number;

    constructor()
    {
        this.findAndReplaceEventsConsumers = [];
        this.editorEventsConumers = [];
        this.editorDocumentEventsConsumer = [];
        this.translationUnitCommandsConsumers = {};
        this.translationUnitEventsConsumers = [];
        this.selectedTranslationUnitNumber = -1;

        this.handlerId = nextGlobalHandlerId();
        this.debugId = "[EditorMediator_" + this.handlerId + "] ";
    }

    getHandlerId()
    {
        return this.handlerId;
    }

	getHandlerDebugId() 
    {
		return this.debugId;
	}

    setSelectedTranslationUnitNumber(orderIndex: number)
    {
        this.selectedTranslationUnitNumber = orderIndex;
    }

    getSelectedTranslationUnitNumber(): number
    {
        return this.selectedTranslationUnitNumber;
    }
    
    subscribeEditorEvents (subscriber: IEditorEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Subscribing for EditorEvents");
        this._subscribeGeneric(subscriber, this.editorEventsConumers);
    }

    unsubscribeEditorEvents (subscriber: IEditorEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Unsubscribing from EditorEvents");
        this._unsubscribeGeneric(subscriber, this.editorEventsConumers);
    }

    onActiveDocumentChanged (documentId: string)
    {
        this._dispatchEvent<IEditorEvents>(this.editorEventsConumers, (consumer) => {
            consumer.onActiveDocumentChanged(documentId);
        });
    }

    onGoToDocument(documentId: string)
    {
        this._dispatchEvent<IEditorEvents>(this.editorEventsConumers, (consumer) => {
            consumer.onGoToDocument(documentId);
        });
    }

    onGoToDocumentTU( tuSequenceNumber: number) 
    {
        this._dispatchEvent<IEditorEvents>(this.editorEventsConumers, (consumer) => {
            consumer.onGoToDocumentTU(tuSequenceNumber);
        });
    }

    onReplace(findSearch: EditorFindAndReplaceSearch, replaceAll: boolean, didChange: boolean)
    {
        this._dispatchEvent<IFindAndReplaceEvents>(this.findAndReplaceEventsConsumers, (consumer) => {
            consumer.onReplace(findSearch, replaceAll, didChange);
        });
    }

    onSelectedTranslationUnit (languageDocumentId: string, sequenceNumber: number)
    {
        this._dispatchEvent<IEditorDocumentEvents>(this.editorDocumentEventsConsumer, (consumer) => {
            consumer.onSelectedTranslationUnit(languageDocumentId, sequenceNumber);
        });
    }

    onDocumentCommentChanged(languageDocumentId: string, args: DocumentCommentChangedArgs)
    {
        this._dispatchEvent<IEditorDocumentEvents>(this.editorDocumentEventsConsumer, (consumer) => {
            consumer.onDocumentCommentChanged(languageDocumentId, args);
        });
    }

    onTuConfirmationLevelChanged(languageDocumentId: string, sequenceNumber: number, confirmationLevel: number)
    {
        this._dispatchEvent<IEditorDocumentEvents>(this.editorDocumentEventsConsumer, (consumer) => {
            consumer.onTuConfirmationLevelChanged(languageDocumentId, sequenceNumber, confirmationLevel);
        });
    }

    subscribeEditorDocumentEvents(subscriber: IEditorDocumentEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Subscribing for EditorDocumentEvents");
        this._subscribeGeneric(subscriber, this.editorDocumentEventsConsumer);
    }
    unsubscribeEditorDocumentEvents (subscriber: IEditorDocumentEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Unsubscribing from EditorDocumentEvents");
        this._unsubscribeGeneric(subscriber, this.editorDocumentEventsConsumer);
    }
    
    subscribeTranslationUnitCommands(tu: IEditorTranslationUnitCommands, languageDocumentId: string, sequenceNumber: number)
    {
        this.translationUnitCommandsConsumers[this._getTranslationUnitCommandMapKey(languageDocumentId, sequenceNumber)] = tu;
    }

    unsubscribeTranslationUnitCommands(languageDocumentId: string, sequenceNumber: number)
    {
        delete this.translationUnitCommandsConsumers[this._getTranslationUnitCommandMapKey(languageDocumentId, sequenceNumber)];
    }

    selectTranslationUnit(languageDocumentId: string, sequenceNumber: number)
    {
        const consumerKey = this._getTranslationUnitCommandMapKey(languageDocumentId, sequenceNumber);
        this.translationUnitCommandsConsumers[consumerKey].selectTranslationUnit(languageDocumentId, sequenceNumber);
    }

    _getTranslationUnitCommandMapKey(languageDocumentId: string, sequenceNumber: number)
    {
        return languageDocumentId + sequenceNumber;
    }

    onTryFixTuTags(languageDocumentId: string, sequenceNumber: number)
    {
        this._dispatchEvent<IEditorTranslationUnitEvents>(this.translationUnitEventsConsumers, (consumer) => {
            consumer.onTryFixTuTags(languageDocumentId, sequenceNumber);
        });
    }

    subscribeTranslationUnitEvents(subscriber: IEditorTranslationUnitEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Subscribing for TranslationUnitEvents");
        this._subscribeGeneric(subscriber, this.translationUnitEventsConsumers);
    }
    unsubscribeTranslationUnitEvents(subscriber: IEditorTranslationUnitEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Unsubscribing from TranslationUnitEvents");
        this._unsubscribeGeneric(subscriber, this.translationUnitEventsConsumers);
    }

    onFindAndReplaceCancel()
    {
        this._dispatchEvent<IFindAndReplaceEvents>(this.findAndReplaceEventsConsumers, (consumer) => {
            consumer.onFindAndReplaceCancel();
        });
    }
    onFindNext(findSearch: EditorFindAndReplaceSearch, didChange: boolean) 
    {
        this._dispatchEvent<IFindAndReplaceEvents>(this.findAndReplaceEventsConsumers, (consumer) => {
            consumer.onFindNext(findSearch, didChange);
        });
    }

    _dispatchEvent<ConsumerType>(consumerList: ConsumerType[], dispatcher: (consumer: ConsumerType) => void )
    {
        for (const consumer of consumerList) 
        {
            try
            {
                dispatcher(consumer);
            }
            catch(e)
            {
                console.error(e);
            }
        }
    }

    subscribeFindAndReplace (subscriber: IFindAndReplaceEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Subscribing for FindAndReplaceEvents");
        this._subscribeGeneric(subscriber, this.findAndReplaceEventsConsumers);
    }

    unsubscriveFindAndReplace (subscriber: IFindAndReplaceEvents)
    {
        console.log(subscriber.getHandlerDebugId() + "Unsubscribing from FindAndReplaceEvents");
        this._unsubscribeGeneric(subscriber, this.findAndReplaceEventsConsumers);
    }

    _subscribeGeneric<ConsumerType>(subscriber: ConsumerType, consumerList: ConsumerType[])
    {
        const indexOfElement = consumerList.indexOf(subscriber);
        if(indexOfElement === -1)
            consumerList.push(subscriber);
    }

    _unsubscribeGeneric<ConsumerType>(subscriber: ConsumerType, consumerList: ConsumerType[])
    {
        const indexOfElement = consumerList.indexOf(subscriber);
        if(indexOfElement > -1)
            consumerList.splice(indexOfElement, 1);
    }
}