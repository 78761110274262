/** 
 * KCH: Nie rozszerzać mechanizmów w tym pliku. Raczej odchodzimy od przechowywania wszystkiego w globalnym stanie.
 * Chcemu tu trzymać dane, które faktycznie są globalne, np. sesja. Najprawdopodobniej całość zostanie zastąpiona przez kontekst danych.  */

import { useReducerAsync } from 'use-reducer-async';
import { createContainer } from 'react-tracked';
import { useEffect } from 'react';
import { appCtrl } from 'AppCtrl'
import getCatOnlineService from 'services/CatOnlineService';
import { ComponentResource } from 'contents/cat/hooks/useComponent'
import ComponentId from 'contents/cat/consts/ComponentId'

export const RequestMethod = {
    GET: 'GET',
    UPDATE: 'UPDATE'
}

const initialState = {
    count: 0,
    session: {
        isLoadingApplicationData: true,
        user: {
            isLoggedIn: false,
            accessToken: null,
            accessTokenExpiration: null,
            refreshToken: null,
            refreshTokenExpiration: null
        },
        task: {
            id: null,
            projectId: null,
            languageDocuments: [],
            canComplete: false
        }
    },
    components: {
        '__global__': {
            isLoading: false,
            isError: false,
            status: 0,
        },
        tmLookups: {
            request: {
                isLoading: false,
                isError: false,
                status: 0
            },
            resource: null,
        },
        terminologyLookups: {
            request: {
                isLoading: false,
                isError: false,
                status: 0
            }
        }
    }
    
    //{
    //    meta: {},
    //    data: [/* {source: {}, target: {}, sourceText: "", targetText: "", score: 1, translationOrigin: "" } */]
    //}
};

let stateStorageKey = "catGlobalState";
let persistentStorage = window.sessionStorage;

let defaultComponentState = {
    request: {
        isLoading: false,
        isError: false,
        status: 0
    },
    resource: null,
    changes: {
        /* '__internalId': {__internalId: 'id', __id: 'id', __type: 'type', attributeName: 'attributeValue'} */
    },
    pagination: {
        rowsPerPage: 20,
        page: 1
    }
}

const initState = () => {
    let preloadedState;
    try {
        preloadedState = JSON.parse(persistentStorage.getItem(stateStorageKey));
    }
    catch (e) {
        console.log(e);
    }

    return preloadedState || initialState;
}

const innerReducer = (state, action) => {
    let actionComponent = "__global__";
    if (action.component) {
        actionComponent = action.component;
    }

    switch (action.type) {
        case 'COMPONENT_SET_RESOURCEOBJECT_ATTRIBUTE_VALUE':
            console.log(`Reducer action ${action.type}. Component: '${actionComponent}'; '${action.attributeName}': ${action.attributeValue}`);
            break;
        case 'COMPONENT_SAVE_CHANGES':
        case 'COMPONENT_CLEAR_RESOURCEOBJECT_CHANGES':
        case 'COMPONENT_CLEAR':
        case 'COMPONENT_CLEAR_CHANGES':
        case 'REQUEST_STARTED':
        case 'REQUEST_FAILURE':
        case 'REQUEST_SUCCESS':
            console.log(`Reducer action ${action.type}. Component: '${actionComponent}'`);
            break
        default:
            console.log(`Reducer action ${action.type}`);
            break
    }
    

    switch (action.type) {
        case 'REQUEST_STARTED':
            let componentState = state.components[actionComponent];
            if (!componentState) {
                componentState = {
                    ...defaultComponentState,
                    request: {
                        ...defaultComponentState.request,
                        isLoading: true
                    }
                }
            }
            else {
                componentState = {
                    ...state.components[actionComponent],
                    request: {
                        ...state.components[actionComponent].request,
                        isLoading: true
                    }
                }
            }

            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: componentState
                },
            };

        case 'REQUEST_FAILURE':
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        request: {
                            ...state.components[actionComponent].request,
                            isLoading: false,
                            isError: true,
                            status: action.status,
                            error: action.error
                        }
                    }
                },
            };
        case 'UPDATE_TASK_SUCCESS':
            return {
                ...state,
                session: {
                    ...state.session,
                    task: {
                        ...state.session.task,
                        canComplete: false
                    }
                },
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        request: {
                            ...state.components[actionComponent].request,
                            status: action.status,
                            isLoading: false,
                            isError: false
                        }
                    }
                },
            };
        case 'REQUEST_SUCCESS':
            let resource = action.status == 204 ? state.components[actionComponent].resource : action.resource;
            //let changes = action.status >= 200 && action.status < 300 ? {} : state.components[actionComponent].changes
            let stateChanges = state.components[actionComponent].changes;

            let partialDataMerge = stateChanges && action.status == 204;
            if(actionComponent == ComponentId.TranslationUnitsDocument && action.requestMethod === RequestMethod.UPDATE)
            {
                resource = state.components[actionComponent].resource;
                stateChanges = action.resource;
                partialDataMerge = true;
            }
            if (partialDataMerge) {
                // mergujemy zmiany
                let componentResource = new ComponentResource(null, state.components[actionComponent], actionComponent)
                let changedResource = componentResource.createResourceDocumentFromChanges()

                for (var i = 0; i < changedResource.data.length; i++) {
                    const changedResourceObject = changedResource.data[i]
                    const indexOfResourceObjectInArray = resource.data.map(function (e) { return e.id; }).indexOf(changedResourceObject.id);
                    
                    if (indexOfResourceObjectInArray === 0) {
                        resource.data = [
                            {
                                ...resource.data[indexOfResourceObjectInArray],
                                ...changedResourceObject,
                                attributes: {
                                    ...resource.data[indexOfResourceObjectInArray].attributes,
                                    ...changedResourceObject.attributes
                                }

                            },
                            ...resource.data.slice(indexOfResourceObjectInArray + 1)
                        ]
                    }
                    else if (indexOfResourceObjectInArray === resource.data.length - 1) {
                        resource.data = [
                            ...resource.data.slice(0, indexOfResourceObjectInArray),
                            {
                                ...resource.data[indexOfResourceObjectInArray],
                                ...changedResourceObject,
                                attributes: {
                                    ...resource.data[indexOfResourceObjectInArray].attributes,
                                    ...changedResourceObject.attributes
                                }

                            }
                        ]
                    }
                    else {
                        resource.data = [
                            ...resource.data.slice(
                                0,
                                indexOfResourceObjectInArray),
                            {
                                ...resource.data[indexOfResourceObjectInArray],
                                ...changedResourceObject,
                                attributes: {
                                    ...resource.data[indexOfResourceObjectInArray].attributes,
                                    ...changedResourceObject.attributes
                                }

                            },
                            ...resource.data.slice(indexOfResourceObjectInArray + 1)
                        ]

                    }

                }
            }

            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        request: {
                            ...state.components[actionComponent].request,
                            status: action.status,
                            isLoading: false,
                            isError: false,
                            error: null
                        },
                        resource: resource,
                        changes: {}
                    }
                },
            };
        case 'AUTH_TASK_SUCCESS':
            appCtrl.sessionCat.value = {
                accessToken: action.accessToken,
                accessTokenExpiration: action.accessTokenExpiration,
                refreshToken: action.refreshToken,
                refreshTokenExpiration: action.refreshTokenExpiration,
                taskToken: action.taskToken
            }

            return {
                ...state,
                session: {
                    ...state.session,
                    user: {
                        ...state.session.user,
                        isLoggedIn: true,
                        accessToken: action.accessToken,
                        accessTokenExpiration: action.accessTokenExpiration,
                        refreshToken: action.refreshToken,
                        refreshTokenExpiration: action.refreshTokenExpiration,
                        taskToken: action.taskToken
                    }
                }
            }
        case 'SET_SESSION_TASK':
            let currentSession = appCtrl.sessionCat.value;
            appCtrl.sessionCat.value = {
                ...currentSession,
                taskId: action.id,
                projectId: action.projectId,
                name: action.name
            }

            let languageDocuments = []
            if(action.languageDocuments){
                for (const document of action.languageDocuments) {
                    languageDocuments.push({...document, id: document.languageDocumentId})
                }
            }

            return {
                ...state,
                session: {
                    ...state.session,
                    task: {
                        ...state.session.task,
                        id: action.id,
                        projectId: action.projectId,
                        languageDocuments: languageDocuments,
                        canComplete: action.canComplete,
                        useMinimalCatFeatures: action.useMinimalCatFeatures,
                        name: action.name
                    }
                }
            }
        case 'SET_SESSION_TASK_LANGUAGE_DOCUMENTS':
            return {
                ...state,
                session: {
                    ...state.session,
                    task: {
                        ...state.session.task,
                        languageDocuments: action.languageDocuments
                    }
                }
            }
        case 'COMPONENT_SET_RESOURCEOBJECT_ATTRIBUTE_VALUE':
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        changes: {
                            ...state.components[actionComponent].changes,
                            [action.resourceObjectInternalId]: {
                                ...state.components[actionComponent].changes[action.resourceObjectInternalId],
                                __internalId: action.resourceObjectInternalId,
                                __id: action.resourceObjectInternalId,
                                __type: action.resourceObjectType,
                                [action.attributeName]: action.attributeValue
                            }
                        }
                    }
                },
            };
        case 'COMPONENT_CLEAR_RESOURCEOBJECT_CHANGES':
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        changes: {
                            ...state.components[actionComponent].changes,
                            [action.resourceObjectInternalId]: undefined
                        }
                    }
                },
            };
        case 'COMPONENT_CLEAR_CHANGES':
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        changes: {},
                        saveChangesToStore: 1
                    }
                },
            }
        case 'COMPONENT_CLEAR':
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        resource: null,
                        changes: {},
                        saveChangesToStore: 1
                    }
                },
            }
        case 'COMPONENT_SAVE_CHANGES':
            let newValue = String(state.components[actionComponent].saveChangesToStore + 1)
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        saveChangesToStore: newValue,
                        saveChangesOptions: action.storeContext
                    }
                },
            }
        case 'COMPONENT_SET_ROWS_PER_PAGE':
            let previousPagination = state.components[actionComponent].pagination ? state.components[actionComponent].pagination : defaultComponentState.pagination
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        pagination: {
                            ...previousPagination,
                            rowsPerPage: action.rowsPerPage,
                            page: 1
                        }
                    }
                },
            }
        case 'COMPONENT_SET_PAGE':
            let previousPagination2 = state.components[actionComponent].pagination ? state.components[actionComponent].pagination : defaultComponentState.pagination
            return {
                ...state,
                components: {
                    ...state.components,
                    [actionComponent]: {
                        ...state.components[actionComponent],
                        pagination: {
                            ...previousPagination2,
                            page: action.page
                        }
                    }
                },
            }
        default:
            throw new Error('unknown action type: ' + action.type);
    }
};

const reducer = (state, action) => {
    
    let actionComponent = "__global__";
    if (action.component) {
        actionComponent = action.component;
    }

    switch (action.type) {
        case 'DISPATCH_MULTIPLE_ACTIONS':
            console.log(`Reducer action ${action.type}. Component: '${actionComponent}'`)
            let newState = state

            for (let i = 0; i < action.actions.length; i++) {
                newState = {
                    ...innerReducer(newState, action.actions[i])
                }
            }

            return newState
        default:
            return innerReducer(state, action)
    }
};



const asyncActionHandlers = {
    UPDATE_SESSION_TASK: ({ dispatch, getState }) => async (action) => {
        console.log("Async reucer action: " + action.type);
        try {
            dispatch({ type: 'REQUEST_STARTED' });

            const user = getState().session.user;
            let catOnlineServiceConfig = {};
            if (user.isLoggedIn) {
                catOnlineServiceConfig.authToken = user.accessToken;
            }

            const task = getState().session.task;
            const catOnlineService = getCatOnlineService(catOnlineServiceConfig);
            const result = await catOnlineService.updateTask(task.projectId, task.id, action.entity);

            if (result.ok) {
                //dispatch({ type: 'UPDATE_TASK_SUCCESS', status: result.status });
                dispatch({
                    type: 'DISPATCH_MULTIPLE_ACTIONS', actions: [
                        { type: 'UPDATE_TASK_SUCCESS', status: result.status}
                    ]
                })
            }
            else {
                dispatch({ type: 'REQUEST_FAILURE', status: result.status });
            }
            
        } catch (error) {
            dispatch({ type: 'REQUEST_FAILURE', error });
            console.log(error);
        }
    },
    SEGMENT_SELECTED_NOTIFICATION: ({ dispatch, getState }) => async (action) => {
        console.log("Async reucer action: " + action.type);
        try {
            dispatch({ type: 'REQUEST_STARTED', component: 'tmLookups' });
            dispatch({ type: 'REQUEST_STARTED', component: 'terminologyLookups' });

            const user = getState().session.user;
            let catOnlineServiceConfig = {};
            if (user.isLoggedIn) {
                catOnlineServiceConfig.authToken = user.accessToken;
            }

            const task = getState().session.task;
            const catOnlineService = getCatOnlineService(catOnlineServiceConfig);
            //const result = await catOnlineService.tmSearch(task.projectId, action.languageDocumentId, action.segmentNumber);
            const [result, terminologyResult] = await Promise.all([
                catOnlineService.tmSearch(task.projectId, action.languageDocumentId, action.segmentNumber),
                catOnlineService.terminologySearch(task.projectId, action.languageDocumentId, action.segmentNumber)
            ])

            if (result.ok) {
                dispatch({ type: 'REQUEST_SUCCESS', component: 'tmLookups', resource: result, status: result.status, requestMethod: RequestMethod.GET });
            }
            else {
                dispatch({ type: 'REQUEST_FAILURE', component: 'tmLookups', status: result.status });
            }

            if (terminologyResult.ok) {
                dispatch({ type: 'REQUEST_SUCCESS', component: 'terminologyLookups', resource: terminologyResult, status: terminologyResult.status, requestMethod: RequestMethod.GET });
            }
            else {
                dispatch({ type: 'REQUEST_FAILURE', component: 'terminologyLookups', status: terminologyResult.status });
            }

        } catch (error) {
            dispatch({ type: 'REQUEST_FAILURE', component: 'tmLookups', error });
            console.log(error);
        }
    },
    //DISPATCH_MULTIPLE_ACTIONS: ({ dispatch, getState }) => async (action) => {
    //    console.log("Async reucer action: " + action.type);

    //    }
    //},
    COMPONENT_SAVE_CHANGES_TO_STORE: ({ dispatch, getState }) => async (action) => {
        console.log("Async reucer action: " + action.type);

        let componentState = getState().components[action.component]
        let componentResource = new ComponentResource(dispatch, componentState, action.component)
        if (componentResource.hasChanges) {
            console.log("Resource has changes")

            let resource = componentResource.createResourceDocumentFromChanges()
            let requestOptions = componentState.saveChangesOptions.requestOptions
            requestOptions.serviceMethodParams.push(resource)

            await action.saveRoutine(dispatch, requestOptions)

            //componentResource.clearChanges()
        }
        
    }
};

const useValue = () => {
    const [state, dispatch] = useReducerAsync(reducer, initState(), asyncActionHandlers);

    // Zapisujemy stan storage
    useEffect(() => {
        persistentStorage.setItem(stateStorageKey, JSON.stringify(state));
    }, [state]);

    return [state, dispatch];
}
    

export const {
    Provider,
    useTrackedState,
    useUpdate: useDispatch,
} = createContainer(useValue);