import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core'

export const StyledAlert = withStyles(({ palette }) => ({
	filledError: {
      width: '100%',
      marginBottom: 15,
		backgroundColor: palette.primary.error,
      maxWidth: 450,
	}
}))(Alert)