/*
 * Operatory filtrowania jako wartość w Query String
 */
export const FilterOperatorQS = {
    Equal: "eq",
    NotEqual: "neq",
    LessThan: "lt",
    LessThanOrEqual: "lte",
    GreaterThan: "gt",
    GreaterThanOrEqual: "gte",
    Contains: "cn",
    StartWith: "sw",
    EndsWith: "ew",
    IsNull: "nu",
    NotNull: "nnu"
};

/*
 * Operatory logiczne jako wartość w Query String
 */
const LogicalOperatorDelimiterQS = {
    And: ",",
    Or: "|"
}

/*
 * Delimiter parametrów warunku filtrującego
 */
const FilterParametersDelimiter = ":";

/*
 * Reprezentuje pojedynczy warunek filtrujacy
 */
class FilterCondition {
    /**
     * @param {string} attributeName 
     * @param {string} operator 
     * @param {any} value 
     */
    constructor(attributeName, operator, value) {
        this.throwIfInvalidOperator(operator);
        this.throwIfNullOrObject(attributeName);
        this.throwIfNotValidValue(value);

        this._attributeName = attributeName;
        this._operator = operator;
        this._value = this.normalizeValue(value);
    }

    /**
     * @param {string} operator 
     */
    throwIfInvalidOperator(operator) {
        if (!Object.values(FilterOperatorQS).includes(operator)) {
            throw "Unknown filter operator: " + JSON.stringify(operator);
        }
    }

     /**
     * @param {string} value 
     */
    throwIfNullOrObject(value) {
        if (value === null || typeof value === 'object') {
            throw "Filter condition value has to be string or number.";
        }
    }

    /**
     * Rzucą błędem jeśli wartość nie jest liczba, stringiem, boolem albo nullem
     * @param {any} value - Wartość po której filtrujemy
     */
     throwIfNotValidValue(value) {
        if (typeof value !== 'number' && typeof value !== 'string' && typeof value !== 'boolean' && value !== null) {
            throw "Filter condition value has to be string, number, boolean or null.";
        }
    }

    get attributeName() {
        return this._attributeName;
    }

    get operator() {
        return this._operator;
    }

    get value() {
        return this._value;
    }

    normalizeValue(value) {
        if (typeof value === "boolean") {
            if (value === true) {
                return 1;
            }

            return 0;
        }

        return value;
    }

    /*
     * Zapisuje warunek w postaci ciągu znaków
     */
    writeToString() {
        return `${this.attributeName}${FilterParametersDelimiter}${this.operator}${FilterParametersDelimiter}${this.value === null ? '' : this.value}`;
    }
}

export default class FilterExpressionWriter {
    constructor() {
        this._conditions = []
    }

    /*
     * Dodaj warunek filtrujący. 
     * @param {string} attributeName nazwa atrybutu, po którym filtrujemy
     * @param {string} operator operator porównania. Patrz operatory w stałej FilterOperatorQS
     * @param {string} value wartość wg której filtrujemy
     */ 
    addCondition(attributeName, operator, value) {
        this._conditions.push(new FilterCondition(attributeName, operator, value));
    }
    
    /*
     * Dodaj warunek filtrujący.
     * @param {FilterCondition} condition warunek filtrujący jako obiekt typu FilterCondition
     */
    addConditionObject(condition) {
        this._conditions.push(condition);
    }

    /*
     * Zapisuje wyrażenie filtrujące w postaci ciągu znaków i zwraca je.
     */
    writeToString() {
        let result = "";
        // Zapisujemy jaki operator był przypisany do poprzedniego warunku
        let prevConditionOperator = "";
        for (const condition of this._conditions) {

            if (result != "") {
                let logicalOperator = LogicalOperatorDelimiterQS.And;

                /**
                 *  jeśli string szukający zawiera już warunek dla tego pola i operator dla poprzedniego warunku był taki sam 
                 * to używamy @see LogicalOperatorDelimiterQS.Or
                 */ 
                const isNullOperator = condition.operator === FilterOperatorQS.IsNull;
                if (result.search(condition.attributeName) !== -1 
                    && (prevConditionOperator === condition.operator || isNullOperator)) {
                        logicalOperator = LogicalOperatorDelimiterQS.Or; 
                } 
                
                result = result.concat(logicalOperator);
            }

            prevConditionOperator = condition.operator;
            const conditionExpression = condition.writeToString();
            result = result.concat(conditionExpression);                  
        }
        return result;
    }

    /*
     * Zapisuje wyrażenie filtrujące w postaci ciągu znaków, enkoduje je i zwraca.
     */
    writeToUrlEncodedString() {
        let result = this.writeToString();
        return encodeURI(result);
    }

    clear() {
        this._conditions = [];
    }
}
