
import { EntityId, IEntity, IEntityContextStateController, IGenericAttributes, Pagination, ResourceContextState } from './DataContextModel';

export default class EntityContextController implements IEntityContextStateController 
{
    readonly _entityContext: ResourceContextState;
    constructor(entityContextState: ResourceContextState) {
      this._entityContext = entityContextState;
    }

    getEntityActual(entityId: string): IEntity {
      let draftEntity = this._entityContext.entityDraftIndex[entityId];
      if(draftEntity)
        return draftEntity;

      return this._entityContext.entityIndex[entityId];
    }

    getEntityOrderSet(): string[] {
      return this._entityContext.entityOrderedSet;
    }
    clearOrderSet() 
    {
      this._entityContext.entityOrderedSet = [];
    }

    clearIndex() 
    {
      this._entityContext.entityIndex = {};
    }

    setEntityAttributes(entityId: string, values: IGenericAttributes) 
    {
      let entityState: IEntity = this._entityContext.entityChangesIndex[entityId];
    
      if (entityState == null) 
      {
        const existingEntityState: IEntity = this._entityContext.entityIndex[entityId];
        entityState = {
          __internalId: entityId,
          __type: existingEntityState?.__type ?? "",
          __id: existingEntityState?.__id ?? null,
          ...values
        };
      } else {
        entityState = { ...entityState, ...values };
      }
      console.log(entityState);
      this.setEntityChanges(entityState);
    }

    setEntityAttributesWithoutChangeTracking(entityId: EntityId, values: IGenericAttributes)
    {
      let draftEntity: IEntity = this._entityContext.entityDraftIndex[entityId];
      if(draftEntity == null)
      {
        const existingEntityState: IEntity = this._entityContext.entityIndex[entityId];
        draftEntity = {
          ...existingEntityState,
          ...values
        }
      }
      else
      {
        draftEntity = {...draftEntity, ...values};
      }
      
      this._entityContext.entityDraftIndex[draftEntity.__internalId] = draftEntity;
    }

    setEntity(entity: IEntity) 
    {
      this._entityContext.entityIndex[entity.__internalId] = entity;
    }

    setEntityChanges(entity: IEntity) 
    {
      this._entityContext.entityChangesIndex[entity.__internalId] = entity;
      const existingEntityState: IEntity = this._entityContext.entityIndex[entity.__internalId];
      if(existingEntityState){
        this._entityContext.entityDraftIndex[entity.__internalId] = {...existingEntityState, ...entity};
      }
      else
      {
        this._entityContext.entityDraftIndex[entity.__internalId] = entity;
      }
    }

    setPagination(options: Pagination) 
    {
      this._entityContext.pagination = options;
    }

    pushToOrderSet(entityId: string) 
    {
      this._entityContext.entityOrderedSet.push(entityId);
    }

    getEntityChanges(): IEntity[] 
    {
      if(this._entityContext?.entityChangesIndex)
        return Object.values(this._entityContext.entityChangesIndex);

      return [];
    }

    getDraftEntities(): Array<IEntity>
    {
      if(this._entityContext?.entityDraftIndex)
        return Object.values(this._entityContext.entityDraftIndex);

      return [];
    }

    getEntityAttributes(entity: IEntity): IGenericAttributes 
    {
      const attributes: IGenericAttributes = {};
      for(const [attributeName, attributeValue] of Object.entries(entity)){
        if(this.isInternalAttribute(attributeName))
          continue;

        attributes[attributeName] = attributeValue;
      }

      return attributes;
    }

    commitAllEntitiesChanges() : Array<IEntity> 
    {
      const changedEntites: IEntity[] = this.getEntityChanges();
      const commitedEntities: IEntity[] = [];

      for(const entity of changedEntites) {
        const commitedEntity = this.commitEntityChanges(entity);
        commitedEntities.push(commitedEntity);
      }

      return commitedEntities;
    }

    commitEntityChanges(changedEntity: IEntity): IEntity 
    {
        const id: EntityId = changedEntity.__internalId;

        let entity = this._entityContext.entityDraftIndex[changedEntity.__internalId];
        this.setEntity(entity);
        this.deleteEntityFromChangesIndex(id);
        return entity;
    }

    rebuildDraftIndex()
    {
      this._entityContext.entityDraftIndex = {}; 
      const changedEntites: IEntity[] = this.getEntityChanges();
      for(const changedEntity of changedEntites)
      {
        const existingEntityState: IEntity = this._entityContext.entityIndex[changedEntity.__internalId];
        if(existingEntityState)
        {
          this._entityContext.entityDraftIndex[changedEntity.__internalId] = {...existingEntityState, ...changedEntity};
        }
        else
        {
          this._entityContext.entityDraftIndex[changedEntity.__internalId] = changedEntity;
        }
      }
    }

    setBag(key: string, value: unknown) 
    {
        this._entityContext.bag[key] = value;
    }

    static InternalAttributeNames = ["__id", "__internalId", "__type"];
    isInternalAttribute(attributeName: string): boolean 
    {
      return EntityContextController.InternalAttributeNames.includes(attributeName);
    }

    deleteEntityFromChangesIndex(entityId: EntityId)
    {
      delete this._entityContext.entityChangesIndex[entityId];
      delete this._entityContext.entityDraftIndex[entityId];
    }
}