import FormMetaArray from './FormMetaArray';


/**
 * Klasa formy obsługującej okienko dialogowe z widocznością kolumn grida
 */
export default class ColumnVisibilityModalFormMetaArray extends FormMetaArray 
{
    constructor(params) 
    {
		super(params);
    }

    /**
	 * Metoda wołana po zmianie wartości pola w formularzu podrzędnym. W tym miejscu możemy reagować na 
	 * zmiany pola
	 * @param {*} fieldChildForm 
	 * @param {*} fieldName 
	 * @param {*} newValue 
	 */
	onFieldValueChange(fieldChildForm, fieldName, newValue)
	{
        super.onFieldValueChange(fieldChildForm, fieldName, newValue);

        const rowIndex = this.rowManager.getRowIndexFromId(fieldChildForm.id);

        if(newValue === true)
        {
            const previousRowIndex = rowIndex - 1;
            if(previousRowIndex < 0)
                return;
    
            const visibleRows = this.rowManager.visibleRows;
            let rowTargetIndex = -1;
            for (let index = previousRowIndex; index >= 0; index--) {
                const rowId = visibleRows[index].id;
                const rowForm = this.getChildFormById(rowId);
                if(rowForm.getValue('FieldVisible') === true)
                {
                    rowTargetIndex = index;
                    break
                }
            }
    
            if(rowTargetIndex !== -1 && previousRowIndex !== rowTargetIndex)
            {
                const rowMoveOffset = -(rowIndex - rowTargetIndex - 1);
                this._moveRowUpOrDown(fieldChildForm.id, rowMoveOffset);
            }
        }
        else if(newValue === false)
        {
            const visibleRows = this.rowManager.visibleRows;
            const nextRowIndex = rowIndex + 1;
            if(nextRowIndex >= visibleRows.length)
                return;

            let rowTargetIndex = -1;
            for (let index = nextRowIndex; index < visibleRows.length; index++) {
                const rowId = visibleRows[index].id;
                const rowForm = this.getChildFormById(rowId);
                if(rowForm.getValue('FieldVisible') === false)
                {
                    rowTargetIndex = index;
                    break
                }
            }

            if(rowTargetIndex !== -1 && nextRowIndex !== rowTargetIndex)
            {
                const rowMoveOffset = (rowTargetIndex - rowIndex - 1);
                this._moveRowUpOrDown(fieldChildForm.id, rowMoveOffset);
            }
        }
	}

    getChildFormById(id)
    {
        const childFormsIterator = this.childForms.values();
        for(const childForm of childFormsIterator)
        {
            if(childForm.id === id)
                return childForm;
        }

        return null;
    }
}