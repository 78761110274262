/**
 * Pomocnicza funkcja łącząca nazwy klass css
 *
 * @param {string,...} classNames Nazwy klas css do połączenia
 * @returns {string}
 */
export default function joinClassNames(...classNames){
	var res=''
	var bFirst=true;

	for(var className of classNames)
		if(className && className!==''){
			if(bFirst){
				bFirst=false
				res+=className
				continue
			}
			res+=' '+className
		}

	if(res!=='')
		return res
}