import React from 'react'
import { customElements } from 'cssStatic'

const HorizontalPairStyle = customElements('o-horizontal-pair', ['grid-hor', 'grid-aiy-center', 'grid-acx-space-between', 'grid-gap-10'])

export function HorizontalPair({ left, right, ...other }) {
	if (!left && !right)
		return null

	if (!left)
		left = <div />
	if (!right)
		right = <div />

	return (
		<HorizontalPairStyle {...other}>
			{left}
			{right}
		</HorizontalPairStyle>
	)
}