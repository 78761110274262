import React from 'react'
import PropTypes, { func } from 'prop-types'
import { Redirect, useHistory } from 'react-router-dom'
import DataView from 'components/dataView/DataView'
import Icon from 'components/icon/Icon'
import { Help, PowerSettingsNew, Settings } from '@material-ui/icons';
import { GradientHidder, HorizontalPair, Horizontal } from '../topology/Topology'
import { useAppCtrl } from 'AppCtrl'
import { StyledTopbar, StyledTooltip, StyledIconButton, StyledAvatar, StyledChip, StyledLink } from './TopBar.styled'
import { ChangeCultureCode } from 'components/locale/ChangeCultureCodes'
import { useTranslate } from 'locale/Locale'
import {saveLogsToFileOnLocalDisk} from 'Tools/Logging/EventLog';
import Logger from 'Tools/Logging/Logger';
import { useMemo } from 'react'
import { ChangeOrganizationContext } from './ChangeOrganizationContext'

const TopbarLeft = ({ headerText }) => (
	<GradientHidder paddingX='md' paddingY='md'>
		{headerText}
	</GradientHidder>
)


const Chip = ({ appCtrl }) => {
	
	const organizationName = appCtrl.session && appCtrl.session.currentOrganizationInfo ?
		appCtrl.session.currentOrganizationInfo.name : null

	const label = appCtrl.session.login + ' (' + organizationName + ')'
	return (
		appCtrl.session.isAuthorized && (
		<StyledChip
			variant='outlined'
			label={label}
			avatar={<StyledAvatar src='#' alt={appCtrl.session.login && appCtrl.session.login.toUpperCase()} />}
		/>
	))
}

const downloadEventLog = () => {
	saveLogsToFileOnLocalDisk(Logger.memorySinkSerializer)
}

const TopBarRight = ({ }) => {
	const translate = useTranslate('WebSpa/MainPage')
	const history = useHistory()
	const appCtrl = useAppCtrl()
	const [redirect, setRedirect] = React.useState(false)

	if (redirect)
		return <Redirect to='/login' />

	const versionModuleAllowedLinkExists = appCtrl.session.links["readVersion"];

	const onVersionClick = (e) => {
		history.push('/Version')
		e.preventDefault()
	}

	return (
		<Horizontal>
			<StyledTooltip title={translate('Hints/eventLog')} arrow>
				<StyledIconButton onClick={downloadEventLog}>
					<Icon name='topic' style={{ fontSize: 28 }} />
				</StyledIconButton>
			</StyledTooltip>
			<StyledTooltip title={translate('Hints/help')} arrow>
				<StyledIconButton >
					<Help style={{ fontSize: 28 }} />
				</StyledIconButton>
			</StyledTooltip>
			<StyledIconButton>
				<Icon name='circle_notifications' style={{ fontSize: 28 }} />
			</StyledIconButton>
			{versionModuleAllowedLinkExists && <StyledLink to='/Version' onClick={onVersionClick}  >
				<StyledIconButton>
					<Settings style={{ fontSize: 28 }} />
				</StyledIconButton>
			</StyledLink>}
			{appCtrl.session.isAuthorized && (
				<StyledTooltip title={translate('Hints/logout')} arrow>
					<StyledIconButton
						onClick={() => {
							appCtrl.logout()
							setRedirect(true)
							localStorage.removeItem('_value')
						}}
					>
						<PowerSettingsNew
							style={{ fontSize: 28 }}
						/>
					</StyledIconButton>
				</StyledTooltip>
			)}
			<ChangeCultureCode />
			<ChangeOrganizationContext />
			<Chip appCtrl={appCtrl}/>
		</Horizontal>
	)
}

/**
 * Komponent panelu narzedziowego
 *
 * @param {string} headerText Tekst nagłówka
 * @returns {Node}
 */
export default function TopBar({ headerText}) 
{
	const topBarRightComponent = useMemo(() => <TopBarRight />, [])

	return (
		<StyledTopbar>
			<HorizontalPair
				left={<TopbarLeft headerText={headerText} />}
				right={topBarRightComponent}
			/>
		</StyledTopbar>
	)
}

TopBar.protoTypes = {
	headerText: PropTypes.string
}
