// import {ErrorBoundary} from 'react-err'
import {Paper, Card, CardActions, CardContent,  CardHeader,  Tooltip } from '@material-ui/core'
import { FileCopy } from '@material-ui/icons'
import Button from 'components/form/components/list/components/buttons/Button'
import { Css, withStyles } from 'cssStatic'
import {  toString } from 'lodash'
import React from 'react'
import {DefaultErrorHandler} from 'services/ErrorHandlingClass/ErrorHandlingClasses'
import { appCtrl } from 'AppCtrl'

Css.add({
	'.expand-more': {transform: 'rotate(0deg)', cursor: 'pointer', },
	'.expand-less': {transform: 'rotate(180deg)', cursor: 'pointer', },
    '.error-boundary-wrapper': { margin: 'auto', 'max-width': '85%', 'max-height': '75%','overflow-y': 'auto', },
    '.error-details':  {'margin-bottom': '25px', 'margin-left': '10px'},
    '.align-left': {'justify-content': 'flex-end'},
})

const StyledCardWrapper = withStyles({root: 'error-boundary-wrapper'}, Paper)
/**
 * Komponent wyswietlania błędów podelementów
 *
 *  @returns {Node}
 */
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            errorObject: {},
            hasError: false,
            expanded: false,
            isButtonDisabled: false,
        }
    }

    static getDerivedStateFromError(error) {
        return { errorObject: new DefaultErrorHandler(error), hasError: true }
    }

    componentDidCatch(error, info) {
        this.setState({ errorObject: new DefaultErrorHandler(error), hasError: true })
    }

    render() {
        if (this.state.hasError) {
            if (this.props.blankOnError === true) {
                return (null)
            }
            else if (this.props.errorElement !== undefined && this.props.errorElement !== null) {
                return this.props.errorElement
            }
            else {
                return (
                    <StyledCardWrapper  elevation={2}>
                        <Card>
                            <CardHeader title={toString(this.state.errorObject?.title) || 'Frontend error'} />
                            <CardContent>
                                
                                <h4>Error: </h4>
                                <div className='error-details'>
                                    {toString(this.state.errorObject?.detail) || 'Something went wrong'}
                                </div>
                                <h4>Stack Trace: </h4>
                                <div className='error-details'>
                                    {toString(this.state.errorObject?.stack) || 'Something went wrong'}
                                </div>
                            </CardContent>
                            <CardActions classes={{root: 'align-left'}}>
                                <Tooltip title={toString(appCtrl?.locale?.translate("WebSpa/ErrorBoundries/ButtonTooltip/title"))}>
                                    <Button type="icon" disabled={this.state.isButtonDisabled} icon={<FileCopy />} onClick={() => {
                                        navigator.clipboard.writeText(`${toString(this.state.errorObject?.detail)}\n${toString(this.state.errorObject?.stack)}`)
                                        this.setState({isButtonDisabled: true})
                                    }}/>
                                </Tooltip>
                            </CardActions>
                        </Card>
                    </StyledCardWrapper>
                )
            }
        }

        return this.props.children
    }
}