import React from 'react'
import { customElements } from 'cssStatic'
import { context } from './Context'
import { ZipElementLeft, ZipElementRight } from './ZipElement'

const StyledZipSite = customElements('o-zip-site', ['grid-ver', 'overflow-x-hidden', 'overflow-y-visible'])

export function ZipLeft() {
	const data = context.useContext()
	const colData = data?.left ?? []

	return (
		<StyledZipSite className='direction-rtl' >
			<div className='direction-ltr'>
				{colData.map((row, index) => <ZipElementLeft key={row.id} row={row} />)}
			</div>
		</StyledZipSite>
	)
}

export function ZipRight() {
	const data = context.useContext()
	const colData = data?.right ?? []

	return (
		<StyledZipSite >
			<div>
				{colData.map((row, index) => <ZipElementRight key={row.id} index={index} row={row} />)}
			</div>
		</StyledZipSite>
	)
}