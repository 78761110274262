import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useSessionTask from './hooks/useSessionTask';
import { Redirect } from 'react-router-dom';
import TmLookups from './TmLookups';
import TerminologyLookups from './TerminologyLookups';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularPreloader from 'components/preloader/CircularPreloader';
import TranslationUnitsDocumentTs from './languageDocument/TranslationUnitsDocumentTs';
import { FilterList, DoneAll, FindInPageOutlined, CodeOutlined, RateReviewOutlined, CreateOutlined, SettingsOutlined } from '@material-ui/icons';
import { Horizontal } from 'components/topology/Topology';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import localConfiguration from './model/LocalConfiguration';
import Paper from '@material-ui/core/Paper';
import EditorFiltersDialog, {EditorFilters} from './EditorFiltersDialog';
import FindAndReplaceDialog, {createDefaultEditFindAndReplaceSearch} from './FindAndReplaceDialog';
import SettingsIcon from '@material-ui/icons/Settings';
import ManualTaskSettingsDialog from './ManualTaskSettingsDialog';
import { useDataContext} from './hooks/useDataContext'
import useUser from './hooks/useUser';
import { RequestResults, RequestOptionsCreator, RequestState } from './contexts/data/RequestModel';
import LivoCatApiDataMapper, {performApiRequest} from './contexts/data/LivoCatApiDataMapper';
import useEditorMediator from './hooks/useEditorMediator';
import { AcceptColor, DiscardColor, InactiveColor } from './consts/Styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Provider as EditorContextProvider } from './contexts/editor/EditorContextState';

import ConfirmationLevelType from './languageDocument/model/ConfirmationLevelType';
import Tooltip from './components/Tooltip';
import LeftSidebar from './components/editor/LeftSidebar';
import DescriptionOutlined from '@material-ui/icons/DescriptionOutlined';
import RightSidebar from './components/editor/RightSidebar';
import { useEditor } from './hooks/useEditor';
import EditorController from './components/editor/EditorController';
import { getDocumentStartIndex, isDocumentAvailableForTranslation } from './model/Utils';
import stringResources from './StringResources';
import DialogYesNo from './components/dialogs/DialogYesNo';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const StyledIconButton = withStyles(() => ({
	root: {
		fontSize: 14,
		padding: 1
	}
}))(IconButton);


const ConfigTrackingMode = 'Components:Editor:TrackingMode';
localConfiguration.registerDefaultBool(ConfigTrackingMode, false);

/** Konfiguracja trybu śledzenia zmian per zadanie */
const useTrackingMode = (taskId) => {
	const [isTrackingMode, setIsTrackingMode] = React.useState(localConfiguration.scope(taskId).getValueBool(ConfigTrackingMode));
	const setIsTrackingModeConfig = (value) => {
		localConfiguration.scope(taskId).setValueBool(ConfigTrackingMode, value);
		setIsTrackingMode(value);
	};

	return [isTrackingMode, setIsTrackingModeConfig];
}

const EditorFiltersKey = 'Components:Editor:Filters';
localConfiguration.registerDefaultObject(EditorFiltersKey, {});

const FilterHasFilters = (filters) => {
    try
    {
        if(filters.qaFilters?.selectedQaChecks && filters.qaFilters.selectedQaChecks.length > 0)
        return true;

        if(filters.qeFilters?.minScore || filters.qeFilters?.maxScore || filters.qeFilters?.orderByScore)
            return true;
    }
    catch(err)
    {
        console.error(err);
        return false;
    }
}

/** Konfiguracja filtrów per zadanie */
const useFilters = (taskId) => {
	const [filters, setFilters] = React.useState(localConfiguration.scope(taskId).getValueObject(EditorFiltersKey));
    const hasFilters = FilterHasFilters(filters);
	const setFiltersConfig = (value) => {
		localConfiguration.scope(taskId).setValueObject(EditorFiltersKey, value);
		setFilters(value);
	};

	return [filters, hasFilters, setFiltersConfig];
}


function EditorWithContext({ match, history }) {
    const classes = useStyles();
    const user = useUser();
    const editorController = useRef(new EditorController());
    const editor = useEditor({
        subscribeTo: (controller) => {
            controller.subscribeEditorEvents(editorController.current);
        },
        subscribeFrom: (controller) => {
            controller.unsubscriveEditorEvents(editorController.current);
        }
    });

    const editorMediator = editor.mediator;
    const [forceDocumentComponentRefresh, setForceDocumentComponentRefresh] = React.useState(0);
    const documentDataContext = useDataContext('LanguageDocument:' + editor.state.activeDocumentId)
    const currentTask = useSessionTask();
    const [isTrackingMode, setTrackingMode] = useTrackingMode(currentTask.id);
    const [isFiltersDialogOpen, setIsFilterDialogOpen] = React.useState(false);
    const [isFindAndReplaceDialogOpen, setIsFindAndReplaceDialogOpen] = React.useState(false);
    const [isTryFixTagsInCurrentDocumentDialogOpen, setIsTryFixTagsInCurrentDocumentDialogOpen] = React.useState(false);
    const [isSettingsDialogOpen, setIsSettingsDialogOpen] = React.useState(false);
    const [editorFilters, hasFilters, setEditorFilters] = useFilters(currentTask.id);
    const [editorFindAndReplaceSearch, setEditorFindAndReplaceSearch] = React.useState(createDefaultEditFindAndReplaceSearch());

    // metoda wołana po kliknieciu na przycisk z nazwa dokumentu
    editorController.current.onActiveDocumentChanged = (activeDocumentId) => {
        console.log("[Editor] Tab changed: " + activeDocumentId);

        if(isDocumentAvailableForTranslation(activeDocumentId, currentTask.languageDocuments))
        {
            editor.activeDocumentChanged(activeDocumentId);
            history.push(`/cat/task/editor/document/${activeDocumentId}`)
        }
        else
        {
            editor.mediator.onGoToDocument(activeDocumentId);
        }
    };

    const handleModeChange = () => {
        setTrackingMode(!isTrackingMode);
    };

    let tabItems = [];
    let selectedDocumentId = match.params.documentId; //documentId;

    if (currentTask && currentTask.languageDocuments) {
        const documentsAvailableFroTranslation = currentTask.languageDocuments.filter(doc => doc.isAvailableForTranslation);
        tabItems = documentsAvailableFroTranslation.map((doc, index) => {
            return (<Tab key={doc.id} value={doc.id} label={doc.name} {...a11yProps(index)} />)
        });

        // Jeśli w roucie nie bylo identyfikatora dokumentu, to wybieramy pierwszy z brzegu
        if (!selectedDocumentId && documentsAvailableFroTranslation.length > 0) 
        {
            selectedDocumentId = documentsAvailableFroTranslation[0].id;
        }
    }

    useEffect(() => {
        editor.activeDocumentChanged(selectedDocumentId);
    }, []);

    // useEffect(() => {
    //     if(editor.state.activeDocumentId)
    //     {
    //         handleDocumentChange(editor.state.activeDocumentId);
    //     }
    // }, [editor.state.activeDocumentId])

    // Jeśli mamy wybrany identyfikator, a w roucie go nie ma, to robimy przekierowanie
    if (selectedDocumentId && !match.params.documentId) {
        return (<Redirect to={`/cat/task/editor/document/${selectedDocumentId}`} />);
    }

    const onOpenFiltersDialog = () => {
        setIsFilterDialogOpen(true);
    }

    const onCloseFiltersDialog = (filters) => {
        setIsFilterDialogOpen(false);
        setEditorFilters(filters);
        setForceDocumentComponentRefresh(prevState => {
            return prevState + 1;
        })
    }

    const onOpenSettingsDialog = () => {
        setIsSettingsDialogOpen(true);
    }

    const onAcceptAllSegmentsInCurrentDocument = () => {
        const options = RequestOptionsCreator.update(
            async (payload) => {
                return await performApiRequest(user, "UPDATE", "confirmAllSegmentsInDocument", [editor.state.activeDocumentId, ConfirmationLevelType.Translated]);
            },
            "confirmAllSegmentsInDocument",
             new LivoCatApiDataMapper()
        );
        documentDataContext.dispatchUpdateRequestWithOptions(options, (key, state)=> {
            setForceDocumentComponentRefresh(prevState => {
                return prevState + 1;
            })
        });
    }

    const onDraftAllSegmentsInCurrentDocument = () => {
        const options = RequestOptionsCreator.update(
            async (payload) => {
                return await performApiRequest(user, "UPDATE", "confirmAllSegmentsInDocument", [editor.state.activeDocumentId, ConfirmationLevelType.Draft]);
            },
            "confirmAllSegmentsInDocument",
             new LivoCatApiDataMapper()
        );
        documentDataContext.dispatchUpdateRequestWithOptions(options, (key, state)=> {
            setForceDocumentComponentRefresh(prevState => {
                return prevState + 1;
            })
        });
    }

    const onTryFixTagsInCurrentDocument = () => {
        return new Promise((resolve, reject) => {
            documentDataContext.dispatchCreateRequestWithOptions(
                RequestOptionsCreator.update(async (payload) => {
                    return await performApiRequest(user, "UPDATE", "tryFixTagsInCurrentDocument", [editor.state.activeDocumentId]);
                },
                "tryFixTagsInCurrentDocument:" + editor.state.activeDocumentId,
                new LivoCatApiDataMapper()),
                (key, state) => {
                    if(state == RequestState.Successed)
                    {
                        resolve({});
                        setForceDocumentComponentRefresh(prevState => {
                            return prevState + 1;
                        })
                    }
                    else if(state == RequestState.Failed)
                    {
                        reject('failed');
                    }
                }
            );
        });
    }

    const onOpenFindAndReplaceDialog = () => {
        setIsFindAndReplaceDialogOpen(true);
    }

    const onOpenTryFixTagsInCurrentDocument = () => {
        setIsTryFixTagsInCurrentDocumentDialogOpen(true);
    }

    const onCloseFindAndReplaceDialog = () => {
        setIsFindAndReplaceDialogOpen(false);
        setEditorFindAndReplaceSearch(createDefaultEditFindAndReplaceSearch());
        editorMediator.onFindAndReplaceCancel();
    }

    const onFindNext = (findParameters) => {
        const didChange = findParameters !== editorFindAndReplaceSearch;
        setEditorFindAndReplaceSearch(findParameters);
        editorMediator.onFindNext(findParameters, didChange);
    }

    const onReplace = (findParameters, replaceAll) => {
        const didChange = findParameters !== editorFindAndReplaceSearch;
        setEditorFindAndReplaceSearch(findParameters);
        editorMediator.onReplace(findParameters, replaceAll, didChange);
    }

    const onApproveChangeTrackingChangesInDocument = () => {
        const options = RequestOptionsCreator.update(
            async (payload) => {
                return await performApiRequest(user, "UPDATE", "approveChangeTrackingChangesInDocument", [editor.state.activeDocumentId]);
            },
            "AcceptChangeTrackingChangesInDocument",
             new LivoCatApiDataMapper()
        );
        documentDataContext.dispatchUpdateRequestWithOptions(options, (key, state)=> {
            // setForceDocumentComponentRefresh(prevState => {
            //     return prevState + 1;
            // })
        });
    }

    const onDiscardChangeTrackingChangesInDocument = () => {
        const options = RequestOptionsCreator.update(
            async (payload) => {
                return await performApiRequest(user, "UPDATE", "discardChangeTrackingChangesInDocument", [editor.state.activeDocumentId]);
            },
            "AcceptChangeTrackingChangesInDocument",
             new LivoCatApiDataMapper()
        );
        documentDataContext.dispatchUpdateRequestWithOptions(options, (key, state)=> {
            setForceDocumentComponentRefresh(prevState => {
                return prevState + 1;
            })
        });
    }

    const isEditionAllowed = currentTask.canComplete;

	return (
        <>
        <Box id="editor-container" style={{'display': 'flex', 'flexDirection': 'column', height: '100%', gap: '4px'}}>
            <Grid container spacing={0}>
                <Grid item xs={6} sm={6} lg={8}>
                <TmLookups />
                </Grid>
                <Grid item xs={6} sm={6} lg={4}>
                <TerminologyLookups />
                </Grid>
            </Grid>
            <Box id="editor-translation-box" style={{'overflowY': 'hidden', 'display': 'flex', gap: '4px'}} >
                <LeftSidebar id="editor-left-sidebar-box" style={{maxWidth: '400px',  'display': 'flex', 'flexDirection': 'row'}} />
                <Paper id="editor-translation-paper" style={{'flex': '1 1 auto', 'overflowY': 'hidden', 'display': 'flex', 'flexDirection': 'column'}}>
                    <Grid id="editor-translation-header" container spacing={0} style={{padding: '5px'}}>
                        <Grid item xs={4} sm={6}>
                            <Grid container direction="row" alignItems='center'>
                                <DescriptionOutlined style={{ color: '#316197'}} />
                                <Typography variant="subtitle1" style={{ color: '#316197'}} >
                                    Merged_File.sdlxliff
                                </Typography>
                            </Grid>
                            
                        </Grid>
                        <Grid item xs={8} sm={6}>
                            <Box id="editor-actions" style={{ gap: '6px', 'float': 'right' }}>
                                {/* <Tooltip title={stringResources.tryFixTagsInCurrentDocument.tooltip} arrow>
                                    <StyledIconButton onClick={onOpenTryFixTagsInCurrentDocument} disabled={!isEditionAllowed}>
                                        <CodeOutlined  />
                                    </StyledIconButton> 
                                </Tooltip> */}
                                <Tooltip title={'Find and replace'} arrow>
                                    <StyledIconButton onClick={onOpenFindAndReplaceDialog} disabled={!isEditionAllowed}>
                                        <FindInPageOutlined  />
                                    </StyledIconButton> 
                                </Tooltip>
                                <Tooltip title={'Show filter dialog'} arrow>
                                    <StyledIconButton onClick={onOpenFiltersDialog}>
                                        <FilterList style={{color: hasFilters ? '#316197' : 'currentColor'}} />
                                    </StyledIconButton> 
                                </Tooltip>
                                <Tooltip title={'Accept all changes in current document'} arrow>
                                    <StyledIconButton onClick={onApproveChangeTrackingChangesInDocument} disabled={!isEditionAllowed}>
                                        <CheckCircleOutlineIcon style={{color: isEditionAllowed ? AcceptColor : InactiveColor}}/>
                                    </StyledIconButton> 
                                </Tooltip>
                                <Tooltip title={'Reject all changes in current document'} arrow>
                                    <StyledIconButton onClick={onDiscardChangeTrackingChangesInDocument} disabled={!isEditionAllowed}>
                                        <HighlightOffIcon style={{color: isEditionAllowed ? DiscardColor : InactiveColor}}/>
                                    </StyledIconButton> 
                                </Tooltip>
                                <Tooltip title='Confirm all segments in current document' arrow>
                                    <StyledIconButton onClick={onAcceptAllSegmentsInCurrentDocument} disabled={!isEditionAllowed}>
                                        <DoneAll style={{color: isEditionAllowed ? '#049372' : InactiveColor}} />
                                    </StyledIconButton>
                                </Tooltip>
                                <Tooltip title='Mark all segments as draft in current document' arrow>
                                    <StyledIconButton onClick={onDraftAllSegmentsInCurrentDocument} disabled={!isEditionAllowed}>
                                        <CreateOutlined style={{color: isEditionAllowed ? '#274239' : InactiveColor}} />
                                    </StyledIconButton>
                                </Tooltip>
                                <Tooltip title={isTrackingMode ? 'Tracking mode on' : 'Tracking mode off'} arrow>
                                    <StyledIconButton onClick={handleModeChange}>
                                        <RateReviewOutlined style={{color: isTrackingMode ? '#316197' : 'currentColor'}} />
                                    </StyledIconButton>
                                </Tooltip>
                                <Tooltip title='Open settings dialog' arrow>
                                    <StyledIconButton onClick={onOpenSettingsDialog} disabled={!isEditionAllowed}>
                                        <SettingsOutlined style={{color: 'currentColor'}} />
                                    </StyledIconButton>
                                </Tooltip>
                            </Box>
                        </Grid>
                    </Grid>
                    {editor.state.activeDocumentId &&
                        <TranslationUnitsDocumentTs id="editor-tu-container" key={editor.state.activeDocumentId + "_" + forceDocumentComponentRefresh} languageDocumentId={editor.state.activeDocumentId} trackingMode={isTrackingMode} filters={editorFilters} findSearch={editorFindAndReplaceSearch} />
                    }
                    <EditorFiltersDialog isOpen={isFiltersDialogOpen} onClose={onCloseFiltersDialog} filters={editorFilters} />
                    {isSettingsDialogOpen && 
                        <ManualTaskSettingsDialog id="editor-task-settings-dialog" projectId={currentTask.projectId} taskId={currentTask.id} onClose={() => {setIsSettingsDialogOpen(false);}} />
                    }
                    {isFindAndReplaceDialogOpen && 
                        <FindAndReplaceDialog id="editor-find-replace-dialog" onClose={onCloseFindAndReplaceDialog} onFindNext={onFindNext} onReplace={onReplace} findAndReplaceSearch={editorFindAndReplaceSearch} />
                    }
                    {isTryFixTagsInCurrentDocumentDialogOpen && 
                        <DialogYesNo
                            title='Fix tags in current document'
                            text='Do you want to try fix tags in target segments in current document? The process might take up to a few minutes depending on the document’s size.'
                            isOpen={isTryFixTagsInCurrentDocumentDialogOpen}
                            onClose={() => {
                                setIsTryFixTagsInCurrentDocumentDialogOpen(false)
                            }}
                            onYes={onTryFixTagsInCurrentDocument} />
                    }
                    
                </Paper>
                <RightSidebar id="editor-right-sidebar-box" style={{maxWidth: '400px', 'display': 'flex', 'flexDirection': 'row'}} />
            </Box>
                
   
		</Box>
        <Backdrop className={classes.backdrop} open={documentDataContext.getLastRequest() && documentDataContext.getLastRequest().state === RequestState.Pending}>
                <CircularPreloader label="Please wait" />
        </Backdrop>
        </>
	)
}

export default function Editor({match, history}) {
    return (
        <EditorContextProvider>
            <EditorWithContext match={match} history={history} />
        </EditorContextProvider>
    )
}