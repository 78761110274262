
/**
 * Moduł komunikacji z serwerem Http
 * @module services/HttpService
 */
import { isEmpty } from 'lodash'
import { appCtrl } from '../AppCtrl.js'
import config from '../config.js'
import ErrorHandler, { LivoCatErrorHandler } from './ErrorHandlingClass/ErrorHandlingClasses.js'
import { jsonToUrl } from './jsonToUrl.js'
import { uploadFilesWithProgress } from 'Tools/IOTools.js'
import Logger from 'Tools/Logging/Logger.js'
import { json } from 'body-parser'
import { mergeQueryParams } from './HttpServiceUtils.js'

const emptyRequestConfig = {};

/**
 * Klasa komunikacyjna z serwerem Http
 */
export default class HttpService {
	/**
	 * @method HttpService
	 * @param {string} serviceHostUrl
	 */
	constructor(serviceHostUrl) {
		this.serviceHostUrl = serviceHostUrl
	}

	setServiceName(serviceName) {
		this.serviceHostUrl = config.serviceHost + serviceName
	}

	/**
	 * Pobierz adres url na który wskazuje serwis
	 */
	get url() {
		return this.replaceOrganizationId(this.serviceHostUrl)
	}

	/**
	 * Metoda tworząca poprawny adres url wraz z przekazanymi w obiekcie data parametrami
	 * @param {String} baseURL - url do którego doklejamy wartości z obiektu data
	 * @param {Object} data - obiekt zawierający wartości które chcemy dokleić do bazowego linku
	 * @returns {String} - Zwraca string z poprawnym adresem url oraz doklejonymi do niego parametrami
	 */
	jsonToUrl(baseURL, data) {
		return jsonToUrl(baseURL, data)
	}
	

	async _fetch(urlPath, method, parameters, data) {
		const config = {
			method,
			headers: {},
			mode: 'cors'
		}

		let queryParamsStartIndex = this.serviceHostUrl.indexOf('?');
		if ( queryParamsStartIndex === -1 ){
			queryParamsStartIndex = this.serviceHostUrl.length
		}
		let serviceHostUrlWithoutQueryParams = this.serviceHostUrl.slice(0, queryParamsStartIndex)
		let queryParams = this.serviceHostUrl.slice(queryParamsStartIndex);

		// W miejscu poniżej sprawdzamy czy przypadkiem w queryparams nie zapodziała część linku np. id.
		// Sytuacja ta wystąpiła przy odświeżaniu nadrzędnego obiektu po operacji update/create podrzędnego obiektu z kontekstem.
		// Link który przyszedł wyglądał tak:
		//'https://localhost:8093/api/v1/{organizationId}/settlements?contextFilterNames=CompensationSettlementContext/3ecfb36c-5933-427f-acd0-18294b5af092',
		// więc w query params znalazło się id odświeżanego obiektu, które musimy 'przekleić' do reszty właściwego linku w serviceHostUrl.
		// Link po przestawieniu elementów powinien wyglądać tak
		//'https://localhost:8093/api/v1/{organizationId}/settlements/3ecfb36c-5933-427f-acd0-18294b5af092?contextFilterNames=CompensationSettlementContext
		if ( queryParams.indexOf('/') !== -1 ){
			let missingUrlPath = queryParams.slice(queryParams.indexOf('/'))
			if ( serviceHostUrlWithoutQueryParams.lastIndexOf('/') === serviceHostUrlWithoutQueryParams.length -1 ){
				serviceHostUrlWithoutQueryParams = serviceHostUrlWithoutQueryParams.slice(0, serviceHostUrlWithoutQueryParams.length - 1 )
			}
			serviceHostUrlWithoutQueryParams = serviceHostUrlWithoutQueryParams + missingUrlPath;
		}

		if( serviceHostUrlWithoutQueryParams.lastIndexOf('/') === serviceHostUrlWithoutQueryParams.length - 1 ){
			if( urlPath.indexOf('/') === 0){
				serviceHostUrlWithoutQueryParams = serviceHostUrlWithoutQueryParams.slice( 0, serviceHostUrlWithoutQueryParams.length - 1 )
			}
		}

		let serviceHostUrlQueryParams = mergeQueryParams( this.serviceHostUrl, queryParamsStartIndex, urlPath );
		if( urlPath.indexOf('?') !== -1 ){
			urlPath = urlPath.slice(0, urlPath.indexOf('?'));
		}
	
		let url = `${serviceHostUrlWithoutQueryParams}${urlPath}${serviceHostUrlQueryParams}`
		url = this.replaceOrganizationId(url)
		url = this.jsonToUrl(url, parameters)

		if (data && Object.keys(data).length)
			config.body = JSON.stringify(data)

		config.headers['Content-Type'] = 'application/json'

		if (appCtrl.session.isAuthorized) {
			config.headers['Authorization'] = 'Bearer ' + appCtrl.session.accessToken
		}
		if (appCtrl.sessionCat.isAuthorized)
			config.headers['Authorization'] = 'Bearer ' + appCtrl.sessionCat.accessToken

		if (appCtrl.locale.currentCultureCode)
			config.headers['Accept-Language'] = appCtrl.locale.currentCultureCode

		let response

		try {
			try {
				Logger.of('HttpService._fetch').info(`Fetch ${method} ${url}. Authorized: ${appCtrl.session.isAuthorized}.`, parameters);
			}
			catch(error){
				console.log(error);
			}
			response = await fetch(url, config)
			response.parameters = parameters
			response.data = data
			response.method = method
		}
		catch (error) {
			Logger.of('HttpService._fetch').error(error, `Fetch ${method} ${url}. Rejected because of network error.`);
			if(response && response.headers)
				Logger.of('HttpService._fetch').error("Headers", ...response.headers);

			const errorClass = new Error(error)
			return await new ErrorHandler(errorClass).errorProcesing()
		}

		if (!response.ok || response.status >= 400) {
			try
			{
				// Password protection
				if(response && response.data && response.data.data && Array.isArray(response.data.data) && response.data.data[0] && 
				response.data.data[0].attributes && response.data.data[0].attributes['Password'] )
				response.data.data[0].attributes['Password'] = "(PasswordProtected)";
			}
			catch(passProtectionError)
			{
				console.error(passProtectionError);
			}
			Logger.of('HttpService._fetch').error(response, `Fetch ${method} ${url}. Http failed with status code: ${response.status}`, ...response.headers);
			const payloadAsText = await this.tryParseResponsePayloadToString(response.clone());
			Logger.of('HttpService._fetch').error('Payload', payloadAsText);
			return await new ErrorHandler(response).errorProcesing()
		}
		else {
			Logger.of('HttpService._fetch').info(`Successful Fetch ${method} ${url}. Authorized: ${appCtrl.session.isAuthorized}.`);
		}

		return response
	}

	/**
	 * Http GET
	 * @method get
	 * @param {string} urlPath
	 * @param {Object} parameters
	 * @param {Object} payload
	 * @returns {Object}
	 * @method GET
	 */
	async get(urlPath, parameters, payload) {
		const response = await this._fetch(urlPath, 'GET', parameters)
		return this.responsePayloadToJson(response)
	}

	/**
	 * Http PATCH
	 *
	 * @param {string} urlPath
	 * @param {Object} parameters
	 * @param {Object} payload
	 * @returns {Object}
	 * @method PATCH
	 */
	async patch(urlPath, parameters, payload) {
		const response = await this._fetch(urlPath, 'PATCH', parameters, payload)
		return this.responsePayloadToJson(response)
	}

	/**
	 * Http POST
	 *
	 * @param {string} urlPath
	 * @param {Object} parameters
	 * @param {Object} payload
	 * @returns {Object}
	 * @method POST
	 */
	async post(urlPath, parameters, payload) {
		const response = await this._fetch(urlPath, 'POST', parameters, payload)
		return this.responsePayloadToJson(response)
	}

	/**
	 * Http DELETE ONE
	 *
	 * @param {string} urlPath
	 * @param {Object} parameters
	 * @param {Object} payload
	 * @returns {Object}
	 * @method DELETE
	 */
	async deleteOne(urlPath, parameters, payload) {
		const response = await this._fetch(urlPath, 'DELETE', parameters, payload)
		if(response.status === 204){ //Przypadek kiedy akcja się udała ale BE nic nie zwraca (204 - No Content )
			return {
				messages: []
			}
		}

		return this.responsePayloadToJson(response)
	}

	async postMultipleFiles(urlPath, files, onProgress){
		const config = {
			headers: {},
		}

		const url = this.replaceOrganizationId(`${this.serviceHostUrl}${urlPath}`)
		
		if (appCtrl.session.isAuthorized)
			config.headers['Authorization'] = 'Bearer ' + appCtrl.session.accessToken

		try{
			const responses = await uploadFilesWithProgress({
				url: url,
				files,
				onProgress,
				headers: config.headers,
			})

			responses.forEach(response => {
				response.value = JSON.parse(response.value)
			})

			return responses
		}
		catch(error){
			throw error //TODO Poprawić obsługę błedów dla sytuacji gdy dostajemy tablicę błędów.
		}
	}

	// /**
	//  * Http POST FILE
	//  *
	//  * @param {string} urlPath
	//  * @param {Object} parameters
	//  * @param {Object} payload
	//  * @returns {Object}
	//  * @method POST
	//  */
	// async postFile(urlPath, parameters, payload) {
	// 	const config = {
	// 		headers: {},
	// 		mode: 'cors',
	// 		method: 'POST',
	// 		body: payload,
	// 	}

	// 	const url = this.replaceOrganizationId(`${this.serviceHostUrl}${urlPath}`)

	// 	if (appCtrl.session.isAuthorized)
	// 		config.headers['Authorization'] = 'Bearer ' + appCtrl.session.accessToken

	// 	let response

	// 	await fetch(url, config)
	// 		.then(async response => {
	// 			if (response.status >= 400) {
	// 				return await new ErrorHandler(response).errorProcesing()
	// 			}

	// 			return response.json()
	// 		})
	// 		.then(data => (response = data))
	// 		.catch(async err => {
	// 			if(err instanceof ErrorHandler){
	// 				err.errorProcesing()
	// 				return
	// 			}
					
	// 			const errorClass = new Error(err)
	// 			return await new ErrorHandler(errorClass).errorProcesing()
	// 		})

	// 	return response
	// }

	
	async getFile(urlPath, parameters, payload) {
		
		let response = await this._fetch(urlPath, 'GET', parameters, payload)
		if(this.serviceHostUrl.endsWith('/par') || urlPath.endsWith('/par'))
		{
			const resourceDocument = await this.responsePayloadToJson(response);
			const downloadLink = resourceDocument.data[0].links.download;
			response = await this.sendRequestInternal(downloadLink.href, downloadLink.method, null, emptyRequestConfig);
		}
		
		return await response.blob()
	}

	async downloadFileFromLivo(progressCallback)
	{
		let response = await this._fetch('', 'GET', null, null);
		const resourceDocument = await this.responsePayloadToJson(response);
		const downloadLink = resourceDocument.data[0].links.download;
		response = await this.sendRequestInternal(downloadLink.href, downloadLink.method, null, emptyRequestConfig);

		// Step 2: get total length
		const contentLength = +response.headers.get('Content-Length');
		let loaded = 0;

		const res = new Response(new ReadableStream({
			async start(controller) {
			  const reader = response.body.getReader();
			  for (;;) {
				const {done, value} = await reader.read();
				if (done) break;
				loaded += value.byteLength;

				console.log(Math.round((loaded/contentLength)*100), loaded, contentLength);
				progressCallback(loaded, contentLength);
				controller.enqueue(value);
			  }
			  controller.close();
			},
		  }));

		return await res.blob();
	}

	async sendRequest(urlPath, httpMethod, data, requestConfig) {
		let url = `${this.serviceHostUrl}${urlPath}`;
		return await this.sendRequestInternal(url, httpMethod, data, requestConfig);
	}

	async sendRequestInternal(url, httpMethod, data, requestConfig)
	{
		let fetchConfig = {
			method: httpMethod,
			headers: {
			},
			mode: 'cors'
		};

		if (data && !isEmpty(data)) {
			fetchConfig.body = JSON.stringify(data);
		}

		if (requestConfig.contentType) {
			fetchConfig.headers['Content-Type'] = requestConfig.contentType;
		}

		if (requestConfig.authorization) {
			fetchConfig.headers['Authorization'] = requestConfig.authorization;
		}

		if (requestConfig.acceptLanguage) {
			fetchConfig.headers['Accept-Language'] = requestConfig.acceptLanguage;
		}

		let response = null;

		try {
			try {
				Logger.of('HttpService.sendRequestInternal').info(`Fetch ${httpMethod} ${url}.`);
			}
			catch(error){
				console.log(error);
			}
			response = await fetch(url, fetchConfig);
			response.data = data
			response.method = httpMethod
		}
		catch (error) {
			Logger.of('HttpService.sendRequestInternal').error(error, `Fetch ${httpMethod} ${url}. Rejected because of network error.`);
			if(response && response.headers)
				Logger.of('HttpService.sendRequestInternal').error("Headers", ...response.headers);

			const errorClass = new Error(error)
			return await new ErrorHandler(errorClass).errorProcesing()
		}

		if (response.status >= 400) {
			try
			{
				// Password protection
				if(response && response.data && response.data.data && Array.isArray(response.data.data) && response.data.data[0] && 
				response.data.data[0].attributes && response.data.data[0].attributes['Password'] )
				response.data.data[0].attributes['Password'] = "(PasswordProtected)";
			}
			catch(passProtectionError)
			{
				console.error(passProtectionError);
			}
			Logger.of('HttpService.sendRequestInternal').error(response, `Fetch ${httpMethod} ${url}. Http failed with status code: ${response.status}`, ...response.headers);
			const payloadAsText = await this.tryParseResponsePayloadToString(response.clone());
			Logger.of('HttpService.sendRequestInternal').error('Payload', payloadAsText);

			return await new LivoCatErrorHandler(response).errorProcesing()
		}

		return response;
	}

	replaceOrganizationId(url) {
		return url.replace("{organizationId}", appCtrl.session.currentOrganizationInfo ? appCtrl.session.currentOrganizationInfo.id : 'undefined')
	}

	async responsePayloadToJson(response) {
		try {
			const contentType = response.headers.get("content-type");
			const contentLength = response.headers.get("content-length");
			if(contentType.includes("application/json") && contentLength != 0)
				return await response.json();
			
			throw new Error(`Unexpected response body "${contentType}" (${contentLength})`);
		} catch (error) {
			Logger.of('HttpService').error(error, "Could not deserialize response payload to JSON");
			Logger.of('HttpService').error('Headers', ...response.headers);
			
			try {
				const payloadAsText = await response.clone().text();
				Logger.of('HttpService').error("Could not deserialize response payload to JSON. Payload: " + payloadAsText);
			} catch (parseTextError) {
				Logger.of('HttpService').error(parseTextError, "Could not deserialize response payload to TEXT");
			}
			return await new ErrorHandler(error).errorProcesing()
		}
	}

	async tryParseResponsePayloadToString(response){
		try {
			return await response.text();
		} catch (error) {
			return ''
		}
	}
}

