import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import { createContext, useContext } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {props.isError ? <WarningIcon fontSize="small" style={{ color: ProgressBarErrorStyle.color}}/> : 
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(props.value,)}%`}
        </Typography>}
      </Box>
    </Box>
  );
}

enum ProgressState {
  DOWNLOADING = 1,
  SUCCESS = 2,
  ERROR = 3
}

type FileProgress = {
  fileHandle: string;
  fileId: string;
  fileName: string;
  progress: number;
  state: ProgressState;
  finishedAt?: number;
  message?: string;
};

type FileProgressData = {
  files: FileProgress[],
  setProgress: (fileHandle: string, progress: number) => void,
  addFile: (fileHandle: string, fileName: string) => string,
  removeFiles: (fileHandles: string[]) => void,
  successed: (fileHandle: string) => void,
  failed: (fileHandle: string, message: string) => void;
  isDownloading: (fileId: string) => boolean;
};

const ProgressContext = createContext<FileProgressData>({
  files: [],
  setProgress: (fileHandle: string, progress: number) : void => {},
  addFile: (fileHandle: string, fileName: string) : string => "",
  removeFiles: (fileHandle: string[]) : void => {},
  successed: (fileHandle: string) : void => {},
  failed: (fileHandle: string, message: string) : void => {},
  isDownloading: (fileId: string): boolean => false,
});

export function useFileProgress() {
  return useContext(ProgressContext);
}

export function FileDownloadProgressProvider({children})
{
  const [files, setFiles] = useState([/*{fileHandle: '1234', fileId: '1234', fileName: '1234.pdf', progress: 50, state: ProgressState.ERROR, message: 'Unexpected error'}*/]);
  const setProgress = (fileHandle: string, progress: number) : void => {
      setFiles((prevState) => {
        let result = [];
        for(let i = 0; i < prevState.length; i++)
        {
          const currentFile = prevState[i];
          if(currentFile.fileHandle === fileHandle)
          {
            result.push({...currentFile, progress: progress});
          }
          else
            result.push(currentFile);
        }
      return result;
    });
  }
  const addFile = (fileId: string, fileName: string) : string => {
    const fileHandle = fileId + "_" + new Date().getTime();
    setFiles((prevState) => {
      let result = [{fileHandle, fileId, fileName, progress: 0, state: ProgressState.DOWNLOADING, message: undefined}, ...prevState];
      return result;
    });
    return fileHandle;
  }
  const removeFiles = (fileHandles: string[]) : void => {
    if(fileHandles.length === 0)
      return;

    setFiles((prevState) => {
      let result = [];
      for (const file of prevState) {
        if(!fileHandles.includes(file.fileHandle))
          result.push(file);
      }
      return result;
    });
  }
  const successed = (fileHandle: string) : void => {
    setFiles((prevState) => {
      let result = [];
      for(let i = 0; i < prevState.length; i++)
      {
        const currentFile = prevState[i];
        if(currentFile.fileHandle === fileHandle)
        {
          result.push({...currentFile, progress: 100, state: ProgressState.SUCCESS, finishedAt: new Date().getTime()});
        }
        else
          result.push(currentFile);
      }
    return result;
  });
  }
  const failed = (fileHandle: string, message: string) : void => {
    setFiles((prevState) => {
      let result = [];
      for(let i = 0; i < prevState.length; i++)
      {
        const currentFile = prevState[i];
        if(currentFile.fileHandle === fileHandle)
        {
          result.push({...currentFile, state: ProgressState.ERROR, message,  finishedAt: new Date().getTime()});
        }
        else
          result.push(currentFile);
      }
    return result;
  });
  }

  const isDownloading = (fileId: string): boolean => {
    for(let i = 0; i < files.length; i++)
    {
      const currentFile = files[i];
      if(currentFile.fileId === fileId && currentFile.state === ProgressState.DOWNLOADING)
        return true;
    }

    return false;
  }

  const value = {files, setProgress, addFile, removeFiles, isDownloading, successed, failed};
    return (
		<ProgressContext.Provider value={value}>
			{children}
		</ProgressContext.Provider>
	)
}

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    maxHeight: '600px', 
    zIndex: 2
  },
});

const ProgressBarErrorStyle = {color: '#A73A38'};
const ProgressBarSuccessStyle = {color: '#049372'};
const ProgressBarDefaultStyle = {};
/**
 * Przechowuje uchwyty do plików, których należy się pozbyć z okna dialogowego po upłynięciu pewngo czasu
 */
const CleanupFileProgressBag = {};
/**
 * Przechowuje uchwyty zwrócone przez setTimeout(), które należy zwolnić po wymontowaniu komponentu.
 */
const CleanupTimeoutsArray = [];

function getStyleForState(state: ProgressState): any
{
  if(state == ProgressState.SUCCESS)
    return ProgressBarSuccessStyle;
  else if(state == ProgressState.ERROR)
    return ProgressBarErrorStyle;
  else
    return ProgressBarDefaultStyle;
}

export default function FileDownloadProgressDialog()
{
    const classes = useStyles();
    const progress = useFileProgress();

    useEffect(() => {
      // Po pewnym czasie chcemy się pozbyć z okienka dialogowego info o plikach, które zostały już obsłużone.
      for (const file of progress.files) {
        if(file.state === ProgressState.SUCCESS)
        {
          CleanupFileProgressBag[file.fileHandle] = new Date().getTime();
        }
      }

      // Uruchamiamy usuwanie plików z okienka po upłynięciu pewnego czasu
      const timeoutHandle = setTimeout(() => {
        let filesToRemove = [];
        const currentTime = new Date().getTime();

        for (const key in CleanupFileProgressBag) {
          const finishedAt: number = CleanupFileProgressBag[key];
          // Sprawdzamy czy dla podanego pliku upłynął minimalny okres czasu aby nie usunąć
          // pliku za wcześnie.
          if(currentTime - finishedAt >= 4900)
          {
            filesToRemove.push(key);
            delete CleanupFileProgressBag[key];
          }
        }

        progress.removeFiles(filesToRemove);
        const indexOfHandle = CleanupTimeoutsArray.findIndex(handle => handle === timeoutHandle);
        if(indexOfHandle >= 0)
          CleanupTimeoutsArray.splice(indexOfHandle, 1); 

      }, 5000);
      CleanupTimeoutsArray.push(timeoutHandle);

      return () => {
        // Na koniec czyścimy wszystkie uchwyty do timeoutów
        for (const timeout of CleanupTimeoutsArray) {
          clearTimeout(timeout);
        }
      }
    }, [progress.files])

    const onDeleteItemsWithError = () => {
      const filesToRemove = progress.files.filter(file => file.state == ProgressState.ERROR || file.state == ProgressState.SUCCESS).map(file => file.fileHandle);
      progress.removeFiles(filesToRemove);
    };

    if(progress.files.length === 0)
      return null;

    return (
        <Dialog hideBackdrop={true} disableEnforceFocus={true} scroll="paper" aria-labelledby="dialog-file-progress-title" aria-describedby="dialog-file-progress-body"
            style={{ position: 'initial' }} open={true} classes={{paper: classes.dialog}}>
          <DialogTitle id="dialog-file-progress-title" style={{textAlign: 'right', padding: 0}}>
            <IconButton aria-label="delete" onClick={onDeleteItemsWithError}>
              <DeleteIcon fontSize="small" style={{ color: ProgressBarErrorStyle.color}}/>
            </IconButton>
          </DialogTitle>
          <DialogContent id="dialog-file-progress-body" style={{padding: 0}} >
            <List>
                {progress.files.map((file) => {
                  const listItem = (
                      <ListItem key={file.fileHandle}>
                          <ListItemAvatar>
                              <CircularProgressWithLabel value={file.progress} isError={file.state == ProgressState.ERROR} style={getStyleForState(file.state)}/>
                          </ListItemAvatar>
                          <ListItemText primary={file.fileName} />
                      </ListItem>
                    );
                    if(file.message == null)
                      return listItem;
                    // TODO: Nie mogłem tutaj zaimportować istniejącego tooltipu z components/Tooltip. Builder TypeScript nie widzi tego modułu?
                    return (<Tooltip title={file.message} arrow>{listItem}</Tooltip>);
                } )}
            </List>
          </DialogContent>
        </Dialog>
    );
}