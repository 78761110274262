import React from 'react'
import PropTypes from 'prop-types'
import useField from '../../hooks/useField'
import DownloadFile from '../../../downloadFile/DownloadFile'
import UploadFile from '../../../uploadFile/UploadFile'
import './File.scss'
import { linkManager } from 'components/LinkManager';
import { isEmpty } from 'lodash';
import getFormRestService from "services/getFormRestService";
import { FORM_EDIT_MODES } from 'components/form/classes/FormMetaObject'


/**
 * W tym momencie tylko niektóre pola typu plik w typach, które nie są plikami wspierają dodawanie plików.
 */
export const fileFieldsNamesOutsideFileTypesThatSupportsUpload = ['TranslationSourceFiles', 'UploadTradosProjectFiles', 'UploadStatsFiles'];

/**
 * Pole file
 *
 * @param {string} name Nazwa pola
 * @param {boolean} hasLabel Czy ma labelkę
 * @returns
 */
export default function File({ name, hasLabel }) {

	const field = useField(name)

	if (isFileFieldUploadFile(field) || field.form.editMode == FORM_EDIT_MODES.MODAL_UPA)
		return <UploadFile name={name} hasLabel={hasLabel}/>
	else
		return <DownloadFile name={name} hasLabel={hasLabel}/>
}

/**
 * Sprawdź czy pole pliku służy do uploadu
 * @param field Pole pliku
 * @returns bool
 */
export const isFileFieldUploadFile = (field) => {

	if (!field.meta)
		return false;

	const createEditable = field.form.editMode === FORM_EDIT_MODES.CREATE && field.meta.editableOnCreate;
	const upaEditable = field.form.editMode === FORM_EDIT_MODES.MODAL_UPA;
	const updateEditable = field.form.editMode === FORM_EDIT_MODES.EDIT && field.meta.editableOnUpdate;
	const specialFieldSupportingUpload = fileFieldsNamesOutsideFileTypesThatSupportsUpload.includes(field.name, 1);
 	
	return createEditable || upaEditable || (updateEditable && specialFieldSupportingUpload);
};

/**
 * Metoda zajmuje się obsługą dodawania wielu plików z poziomu kontrolki do dodania wielu plików.
 * Na ten moment tylko określone pola plików obsługują dodawanie za ich pomocą. Pozostałe pola służą wyłącznie do odczytu (pobierania plików).
 * @param {} createNewObject Informacja czy tworzymy nowy obiekt, czy go aktualizujemy
 * @param {*} form Obiekt form
 * @param {*} part 
 * @param {*} enqueueSnackbar 
 * @param {*} translate 
 * @param {*} history 
 * @returns 
 */
export const handleUploadMultipleFileFields = async (createNewObject, form, part, enqueueSnackbar, translate, history) => {
	const allFileFields = form.allFileFields

	for (const fileField of allFileFields) {
		const value = fileField.value
		const files = fileField.value.files

		if (!isEmpty(files) && !fileField.disabled) {
			delete value.files
			const uploadLinks = { ...form._data.links }

			if (fileFieldsNamesOutsideFileTypesThatSupportsUpload.filter(fn => fn === fileField.name).length>0) {
				//Zahardcodowana sytuajcja dla kontrolki 'Files' występującej w zakładce 'Specyfikacje'
				const filesToSend = []

				for (const file of files) {
					filesToSend.push({ id: file.id, value: file.name })
				}

				fileField.form.handleChange(fileField.name, filesToSend)

				if (createNewObject) {
					await getFormRestService(form.serviceCreateHRef).post(form, '')
				}
				else {
					await getFormRestService(form.serviceUpdateHRef).patch(form, '')
				}
			
				form._data.links = uploadLinks

				return
			}

			for (const file of files) {
				fileField.value.id = file.id
				fileField.value.name = file.name
				
				if(form.editMode === FORM_EDIT_MODES.MODAL_UPA) {
					await getFormRestService(form.serviceUploadUPAFileHRef).post(form)
				} else {
					await getFormRestService(form.serviceCreateHRef).post(form)
				}
				

				if (!isEmpty(form.errorFromService)) { //Obsługa błędów
					const responseErrorText = form.errorFromService?.responseError?.title || translate('error')
					enqueueSnackbar(responseErrorText, { variant: 'error', preventDuplicate: true })
					console.warn(responseErrorText, form.errorFromService?.responseError);
					linkManager.handleListNewPage(history, part.parent)
					form.parentForm.rowManager.selectedRowId = null //Żaden wiersz nie będzie zaznaczony
					form.parentForm.asyncLoad('reload') //Przeładowanie i odświeżenie widoku FormMetaArray
					return
				}

				enqueueSnackbar(translate('succes'), { variant: 'success', preventDuplicate: true })
				form._data.links = uploadLinks //Przepisywanie 'starych' linków do aktualnej formy. W ten sposób link do uploadu plików nie znika 
			}

			linkManager.handleListNewPage(history, part.parent, true)
			form.parentForm.rowManager.selectedRowId = null //Żaden wiersz nie będzie zaznaczony
			form.parentForm.asyncLoad('reload') //Przeładowanie i odświeżenie widoku FormMetaArray

			return
		}
	}
}

File.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool
}

File.defaultProps = {
	hasLabel: true
}
