import React from 'react'
import { FormMetaObject, Fields, Preloader } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import { Buttons } from 'components/form/components/list/components'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, } from '@material-ui/core'
import { isArray, isEmpty } from 'lodash'
import { handleUploadMultipleFileFields } from 'components/form/components/file/File';
import { useHistory } from 'react-router-dom'
import { useTranslate } from 'locale/Locale'
import { useSnackbar } from 'notistack'
import ModalUPAFormMetaObject from 'components/form/classes/ModalUPAFormMetaObject'

const formMettaObjectClassFactory = (params) => {
	return new ModalUPAFormMetaObject(params);
}


function ModalBody({ modalButton, part }) {
	return (
		<Dialog open={true} fullWidth maxWidth='sm' onPointerDown={e => e.stopPropagation()}>
			<DialogTitle>
				<p style={{ fontSize: 20, letterSpacing: 1, fontWeight: 500 }}>
					{modalButton?.label}
				</p>
			</DialogTitle>
			<Divider />
			<DialogContent>
				<Preloader>
					<Fields />
				</Preloader>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Preloader>
					<Buttons part={part}/>
				</Preloader>
			</DialogActions>
		</Dialog>
	)
}

export default function ModalUpa({ modalButton, setModalButton, part }) {
	const history = useHistory()
	const translate = useTranslate('WebSpa/Snackbar/UploadFile/CreatePartOuter/SnackbarMessage')
	const { enqueueSnackbar } = useSnackbar()

	if (!modalButton)
		return null

	return (
		<FormMetaObject
			enableMultiMarked
			editMode='modalUpa'
			formMetaControllerClassFactory={formMettaObjectClassFactory}
			onLoad={async (form) => {
				await getFormRestService(modalButton.href)
					.get(form)
			}}
			onSubmit={async (form, submitType, props) => {
				if (!form.isOk)
					return

				//Obsługa przesyłania plików
				if(submitType === 'ButtonConfirm') {
					if (isArray(form.allFileFields) && !isEmpty(form.allFileFields)) {	
						handleUploadMultipleFileFields(true, form, part, enqueueSnackbar, translate, history);
					}
				}
				
				let selectedRowIds = form._parentForm?.rowManager?.marksOrSelectedIds;
				if( selectedRowIds !== undefined && selectedRowIds.length > 1){
					selectedRowIds = selectedRowIds.map(r => {return {id: r}});
					if(form._data.data[0]._changes === null || form._data.data[0]._changes === undefined)
						form._data.data[0]._changes = { 'RelatedObjectsRefList': selectedRowIds}
					else
						form._data.data[0]._changes = {...form._data.data[0]._changes, 'RelatedObjectsRefList': selectedRowIds}
				}
				
				if (await form.onSubmitButton(submitType)) {
					setModalButton(false)
					return
				}
				if (form.serviceUpdateHRef) {
					await getFormRestService(form.serviceUpdateHRef)
						.patch(form, '')
				}
				setModalButton(false)
			}}
		>
			<ModalBody modalButton={modalButton} part={part} />
		</FormMetaObject>
	)
}