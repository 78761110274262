import { IEntity, ResourceContextState } from "./DataContextModel";
import { ActionPerformStateUpdate, ActionSetValues } from "./DataContextAction";
import EntityContextController from './EntityContextStateController';

export const reducerSetValues = (draftContext: ResourceContextState, action: ActionSetValues) => {
    const contextController = new EntityContextController(draftContext);
    contextController.setEntityAttributes(action.entityId, action.values);
};

export const reducerrPerformStateUpdate = (draftContext: ResourceContextState, action: ActionPerformStateUpdate) => {
  const contextController = new EntityContextController(draftContext);
  action.callback(contextController);
};

