import React from 'react'
import { useForm } from 'components/form/Form'

/**
 * Rysowanie wierszy zgrupowanych
 */
export function RowsTypeGroup({ children }) {
	const form = useForm('data')
	const group = form.groupManager.group
	const child = React.Children.only(children)

	return React.cloneElement(child, { group })
}