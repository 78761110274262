const OriginType = {
    None: 0,
    AutomatedTranslation: 1,
    NeuralMachineTranslation: 2,
    TranslationMemory: 3,
    NotSupported: 4,

    getShortName: function (originTypeId) {
        switch(originTypeId){
            case OriginType.AutomatedTranslation:
                return "AT";
            case OriginType.NeuralMachineTranslation:
                return "NMT";
            case OriginType.TranslationMemory:
                return "TM";
            default:
                return "N/A";
        }
    }
}

export default OriginType;