import { MixinGrid, MixinFont } from 'components/topology/Topology';
import styled from 'styled-components';

///TODO style
export const CellBorderStyled = styled.div`
	${props => MixinGrid(props)}
	color: #4d4d4d;
	border-bottom: ${({isInlineEdition}) => isInlineEdition ? 'none' : '1px solid #dcdcdc'}; 
	${MixinFont('p-md')};
	min-height: ${({theme}) => `${theme.rowSize}px`};
	max-height: ${(props) =>  {return props.rowHeight !== undefined ? props.rowHeight : `${props.theme.rowSize * 3}px`} };
	background-color: ${ props => props.backgroundColor};
	${({ alignRight }) => alignRight && `text-align: right;`};
	align-content: center;
	display:${({isInlineEdition}) => isInlineEdition && 'flex'}; 
	flex-direction:${({isInlineEdition}) => isInlineEdition && 'column'}; 
`