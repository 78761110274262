import { Box, Divider } from '@material-ui/core';
import React, { MouseEventHandler } from 'react'
import SidebarContentHeader from './SidebarContentHeader';

export type LeftSidebarContentProps = {
    title: string,
    onCloseClick: (event: MouseEventHandler<SVGSVGElement>) => void,
    children: JSX.Element
}

const RightSidebarContent = ({title, children, onCloseClick} : LeftSidebarContentProps) => {
    return (
        <Box id="editor-right-sidebar-box-content" style={{borderRightWidth: '2px', borderRightStyle: 'solid', borderRightColor: 'lightgray', padding: '8px', minWidth: '180px', width: '100%', flex: '1 1 auto', overflowY: 'auto' }}>
            <SidebarContentHeader title={title} onCloseClick={onCloseClick} />
            <Divider />
            {children}
        </Box>

    );
};

export default RightSidebarContent;