import { Reducer } from "react";
import produce from "immer";
import { useReducerAsync, AsyncActionHandlers } from "use-reducer-async";
import { createContainer } from "react-tracked";
import { ActionDisposeContext, ActionPerformRequest, ActionPerformStateUpdate, ActionSetValues } from './DataContextAction';
import { reducerSetValues, reducerrPerformStateUpdate } from './DataContextReducers';
import { ActionRequestStart, ActionRequestSuccess, ActionRequestFailure, ActionPerformRequestDeferred } from "./RequestAction";
import { reducerRequestStart, reducerRequestSuccess, reducerRequestFailure, recuderPerformRequestAsync, reducerPerformRequestDeferred } from "./RequestReducers";
import { ResourceContextState, ContextId } from './DataContextModel';


export interface IContextMap {
    [key: ContextId]: ResourceContextState;
}

export type ResourceContextsContainerState = {
    contexts: IContextMap;
};

export type Action =
    | ActionRequestStart
    | ActionRequestSuccess
    | ActionRequestFailure
    | ActionSetValues
    | ActionPerformStateUpdate
    | ActionPerformRequestDeferred
    | ActionDisposeContext;

const initialState: ResourceContextsContainerState = {
    contexts: {}
};

export const createDefaultContext = (
    contextId: ContextId
): ResourceContextState => {
    return {
        id: contextId,
        revision: 0,
        entityIndex: {},
        entityChangesIndex: {},
        entityDraftIndex: {},
        entityOrderedSet: [],
        requests: [],
        bag: {},
        deferredRequest: null
    };
};

const reducer: Reducer<ResourceContextsContainerState, Action> = (state, action) => {
    return produce(state, (draftState) => {
        if(action.type === "REQUEST_START")
        {
            console.log("[Action] " + action.contextId + " - " + action.type, action?.options?.key);
        }
        else
        {
            console.log("[Action] " + action.contextId + " - " + action.type);
        }
        

        let draftContext = draftState.contexts[action.contextId];
        if (draftContext == null) {
            draftContext = createDefaultContext(action.contextId);
            draftState.contexts[action.contextId] = draftContext;
        }

        draftContext.revision++;

        switch (action.type) {
            case "SET_VALUES":
                reducerSetValues(draftContext, action);
                break;
            case "PERFORM_STATE_UPDATE":
                reducerrPerformStateUpdate(draftContext, action);
                break;
            case "REQUEST_START":
                reducerRequestStart(draftContext, action);
                break;
            case "REQUEST_SUCCESS":
                reducerRequestSuccess(draftContext, action);
                break;
            case "REQUEST_FAILURE":
                reducerRequestFailure(draftContext, action);
                break;
            case "PERFORM_REQUEST_DEFERRED":
                reducerPerformRequestDeferred(draftContext, action);
                break;
            case "DISPOSE":
                draftState.contexts[action.contextId] = createDefaultContext(action.contextId);
                break;
            default:
                throw new Error("unknown action type: " + action['type']);
        }
    });
};


type AsyncAction = ActionPerformRequest;

const asyncActionHandlers: AsyncActionHandlers<Reducer<ResourceContextsContainerState, Action>, AsyncAction> = {
    PERFORM_REQUEST: recuderPerformRequestAsync
};

const useValue = () =>
    useReducerAsync<Reducer<ResourceContextsContainerState, Action>, AsyncAction>(
        reducer,
        initialState,
        asyncActionHandlers
    );

export const {
    Provider,
    useTrackedState,
    useUpdate: useDispatch
} = createContainer(useValue);