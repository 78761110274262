import { Css } from './CssStaticLib'
import React from 'react'

/**
 * Tworzenie custom elementu dla react'a. 
 * 
 * @param {string} name - Nazwa custom elementu. Powinno być w formacie: 'o-dalsza-nazwa'
 * @param {object} definitions - Definicje przekazywane do Css.customElements(definitions)
 * @returns Zwraca funkcje Reaktową która tworzy ten komponent
 * 
 * @example
 * 	//Definiujemy komponent ktorego dzieci sa ulożone w poziomie i z odpowiednimi odstepami
 * 	//W przeladarce ten komponent jest widoczny jako <o-my> 
 * 	const Component=customElements('o-my',['grid-hor','grid-gap-10','m-10'])
 */
export const customElements = (name, definitions) => {
	Css.add({ [name]: definitions })
	Css.customElements(name)
	return React.forwardRef(({ className, ...props }, ref) => {
		return React.createElement(name, { ref, ...props, class: className })
	})
}

