import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'locale/Locale'
import { useForm } from 'components/form/Form'
import { FormMetaObject, InputPassword } from 'components/form/Form'
import Button from 'components/form/components/list/components/buttons/Button'
import getFormRestService from 'services/getFormRestService'
import { ButtonSubmit } from '../list/components'
import { isEmpty, trim } from 'lodash'
import { useSnackbar } from 'notistack'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

/**
 * Widok okienka modalnego ze zmianą hasła
 * @param {Boolean} isModalOpen - Informacja czy modal jest otwarty czy nie
 * @param {Function} setIsModalOpen - Funkcja służąca do zamykania okienka modalnego
 * @param {String} NEW_PASSWORD_NAME - Nazwa kontrolki z hasłem
 * @returns 
 */
const PasswordChangeModal = ({ isModalOpen, setIsModalOpen, NEW_PASSWORD_NAME }) => {
	const translate = useTranslate('WebSpa')
	const form = useForm()

	const handleSaveOnEnter = e => {
		if (e.key === 'Enter') {
			if (!isEmpty(form.changes) && isEmpty(form.errors)) {
				form.asyncSubmit()
			}
		}

		return
	}

	return (
		<Dialog
			fullWidth
			disableBackdropClick
			maxWidth='sm'
			open={isModalOpen}
			onClose={e => setIsModalOpen(false)}
			onPointerDown={e => 	e.stopPropagation() }
		>
			<DialogTitle >
				{translate('PasswordChangeView/title')}
			</DialogTitle>
			<DialogContent dividers onKeyDown={e => handleSaveOnEnter(e)}>
				<form onSubmit={e => e.preventDefault()}>
					<InputPassword
						autoFocus
						name={NEW_PASSWORD_NAME}
						inputProps={{
							autocomplete: 'new-password',
							form: {
								autocomplete: 'off',
							},
						}}
					/>
				</form>
			</DialogContent>
			<DialogActions>
				<ButtonSubmit content={translate('Buttons/changeButton')} />
				<Button type='cancel' onClick={() => setIsModalOpen(false)} content={translate('Buttons/cancelButton')} />
			</DialogActions>
		</Dialog>
	)
}

/**
 * Wrapper dla okienka modalnego pozwalającego na zmianę hasła. Oplata okienko modalne w dane z Formy
 * @param {Boolean} isModalOpen - Informacja czy modal jest otwarty czy nie
 * @param {Function} setIsModalOpen - Funkcja służąca do zamykania okienka modalnego
 * @param {String} personId - ID osoby dla której zmieniamy hasło
 * @param {String} companyId - ID firmy do której należy dana osoba
 * @returns {Node}
 */
const PasswordChangeMetaDriven = ({ isModalOpen, setIsModalOpen, passwordChangeLink }) => {

	const translate = useTranslate('WebSpa')
	const { enqueueSnackbar } = useSnackbar()
	const NEW_PASSWORD_NAME = 'NewPassword'
	const DATA = {
		data: [
			{
				attributes: {}
			}
		],
		meta: {
			dataType: 'IdentityPasswordChange',
			attributes: {
				NewPassword: {
					name: NEW_PASSWORD_NAME,
					label: translate('MainMenu/Company/Person/PasswordChange/label'),
					type: 'String',
					validations: [{ type: 'Required' }, { type: 'PasswordValidate' }]
				}
			}
		}
	}
	
	if (!isModalOpen) {
		return null
	}

	return (
		<FormMetaObject
			data={DATA}
			errorModal={() => null}
			onSubmit={async form => {
				const response = await getFormRestService(passwordChangeLink).patch(form, '')

				if (response) {
					enqueueSnackbar(translate('Snackbar/PasswordChange/succes'), { variant: 'success', preventDuplicate: true })
				}
				else {
					form.globalError = form?.errorFromService?.detail ?? null
					form.trigger('data')
					const snackbarErrorText = form.errorFromService?.detail || form.errorFromService?.title || translate('Snackbar/PasswordChange/error/badServerResponse')
					enqueueSnackbar(snackbarErrorText, { variant: 'error', preventDuplicate: true })
				}

				setIsModalOpen(false)
			}}
		>
			<PasswordChangeModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				NEW_PASSWORD_NAME={NEW_PASSWORD_NAME}
			/>
		</FormMetaObject>
	)
}

/**
 * Przycisk wraz z okienkiem modalnym do zmiany hasła
 * @param {String} personId - ID osoby dla której zmieniamy hasło
 * @param {String} companyId - ID firmy do której należy dana osoba
 * @returns 
 */
const PasswordChange = ({ passwordChangeLink }) => {
	const translate = useTranslate('WebSpa/Buttons')
	const [isModalOpen, setIsModalOpen] = useState(false)

	if (isEmpty(trim(passwordChangeLink))){
		console.error(`Brak linku do zmiany hasła`)
		return null
	}

	return (
		<>
			<PasswordChangeMetaDriven
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				passwordChangeLink={passwordChangeLink}
			/>
			<Button
				type='submit'
				onClick={() => setIsModalOpen(true)}
				content={translate('passwordChange')}
			/>
		</>
	)
}

//#region Export Komponentu
export default PasswordChange
//#endregion

//#region PropTypes & DefaultProps
PasswordChange.propTypes = {
	personId: PropTypes.string.isRequired,
	companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

PasswordChangeMetaDriven.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
	personId: PropTypes.string,
	companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

PasswordChangeModal.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
	NEW_PASSWORD_NAME: PropTypes.string
}

PasswordChange.defaultProps = {
	personId: '',
	companyId: '',
}

//#endregion 