import React from 'react'
import { Horizontal } from 'components/topology/Topology'
import { useForm } from 'components/form/Form'
import { rowColor } from '../row/rowColor'
import { TreeIndent, TreeAddChild } from '../tree'
import { CellBorderStyled } from './CellBorderStyled'
import { toString } from 'lodash'
import { messageColumnName } from 'components/form/classes/ColumnManager'

/**
 * @param {string} rowHeight Wysokość komórek w wierszu
 */
export function CellBorder({ row, rowIndex, columnIndex, rowSpan, children, onClick, onDoubleClick, onClickTreeChild, rowHeight, tree, ...other }) {
	const form = useForm('data')
	const dataType = form.dataType
	const isInlineEdition = Boolean(form.parentForm?.rowManager?.inlineEdition)
	const selected = form.parentForm.rowManager.selectedRowId === row.id
	const marked = form.parentForm.rowManager.isMarked(row.id)
	const editMode = form.editMode
	const backgroundColor = rowColor({ selected, marked })
	const child = React.Children.only(children)
	let content = React.cloneElement(child, { row, rowIndex, columnIndex, backgroundColor, rowHeight: rowHeight, ...other });
	const cellID = `${toString(dataType)}-${toString(content.props.column.name)}-row-${toString(editMode)}-${toString(rowIndex)}`;

	if (!form.hasMeta(content.props.column.name) && content.props.column.name !== messageColumnName)
		return (
			<CellBorderStyled
				alignRight={false}
				rowSpan={rowSpan}
				onClick={e => onClick && onClick({ form, rowIndex, e })}
				onDoubleClick={() => onDoubleClick && onDoubleClick({ form, rowIndex })}
				backgroundColor={backgroundColor}
				isInlineEdition={isInlineEdition}
			>
			</CellBorderStyled>
		)


	const field = form.getField(content.props.column.name)
	const type = field.meta.type

	if (tree && columnIndex === 0)
		content = (
			<Horizontal className='grid-acx-start'>
				<TreeIndent tree={tree} />
				{content}
			</Horizontal>
		)
	else
		if (tree && form.parentForm.columnManager.visibleColumnArray.length - 1 === columnIndex)
			content = (
				<Horizontal className='grid-acx-space-between'>
					{content}
					<TreeAddChild tree={tree} onClickTreeChild={onClickTreeChild} rowIndex={rowIndex} />
				</Horizontal>
			)

	return (
		<CellBorderStyled
			id={cellID}
			alignRight={type === 'Number' || type === 'Int' ? true : false}
			rowSpan={rowSpan}
			onClick={e => onClick && onClick({ form, rowIndex, e })}
			onDoubleClick={() => onDoubleClick && onDoubleClick({ form, rowIndex })}
			backgroundColor={backgroundColor}
			isInlineEdition={isInlineEdition}
			rowHeight={rowHeight}
		>
			{content}
		</CellBorderStyled>
	)
}