import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'components/form/Form'
import Combobox, { ComboboxMenu } from './Combobox'
import { ComboboxTag } from './Combobox'
import { differenceBy, isArray } from 'lodash'

/**
 * Komponent obsługujący dane przychodzące o typie "EnumList" w widoku edycji wiersza
 * @param {String} name - Nazwa kontrolki
 * @param {Boolean} hasLabel - Informacja czy komponent jest wyświetlany normalnie czy w widoku edycji wiersza
 * @param {Boolean} useRefValuesArray - Parametr mówiący o tym czy wartości do Comboboxa powinny zostać pobrane z tablicy values czy refValues.
 */
const ComboboxEnumList = ({ name, hasLabel, useRefValuesArray }) => {

   const field = useField(name)
   const options = (useRefValuesArray ? field?.meta?.refValues : field?.meta?.values) ?? []
   const filteredOptions = differenceBy(options, field.value, 'id' ) //Filtrujemy po "id" już wybrane opcje tak aby nie były możliwe do ponownego wybrania z listy wartości
   const { label, error, placeholder, disabled, required } = field ?? {}
   const inputParams = { label, error, placeholder, disabled, required, hasLabel }
   
   const handleChange = (e, newValue) => {
      e.stopPropagation()
      
      if (isArray (newValue)) 
         field.handleChange(newValue)
      }
      
  
   return (
      <Combobox
         name={name}
         multiple
         filterSelectedOptions
         limitTags={1}
         options={filteredOptions}
         inputParams={inputParams}
         forcePopupIcon={false}
         value={field?.value ?? []}
         onChange={(e, newValue) => handleChange(e, newValue)}
         getOptionLabel={option => option.value ?? ""}
         renderOption={(option, currentInputValue) => <ComboboxMenu option={option.value} inputValue={currentInputValue} name={name} />}
         renderTags={(tags, getTagProps) => (
            tags.map((tag, index) => <ComboboxTag getTagProps={getTagProps} value={tag?.value} index={index} />)
         )}
         isComboboxList
      />
   )
}


/* #region Export komponentu */
   export default ComboboxEnumList
/* #endregion */


/* #region  PropTypes */
ComboboxEnumList.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool,
   useRefValuesArray: PropTypes.bool,
}

ComboboxEnumList.defaultProps = {
	hasLabel: true,
   useRefValuesArray: false
}
/* #endregion */
