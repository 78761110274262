import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import useField from 'components/form/hooks/useField'
import { useTextFieldStyles } from 'components/topology/StyledTextField'
import { Box, InputAdornment, TextField } from '@material-ui/core'
import NewIcons from 'components/icon/NewIcons'
import trim from 'lodash/trim'
import trimStart from 'lodash/trimStart'
import { Tooltip } from 'components/Tooltip'

/**
 * Komponent labelki kontrolki wyświetlający "..." gdy labelka przekroczy maksymalny rozmiar (nie będzędzie widoczna w ramach kontrolki)
 * @param {String} label - Tekst labelki
 * @param {Boolean} isRequired - Informacja czy pole jest wymagane
 * @returns {Node} Komponent labelki
 */
export const InputLabel = React.memo(({ label, isRequired }) => {

	if(!trim(label) || typeof label !== 'string')
		return null

	return (
		<Box textOverflow="ellipsis" overflow="hidden">
			{`${label} ${isRequired ? '*' : ''}` } {/* Gdy labelka jest wymagana doklejamy znaczek "*"  do tekstu */}
		</Box>
	)
})

/**
 * Komponent renderujący ikonę w kontrolce
 * @param {String} icon - Nazwa ikony do użycia  
 * @param {Object} disabled - informacja czy kontrolka jest aktywna
 * @param {Bool} error - Informacja czy kontrolka ma jakiś błąd
 */
const InputIcon = React.memo(({ disabled, error, icon }) => (
	<InputAdornment position="end">
		<NewIcons name={icon && icon} disabled={disabled} error={error} style={{ cursor: disabled && 'default' }} width={42} />
	</InputAdornment>
))

/**
 * Komponent renderujący kontrolki różnego typu 
 *
 * @param {String} name Nazwa pola
 * @param {String} type typ kontrolki
 * @param {Boolean} autoComplete czy zezwalać na auto kompletowanie treści w kontrolce
 * @param {Node} icon Ikonka która ma pojawić się w kontrolce
 * @param {Boolean} hasIcon Informacja czy kontrolka posiada ikonkę czy nie 
 * @param {Boolean} error Informacja czy kontrolka ma błąd.
 * @param {String} helperText Tekst błędu wyświetlany pod kontrolką
 * @param {String} required Informacja czy pole jest wymagane
 * @param {String} label Labelka kontrolki
 * @param {Boolean} hasLabel Informacja czy kontrolka ma mieć labelkę
 * @param {Boolean} disabled Informacja czy kontrolka jest aktywna
 * @param {String} value Aktualna wartość kontrolki
 * @param {String} placeholder Placeholder kontrolki
 * @param {String} hint hint kontrolki
 * @param {Function} onInput - Callback uruchamiany podczas zmiany wartości kontrolki
 * @param {Object} rootClassName - Style z biblioteki material-ui dla kontrolki
 * @param {Object} other - Pozostałe propsy przekazane do kontrolki
 * @returns {Node} Komponent Kontrolki 
 */
const Input = React.memo(({ name, type, autoComplete, icon, hasIcon, error, helperText, required, label, hasLabel, disabled, value, placeholder, hint, onInput, rootClassName, ...other }) => (
	<Tooltip title={(label ? label : '') + ( hint ? '- ' + hint : '' )}>
		<TextField
			name={name}
			variant="outlined"
			size="small"
			fullWidth
			type={type}
			className={rootClassName}
			label={hasLabel && <InputLabel label={label} isRequired={required} />}
			disabled={disabled}
			error={Boolean(error)}
			helperText={helperText}
			value={value}
			placeholder={placeholder}
			autoComplete={autoComplete}
			InputLabelProps={{ shrink: true }}
			InputProps={{ endAdornment: icon && hasIcon && <InputIcon disabled={disabled} error={error} icon={icon} /> }}
			onInput={e => onInput(e)}
			{...other}
		/>
	</Tooltip>
))


/**
 * Komponent powzalający izolować komponent <Input /> w celu ograniczenia ponownego renderowania komponentu, podczas gdy jego propsy nie uległy zmianie.
 * Komponent <Input /> zostanie rerenderowany jedynie w momencie gdy zmienią się któreś z propsów do niego przekazywanych
 * 
 * @param {String} name Nazwa pola
 * @param {String} type typ kontrolki
 * @param {Function} onValueParse typ kontrolki
 * @param {Boolean} hasLabel typ kontrolki
 * @param {Boolean} autoComplete czy zezwalać na auto kompletowanie treści w kontrolce
 * @param {Node} icon Ikonka która ma pojawić się w kontrolce
 * @param {Boolean} hasIcon Informacja czy kontrolka posiada ikonkę czy nie 
 * @param {Object} other - Pozostałe propsy przekazane do kontrolki
 * @returns {Node} Komponent Kontrolki 
 */
const InputRenderProvider = ({ name, type, onValueParse, hasLabel, autoComplete, icon, hasIcon, ...other }) => {
	const field = useField(name)
	const { root } = useTextFieldStyles({ hasLabel, error: Boolean(field.error) })

	const onInput = useCallback((e) => {
		const currentInputValue = trimStart(e.target?.value)

		if (onValueParse) {
			field.handleChange(onValueParse(currentInputValue))
			return
		}

		field.handleChange(currentInputValue)
	}, [])

	return (
		<Input 
			name={name}
			type={type}
			autoComplete={autoComplete}
			icon={icon}
			hasIcon={hasIcon}
			error={Boolean(field.error)}
			helperText={(field.error && hasLabel && !field.disabled) && field.error}
			required={field.required}
			label={field.label}
			hasLabel={hasLabel}
			disabled={field.disabled}
			value={field.valueInput}
			placeholder={field.placeholder}
			hint={field.hint}
			onInput={onInput}
			rootClassName={root}
			{...other}
		/>
	)
} 

//#region Export komponentu
	export default InputRenderProvider	
//#endregion

//#region  PropTypes
InputRenderProvider.propTypes = {
	name: PropTypes.string.isRequired,
	type: PropTypes.string,
	autoComplete: PropTypes.bool,
	icon: PropTypes.string,
	hasIcon: PropTypes.bool,
	error: PropTypes.bool,
	helperText: PropTypes.string, 
	required: PropTypes.bool, 
	label: PropTypes.string, 
	hasLabel: PropTypes.bool, 
	disabled: PropTypes.bool, 
	value: PropTypes.string, 
	placeholder: PropTypes.string, 
	hint: PropTypes.string, 
	onInput: PropTypes.func, 
	rootClassName: PropTypes.object
}
//#endregion

Input.propTypes = {
	name: PropTypes.string.isRequired,
	onValueParse: PropTypes.func, 
	hasLabel: PropTypes.bool, 
	autoComplete: PropTypes.bool,
	icon: PropTypes.string,
	hasIcon: PropTypes.bool,
}

//#region  DefaultProps
InputRenderProvider.defaultProps = {
	name: '',
	hasLabel: true,
	hasIcon: true,
}
//#endregion



//******//TODO NIE USUWAĆ- do dokończenia - poprawa wydajności w inputach**** */

// import React, { useState } from 'react'
// import useField from 'components/form/hooks/useField'
// import FormGroup from 'components/form/components/formGroup/FormGroup'
// import { ConditionalWrapper } from 'Tools/Tools'
// import { StyledTextField } from 'components/topology/StyledTextField'
// import { InputAdornment} from '@material-ui/core'
// import NewIcons from 'components/icon/NewIcons'
// import RefreshableContext from 'components/refreshableContext/RefreshableContext'
// import Data from './InputClass'

// //#region Tworzenie kontekstu
// 	const context = RefreshableContext.create()
// //#endregion

// /**
//  * 
//  * @param {Object} field - Obiekt pola z właściwościamy  
//  * @param {String} icon - Nazwa ikony do użycia  
//  * @param {Bool} hasIcon - Informacja czy komponent posiada ikonę
//  */
// const InputIcon = ({icon, hasIcon}) => {
// 	const inputData = context.useContext()

// 	return (icon && hasIcon ? 
// 		<InputAdornment position="end">
// 			<NewIcons name={icon && icon} disabled={inputData.disabled} error={inputData.error} style={{ cursor: inputData.disabled && 'default' }} width={42} />
// 		</InputAdornment>
// 		: null
// 	)
// }

// const InputComponent = ({icon, hasIcon, autoComplete, hasLabel, ...other}) => {
// 	const inputData = context.useContext()
// 	const {name, inputValue, type, required, label, disabled, placeholder} = inputData 

// 	React.useEffect(() => {
// 		const timeoutId = setTimeout(() => inputData.field?.handleChange(inputValue), 400);
// 		return () => clearTimeout(timeoutId);
// 	}, [ inputValue ])

// 	return (
// 	   <ConditionalWrapper
//         condition={hasLabel}
//         wrapper={children => <FormGroup>{children}</FormGroup>}
//       >
// 			<StyledTextField
// 				name={name}
// 				type={type}
// 				required={required}
// 				variant="outlined"
// 				size="small"
// 				fullWidth
// 				label={hasLabel && label}
// 				disabled={disabled}
// 				error={Boolean(inputData.error && !inputData.disabled )}
// 				helperText={inputData.error && hasLabel && !inputData.disabled && inputData.error}
// 				value={inputValue}
// 				placeholder={placeholder}
// 				autoComplete={autoComplete}
// 				style={{ height: hasLabel && '60px' }}
// 				InputLabelProps={{ shrink: true  }}
// 				InputProps={{endAdornment: <InputIcon icon={icon} hasIcon={hasIcon} />}}
// 				onInput={e => inputData.handleInputChange(e)}
// 				// onBlur={e => handleOnBlur(e)}
// 				{...other}
// 			/>
// 		</ConditionalWrapper>
// 	)
// }

// /**
//  * Pole input
//  *
//  * @param {string} name Nazwa pola
//  * @param {string} type typ imputa
//  * @param {function} onValueParse Funkcja parsowania danych
//  * @param {boolean} hasLabel Czy ma labelkę
//  * @param {boolean} autoComplete czy może być auto kompletowanie treści
//  * @returns {Node}
//  */
// export default function Input({name, type, onValueParse,	hasLabel, autoComplete, icon, hasIcon, ...other}) {

// 	const field = useField(name)

// 	return(
// 		<context.Provider
// 			fixedData
// 			createData={() => new Data(name, field, type, onValueParse )}
// 		>
// 			<InputComponent icon={icon} hasIcon={hasIcon} autoComplete={autoComplete} hasLabel={hasLabel} {...other} />
// 		</context.Provider>
// 	)
// }

// Input.defaultProps = {
// 	icon: undefined,
// 	hasLabel: true,
// 	hasIcon: true
// }


