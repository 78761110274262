import React from 'react'
import { useLocale } from 'locale/Locale'
import { FormMetaObject, ComboboxEnum } from 'components/form/Form'
import getFormRestService from 'services/getFormRestService'
import { useAppCtrl } from 'AppCtrl'
import { Redirect } from 'react-router-dom'
import { ComboboxInput } from 'components/form/components/combobox/Combobox'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(({ palette }) => ({
	root: {
		'& [class*="MuiOutlinedInput-root"]': {
			padding: '1px !important',
			paddingLeft: '0px !important',
			fontSize: '17px !important',
			border: 'none !important',

		},
		'& [class*="MuiOutlinedInput-root"] fieldset': {
			border: `1px solid ${palette.primary.mainDarken800}`,
			borderColor: 'transparent !important',
			transition: 'all .19s cubic-bezier(0.4, 0, 0.2, 1)',
		},

		'& [class*="MuiOutlinedInput-root"]:hover fieldset': {
			borderColor: `${palette.primary.mainDarken800} !important`
		},
	},
}));

function ChangeCultureCodeView() {
	const classes = useStyles();

	return (
		<ComboboxEnum
			name='CultureCode'
			disableClearable
			style={{ width: 125, height: 31 }}
			hasLabel={false}
			renderInput={(params) => <ComboboxInput name='CultureCode' params={params} className={classes.root} />}
			onChange={field => field.form.asyncSubmit()}
		/>
	)
}

export function ChangeCultureCode() {
	const appCtrl = useAppCtrl()
	const locale = useLocale()

	if (!appCtrl.session.isAuthorized)
		return <Redirect to='/login' />

		const languages = locale.languages.map(lang => {
			return {
				value: lang.name,
				id: lang.id,
			}
		})
		
	const mapLang = locale.languages.reduce((prev, current) => {
		prev[current.name] = current
		return prev
	}, {})

	const currentLanguage = languages.find(language => language.id === locale?.currentLanguage.id)

	return (
		<FormMetaObject
			data={{
				meta: {
					dataType: 'AuthLogin',
					attributes: {
						CultureCode: {
							name: 'CultureCode',
							type: 'Enum',
							editable: 'Editable',
							values: languages
						}
					}
				},
				data: [
					{
						attributes: {
							CultureCode: currentLanguage
						}
					}
				]
			}}
			onSubmit={async form => {
				if (!form.isOk)
					return
					
				const langName = form.getValue('CultureCode')
				const langCultureCode = mapLang[langName.value].cultureCode


				if (await getFormRestService('/auth/identities/me/culture').patchCulturecode(form, langCultureCode)) {
					appCtrl.locale._cultureCode = langCultureCode
					appCtrl.logout()
					appCtrl.refresh()
				}
			}}
			redirectToLogin={() => null}
			errorModal={() => null}
		>
			<ChangeCultureCodeView />
		</FormMetaObject>
	)
}