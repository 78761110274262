import React from 'react'
import useForm from 'components/form/hooks/useForm'
import { useTranslate } from 'locale/Locale'
import Button from 'components/form/components/list/components/buttons/Button'

/**
 * Struktura danych generowanych dla Modala z Filtrowaniem:
 * {
 *  meta: {
			attributes: {
				"FieldLabel": { "name": "FieldLabel", "label": locale.translate("WebSpa/FilterModal/columnName"), "type": "String", "editable": "NonEditable", "sequenceIndex": 20, "sections": ["user", "list"], "validations": [{ "type": "Required" }] },
				"FieldName": { "name": "FieldName", "label": "Nazwa pola", "type": "String", "editable": "NonEditable", "sequenceIndex": 1, "sections": ["user",], "validations": [] },
			}
	 data: {
		  id: col.name,
		  attributes: {
				"FieldLabel": col.meta.label, 
				"FieldName": col.meta.name,
				"From": undefined, - KOLUMNA OPCJONALNA
				"To": undefined, - KOLUMNA OPCJONALNA
				"Value": '', - KOLUMNA OPCJONALNA
				"ComparisionOperator": "Gdziekolwiek" - KOLUMNA OPCJONALNA
				},
		  links: {},
		  meta: {
				attributes: {
KOLUMNA OPCJONALNA: "From": { "name": "From", "label": locale.translate("WebSpa/FilterModal/fromValue"), "type": col.meta.type, "editable": "Editable", "sequenceIndex": 40, "sections": ["user", "list"], "validations": [] },
KOLUMNA OPCJONALNA: "To": { "name": "To", "label": locale.translate("WebSpa/FilterModal/toValue"), "type": col.meta.type, "editable": "Editable", "sequenceIndex": 50, "sections": ["user", "list"], "validations": [] },
KOLUMNA OPCJONALNA: "Value": { "name": "Value", "label": locale.translate("WebSpa/FilterModal/searchedValue"), "type": col.meta.type, "editable": "Editable", "sequenceIndex": 30, "sections": ["user", "list"], "validations": [] },
KOLUMNA OPCJONALNA: "ComparisionOperator": { "name": "ComparisionOperator", "label": "Typ", "type": "Enum", "editable": "Editable", "sequenceIndex": 2 , "sections": ["list", "user"], "values": ["Na początku", "Gdziekolwiek", "Na końcu",], "validations": [] }
					}
			}
	 }
  }
 
 * FieldLabel -                Nazwa pola wyświetlana w kontrolce
 * FieldName -                 Nazwa pola wysyłana do BE (Nie wyświetlana)
 * From -                      Pole zakresu używane dla typów: Date, Timestamp, Int, Number
 * To -                        Pole zakresu używane dla typów: Date, Timestamp, Int, Number
 * Value -                     Pole do wpisywania ciągu znaków, używane typów: String 
 * ComparisionOperator -       Pole w którym określamy gdzie szukamy danej wartości, używane dla typów: Ref | (Wartości przyjmowane przez pole: "Na początku", "Gdziekolwiek", "Na końcu")
 

 ******* sequenceId: ***********
FieldName  -   0
FieldLabel -   10
ComparisionOperator -         20
Value -        30
From -         40 
To -           50
 ********************************
 */


export function FilterButton({ close }) {
	const form = useForm('data')
	const translate = useTranslate('WebSpa/FilterModal')
	const parentForm = form.parentForm

	const handleButtonClick = () => {
		//Ta pętla pozwala na zapis wszystkich zmian w momencie gdy naciśniemy przycisk "Filtruj", nie musimy "tracić" focusu z pola które edytujemy
		form.childForms.forEach(childForm => {
			if (childForm.isChanged)
				childForm.changesToValues()
		})

		const props = form.filterManager.getPropsFromRows(form.data)

		for (let index = 0; index < props.length; index++) {
			const fieldFilter = props[index];
			const fieldName = Object.keys(fieldFilter)[0];			
			props[index][fieldName].value = fieldFilter[fieldName].value;
		}

		if (props.length)
			close(); //Zamykanie modala jeśli zakończymy filtrować

		parentForm.currentPage = 1 // Przechodzenie do pierwszej strony po filtrowaniu	
		parentForm.asyncSubmit('Filter', props)
	}

	return (
		<Button
			type='submit'
			onClick={() => handleButtonClick()}
			content={translate('submitButton')}
		/>
	)
}