import React from 'react'
import { MessageListener } from 'components/form/Form'
import Tab from 'contents/main/dataView/Tab'

export function ModulePartInner({ part }) {
	return (
		<div className='layout-part'>
			<MessageListener />
			<Tab tab={[...part.parts.filter(p => p.reachable !== false)]} />
		</div>
	)
}
