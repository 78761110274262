import decoratorsId from "../../consts/decoratorsId";
import { CharacterMetadata, CompositeDecorator, ContentBlock, ContentState, DraftEditorCommand, DraftEntityMutability, DraftHandleValue, Editor, EditorState, EntityInstance, getDefaultKeyBinding, Modifier, RichUtils, SelectionState } from "draft-js";
import SegmentElementViewModel from "../model/SegmentElementViewModel";
import SegmentElementType from "../model/SegmentElementType";
import SegmentViewModel from "../model/SegmentViewModel";
import { OrderedSet } from 'immutable';
import { SegmentElementRole, SegmentElementStatus } from "../model/TranslationUnitViewModel";

export type Range = {
    startIdx: number,
    endIdx: number
};

/**
 * Czy dwa zakresy na siebie zachodzą, tj. mają część wspólną?
 */
export const doesRangesOverlap = (r1: Range, r2: Range) : boolean =>
{
    return r1.startIdx < r2.endIdx && r2.startIdx < r1.endIdx;
}

/**
 * Typ opisujący pojedynczą encje edytora.
 */
export type EntityFromState = {
    entityKey: string,
    blockKey: string,
    entity: EntityInstance,
    location: Range
};

/**
 * Metadane encji edytora
 */
export type EntityMetadata = {
    element?: any,
    changeTracking?: any
}

export type MergedEntity = {
    location: Range,
    entityType: string,
    text?: string,
    metadata: EntityMetadata
};

export const newLineCharacter = '\u000a';

export const initEditorContent = (segmentObject: any, allowedTags: any[]) => {
    let editorState = EditorState.createEmpty()
    editorState = appendSegmentObjectToEditorState(editorState, segmentObject, allowedTags);
    let contentState = editorState.getCurrentContent()
    return contentState
}

export const appendSegmentObjectToEditorState = (editorState: EditorState, segmentObject: any, allowedTags: any[]) : EditorState => {
    let tags = segmentObject.elements
    let isIns = false
    let isDel = false
    let insMeta = null
    let delMeta = null
    let isHighlighted = false;
    let isHighlightSelected = false;
    let isHighglightTerminology = false;
    tags.forEach((tag, index) => {
        if (tag._typeName === SegmentElementType.StartIns){
            isIns = true
            insMeta= tag
        }
        else if (tag._typeName === SegmentElementType.EndIns){
            isIns = false
        }
        else if (tag._typeName === SegmentElementType.StartDel){
            isDel = true
            delMeta= tag
        }
        else if (tag._typeName === SegmentElementType.EndDel){
            isDel = false
        }
        else if(tag._typeName === SegmentElementType.StartHighlight)
        {
            isHighlighted = true;
            isHighlightSelected = tag.status === SegmentElementStatus.Selected ? true: false;
            isHighglightTerminology = tag.role === SegmentElementRole.HighglightTerminology;
            //console.log(tag);
        }
        else if(tag._typeName === SegmentElementType.EndHighlight)
        {
            isHighlighted = false;
        }
        else if (tag.isText){
            if (isIns) {
                editorState = insertCharsAsEntity(tag.value, editorState, decoratorsId.INS, { changeTracking: insMeta });
            }
            else if (isDel) {
                editorState = insertCharsAsEntity(tag.value, editorState, decoratorsId.DEL, { changeTracking: delMeta });
            }
            else {
                let style = null;
                if(isHighlighted)
                {
                    if(isHighglightTerminology)
                        style = 'green'
                    else if(isHighlightSelected)
                        style = 'oliveDark';
                    else
                        style = 'olive';
                }
                editorState = insertCharsAsEntity(tag.value, editorState, decoratorsId.SOURCE_TEXT, {}, style );
            }
        }
        else if (tag.isStartTag){
            if (isIns) {
                editorState = insertTagAsEntity(tag.crossFormatId + ' START', editorState, decoratorsId.INS_TAG, 'IMMUTABLE', { element: tag, changeTracking: insMeta });
            }
            else if (isDel) {
                editorState = insertTagAsEntity(tag.crossFormatId + ' START', editorState, decoratorsId.DEL_TAG, 'IMMUTABLE', { element: tag, changeTracking: delMeta });
            }
            else {
                editorState = insertTagAsEntity(tag.crossFormatId + ' START', editorState, decoratorsId.TAG, 'IMMUTABLE', { element: tag });
            }
        }
        else if (tag.isEndTag){
            const pairedTag = allowedTags.find((allowedTag) => allowedTag.anchor === tag.anchor && allowedTag.isStartTag)
            if(pairedTag)
            {
                if (isIns) {
                    editorState = insertTagAsEntity(pairedTag.crossFormatId + ' END', editorState, decoratorsId.INS_TAG, 'IMMUTABLE', { element: tag, changeTracking: insMeta });
                }
                else if (isDel) {
                    editorState = insertTagAsEntity(pairedTag.crossFormatId + ' END', editorState, decoratorsId.DEL_TAG, 'IMMUTABLE', { element: tag, changeTracking: delMeta });
                }
                else {
                    editorState = insertTagAsEntity(pairedTag.crossFormatId + ' END', editorState, decoratorsId.TAG, 'IMMUTABLE', { element: tag });
                }
            }
        }
        else if (tag.isStandaloneTag)
        {
            editorState = insertTagAsEntity(tag.crossFormatId, editorState, decoratorsId.TAG, 'IMMUTABLE', { element: tag, changeTracking: insMeta });
        }
    })

    return editorState;
}

/** Dodaj znaki jako podany typ encji. Typ encji na ten momement to element z "decoratorsId". */
export const insertCharsAsEntity = (chars: string, editorState: EditorState, entityType: string, metadata: EntityMetadata, style: string = null): EditorState => {
    if(!entityType)
        throw Error('Argumnet entityStype cannot be null or empty');

    const currentContent = editorState.getCurrentContent();
    let selection = editorState.getSelection();
    let contentStateWithNewText = currentContent;

    for (let i = 0; i < chars.length; i++) 
    {
        const character = chars.charAt(i);
        if(i !== 0)
        {
            selection = contentStateWithNewText.getSelectionAfter();
        }

        const entity = currentContent.createEntity(entityType, 'SEGMENTED', metadata);
        const entityKey = currentContent.getLastCreatedEntityKey();
        let inlineStyle = null;
        if(style)
        {
            inlineStyle = OrderedSet.of(style);
        }
        contentStateWithNewText = Modifier.insertText(contentStateWithNewText, selection, character, inlineStyle, entityKey);
    }

    return EditorState.push(editorState, contentStateWithNewText, 'insert-characters');
}

export const insertTagAsEntity = (tagName: string, editorState: EditorState, entityType: string, mutability: DraftEntityMutability, metadata: EntityMetadata) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const contentStateWithEntity = currentContent.createEntity(entityType, mutability, metadata);
    const entityKey = currentContent.getLastCreatedEntityKey();
    const textWithEntity = Modifier.insertText(currentContent, selection, tagName, null, entityKey);
    const newState = EditorState.push(editorState, textWithEntity, 'insert-characters');

    return newState;
}

export const replaceEntity = (charToIns: string, editorState: EditorState, entityType, offset: Range, metadata: EntityMetadata): EditorState => {
    const currentContent = editorState.getCurrentContent();
    let contentStateWithNewText = currentContent
    const selection = contentStateWithNewText.getSelectionAfter();
    let anchorKey = selection.getAnchorKey();
    const targetRange = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset: offset.startIdx,
        focusKey: anchorKey,
        focusOffset: offset.endIdx,
    });
    const entity = currentContent.createEntity(entityType, 'IMMUTABLE', metadata);
    const entityKey = currentContent.getLastCreatedEntityKey()
    contentStateWithNewText = Modifier.replaceText(contentStateWithNewText, targetRange, charToIns, null, entityKey);
    return EditorState.push(editorState, contentStateWithNewText, 'insert-characters');
}

export const changeStateOnCloseAutoSuggest = (editorState: EditorState): EditorState  => {
    const selection = editorState.getSelection()
    const startCaret = selection.getStartOffset()
    let newState = insertCharsAsEntity('@', editorState, decoratorsId.SOURCE_TEXT, {})
    const anchorKey = selection.getAnchorKey();
    const newSelection = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset:startCaret+1,
        focusKey: anchorKey,
        focusOffset: startCaret+1,
    });
    newState = EditorState.forceSelection(newState, newSelection);
    return newState
}

/**
 * Pobierz encje edytora w formie "surowej" czyli w postaci takiej jakiej są reprezentowane w edytorze.
 * @param editorState Stan edytora
 * @param entityType Typ encji jeśli chcemy przefiltrować po konkretnym typie
 * @returns 
 */
export const getEntitiesFromEditorState = (editorState: EditorState, entityType: string = null): EntityFromState[] => {
    const content = editorState.getCurrentContent();
    const entities: EntityFromState[] = [];

    for (const contentBlock of content.getBlocksAsArray()) {
        const entitiesForBlock = getEntitiesFromContentBlock(contentBlock, content, entityType);
        entities.push(...entitiesForBlock);
    }

    return entities;
}

export const getEntitiesFromContentBlock = (contentBlock: ContentBlock, contentState: ContentState, entityType: string = null): EntityFromState[] => {
    const entities = [];
    let matchingEntity: EntityFromState = null;

    contentBlock.findEntityRanges((character: CharacterMetadata) => {
        if (character.getEntity() === null) {
            return false;
        }

        const entity: EntityInstance = contentState.getEntity(character.getEntity());
        if(!entityType || (entityType && entity.getType() === entityType))
        {
            matchingEntity = {
                entityKey: character.getEntity(),
                blockKey: contentBlock.getKey(),
                entity: contentState.getEntity(character.getEntity()),
                location: {
                    startIdx: -1,
                    endIdx: -1
                }
            };

            return true;
        }

        return false;
    },
    (start: number, end:number) => {
        matchingEntity.location = {
            startIdx: start,
            endIdx: end
        };
        entities.push(matchingEntity);
    });

    return entities;
}

export const convertEntitiesToBackendForm = (entities: EntityFromState[], editorState: EditorState) : SegmentViewModel => 
{
    let contentState = editorState.getCurrentContent()
    let text = editorState.getCurrentContent().getPlainText(newLineCharacter)
    let mergedEntities = mergeEntities(entities, contentState)
    let newSegmentObjectsArray = []

    for(let currentEntityIdx = 0; currentEntityIdx < mergedEntities.length; currentEntityIdx++)
    {
        const currentEntity = mergedEntities[currentEntityIdx];
        switch(currentEntity.entityType)
        {
            case decoratorsId.SOURCE_TEXT:
                newSegmentObjectsArray.push(convertTextToBackendForm(currentEntity));
                break;
            case decoratorsId.INS:
                const insertTextElement = convertTextToBackendForm(currentEntity);
                if(!tryInsertElementBetweenLastInsertTrackingTags(insertTextElement, newSegmentObjectsArray))
                {
                    insertElementBetweenNewlyCreatedInsertTrackingTags(insertTextElement, currentEntity, newSegmentObjectsArray);
                }
                break;
            case decoratorsId.DEL:
                const deleteTextElement = convertTextToBackendForm(currentEntity);
                if(!tryInsertElementBetweenLastDeleteTrackingTags(deleteTextElement, newSegmentObjectsArray))
                {
                    insertElementBetweenNewlyCreatedDeleteTrackingTags(deleteTextElement, currentEntity, newSegmentObjectsArray);
                }
                break;
            case decoratorsId.TAG:
                const tagElement = convertTagToBackendForm(currentEntity);
                newSegmentObjectsArray.push(tagElement);
                break;
            case decoratorsId.INS_TAG:
                const insertedTagElement = convertTagToBackendForm(currentEntity);
                if(!tryInsertElementBetweenLastInsertTrackingTags(insertedTagElement, newSegmentObjectsArray))
                {
                    insertElementBetweenNewlyCreatedInsertTrackingTags(insertedTagElement, currentEntity, newSegmentObjectsArray);
                }
                break;
            case decoratorsId.DEL_TAG:
                const deleteTagElement = convertTagToBackendForm(currentEntity);
                if(!tryInsertElementBetweenLastDeleteTrackingTags(deleteTagElement, newSegmentObjectsArray))
                {
                    insertElementBetweenNewlyCreatedDeleteTrackingTags(deleteTagElement, currentEntity, newSegmentObjectsArray);
                }
                break;
        }
    }

    let segmentViewModel = new SegmentViewModel(newSegmentObjectsArray)
    return segmentViewModel
}

export const mergeEntities = (entities: EntityFromState[], contentState, contentBlock = null) => {
    let text = contentState.getPlainText(newLineCharacter);
    if(contentBlock)
    {
        text = contentBlock.getText();
    }

    if(text.length === 0)
    {
        return [];
    }

    let mergedEntities: MergedEntity[] = [];
    for(let currentEntityIdx = 0; currentEntityIdx < entities.length; currentEntityIdx++) 
    {
        const currentEntity =  entities[currentEntityIdx];
        const currentEntityType = contentState.getEntity(currentEntity.entityKey).getType();
        if(mergedEntities.length === 0)
        {
            mergedEntities.push(createMergedEntity(currentEntity, currentEntityType, text));
            continue;
        }

        const currentEntityMetadata = currentEntity.entity.getData() as EntityMetadata;
        const currentEntityElement = currentEntityMetadata?.element as SegmentElementViewModel;
        const currentMergedEntity = mergedEntities[mergedEntities.length - 1];
        const currentMergedEntityElement = currentMergedEntity.metadata?.element as SegmentElementViewModel;

        const doesMergedEntityTypeMatch = currentEntityType === decoratorsId.TAG;
        let isTheSameAsPrevious = currentMergedEntity.entityType === currentEntityType;
        if(currentEntityType === decoratorsId.TAG && currentEntityElement && currentMergedEntityElement)
        {
            isTheSameAsPrevious = currentMergedEntityElement.crossFormatId === currentEntityElement.crossFormatId &&
                currentMergedEntityElement.anchor === currentEntityElement.anchor;
        }

        if(isTheSameAsPrevious)
        {
            currentMergedEntity.location.endIdx = currentEntity.location.endIdx;
            currentMergedEntity.text = text.slice(currentMergedEntity.location.startIdx, currentEntity.location.endIdx)
        }
        else
        {
            mergedEntities.push(createMergedEntity(currentEntity, currentEntityType, text));
        }
    }

    return mergedEntities;
}

export const createMergedEntity = (entity: EntityFromState, entityType: string, segmentText: string): MergedEntity =>
{
    return {
        location:{
            startIdx: entity.location.startIdx,
            endIdx: entity.location.endIdx
        }, 
        entityType: entityType, 
        metadata: entity.entity.getData(),
        text: segmentText.slice(entity.location.startIdx, entity.location.endIdx)
    };
}

/**
     * Spróbuj dodać element będący elementem dodanym w ramach śledzenia zmian do kolekcji elementów segmentu. Metoda
     * próbuje dodać element pomiędzy tagi StartInsert i EndInsert jeśli takie znajdują się na samym końcu tablicy elementów.
     * @param elementToInsert Element segmentu, który chcemy dodać.
     * @param segmentElements Elementy wchodzące w skład segmentu
     * @returns true jeśli element został dodany pomiędzy tagi StartInsert i EndInsert
     */
 const tryInsertElementBetweenLastInsertTrackingTags = (elementToInsert: SegmentElementViewModel, segmentElements: SegmentElementViewModel[]) : boolean =>
 {
     return tryInsertElementBetweenLastTrackingTags(SegmentElementType.EndIns, elementToInsert, segmentElements);
 }

 /**
  * Spróbuj dodać element będący elementem usuniętym w ramach śledzenia zmian do kolekcji elementów segmentu. Metoda
  * próbuje dodać element pomiędzy tagi StartDelete i EndDelete jeśli takie znajdują się na samym końcu tablicy elementów.
  * @param elementToInsert Element segmentu, który chcemy dodać.
  * @param segmentElements Elementy wchodzące w skład segmentu
  * @returns true jeśli element został dodany pomiędzy tagi StartDelete i EndDelete
  */
 const tryInsertElementBetweenLastDeleteTrackingTags = (elementToInsert: SegmentElementViewModel, segmentElements: SegmentElementViewModel[]) : boolean =>
 {
     return tryInsertElementBetweenLastTrackingTags(SegmentElementType.EndDel, elementToInsert, segmentElements);
 }

 const tryInsertElementBetweenLastTrackingTags = (endTrackingType: string, elementToInsert: SegmentElementViewModel, segmentElements: SegmentElementViewModel[]) : boolean =>
 {
     if(segmentElements.length > 0)
     {
         const lastElement = segmentElements[segmentElements.length - 1];
         if(lastElement.typeName === endTrackingType)
         {
             segmentElements.splice(segmentElements.length - 1, 0, elementToInsert);
             return true;
         }
     }

     return false;
 }

 const insertElementBetweenNewlyCreatedInsertTrackingTags = (elementToInsert: SegmentElementViewModel, elementEntity: MergedEntity, segmentElements: SegmentElementViewModel[]) =>
 {
     segmentElements.push(
         new SegmentElementViewModel({
             typeName: SegmentElementType.StartIns,
             crossFormatId: elementEntity.metadata.changeTracking?.crossFormatId, 
             anchor: undefined, 
             status: undefined, 
             value: undefined,
             role: undefined}));
     segmentElements.push(elementToInsert);
     segmentElements.push(
         new SegmentElementViewModel({
             typeName: SegmentElementType.EndIns,
             crossFormatId: elementEntity.metadata.changeTracking?.crossFormatId, 
             anchor: undefined, 
             status: undefined, 
             value: undefined,
             role: undefined}));
 }

 const insertElementBetweenNewlyCreatedDeleteTrackingTags = (elementToInsert: SegmentElementViewModel, elementEntity: MergedEntity, segmentElements: SegmentElementViewModel[]) =>
 {
     segmentElements.push(
         new SegmentElementViewModel({
             typeName: SegmentElementType.StartDel,
             crossFormatId: elementEntity.metadata.changeTracking?.crossFormatId, 
             anchor: undefined, 
             status: undefined, 
             value: undefined,
             role: undefined}));
     segmentElements.push(elementToInsert);
     segmentElements.push(
         new SegmentElementViewModel({
             typeName: SegmentElementType.EndDel,
             crossFormatId: elementEntity.metadata.changeTracking?.crossFormatId, 
             anchor: undefined, 
             status: undefined, 
             value: undefined,
             role: undefined}));
 }

 const convertTextToBackendForm = (textEntity: MergedEntity): SegmentElementViewModel =>
 {
     return new SegmentElementViewModel({
         typeName: SegmentElementType.Text,
         crossFormatId: undefined, 
         anchor: undefined, 
         status: undefined, 
         value: textEntity.text,
         role: undefined});
 }

 const convertTagToBackendForm = (tagEntity: MergedEntity): SegmentElementViewModel =>
 {
     const isStandaloneTag = tagEntity.metadata.element.isStandaloneTag;
     if(isStandaloneTag)
     {
         return new SegmentElementViewModel({
             typeName: SegmentElementType.StandaloneTag,
             crossFormatId: tagEntity.metadata.element.crossFormatId, 
             anchor: undefined, 
             status: undefined, 
             value: undefined,
             role: undefined});
     }

     const isStartTag = tagEntity.metadata.element.isStartTag;
     if(isStartTag)
     {
         return new SegmentElementViewModel({
             typeName: SegmentElementType.StartTag,
             crossFormatId: tagEntity.metadata.element.crossFormatId, 
             anchor: tagEntity.metadata.element.anchor, 
             status: undefined, 
             value: undefined,
             role: undefined});
     }
     else
     {
         return new SegmentElementViewModel({
             typeName: SegmentElementType.EndTag,
             crossFormatId: undefined, 
             anchor: tagEntity.metadata.element.anchor, 
             status: undefined, 
             value: undefined,
             role: undefined});
     }
 }

export const forceSelection = (editorState: EditorState, range: Range): EditorState => {
    const currentSelection = editorState.getSelection();
    let anchorKey = currentSelection.getAnchorKey();
    const targetRange = new SelectionState({
        anchorKey: anchorKey,
        anchorOffset: range.startIdx,
        focusKey: anchorKey,
        focusOffset: range.endIdx,
    });

    return EditorState.forceSelection(editorState, targetRange);
}

export const checkIfStartCaretIsOnTag = (editorState: EditorState): boolean => {
    const selection = editorState.getSelection()
    let startCaret = selection.getStartOffset()
    let entities = getEntitiesFromEditorState(editorState)
    let result = false
    entities.forEach((entity, index) => {
        if (entity.entity.getType() === decoratorsId.TAG ||
            entity.entity.getType() === decoratorsId.INS_TAG ||
            entity.entity.getType() === decoratorsId.DEL_TAG
        ) {
            if (entity.location.startIdx < startCaret && startCaret < entity.location.endIdx){
                result = true
            }
        }
    })
    return result
}


export const getCurrentAllowedTagsAndMetaDataForEditorState = (editorState: EditorState, allowedTagsProps: SegmentElementViewModel[]) => {
    console.log("Allowed tags props", allowedTagsProps);
    let allowedTags = [...allowedTagsProps]
    let result = [...allowedTagsProps]
    let tags = getEntitiesFromEditorState(editorState, decoratorsId.TAG)


    let tagsObjects = tags.map((item) => {
        return item.entity.getData().element;
    })
    let insTags = getEntitiesFromEditorState(editorState, decoratorsId.INS_TAG)
    let insTagsObjects = insTags.map((item) => {
        return item.entity.getData().element;
    })
    result = filteroutDuplicatedTags(allowedTags, tagsObjects)
    result = filteroutDuplicatedTags(result, insTagsObjects)
    const newTagsObject = {
        allowedTags: result,
        sourceTags: allowedTagsProps
    }
    return newTagsObject
}

const filteroutDuplicatedTags = (allowedTags: SegmentElementViewModel[], tagsObjects: SegmentElementViewModel[]) => {

    const result = [];
    for (let index = 0; index < allowedTags.length; index++) {
        const allowedTag = allowedTags[index];
        const foundTag = tagsObjects.find(targetTag => {
            if(allowedTag.typeName === targetTag.typeName)
            {
                if(allowedTag.isEndTag && allowedTag.anchor === targetTag.anchor)
                    return true;
                
                return (allowedTag.isStartTag || allowedTag.isStandaloneTag) && allowedTag.crossFormatId === targetTag.crossFormatId;
            }
            return false;
        });

        if(!foundTag)
            result.push(allowedTag);
    }

    return result
}