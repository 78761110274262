import React from 'react'
import * as PositionObserver from 'components/positionObserver/PositionObserver'
import { GanttElements } from './GanttElements'

export function GanttGroupElements({ children }) {
	return (
		<PositionObserver.Group>
			<GanttElements>
				{children}
			</GanttElements>
		</PositionObserver.Group>
	)
}