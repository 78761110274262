import React, { useEffect } from 'react'
import { useFetchLanguageDocumentsSwr } from 'contents/cat/hooks/useApi';
import useSessionTask from 'contents/cat/hooks/useSessionTask';
import MaterialTableMetaDriven from 'contents/cat/MaterialTableMetaDriven';
import { useEditor } from '../hooks/useEditor';

// Komponent prezentuje liste dokumentów przynależących do zadania
export default function ProjectDocuments({ match, history }) {

	const { data, isLoading } = useFetchLanguageDocumentsSwr({});
	const currentTask = useSessionTask();

	useEffect(() => {
		if (data?.resourceDocument) {
			// Konwertujemy resource object do obiektu POCO
			let languageDocuments = [];
			for (const resourceObject of data.resourceDocument.data) {
				let languageDocument = { ...resourceObject.attributes, id: resourceObject.id };
				languageDocuments.push(languageDocument);
			}
			// I ustawiamy w sesji, tak na wszelki wypadek (być może na wyrost) 
			// gdyby zadanie zostało zaktualizowane
			currentTask.setTaskLanguageDocuments(languageDocuments);
        }
    }, [data?.resourceDocument])

	const resourceDocument = data?.resourceDocument;

	let resourceMeta = null
	let resourceData = null
	if (resourceDocument) {
		resourceMeta = resourceDocument.meta
		resourceData = resourceDocument.data
    }

    return (
		<MaterialTableMetaDriven
			style={{marginBottom: '5px'}}
			meta={resourceMeta}
			data={resourceData}
			title="Documents"
			isLoading={isLoading}
			options={{
				pageSize: 15,
				pageSizeOptions: [15, 20],
				emptyRowsWhenPaging: false,
				padding: 'dense',
				filtering: false,
				selection: false,
				search: false,
				showTitle: true,
				maxBodyHeight: null
			}}
		/>
    )
}