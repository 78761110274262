/* 
Wysyła logi do session storage.
*/
export default class InMemoryStorageSinkSerializer{
    constructor(sink) {
        this._sink = sink;
    }

    toStrings(){
        let results = [];
        for (let index = 0; index < this._sink.length; index++) {
            const serializedElement = this.toString(index);
            results.push(serializedElement)
        }

        return results;
    }

    toString(elementAtIndex) {
        const logEntry = this._sink.elementAt(elementAtIndex);
        if(logEntry === null)
            return '';
        
        let messageString = "";
        let messageObjects = [];
        if(logEntry.args)
        {
            for (let index = 0; index < logEntry.args.length; index++) {
                const arg = logEntry.args[index];
                if (typeof arg === 'string' || arg instanceof String)
                    messageString += arg + '; ';
                else
                    messageObjects.push(arg);
            }

            for (let index = 0; index < messageObjects.length; index++) {
                const obj = messageObjects[index];
                if(obj){
                    try {
                        messageString += JSON.stringify(obj) + '; ';
                    } catch (error) {
                        console.error(error, "Could not serialize object");
                    }
                }
                else
                    messageString += '(null); ';
            }
        }

        const timestamp = logEntry.timestamp.toISOString();
        const level = logEntry.level;
        const module = logEntry.module;

        return `[${timestamp}][${level}][${module}] ${messageString}`;
    }
}