import IRefEquatableValue from "../../model/IRefEquatableValue"
import { IEntity, IEntityContextStateController } from "../../contexts/data/DataContextModel";
import SegmentViewModel from "./SegmentViewModel";
import SegmentElementViewModel from "./SegmentElementViewModel";
import ConfirmationLevelType from "./ConfirmationLevelType";
import { DataContext } from "../../hooks/useDataContext";
import DocumentMessage, {DocumentMessageSeverity} from './DocumentMessage'
import SegmentElementType from "./SegmentElementType";
import { IDocumentCommentDto } from "contents/cat/model/DocumentComment";


/**
 * @param {ConfirmationLevelType} segment 
 */
const segmentViewModelToAttributeObject = (segment) => {
    let segmentElements = []

    segment.elements.forEach(element => {
        segmentElements.push({
            typeName: element.typeName,
            value: element.value,
            crossFormatId: element.crossFormatId,
            anchor: element.anchor,
            status: element.status
        })
    })

    return {
        elements: segmentElements
    }
}

/** Definiuje atrybuty encji TranslationUnit */
export const TranslationUnitAttributes = {
    SeqenceNumber: 'seqNum',
    Source: 'source',
    Target: 'target',
    ConfirmationLevel: 'confirmationLevel',
    IsLocked: 'isLocked',
    IsEnabled: 'isEnabled',
    Score: 'score',
    QEScore: 'qeScore',
    Origin: 'origin',
    IsContextMatch: 'isContextMatch',
    ProviderName: 'providerName',
    Messages: 'messages',
    Comments: 'comments'
}

/** Definiuje atrybuty segmentu, tj. atrybuty typu obiektu którym jest "Source" i "Target" w TranslationUnit */
export const SegmentAttributes = {
    Elements: 'elements'
}

export const SegmentElementStatus = {
    Selected: 'selected'
}

export const SegmentElementRole = {
    HighglightTerminology: 10,
    HighglightFindResult: 11

}

/** Sprawdza czy segment ma element o status 'selected' */
export function hasSegmentSelectedElement(tuEntity: IEntity, inSource: boolean): boolean
{
	const segmentAttributeName = inSource ? TranslationUnitAttributes.Source : TranslationUnitAttributes.Target;
	const segmentValue = tuEntity[segmentAttributeName] as object;
	const segmentElements = segmentValue[SegmentAttributes.Elements];
	if(segmentValue == null || !segmentElements)
		return false;

	for(let idx = 0; idx < segmentElements.length; idx++)
	{
		const element = segmentElements[idx];
		if(SegmentElementType.StartHighlight !== element.typeName && element.role !== SegmentElementRole.HighglightFindResult)
			continue;

		if(element.status != null)
		{
			return true;
		}
	}

	return false;
}

/** Zaznacz następny podświetlony element w segmencie.
 * @returns CrossFormatId wybranego elemenetu lub null jeśli nie ma elementów do wybrania
 */
export function markNextHighlightSelected(tuEntity: IEntity, stateController: IEntityContextStateController, inSource: boolean) : string | null
{
	const segmentAttributeName = inSource ? TranslationUnitAttributes.Source : TranslationUnitAttributes.Target;
	const segmentValue = tuEntity[segmentAttributeName] as object;
	const segmentElements = segmentValue[SegmentAttributes.Elements];
	if(segmentValue == null || !segmentElements)
		return null;
	
	let didChange = false;
	let selectableElement = null;
	let selectedElementCrossFormatId = null;

	for(let idx = segmentElements.length - 1; idx >= 0; idx--)
	{
		const element = segmentElements[idx];
		if(SegmentElementType.StartHighlight !== element.typeName  && element.role !== SegmentElementRole.HighglightFindResult)
			continue;

		didChange = true;
		if(element.status != null)
		{
			break;
		}
		else
		{
			selectableElement = element;
		}
	}

	if(didChange)
	{
		const updatedElements = segmentElements.map( el => Object.assign({}, el));
		for(const element of updatedElements)
		{
			if(SegmentElementType.StartHighlight !== element.typeName  && element.role !== SegmentElementRole.HighglightFindResult)
				continue;

			if(selectableElement && element.crossFormatId === selectableElement.crossFormatId)
			{
				element.status = SegmentElementStatus.Selected;
				selectedElementCrossFormatId = element.crossFormatId;
			}
			else
				element.status = undefined;
		}

		stateController.setEntityAttributesWithoutChangeTracking(tuEntity.__id, {[segmentAttributeName]: {[SegmentAttributes.Elements]: updatedElements}});
	}
	
	return selectedElementCrossFormatId;
}

/**
 * Usuwa podświetlenie elementów bazując na fakcie, że tego typu zmiany nie trafiają do indeksu śledzącego zmiany.
 * @param stateController 
 */
export function removeHighlightedEntitiesFromDraftIndex(stateController: IEntityContextStateController)
{
    stateController.rebuildDraftIndex();
}


export default class TranslationUnitViewModel implements IRefEquatableValue {
    readonly _refEquatableValue: any;
    readonly _dataContext: DataContext;
    _sourceSegment: SegmentViewModel;
    _targetSegment: SegmentViewModel;
    
    constructor(entity: IEntity, dataContext: DataContext) {
        this._refEquatableValue = entity;
        this._dataContext = dataContext;

        // Tworzymy model widoku segmentu zrodlowego
        this._sourceSegment = new SegmentViewModel()
        const sourceValue = entity[TranslationUnitAttributes.Source];
        if(sourceValue){
            sourceValue[SegmentAttributes.Elements].forEach(element => {
                this._sourceSegment.addElement(new SegmentElementViewModel({
                    typeName: element.typeName,
                    value: element.value,
                    crossFormatId: element.crossFormatId,
                    anchor: element.anchor,
                    status: element.status,
                    role: element.role
                }))
            });
        }

        // Tworzymy model widoku segmentu docelowego
        this._targetSegment = null;
        let targetValue = entity[TranslationUnitAttributes.Target];
        // Jeśli segment docelowy jest pusty, to przepisujemy wartość segmentu źródłowego
        if (!targetValue) {
            this._targetSegment = Object.assign({}, this._sourceSegment); // klon
        }
        else {
            this._targetSegment = new SegmentViewModel()
            targetValue[SegmentAttributes.Elements].forEach(element => {
                this._targetSegment.addElement(new SegmentElementViewModel({ 
                    typeName: element.typeName, 
                    value: element.value, 
                    crossFormatId: element.crossFormatId, 
                    anchor: element.anchor, 
                    status: element.status,
                    role: element.role }))
            });
        }
    }

    get _entity(): IEntity {
        return this._refEquatableValue;
    }

    get id() {
        return this._entity.__id;
    }

    get sequenceNumber() {
        return this._entity[TranslationUnitAttributes.SeqenceNumber];
    }

    get sourceSegment() {
        return this._sourceSegment;
    }

    get targetSegment() {
        return this._targetSegment;
    }

    set targetSegment(value: SegmentViewModel) {
        this._targetSegment = value

        const attributeValue = segmentViewModelToAttributeObject(value)
        if (this.confirmationLevel == ConfirmationLevelType.NotSpecified) {
            this._dataContext.setAttributes(this.id, {
                [TranslationUnitAttributes.Target]: attributeValue,
                [TranslationUnitAttributes.ConfirmationLevel]: ConfirmationLevelType.Draft
            });
        }
        else {
            this._dataContext.setAttribute(this.id, TranslationUnitAttributes.Target, attributeValue);
        }
    }

    get confirmationLevel() {
        return this._entity[TranslationUnitAttributes.ConfirmationLevel];
    }

    set confirmationLevel(value) {
        this._dataContext.setAttribute(this.id, TranslationUnitAttributes.ConfirmationLevel, value);
    }

    get isLocked() {
        return this._entity[TranslationUnitAttributes.IsLocked];
    }

    set isLocked(value) {
        this._dataContext.setAttribute(this.id, TranslationUnitAttributes.IsLocked, value);
    }

    get isEnabled() {
        return this._entity[TranslationUnitAttributes.IsEnabled];
    }

    set isEnabled(value) {
        this._dataContext.setAttribute(this.id, TranslationUnitAttributes.IsEnabled, value);
    }

    get score() {
        return this._entity[TranslationUnitAttributes.Score];
    }

    get qeScore() {
        return this._entity[TranslationUnitAttributes.QEScore];
    }

    get origin() {
        return this._entity[TranslationUnitAttributes.Origin];
    }

    get isContextMatch() {
        return this._entity[TranslationUnitAttributes.IsContextMatch];
    }

    get providerName() {
        return this._entity[TranslationUnitAttributes.ProviderName];
    }

    /** Zaakceptuj tłumaczenie. Zwraca True jeśli zmieniono poziom zatwierdzenia lub wartość segmentu się zmieniła, wpp False. */
    confirmTranslation(segmentValueChanged) {
        if (this.confirmationLevel != ConfirmationLevelType.Translated) {
            this.confirmationLevel = ConfirmationLevelType.Translated
            return true;
        }
        return segmentValueChanged;
    }

    copySourceToTarget() {
        const sourceSegment = this.sourceSegment;
        this.targetSegment = sourceSegment;
    }

    // Dozwolone tagi, które mogą wystąpić w segmencie docelowym
    // TODO: byc moze warto przeniesc do backendu?
    allowedTags() {
        if (!this._sourceSegment || !this._sourceSegment.elements) {
            return []
        }

        let tags = this._sourceSegment.elements.filter(element => {
            return element.isTag
        });

        if (this._targetSegment && this._targetSegment.elements) {
            let targetSegmentTags = this._targetSegment.elements.filter(element => {
                // wybieramy tylko te tagi, ktorych nie bylo w zrodlowym segmencie
                return element.isTag && tags.findIndex(tag => tag.crossFormatId === element.crossFormatId && tag.anchor === element.anchor ) < 0
            });

            tags.push(...targetSegmentTags)
        }

        return tags
    }

    get messages(): Array<DocumentMessage> {
        if(!this._entity[TranslationUnitAttributes.Messages])
            return [];

        return this._entity[TranslationUnitAttributes.Messages] as Array<DocumentMessage>;
    }

    get warningMessages() : Array<DocumentMessage> {
        return this.messages.filter(msg => msg.severity.id === DocumentMessageSeverity.Warning);
    }

    get errorMessages() : Array<DocumentMessage> {
        return this.messages.filter(msg => msg.severity.id === DocumentMessageSeverity.Error);
    }

    get comments() : Array<IDocumentCommentDto> {
        if(!this._entity[TranslationUnitAttributes.Comments])
            return [];

        return this._entity[TranslationUnitAttributes.Comments] as Array<IDocumentCommentDto>;
    }

    static _highlightElementTypes: string[] = SegmentElementType.highlightValues();
    static hasFindResult(entity: IEntity, inSource: boolean)
    {
        const segmentValue = entity[inSource ? TranslationUnitAttributes.Source : TranslationUnitAttributes.Target];
        if(segmentValue == null)
            return false;

        for (const element of segmentValue[SegmentAttributes.Elements]) 
        {
            if(TranslationUnitViewModel._highlightElementTypes.includes(element.typeName) && element.role === SegmentElementRole.HighglightFindResult)
                return true;
        }

        return false;
    }
}
