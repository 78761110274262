import React from 'react';
import PropTypes from 'prop-types'
import useField from '../../hooks/useField'
import { FormGroup, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import { CheckBox as CheckBoxIcon } from '@material-ui/icons'
import toString from 'lodash/toString'
import './Checkbox.css'
import { GlobalStrings } from 'GlobalStrings';
import { Tooltip } from 'components/Tooltip';
import { appCtrl } from 'AppCtrl';


export const checkboxOptionsNonindeterminate = {
	indeterminate: false
};
export const checkboxOptionsindeterminate = {
	indeterminate: true
};

/**
* Komponent kontrolki opleciony w funkcję memo() która pozwala na ogarniczenie ponownego renderowania kontrolki bez zmiany propsów.
* 
* @param {String} name Nazwa pola
* @param {String} dataType dataType danej kontrolki
* @param {String} value - aktualna wartość kontrolki
* @param {Boolean} required Informacja czy kontrolka jest wymagana
* @param {String} error Komunikat z błędem lub null/pust string
* @param {Boolean} disabled Informacja czy kontrolka jest aktywna
* @param {Function} onChange - Callback uruchamiany podczas zmiany wartości kontrolki
*/
const CheckboxInput = React.memo(({ name, dataType, value, required, error, disabled, onChange, options }) => {
	const CHECKBOX_INPUT_NAME = 'checkboxInput'
	const label = value == null ?
							'indeterminate'
								: Array.isArray(value) ? !value : !!value

	// Ustawiamy domyślne wartości dla trybu checkboxa
	options = {...checkboxOptionsNonindeterminate, ...options }
	const checkboxClasses = 'checkbox-input' + (error ? '-error' : '');

	return (
		<Tooltip title={options.indeterminate ? toString(appCtrl?.locale?.translate(`WebSpa/Checkbox/label/${label}`)) : ''}>
			<MuiCheckbox
				id={`${toString(dataType)}-${name}-${CHECKBOX_INPUT_NAME}`}
				className={checkboxClasses}
				name={name}
				checked={Array.isArray(value) ? !value : !!value}
				indeterminate={options.indeterminate && value == null}
				required={required}
				disabled={disabled}
				error={error}
				checkedIcon={<CheckBoxIcon color={disabled ? "disabled" : "primary"} />}
				onChange={() => onChange && onChange()}
			/>
		</Tooltip>
	)
})

CheckboxInput.displayName = "CheckboxInput";
/**
* Komponent kontrolki opleciony w funkcję memo() która pozwala na ogarniczenie ponownego renderowania kontrolki bez zmiany propsów.
* 
* @param {String} name Nazwa pola
* @param {String} value - aktualna wartość kontrolki
* @param {Boolean} required Czy jest wymagany
* @param {String} error Komunikat z błędem lub null/pust string
* @param {Boolean} disabled Czy jest aktywny
* @param {String} label Labelka dla komponentu
* @param {String} hint Hint dla komponentu
* @param {String} dataType Typ danych dla kontrolki
* @param {Boolean} hasLabel Czy ma labelkę
* @param {Function} onChange - Callback uruchamiany podczas zmiany wartości kontrolki
* @param {Object} rootClassName - Style z biblioteki material-ui dla kontrolki
*/
export const Checkbox = React.memo(({ name, value, options, required, error, disabled, label, hint, dataType, hasLabel, onChange, rootClassName }) => {
	const LABEL_NAME = 'checkboxLabel'
	// Jeśłi pole wymagane, to dodajemy '*'
	const formattedLabel = hasLabel ? 
								required ? label + ' *' : label
									: ''
	const labelClassName = Boolean(error) ? 'checkbox-error ' + String(rootClassName) : rootClassName
	const wrapperClassName = hasLabel ? 'checkbox-label-on' : 'checkbox-label-off';

	if(hasLabel)
	{
		return (
			<div className={wrapperClassName}>
				<FormGroup>
					<Tooltip title={label + (hint ? '- ' + hint : '')}>
						<FormControlLabel
							className={labelClassName}
							id={`${dataType}-${name}-${LABEL_NAME}`}
							disabled={disabled}
							control={ <CheckboxInput name={name} dataType={dataType} value={value} options={options} required={required} error={error} disabled={disabled} onChange={onChange} /> }
							label={formattedLabel}
							/>
					</Tooltip>
				</FormGroup>
			</div>
		)
	}
	else
	{
		return (
			<div className={wrapperClassName}>
				<CheckboxInput name={name} dataType={dataType} value={value} options={options} required={required} error={error} disabled={disabled} onChange={onChange} />
			</div>
		)
	}
})

/**
 * Komponent powzalający izolować komponent <Checkbox /> w celu ograniczenia ponownego renderowania komponentu, podczas gdy jego propsy nie uległy zmianie.
 * Komponent <Checkbox /> zostanie rerenderowany jedynie w momencie gdy zmienią się któreś z propsów do niego przekazywanych
 * 
 * @param {String} name - nazwa kontrolki
 * @param {Boolean} hasLabel - Informacja czy komponent jest wyświetlany normalnie czy w widoku Edycji wiersza
 * @returns {Node}
 */
function CheckboxRenderProvider({ name, hasLabel, options }){
	const field = useField(name);
	const onChange = () => {

		if (field?.form?.data?.meta?.reloadOnChangeAttributes?.includes(field.name)) {
			field.form.asyncSubmit( GlobalStrings.reloadOnChangeSubmitType, { triggeredFieldName: name });
		}

		if (options.indeterminate) {
			field.handleChange(field.value === false || Array.isArray(field.value) ? null : !field.value);
		} else {
			field.handleChange(!field.value);
		}
	};
	

	return (
		<Checkbox 
			name={name}
			value={field.value}
			required={field.required}
			disabled={field.disabled}
			label={field.label}
			hint={field.hint}
			dataType={field.dataType}
			hasLabel={hasLabel}
			onChange={onChange}
			options={options}
			rootClassName={hasLabel !== false ? "checkbox-label" : null}
		/>
	);
}


export default CheckboxRenderProvider


CheckboxRenderProvider.defaultProps = {
	options: {
		indeterminate: false
	},
}

CheckboxRenderProvider.propTypes = {
	options: PropTypes.object,
}

Checkbox.propTypes = {
	name: PropTypes.string.isRequired,
	hasLabel: PropTypes.bool,
}

Checkbox.defaultProps = {
	hasLabel: true,
}

CheckboxInput.propTypes = {
	name: PropTypes.string.isRequired,
}

CheckboxInput.defaultProps = {
	name: '',
	hasLabel: true,
}
