import React from 'react'
import PropTypes from 'prop-types'
import { Card, makeStyles } from '@material-ui/core'
import './Sections.scss'
import { SectionContentWrapper } from './SectionContentWrapper'

//#region  Style
const useDefaultSectionStyles = makeStyles(({ palette }) => ({
	cardRoot: {
		padding: '30px 15px 30px 15px',
		boxShadow: 'none'
	}
}))
//#endregion


/**
 * Komponent wyświetlający sekcję domyślną - Default
 * 
 * @param {Object} section Obiekt sekcji nadrzędnej - rodzic grupujący inne sekcje z tabami
 * @returns {Node}
 */
export function SectionDefault({ section, children }) {
	const { cardRoot } = useDefaultSectionStyles()
	return (
		<Card className={cardRoot}>
			<SectionContentWrapper>
				{children}
			</SectionContentWrapper>
		</Card>
	)
}

//#region PropTypes & DefaultProps
SectionDefault.propTypes = {
	section: PropTypes.object,
	children: PropTypes.node.isRequired,
}
//#endregion