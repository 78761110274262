import React from 'react'
import { GradientHidder } from 'components/topology/Topology'
import { useForm, Field } from 'components/form/Form'
import { useTranslate } from 'locale/Locale'
import { cellFactory } from './cellFactory'
import { IconMessages } from 'components/iconMessages/IconMessages'
import { Box } from '@material-ui/core'
import { messageColumnName } from 'components/form/classes/ColumnManager'
import './CellSwitch.css';

const BUTTON_NAME = "ListAddButton"

export function CellSwitch({ column, backgroundColor, columnIndex, rowHeight  }) {
	const form = useForm('data')
	const translate = useTranslate('WebSpa/Buttons')

	if (column.name === messageColumnName) {
		return (
			<GradientHidder
				paddingY={'5px'}
				backgroundColor={backgroundColor}
				backgroundColorHover={backgroundColor}
			>
				<IconMessages isList/>
			</GradientHidder>
		)
	}

	if (!form.hasMeta(column.name))
		return null

	const field = form.getField(column.name)
	const meta = field.meta
	const dataType = form.dataType ? form.dataType : ""
	if (!(meta.type in cellFactory))
		return column.name + ' type: ' + meta.type

	if (form.parentForm.rowManager.inlineEdition)
		return <Field name={field.name} hasLabel={false} hasIcon={false} />

	if (form.id === undefined) {
		if (columnIndex === 0 || form?.parentForm?.columnManager.hasMessageColumn && columnIndex === 1) {
			return (
				<div id={`${dataType}-${BUTTON_NAME}`}>
					<GradientHidder
						backgroundColor={backgroundColor}
						backgroundColorHover={backgroundColor}
					>
						{translate('addButton')}
					</GradientHidder>
				</div>
			)
		} else {
			return (
				<GradientHidder
					backgroundColor={backgroundColor}
					backgroundColorHover={backgroundColor}
				>
				</GradientHidder>
			)
		}
	}

	if(rowHeight)
	{
		return (
			<div className='cell-inner-rh'>
				{cellFactory[meta.type](field)}
			</div>
		);
	}

	return (
		<div className='cell-inner'> 
			{cellFactory[meta.type](field)}
		</div>
	);
}