import React from 'react'
import SideBar from 'components/sidebar/SideBar'
import './Layout.scss'

/**
 * Komponent wyglądu całej aplikacji
 *
 * @param {Node} children Zawartość prawej strony
 * @returns {Node}
 */
export default function Layout(props) {
	return (
		<div className="layout">
			<SideBar />
			<div className='layout-right'>
				{props.children}
			</div>
		</div>
	)
}
