import React from 'react'
import { MixinGridItem, MixinFont, Horizontal } from 'components/topology/Topology'
import { useForm } from 'components/form/Form'
import styled from 'styled-components'
import { rowColor } from '../row/rowColor'
import { GroupButtonAdd } from './GroupButtonAdd'

///TODO style
export const CellGroupBorderStyled = styled.div`
	color: #4d4d4d;
	border-bottom: 1px solid #dcdcdc;
	border-right: 1px solid #dcdcdc;
	${MixinFont('p-sm')};
	min-height: ${({theme}) => `${theme.rowSize}px`};
	${({ rowSpan }) => MixinGridItem({ rowSpan })}
	background-color: ${props => props.backgroundColor};
`

export function CellGroupBorder({ row, rowIndex, rowSpan, children, onClick, onDoubleClick, onClickGroupBreakLine, ...other }) {
	const form = useForm('data')
	const backgroundColor = rowColor({ selected: false, marked: false })
	const child = React.Children.only(children)

	return (
		<CellGroupBorderStyled
			rowSpan={rowSpan}
			onClick={e => onClick && onClick({ form, rowIndex, e })}
			onDoubleClick={() => onDoubleClick && onDoubleClick({ form, rowIndex })}
			backgroundColor={backgroundColor}
		>
			<Horizontal className='grid-acx-stretch'>
				{React.cloneElement(child, { row, rowIndex, backgroundColor, ...other })}
				<GroupButtonAdd rowIndex={rowIndex} onClickGroupBreakLine={onClickGroupBreakLine} />
			</Horizontal>
		</CellGroupBorderStyled>
	)
}


