import { includes, isEmpty } from 'lodash'

const widthUnitInPixel = 41

function getDefaultWidth(width) {
	if (!width || width === '100%')
		return '100%'

	if (width.endsWith('u'))
		return `${Number.parseInt(width.slice(0, -1)) * widthUnitInPixel}px`

	return width
}

/**
 * Zwraca uporządkowane sekcje które są w sekcji parentSectionName.
 * @param {FormMetaObjest} form Forma w której są sekcje 
 * @param {string | undefined} parentSectionName Nazwa sekcji parenta
 * @param {boolean | undefined} polymorphic czy jest polimorficzny
 * @returns {object[]} Array opisów sekcji
 */
export function getSectionsDescriptions(form, parentSectionName, polymorphic) {
	// //Jeśli brak sekcji polymorphic to ją dodajemy
	// if (!('polymorphic' in form.data.meta.sections) && polymorphic)
	// 	form.data.meta.sections.polymorphic = {
	// 		name: 'polymorphic',
	// 		label: 'polymorphic',
	// 		sequenceIndex: 1000
	// 	}
	const meargedSections = {...form.data.meta.sections, ...form._data?.data?.[0].meta?.sections}

	let sections = Object.values(meargedSections)
		.map(section => { //Pętla pozwalająca na robienie operacji na wszystkich sekcjach (przed filtrowaniem i sortowaniem)
			if(!isEmpty(meargedSections)){
				const sectionWithChilds = meargedSections[section.parentSectionName] //Sekcja na które wskazuje "parentSectionName" są sekcjami które mają dzieci, wybieramy ją
				if(!isEmpty(sectionWithChilds)){
					//Jeśli znaleźliśmy taką sekcję, to ustawiamy właściwość "hasChildren" na true. Wykorzystujemy to aby ustalić które sekcje mają dzieci
					meargedSections[sectionWithChilds.name].hasChildren = true
				} 

				return section
			}
		})
		.filter(section => section.name !== 'list' && (!parentSectionName && !section.parentSectionName || section.parentSectionName === parentSectionName))
		.sort((a, b) => (a.sequenceIndex ? a.sequenceIndex : 0) - (b.sequenceIndex ? b.sequenceIndex : 0))
		.map(currentSection => {
			currentSection.width = getDefaultWidth(currentSection.width)
			return currentSection
		})

	return sections
}