import React from 'react'

/* #region  Ikony logo - path: src/icons/logo*/
import { ReactComponent as LogoDark } from 'icons/logo/logo_ciemne.svg'
import { ReactComponent as LogoLight } from 'icons/logo/logo_jasne.svg'
import { ReactComponent as LogotypeDark } from 'icons/logo/logotyp_ciemne.svg'
import { ReactComponent as LogotypeLight } from 'icons/logo/logotyp_jasne.svg'

import { ReactComponent as Logo } from 'icons/logo/logo.svg';
import { ReactComponent as Logotype } from 'icons/logo/logotyp.svg';
import { ReactComponent as LinguaLabLogo } from 'icons/logo/LinguaLab_2023_logo_200px.svg';
import { ReactComponent as LinguaLabLogoType } from 'icons/logo/LinguaLab_2023_logo_38px_białe.svg';
import { ReactComponent as PunktLogo } from 'icons/logo/logo_punkt.svg';
import { ReactComponent as PunktLogoType } from 'icons/logo/logo_punkt_38.svg';
import { ReactComponent as IuridicoLogo } from 'icons/logo/logo_iuridico.svg';
import { ReactComponent as IuridicoLogoType } from 'icons/logo/logo_iuridico_38.svg';

/* #endregion */

/* #region  Ikony kontrolek - path: src/icons/inputs */
import { ReactComponent as HelpDark } from 'icons/inputs/help_dark.svg'
import { ReactComponent as HelpWhite } from 'icons/inputs/help_white.svg'
import { ReactComponent as OrganizationDark } from 'icons/inputs/organization_dark.svg'
import { ReactComponent as OrganizationWhite } from 'icons/inputs/organization_white.svg'
import { ReactComponent as PasswordDark } from 'icons/inputs/password_dark.svg'
import { ReactComponent as PasswordWhite } from 'icons/inputs/password_white.svg'
import { ReactComponent as UsersDark } from 'icons/inputs/user_dark.svg'
import { ReactComponent as UsersWhite } from 'icons/inputs/users_white.svg'
import { ReactComponent as CalendarDark } from 'icons/inputs/calendar_dark.svg'
import { ReactComponent as NotificationDark } from 'icons/inputs/notification_dark.svg'
import { ReactComponent as ConfigurationDark } from 'icons/inputs/configuration_dark.svg'
import { ReactComponent as UploadDark } from 'icons/inputs/upload_dark.svg'

import { ReactComponent as FilterColor } from 'icons/inputs/filter_color.svg'
import { ReactComponent as FirstPageColor } from 'icons/inputs/first_page_color.svg'
import { ReactComponent as GusColor } from 'icons/inputs/gus_color.svg'
import { ReactComponent as LastPageColor } from 'icons/inputs/last_page_color.svg'
import { ReactComponent as NextColor } from 'icons/inputs/next_color.svg'
import { ReactComponent as PrevColor } from 'icons/inputs/prev_color.svg'
import { ReactComponent as RefreshColor } from 'icons/inputs/refresh_color.svg'
import { ReactComponent as SearchColor } from 'icons/inputs/search_color.svg'
import { ReactComponent as TrashColor } from 'icons/inputs/trash_color.svg'
import { ReactComponent as LogoutColor } from 'icons/inputs/logout_color.svg'
import { ReactComponent as NotificationColor } from 'icons/inputs/notification_color.svg'

import { ReactComponent as Calendar } from 'icons/inputs/calendar.svg'
import { ReactComponent as Download } from 'icons/inputs/download.svg'
import { ReactComponent as Upload } from 'icons/inputs/upload.svg'
import { ReactComponent as Filter } from 'icons/inputs/filter.svg'
import { ReactComponent as FirstPage } from 'icons/inputs/first_page.svg'
import { ReactComponent as LastPage } from 'icons/inputs/last_page.svg'
import { ReactComponent as Gus } from 'icons/inputs/gus.svg'
import { ReactComponent as Next } from 'icons/inputs/next.svg'
import { ReactComponent as Prev } from 'icons/inputs/prev.svg'
import { ReactComponent as Notification } from 'icons/inputs/notification.svg'
import { ReactComponent as Refresh } from 'icons/inputs/refresh.svg'
import { ReactComponent as Search } from 'icons/inputs/search.svg'
import { ReactComponent as Trash } from 'icons/inputs/trash.svg'
/* #endregion */

/* #region  Ikony menu - path: src/icons/menu*/
import { ReactComponent as Inquiries } from 'icons/menu/inquiries.svg'
import { ReactComponent as Administration } from 'icons/menu/administration.svg'
import { ReactComponent as Company } from 'icons/menu/company.svg'
import { ReactComponent as MyProfile } from 'icons/menu/myprofile.svg'
import { ReactComponent as Complaint } from 'icons/menu/complaint.svg'
import { ReactComponent as Configuration } from 'icons/menu/configuration.svg'
import { ReactComponent as Offer } from 'icons/menu/offer.svg'
import { ReactComponent as Pricelist } from 'icons/menu/pricelist.svg'
import { ReactComponent as Project } from 'icons/menu/project.svg'
import { ReactComponent as Qualityassurance } from 'icons/menu/qualityassurance.svg'
import { ReactComponent as ProjectItems } from 'icons/menu/projectitems.svg'
import { ReactComponent as ProposedJob } from 'icons/menu/proposedjob.svg'
import { ReactComponent as Reports } from 'icons/menu/reports.svg'
/* #endregion */

/**
 * Submenu Icons - path: src/icons/menu/submenu/...
 */

/* #region  Raporty */
import { ReactComponent as ReportProfitability } from 'icons/menu/submenu/reports/reportProfitability.svg'
import { ReactComponent as ReportOfferEfficiency } from 'icons/menu/submenu/reports/reportOfferEfficiency.svg'
import { ReactComponent as ReportQaEfficiency } from 'icons/menu/submenu/reports/reportQaEfficiency.svg'
import { ReactComponent as ReportComplaint } from 'icons/menu/submenu/reports/reportComplaint.svg'
import { ReactComponent as ReportInvoice } from 'icons/menu/submenu/reports/reportInvoice.svg'
import { ReactComponent as ReportsPriceListItemCompanySupplier } from 'icons/menu/submenu/reports/reportsPriceListItemCompanySupplier.svg'
import { ReactComponent as ReportJasperSoft } from 'icons/menu/submenu/reports/reportQaEfficiency.svg'
/* #endregion */

/* #region Konfiguracja  */
import { ReactComponent as FeatureTypes } from 'icons/menu/submenu/configuration/featureTypes.svg'
import { ReactComponent as ProductTypes } from 'icons/menu/submenu/configuration/productTypes.svg'
import { ReactComponent as Templates } from 'icons/menu/submenu/configuration/templates.svg'
import { ReactComponent as Group } from 'icons/menu/submenu/configuration/group.svg'
import { ReactComponent as Currency } from 'icons/menu/submenu/configuration/currency.svg'
import { ReactComponent as TemplateDef } from 'icons/menu/submenu/configuration/templateDef.svg'
import { ReactComponent as ProjDeliveryTemplate } from 'icons/menu/submenu/configuration/projDeliveryTemplate.svg'
import { ReactComponent as CustomerSupplierTypePref } from 'icons/menu/submenu/configuration/customerSupplierTypePref.svg'
import { ReactComponent as RegistryForDocuments } from 'icons/menu/submenu/configuration/registryForDocuments.svg'
import { ReactComponent as VatTaxRate } from 'icons/menu/submenu/configuration/vatTaxRate.svg'
import { ReactComponent as OrganizationConfiguration } from 'icons/menu/submenu/configuration/organizationConfiguration.svg'
import { ReactComponent as TranslationEnginePref } from 'icons/menu/submenu/configuration/translationEnginePref.svg'
import { ReactComponent as TranslationSubengine } from 'icons/menu/submenu/configuration/translationSubengine.svg'
/* #endregion */

/* #region   Administracyjne*/
import { ReactComponent as Role } from 'icons/menu/submenu/administration/role.svg'
import { ReactComponent as Organization } from 'icons/menu/submenu/administration/organization.svg'
import { ReactComponent as Internationalization } from 'icons/menu/submenu/administration/internationalization.svg'
import { ReactComponent as SystemConfiguration } from 'icons/menu/submenu/administration/systemConfiguration.svg'
/* #endregion */

/**
 * Komponent wyświetlający po nazwie ikony SVG z zaimportowanych powyżej
 * Można dodać atrbut width, height
 * @param {String} name - Nazwa Ikony
 */
const NewIcons = ({ name, ...other }) => {
	switch (name) {
		/* #region  Ikony logo */
		case 'logo_dark':
			return <LogoDark {...other} />
		case 'logo_light':
			return <LogoLight {...other} />
		case 'logotype_dark':
			return <LogotypeDark {...other} />
		case 'logotype_light':
			return <LogotypeLight {...other} />
		case 'logo':
			return <Logo {...other} />
		case 'logotype':
			return <Logotype {...other} />
		case 'linguaLabLogo':
			return <LinguaLabLogo {...other} />
		case 'linguaLabLogoType':
			return <LinguaLabLogoType {...other} />
		case 'punktLogo':
			return <PunktLogo {...other} />
		case 'punktLogoType':
			return <PunktLogoType {...other} />
		case 'iuridicoLogo':
			return <IuridicoLogo {...other} />
		case 'iuridicoLogoType':
			return <IuridicoLogoType {...other} />
		/* #endregion */

		/* #region  Ikony kontrolek */
		case 'help_dark':
			return <HelpDark {...other} />
		case 'help_white':
			return <HelpWhite {...other} />
		case 'organization_dark':
			return <OrganizationDark {...other} />
		case 'organization_white':
			return <OrganizationWhite {...other} />
		case 'password_dark':
			return <PasswordDark {...other} />
		case 'password_white':
			return <PasswordWhite {...other} />
		case 'user_dark':
			return <UsersDark {...other} />
		case 'user_white':
			return <UsersWhite {...other} />
		case 'calendar':
			return <Calendar {...other} />
		case 'download':
			return <Download {...other} />
		case 'upload':
			return <Upload {...other} />
		case 'filter':
			return <Filter {...other} />
		case 'first_page':
			return <FirstPage {...other} />
		case 'last_page':
			return <LastPage {...other} />
		case 'gus':
			return <Gus {...other} />
		case 'next':
			return <Next {...other} />
		case 'prev':
			return <Prev {...other} />
		case 'notification':
			return <Notification {...other} />
		case 'refresh':
			return <Refresh {...other} />
		case 'search':
			return <Search {...other} />
		case 'trash':
			return <Trash {...other} />
		case 'notification_dark':
			return <NotificationDark {...other} />
		case 'configuration_dark':
			return <ConfigurationDark {...other} />
		case 'upload_dark':
			return <UploadDark {...other} />
		case 'calendar_dark':
			return <CalendarDark {...other} />
		/* #endregion */

		/* #region  Ikony wersja kolor */
		case 'filter_color':
			return <FilterColor {...other} />
		case 'first_page_color':
			return <FirstPageColor {...other} />
		case 'gus_color':
			return <GusColor {...other} />
		case 'last_page_color':
			return <LastPageColor {...other} />
		case 'next_color':
			return <NextColor {...other} />
		case 'prev_color':
			return <PrevColor {...other} />
		case 'refresh_color':
			return <RefreshColor {...other} />
		case 'search_color':
			return <SearchColor {...other} />
		case 'trash_color':
			return <TrashColor {...other} />
		case 'logout_color':
			return <LogoutColor {...other} />
		case 'notification_color':
			return <NotificationColor {...other} />
		/* #endregion */

		/* #region  Ikony Menu */
		case 'inquiries':
			return <Inquiries {...other} />
		case 'offer':
			return <Offer {...other} />
		case 'project':
			return <Project {...other} />
		case 'complaint':
			return <Complaint {...other} />
		case 'price_list':
			return <Pricelist {...other} />
		case 'company':
			return <Company {...other} />
		case 'my_profile':
			return <MyProfile {...other} />
		case 'quality_assurance':
			return <Qualityassurance {...other} />
		case 'project_items':
			return <ProjectItems {...other} />
		case 'proposed_job':
			return <ProposedJob {...other} />
		case 'invoices':
				return <ReportInvoice {...other} />
		case 'reports':
			return <Reports {...other} />
		case 'configuration':
			return <Configuration {...other} />
		case 'administration':
			return <Administration {...other} />
		/* #endregion */

		/* #region  Ikony Podmenu */
		/* #region  Raporty */
		case 'reportsPriceListItemCompanySupplier':
			return <ReportsPriceListItemCompanySupplier {...other} />
		case 'reportProfitability':
			return <ReportProfitability {...other} />
		case 'reportOfferEfficiency':
			return <ReportOfferEfficiency {...other} />
		case 'reportQaEfficiency':
			return <ReportQaEfficiency {...other} />
		case 'reportComplaint':
			return <ReportComplaint {...other} />
		case 'reportInvoice':
			return <ReportInvoice {...other} />
		case 'reportJasperSoft':
			return <ReportJasperSoft {...other} />
		/* #endregion */
		/* #region  Konfiguracja */
		case 'featureTypes':
			return <FeatureTypes {...other} />
		case 'productTypes':
			return <ProductTypes {...other} />
		case 'templates':
			return <Templates {...other} />
		case 'group':
			return <Group {...other} />
		case 'currency':
			return <Currency {...other} />
		case 'templateDef':
			return <TemplateDef {...other} />
		case 'projDeliveryTemplate':
			return <ProjDeliveryTemplate {...other} />
		case 'customerSupplierTypePref':
			return <CustomerSupplierTypePref {...other} />
		case 'registryForDocuments':
			return <RegistryForDocuments {...other} width={28} />
		case 'vatTaxRate':
			return <VatTaxRate {...other} width={25} />
		case 'organizationConfiguration':
			return <OrganizationConfiguration {...other} />
		case 'translationEnginePref':
			return <TranslationEnginePref {...other} style={{ marginLeft: 3 }} />
		case 'translationSubengine':
			return <TranslationSubengine {...other} />
		/* #endregion */
		/* #region  Administracyjne */
		case 'role':
			return <Role {...other} />
		case 'organization':
			return <Organization {...other} />
		case 'internationalization':
			return <Internationalization {...other} />
		case 'systemConfiguration':
			return <SystemConfiguration {...other} width={28} />
		/* #endregion */
		/* #endregion */

		default:
			return <HelpWhite {...other} />
	}
}

/* #region  Eksport */
export {
	Logo,
	Logotype,
	LogoDark,
	LogoLight,
	LogotypeDark,
	LogotypeLight,
	HelpDark,
	HelpWhite,
	OrganizationDark,
	OrganizationWhite,
	PasswordDark,
	PasswordWhite,
	UsersDark,
	UsersWhite,
	CalendarDark,
	NotificationDark,
	ConfigurationDark,
	UploadDark,
	FilterColor,
	FirstPageColor,
	GusColor,
	LastPageColor,
	NextColor,
	PrevColor,
	RefreshColor,
	SearchColor,
	TrashColor,
	LogoutColor,
	NotificationColor,
	Calendar,
	Download,
	Upload,
	Filter,
	FirstPage,
	LastPage,
	Gus,
	Next,
	Prev,
	Notification,
	Refresh,
	Search,
	Trash,
	Inquiries,
	Administration,
	Company,
	Complaint,
	Configuration,
	Offer,
	Pricelist,
	Project,
	Qualityassurance,
	Reports,
	ReportProfitability,
	ReportOfferEfficiency,
	ReportQaEfficiency,
	ReportComplaint,
	FeatureTypes,
	ProductTypes,
	Templates,
	Group,
	Currency,
	TemplateDef,
	ProjDeliveryTemplate,
	CustomerSupplierTypePref,
	Role,
	Organization,
	Internationalization,
}

/* #endregion */
export default NewIcons
