import React from 'react'
import { DialogContent, DialogTitle, Typography} from '@material-ui/core'
import { Title, Description, Subtitle, Key, Wrapper, StyledDialog } from './HelperModalStyled'
import { useTranslate } from 'locale/Locale'

const HelperModal = ({ open, setOpen }) => {
	
	const translate = useTranslate('WebSpa/HelperModal')

	return (
		<div tabIndex='0' >
			<StyledDialog open={open} onClose={() => setOpen && setOpen(false)} onKeyDown={(e) => {
				if (e.key === "F1") {
					e.preventDefault()
					setOpen && setOpen(false)
				}
				return
			}}>
				<DialogTitle>
					<Typography style={{fontSize: '1.9rem', fontWeight: 600}}>{translate('title')}</Typography>
				</DialogTitle>
				<DialogContent dividers>
					<Title>
						{translate('grouping/title')}
					</Title>

					<Wrapper>
						<Subtitle>
							{translate('grouping/option1')}
						</Subtitle>
						<Description>
							<Key>
								CTRL
							</Key>
							+
							<Key>
								{translate('LMB')}
							</Key>
						</Description>
					</Wrapper>

					<Wrapper>
						<Subtitle>
							{translate('grouping/option2')}
						</Subtitle>
						<Description>
							<Key>
								CTRL
							</Key>
							+
							<Key>
								SHIFT
							</Key>
							+
							<Key>
								{translate('LMB')}
							</Key>
						</Description>
					</Wrapper>

					<Title>
						{translate('sorting/title')}
					</Title>

					<Wrapper>
						<Subtitle>
							{translate('sorting/option1')}
						</Subtitle>
						<Description>
							<Key>
								SHIFT
							</Key>
							+
							<Key>
								{translate('LMB')}
							</Key>
						</Description>
					</Wrapper>
				</DialogContent>
			</StyledDialog>
		</div>
	)
}

HelperModal.defaultProps = {
	open: false
}

export default HelperModal
