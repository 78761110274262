import React from 'react'
import { ThemeProvider as TP, css } from 'styled-components'
import { ThemeValue } from 'components/topology/Topology'
import config from 'config';

const fonts = {
	// secondary: 'Source Sans Pro, sans-serif'

	//Standardowe fonty: 

	// primary: 'Arial',
	// secondary: 'Arial'

	// primary: 'Segoe UI',
	// secondary: 'Segoe UI'

	// primary: 'Calibri',
	// secondary: 'Calibri'

	//Google Fonts: 

	// primary: 'Montserrat',
	// secondary: 'Montserrat'

	primary: 'Roboto',
	secondary: 'Roboto'

}

const colors = {
	primary: '#316197',
	primaryLighten: '#3f7daf',
	primaryDarken: '#29436d',
	primaryDarken800: '#1a314b',

	primaryDarken700: '#336699',
	primaryDarken900: 'linear-gradient(180deg, ' + config.upperMainMenuColor + ' 0%, ' + config.lowerMainMenuColor + ' 100%)',

	primaryDarken1000: '#122438',
	primaryBackground: '#ffffff',
	primaryAlternative: '#599ca4',

	error: '#A73A38',
	errorAlternative: '#963352',
	success: '#1A746B',


	warning: '#FB8C00',
	warningLighten: '#ddcb72',
	info: '#b26262',
	
	orange700: '#e67e22',
	green700: '#049372',
	blue700: '#274239',

	grey100: '#EDEDED',
	grey200: '#E4E4E4',
	grey300: '#D2D3D3',
	grey400: '#BCBBBB',
	grey500: '#9F9F9F',
	grey600: '#7C7C7C',
	grey700: '#424242',
	
	yellow100: '#EE9611',

	dropzoneDragActive: '#38ada9',
	dropzoneDragReject: '#ad2d40',

	link: "#2b5687",
	
	none: 'transparent',
	white: '#fff', 
	black: '#000',
}

const theme = {
	rowSize: 39,
	colors,
	fontFamily: fonts,
	fontDefinition: {
		'p-lg': { fontFamily: 'primary', fontSize: 'lg' },
		'p-md': { fontFamily: 'primary', fontSize: 'md' },
		'p-sm': { fontFamily: 'primary', fontSize: 'sm' },
		's-lg': { fontFamily: 'secondary', fontSize: 'lg' },
		's-md': { fontFamily: 'secondary', fontSize: 'md' },
		's-sm': { fontFamily: 'secondary', fontSize: 'sm' },
		'topbar': { fontFamily: 'primary', fontSize: 'extralg' },
		'label': { fontFamily: 'primary', fontSize: 12 },
	},

	fontSize: {
		extralg: 30,
		lg: 18,
		md: 16,
		sm: 12
	},

	gapX: {
		lg: '15px',
		md: '5px',
		sm: '2px',
		none: '0px'
	},
	gapY: {
		lg: '15px',
		md: '5px',
		sm: '2px',
		none: '0px'
	},
	background: {
		default: 'white',
		none: 'transparent'
	},
	padding: {
		lg: '25px',
		md: '10px',
		sm: '5px',
		vsm: '2px',
		none: '0px'
	},
	margin: {
		lg: '25px',
		md: '10px',
		sm: '5px',
		none: '0px'
	},

	borders: {
		primary: '1px solid	rgba(85,85,85, 0.4)', //Border używany w większości inputów
		focus: '1px solid #29436d', //Border o kolorze primaryColor z tematu
		paginatorInput: '1px solid #555555', //Border w kolorze darkText z tematu
		error: '1px solid #ff5d47', //Border w kolorze error z tematu
		search: '1px solid #b8c1c9 !important',
		rounded: '40px',
		transparent: '1px solid transparent',
		none: 'none',

		defaultRadius: '4px', //Domyślny border Radius dla inputów
		searchInputRadius: '17px' //Border dla wyszukiwarki
	},

	ntv: {
		//Values. Poniżej wpisujemy tylko wartości taje jak kolor ilość pixeli itd
		color: {
			primary: '#14A0A4',
		},
		space: {
			hor: {},
			ver: {}
		},
		fontFamily: {
			primary: 'Roboto, sans-serif',
			secondary: 'Source Sans Pro, sans-serif'
		},
		fontSize: {

		},
		fontWeight: {

		},

		//Styles. Poniżej umieszczamy tylko podstaowe style na podstawie Values odpowiadające konkretnemu stylowi z css bądź grupie z css.
		//Np. w Color: tylko css`color: ${ThemeValue('color.cośtam')}`
		//Np. w Font: css`font-family:...; font-size:...; ` 
		Color: {
			primary: css`color: ${ThemeValue('color.primary')};`,
		},
		BorderColor: {

		},
		BackgroundColor: {

		},
		//W pełni zdefiniowane kolory
		//składa się z color border-color i background-color
		Colors: {

		},
		Radius: {

		},
		Border: {

		},
		Transition: {

		},
		//W pelni zdefiniowane fonty 
		Font: {
			primary: css`
				font-family: ${ThemeValue('fontFamily.primary')};
				font-size: ${ThemeValue('fontSize.primary')};
			`
		},
		Margin: {

		},
		Padding: {

		},

		//Component specify styles
		//Tu umieszczamy tylko style związane z konkretnymi komponentami i mogące wyglądać inaczej w innym temacie
		Button: {
			Colors: {
				cancel: '',
				save: '',
				link: '',
				delete: '',
				login: ''
			},
			Transition: '',
			Border: '',
			Font: ''
		}
	},





	test1: {
		b1: '1px',
		t1: 'solid',
		c1: '#f00',
		aa: css`ala`,

		m1: css`
			border: ${ThemeValue('test1.p2')};
			color: blue;
		`
	}
}

export default function ThemeProvider({ children }) {
	return <TP theme={theme}>{children}</TP>
}
