import React from 'react'

export function TreeIndent({ tree, size }) {
	let indent = -1

	if (!size)
		size = 20

	for (let p = tree.parent; p; ++indent, p = p.parent);

	return <div style={{ width: indent * size }}></div>
}
