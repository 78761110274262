import SegmentElementType from "./SegmentElementType"

export default class SegmentElementViewModel 
{
    static createText(text)
    {
        return new SegmentElementViewModel({typeName: SegmentElementType.Text, value: text});
    }

    static createEmptyTagStartInsert()
    {
        return new SegmentElementViewModel({typeName: SegmentElementType.StartIns});
    }

    static createEmptyTagEndInsert()
    {
        return new SegmentElementViewModel({typeName: SegmentElementType.EndIns});
    }

    static createEmptyTagStartDelete()
    {
        return new SegmentElementViewModel({typeName: SegmentElementType.StartDel});
    }

    static createEmptyTagEndDelete()
    {
        return new SegmentElementViewModel({typeName: SegmentElementType.EndDel});
    }

    static createStartTag(crossFormatId, anchor)
    {
        return new SegmentElementViewModel({typeName: SegmentElementType.StartTag, crossFormatId, anchor});
    }

    static createEndTag(anchor)
    {
        return new SegmentElementViewModel({typeName: SegmentElementType.EndTag, anchor});
    }

    constructor({ typeName, value, crossFormatId, anchor, status, role}) {
        if (!typeName) {
            throw `Segment element type cannot be empty`
        }

        this._typeName = typeName;
        if(![SegmentElementType.StartDel,SegmentElementType.EndDel,SegmentElementType.StartIns, SegmentElementType.EndIns].includes(typeName)) {
            this._value = value || '';
        }
        this._crossFormatId = crossFormatId;
        this._anchor = anchor;
        this._status = status;
        this._role = role;

        if (!SegmentElementType.values().includes(this._typeName)) {
            throw `Unsupported segment element type: ${typeName}`;
        }

    }

    get typeName() {
        return this._typeName;
    }

    get value() {
        return this._value;
    }

    get crossFormatId() {
        return this._crossFormatId;
    }

    get status() {
        return this._status;
    }

    get role() {
        return this._role;
    }

    get anchor() {
        return this._anchor;
    }

    get isText() {
        return this._typeName === SegmentElementType.Text
    }

    get isStandaloneTag() {
        return this._typeName === SegmentElementType.StandaloneTag
    }

    get isStartTag() {
        return this._typeName === SegmentElementType.StartTag
    }

    get isEndTag() {
        return this._typeName === SegmentElementType.EndTag
    }

    get isPairedTag() {
        return this.isStartTag || this.isEndTag
    }

    get isTag() {
        return this.isPairedTag || this.isStandaloneTag
    }

    get isEndInsertTag() {
        return this._typeName === SegmentElementType.EndIns;
    }

    get isStartHighlight() {
        return this._typeName === SegmentElementType.StartHighlight;
    }

    get isEndHighlight() {
        return this._typeName === SegmentElementType.EndHighlight;
    }

    get isHighlight() {
        return this.isStartHighlight || this.isEndHighlight;
    }

    equals(element) {
        if(!element)
            return false;

        if(this._typeName !== element._typeName)
            return false;

        if(this.isTag || this.isHighlight) 
        {
            if(this.isEndTag || this.isEndHighlight)
                return this._anchor === element._anchor;

            return this._crossFormatId === element._crossFormatId;
        }
        
        return this.value === element._value;
    }

    toString()
    {
        switch(this._typeName)
        {
            case SegmentElementType.Text:
                return this._value;
            case SegmentElementType.StandaloneTag:
                return this._crossFormatId;
            case SegmentElementType.StartTag:
            case SegmentElementType.StartIns:
            case SegmentElementType.StartDel:
            case SegmentElementType.StartHighlight:
                return this._crossFormatId;
            case SegmentElementType.EndTag:
            case SegmentElementType.EndIns:
            case SegmentElementType.EndDel:
            case SegmentElementType.EndHighlight:
                return this._anchor;
            default:
                throw new Error('Unknown element type:' + this._typeName);
            
        }
    }
}
