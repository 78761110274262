export function createRectTree(elements) {
	/*
		Elements zawiara
		[
			{
				parent			// wskazanie na parenta tego (jest undefined jesli to root)
				rowIndex,		// index wierwsza w form.rowManager.visibleRows
				row,				// Wiersz z formy	(jest undefined jesli to root)
				children: []	// Tablica dzieci w tym formacie
			},...
		]
	*/

	/*
	Map{
		{
			id:
			tree:element,
			next:
			prev:
		}
	}
	 */
	let mapElements = new Map()

	//elements -> mapElements
	elements.forEach(e => {
		mapElements.set(e.row.id, { id: e.row.id, tree: e, next: [], prev: [] })
	})

	//dodawanie next prev
	mapElements.forEach(e => {
		//DependOnItemsListId
		let dep = e.tree.row.attributes.DependOnItemsListId

		//Brak zależności to nie dodajemy
		if (!dep)
			return

		dep.forEach(oid => {
			let id = oid.id

			//Zależne od nieistniejacego to nie dodajemy
			if (!mapElements.has(id))
				return

			e.prev.push(id)
			mapElements.get(id).next.push(e.id)
		})
	})

	let start = []

	//utworzenie startów
	mapElements.forEach(e => {
		if (e.next.length > 0 && e.prev.length === 0)
			start.push(e.id)
	})

	//Wykasowanie zapetlen
	let set = new Set()

	mapElements.forEach(e => {
		set.add(e.id)
	})

	let remover = function (start) {
		let res = start.reduce((p, id) => {
			if (set.has(id)) {
				p.push(id)
				set.delete(id)
			}
			return p
		}, [])

		start = res

		start.forEach(id => {
			let e = mapElements.get(id)
			e.next = remover(e.next)
		})

		return start
	}

	remover(start)

	//Dodanie pozostałych
	let prevE
	let firstE
	set.forEach(id => {
		if (!prevE) {
			firstE = prevE = mapElements.get(id)
			return
		}
		prevE.next.push(id)
		prevE = mapElements.get(id)
	})

	if (firstE)
		start.push(firstE.id)

	return {
		start,
		mapElements
	}
}