
import LanguageDocument from "./LanguageDocument";

export function removeFromArrayByIndex(index: number, arr: any[])
{
    arr.splice(index, 1);
}

export function compareDocumentsFn(a: any, b: any) {
    if(a.hasMergedDocuments)
        return -1;
    else if(b.hasMergedDocuments)
        return 1;

    try
    {
        if(a.segmentsRange.start < b.segmentsRange.start)
            return -1;
        else if(a.segmentsRange.start > b.segmentsRange.start)
            return 1;
    }
    catch(err)
    {
        console.error(err);
        if(a.name < b.name)
            return -1;
        else if(a.name > b.name)
            return 1;
    }

    return 0;
}

export function isDocumentAvailableForTranslation(documentId: string, documents: Array<LanguageDocument>): boolean
{
    if(!documents || !documentId)
        return false;

    for (const document of documents)
    {
        if(document.id === documentId)
            return !!document.isAvailableForTranslation;
    }

    return false;
}

export function getDocumentStartIndex(documentId: string, documents: Array<LanguageDocument>): number
{
    if(!documents || !documentId)
        return -1;

    for (const document of documents)
    {
        if(document.id === documentId)
            return document.segmentsRange.start;
    }

    return -1;
}

export function dateTimeStringToFormatedString(value?: string): string
{
    if(value)
    {
        const dateValue = Date.parse(value);
        return new Date(dateValue).toLocaleString('pl-PL');
    }

    return "";
}